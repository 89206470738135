import { getMockedBlockRenderer } from "../widget_blocks/block";
import { Pair, IOWidget, Action, mkWidget } from "widgets-for-react";
import { FeaturedContentBlockProps, FeaturedContentBlockStyle } from "../widget_blocks/featuredContentBlock";
import { CMSState } from "../widget_blocks/state";
import { CustomBlockData } from "./custom_blocks_types";
import { ExtraImages } from "./extra_images";
import React = require("react");


let myFeauredContentBlock = (
    itemsOptions: Array<
      Pair<FeaturedContentBlockProps<CustomBlockData, ExtraImages>, { style?: FeaturedContentBlockStyle; key?: string }>>
  ): IOWidget<CMSState<CustomBlockData, ExtraImages>, Action<CMSState<CustomBlockData, ExtraImages>>> => {
      return s => mkWidget({run:(setstate) => {
          return <div>
              This is a custom renderer for a default block of kind <b>featuredContentComponent</b>
              {itemsOptions.map(i => <div>
                  <div> <i>title:</i> <b>{i.fst.title}</b> </div>
                  <div> <i>image location:</i> <b>{i.fst.imageLocation}</b> </div>
                  <div> <i>image id:</i> <b>{i.fst.image}</b> </div>
                  <div> <i>image image_size:</i> <b>{i.fst.image_size}</b> </div>
                  <div> <i>intro:</i> <b>{i.fst.intro}</b> </div>
              </div>)}
          </div>
      }})
  }

export let blocks_renderer = <customBlockData, extra_images>() => 
    ({...getMockedBlockRenderer<customBlockData, extra_images>(), 
        // featuredContentComponent:myFeauredContentBlock
    })