import * as React from "react"
import { Action, IOWidget, mk_widget } from "widgets-for-react"
import { ExtraImages } from "../public_site_utils/extra_images"
import { CMSState } from "./state"
import { Url } from "./types"
import { link } from "./utils"

export type CtaCampaignBlockProps = {
  title: string
  intro: string
  title_options?: {
    size: string,
    font: string
  }
  text_options?: {
    size: string,
    font: string
  }
  buttons: "none" | "primary" | "primary_secondary"
  primary_button?: ({
    kind: "link"
    is_link_external: boolean
    target: "_blank" | "_self"
    url: Url
  }) & { title: string }
  secondary_button?: ({
    kind: "link"
    is_link_external: boolean
    target: "_blank" | "_self"
    url: Url
  }) & { title: string }
}

export type CtaCampaignBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
  header_classname: string
  title_classname: string
  content_classname: string
  text_classname: string
  footer_classname: string
  clickable_primary_classname: string
  clickable_secondary_classname: string
}>

const getClassName = (
  options: { style?: CtaCampaignBlockStyle },
  className: keyof CtaCampaignBlockStyle,
  notset: string = ""
): string =>
  options && options.style && (options.style[className] || options.style[className] == null) ? options.style[className] : notset

export function CtaCampaignBlock<CustomBlockData>(
  props: CtaCampaignBlockProps,
  options?: { style?: CtaCampaignBlockStyle; key?: string }
): IOWidget<CMSState<CustomBlockData, ExtraImages>, Action<CMSState<CustomBlockData, ExtraImages>>> {
  return (s: CMSState<CustomBlockData, ExtraImages>) =>
    mk_widget({
      run: _ => (
        <section className={getClassName(options, "block_classname", "container")} key={options && options.key ? options.key : "cta-block"}>
          <div className={getClassName(options, "inner_classname", "py-5")}>
            { props.title &&
              <header className={getClassName(options, "header_classname", "")}>
                <h2
                  className={getClassName(options, "title_classname", "text-center mb-3")}
                  dangerouslySetInnerHTML={{ __html: props.title }}
                />
              </header>
            }

            { props.intro &&
              <div
                className={getClassName(options, "content_classname", "")}
                dangerouslySetInnerHTML={{ __html: props.intro }}
              />
            }

            {props.buttons !== "none"
              ? <footer className={getClassName(options, "footer_classname", "text-center")}>
                  {props.primary_button && props.primary_button.title && props.primary_button.url
                    ? link(props.primary_button,
                          {classname: getClassName(options, "clickable_primary_classname", "button mr-2" )},
                          props.primary_button.title)
                    : null
                  }
                  {props.secondary_button && props.secondary_button.title && props.secondary_button.url && props.buttons === "primary_secondary"
                    ? link(props.secondary_button,
                          {classname: getClassName(options, "clickable_secondary_classname", "button button-link" )},
                          props.secondary_button.title)
                    : null
                  }
                </footer>
              : null
            }
          </div>
        </section>
      ),
    })
}
