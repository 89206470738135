import * as React from "react";
import { mkWidget, Action, checkbox } from "widgets-for-react";
import { SignUpState } from "../state";
import * as i18next from 'i18next';

export const sign_up_form_privacy = (s0: SignUpState) => mkWidget<Action<SignUpState>>({ run: setState =>
  <>
    {checkbox<boolean>({ selected_value: true, unselected_value: false, id: "aggree-with-terms-and-conditions", className: "" })(s0.login_data.agreed)
      .map<Action<SignUpState>>(a => s => ({ ...s, login_data:{...s.login_data, agreed: a }})).run(a => setState(a)) }
    <label htmlFor="aggree-with-terms-and-conditions" dangerouslySetInnerHTML={{ __html: i18next.t('signup_form:checkbox_tos') }} />
  </>
})
