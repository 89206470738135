import { Block, CtaBlock, DownloadBlock, ExpandBlock, FeaturedContentBlock, LinkGridBlock, LinkListBlock, MediaBlock, TextBlock, LinkGridBlockPlain, QuoteBlock, IframeBlock, EmbedBlock } from "./drupal/drupal_utils/types";
import { CtaBlockProps, CtaBlockStyle } from "./widget_blocks/ctaBlock";
import { DownloadBlockProps, DownloadBlockStyle, DownloadProps } from "./widget_blocks/downloadBlock";
import { ExpandBlockProps, ExpandBlockStyle, ExpandProps } from "./widget_blocks/expandBlock";
import { FeaturedContentBlockProps, FeaturedContentBlockStyle } from "./widget_blocks/featuredContentBlock";
import { LinkBlockProps, LinkBlockStyle } from "./widget_blocks/linkBlock";
import { LinkGridBlockProps, LinkGridBlockStyle, LinkGridItem } from "./widget_blocks/linkGridBlock";
import { MediaBlockProps, MediaBlockStyle } from "./widget_blocks/mediaBlock";
import { BlockData } from "./widget_blocks/state";
import { TextBlockProps, TextBlockStyle } from "./widget_blocks/textBlock";
import { buttonWrapper, linkWrapper } from "./widget_blocks/utils";
import { ParsedMediaImage, ParsedParagraph } from "./drupal/drupal_generated/types";
import { textBlockClasses, featuredContentClasses, ctaBlockClasses, expandBlockClasses, mediaBlockClasses, linkGridBlockPlainClasses, downloadBlockClasses, linkListBlockClasses, linkGridBlockClasses, quoteBlockClasses, IframeBlockClasses, ctaImageCampaignBlockClasses, mediaCampaignBlockClasses, linkGridCampaignBlockClasses, ctaCampaignBlockClasses, textCampaignBlockClasses, expandCampaignBlockClasses, embedBlockClasses } from "./blockclasses";
import { Option, some, none, mk_pair } from "widgets-for-react"
import * as UriJs from "uri-js"
import { isCustomBlock, CustomBlock, CustomBlockData, RestaurantListBlock, MenuBlock, CtaImageCampaignBlock, MediaCampaignBlock, LinkGridCampaignBlock, TextCampaignBlock, CtaCampaignBlock, ExpandCampaignBlock, LeafletMapBlock } from "./public_site_utils/custom_blocks_types";
import { ExtraImages } from "./public_site_utils/extra_images";
import { QuoteBlockProps, QuoteBlockStyle } from "./widget_blocks/quoteBlock";
import { IframeBlockProps, IframeBlockStyle } from "./widget_blocks/iframeBlock";
import { RestaurantListBlockProps, RestaurantListBlockStyle } from "./widget_blocks/restaurantListBlock";
import { MenuBlockProps, MenuBlockStyle, MenuGroup, MenuItem } from "./widget_blocks/menuBlock";
import { CtaImageCampaignBlockProps, CtaImageCampaignBlockStyle } from "./widget_blocks/ctaImageCampaignBlock";
import { MediaCampaignBlockProps, MediaCampaignBlockStyle } from "./widget_blocks/mediaCampaignBlock";
import { LinkGridCampaignBlockProps, LinkGridCampaignBlockStyle, LinkGridCampaignItem } from "./widget_blocks/linkGridCampaignBlock";
import { TextCampaignBlockProps, TextCampaignBlockStyle } from "./widget_blocks/textCampaignBlock";
import { CtaCampaignBlockProps, CtaCampaignBlockStyle } from "./widget_blocks/ctaCampaignBlock";
import { ExpandCampaignBlockProps, ExpandCampaignBlockStyle } from "./widget_blocks/expandCampaignBlock";
import { EmbedBlockProps, EmbedBlockStyle } from "./widget_blocks/embedBlock";

export const TransformStandardBlock = (b: ParsedParagraph): Block => {
  // @ts-ignore
  return { ...b, kind: b.bundle }
}
// tslint:disable-next-line:variable-name
export const DrupalBlock_To_WidgetBlock = (
  block: Block | CustomBlock,
  index: number,
  mk_block?: (block: CustomBlock, index: number) => Option<CustomBlockData>,
): Option<BlockData<CustomBlockData, ExtraImages> | CustomBlockData> => {
  switch (block.kind) {
    case "featured_content":
      return some<BlockData<CustomBlockData, ExtraImages>>(featuredContent<CustomBlockData, ExtraImages>(block))
    case "text":
      return some<BlockData<CustomBlockData, ExtraImages>>(textBlock(block))
    case "cta":
      return some<BlockData<CustomBlockData, ExtraImages>>(ctaBlock(block))
    case "downloads":
      return some<BlockData<CustomBlockData, ExtraImages>>(downloadBlock(block))
    case "links_list":
      return some<BlockData<CustomBlockData, ExtraImages>>(linkListBlock(block))
    case "grid_blocks_plain":
      return some<BlockData<CustomBlockData, ExtraImages>>(linkGridBlockPlain(block))
    case "grid_blocks_image":
      return some<BlockData<CustomBlockData, ExtraImages>>(linkGridBlock(block))
    case "media":
      return some<BlockData<CustomBlockData, ExtraImages>>(mediaBlock(block))
    case "expandable_content_blocks":
      return some<BlockData<CustomBlockData, ExtraImages>>(expandBlock(block, index))
    case "quote":
      return some<BlockData<CustomBlockData, ExtraImages>>(quoteBlock(block))
    case "iframe":
      return some<BlockData<CustomBlockData, ExtraImages>>(iframeBlock(block))
    case "map":
      return some<BlockData<CustomBlockData, ExtraImages>>(mk_leafletMapBlock(block, index))
    case "embed":
      return some<BlockData<CustomBlockData, ExtraImages>>(embedBlock(block))
    default: {
      if (mk_block && isCustomBlock(block)) {
        let maybe_res = mk_block(block, index)
        if (maybe_res.v.kind == "r") return maybe_res
      }
      console.warn("Unsupported block.", block)
      return none<BlockData<CustomBlockData, ExtraImages> | CustomBlockData>()
    }
  }
}

const parseImage = (i: ParsedMediaImage | undefined): number =>
  i != undefined && i.field_media_image != undefined && i.field_media_image.image != undefined && i.field_media_image.image.id != null
    ? i.field_media_image.image.id
    : 0

export const featuredContent = <CustomBlocks, custom_images>(block: FeaturedContentBlock): BlockData<CustomBlocks, custom_images> => ({
  kind: "featuredContentBlock",
  value: [
    mk_pair<FeaturedContentBlockProps<CustomBlocks, custom_images>, FeaturedContentBlockStyle>(
      {
        image: block.field_image ? parseImage(block.field_image) : 0,
        imageLocation: block.field_featured_content_direction == 'image_left' ? 'left' : 'right',
        title: block.field_title ? block.field_title : "",
        intro: block.field_introduction,
        image_size: "large",
        image_alt_text: block.field_image && block.field_image.field_media_image.alt ? block.field_image.field_media_image.alt : "",
        primary_button: block.field_links.has(0) ? buttonWrapper(block.field_links.get(0)) : undefined,
        secondary_button: block.field_links.has(1) ? buttonWrapper(block.field_links.get(1)) : undefined,
      },
      featuredContentClasses(block.field_featured_content_direction == 'image_left' ? 'left' : 'right')
    )
  ]
})

const parseMediaUrl = (s: string) => {
  const uri = UriJs.parse(s)
  return `/drupal/media/${uri.path}?${uri.query}`
}

export const textBlock = <CustomBlocks, custom_images>(block: TextBlock): BlockData<CustomBlocks, custom_images> => ({
  kind: "textBlock",
  value: mk_pair<TextBlockProps<CustomBlocks, custom_images>, TextBlockStyle>(
    {
      primary_button: block.field_links.has(0) ? buttonWrapper(block.field_links.get(0)) : undefined,
      link: block.field_links.has(1) ? linkWrapper(block.field_links.get(1)) : undefined,
      title: block.field_title ? block.field_title : "",
      text: block.field_body,
    },
    textBlockClasses),
})

export const ctaBlock = <CustomBlocks, custom_images>(block: CtaBlock): BlockData<CustomBlocks, custom_images> => ({
  kind: "ctaBlock",
  value: mk_pair<CtaBlockProps, CtaBlockStyle>(
    {
      title: block.field_title,
      intro: block.field_introduction,
      buttons: block.field_links == null ? "none" : block.field_links.count() == 1 ? "primary" : "primary_secondary",
      primary_button: block.field_links.has(0) ? linkWrapper(block.field_links.get(0)) : undefined,
      secondary_button: block.field_links.has(1) ? linkWrapper(block.field_links.get(1)) : undefined
    },
    ctaBlockClasses)
})

export const expandBlock = <CustomBlocks, custom_images>(block: ExpandBlock, index: number): BlockData<CustomBlocks, custom_images> => ({
  kind: "expandBlock",
  value: mk_pair<ExpandBlockProps, ExpandBlockStyle>(
    {
      block_index: index,
      title: block.field_title ? block.field_title : "",
      intro: block.field_introduction ? block.field_introduction : "",
      items: block.field_expandable_content_blocks
        .filter(x => x !== undefined)
        .map<ExpandProps>((e, i) => ({
          key: `${e!.field_title} ${i}`,
          title: e!.field_title,
          text: e!.field_body,
          open: false,
        })).toArray(),
      link: block.field_link != undefined ? linkWrapper(block.field_link) : undefined,
    },
    expandBlockClasses)
})

export const embedBlock = <CustomBlocks, custom_images>(block: EmbedBlock): BlockData<CustomBlocks, custom_images> => ({
  kind: "embedBlock",
  value: mk_pair<EmbedBlockProps, EmbedBlockStyle>(
    {
      embedHtml: block.field_embed_html,
    },
    embedBlockClasses)
})

export const linkListBlock = <CustomBlocks, custom_images>(block: LinkListBlock): BlockData<CustomBlocks, custom_images> => ({
  kind: "linkBlock",
  value: mk_pair<LinkBlockProps, LinkBlockStyle>(
    {
      title: block.field_title,
      intro: block.field_introduction ? block.field_introduction : "",
      items: block.field_links_list
        .filter(x => x !== undefined)
        .map(l => linkWrapper(l))
        .toArray(),
    },
    linkListBlockClasses)
})

export const downloadBlock = <CustomBlocks, custom_images>(block: DownloadBlock): BlockData<CustomBlocks, custom_images> => ({
  kind: "downloadBlock",
  value: mk_pair<DownloadBlockProps, DownloadBlockStyle>(
    {
      title: block.field_title ? block.field_title : "",
      intro: block.field_introduction ? block.field_introduction : "",
      items: block.field_downloads
        .filter(x => x !== undefined)
        .map<DownloadProps>((e, i) => ({
          key: `${e!.label} ${i}`,
          title: e!.label,
          file_url: e && e.field_media_file && e.field_media_file.file && e.field_media_file.file.url ? parseMediaUrl(e.field_media_file.file.url) : "",
        })).toArray(),
    },
    downloadBlockClasses)
})

export const linkGridBlock = <CustomBlocks, custom_images>(block: LinkGridBlock): BlockData<CustomBlocks, custom_images> => ({
  kind: "linkGridBlock",
  value: mk_pair<LinkGridBlockProps<custom_images>, LinkGridBlockStyle>(
    {
      title: block.field_title ? block.field_title : "",
      intro: block.field_introduction ? block.field_introduction : "",
      items: block.field_grid_blocks
        .filter(x => x !== undefined)
        .map<LinkGridItem<custom_images>>(
          e =>
          ({
            ...e,
            image: parseImage(e!.field_image),
            image_size: "grid_with_image_750_375",
            image_alt: e && e.field_image && e.field_image.field_media_image.alt ? e.field_image.field_media_image.alt : "",
            title: e && e.field_title ? e.field_title : "",
            intro: e && e.field_introduction ? e.field_introduction : "",
            link: e && e.field_link && e.field_link.title && e.field_link.url != null ? linkWrapper(e.field_link) : undefined,
            show_image: block.kind == "grid_blocks_image" ? "yes" : "no"
          })
        ).toArray(),
      link: block.field_link != undefined ? linkWrapper(block.field_link) : undefined,
      show_image: 'yes'
    },
    linkGridBlockClasses(block.field_link != undefined))
})

export const linkGridBlockPlain = <CustomBlocks, custom_images>(block: LinkGridBlockPlain): BlockData<CustomBlocks, custom_images> => ({
  kind: "linkGridBlock",
  value: mk_pair<LinkGridBlockProps<custom_images>, LinkGridBlockStyle>(
    {
      title: block.field_title ? block.field_title : "",
      intro: block.field_introduction ? block.field_introduction : "",
      items: block.field_grid_blocks.map<LinkGridItem<custom_images>>(
        e =>
        ({
          ...e,
          image: 0,
          image_size: "grid_with_image_750_375",
          image_alt: "",
          title: e && e.field_title ? e.field_title : "",
          intro: e && e.field_introduction ? e.field_introduction : "",
          link: e && e.field_link && e.field_link.title && e.field_link.url ? linkWrapper(e.field_link) : undefined,
          show_image: "no"
        })
      ).toArray(),
      link: block.field_link != undefined ? linkWrapper(block.field_link) : undefined,
      show_image: 'yes'
    },
    linkGridBlockPlainClasses(block.field_grid_blocks.size)
  )
})

export const mediaBlock = <CustomBlocks, custom_images>(block: MediaBlock): BlockData<CustomBlocks, custom_images> => ({
  kind: "mediaBlock",
  value: mk_pair<MediaBlockProps<custom_images>, MediaBlockStyle>(
    {
      caption: block.field_caption ? block.field_caption : "",
      image: block.field_media && block.field_media.bundle === "image" ? parseImage(block.field_media) : 0,
      image_alt: block.field_media && block.field_media.bundle === "image" && block.field_media.field_media_image.alt ? block.field_media.field_media_image.alt : "",
      size: "media_880w",
      kind_media: block.field_media && block.field_media.bundle === "image" ? "image" : "video",
      video: {
        url: block.field_media && block.field_media.bundle === "video" ? block.field_media.field_media_video_embed_field.iframe_url : "",
        id: block.field_media && block.field_media.bundle === "video" ? block.field_media.field_media_video_embed_field.id : "",
        type: block.field_media && block.field_media.bundle === "video" ? block.field_media.field_media_video_embed_field.type : "",
        allowFullScreen: true,
        frameBorder: "0",
        height: "315",
        width: "420",
      },
    },
    mediaBlockClasses(block.field_media && block.field_media.bundle === "image" ? "image" : "video")
  )
})

export const quoteBlock = <CustomBlocks, custom_images>(block: QuoteBlock): BlockData<CustomBlocks, custom_images> => ({
  kind: "quoteBlock",
  value: mk_pair<QuoteBlockProps<custom_images>, QuoteBlockStyle>(
    {
      text: block.field_text,
      author_name: block.field_author_name,
      link: block.field_link != undefined ? linkWrapper(block.field_link) : undefined,
      image: block.field_image ? parseImage(block.field_image) : null,
      image_size: "person_140x140",
      image_alt_text: block.field_image && block.field_image.field_media_image.alt ? block.field_image.field_media_image.alt : "",
    }, quoteBlockClasses)
})

export const iframeBlock = <CustomBlocks, custom_images>(block: IframeBlock): BlockData<CustomBlocks, custom_images> => ({
  kind: "iframeBlock",
  value: mk_pair<IframeBlockProps, IframeBlockStyle>(
    {
      link: block.field_link.url, 
      height: block.field_height
    }, IframeBlockClasses)
})

export const restaurantListBlock = (block: RestaurantListBlock): CustomBlockData => ({
  kind: "restaurantListBlock",
  value: mk_pair<RestaurantListBlockProps, RestaurantListBlockStyle>(
    {},
    {}
  )
})

export const menuBlock = (block: MenuBlock): CustomBlockData => ({
  kind: "menuBlock",
  value: mk_pair<MenuBlockProps, MenuBlockStyle>(
    {
      groups: block.field_menu_groups.map<MenuGroup>(group => ({
        title: group.field_title,
        items: group.field_menu_items.map<MenuItem>(item =>
          item.bundle == "menu_product" ? ({
            kind: "product",
            title: item.field_title,
            allergens: item.field_allergens ? item.field_allergens : "",
            price: item.field_price ? new Intl.NumberFormat('nl', { minimumFractionDigits: 2 }).format(parseFloat(item.field_price)) : null,
            text: item.field_text,
            image: item.field_image ? parseImage(item.field_image) : 0,
            imageAltText: item.field_image && item.field_image.field_media_image.alt ? item.field_image.field_media_image.alt : "",
            imageSize: "product_small"
          }) : ({
            kind: "photo",
            image: item.field_image ? parseImage(item.field_image) : 0,
            imageAltText: item.field_image && item.field_image.field_media_image.alt ? item.field_image.field_media_image.alt : "",
            imageSize: "media_880w"
          })
        ).toList()
      })).toList()
    },
    {}
  )
})

// ---- Used only on Campaigns post type

export const ctaImageCampaignBlock = (block: CtaImageCampaignBlock): CustomBlockData => ({
  kind: "ctaImageCampaignBlock",
  value: mk_pair<CtaImageCampaignBlockProps, CtaImageCampaignBlockStyle>(
    {
      title: block.field_campaign_title,
      intro: block.field_body,
      title_options: block.field_fonts_font_size_title ? {
        size: block.field_fonts_font_size_title.field_size ? block.field_fonts_font_size_title.field_size : undefined,
        font: block.field_fonts_font_size_title.field_font ? block.field_fonts_font_size_title.field_font : undefined
      } : undefined,
      text_options: block.field_fonts_font_size_text ? {
        size: block.field_fonts_font_size_text.field_size ? block.field_fonts_font_size_text.field_size : undefined,
        font: block.field_fonts_font_size_text.field_font ? block.field_fonts_font_size_text.field_font : undefined
      } : undefined,
      buttons: block.field_links == null ? "none" : block.field_links.count() == 1 ? "primary" : "primary_secondary",
      primary_button: block.field_links.has(0) ? linkWrapper(block.field_links.get(0)) : undefined,
      secondary_button: block.field_links.has(1) ? linkWrapper(block.field_links.get(1)) : undefined,
      direction: block.field_width ? 'center' : block.field_direction == 'text_left' ? 'right' : 'left',
      width: block.field_width,
      image: block.field_image ? {
        id: parseImage(block.field_image),
        size: block.field_width ? 'media_880w' : 'w720',
        srcsetSize: block.field_width ? [
          {
            srcSize: 'media_880w',
            imgSize: '880w'
          },
          {
            srcSize: 'w1320',
            imgSize: '1320w'
          }
        ] : [
          {
            srcSize: 'w720',
            imgSize: '720w'
          },
          {
            srcSize: 'w1440',
            imgSize: '1440w'
          },
          {
            srcSize: 'w2160',
            imgSize: '2160w'
          },
          {
            srcSize: 'w2880',
            imgSize: '2880w'
          }
        ],
        alt: block.field_image.field_media_image.alt ? block.field_image.field_media_image.alt : "",
        width: block.field_width ? '880' : '720',
        height: null,
        lazyload: true
      } : null
    },
    ctaImageCampaignBlockClasses({
      width: block.field_width ? 'content' : 'full',
      direction: block.field_width ? 'center' : block.field_direction == 'text_left' ? 'right' : 'left',
      textFont: block.field_fonts_font_size_text && block.field_fonts_font_size_text.field_font ? block.field_fonts_font_size_text.field_font : undefined,
      textSize: block.field_fonts_font_size_text && block.field_fonts_font_size_text.field_size ? block.field_fonts_font_size_text.field_size : undefined,
      titleFont: block.field_fonts_font_size_title && block.field_fonts_font_size_title.field_font ? block.field_fonts_font_size_title.field_font : undefined,
      titleSize: block.field_fonts_font_size_title && block.field_fonts_font_size_title.field_size ? block.field_fonts_font_size_title.field_size : undefined
    })
  )
})


export const mediaCampaignBlock = (block: MediaCampaignBlock): CustomBlockData => ({
  kind: "mediaCampaignBlock",
  value: mk_pair<MediaCampaignBlockProps, MediaCampaignBlockStyle>(
    {
      caption: block.field_campaign_title ? block.field_campaign_title : "",
      text_options: block.field_fonts_font_size_title ? {
        size: block.field_fonts_font_size_title.field_size ? block.field_fonts_font_size_title.field_size : undefined,
        font: block.field_fonts_font_size_title.field_font ? block.field_fonts_font_size_title.field_font : undefined
      } : undefined,
      image: block.field_media && block.field_media.bundle === "image" ? {
        id: parseImage(block.field_media),
        size: block.field_width ? 'media_880w' : 'w660',
        srcsetSize: block.field_width ? [
          {
            srcSize: 'media_880w',
            imgSize: '880w'
          },
          {
            srcSize: 'w1320',
            imgSize: '1320w'
          }
        ] : [
          {
            srcSize: 'w660',
            imgSize: '660w'
          },
          {
            srcSize: 'w1320',
            imgSize: '1320w'
          },
          {
            srcSize: 'w1980',
            imgSize: '1980w'
          }
        ],
        alt: block.field_media.field_media_image.alt ? block.field_media.field_media_image.alt : "",
        width: block.field_width ? '880' : '660',
        height: null,
        lazyload: true
      } : null,
      kind_media: block.field_media && block.field_media.bundle === "image" ? "image" : "video",
      video: {
        url: block.field_media && block.field_media.bundle === "video" ? block.field_media.field_media_video_embed_field.iframe_url : "",
        id: block.field_media && block.field_media.bundle === "video" ? block.field_media.field_media_video_embed_field.id : "",
        type: block.field_media && block.field_media.bundle === "video" ? block.field_media.field_media_video_embed_field.type : "",
        allowFullScreen: true,
        frameBorder: "0",
        height: "315",
        width: "420",
      },
      width: block.field_width,
    },
    mediaCampaignBlockClasses({
      width: block.field_width ? 'content' : 'full',
      textFont: block.field_fonts_font_size_title && block.field_fonts_font_size_title.field_font ? block.field_fonts_font_size_title.field_font : undefined,
      textSize: block.field_fonts_font_size_title && block.field_fonts_font_size_title.field_size ? block.field_fonts_font_size_title.field_size : undefined,
    })
  )
})


export const linkGridCampaignBlock = (block: LinkGridCampaignBlock): CustomBlockData => ({
  kind: "linkGridCampaignBlock",
  value: mk_pair<LinkGridCampaignBlockProps, LinkGridCampaignBlockStyle>(
    {
      title: block.field_campaign_title ? block.field_campaign_title : "",
      intro: block.field_body ? block.field_body : "",
      title_options: block.field_fonts_font_size_title ? {
        size: block.field_fonts_font_size_title.field_size ? block.field_fonts_font_size_title.field_size : undefined,
        font: block.field_fonts_font_size_title.field_font ? block.field_fonts_font_size_title.field_font : undefined
      } : undefined,
      text_options: block.field_fonts_font_size_text ? {
        size: block.field_fonts_font_size_text.field_size ? block.field_fonts_font_size_text.field_size : undefined,
        font: block.field_fonts_font_size_text.field_font ? block.field_fonts_font_size_text.field_font : undefined
      } : undefined,
      items: block.field_grid_blocks
        .filter(x => x !== undefined)
        .map<LinkGridCampaignItem>(
          e =>
          ({
            ...e,
            image: e && e.field_image ? {
              id: parseImage(e!.field_image),
              size: '480x240',
              srcsetSize: [
                {
                  srcSize: '480x240',
                  imgSize: '480w'
                },
                {
                  srcSize: '640x320',
                  imgSize: '640w'
                },
                {
                  srcSize: '960x480',
                  imgSize: '960w'
                },
                {
                  srcSize: "1280x640",
                  imgSize: "1280w"
                }
              ],
              alt: e.field_image.field_media_image.alt ? e.field_image.field_media_image.alt : "",
              width: '480',
              height: '240',
              lazyload: true
            } : null,
            title: e && e.field_campaign_title ? e.field_campaign_title : "",
            intro: e && e.field_campaign_introduction ? e.field_campaign_introduction : "",
            title_options: e.field_fonts_font_size_title ? {
              size: e.field_fonts_font_size_title.field_size ? e.field_fonts_font_size_title.field_size : undefined,
              font: e.field_fonts_font_size_title.field_font ? e.field_fonts_font_size_title.field_font : undefined
            } : undefined,
            text_options: e.field_fonts_font_size_text ? {
              size: e.field_fonts_font_size_text.field_size ? e.field_fonts_font_size_text.field_size : undefined,
              font: e.field_fonts_font_size_text.field_font ? e.field_fonts_font_size_text.field_font : undefined
            } : undefined,
            link: e && e.field_link && e.field_link.title && e.field_link.url != null ? linkWrapper(e.field_link) : undefined,
            show_image: 'yes'
          })
        ).toArray(),
      link: block.field_link != undefined ? linkWrapper(block.field_link) : undefined,
      show_image: 'yes',
      width: block.field_width,
      columns: block.field_columns,
      alignment: block.field_alignment
    },
    linkGridCampaignBlockClasses({
      width: block.field_width ? 'content' : 'full',
      colunms: block.field_columns,
      alignment: block.field_alignment ? 'center' : 'left',
      textFont: block.field_fonts_font_size_text && block.field_fonts_font_size_text.field_font ? block.field_fonts_font_size_text.field_font : undefined,
      textSize: block.field_fonts_font_size_text && block.field_fonts_font_size_text.field_size ? block.field_fonts_font_size_text.field_size : undefined,
      titleFont: block.field_fonts_font_size_title && block.field_fonts_font_size_title.field_font ? block.field_fonts_font_size_title.field_font : undefined,
      titleSize: block.field_fonts_font_size_title && block.field_fonts_font_size_title.field_size ? block.field_fonts_font_size_title.field_size : undefined
    })
  )
})

export const textCampaignBlock = (block: TextCampaignBlock): CustomBlockData => ({
  kind: "textCampaignBlock",
  value: mk_pair<TextCampaignBlockProps, TextCampaignBlockStyle>(
    {
      primary_button: block.field_links.has(0) ? linkWrapper(block.field_links.get(0)) : undefined,
      link: block.field_links.has(1) ? linkWrapper(block.field_links.get(1)) : undefined,
      title: block.field_campaign_title ? block.field_campaign_title : "",
      text: block.field_campaign_introduction,
      title_options: block.field_fonts_font_size_title ? {
        size: block.field_fonts_font_size_title.field_size ? block.field_fonts_font_size_title.field_size : undefined,
        font: block.field_fonts_font_size_title.field_font ? block.field_fonts_font_size_title.field_font : undefined
      } : undefined,
      text_options: block.field_fonts_font_size_text ? {
        size: block.field_fonts_font_size_text.field_size ? block.field_fonts_font_size_text.field_size : undefined,
        font: block.field_fonts_font_size_text.field_font ? block.field_fonts_font_size_text.field_font : undefined
      } : undefined,
    },
    textCampaignBlockClasses({
      textFont: block.field_fonts_font_size_text && block.field_fonts_font_size_text.field_font ? block.field_fonts_font_size_text.field_font : undefined,
      textSize: block.field_fonts_font_size_text && block.field_fonts_font_size_text.field_size ? block.field_fonts_font_size_text.field_size : undefined,
      titleFont: block.field_fonts_font_size_title && block.field_fonts_font_size_title.field_font ? block.field_fonts_font_size_title.field_font : undefined,
      titleSize: block.field_fonts_font_size_title && block.field_fonts_font_size_title.field_size ? block.field_fonts_font_size_title.field_size : undefined
    })
  ),
})

export const ctaCampaignBlock = (block: CtaCampaignBlock): CustomBlockData => ({
  kind: "ctaCampaignBlock",
  value: mk_pair<CtaCampaignBlockProps, CtaCampaignBlockStyle>(
    {
      title: block.field_campaign_title,
      intro: block.field_campaign_introduction,
      buttons: block.field_links == null ? "none" : block.field_links.count() == 1 ? "primary" : "primary_secondary",
      primary_button: block.field_links.has(0) ? linkWrapper(block.field_links.get(0)) : undefined,
      secondary_button: block.field_links.has(1) ? linkWrapper(block.field_links.get(1)) : undefined,
      title_options: block.field_fonts_font_size_title ? {
        size: block.field_fonts_font_size_title.field_size ? block.field_fonts_font_size_title.field_size : undefined,
        font: block.field_fonts_font_size_title.field_font ? block.field_fonts_font_size_title.field_font : undefined
      } : undefined,
      text_options: block.field_fonts_font_size_text ? {
        size: block.field_fonts_font_size_text.field_size ? block.field_fonts_font_size_text.field_size : undefined,
        font: block.field_fonts_font_size_text.field_font ? block.field_fonts_font_size_text.field_font : undefined
      } : undefined,
    },
    ctaCampaignBlockClasses({
      textFont: block.field_fonts_font_size_text && block.field_fonts_font_size_text.field_font ? block.field_fonts_font_size_text.field_font : undefined,
      textSize: block.field_fonts_font_size_text && block.field_fonts_font_size_text.field_size ? block.field_fonts_font_size_text.field_size : undefined,
      titleFont: block.field_fonts_font_size_title && block.field_fonts_font_size_title.field_font ? block.field_fonts_font_size_title.field_font : undefined,
      titleSize: block.field_fonts_font_size_title && block.field_fonts_font_size_title.field_size ? block.field_fonts_font_size_title.field_size : undefined
    })
  )
})

export const expandCampaignBlock = (block: ExpandCampaignBlock, index: number): CustomBlockData => ({
  kind: "expandCampaignBlock",
  value: mk_pair<ExpandCampaignBlockProps, ExpandCampaignBlockStyle>(
    {
      block_index: index,
      title: block.field_campaign_title ? block.field_campaign_title : "",
      intro: block.field_campaign_introduction ? block.field_campaign_introduction : "",
      title_options: block.field_fonts_font_size_title ? {
        size: block.field_fonts_font_size_title.field_size ? block.field_fonts_font_size_title.field_size : undefined,
        font: block.field_fonts_font_size_title.field_font ? block.field_fonts_font_size_title.field_font : undefined
      } : undefined,
      text_options: block.field_fonts_font_size_text ? {
        size: block.field_fonts_font_size_text.field_size ? block.field_fonts_font_size_text.field_size : undefined,
        font: block.field_fonts_font_size_text.field_font ? block.field_fonts_font_size_text.field_font : undefined
      } : undefined,
      items: block.field_expandable_content_blocks
        .filter(x => x !== undefined)
        .map<ExpandProps>((e, i) => ({
          key: `${e!.field_campaign_title} ${i}`,
          title: e!.field_campaign_title,
          text: e!.field_body,
          title_options: e.field_fonts_font_size_title ? {
            size: e.field_fonts_font_size_title.field_size ? e.field_fonts_font_size_title.field_size : undefined,
            font: e.field_fonts_font_size_title.field_font ? e.field_fonts_font_size_title.field_font : undefined
          } : undefined,
          text_options: e.field_fonts_font_size_text ? {
            size: e.field_fonts_font_size_text.field_size ? e.field_fonts_font_size_text.field_size : undefined,
            font: e.field_fonts_font_size_text.field_font ? e.field_fonts_font_size_text.field_font : undefined
          } : undefined,
          open: false,
        })).toArray(),
      link: block.field_link != undefined ? linkWrapper(block.field_link) : undefined,
    },
    expandCampaignBlockClasses({
      textFont: block.field_fonts_font_size_text && block.field_fonts_font_size_text.field_font ? block.field_fonts_font_size_text.field_font : undefined,
      textSize: block.field_fonts_font_size_text && block.field_fonts_font_size_text.field_size ? block.field_fonts_font_size_text.field_size : undefined,
      titleFont: block.field_fonts_font_size_title && block.field_fonts_font_size_title.field_font ? block.field_fonts_font_size_title.field_font : undefined,
      titleSize: block.field_fonts_font_size_title && block.field_fonts_font_size_title.field_size ? block.field_fonts_font_size_title.field_size : undefined
    })
  )
})

export const mk_leafletMapBlock = <CustomBlocks, custom_images>(block: LeafletMapBlock, index: number): BlockData<CustomBlocks, custom_images>  => ({
  kind: "leafletMap",
  value: {
    fst: {
      block_index: index,
    },
    snd: {
      // TODO: Styling of leafletmap
    }
  }
})
