import * as React from 'react'
import { Action, IOWidget, mk_widget } from 'widgets-for-react'
import { CMSState } from './state'
import { Link, ImageSize, Id } from './types'
import { link } from './utils'
import { ImageContainer } from './ImageContainer'

export type QuoteBlockProps<extra_images> = {
  author_name: string
  text: string
  link: Link
  image: Id
  image_size: ImageSize<extra_images>
  image_alt_text: string
}
export type QuoteBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
  content_classname: string
  quote_classname: string
  quote_content_classname: string
  quote_footer_classname: string
  quote_person_classname: string
  figure_classname: string
  image_classname: string
  clickable_classname: string
}>

const getClassName = (
  options: { style?: QuoteBlockStyle },
  className: keyof QuoteBlockStyle,
  notset: string = ""
): string =>
  options && options.style && (options.style[className] || options.style[className] == null) ? options.style[className] : notset

// TODO
export function QuoteBlock<CustomBlockData, extra_images>(
  props: QuoteBlockProps<extra_images>,
  options?: { style?: QuoteBlockStyle; key?: string }
): IOWidget<
  CMSState<CustomBlockData, extra_images>,
  Action<CMSState<CustomBlockData, extra_images>>
> {
  return (cmsState: CMSState<CustomBlockData, extra_images>) =>
    mk_widget({
      run: _ => {
        return (
          <section className={getClassName(options, "block_classname", "container")} key={options && options.key ? options.key : "quote-block"}>
            <div className={getClassName(options, "inner_classname", "py-5")}>
              <div className={getClassName(options, "content_classname", "")}>
                <blockquote className={getClassName(options, "quote_classname", "mb-3")}>
                  <div className={getClassName(options, "quote_content_classname", "mb-3")}>{props.text}</div>
                  <footer className={getClassName(options, "quote_footer_classname", "")}>
                    { props.image && 
                      <figure className={getClassName(options, "figure_classname", "")}>
                        {cmsState.images.has(props.image) &&
                          ImageContainer(
                            cmsState.images.get(props.image),
                            props.image_size,
                            getClassName(options, "image_classname", ""),
                            props.image_alt_text
                          ).run(_ => null)}
                      </figure>
                    }
                    { props.author_name &&
                      <div className={getClassName(options, "quote_person_classname", "")}>{props.author_name}</div>
                    }
                  </footer>
                </blockquote>
              </div>

              { props.link &&              
                <footer>
                  <p className={getClassName(options, "clickable_classname", "")}>{props.link ? link(props.link, { classname: '' }, props.link.title) : null}</p>
                </footer> 
              }
            </div>
          </section>
        )
      }
    })
}
