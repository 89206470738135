import { LoginState, LoggedOutState, SignUpState, isSignUpState } from '../state';
import { Action, Widget, any, div, label, string, button, link, hn, onlyIf, mkWidget, checkbox, span, nothing } from "widgets-for-react";
import { Api } from '../api_to_state';
import { pushToDataLayer } from '../../public_site_utils/ecommerce';
import { Api as MockAPI } from '../mocked_api';
import * as i18next from 'i18next'
import { sign_up_form_privacy } from './sign_up_form_privacy';
import { notifications } from '../notifications';

let can_sign_up = <a>(s0: SignUpState, api?: Api<a>): boolean => {
  return !(s0.login_data.name == "" ||
    s0.login_data.name == undefined ||
    s0.login_data.surname == "" ||
    s0.login_data.surname == undefined ||
    s0.login_data.username == "" ||
    s0.login_data.username == undefined ||
    s0.login_data.password == undefined ||
    // Api.scorePassword(s0.login_data.password).res != "strong" ||
    s0.login_data.email == undefined ||
    !(api && api.scorePassword && api.isValidEmail(s0.login_data.email)) ||
    s0.login_data.email != s0.login_data.confirmed_email ||
    s0.login_data.password != s0.login_data.confirmed_password)
}

export let sign_up_form = <a>(s0: SignUpState, readonly: boolean, rootpath: string, api?: Api<a>): Widget<Action<LoginState<a>>> =>
  div<Action<LoginState<a>>>({ key: "sign-up", className: "login" })(
    div<Action<LoginState<a>>>({ key: "sign-up", className: "login__inner" })([
      hn<Action<LoggedOutState>>(1, i18next.t('signup_form:create_account'), { className: "login__title title--l" })(),
      div<Action<LoginState<a>>>({ key: "login-widget-sign-up-form-inner-any-0", className: "field" })([
        label<Action<SignUpState>>(i18next.t('signup_form:first_name'), { key: "login-widget-sign-up-form-inner-any-0-label", htmlFor: "name", label_position: "before", className: "label" })(
          div<Action<SignUpState>>()(
            string({ readonly, readonly_element: "input", id: "name", type: "text", className: `${s0.login_data.name == "" ? "input--error" : ""}`})(s0.login_data.name!)
              .map<Action<SignUpState>>(x => s => ({ ...s, status: "sign up", login_data: { ...s.login_data, name: x } }))
          )).map<Action<LoginState<a>>>(a => s => isSignUpState(s) ? a(s) : s),
        onlyIf<LoginState<a>>(s0.login_data.name == "",
          div<LoginState<a>>({ key: "login-widget-sign-up-form-inner-any-0-missing-name", className: "inline-error" })(
            string({ readonly: true, readonly_element: "span" })(i18next.t('signup_form:fill_in_first_name')).never()
          )
        ).never()
      ]),
      div<Action<LoginState<a>>>({ className: "field", key: "login-widget-sign-up-form-div-1" })(
        any<Action<LoginState<a>>>({ key: "login-widget-sign-up-form-inner-any-1" })([
          label<Action<SignUpState>>(i18next.t('signup_form:last_name'), { key: "login-widget-sign-up-form-inner-any-1-label", htmlFor: "surname", label_position: "before", className: "label" })(
            div<Action<SignUpState>>()(
              string({ readonly, readonly_element: "input", type: "text", id: "surname", className: `${s0.login_data.surname == "" ? "input--error" : ""}` })(s0.login_data.surname!)
                .map<Action<SignUpState>>(x => s => ({ ...s, status: "sign up", login_data: { ...s.login_data, surname: x } }))
            )).map<Action<LoginState<a>>>(a => s => isSignUpState(s) ? a(s) : s),
          onlyIf<LoginState<a>>(s0.login_data.surname == "",
            div<LoginState<a>>({ key: "login-widget-sign-up-form-inner-any-1-missing-surname", className: "inline-error" })(
              string({ readonly: true, readonly_element: "span" })(i18next.t('signup_form:fill_in_last_name')).never()
            )
          ).never()
        ])
      ),
      div<Action<LoginState<a>>>({ key: "login-widget-sign-up-form-div-3", className: "field" })(
        label<Action<LoginState<a>>>(i18next.t('signup_form:email'), { key: "login-widget-sign-up-form-email-label", htmlFor: "email", label_position: "before", className: "label" })(
          any<Action<LoginState<a>>>({ key: "login-widget-sign-up-form-inner-any-3" })([
            div<Action<SignUpState>>({ key: "login-widget-sign-up-form-inner-any-3-div" })(
              string({ readonly, readonly_element: "input", type: "email", id: "email", className: `${s0.login_data.email != undefined && !(api && api.scorePassword && api.isValidEmail(s0.login_data.email)) ? "input--error" : ""}` })(s0.login_data.email!)
                .map<Action<SignUpState>>(x => s => ({ ...s, status: "sign up", login_data: { ...s.login_data, email: x, username: x } }))
            ).map<Action<LoginState<a>>>(a => s => isSignUpState(s) ? a(s) : s),
            onlyIf<LoginState<a>>(s0.login_data.email != undefined && !(api && api.scorePassword && api.isValidEmail(s0.login_data.email)),
              div<LoginState<a>>({ key: "login-widget-sign-up-form-inner-any-3-invalid-email", className: "inline-error" })(
                string({ readonly: true, readonly_element: "span" })(i18next.t('signup_form:fill_in_email')).never()
              )
            ).never()
          ])
        )),
      div<Action<LoginState<a>>>({ key: "login-widget-sign-up-form-div-4", className: "field" })(
        label<Action<LoginState<a>>>(i18next.t('signup_form:confirm_email'), { key: "login-widget-sign-up-form-not-matching-email-label", htmlFor: "confirm-email", label_position: "before", className: "label" })(
          any<Action<LoginState<a>>>({ key: "login-widget-sign-up-form-inner-any-4" })([
            div<Action<SignUpState>>({ key: "login-widget-sign-up-form-inner-any-4-div" })(
              string({ readonly, readonly_element: "input", type: "email", id: "confirm-email", className: `${s0.login_data.email != undefined && s0.login_data.confirmed_email != s0.login_data.email ? "input--error" : ""}` })(s0.login_data.confirmed_email!)
                .map<Action<SignUpState>>(x => s => ({ ...s, status: "sign up", login_data: { ...s.login_data, confirmed_email: x } }))
            ).map<Action<LoginState<a>>>(a => s => isSignUpState(s) ? a(s) : s),
            onlyIf<LoginState<a>>(s0.login_data.email != undefined && s0.login_data.confirmed_email != s0.login_data.email,
              div<LoginState<a>>({ key: "login-widget-sign-up-form-inner-any-4-not-matching-email", className: "inline-error" })(
                string({ readonly: true, readonly_element: "span" })(i18next.t('signup_form:email_not_the_same')).never()
              )
            ).never()
          ])
        )),
      div<Action<LoginState<a>>>({ key: "login-widget-sign-up-form-div-5", className: "field" })(
        label<Action<LoginState<a>>>(i18next.t('signup_form:password'), { key: "login-widget-sign-up-form-password-score-label", htmlFor: "password", label_position: "before", className: "label" })(
          any<Action<LoginState<a>>>({ key: "login-widget-sign-up-form-inner-any-5" })([
            div<Action<SignUpState>>({ key: "login-widget-sign-up-form-inner-any-5-div" })(
              string({ readonly, readonly_element: "input", id: "password", className: `form-control ${s0.login_data.password != undefined && MockAPI.scorePassword(s0.login_data.password).res != "strong" ? 'input--error' : ''}`, type: "password" })(s0.login_data.password!)
                .map<Action<SignUpState>>(x => s => ({ ...s, status: "sign up", login_data: { ...s.login_data, password: x } }))
            ).map<Action<LoginState<a>>>(a => s => isSignUpState(s) ? a(s) : s),
            onlyIf<LoginState<a>>(s0.login_data.password != undefined && MockAPI.scorePassword(s0.login_data.password).res != "strong",
              div<LoginState<a>>({ key: "login-widget-sign-up-form-inner-any-5-score-password", className:"inline-error" })(
                string({ readonly:true, readonly_element: "span" })(
                  MockAPI.scorePassword(s0.login_data.password!).res === 'weak'
                    ? i18next.t('signup_form:password_too_weak')
                    : MockAPI.scorePassword(s0.login_data.password).res === 'almost good'
                      ? i18next.t('signup_form:password_almost_good')
                      : '' ).never()
              )
            ).never()
          ])
        )),
      div<Action<LoginState<a>>>({ key: "login-widget-sign-up-form-div-6", className: "field" })(
        label<Action<LoginState<a>>>(i18next.t('signup_form:confirm_password'), { key: "login-widget-sign-up-form-div-6-label", htmlFor: "confirm-password", label_position: "before", className: "label" })(
          any<Action<LoginState<a>>>({ key: "login-widget-sign-up-form-inner-any-6" })([
            div<Action<SignUpState>>({ key: "login-widget-sign-up-form-inner-any-6-div" })(
              string({ readonly, readonly_element: "input", id: "confirm-password", className: `${s0.login_data.password != "" && s0.login_data.confirmed_password != s0.login_data.password ? "input--error" : ""}`, type: "password" })(s0.login_data.confirmed_password!)
                .map<Action<SignUpState>>(x => s => ({ ...s, status: "sign up", login_data: { ...s.login_data, confirmed_password: x } }))
            ).map<Action<LoginState<a>>>(a => s => isSignUpState(s) ? a(s) : s),
            onlyIf<LoginState<a>>(s0.login_data.password != '' && s0.login_data.confirmed_password != s0.login_data.password,
              div<LoginState<a>>({ key: "login-widget-sign-up-form-inner-any-6-not-matching-password", className: "inline-error" })(
                string({ readonly: true, readonly_element: "span" })(i18next.t('signup_form:password_not_the_same')).never()
              )
            ).never()
          ])
        )),
      div<Action<LoginState<a>>>({ key: "login-widget-sign-up-form-div-8", className: "field checkbox text--styled" })(
        sign_up_form_privacy(s0)
      ),
      div<Action<LoginState<a>>>({ className: "text--styled login__wrong-credentials inline-error" })(
        notifications(s0).never()
      ),
      div<Action<LoginState<a>>>({ key: "login-widget-sign-up-form-div-9", className: "" })(
        any<Action<LoginState<a>>>({ key: "login-widget-sign-up-form-inner-any-7" })([
          div<Action<SignUpState>>({ key: "login-widget-sign-up-form-inner-any-7-div" })(
            button<Action<SignUpState>>(i18next.t('signup_form:register'),
              { className: "button button--primary button--full-width", disabled: !s0.login_data.agreed })
              (() => s => {
                pushToDataLayer({
                  event: "event-beren",
                  category: "Registreren",
                  action: "verzend",
                  label: "formulier",
                })
                return (
                  can_sign_up(s0, api)
                  ? ({ ...s, status: "sign up - signing up", login_data: s0.login_data })
                  : {
                    ...s0, login_data: {
                      ...s0.login_data, username: s0.login_data.username == undefined ? "" : s0.login_data.username,
                      name: s0.login_data.name == undefined ? "" : s0.login_data.name,
                      surname: s0.login_data.surname == undefined ? "" : s0.login_data.surname,
                      email: s0.login_data.email == undefined ? "" : s0.login_data.email,
                      password: s0.login_data.password == undefined ? "" : s0.login_data.password
                    }
                  }
                )
              })
            ).map<Action<LoginState<a>>>(a => s => isSignUpState(s) ? a(s) : s),
            div<Action<LoginState<a>>>({ className: "login__link text--styled" })([
              string({ readonly: true, readonly_element: "p" })(i18next.t('signup_form:do_you_already_have_an_account')).never(),
              link(i18next.t('signup_form:login'), rootpath, { key: "sign-in" }).never()
            ]),
          ])
        )
      ])
    ).map<Action<LoginState<a>>>(a => s => isSignUpState(s) && !readonly ? a(s) : s)
