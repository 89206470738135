import { List } from "immutable";
import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import { Action, AsyncState, mk_widget, Widget } from "widgets-for-react";
import { ParsedMenuItem } from "../drupal/drupal_utils/types";
import { RestaurantList } from "../drupal_api";
import * as i18next from "i18next";
import { pushToDataLayer } from "./ecommerce";
import { state } from "../custom_portal/login";
import { PublicSiteState } from "../../public_site";

export type FooterState = {
  nav_links: AsyncState<List<ParsedMenuItem>>,
  show_buttons?: boolean
}
export const footer: (public_state: PublicSiteState) => (state: FooterState) => Widget<Action<FooterState>> = public_state => props => mk_widget({
  run: _ => (
    <footer className="footer">
      <section className="footer__cta">
        {props.show_buttons && <div className="footer__inner">
          <h2 className="title--l">{i18next.t("footer:experience_de_beren")}</h2>
          <a href="https://bestel.beren.nl/" className="button button--primary" target="_blank" rel="noopener noreferrer">
            {i18next.t("common:order_online")}
          </a>
          <Link to={'/reserveer'} className="button button--primary">{i18next.t("common:book_table")}</Link>
        </div>}
      </section>
      <div className="footer__social">
        <div className="footer__inner">
          <ul className="social">
            <li className="social__item">
              <a
                href="https://www.facebook.com/deberensinds1984/"
                target="_blank"
                className="social__link social__link--facebook"
                onClick={
                  () => pushToDataLayer({
                    event: "event-beren",
                    category: "Social media",
                    action: "button",
                    label: "Facebook",
                  })
                }
              >
                <svg className="social__link-icon" aria-hidden="true"><use xlinkHref="#icon--facebook" /></svg>
                <span className="hide--mobile">{i18next.t("footer:follow_facebook")}</span>
              </a>
            </li>
            <li className="social__item">
              <a
                href="https://www.instagram.com/deberen"
                target="_blank"
                className="social__link social__link--instagram"
                onClick={
                  () => pushToDataLayer({
                    event: "event-beren",
                    category: "Social media",
                    action: "button",
                    label: "Instagram",
                  })
                }
              >
                <svg className="social__link-icon" aria-hidden="true"><use xlinkHref="#icon--instagram" /></svg>
                <span className="hide--mobile">{i18next.t("footer:follow_instagram")}</span>
              </a>
            </li>
            <li className="social__item">
              <a
                href="http://www.youtube.com/channel/UCwcDcMt-JGWRYDs8EL5tjHA"
                target="_blank"
                className="social__link social__link--youtube"
                onClick={
                  () => pushToDataLayer({
                    event: "event-beren",
                    category: "Social media",
                    action: "button",
                    label: "YouTube",
                  })
                }
              >
                <svg className="social__link-icon" aria-hidden="true"><use xlinkHref="#icon--youtube" /></svg>
                <span className="hide--mobile">{i18next.t("footer:follow_youtube")}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer__main">
        <div className="footer__inner">
          <div className="footer__col footer__col--66">
            <h5 className="footer__title">{i18next.t("footer:restaurants")}</h5>
            <ul className="nav nav--footer nav--columns">
              {public_state.restaurant_list.kind === "loaded" &&
                public_state.restaurant_list.value.filter(r => r.dineIn == "1").map((restaurant, index) => (
                  <li className="nav__item" key={index}>
                    <Link to={restaurant.url} className="nav__link" dangerouslySetInnerHTML={{ __html: restaurant.title }} />
                  </li>
                ))
              }
            </ul>
          </div>
          <div className="footer__col footer__col--33">
            <h5 className="footer__title">{i18next.t("footer:deliver_restaurants")}</h5>
            <ul className="nav nav--footer">
              {public_state.restaurant_list.kind === "loaded" &&
                public_state.restaurant_list.value.filter(r => r.delivery == "1").map((restaurant, index) => (
                  <li className="nav__item" key={index}>
                    <Link to={restaurant.url} className="nav__link" dangerouslySetInnerHTML={{ __html: restaurant.title }} />
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
      <div className="footer__sub">
        <div className="footer__inner">
          <ul className="nav nav--subfooter">
            {props.nav_links.kind == "loaded" && (
              props.nav_links.value.map(l => (
                <li className="nav__item">
                  {l.external
                    ? <a className="nav__link" href={l.url} target="_blank">{l.label}</a>
                    : <NavLink className="nav__link" activeClassName="nav__link--active" to={l.url}>{l.label}</NavLink>
                  }
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
    </footer>
  )
})
