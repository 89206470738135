import * as React from "react"
import * as Leaflet from 'leaflet';
import 'leaflet.markercluster'
import 'leaflet-providers';
import { LeafletMapProps } from "./LeafletMap.State"
import { loader } from "../../public_site_utils/loader";
import * as i18next from "i18next"
import { RestaurantListItem } from "../../drupal_api";

const iconWidth = 35;
const markerDark = 'data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iNDUiIHZpZXdCb3g9IjAgMCA0NSA0NSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyLjUgMEMxNC43MzM1IDAgOC40Mzc1IDYuMjk1OTYgOC40Mzc1IDE0LjA2MjVDOC40Mzc1IDI4LjEyNSAyMi41IDQ1IDIyLjUgNDVDMjIuNSA0NSAzNi41NjI1IDI4LjEyNSAzNi41NjI1IDE0LjA2MjVDMzYuNTYyNSA2LjI5NTk2IDMwLjI2NjUgMCAyMi41IDBaTTIyLjUgMjIuNUMxNy44NCAyMi41IDE0LjA2MjUgMTguNzIyNSAxNC4wNjI1IDE0LjA2MjVDMTQuMDYyNSA5LjQwMjU0IDE3Ljg0IDUuNjI1IDIyLjUgNS42MjVDMjcuMTYgNS42MjUgMzAuOTM3NSA5LjQwMjU0IDMwLjkzNzUgMTQuMDYyNUMzMC45Mzc1IDE4LjcyMjUgMjcuMTYgMjIuNSAyMi41IDIyLjVaIiBmaWxsPSIjMUMzQjQyIi8+CjxwYXRoIGQ9Ik0yMi41IDIyLjVDMTcuODQgMjIuNSAxNC4wNjI1IDE4LjcyMjUgMTQuMDYyNSAxNC4wNjI1QzE0LjA2MjUgOS40MDI1NCAxNy44NCA1LjYyNSAyMi41IDUuNjI1QzI3LjE2IDUuNjI1IDMwLjkzNzUgOS40MDI1NCAzMC45Mzc1IDE0LjA2MjVDMzAuOTM3NSAxOC43MjI1IDI3LjE2IDIyLjUgMjIuNSAyMi41WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==';
const markerLight = 'data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iNDUiIHZpZXdCb3g9IjAgMCA0NSA0NSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyLjUgMEMxNC43MzM1IDAgOC40Mzc1IDYuMjk1OTYgOC40Mzc1IDE0LjA2MjVDOC40Mzc1IDI4LjEyNSAyMi41IDQ1IDIyLjUgNDVDMjIuNSA0NSAzNi41NjI1IDI4LjEyNSAzNi41NjI1IDE0LjA2MjVDMzYuNTYyNSA2LjI5NTk2IDMwLjI2NjUgMCAyMi41IDBaTTIyLjUgMjIuNUMxNy44NCAyMi41IDE0LjA2MjUgMTguNzIyNSAxNC4wNjI1IDE0LjA2MjVDMTQuMDYyNSA5LjQwMjU0IDE3Ljg0IDUuNjI1IDIyLjUgNS42MjVDMjcuMTYgNS42MjUgMzAuOTM3NSA5LjQwMjU0IDMwLjkzNzUgMTQuMDYyNUMzMC45Mzc1IDE4LjcyMjUgMjcuMTYgMjIuNSAyMi41IDIyLjVaIiBmaWxsPSIjQzA5NzRDIi8+CjxwYXRoIGQ9Ik0yMi41IDIyLjVDMTcuODQgMjIuNSAxNC4wNjI1IDE4LjcyMjUgMTQuMDYyNSAxNC4wNjI1QzE0LjA2MjUgOS40MDI1NCAxNy44NCA1LjYyNSAyMi41IDUuNjI1QzI3LjE2IDUuNjI1IDMwLjkzNzUgOS40MDI1NCAzMC45Mzc1IDE0LjA2MjVDMzAuOTM3NSAxOC43MjI1IDI3LjE2IDIyLjUgMjIuNSAyMi41WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==';
const markerMixed = 'data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iNDUiIHZpZXdCb3g9IjAgMCA0NSA0NSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyLjUgMEMxNC43MzM1IDAgOC40Mzc1IDYuMjk1OTYgOC40Mzc1IDE0LjA2MjVDOC40Mzc1IDI4LjEyNSAyMi41IDQ1IDIyLjUgNDVDMjIuNSA0NSAzNi41NjI1IDI4LjEyNSAzNi41NjI1IDE0LjA2MjVDMzYuNTYyNSA2LjI5NTk2IDMwLjI2NjUgMCAyMi41IDBaTTIyLjUgMjIuNUMxNy44NCAyMi41IDE0LjA2MjUgMTguNzIyNSAxNC4wNjI1IDE0LjA2MjVDMTQuMDYyNSA5LjQwMjU0IDE3Ljg0IDUuNjI1IDIyLjUgNS42MjVDMjcuMTYgNS42MjUgMzAuOTM3NSA5LjQwMjU0IDMwLjkzNzUgMTQuMDYyNUMzMC45Mzc1IDE4LjcyMjUgMjcuMTYgMjIuNSAyMi41IDIyLjVaIiBmaWxsPSIjQzA5NzRDIi8+CjxwYXRoIGQ9Ik0yMi41IDBDMTQuNzMzNSAwIDguNDM3NSA2LjI5NTk2IDguNDM3NSAxNC4wNjI1QzguNDM3NSAyOC4xMjUgMjIuNSA0NSAyMi41IDQ1VjIyLjVDMTcuODQgMjIuNSAxNC4wNjI1IDE4LjcyMjUgMTQuMDYyNSAxNC4wNjI1QzE0LjA2MjUgOS40MDI1NCAxNy44NCA1LjYyNSAyMi41IDUuNjI1VjBaIiBmaWxsPSIjMUMzQjQyIi8+CjxwYXRoIGQ9Ik0yMi41IDIyLjVDMTcuODQgMjIuNSAxNC4wNjI1IDE4LjcyMjUgMTQuMDYyNSAxNC4wNjI1QzE0LjA2MjUgOS40MDI1NCAxNy44NCA1LjYyNSAyMi41IDUuNjI1QzI3LjE2IDUuNjI1IDMwLjkzNzUgOS40MDI1NCAzMC45Mzc1IDE0LjA2MjVDMzAuOTM3NSAxOC43MjI1IDI3LjE2IDIyLjUgMjIuNSAyMi41WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==';

const iconDark = Leaflet.icon({
  iconUrl: markerDark,
  iconSize: [iconWidth, iconWidth],
})

const iconLight = Leaflet.icon({
  iconUrl: markerLight,
  iconSize: [iconWidth, iconWidth],
})

const iconMixed = Leaflet.icon({
  iconUrl: markerMixed,
  iconSize: [iconWidth, iconWidth],
})

type RestaurantFilter = keyof Pick<RestaurantListItem, 'delivery' | 'dineIn' | 'pickUp'> | 'all'

interface State {
  view: "map" | "list"
  filter: RestaurantFilter
  markers: Leaflet.MarkerClusterGroup
}
export class LeafletMapLayout extends React.Component<LeafletMapProps, State> {
  mapRef: HTMLDivElement
  map: Leaflet.Map

  constructor(props: LeafletMapProps) {
    super(props)
    this.state = {
      view: 'list',
      filter: 'all',
      markers: this.initialMarkClusterGroup()
    }
    this.mapRef = null
    this.map = null

    this.initMap = this.initMap.bind(this)
    this.placeMapMarkers = this.placeMapMarkers.bind(this)
    this.toggleView = this.toggleView.bind(this)
    this.destroyMap = this.destroyMap.bind(this)
    this.filterRestaurant = this.filterRestaurant.bind(this)
  }

  initialMarkClusterGroup() {
    return Leaflet.markerClusterGroup({
      showCoverageOnHover: false,
      zoomToBoundsOnClick: true,
      removeOutsideVisibleBounds: true,
    })
  }

  initMap() {
    if (this.state.view == 'map') {
      this.destroyMap() // To make sure the map isn't initilized twice
      let center = Leaflet.latLng(52.187, 5.070)
      let zoom = 9
      this.map = Leaflet.map(this.mapRef)

      let map = this.map
        .setView(center, zoom, { animate: false })

      Leaflet.tileLayer.provider('CartoDB.Positron').addTo(map)

    }
  }

  destroyMap() {
    if (this.map != null) {
      this.map.remove()
      this.map = null
      this.mapRef = null
      this.setState(s => ({ ...s, markers: this.initialMarkClusterGroup() }))
    }
  }

  placeMapMarkers() {
    if (this.props.restaurant_list.kind == 'loaded') {
      let newMarkers = this.props.restaurant_list.value
        .filter(this.filterRestaurant)
        .reduce((markers, r) => {
          // there are also a few restaurants that have no classification: dineIn, delivery, or pickUp
          // these default to regular icons
          let iconType = iconLight;
          if (r.delivery == '1') { iconType = iconDark }
          if (r.delivery == '1' && r.dineIn == '1') { iconType = iconMixed; }

          let types = []
          if (r.dineIn == "1") types.push(i18next.t("common:restaurant"))
          if (r.delivery == "1") types.push(i18next.t("common:deliver"))
          if (r.pickUp == "1") types.push(i18next.t("common:pickup"))

          let tags = types.length > 0 ? (
            types.map((el, k) => (
              `${k > 0 ? " " : ""}${el}`
            ))) : ''

          // The default is the headquarters in Rotterdam
          let pos = Leaflet.latLng(r.field_latitude == "" || isNaN(Number(r.field_latitude)) ? 51.9206999 : Number(r.field_latitude), r.field_longitude == "" || isNaN(Number(r.field_longitude)) ? 4.4748636 : Number(r.field_longitude))
          markers = markers.addLayer(
            Leaflet.marker(pos, { icon: iconType })
              .bindPopup(`
              <h3 class="leaflet-popup__title">${r.title}</h3>
              <span class="leaflet-popup__tags">
                ${tags}
              </span>
              <ul class="leaflet-popup__list">
                ${r.field_street_and_house_number ? `<li>${r.field_street_and_house_number}</li>` : ''}
                ${(r.field_postal_code || r.field_city) ? `<li>${r.field_postal_code} ${r.field_city}</li>` : ''}
                ${r.telephoneNumber ? `<li><a href="tel:${r.telephoneNumber}">${r.telephoneNumber}</a></li>` : ''}
                ${r.emailAddress ? `<li><a href="mailto:${r.emailAddress}">${r.emailAddress}</a></li>` : ''}
                ${r.field_route ? `<li><a target="_blank" href="${r.field_route}">Bekijk route</a></li>` : ''}
              </ul>
              <a class="leaflet-popup__button" href="${r.url}">Meer info</a>
          `)
          )

          return markers
        }, this.state.markers)
        
      newMarkers.addTo(this.map)
      this.setState(s => ({ ...s, markers: newMarkers }))
    }
  }

  componentDidUpdate(prevProps: Readonly<LeafletMapProps>, prevState: Readonly<State>, snapshot?: any): void {
    if (this.state.view == 'map' && prevState.view == 'list') {
      this.initMap()
      this.placeMapMarkers()
    }

    if (this.state.view == 'list' && prevState.view == 'map') {
      this.destroyMap()
    }

    if (this.state.view == 'map' && this.state.filter != prevState.filter) {
      this.map.removeLayer(this.state.markers)
      setTimeout(() => this.placeMapMarkers(), 100)
      this.setState(s => ({ ...s, markers: this.initialMarkClusterGroup() }))
    }
  }

  componentWillUnmount(): void {
    this.destroyMap()
  }

  componentDidMount(): void {
    this.initMap()
  }

  toggleView() {
    this.setState(s => ({ ...s, view: s.view == 'list' ? 'map' : 'list' }))
  }

  filterRestaurant(r: RestaurantListItem): boolean {
    if (this.state.filter == 'all') return true
    return r[this.state.filter] == '1'
  }

  render(): React.ReactNode {
    return (
      <section className={`block block--locations ${this.state.view == 'map' ? 'block--map-view' : ''}`}>
        <div className="block__inner filter">
          <h3 className="filter__title title--m">{i18next.t("locations:show_restaurants")}</h3>
          <ul className="filter__list">
            <li>
              <button onClick={() => this.setState(s => ({ ...s, filter: 'dineIn' }))} className={`button button--${this.state.filter == 'dineIn' ? 'primary' : 'secondary'}`}>{i18next.t("locations:restaurant")}</button>
            </li>
            <li>
              <button onClick={() => this.setState(s => ({ ...s, filter: 'delivery' }))} className={`button button--${this.state.filter == 'delivery' ? 'primary' : 'secondary'}`}>{i18next.t("locations:deliver")}</button>
            </li>
            <li>
              <button onClick={() => this.setState(s => ({ ...s, filter: 'pickUp' }))} className={`button button--${this.state.filter == 'pickUp' ? 'primary' : 'secondary'}`}>{i18next.t("locations:pickup")}</button>
            </li>
            <li>
              <button onClick={() => this.setState(s => ({ ...s, filter: 'all' }))} className={`button button--${this.state.filter == 'all' ? 'primary' : 'secondary'}`}>{i18next.t("locations:all")}</button>
            </li>
          </ul>
        </div>

        <div className="block__inner">
          <div className="locations">
            <div className="locations__toggler">
              <div className={`locations__switch ${this.state.view == 'map' ? 'locations__switch--right' : 'locations__switch--left'}`}>
                <button className={`locations__btn ${this.state.view == 'list' ? 'locations__btn--active' : ''}`} onClick={this.toggleView}>Lijst</button>
                <button className={`locations__btn ${this.state.view == 'map' ? 'locations__btn--active' : ''}`} onClick={this.toggleView}>Kaart</button>
              </div>
            </div>
            {
              this.state.view == 'list' ?
                <div className="locations__list-container">
                  {
                    this.props.restaurant_list.kind == 'loaded' ?
                      <>
                        <ul className="overview">
                          {
                            this.props.restaurant_list.value
                              .filter(this.filterRestaurant)
                              .toArray()
                              .map(r => {
                                let types: JSX.Element[] = []
                                if (r.dineIn == "1") types.push(<span>{i18next.t("common:restaurant")}</span>)
                                if (r.delivery == "1") types.push(<span>{i18next.t("common:deliver")}</span>)
                                if (r.pickUp == "1") types.push(<span>{i18next.t("common:pickup")}</span>)
                                return (
                                  <li className="locations__item" key={r.id}>
                                    <a className="overview-item__link overview-item__columns" href={`${r.url}`}>
                                      <div className="overview-item__column">
                                        <svg className="overview-item__link-icon" aria-hidden="true"><use xlinkHref="#icon--caret-right" /></svg>
                                        <h2 className="title--xs overview-item__title">{r.title}</h2>
                                        <p className="text--xs">
                                          {types.length > 0 ? (
                                            types.map((el, k) => (
                                              <>
                                                {k > 0 ? ", " : ""}
                                                {el}
                                              </>
                                            ))
                                          ) : null}
                                        </p>
                                      </div>
                                      <div className="overview-item__column">
                                        <p className="overview-item__text">
                                          {r.telephoneNumber ? r.telephoneNumber : '-'}
                                        </p>
                                      </div>
                                    </a>
                                  </li>
                                )
                              })
                          }
                        </ul>
                      </>
                      : loader.run(_ => _)
                  }
                </div>
                :
                <>
                  <div className="locations__outer-container">
                    <div className="locations__inner-container">
                      <div id="map" className="locations__map"
                        ref={(mapDiv) => {
                          this.mapRef = mapDiv
                        }}
                      />
                      <div className="locations__legend legend">
                        <ul className="legend__list">
                          <li className="legend__item">
                            <img className="legend__icon" src={markerLight} />
                             Restaurant
                          </li>
                          <li className="legend__item">
                            <img className="legend__icon" src={markerDark} />
                             Bezorg/afhalen
                          </li>
                          <li className="legend__item">
                            <img className="legend__icon" src={markerMixed} />
                            Restaurant + Bezorg/afhalen
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
            }
          </div>
        </div>
      </section>
    )
  }
}