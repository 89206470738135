import { Map, List } from "immutable"
import { AsyncState, HttpResult, loadingAsyncState, mk_pair, Pair } from "widgets-for-react"
import { ImageSize, Url } from "../../widget_blocks/types"
import { PairAsyncImage } from "../../widget_blocks_client/types/images";
import { Block } from "./types";
import * as UriJS from "uri-js"
import { ParsedFileImage, ParsedStyles } from "../drupal_generated/types";
import { isCustomBlockData, CustomBlock, isCustomBlock } from "../../public_site_utils/custom_blocks_types";
import { ExtraImages } from "../../public_site_utils/extra_images";


export const parseImageUrl = (s: string): string =>
  `/drupal/media${UriJS.parse(s).path}${UriJS.parse(s).query ? "?" + UriJS.parse(s).query : ""}`

export function loadMedia<extra_images>(image: ParsedFileImage): AsyncState<Map<ImageSize<extra_images>, Url>>{//unloadedAsyncState()
  return loadingAsyncState<Map<ImageSize<extra_images>, Url>>(async _ => {
    const sizes = image.style_urls

    let res = Map<ImageSize<extra_images>, Url>()
      .set("full", parseImageUrl(image.url))

    let image_styles: ImageSize<extra_images>[] = [
      'crop_thumbnail',
      'grid_400x200',
      'header_1440x500',
      'header_1440x670',
      'large',
      'media_880w',
      'medium',
      'thumbnail',
      'person_140x140',
      "product_small",
      'grid_with_image_750_375',
      // Used only on Campaigns post type
      'w1320',
      'w1440',
      'w2160',
      'w2880',
      'w720',
      'w660',
      'w1980',
      "1280x640",
      "480x240",
      "640x320",
      "960x480"
    ]

    for (let style of image_styles) {
      if (sizes[style as keyof ParsedStyles]) {
        res = res.set(style as ImageSize<extra_images>, parseImageUrl(sizes[style as keyof ParsedStyles]))
      }
    }

    return {
      kind: "result",
      status: 200,
      value: res,
    }
  })
}

export const GetDrupalBlockImagesLoaders = (
  block: Block|CustomBlock,
  mk_block?: (b: CustomBlock) => Array<PairAsyncImage<ExtraImages>>
): Array<Pair<number, AsyncState<Map<ImageSize<ExtraImages>, Url>>>> => {
  switch (block.kind) {
    case "featured_content":
      return [
        mk_pair(block.field_image.field_media_image.image.id, loadMedia(block.field_image.field_media_image.image) as AsyncState<Map<ImageSize<ExtraImages>, Url>>)
      ]
    case "grid_blocks_image":
      return block.field_grid_blocks.map(b =>
        mk_pair(b.field_image.field_media_image.image.id, loadMedia(b.field_image.field_media_image.image) as AsyncState<Map<ImageSize<ExtraImages>, Url>>)
      ).toArray()
    case "campaign_grid":
      return block.field_grid_blocks.map(b =>
        mk_pair(b.field_image.field_media_image.image.id, loadMedia(b.field_image.field_media_image.image) as AsyncState<Map<ImageSize<ExtraImages>, Url>>)
      ).toArray()
    case "media":
      return [
        mk_pair(block.field_media.bundle === "image" ? block.field_media.field_media_image.image.id : null, loadMedia(block.field_media.bundle === "image" ? block.field_media.field_media_image.image : null) as AsyncState<Map<ImageSize<ExtraImages>, Url>>)
      ]
    case "campaign_media":
      return [
        mk_pair(block.field_media.bundle === "image" ? block.field_media.field_media_image.image.id : null, loadMedia(block.field_media.bundle === "image" ? block.field_media.field_media_image.image : null) as AsyncState<Map<ImageSize<ExtraImages>, Url>>)
      ]
    case "quote":
      return block.field_image? [
         mk_pair(block.field_image.bundle === "image" ? block.field_image.field_media_image.image.id : null, loadMedia(block.field_image.bundle === "image" ? block.field_image.field_media_image.image : null) as AsyncState<Map<ImageSize<ExtraImages>, Url>>)
      ] : []
    case "cta_with_image":
      return [
          mk_pair(block.field_image ? block.field_image.field_media_image.image.id : null, loadMedia(block.field_image.bundle === "image" ? block.field_image.field_media_image.image : null) as AsyncState<Map<ImageSize<ExtraImages>, Url>>)
      ]
    case "menu":
      const menuImages = []
      block.field_menu_groups.forEach(group =>
        group.field_menu_items.forEach(item =>
            item.field_image ?
              menuImages.push(mk_pair(item.field_image.bundle === "image" ? item.field_image.field_media_image.image.id : null, loadMedia(item.field_image.bundle === "image" ? item.field_image.field_media_image.image : null) as AsyncState<Map<ImageSize<ExtraImages>, Url>>))
            : null
        )
      )
      return menuImages
    default:
      if (mk_block && isCustomBlock(block)) {
        return mk_block(block)
      }

      return []
  }
}
