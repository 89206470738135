import * as React from "react"
import { Action, IOWidget, mk_widget } from "widgets-for-react"
import { ExtraImages } from "../public_site_utils/extra_images"
import { ImageContainerExtended, ImageSrcsetProps } from "./ImageCampaignContainer"
import { CMSState } from "./state"
import { ImageSize, Url } from "./types"
import { link } from "./utils"

export type CtaImageProps = {
  id: number
  size: ImageSize<ExtraImages>
  srcsetSize: ImageSrcsetProps<ExtraImages>[]
  alt: string
  width: string
  height: string
  lazyload: boolean
}

export type CtaImageCampaignBlockProps = {
  title: string
  intro: string
  title_options?: {
    size: string,
    font: string
  }
  text_options?: {
    size: string,
    font: string
  }
  buttons: "none" | "primary" | "primary_secondary"
  primary_button?: ({
    kind: "link"
    is_link_external: boolean
    target: "_blank" | "_self"
    url: Url
  }) & { title: string }
  secondary_button?: ({
    kind: "link"
    is_link_external: boolean
    target: "_blank" | "_self"
    url: Url
  }) & { title: string }
  width: boolean
  direction: 'left' | 'right' | 'center'
  image?: CtaImageProps
}

export type CtaImageCampaignBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
  content_classname: string
  header_classname: string
  title_classname: string
  body_classname: string
  text_classname: string
  footer_classname: string
  clickable_primary_classname: string
  clickable_secondary_classname: string
  visual_classname: string
  image_classname: string
}>

const getClassName = (
  options: { style?: CtaImageCampaignBlockStyle },
  className: keyof CtaImageCampaignBlockStyle,
  notset: string = ""
): string =>
  options && options.style && (options.style[className] || options.style[className] == null) ? options.style[className] : notset

export function CtaImageCampaignBlock<CustomBlockData>(
  props: CtaImageCampaignBlockProps,
  options?: { style?: CtaImageCampaignBlockStyle; key?: string }
): IOWidget<CMSState<CustomBlockData, ExtraImages>, Action<CMSState<CustomBlockData, ExtraImages>>> {
  return (s: CMSState<CustomBlockData, ExtraImages>) =>
    mk_widget({
      run: _ => (
        <section className={getClassName(options, "block_classname", "container")} key={options && options.key ? options.key : "cta-block"}>
          <div className={getClassName(options, "inner_classname", "py-5")}>
            <div className={getClassName(options, "content_classname", "")}>
              { props.title &&
                <header className={getClassName(options, "header_classname", "")}>
                  <h2
                    className={getClassName(options, "title_classname", "text-center mb-3")}
                    dangerouslySetInnerHTML={{ __html: props.title }}
                  />
                </header>
              }

              { props.intro &&
                <div className={getClassName(options, "body_classname", "")}>
                  <div
                    className={getClassName(options, "text_classname", "lead text-center")}
                    dangerouslySetInnerHTML={{ __html: props.intro }}
                  />
                </div>
              }

              {props.buttons !== "none"
                ? <footer className={getClassName(options, "footer_classname", "text-center")}>
                    {props.primary_button && props.primary_button.title && props.primary_button.url
                      ? link(props.primary_button,
                            {classname: getClassName(options, "clickable_primary_classname", "button mr-2" )},
                            props.primary_button.title)
                      : null
                    }
                    {props.secondary_button && props.secondary_button.title && props.secondary_button.url && props.buttons === "primary_secondary"
                      ? link(props.secondary_button,
                            {classname: getClassName(options, "clickable_secondary_classname", "button button-link" )},
                            props.secondary_button.title)
                      : null
                    }
                  </footer>
                : null
              }
            </div>

            { props.image && (
              <div className={getClassName(options, "visual_classname", "")}>
                {ImageContainerExtended({
                  url: s.images.get(props.image.id),
                  srcSize: props.image.size,
                  srcsetSize: props.image.srcsetSize,
                  extraClasses: getClassName(options, "image_classname", "w-100"),
                  alt: props.image.alt,
                  width: props.image.width,
                  height: props.image.height,
                  lazyload: props.image.lazyload
                }).run(_ => {})}
              </div>
            )}
          </div>
        </section>
      ),
    })
}