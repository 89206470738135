import * as React from "react"
import { Widget, mk_widget } from "widgets-for-react"

export const styleguideAbout: Widget<never> = mk_widget({
  run: _ => (
    <>
      <section id="about" className="styleguide-chapter styleguide-chapter--about">
        <header className="styleguide--styled styleguide-chapter__header">
          <h2 className="styleguide__title styleguide-chapter__title">About</h2>
        </header>

        <div className="styleguide-chapter__content styleguide-chapter__content--plain">
          <p className="styleguide-chapter__intro">Maecenas sed diam eget risus varius blandit sit amet non magna. Nullam quis risus eget urna mollis ornare vel eu leo. Maecenas faucibus mollis interdum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</p>
          <p className="styleguide-chapter__intro">Sed posuere consectetur est at lobortis. Vestibulum id ligula porta felis euismod semper. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Donec id elit non mi porta gravida at eget metus.</p>
        </div>
      </section>
    </>
  )
})
