import { ActivateAccountState, LoginState, isActivateAccountState } from '../state';
import { Action, Widget, any, div, string, button, link, hn } from "widgets-for-react";
import i18next = require('i18next');

export let activate_account_form = <a>(s0: ActivateAccountState, rootpath: string, readonly: boolean): Widget<Action<LoginState<a>>> =>
  any<Action<LoginState<a>>>({ key: "activate-account-form" })([
    div<Action<LoginState<a>>>({ className: "login", key: "login-widget-activate-account-action-div" })(
      div<Action<LoginState<a>>>({ className: "login__inner", key: "login-widget-activate-account-action-div-inner" })([
        hn<Action<LoginState<a>>>(1, i18next.t('activate_account_form:activate_account'), { className: "login__title title--l" })(),
        string({ readonly: true, readonly_element: "p", key: "verify-activation-token-disclaimer" })(i18next.t('activate_account_form:activate_text', { email: s0.login_data.email })).never(),
        div<Action<LoginState<a>>>()(
          button<Action<LoginState<a>>>(i18next.t('activate_account_form:activate_button'),
            { className: "button button--primary button--full-width", readonly })
            (() => s =>
              s0.login_data.token != undefined && s0.login_data.token != "" &&
                s0.login_data.email != undefined && s0.login_data.email != "" ? ({ status: "activate account - activating", login_data: s0.login_data })
                : ({ ...s0, login_data: { ...s0.login_data, token: s0.login_data.token == undefined ? "" : s0.login_data.token, email: s0.login_data.email == undefined ? "" : s0.login_data.email } }))
        ).map<Action<LoginState<a>>>(a => s => isActivateAccountState(s) ? a(s) : s),
        div<Action<LoginState<a>>>({ className: "login__link text--styled" })([
          string({ readonly: true, readonly_element: "p" })(i18next.t('activate_account_form:activate_account_form')).never(),
          link(i18next.t('activate_account_form:login'), rootpath, { key: "sign-in" }).never()
        ]),
      ]))
  ]).map<Action<LoginState<a>>>(a => s => isActivateAccountState(s) ? a(s) : s)
