import * as React from "react"
import { Link as LinkComponent, NavLink } from "react-router-dom"

type Props = {
  type: "link"
  [key: string]: any
  to: string
  children?: any
  onClick?: () => void
} | {
  type: "navlink"
  [key: string]: any
  to: string
  children?: any
  onClick?: () => void
  activeClassName: string
  
}

/*
This is a link that will automatically determine if it needs to be an <a> <Link> or <NavLink>
 */
export const Link = (props: Props) => {
  let linkProps = { ...props }
  delete linkProps.to
  delete linkProps.children
  delete linkProps.type
  props.type === "navlink" ? delete linkProps.activeClassName : null
  
  return /^https?:\/\//.test(props.to) ? (
    <a {...linkProps} href={props.to} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  ) : /.pdf$/.test(props.to) ||
  /.docx?$/.test(props.to) ||
  /.pptx?$/.test(props.to) ||
  /.xlsx?$/.test(props.to) ? (
    <a {...linkProps} href={props.to} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  ) :  props.type === "link" ? (
    <LinkComponent {...linkProps} to={props.to}>
      {props.children}
    </LinkComponent>) : (<NavLink {...linkProps} to={props.to} activeClassName={props.activeClassName}>{props.children}</NavLink>)
}