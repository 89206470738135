import * as React from "react"
import { List } from "immutable"
import { Link } from "react-router-dom";
import { Breadcrumb } from "../drupal/drupal_utils/types";

export const Breadcrumbs = (props: { breadcrumbs: List<Breadcrumb> }) => {
  let { breadcrumbs } = props

  return (
    <section className="breadcrumb" aria-label="Breadcrumb">
      <div className="breadcrumb__inner">
        <ol className="breadcrumb__items">
          {breadcrumbs.map((b, k) => (
            <li key={`br-${k}`} className="breadcrumb__item">
              <Link to={b.url} className="breadcrumb__link" title={b.title}>{b.title}</Link>
            </li>
          ))}
        </ol>
      </div>
    </section>
  )
}
