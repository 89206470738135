import * as React from "react"
import { mk_widget, stateful, any, Action, AsyncState, async, nothing } from "widgets-for-react";
import { order_flow_state, order_flow_state_zero, restaurantsDataWidget, updateRestaurantsOnState, reservationBlock } from "../../custom_components/order_flow/page/order_flow";
import { RestaurantsQueryResult } from "../../custom_components/order_flow/server-communication/data_types";
import { List } from "immutable";
import { RestaurantListItem } from "../drupal_api";
import { Breadcrumb, Block } from "../drupal/drupal_utils/types";
import { Page } from "../widget_blocks_client/PageTemplate";
import { get_page } from "../public_site_client";
import { CustomBlock } from "../public_site_utils/custom_blocks_types";
import { pageHeaderFallback } from "../public_site_utils/page_headers";

export type BookingCustomPageState = {
  page: AsyncState<Page<CustomBlock, Block>>
}

export const bookingPage = (breadcrumbs: List<Breadcrumb>) =>
  stateful<BookingCustomPageState>()(s =>
    any<Action<BookingCustomPageState>>()([
      async<Page<CustomBlock, Block>>(_ => nothing())(s.page).map(a => s1 => ({ ...s1, page: a(s1.page) })),
      s.page.kind === "loaded" && s.page.value.node.bundle == 'homepage' ? pageHeaderFallback(breadcrumbs)(s.page.value.node) : nothing(),
      mk_widget({
        run: _ => (
          <div className="content">
            <div className="block orderflow orderflow--single">
              <div className="block__inner orderflow__inner">
                {
                  stateful<order_flow_state>()(s0 =>
                    any<Action<order_flow_state>>()([
                      restaurantsDataWidget(s0.restaurants).map<Action<order_flow_state>>((a: Action<AsyncState<RestaurantsQueryResult[]>>) => (state: order_flow_state) => updateRestaurantsOnState(a, state)),
                      async<List<RestaurantListItem>>(_ => nothing())(s0.dineInRestaurants).map(a => s1 => ({ ...s1, dineInRestaurants: a(s1.dineInRestaurants) })),
                      reservationBlock(s0)
                    ]).map(a => a(s0)))(order_flow_state_zero).run(_ => null)
                }
              </div>
            </div>
          </div>
        )
      })
    ]).map(a => a(s)))({ page: get_page("/") })