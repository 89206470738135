import { LoginState, PortalAppState, LoggedOutState, isLoggedOutState, LoggedInState, isLoggedInState } from './state';
import { Widget, Action, onlyIf, string, any, nothing, div, button } from "widgets-for-react";
import i18next = require('i18next');

const errorMessages = (value: LoggedOutState): string => {
  switch (value.status) {
    case "logged out - login not authorized":
      return i18next.t("login:wrong_cred")
    case "logged out - too many attempts":
      return i18next.t("notifications:tooManyAttempts")
    case "logged out - error account not active":
      return i18next.t("notifications:notActiveYet")
    case "logged out - logging in":
      return i18next.t("notifications:authorizing")
    default:
      return "It was not possible to complete the request. Please try again."
  }
}

export let notifications = <a, b extends {kind:string}>(s0:LoginState<a>) : Widget<Action<PortalAppState<a, b>>> =>// nothing()
    onlyIf(s0.status != "logged in" &&
      s0.status != "logged out" &&
      s0.status != "two factor" &&
      s0.status != "sign up" &&
      s0.status != "reset password request" &&
      s0.status != "reset password",
    any<Action<PortalAppState<a, b>>>()([
        div<Action<PortalAppState<a, b>>>({ key: "notifications-0" })(
            isLoggedOutState(s0) ? string({ readonly_element:"p", readonly:true, className: 'alert alert--error' })(errorMessages(s0)).never()
            // : s0.status == "change password - error" ||  s0.status == "reset password - error weak password"
            // ? string({ readonly_element:"p", readonly:true })("It was not possible to complete the request. Please verify your input and try again.").never()
            : nothing()
        ),
         s0.status == "sign up - error account already exists"
          ? div<Action<PortalAppState<a, b>>>({ className:"row form-group", key: "notifications-2" })(
              div<Action<PortalAppState<a, b>>>({ className:"col-sm-4 " })([
                string({readonly_element:"p", readonly:true})(i18next.t("notifications:unactivatedAccountExists")).never(),
              ]))
          : nothing()

    ]))
