import { Map } from "immutable"
import * as React from "react"
import { AsyncState, Widget } from "widgets-for-react"
import { ImageSize, Url } from "./types"
import { jsxToWidget } from "./utils"

export function ImageContainer<extra_images> (
  url: AsyncState<Map<ImageSize<extra_images>, Url>>,
  imageSize: ImageSize<extra_images>,
  className: string,
  alt: string
): Widget<never>{
  return jsxToWidget(
    url.kind === "loaded" ? (
      <img 
        width="432" 
        src={`${url.value.get(imageSize || 'full')}`}
        data-src={url.value.get(imageSize || 'full')}
        data-srcset={`${url.value.get('crop_thumbnail')}, ${url.value.get('medium')}, ${url.value.get('large')}, ${url.value.get('thumbnail')}`}
        data-sizes="auto"
        className={className} 
        alt={alt} />
    ) : url.kind === "connection-error" ? (
      <>Error loading image</>
    ) : (
      <div className={`${className} ${className && className + "--loading"} svg-loader`}>
        <svg viewBox="0 0 40 40" className="svg-loader__svg">
          <path
            fill="#ccc"
            className="svg-loader__background"
            d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
              s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
              c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
          />
          <path
            fill="#000"
            className="svg-loader__foreground"
            d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z"
          >
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 20 20"
              to="360 20 20"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>
    )
  )
}
{/* <img className={options && options.style ? options.style.textimage_image_className : undefined} 
                    width="432" 
                    alt={props.image_alt_text}
                    src='/frontend/images/empty.gif'
                    data-src={image.get('media_w432')}
                    data-srcset={`${image.get('media_w432')} 432w, ${image.get('media_w864')} 864w, ${image.get('media_w1296')} 1296w`}
                    data-sizes="auto" /> */}

                    