import * as React from "react"
import { Action, any, async, AsyncState, IOWidget, loadingAsyncState, mkWidget, stateful } from "widgets-for-react"
import { loadRestaurantList, RestaurantList, RestaurantListFilterType } from "../drupal_api"
import { ExtraImages } from "../public_site_utils/extra_images"
import { Link } from "../public_site_utils/Link"
import { CMSState } from "./state"
import * as i18next from "i18next"
import { loader } from "../public_site_utils/loader";

export type RestaurantListBlockProps = {}

export type RestaurantListBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
}>

export type RestaurantListBlockState = {
  list: AsyncState<RestaurantList>
  filter: "all" | RestaurantListFilterType
}

export function RestaurantListBlock<CustomBlockData>(
  props: RestaurantListBlockProps,
  options?: { style?: RestaurantListBlockStyle; key?: string }
): IOWidget<CMSState<CustomBlockData, ExtraImages>, Action<CMSState<CustomBlockData, ExtraImages>>> {
  return (s: CMSState<CustomBlockData, ExtraImages>) =>
    stateful<RestaurantListBlockState>()(s0 =>
      any<Action<RestaurantListBlockState>>()([
        async<RestaurantList>()(s0.list).map<Action<RestaurantListBlockState>>(a => s => ({ ...s, list: a(s.list) })),
        mkWidget({
          run: setState => {
            if (s0.list.kind !== "loaded") return loader.run(() => null)

            const list = s0.filter != 'all'
              ? s0.list.value.filter(i => i[s0.filter] == true)
              : s0.list.value

            return (
              <section className="block">
                <div className="block__inner filter">
                  <h3 className="filter__title title--m">{i18next.t("locations:show_restaurants")}</h3>
                  <ul className="filter__list">
                    <li>
                      <button
                        onClick={() => setState(s1 => ({ ...s1, filter: "dineIn" }))}
                        className={`button ${s0.filter == "dineIn" ? "button--primary" : "button--secondary"}`}
                      >
                        {i18next.t("locations:restaurant")}
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => setState(s1 => ({ ...s1, filter: "delivery"}))}
                        className={`button ${s0.filter == "delivery" ? "button--primary" : "button--secondary"}`}
                      >
                        {i18next.t("locations:deliver")}
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => setState(s1 => ({ ...s1, filter: "pickUp"}))}
                        className={`button ${s0.filter == "pickUp" ? "button--primary" : "button--secondary"}`}
                      >
                        {i18next.t("locations:pickup")}
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => setState(s1 => ({ ...s1, filter: "all"}))}
                        className={`button ${s0.filter == "all" ? "button--primary" : "button--secondary"}`}
                      >
                        {i18next.t("locations:all")}
                      </button>
                    </li>
                  </ul>
                </div>
                <ul className="block__inner overview">
                  {list.map((s, k) => {
                    let types: JSX.Element[] = []
                    if (s.dineIn == "1") types.push(<span>{i18next.t("common:restaurant")}</span>)
                    if (s.delivery == "1") types.push(<span>{i18next.t("common:deliver")}</span>)
                    if (s.pickUp == "1") types.push(<span>{i18next.t("common:pickup")}</span>)

                    return (
                      <li className="overview-item" key={`RestaurantListBlock-li-${k}`}>
                        <Link className="overview-item__link overview-item__columns" to={s.url} type="link">
                          <div className="overview-item__column">
                            <svg className="overview-item__link-icon" aria-hidden="true"><use xlinkHref="#icon--caret-right" /></svg>
                            <h2 className="title--xs overview-item__title">{s.title}</h2>
                            <p className="text--xs">
                              {types.length > 0 ? (
                                types.map((el, k) => (
                                  <>
                                    {k > 0 ? ", " : ""}
                                    {el}
                                  </>
                                ))
                              ) : null}
                            </p>
                          </div>
                          <div className="overview-item__column">
                            { s.telephoneNumber
                              ? <p className="overview-item__text">{s.telephoneNumber}</p>
                              : <p className="overview-item__text">-</p>
                            }
                          </div>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </section>
            )
          }
        })
      ]).map(a => a(s0))
    )({
      filter: "all",
      list: loadingAsyncState(() => loadRestaurantList())
    }).never()
}
