import { LoginState, ResendActivationState } from '../state';
import { Action, Widget, any, div, string, link, hn } from "widgets-for-react";
import i18next = require('i18next');

export let resend_activation_success = <a>(s0: ResendActivationState, rootpath: string): Widget<Action<LoginState<a>>> =>
  div<Action<LoginState<a>>>({ className: "login" })(
    div<Action<LoginState<a>>>({ className: "login__inner" })([
      hn<Action<LoginState<a>>>(1, i18next.t('resend_activation_success:activationmail_send'), { className: "login__title title--l" })(),
      div<Action<LoginState<a>>>()(
        string({ readonly: true, readonly_element: "p" })(i18next.t('resend_activation_success:activationmail_send_text', { mail: s0.login_data.email })).never()
      ),
      div<Action<LoginState<a>>>({ className: "login__link text--styled" })([
        string({ readonly: true, readonly_element: "p" })(i18next.t('resend_activation_success:already_activated')).never(),
        link(i18next.t('resend_activation_success:login'), rootpath, { key: "sign-in" }).never()
      ])
    ])
  )