import { Action, async, AsyncState, loadingAsyncState, mk_widget, nothing, Widget } from "widgets-for-react";
import { getRestaurantsAsync } from "../server-communication/data_retrieval";
import { RestaurantsQueryResult } from "../server-communication/data_types";
import React = require("react");
import { List } from "immutable";
import { Route } from "react-router";
import { loadRestaurantList, RestaurantListItem } from "../../../components/drupal_api";
import * as i18next from "i18next"
import { pushToDataLayer } from "../../../components/public_site_utils/ecommerce";

export type order_flow_state = {
  restaurants: AsyncState<RestaurantsQueryResult[]>
  postcode: string
  valid_postcode: boolean
  restaurant: number
  dineInRestaurants: AsyncState<List<RestaurantListItem>>
  selectedDineInRestaurant: 'none' | RestaurantListItem['id']
  deliveryErrorMessage: string
  pickupErrorMessage: string
}

export const order_flow_state_zero: order_flow_state = {
  restaurants: loadingAsyncState<RestaurantsQueryResult[]>(_ => getRestaurantsAsync()),
  postcode: "",
  valid_postcode: false,
  restaurant: -1,
  dineInRestaurants: loadingAsyncState(() => loadRestaurantList({ dineIn: true })),
  selectedDineInRestaurant: 'none',
  deliveryErrorMessage: "",
  pickupErrorMessage: ""
}

export const restaurantsDataWidget = (restaurants: AsyncState<RestaurantsQueryResult[]>): Widget<Action<AsyncState<RestaurantsQueryResult[]>>> =>
 async<RestaurantsQueryResult[]>(_ => nothing(), {
   loading: _ => nothing(),
   connectionError: nothing(),
   otherError: nothing(),
 })(restaurants)

export const reservationBlock = (state:order_flow_state): Widget<Action<order_flow_state>> => {
  return mk_widget({
    run: onDone => {
      const selectedDineInRestaurant = state.dineInRestaurants.kind == "loaded" && state.selectedDineInRestaurant != "none"
        ? state.dineInRestaurants.value.filter(f => f.id == state.selectedDineInRestaurant).first()
        : 'none'
      return (
        <div className="orderflow__column">
          <h2 className="orderflow__title title--m">{i18next.t("common:book_table")}</h2>

          <div className="tabs">
            <div className="tab__content">
              <p className="orderflow__intro text--m">{i18next.t("orderflow:come_eat")}</p>

              <label
                htmlFor="select_restaurant_table"
                className="label"
              >
                {i18next.t("orderflow:select_restaurant")}
              </label>
              {state.dineInRestaurants.kind == "loaded" && (
                <div className="orderflow__fields">
                  <div className="select">
                    <select
                      id="select_restaurant_table"
                      value={state.selectedDineInRestaurant}
                      onChange={
                        e => {
                          onDone(s => ({...s, selectedDineInRestaurant: e.target.value}))
                        }
                      }
                    >
                      <option value="none">{i18next.t("orderflow:make_choice")}</option>
                      {state.dineInRestaurants.value.map((r, k) => (
                        <option value={r.id} dangerouslySetInnerHTML={{ __html: r.title }} />
                      ))}
                    </select>
                  </div>

                  <Route render={r => (
                    <button
                      disabled={selectedDineInRestaurant == "none"}
                      onClick={
                        () => {
                          pushToDataLayer({
                            event: "event-beren",
                            category: "Reserveer een tafel",
                            action: "Klik",
                            label: state.dineInRestaurants.kind === 'loaded' ? state.dineInRestaurants.value.findEntry(r => r.id === state.selectedDineInRestaurant)[1].title : 'not loaded',
                          })
                          selectedDineInRestaurant != "none"
                            ? r.history.push(`${selectedDineInRestaurant.url}#reserveren`)
                            : null
                          window.scroll(0, 0)
                        }
                      }
                      className="button button--primary"
                    >
                      {i18next.t("orderflow:select")}
                    </button>
                  )} />
                </div>
              )}
            </div>
          </div>
        </div>
      )
    }
  })
}

export const updateRestaurantsOnState = (a: Action<AsyncState<RestaurantsQueryResult[]>>, s: order_flow_state): order_flow_state => {
  let newRest = a(s.restaurants)
  return {...s,
           restaurants: newRest,
           restaurant: newRest.kind === "loaded" ? firstRestaurantId(newRest.value) : -1
         }
}

const firstRestaurantId = (restaurants: RestaurantsQueryResult[]) => {
  return restaurants.filter(rest => rest.Pickup === true).find(rest => true).Id
}

const cleanPostcode = (postcode: string) => 
  postcode ? postcode.replace(/[^a-zA-Z0-9]/gi, '').toUpperCase() : ''

const gtmDeliveryError = (error: string, postcode: string) => {
  if (error === 'Helaas verwerken wij vandaag geen bestellingen meer.') {
    pushToDataLayerTemplate(
      'event-beren',
      'Order flow',
      'Restaurant niet meer open',
      cleanPostcode(postcode))
  } else if (error.startsWith('Helaas bezorgen wij nog niet op jouw postcode')) {
    pushToDataLayerTemplate(
      'event-beren',
      'Bestel online failed',
      'Bezorgen',
      cleanPostcode(postcode))
  } else {
    pushToDataLayerTemplate(
      'event-beren',
      'Order flow',
      'Restaurant gesloten',
      cleanPostcode(postcode))
  }
}

const gtmDeliverySuccess = (postcode: string) => {
  pushToDataLayerTemplate(
    'event-beren',
    'Bestel online',
    'Bezorgen',
    cleanPostcode(postcode))
}

const gtmPickupError = (error: string, restaurant: number,  restaurants: AsyncState<RestaurantsQueryResult[]>) => {
  if (error === 'Helaas verwerken wij vandaag geen bestellingen meer.') {
    pushToDataLayerTemplate(
      'event-beren',
      'Order flow',
      'Restaurant niet meer open',
      restaurants.kind === 'loaded' ? restaurants.value.find(r => r.Id === restaurant).Name : restaurant.toString()
    )
  } else {
    pushToDataLayerTemplate(
      'event-beren',
      'Order flow',
      'Restaurant gesloten',
      restaurants.kind === 'loaded' ? restaurants.value.find(r => r.Id === restaurant).Name : restaurant.toString()
    )
  }
}

const gtmPickupSuccess = (restaurant: number,  restaurants: AsyncState<RestaurantsQueryResult[]>) => {
  pushToDataLayerTemplate(
    'event-beren',
    'Bestel online',
    'Afhalen',
    restaurants.kind === 'loaded' ? restaurants.value.find(r => r.Id === restaurant).Name : restaurant.toString(),
  )
}

const pushToDataLayerTemplate = (event: string, category: string, action: string, label: string) => {
  pushToDataLayer({
    event,
    category,
    action,
    label,
  })
}
