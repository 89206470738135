import { LoginState, ChangePasswordState, isChangePasswordState } from '../state';
import { Action, Widget, any, div, label, string, button, onlyIf, bool, link, hn } from "widgets-for-react";

export let change_password_form = <a>(s0: ChangePasswordState<a>, readonly: boolean, rootpath: string): Widget<Action<LoginState<a>>> => {
  const passwordInputType = s0.change_password_data.show_password ? "text" : "password"
  return div<Action<LoginState<a>>>({ key: "login-widget-change-password-form", className: "login" })(
    div<Action<LoginState<a>>>({ key: "login-widget-change-password-form-div-0", className: "login__inner" })([
      hn(1, "Wijzig wachtwoord", { className: "login__title title--l" })().never(),
      string({ readonly: true, readonly_element: 'p' })("Gebruik een combinatie van hoofdletters, kleine letters en getallen voor een veilig wachtwoord.").never(),
      div<Action<LoginState<a>>>({ className: "field", key: "login-widget-change-password-form-div-2" })(
        label<Action<LoginState<a>>>("Huidige wachtwoord", { htmlFor: "old-password", label_position: "before", className: "label" })(
          any<Action<LoginState<a>>>()([
            div<Action<LoginState<a>>>({ key: "login-widget-change-password-form-inner-any-div-1" })(
              string({ id: "old-password", className: `${s0.status == "change password - error incorrect password" ? "input--error" : ""}`, type: passwordInputType, readonly })(s0.change_password_data.old_password || "")
                .map<Action<LoginState<a>>>(old_password => s => isChangePasswordState(s) ? { ...s, status: "change password", change_password_data: { ...s.change_password_data, old_password } } : s)
            ),
            onlyIf<Action<LoginState<a>>>(s0.status == "change password - error incorrect password",
              div<Action<LoginState<a>>>({ key: "login-widget-change-password-form-inner-any-div-1", className: "inline-error alert alert--error" })(
                string({ readonly: true, readonly_element: "p" })("Ongeldig wachtwoord").never()
              )
            )
          ])
        )
      ),
      div<Action<LoginState<a>>>({ key: "login-widget-change-password-form-div-3", className: "field" })(
        label<Action<LoginState<a>>>("Nieuwe wachtwoord", { htmlFor: "new-password", label_position: "before", className: "label" })(
          any<Action<LoginState<a>>>()([
            div<Action<LoginState<a>>>({ key: "login-widget-change-password-form-inner-any-1-div-1" })(
              string({ id: "new-password", className: `${s0.status == "change password - error too weak password" ? "input--error" : ""}`, type: passwordInputType, readonly })(s0.change_password_data.new_password || "")
                .map<Action<LoginState<a>>>(new_password => s => isChangePasswordState(s) ? { ...s, status: "change password", change_password_data: { ...s.change_password_data, new_password } } : s)
            ),
            onlyIf<Action<LoginState<a>>>(s0.status == "change password - error too weak password",
              div<Action<LoginState<a>>>({ key: "login-widget-change-password-form-inner-any-1-div-1", className: "inline-error alert alert--error" })(
                string({ readonly: true, readonly_element: "p" })("Wachtwoord niet veilig genoeg").never()
              )
            )
          ])
        )
      ),
      div<Action<LoginState<a>>>({ key: "login-widget-change-password-form-div-4", className: "field" })(
        label<Action<LoginState<a>>>("Herhaal nieuwe wachtwoord", { htmlFor: "repeat-new-password", label_position: "before", className: "label" })(
          any<Action<LoginState<a>>>()([
            div<Action<LoginState<a>>>({ key: "login-widget-change-password-form-div-4-inner-any-div-1" })(
              string({ id: "repeat-new-password", className: `${s0.change_password_data.new_password_confirmed != undefined && s0.change_password_data.new_password != s0.change_password_data.new_password_confirmed ? "input--error" : ""}`, type: passwordInputType, readonly })(s0.change_password_data.new_password_confirmed || "")
                .map<Action<LoginState<a>>>(new_password_confirmed => s => isChangePasswordState(s) ? { ...s, status: "change password", change_password_data: { ...s.change_password_data, new_password_confirmed } } : s)
            ),
            onlyIf<Action<LoginState<a>>>(s0.change_password_data.new_password_confirmed != undefined && s0.change_password_data.new_password != s0.change_password_data.new_password_confirmed,
              div<Action<LoginState<a>>>({ key: "login-widget-change-password-form-div-4-inner-any-div-2", className: "inline-error alert alert--error" })(
                string({ readonly: true, readonly_element: "p" })("Wachtwoorden komen niet overeen").never()
              )
            )
          ])
        )
      ),
      // div<Action<LoginState<a>>>({ key: "login-widget-change-password-form-div-5", className: "field" })(
      //   label<Action<LoginState<a>>>("Show password", { htmlFor: "show-password", label_position: "before", className: "label" })(
      //     bool({ id: "show-password", className: "form-control", readonly })(s0.change_password_data.show_password)
      //       .map<Action<LoginState<a>>>(show_password => s => isChangePasswordState(s) ? { ...s, status: "change password", change_password_data: { ...s.change_password_data, show_password } } : s)
      //   )
      // ),
      div<Action<LoginState<a>>>({ key: "login-widget-change-password-form-div-6", className: "field" })([
        button<Action<LoginState<a>>>("Sla op", { className: "button button--primary button--full-width", disabled: readonly })(() => s => isChangePasswordState(s) ? { ...s, status: "change password - sending" } : s),
        link("Annuleren", `${rootpath}`, { className: "button-link" }).never()
        //button<Action<LoginState<a>>>("Cancel", { className: "button button--primary", disabled: readonly })(() => s => isChangePasswordState(s) ? {...s, status: "logged in" } : s)
      ]),
      // div<Action<LoginState<a>>>({ key: "login-widget-change-password-form-div-8", className:"row form-group" })(
      //   any<Action<LoginState<a>>>()([
      //      a<Action<LoginState<a>>>(" log out and reset your password", { className: " link link--white link--center"})(() => s => ({ status: "reset password request", login_data:{ email: s0.user.email } }))
      //   ])
      // )
    ])
  ).map<Action<LoginState<a>>>(a => s => readonly ? s : a(s))
}