import * as React from "react"
import { Widget, mk_widget } from "widgets-for-react"

export const styleguideGuidelines: Widget<never> = mk_widget({
  run: _ => (
    <>
      <section id="guidelines" className="styleguide-chapter styleguide-chapter--guidelines">
        <header className="styleguide--styled styleguide-chapter__header">
          <h2 className="styleguide__title styleguide-chapter__title">Design Guidelines</h2>
        </header>

        <div className="styleguide-chapter__content styleguide-chapter__content--plain">
          <p className="styleguide-chapter__intro">Aenean lacinia bibendum nulla sed consectetur. Donec sed odio dui. Curabitur blandit tempus porttitor. Donec ullamcorper nulla non metus auctor fringilla. Donec sed odio dui. Nulla vitae elit libero, a pharetra augue. Aenean lacinia bibendum nulla sed consectetur.</p>
          <p className="styleguide-chapter__intro">Etiam porta sem malesuada magna mollis euismod. Nulla vitae elit libero, a pharetra augue. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Vestibulum id ligula porta felis euismod semper. Nullam quis risus eget urna mollis ornare vel eu leo. Nullam quis risus eget urna mollis ornare vel eu leo.</p>
        </div>
      </section>
    </>
  )
})
