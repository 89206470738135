import { Map } from "immutable";
import {
  Action,
  AsyncState,
  IOWidget,
  Option,
  unloadedAsyncState,
  Widget,
} from "widgets-for-react";
import { TemplateState, Page } from "../PageTemplate";
import { CustomRouteParams } from "../router_state";

export type AppState<
  CurrenState,
  CustomRoutes extends { kind: keyof CurrenState }
> = {
  state: CurrenState;
  current_widget: Option<IOWidget<CurrenState, Action<CurrenState>>>;
  route: CustomRouteParams<CurrenState, CustomRoutes>;
};

export const SetCurrentWidget = <
  CustomBlockData extends { kind: CustomBlockData["kind"] },
  CustomBlocks extends { kind: CustomBlocks["kind"] },
  StandardBlocks,
  CurrentState extends DefaultState<
    CustomBlockData,
    CustomBlocks,
    StandardBlocks,
    extra_images
  >,
  CustomRoutes extends { kind: keyof CurrentState },
  extra_images
>(
  s: AppState<CurrentState, CustomRoutes>,
  newRoute: CustomRouteParams<CurrentState, CustomRoutes>,
  current_widget: Option<IOWidget<CurrentState, Action<CurrentState>>>
): AppState<CurrentState, CustomRoutes> => ({
  ...s,
  route: newRoute,
  current_widget: current_widget,
  state: {
    ...(s.state as any),
    page: { ...s.state.page, blocks: Map(), page: unloadedAsyncState() },
  },
});

export type PageTemplateState<
  CustomBlockData extends { kind: CustomBlockData["kind"] },
  CustomBlocks extends { kind: CustomBlocks["kind"] },
  StandardBlock,
  extra_images
> = {
  page: TemplateState<CustomBlockData, CustomBlocks, StandardBlock, extra_images> & {
    display: (
      placeholder: Widget<
        Action<TemplateState<CustomBlockData, CustomBlocks, StandardBlock, extra_images>>
      >
    ) => Widget<
      Action<TemplateState<CustomBlockData, CustomBlocks, StandardBlock, extra_images>>
    >;
    pagedisplay: (
      page: (CustomBlocks | StandardBlock)[]
    ) => IOWidget<
      TemplateState<CustomBlockData, CustomBlocks, StandardBlock, extra_images>,
      Action<TemplateState<CustomBlockData, CustomBlocks, StandardBlock, extra_images>>
    >
  } & {

  } & { get_page: (slug: string) => AsyncState<Page<CustomBlocks, StandardBlock>>;
};
}
export let PageStateZero = function<
  CustomBlockData extends { kind: CustomBlockData["kind"] },
  CustomBlocks extends { kind: CustomBlocks["kind"] },
  StandardBlock,
  extra_images
>(
  get_page: (slug: string) => AsyncState<Page<CustomBlocks, StandardBlock>>,
  display: (placeholder: Widget<Action<TemplateState<CustomBlockData, CustomBlocks, StandardBlock, extra_images>>>)
          => Widget<Action<TemplateState<CustomBlockData, CustomBlocks, StandardBlock, extra_images>>>,
  pagedisplay: (
    page: (CustomBlocks | StandardBlock)[]
  ) => IOWidget<
    TemplateState<CustomBlockData, CustomBlocks, StandardBlock, extra_images>,
    Action<TemplateState<CustomBlockData, CustomBlocks, StandardBlock, extra_images>>
  >
): PageTemplateState<CustomBlockData, CustomBlocks, StandardBlock, extra_images> {
  return {
    page: {
      get_page: get_page,
      page: unloadedAsyncState(),
      images: Map(),
      restaurant_list: unloadedAsyncState(),
      blocks: Map(),
      pagedisplay: pagedisplay,
      display: display ,
    },
  };
};

export type DefaultState<
  CustomBlockData extends { kind: CustomBlockData["kind"] },
  CustomBlocks extends { kind: CustomBlocks["kind"] },
  StandardBlocks,
  extra_images
> = PageTemplateState<CustomBlockData, CustomBlocks, StandardBlocks, extra_images>;

export let DefaultStateZero = function<
  CustomBlockData extends { kind: CustomBlockData["kind"] },
  CustomBlocks extends { kind: CustomBlocks["kind"] },
  StandardBlock,
  extra_images
>(
  get_page: (slug: string) => AsyncState<Page<CustomBlocks, StandardBlock>>,
  display: (
    placeholder: Widget<
      Action<TemplateState<CustomBlockData, CustomBlocks, StandardBlock, extra_images>>
    >
  ) => Widget<
    Action<TemplateState<CustomBlockData, CustomBlocks, StandardBlock, extra_images>>
  >,
  pagedisplay: (
    page: (CustomBlocks | StandardBlock)[]
  ) => IOWidget<
    TemplateState<CustomBlockData, CustomBlocks, StandardBlock, extra_images>,
    Action<TemplateState<CustomBlockData, CustomBlocks, StandardBlock, extra_images>>
  >
): DefaultState<CustomBlockData, CustomBlocks, StandardBlock, extra_images> {
  return {
    page: {
      get_page: get_page,
      images: Map(),
      restaurant_list: unloadedAsyncState(),
      blocks: Map(),
      page: unloadedAsyncState(),
      pagedisplay: pagedisplay,
      display: display
    },
  };
};
