import * as React from "react"
import { nothing, lifecycle } from "widgets-for-react"
import { ParsedNodeRestaurant } from "../drupal/drupal_generated/types"
import { jsxToWidget } from "../widget_blocks/utils"
import * as i18next from "i18next"

const scriptSrc: string = "https://cdn.guestplan.com/widget.js" 

const insertScript = (etenderKey: string): void => {
  removeScript();
  (function(g,s,t,p,l,n){g["_gstpln"]={};(l=s.createElement(t)),(n=s.getElementsByTagName(t)[0]);l.async=1;l.src=p;n.parentNode.insertBefore(l,n);})(window,document,"script",scriptSrc)
  window["_gstpln"].accessKey = etenderKey
}

const removeScript = () => {
  document.querySelectorAll(`script[src="${scriptSrc}"], div.cleanslate`).forEach(e => e.parentNode.removeChild(e))
}

export const etender = (accessKey: string) => {
  if (!accessKey) return nothing()

  return lifecycle({
    componentDidMount: () => insertScript(accessKey),
    componentWillUnmount: () => removeScript()
  })(() => jsxToWidget(<React.Fragment />))({})

}
