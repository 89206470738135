import * as React from "react"
import { Widget, mk_widget } from "widgets-for-react"
import { styleguideAbout } from "./components/about"
import { styleguideGuidelines } from "./components/guidelines"
import { styleguideTokens } from "./components/tokens"
import { styleguideObjects } from "./components/objects"
import { styleguideComponents } from "./components/components"
import { styleguideCompositions } from "./components/compositions"

export type StyleguideState = {
  page: 'about' | 'guidelines' | 'tokens' | 'objects' | 'components' | 'compositions'
}

const splitHash = () => {
  return window.location.hash.split('#')[1].split('-')[0];
}

const $breakpoint = 768;

export let currentPage = window.location.hash ? splitHash() :'tokens'

const __ = () => null

export const styleguide: Widget<never> = mk_widget({
  run: _ => (
    <>
      <article className="styleguide">
        <div className="styleguide__main">
          <header className="styleguide__header">
            <div className="styleguide__brand styleguide--styled">
              <a className="styleguide__logo-link" href="/styleguide">
                <h1 className="sr-text">Styleguide De Beren</h1>
                <svg className="styleguide__logo" width="220" height="35" fill="#000000" aria-hidden="true"><use xlinkHref="#image--logo-word"/></svg>
                <span className="styleguide__subtitle" aria-hidden="true">Styleguide</span>
              </a>

              <button className="styleguide-toggler" onClick={e => document.documentElement.classList.toggle('styleguide-nav--toggled')}>
                <span className="sr-text">Toggle Menu</span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </button>
            </div>

            <div className="styleguide-nav__wrapper styleguide--styled" data-active-page={currentPage}>
              <nav className="styleguide-nav">
                <h2 className="sr-text">Menu</h2>

                <ul className="styleguide-nav__list">
                  {/* <li className="styleguide-nav__item styleguide-nav--about">
                    <a className="styleguide-nav__link" href="#about" onClick={e => [currentPage = 'about', (window.innerWidth < $breakpoint) && document.documentElement.classList.remove('styleguide-nav--toggled')]}>About</a>
                  </li> */}
                  {/* <li className="styleguide-nav__item styleguide-nav--guidelines">
                    <a className="styleguide-nav__link" href="#guidelines" onClick={e => [currentPage = 'guidelines', (window.innerWidth < $breakpoint) && document.documentElement.classList.remove('styleguide-nav--toggled')]}>Design Guidelines</a>
                  </li> */}
                  <li className="styleguide-nav__item styleguide-nav--tokens">
                    <a className="styleguide-nav__link styleguide-nav__toggler" href="#tokens" onClick={e => [currentPage = 'tokens', document.documentElement.classList.toggle('styleguide-tokens--toggled')]}>Design Tokens</a>

                    <ul className="styleguide-nav__sublist">
                      <li className="styleguide-nav__item">
                        <a className="styleguide-nav__link" href="#tokens-colors" onClick={e => [currentPage = 'tokens', (window.innerWidth < $breakpoint) && document.documentElement.classList.remove('styleguide-nav--toggled')]}>Colors</a>
                      </li>
                      <li className="styleguide-nav__item">
                        <a className="styleguide-nav__link" href="#tokens-fonts" onClick={e => [currentPage = 'tokens', (window.innerWidth < $breakpoint) && document.documentElement.classList.remove('styleguide-nav--toggled')]}>Fonts</a>
                      </li>
                      <li className="styleguide-nav__item">
                        <a className="styleguide-nav__link" href="#tokens-styles" onClick={e => [currentPage = 'tokens', (window.innerWidth < $breakpoint) && document.documentElement.classList.remove('styleguide-nav--toggled')]}>Font Styles</a>
                      </li>
                      <li className="styleguide-nav__item">
                        <a className="styleguide-nav__link" href="#tokens-sizes-heights" onClick={e => [currentPage = 'tokens', (window.innerWidth < $breakpoint) && document.documentElement.classList.remove('styleguide-nav--toggled')]}>Font Sizes &amp; Line Heights</a>
                      </li>
                      <li className="styleguide-nav__item">
                        <a className="styleguide-nav__link" href="#tokens-icons" onClick={e => [currentPage = 'tokens', (window.innerWidth < $breakpoint) && document.documentElement.classList.remove('styleguide-nav--toggled')]}>Icons</a>
                      </li>
                      <li className="styleguide-nav__item">
                        <a className="styleguide-nav__link" href="#tokens-widths" onClick={e => [currentPage = 'tokens', (window.innerWidth < $breakpoint) && document.documentElement.classList.remove('styleguide-nav--toggled')]}>Widths</a>
                      </li>
                      <li className="styleguide-nav__item">
                        <a className="styleguide-nav__link" href="#tokens-spacings-gutters" onClick={e => [currentPage = 'tokens', (window.innerWidth < $breakpoint) && document.documentElement.classList.remove('styleguide-nav--toggled')]}>Spacings &amp; Gutters</a>
                      </li>
                      <li className="styleguide-nav__item">
                        <a className="styleguide-nav__link" href="#tokens-transitions" onClick={e => [currentPage = 'tokens', (window.innerWidth < $breakpoint) && document.documentElement.classList.remove('styleguide-nav--toggled')]}>Transitions</a>
                      </li>
                    </ul>
                  </li>
                  <li className="styleguide-nav__item styleguide-nav--objects">
                    <a className="styleguide-nav__link styleguide-nav__toggler" href="#objects" onClick={e => [currentPage = 'objects', document.documentElement.classList.toggle('styleguide-objects--toggled')]}>Design Objects</a>

                    <ul className="styleguide-nav__sublist">
                    <li className="styleguide-nav__item">
                        <a className="styleguide-nav__link" href="#objects-typography" onClick={e => [currentPage = 'objects', (window.innerWidth < $breakpoint) && document.documentElement.classList.remove('styleguide-nav--toggled')]}>Typography</a>
                      </li>
                      <li className="styleguide-nav__item">
                        <a className="styleguide-nav__link" href="#objects-links" onClick={e => [currentPage = 'objects', (window.innerWidth < $breakpoint) && document.documentElement.classList.remove('styleguide-nav--toggled')]}>Links</a>
                      </li>
                      <li className="styleguide-nav__item">
                        <a className="styleguide-nav__link" href="#objects-buttons" onClick={e => [currentPage = 'objects', (window.innerWidth < $breakpoint) && document.documentElement.classList.remove('styleguide-nav--toggled')]}>Buttons</a>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="styleguide-nav__item styleguide-nav--components">
                    <a className="styleguide-nav__link styleguide-nav__toggler" href="#components" onClick={e => [currentPage = 'components', document.documentElement.classList.toggle('styleguide-components--toggled')]}>Design Components</a>

                    <ul className="styleguide-nav__sublist">
                      <li className="styleguide-nav__item">
                        <a className="styleguide-nav__link" href="#components-contentblocks" onClick={e => [currentPage = 'components', (window.innerWidth < $breakpoint) && document.documentElement.classList.remove('styleguide-nav--toggled')]}>Content blocks</a>
                      </li>
                    </ul>
                  </li>
                  <li className="styleguide-nav__item styleguide-nav--compositions">
                    <a className="styleguide-nav__link styleguide-nav__toggler" href="#compositions" onClick={e => [currentPage = 'compositions', document.documentElement.classList.toggle('styleguide-compositions--toggled')]}>Design Compositions</a>

                    <ul className="styleguide-nav__sublist">
                      <li className="styleguide-nav__item">
                        <a className="styleguide-nav__link" href="#components-homepage" onClick={e => [currentPage = 'compositions', (window.innerWidth < $breakpoint) && document.documentElement.classList.remove('styleguide-nav--toggled')]}>Homepage</a>
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </nav>

              <div className="styleguide__extra styleguide--styled">
                <a className="styleguide__extra-link" href="https://www.hoppinger.com">by Hoppinger</a>
              </div>
            </div>
          </header>

          <div className="styleguide__content">
            {currentPage == 'about' ?
              styleguideAbout.run(__) :
              currentPage == 'guidelines' ?
                styleguideGuidelines.run(__) :
                currentPage == 'tokens' ?
                  styleguideTokens.run(__) :
                  currentPage == 'objects' ?
                    styleguideObjects.run(__) :
                    currentPage == 'components' ?
                      styleguideComponents.run(__) :
                      currentPage == 'compositions' ?
                        styleguideCompositions.run(__) :
                        null
            }
          </div>
        </div>
      </article>
    </>
  )
})