import { Widget, mk_widget } from "widgets-for-react"
import * as React from "react"
import { Link } from "react-router-dom";
import { Breadcrumbs } from "./breadcrumbs";
import { List } from "immutable";
import { Breadcrumb } from "../drupal/drupal_utils/types";

export const notfound_page: Widget<never> = mk_widget({
  run: o => (
    <header className="hero hero--error hero--without-image">
      <div className="content">
        <Breadcrumbs breadcrumbs={List<Breadcrumb>([
          { url: "/", title: "Home" }
        ])} />
        <div className="hero__content">
          <div className="hero__inner">
            <h1 className="hero__title title--xl">Sorry, beren op de weg!</h1>
            <div className="hero__intro text--styled">
              <p>Deze pagina is helaas niet (meer) beschikbaar. Je kunt doorgaan via het hoofdmenu of op onze <Link to="/">homepage</Link> kijken.</p>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
})
