import { stateful, string, any, button, wait, nothing, Action, Widget, Unit, browserRouter, Route, link, none, some, Option, IOWidget, div, loadingAsyncState } from 'widgets-for-react'
import { LoginState, PortalAppState, emptyLoginData, PrivacyKind, isLoggedInState, SessionGetter, SessionSetter, User, UserSession, LoggedInState, isLoggedOutState, isChooseRoleState } from './state';
import { notifications } from './notifications';
import { PagesRenderer, getMockedPagesRenderer, page_404 } from './view';
import { Api, getMockedApi, activate_account, resend_account_activation, reset_password, basic_login, verify_reset_password_token, reset_password_request, sign_up, logout, login, login_two_factor, send_new_pin, change_password, login_choose_role } from './api_to_state';
import { RouteParams, CustomRouteParams } from './router_state';
import { router } from './router';
import { getUser } from '../../custom_components/order_flow/server-communication/data_retrieval';
import { pushToDataLayer } from '../public_site_utils/ecommerce';

export let state = <a, b extends {kind:string}>(get_initial_state:() => a, initial_login_state:LoginState<a>, initial_route: RouteParams, privacy_kind:PrivacyKind="standard", get_404_page:Option<Widget<never>>) : PortalAppState<a, b> => ({
  login:initial_login_state,
  privacy_kind:privacy_kind,
  get_initial_state:get_initial_state,
  route: initial_route,
  get_404_page:get_404_page
})

let login_switch_tmp = <a, b extends {kind:string}>(ps_r:PagesRenderer<a>, api:Api<a>, s0:PortalAppState<a, b>, setSession: SessionSetter, application:IOWidget<PortalAppState<a, b>, Action<PortalAppState<a, b>>>, rootpath:string) : Widget<Action<PortalAppState<a, b>>> =>
  any<Action<LoginState<a>>>({ key: "login-widget-login-switch-tmp" })([
    // ----------------------- ACTIVATE ACCOUNT
    s0.login.status == "activate account"  ?
      ps_r.activate_account_form(s0.login, rootpath, false) :
    s0.login.status == "activate account - api error" ?
      any<Action<LoginState<a>>>()([
        wait<Action<LoginState<a>>>(500, { key: "login-widget-activate-account-promise" })(() => s => s0.login.status != "activate account - api error" ? s : ({status:"activate account - activating", login_data:s0.login.login_data})),
        ps_r.activate_account_form(s0.login, rootpath, true)
      ]) :
    s0.login.status == "activate account - activating" ?
      any<Action<LoginState<a>>>()([
        activate_account(api, s0.login.login_data, s0.get_initial_state(), setSession, rootpath),
        ps_r.activate_account_form(s0.login, rootpath, true)
      ]) :
    s0.login.status == "logged in - activation success" ?
        ps_r.activate_account_success(s0.login, false) :

    // ----------------------- RESEND ACCOUNT ACTIVATION
    s0.login.status == "resend activation" ||
    s0.login.status == "resend activation - error too many attempts" ?
      ps_r.resend_activation_form(s0.login, false, rootpath, api) :
    s0.login.status == "resend activation - signup success" ?
      ps_r.signup_success(s0.login, false, rootpath, api) :
    s0.login.status == "resend activation - success" ?
      ps_r.resend_activation_success(s0.login, rootpath) :
    s0.login.status == "resend activation - api error" ?
      any<Action<LoginState<a>>>()([
        wait<Action<LoginState<a>>>(500, { key: "login-widget-resend-activation-promise" })(() => s => s.status != "resend activation - api error" ? s : ({...s, status: "resend activation - resending"})),
        ps_r.resend_activation_form(s0.login, true, rootpath, api)
      ]) :
    s0.login.status == "resend activation - resending" ?
      any<Action<LoginState<a>>>()([
        resend_account_activation(api, s0.login.login_data),
        ps_r.resend_activation_form(s0.login, true, rootpath, api)
      ]) :
    s0.login.status == "resend activation - error token expired" ?
      any<Action<LoginState<a>>>()([
        wait<Action<LoginState<a>>>(2000, { key: "login-widget-token-expired-promise" })(() => s => s0.login.status != "resend activation - error token expired" ? s : ({status:"resend activation - resending", login_data:s0.login.login_data})),
        ps_r.login_form({status: "logged out - error account activation token not found", login_data: emptyLoginData}, false, rootpath)
      ]) :

    // ----------------------- RESET PASSWORD
    s0.login.status == "reset password" ||
    s0.login.status == "reset password - error weak password" ?
      ps_r.reset_password_form(s0.login, false, rootpath, api) :
    s0.login.status == "reset password - api error" ?
      any<Action<LoginState<a>>>()([
        wait<Action<LoginState<a>>>(500, { key: "login-widget-reset-password-error-promise" })(() => s => s0.login.status != "reset password - api error" ? s : ({status:"reset password - resetting password", login_data:s0.login.login_data})),
        ps_r.reset_password_form(s0.login, true, rootpath, api)
      ]) :
    s0.login.status == "reset password - resetting password" ?
      any<Action<LoginState<a>>>()([
        reset_password(api, s0.login.login_data),
        ps_r.reset_password_form(s0.login, true, rootpath, api)
      ]) :
    s0.login.status == "reset password success" ?
      ps_r.reset_password_success(s0.login, false) :
    s0.login.status == "reset password success - api error" ?
      any<Action<LoginState<a>>>()([
        wait<Action<LoginState<a>>>(500, { key: "login-widget-reset-password-success-promise" })(() => s => s0.login.status != "reset password success - api error" ? s : ({status:"reset password success - logging in", login_data:s0.login.login_data})),
        ps_r.reset_password_success(s0.login, true)
      ]) :
    s0.login.status == "reset password success - logging in" ?
      any<Action<LoginState<a>>>()([
        basic_login(api, s0.login.login_data, s0.get_initial_state(), setSession, _ => s => s.status == "reset password success - logging in" ? {...s, status: "reset password success - api error"} : s, rootpath),
        ps_r.reset_password_success(s0.login, true)
      ]) :

    // ----------------------- VERIFY RESET PASSWORD TOKEN
    s0.login.status == "verify reset password token" ?
      ps_r.verify_reset_password_form(s0.login, rootpath) :
    s0.login.status == "verify reset password token - api error" ?
      any<Action<LoginState<a>>>()([
        wait<Action<LoginState<a>>>(500, { key: "login-widget-reset-password-verify-promise" })(() => s => s.status != "verify reset password token - api error" ? s : {...s, status: "verify reset password token - sending"}),
        ps_r.login_form({status: "logged out", login_data: emptyLoginData }, false, rootpath)
      ]) :
    s0.login.status == "verify reset password token - sending" ?
      any<Action<LoginState<a>>>()([
       verify_reset_password_token(api, s0.login.login_data),
       ps_r.login_form({status: "logged out", login_data: emptyLoginData }, false, rootpath)
      ]) :

    // ----------------------- RESET PASSWORD REQUEST
    s0.login.status == "reset password request" ||
    s0.login.status == "reset password request - error email not found" ||
    s0.login.status == "reset password request - error email not valid" ||
    s0.login.status == "reset password request - error user not activated" ||
    s0.login.status == "reset password request - error too many attempts" ?
      ps_r.reset_password_request_form(s0.login, false, rootpath, api) :
    s0.login.status == "reset password request - api error" ?
      any<Action<LoginState<a>>>()([
        wait<Action<LoginState<a>>>(500, { key: "login-widget-reset-password-request-promise" })(() => s => s0.login.status != "reset password request - api error" ? s : ({status:"reset password request - resetting", login_data:s0.login.login_data})),
        ps_r.reset_password_request_form(s0.login, true, rootpath, api)
      ]) :
    s0.login.status == "reset password request - resetting" ?
      any<Action<LoginState<a>>>()([
        reset_password_request(api, s0.login.login_data, s0.privacy_kind),
        ps_r.reset_password_request_form(s0.login, true, rootpath, api).never()
      ]) :
    s0.login.status == "reset password request - success" ?
      any<Action<LoginState<a>>>()([
        ps_r.reset_password_request_success(s0.login, rootpath)
      ]) :

    // ----------------------- SIGN UP
    s0.login.status == "sign up" ||
    s0.login.status == "sign up - error" ||
    s0.login.status == "sign up - error invalid email" ||
    s0.login.status == "sign up - error invalid username" ||
    s0.login.status == "sign up - error account already exists" ||
    s0.login.status == "sign up - error account not active" ||
    s0.login.status == "sign up - error too many attempts" ||
    s0.login.status == "sign up - error weak password" ?
      ps_r.sign_up_form(s0.login, false, rootpath, api) :
    s0.login.status == "sign up - api error" ?
      any<Action<LoginState<a>>>()([
        wait<Action<LoginState<a>>>(500, { key: "login-widget-sign-up-promise" })(() => s => s.status != "sign up - api error" ? s : ({status:"sign up - signing up", login_data:s.login_data})),
        ps_r.sign_up_form(s0.login, true, rootpath, api)
      ]) :
    s0.login.status == "sign up - signing up" ?
      any<Action<LoginState<a>>>()([
        sign_up(api, s0.login.login_data, s0.privacy_kind, s0.get_initial_state(), setSession),
        ps_r.sign_up_form(s0.login, true, rootpath, api).never()
      ]) :
    // s0.login.status == "sign up - success" ?
    //   any<Action<LoginState<a>>>()([
    //     wait<Action<LoginState<a>>>(1000, { key: "login-widget-logged-out-promise" })(() => s => ({status:"logged out", login_data:{username:s.status == "sign up" ? s.login_data.username! : "", password:s.status == "sign up" ? s.login_data.password! : "", pin:{kind:"no pin"}, show_password:false}})),
    //     ps_r.sign_up_form(s0.login, true, rootpath, api)
    //   ]) :
    // s0.login.status == "logged in - signup success" ?
    //   ps_r.signup_success(s0.login) :

    // ----------------------- LOGIN/LOGOUT
    s0.login.status == "logged out" ||
    s0.login.status == "logged out - login not authorized" ||
    s0.login.status == "logged out - too many attempts" ||
    s0.login.status == "logged out - error too many reset password attempts" ||
    s0.login.status == "logged out - error reset password token has expired" ||
    s0.login.status == "logged out - error reset password token not found" ||
    s0.login.status == "logged out - error too many account activation attempts" ||
    s0.login.status == "logged out - error account activation token not found" ||
    s0.login.status == "logged out - account already active" ||
    s0.login.status == "logged out - error account not active" ?
      ps_r.login_form(s0.login, false, rootpath) :
    s0.login.status == "logged in" ?
      nothing() :
    s0.login.status == "logged in - logging out" ?
      logout(api, s0.login, s0.login.appState, setSession, rootpath) :
    s0.login.status == "logged out - login api error" ?
      any<Action<LoginState<a>>>()([
        wait<Action<LoginState<a>>>(500, { key: "login-widget-logging-in-promise" })(() => s => s0.login.status != "logged out - login api error" ? s : ({status:"logged out - logging in", login_data:s0.login.login_data})),
        ps_r.login_form(s0.login, true, rootpath).never()
      ]) :
    s0.login.status == "logged in - logout api error" ?
      wait<Action<LoginState<a>>>(500, { key: "login-widget-logging-out-promise" })(() => s => s.status != "logged in - logout api error" ? s : ({...s, status:"logged in - logging out" })) :
    s0.login.status == "logged out - logging in" ?
      any<Action<LoginState<a>>>()([
        login(api, s0.login.login_data, s0.privacy_kind, s0.get_initial_state(), setSession),
        ps_r.login_form(s0.login, true, rootpath).never()
      ]) :

    // ----------------------- TWO FACTOR
    s0.login.status == "two factor" ||
    s0.login.status == "two factor - empty code" ||
    s0.login.status == "two factor - too many attempts" ||
    s0.login.status == "two factor - wrong code" ?
      ps_r.login_two_factor_form(s0.login, false) :
    s0.login.status == "two factor - login api error" ?
      any<Action<LoginState<a>>>()([
        wait<Action<LoginState<a>>>(500, { key: "login-widget-two-factor-logging-promise" })(() => s => s.status != "two factor - login api error" ? s : ({status:"two factor - logging", login_data:s.login_data})),
        ps_r.login_two_factor_form(s0.login, true).never()
      ]) :
    s0.login.status == "two factor - logging" ?
      any<Action<LoginState<a>>>()([
        login_two_factor(api, s0.login.login_data, s0.get_initial_state(), setSession),
        ps_r.login_two_factor_form(s0.login, true).never()
      ]) :
    s0.login.status == "two factor - resend api error" ?
      any<Action<LoginState<a>>>()([
        wait<Action<LoginState<a>>>(500, { key: "login-widget-two-factor-resending-pin-promise" })(() => s => s.status != "two factor - resend api error" ? s : ({status: "two factor - resending pin", login_data: s.login_data})),
        ps_r.login_two_factor_form(s0.login, true).never()
      ]) :
    s0.login.status == "two factor - resending pin" ?
      any<Action<LoginState<a>>>()([
        send_new_pin(api, s0.login.login_data),
        ps_r.login_two_factor_form(s0.login, true).never()
      ]) :

    // ----------------------- CHANGE PASSWORD
    s0.login.status == "change password" ||
    s0.login.status == "change password - error incorrect password" ||
    s0.login.status == "change password - error passwords do not match" ||
    s0.login.status == "change password - error too weak password" ||
    s0.login.status == "change password - error missing fields" ||
    s0.login.status == "change password - error" ?
      ps_r.change_password_form(s0.login, false, rootpath) :
    s0.login.status == "change password - api error" ?
      any<Action<LoginState<a>>>()([
        wait<Action<LoginState<a>>>(500, { key: "login-widget-change-password-sending-promise" })(() => s => s.status != 'change password - api error' ? s : {...s, status: "change password - sending"}),
        ps_r.change_password_form(s0.login, true, rootpath)
      ]):
    s0.login.status == "change password - sending" ?
      any<Action<LoginState<a>>>()([
        change_password(api, s0.login),
        ps_r.change_password_form(s0.login, true, rootpath)
      ]) :
    s0.login.status == "change password - success" ?
    ps_r.change_password_success(s0.login, rootpath)
    :

    // ----------------------- CHOOSE ROLE
    s0.login.status == "choose role" ||
    s0.login.status == "choose role - error too many attempts" ||
    s0.login.status == "choose role - error no role selected" ||
    s0.login.status == "choose role - error incompatible role for user" ?
      ps_r.choose_roles_form(s0.login, false) :
    s0.login.status == "choose role - api error" ?
      any<Action<LoginState<a>>>()([
        wait<Action<LoginState<a>>>(500, { key: "login-widget-choose-role-logging-in-promise" })(() => s => s.status != 'choose role - api error' ? s : {...s, status: "choose role - logging in"}),
        ps_r.choose_roles_form(s0.login, true)
      ]):
    s0.login.status == "choose role - logging in" ?
      any<Action<LoginState<a>>>()([
        login_choose_role(api, s0.login.login_data, s0.get_initial_state(), setSession),
        ps_r.choose_roles_form(s0.login, true)
      ]):
    s0.login.status == "404" ?
      s0.get_404_page.v.kind == "r" ? s0.get_404_page.v.v
      : page_404()
    :
    nothing()
    ]).map<Action<PortalAppState<a, b>>>(a => s1 => ({...s1, login: a(s1.login)}))

export const setSession: SessionSetter = (user, session, role) => {

  if (!window.localStorage) return

  if (user == undefined)
    localStorage.removeItem("user")
  else
    localStorage.setItem("user", JSON.stringify(user))

  if (session == undefined)
    localStorage.removeItem("session")
  else
    localStorage.setItem("session", session)

  if (role == undefined)
    localStorage.removeItem("role")
  else
    localStorage.setItem("role", role)
}

export const getSession: SessionGetter = () => {

  if (window.localStorage && localStorage.length > 0) {
    let user = localStorage.getItem("user");
    let session = localStorage.getItem("session");
    let role = localStorage.getItem("role");
    if(user == null){
      return none<UserSession>()
    }
    return some<UserSession>({
      user: JSON.parse(user),
      session: session,
      role: role ? role : undefined
    })
  }
  return none<UserSession>()
}

export const login_state = <a, b extends { kind: string }>(s: PortalAppState<a, b>, data: LoginWidgetData<a, b>, get_session: SessionGetter, rootpath: string, newRoute: CustomRouteParams<b>): PortalAppState<a, b> => {
  let info = get_session()

  switch (newRoute.kind) {
    // custom cases
    case "register":
      const state0: PortalAppState<a, b> = {
        ...s,
        route: newRoute,
        login: {
          status: "sign up",
          login_data: {
            name: undefined,
            email: undefined,
            password: undefined,
            /*password_kind:"standard",*/ confirmed_email: undefined,
            confirmed_password: undefined,
            surname: undefined,
            username: undefined,
            agreed: false
          }
        }
      }

      return state0
      break

    case "home":
      if (isLoggedInState(s.login)) {
        const state8: PortalAppState<a, b> = {
          ...s,
          route: newRoute,
          login: { ...s.login, status: "logged in" }
        }
        return state8
      }

      if (info.v.kind == "r") {
        let info_value = info.v.v

        if (localStorage.getItem('fired_logged_in') === 'false') {
          localStorage.setItem('fired_logged_in', 'true')

          pushToDataLayer({
            event: "event-beren",
            category: "Inloggen",
            action: "verzend",
            label: "formulier",
          })
        }


        const state1: PortalAppState<a, b> = {
          ...s,
          route: newRoute,
          login: {
            status: "logged in",
            user: info_value.user,
            appState: data.initial_state,
            session: info_value.session,
            role: info_value.role ? info_value.role : "",
            info: loadingAsyncState(() => getUser(info_value.user.username))
          }
        }

        return state1
      }

      localStorage.setItem('fired_logged_in', 'false')

      const state2: PortalAppState<a, b> = {
        ...s,
        route: newRoute,
        login: { status: "logged out", login_data: emptyLoginData }
      }

      return state2
      break

    case "activate":
      const state3: PortalAppState<a, b> = {
        ...s,
        route: newRoute,
        login: {
          status: "activate account",
          login_data: {
            token: (newRoute as { kind: "activate"; token: string }).token,
            email: (newRoute as { kind: "activate"; email: string }).email
          }
        }
      }

      return state3
      break

    case "change password":
      if (isLoggedInState(s.login)) {
        return {
          ...s,
          route: newRoute,
          login: {
            ...s.login,
            status: "change password",
            change_password_data: {
              old_password: undefined,
              new_password: undefined,
              new_password_confirmed: undefined,
              show_password: false
            }
          }
        }
      }
      if (info.v.kind == "r") {
        let info_value = info.v.v
        return {
          ...s,
          route: newRoute,
          login: {
            status: "change password",
            user: info_value.user,
            appState: data.initial_state,
            session: info_value.session,
            change_password_data: {
              old_password: undefined,
              new_password: undefined,
              new_password_confirmed: undefined,
              show_password: false
            },
            role: info_value.role ? info_value.role : "",
            info: loadingAsyncState(() => getUser(info_value.user.username))
          }
        }
      }

      const state4: PortalAppState<a, b> = {
        ...s,
        route: newRoute,
        login: { status: "logged out", login_data: emptyLoginData }
      }

      return state4
      break

    case "forgot password":
      const state5: PortalAppState<a, b> = {
        ...s,
        route: newRoute,
        login: {
          status: "reset password request",
          login_data: {
            /*name:undefined,*/ email: undefined /*password:undefined,/*password_kind:"standard",*/ /*confirmed_email:undefined,*/ /*confirmed_password:undefined,*/ /*surname:undefined,*/ /*username:undefined*/
          }
        }
      }

      return state5
      break

    case "reset password":
      const state6: PortalAppState<a, b> = {
        ...s,
        route: newRoute,
        login: {
          status: "verify reset password token - sending",
          login_data: {
            reset_password_token: (newRoute as {
              kind: "reset password"
              token: string
            }).token,
            email: (newRoute as { kind: "reset password"; email: string }).email
          }
        }
      }

      return state6
      break

    case "resend activation":
      const state7: PortalAppState<a, b> = {
        ...s,
        route: newRoute,
        login: {
          status: "resend activation",
          login_data: {
            /*name:undefined,*/ email: undefined /*password:undefined,/*password_kind:"standard",*/ /*confirmed_email:undefined,*/ /*confirmed_password:undefined,*/ /*surname:undefined,*/ /*username:undefined*/
          }
        }
      }

      return state7
      break

    case "404": {
      return { ...s, route: newRoute, login: { ...s.login, status: "404" } }
    }
    default: {
      let res = data.application_router_switch(newRoute, s)
      if (res.v.kind == "r") {
        return res.v.v
      }
      return { ...s, route: newRoute }
    }
  }

  // Unreachable code
  // const state7: PortalAppState<a, b> = {...s, route: newRoute}
  // return state7
}

let portal_router = <a, b extends { kind: string }>(s0: PortalAppState<a, b>, data: LoginWidgetData<a, b>, get_session: SessionGetter, rootpath: string) =>
  router<a, b>(rootpath, s0, data.custom_auth_routes, data.custom_unauth_routes).map<Action<PortalAppState<a, b>>>(newRoute => s => {
    const title: string = (newRoute as any).title
    if (title) {
      document.title = title
    }

    return login_state(s, data, get_session, rootpath, newRoute)
  })

export type LoginWidgetData<a, b extends { kind: string }> = {
  rootpath?: string
  api?: Api<a>
  ps_r?: PagesRenderer<a>
  set_session?: SessionSetter
  get_session?: SessionGetter
  events?: {
    on_login: (_: PortalAppState<a, b>) => PortalAppState<a, b>
  }
  application: IOWidget<PortalAppState<a, b>, Action<PortalAppState<a, b>>>
  initial_state: a
  custom_auth_routes: Array<Route<CustomRouteParams<b>>>
  custom_unauth_routes: Array<Route<CustomRouteParams<b>>>
  application_router_switch: (r: CustomRouteParams<b>, s: PortalAppState<a, b>) => Option<PortalAppState<a, b>>
  login_decorator?: IOWidget<Widget<Action<PortalAppState<a, b>>>, Action<PortalAppState<a, b>>>
  without_browserRouter?: boolean
}

export let login_switch = <a, b extends { kind: string }>(
  ps_r: PagesRenderer<a>,
  api: Api<a>,
  s0: PortalAppState<a, b>,
  setSession: SessionSetter,
  application: IOWidget<PortalAppState<a, b>, Action<PortalAppState<a, b>>>,
  rootpath: string,
  login_decorator: Option<IOWidget<Widget<Action<PortalAppState<a, b>>>, Action<PortalAppState<a, b>>>>
): Widget<Action<PortalAppState<a, b>>> => {
  let page = any<Action<PortalAppState<a, b>>>()([
    login_switch_tmp(ps_r, api, s0, setSession, application, rootpath),
    isLoggedInState(s0.login) ? application(s0) : nothing()
  ])
  return any<Action<PortalAppState<a, b>>>({
    key: "login-widget-browser-router-login-switch"
  })([login_decorator.v.kind == "l" ? page : login_decorator.v.v(page)])
}

export let login_widget = <a, b extends { kind: string }>(
  data: LoginWidgetData<a, b>
): IOWidget<PortalAppState<a, b>, Action<PortalAppState<a, b>>> => s0 => {
  let rootpath = data.rootpath ? data.rootpath : ""
  let get_session = data.get_session ? data.get_session : getSession
  let api = data.api ? data.api : getMockedApi()
  let ps_r = data.ps_r ? data.ps_r : getMockedPagesRenderer<a>()
  let innerLoginWidget = any<Action<PortalAppState<a, b>>>({
    key: "login-widget-browser-router-inner-any"
  })([
    s0.route.kind == "ignore"
      ? nothing()
      : login_switch<a, b>(
          ps_r,
          api,
          s0,
          data.set_session ? data.set_session : setSession,
          data.application,
          rootpath,
          data.login_decorator ? some(data.login_decorator) : none()
        ),
    data.without_browserRouter ? nothing() : portal_router(s0, data, get_session, rootpath)
  ]).map<Action<PortalAppState<a, b>>>(a => s => {
    let s1 = a(s)
    if ((isLoggedOutState(s.login) || isChooseRoleState(s.login)) && isLoggedInState(s1.login)) {
      if (data.events && data.events.on_login) {
        s1 = data.events.on_login(s1)
      }
    }
    return s1
  })
  return data.without_browserRouter
    ? innerLoginWidget
    : browserRouter<Action<PortalAppState<a, b>>>({
        key: "login-widget-browser-router"
      })(innerLoginWidget)
}
