

import {
  mk_query,
  mk_rel_query,
  RelationBuilder,
  RelationsOf,
  FilterBuilderComplex,
  FilterBuilderBoolean,
  FilterBuilderNumber,
  FilterBuilderString,
  FilterBuilderCollection,
  FilterBuilderDate,
  parseBoolean,
  parseNumber,
  parseString,
  parseDate,
  EntityParser,
  parseCollection,
  parseEntity,
  succes,
  parseNullBoolean,
  parseNullNumber,
  parseNullDate,
  parseNullString,
  OrderByBuilderComplex,
  OrderByProp
} from "typescript-odata-client"
import { List } from "immutable"


const mk_prefix = (id: string, prefix?: string) => prefix ? prefix + '/' + id : id



  export const AddressQuery = (uri?: string) => mk_query<Address>(
    FilterBuilderAddress(), 
    AddressRelationBuilder,
    uri != undefined ? uri + 'Address' : 'http://localhost:5000/odata/Address',
    AddressParser,
    AddressOrderBy("")
  )

  export const AdminQuery = (uri?: string) => mk_query<Admin>(
    FilterBuilderAdmin(), 
    AdminRelationBuilder,
    uri != undefined ? uri + 'Admin' : 'http://localhost:5000/odata/Admin',
    AdminParser,
    AdminOrderBy("")
  )

  export const AlternativeAddressQuery = (uri?: string) => mk_query<AlternativeAddress>(
    FilterBuilderAlternativeAddress(), 
    AlternativeAddressRelationBuilder,
    uri != undefined ? uri + 'AlternativeAddress' : 'http://localhost:5000/odata/AlternativeAddress',
    AlternativeAddressParser,
    AlternativeAddressOrderBy("")
  )

  export const ApplicationQuery = (uri?: string) => mk_query<Application>(
    FilterBuilderApplication(), 
    ApplicationRelationBuilder,
    uri != undefined ? uri + 'Application' : 'http://localhost:5000/odata/Application',
    ApplicationParser,
    ApplicationOrderBy("")
  )

  export const CategoryQuery = (uri?: string) => mk_query<Category>(
    FilterBuilderCategory(), 
    CategoryRelationBuilder,
    uri != undefined ? uri + 'Category' : 'http://localhost:5000/odata/Category',
    CategoryParser,
    CategoryOrderBy("")
  )

  export const Category_ProductPositionQuery = (uri?: string) => mk_query<Category_ProductPosition>(
    FilterBuilderCategory_ProductPosition(), 
    Category_ProductPositionRelationBuilder,
    uri != undefined ? uri + 'Category_ProductPosition' : 'http://localhost:5000/odata/Category_ProductPosition',
    Category_ProductPositionParser,
    Category_ProductPositionOrderBy("")
  )

  export const Category_RestaurantQuery = (uri?: string) => mk_query<Category_Restaurant>(
    FilterBuilderCategory_Restaurant(), 
    Category_RestaurantRelationBuilder,
    uri != undefined ? uri + 'Category_Restaurant' : 'http://localhost:5000/odata/Category_Restaurant',
    Category_RestaurantParser,
    Category_RestaurantOrderBy("")
  )

  export const CompanyQuery = (uri?: string) => mk_query<Company>(
    FilterBuilderCompany(), 
    CompanyRelationBuilder,
    uri != undefined ? uri + 'Company' : 'http://localhost:5000/odata/Company',
    CompanyParser,
    CompanyOrderBy("")
  )

  export const Company_UserQuery = (uri?: string) => mk_query<Company_User>(
    FilterBuilderCompany_User(), 
    Company_UserRelationBuilder,
    uri != undefined ? uri + 'Company_User' : 'http://localhost:5000/odata/Company_User',
    Company_UserParser,
    Company_UserOrderBy("")
  )

  export const DefaultAddressQuery = (uri?: string) => mk_query<DefaultAddress>(
    FilterBuilderDefaultAddress(), 
    DefaultAddressRelationBuilder,
    uri != undefined ? uri + 'DefaultAddress' : 'http://localhost:5000/odata/DefaultAddress',
    DefaultAddressParser,
    DefaultAddressOrderBy("")
  )

  export const DeliveryClosingTimeQuery = (uri?: string) => mk_query<DeliveryClosingTime>(
    FilterBuilderDeliveryClosingTime(), 
    DeliveryClosingTimeRelationBuilder,
    uri != undefined ? uri + 'DeliveryClosingTime' : 'http://localhost:5000/odata/DeliveryClosingTime',
    DeliveryClosingTimeParser,
    DeliveryClosingTimeOrderBy("")
  )

  export const DeliveryRestaurant_DeliveryPayMethodQuery = (uri?: string) => mk_query<DeliveryRestaurant_DeliveryPayMethod>(
    FilterBuilderDeliveryRestaurant_DeliveryPayMethod(), 
    DeliveryRestaurant_DeliveryPayMethodRelationBuilder,
    uri != undefined ? uri + 'DeliveryRestaurant_DeliveryPayMethod' : 'http://localhost:5000/odata/DeliveryRestaurant_DeliveryPayMethod',
    DeliveryRestaurant_DeliveryPayMethodParser,
    DeliveryRestaurant_DeliveryPayMethodOrderBy("")
  )

  export const DeliveryTimesQuery = (uri?: string) => mk_query<DeliveryTimes>(
    FilterBuilderDeliveryTimes(), 
    DeliveryTimesRelationBuilder,
    uri != undefined ? uri + 'DeliveryTimes' : 'http://localhost:5000/odata/DeliveryTime',
    DeliveryTimesParser,
    DeliveryTimesOrderBy("")
  )

  export const DiscountQuery = (uri?: string) => mk_query<Discount>(
    FilterBuilderDiscount(), 
    DiscountRelationBuilder,
    uri != undefined ? uri + 'Discount' : 'http://localhost:5000/odata/Discount',
    DiscountParser,
    DiscountOrderBy("")
  )

  export const FranchiseManagerQuery = (uri?: string) => mk_query<FranchiseManager>(
    FilterBuilderFranchiseManager(), 
    FranchiseManagerRelationBuilder,
    uri != undefined ? uri + 'FranchiseManager' : 'http://localhost:5000/odata/FranchiseManager',
    FranchiseManagerParser,
    FranchiseManagerOrderBy("")
  )

  export const FranchiseManager_RestaurantQuery = (uri?: string) => mk_query<FranchiseManager_Restaurant>(
    FilterBuilderFranchiseManager_Restaurant(), 
    FranchiseManager_RestaurantRelationBuilder,
    uri != undefined ? uri + 'FranchiseManager_Restaurant' : 'http://localhost:5000/odata/FranchiseManager_Restaurant',
    FranchiseManager_RestaurantParser,
    FranchiseManager_RestaurantOrderBy("")
  )

  export const LoginAttemptQuery = (uri?: string) => mk_query<LoginAttempt>(
    FilterBuilderLoginAttempt(), 
    LoginAttemptRelationBuilder,
    uri != undefined ? uri + 'LoginAttempt' : 'http://localhost:5000/odata/LoginAttempt',
    LoginAttemptParser,
    LoginAttemptOrderBy("")
  )

  export const MollieKeysQuery = (uri?: string) => mk_query<MollieKeys>(
    FilterBuilderMollieKeys(), 
    MollieKeysRelationBuilder,
    uri != undefined ? uri + 'MollieKeys' : 'http://localhost:5000/odata/MollieKey',
    MollieKeysParser,
    MollieKeysOrderBy("")
  )

  export const OptionQuery = (uri?: string) => mk_query<Option>(
    FilterBuilderOption(), 
    OptionRelationBuilder,
    uri != undefined ? uri + 'Option' : 'http://localhost:5000/odata/Option',
    OptionParser,
    OptionOrderBy("")
  )

  export const OptionGroupQuery = (uri?: string) => mk_query<OptionGroup>(
    FilterBuilderOptionGroup(), 
    OptionGroupRelationBuilder,
    uri != undefined ? uri + 'OptionGroup' : 'http://localhost:5000/odata/OptionGroup',
    OptionGroupParser,
    OptionGroupOrderBy("")
  )

  export const OptionGroupPositionQuery = (uri?: string) => mk_query<OptionGroupPosition>(
    FilterBuilderOptionGroupPosition(), 
    OptionGroupPositionRelationBuilder,
    uri != undefined ? uri + 'OptionGroupPosition' : 'http://localhost:5000/odata/OptionGroupPosition',
    OptionGroupPositionParser,
    OptionGroupPositionOrderBy("")
  )

  export const OptionGroup_OptionQuery = (uri?: string) => mk_query<OptionGroup_Option>(
    FilterBuilderOptionGroup_Option(), 
    OptionGroup_OptionRelationBuilder,
    uri != undefined ? uri + 'OptionGroup_Option' : 'http://localhost:5000/odata/OptionGroup_Option',
    OptionGroup_OptionParser,
    OptionGroup_OptionOrderBy("")
  )

  export const OptionGroup_OptionGroupPositionQuery = (uri?: string) => mk_query<OptionGroup_OptionGroupPosition>(
    FilterBuilderOptionGroup_OptionGroupPosition(), 
    OptionGroup_OptionGroupPositionRelationBuilder,
    uri != undefined ? uri + 'OptionGroup_OptionGroupPosition' : 'http://localhost:5000/odata/OptionGroup_OptionGroupPosition',
    OptionGroup_OptionGroupPositionParser,
    OptionGroup_OptionGroupPositionOrderBy("")
  )

  export const Option_RestaurantQuery = (uri?: string) => mk_query<Option_Restaurant>(
    FilterBuilderOption_Restaurant(), 
    Option_RestaurantRelationBuilder,
    uri != undefined ? uri + 'Option_Restaurant' : 'http://localhost:5000/odata/Option_Restaurant',
    Option_RestaurantParser,
    Option_RestaurantOrderBy("")
  )

  export const OrderQuery = (uri?: string) => mk_query<Order>(
    FilterBuilderOrder(), 
    OrderRelationBuilder,
    uri != undefined ? uri + 'Order' : 'http://localhost:5000/odata/Order',
    OrderParser,
    OrderOrderBy("")
  )

  export const OrderItemQuery = (uri?: string) => mk_query<OrderItem>(
    FilterBuilderOrderItem(), 
    OrderItemRelationBuilder,
    uri != undefined ? uri + 'OrderItem' : 'http://localhost:5000/odata/OrderItem',
    OrderItemParser,
    OrderItemOrderBy("")
  )

  export const OrderItemOptionQuery = (uri?: string) => mk_query<OrderItemOption>(
    FilterBuilderOrderItemOption(), 
    OrderItemOptionRelationBuilder,
    uri != undefined ? uri + 'OrderItemOption' : 'http://localhost:5000/odata/OrderItemOption',
    OrderItemOptionParser,
    OrderItemOptionOrderBy("")
  )

  export const OrderItemOption_OptionQuery = (uri?: string) => mk_query<OrderItemOption_Option>(
    FilterBuilderOrderItemOption_Option(), 
    OrderItemOption_OptionRelationBuilder,
    uri != undefined ? uri + 'OrderItemOption_Option' : 'http://localhost:5000/odata/OrderItemOption_Option',
    OrderItemOption_OptionParser,
    OrderItemOption_OptionOrderBy("")
  )

  export const OrderItemProductQuery = (uri?: string) => mk_query<OrderItemProduct>(
    FilterBuilderOrderItemProduct(), 
    OrderItemProductRelationBuilder,
    uri != undefined ? uri + 'OrderItemProduct' : 'http://localhost:5000/odata/OrderItemProduct',
    OrderItemProductParser,
    OrderItemProductOrderBy("")
  )

  export const OrderItemProduct_ProductQuery = (uri?: string) => mk_query<OrderItemProduct_Product>(
    FilterBuilderOrderItemProduct_Product(), 
    OrderItemProduct_ProductRelationBuilder,
    uri != undefined ? uri + 'OrderItemProduct_Product' : 'http://localhost:5000/odata/OrderItemProduct_Product',
    OrderItemProduct_ProductParser,
    OrderItemProduct_ProductOrderBy("")
  )

  export const OrderItem_OrderItemOptionQuery = (uri?: string) => mk_query<OrderItem_OrderItemOption>(
    FilterBuilderOrderItem_OrderItemOption(), 
    OrderItem_OrderItemOptionRelationBuilder,
    uri != undefined ? uri + 'OrderItem_OrderItemOption' : 'http://localhost:5000/odata/OrderItem_OrderItemOption',
    OrderItem_OrderItemOptionParser,
    OrderItem_OrderItemOptionOrderBy("")
  )

  export const OrderItem_OrderItemProductQuery = (uri?: string) => mk_query<OrderItem_OrderItemProduct>(
    FilterBuilderOrderItem_OrderItemProduct(), 
    OrderItem_OrderItemProductRelationBuilder,
    uri != undefined ? uri + 'OrderItem_OrderItemProduct' : 'http://localhost:5000/odata/OrderItem_OrderItemProduct',
    OrderItem_OrderItemProductParser,
    OrderItem_OrderItemProductOrderBy("")
  )

  export const Order_DiscountQuery = (uri?: string) => mk_query<Order_Discount>(
    FilterBuilderOrder_Discount(), 
    Order_DiscountRelationBuilder,
    uri != undefined ? uri + 'Order_Discount' : 'http://localhost:5000/odata/Order_Discount',
    Order_DiscountParser,
    Order_DiscountOrderBy("")
  )

  export const Order_OrderItemQuery = (uri?: string) => mk_query<Order_OrderItem>(
    FilterBuilderOrder_OrderItem(), 
    Order_OrderItemRelationBuilder,
    uri != undefined ? uri + 'Order_OrderItem' : 'http://localhost:5000/odata/Order_OrderItem',
    Order_OrderItemParser,
    Order_OrderItemOrderBy("")
  )

  export const Order_PayMethodQuery = (uri?: string) => mk_query<Order_PayMethod>(
    FilterBuilderOrder_PayMethod(), 
    Order_PayMethodRelationBuilder,
    uri != undefined ? uri + 'Order_PayMethod' : 'http://localhost:5000/odata/Order_PayMethod',
    Order_PayMethodParser,
    Order_PayMethodOrderBy("")
  )

  export const Order_RestaurantQuery = (uri?: string) => mk_query<Order_Restaurant>(
    FilterBuilderOrder_Restaurant(), 
    Order_RestaurantRelationBuilder,
    uri != undefined ? uri + 'Order_Restaurant' : 'http://localhost:5000/odata/Order_Restaurant',
    Order_RestaurantParser,
    Order_RestaurantOrderBy("")
  )

  export const Order_UserQuery = (uri?: string) => mk_query<Order_User>(
    FilterBuilderOrder_User(), 
    Order_UserRelationBuilder,
    uri != undefined ? uri + 'Order_User' : 'http://localhost:5000/odata/Order_User',
    Order_UserParser,
    Order_UserOrderBy("")
  )

  export const POS4Query = (uri?: string) => mk_query<POS4>(
    FilterBuilderPOS4(), 
    POS4RelationBuilder,
    uri != undefined ? uri + 'POS4' : 'http://localhost:5000/odata/POS4',
    POS4Parser,
    POS4OrderBy("")
  )

  export const POS4EventQuery = (uri?: string) => mk_query<POS4Event>(
    FilterBuilderPOS4Event(), 
    POS4EventRelationBuilder,
    uri != undefined ? uri + 'POS4Event' : 'http://localhost:5000/odata/POS4Event',
    POS4EventParser,
    POS4EventOrderBy("")
  )

  export const POS4LoginEventQuery = (uri?: string) => mk_query<POS4LoginEvent>(
    FilterBuilderPOS4LoginEvent(), 
    POS4LoginEventRelationBuilder,
    uri != undefined ? uri + 'POS4LoginEvent' : 'http://localhost:5000/odata/POS4LoginEvent',
    POS4LoginEventParser,
    POS4LoginEventOrderBy("")
  )

  export const POS4OrderFetchEventQuery = (uri?: string) => mk_query<POS4OrderFetchEvent>(
    FilterBuilderPOS4OrderFetchEvent(), 
    POS4OrderFetchEventRelationBuilder,
    uri != undefined ? uri + 'POS4OrderFetchEvent' : 'http://localhost:5000/odata/POS4OrderFetchEvent',
    POS4OrderFetchEventParser,
    POS4OrderFetchEventOrderBy("")
  )

  export const POS4_POS4LoginEventQuery = (uri?: string) => mk_query<POS4_POS4LoginEvent>(
    FilterBuilderPOS4_POS4LoginEvent(), 
    POS4_POS4LoginEventRelationBuilder,
    uri != undefined ? uri + 'POS4_POS4LoginEvent' : 'http://localhost:5000/odata/POS4_POS4LoginEvent',
    POS4_POS4LoginEventParser,
    POS4_POS4LoginEventOrderBy("")
  )

  export const POS4_POS4OrderFetchEventQuery = (uri?: string) => mk_query<POS4_POS4OrderFetchEvent>(
    FilterBuilderPOS4_POS4OrderFetchEvent(), 
    POS4_POS4OrderFetchEventRelationBuilder,
    uri != undefined ? uri + 'POS4_POS4OrderFetchEvent' : 'http://localhost:5000/odata/POS4_POS4OrderFetchEvent',
    POS4_POS4OrderFetchEventParser,
    POS4_POS4OrderFetchEventOrderBy("")
  )

  export const PayMethodQuery = (uri?: string) => mk_query<PayMethod>(
    FilterBuilderPayMethod(), 
    PayMethodRelationBuilder,
    uri != undefined ? uri + 'PayMethod' : 'http://localhost:5000/odata/PayMethod',
    PayMethodParser,
    PayMethodOrderBy("")
  )

  export const PickUpRestaurant_PickUpPayMethodQuery = (uri?: string) => mk_query<PickUpRestaurant_PickUpPayMethod>(
    FilterBuilderPickUpRestaurant_PickUpPayMethod(), 
    PickUpRestaurant_PickUpPayMethodRelationBuilder,
    uri != undefined ? uri + 'PickUpRestaurant_PickUpPayMethod' : 'http://localhost:5000/odata/PickUpRestaurant_PickUpPayMethod',
    PickUpRestaurant_PickUpPayMethodParser,
    PickUpRestaurant_PickUpPayMethodOrderBy("")
  )

  export const PickupClosingTimeQuery = (uri?: string) => mk_query<PickupClosingTime>(
    FilterBuilderPickupClosingTime(), 
    PickupClosingTimeRelationBuilder,
    uri != undefined ? uri + 'PickupClosingTime' : 'http://localhost:5000/odata/PickupClosingTime',
    PickupClosingTimeParser,
    PickupClosingTimeOrderBy("")
  )

  export const PickupTimesQuery = (uri?: string) => mk_query<PickupTimes>(
    FilterBuilderPickupTimes(), 
    PickupTimesRelationBuilder,
    uri != undefined ? uri + 'PickupTimes' : 'http://localhost:5000/odata/PickupTime',
    PickupTimesParser,
    PickupTimesOrderBy("")
  )

  export const PostCodeExclusionQuery = (uri?: string) => mk_query<PostCodeExclusion>(
    FilterBuilderPostCodeExclusion(), 
    PostCodeExclusionRelationBuilder,
    uri != undefined ? uri + 'PostCodeExclusion' : 'http://localhost:5000/odata/PostCodeExclusion',
    PostCodeExclusionParser,
    PostCodeExclusionOrderBy("")
  )

  export const PostCodeRangeQuery = (uri?: string) => mk_query<PostCodeRange>(
    FilterBuilderPostCodeRange(), 
    PostCodeRangeRelationBuilder,
    uri != undefined ? uri + 'PostCodeRange' : 'http://localhost:5000/odata/PostCodeRange',
    PostCodeRangeParser,
    PostCodeRangeOrderBy("")
  )

  export const PostCodeRange_PostCodeExclusionQuery = (uri?: string) => mk_query<PostCodeRange_PostCodeExclusion>(
    FilterBuilderPostCodeRange_PostCodeExclusion(), 
    PostCodeRange_PostCodeExclusionRelationBuilder,
    uri != undefined ? uri + 'PostCodeRange_PostCodeExclusion' : 'http://localhost:5000/odata/PostCodeRange_PostCodeExclusion',
    PostCodeRange_PostCodeExclusionParser,
    PostCodeRange_PostCodeExclusionOrderBy("")
  )

  export const ProductQuery = (uri?: string) => mk_query<Product>(
    FilterBuilderProduct(), 
    ProductRelationBuilder,
    uri != undefined ? uri + 'Product' : 'http://localhost:5000/odata/Product',
    ProductParser,
    ProductOrderBy("")
  )

  export const ProductPositionQuery = (uri?: string) => mk_query<ProductPosition>(
    FilterBuilderProductPosition(), 
    ProductPositionRelationBuilder,
    uri != undefined ? uri + 'ProductPosition' : 'http://localhost:5000/odata/ProductPosition',
    ProductPositionParser,
    ProductPositionOrderBy("")
  )

  export const Product_DiscountQuery = (uri?: string) => mk_query<Product_Discount>(
    FilterBuilderProduct_Discount(), 
    Product_DiscountRelationBuilder,
    uri != undefined ? uri + 'Product_Discount' : 'http://localhost:5000/odata/Product_Discount',
    Product_DiscountParser,
    Product_DiscountOrderBy("")
  )

  export const Product_ImageBigDataQuery = (uri?: string) => mk_query<Product_ImageBigData>(
    FilterBuilderProduct_ImageBigData(), 
    Product_ImageBigDataRelationBuilder,
    uri != undefined ? uri + 'Product_ImageBigData' : 'http://localhost:5000/odata/Product_ImageBigDatum',
    Product_ImageBigDataParser,
    Product_ImageBigDataOrderBy("")
  )

  export const Product_ImageSmallDataQuery = (uri?: string) => mk_query<Product_ImageSmallData>(
    FilterBuilderProduct_ImageSmallData(), 
    Product_ImageSmallDataRelationBuilder,
    uri != undefined ? uri + 'Product_ImageSmallData' : 'http://localhost:5000/odata/Product_ImageSmallDatum',
    Product_ImageSmallDataParser,
    Product_ImageSmallDataOrderBy("")
  )

  export const Product_OptionGroupPositionQuery = (uri?: string) => mk_query<Product_OptionGroupPosition>(
    FilterBuilderProduct_OptionGroupPosition(), 
    Product_OptionGroupPositionRelationBuilder,
    uri != undefined ? uri + 'Product_OptionGroupPosition' : 'http://localhost:5000/odata/Product_OptionGroupPosition',
    Product_OptionGroupPositionParser,
    Product_OptionGroupPositionOrderBy("")
  )

  export const Product_ProductPositionQuery = (uri?: string) => mk_query<Product_ProductPosition>(
    FilterBuilderProduct_ProductPosition(), 
    Product_ProductPositionRelationBuilder,
    uri != undefined ? uri + 'Product_ProductPosition' : 'http://localhost:5000/odata/Product_ProductPosition',
    Product_ProductPositionParser,
    Product_ProductPositionOrderBy("")
  )

  export const Product_RestaurantQuery = (uri?: string) => mk_query<Product_Restaurant>(
    FilterBuilderProduct_Restaurant(), 
    Product_RestaurantRelationBuilder,
    uri != undefined ? uri + 'Product_Restaurant' : 'http://localhost:5000/odata/Product_Restaurant',
    Product_RestaurantParser,
    Product_RestaurantOrderBy("")
  )

  export const RestaurantQuery = (uri?: string) => mk_query<Restaurant>(
    FilterBuilderRestaurant(), 
    RestaurantRelationBuilder,
    uri != undefined ? uri + 'Restaurant' : 'http://localhost:5000/odata/Restaurant',
    RestaurantParser,
    RestaurantOrderBy("")
  )

  export const Restaurant_CompanyQuery = (uri?: string) => mk_query<Restaurant_Company>(
    FilterBuilderRestaurant_Company(), 
    Restaurant_CompanyRelationBuilder,
    uri != undefined ? uri + 'Restaurant_Company' : 'http://localhost:5000/odata/Restaurant_Company',
    Restaurant_CompanyParser,
    Restaurant_CompanyOrderBy("")
  )

  export const Restaurant_DeliveryClosingTimeQuery = (uri?: string) => mk_query<Restaurant_DeliveryClosingTime>(
    FilterBuilderRestaurant_DeliveryClosingTime(), 
    Restaurant_DeliveryClosingTimeRelationBuilder,
    uri != undefined ? uri + 'Restaurant_DeliveryClosingTime' : 'http://localhost:5000/odata/Restaurant_DeliveryClosingTime',
    Restaurant_DeliveryClosingTimeParser,
    Restaurant_DeliveryClosingTimeOrderBy("")
  )

  export const Restaurant_DeliveryTimesQuery = (uri?: string) => mk_query<Restaurant_DeliveryTimes>(
    FilterBuilderRestaurant_DeliveryTimes(), 
    Restaurant_DeliveryTimesRelationBuilder,
    uri != undefined ? uri + 'Restaurant_DeliveryTimes' : 'http://localhost:5000/odata/Restaurant_DeliveryTime',
    Restaurant_DeliveryTimesParser,
    Restaurant_DeliveryTimesOrderBy("")
  )

  export const Restaurant_DiscountQuery = (uri?: string) => mk_query<Restaurant_Discount>(
    FilterBuilderRestaurant_Discount(), 
    Restaurant_DiscountRelationBuilder,
    uri != undefined ? uri + 'Restaurant_Discount' : 'http://localhost:5000/odata/Restaurant_Discount',
    Restaurant_DiscountParser,
    Restaurant_DiscountOrderBy("")
  )

  export const Restaurant_MollieKeysQuery = (uri?: string) => mk_query<Restaurant_MollieKeys>(
    FilterBuilderRestaurant_MollieKeys(), 
    Restaurant_MollieKeysRelationBuilder,
    uri != undefined ? uri + 'Restaurant_MollieKeys' : 'http://localhost:5000/odata/Restaurant_MollieKey',
    Restaurant_MollieKeysParser,
    Restaurant_MollieKeysOrderBy("")
  )

  export const Restaurant_POS4Query = (uri?: string) => mk_query<Restaurant_POS4>(
    FilterBuilderRestaurant_POS4(), 
    Restaurant_POS4RelationBuilder,
    uri != undefined ? uri + 'Restaurant_POS4' : 'http://localhost:5000/odata/Restaurant_POS4',
    Restaurant_POS4Parser,
    Restaurant_POS4OrderBy("")
  )

  export const Restaurant_PickupClosingTimeQuery = (uri?: string) => mk_query<Restaurant_PickupClosingTime>(
    FilterBuilderRestaurant_PickupClosingTime(), 
    Restaurant_PickupClosingTimeRelationBuilder,
    uri != undefined ? uri + 'Restaurant_PickupClosingTime' : 'http://localhost:5000/odata/Restaurant_PickupClosingTime',
    Restaurant_PickupClosingTimeParser,
    Restaurant_PickupClosingTimeOrderBy("")
  )

  export const Restaurant_PickupTimesQuery = (uri?: string) => mk_query<Restaurant_PickupTimes>(
    FilterBuilderRestaurant_PickupTimes(), 
    Restaurant_PickupTimesRelationBuilder,
    uri != undefined ? uri + 'Restaurant_PickupTimes' : 'http://localhost:5000/odata/Restaurant_PickupTime',
    Restaurant_PickupTimesParser,
    Restaurant_PickupTimesOrderBy("")
  )

  export const Restaurant_PostCodeRangeQuery = (uri?: string) => mk_query<Restaurant_PostCodeRange>(
    FilterBuilderRestaurant_PostCodeRange(), 
    Restaurant_PostCodeRangeRelationBuilder,
    uri != undefined ? uri + 'Restaurant_PostCodeRange' : 'http://localhost:5000/odata/Restaurant_PostCodeRange',
    Restaurant_PostCodeRangeParser,
    Restaurant_PostCodeRangeOrderBy("")
  )

  export const Restaurant_WaitingTimesQuery = (uri?: string) => mk_query<Restaurant_WaitingTimes>(
    FilterBuilderRestaurant_WaitingTimes(), 
    Restaurant_WaitingTimesRelationBuilder,
    uri != undefined ? uri + 'Restaurant_WaitingTimes' : 'http://localhost:5000/odata/Restaurant_WaitingTime',
    Restaurant_WaitingTimesParser,
    Restaurant_WaitingTimesOrderBy("")
  )

  export const SessionQuery = (uri?: string) => mk_query<Session>(
    FilterBuilderSession(), 
    SessionRelationBuilder,
    uri != undefined ? uri + 'Session' : 'http://localhost:5000/odata/Session',
    SessionParser,
    SessionOrderBy("")
  )

  export const UserQuery = (uri?: string) => mk_query<User>(
    FilterBuilderUser(), 
    UserRelationBuilder,
    uri != undefined ? uri + 'User' : 'http://localhost:5000/odata/User',
    UserParser,
    UserOrderBy("")
  )

  export const User_AlternativeAddressQuery = (uri?: string) => mk_query<User_AlternativeAddress>(
    FilterBuilderUser_AlternativeAddress(), 
    User_AlternativeAddressRelationBuilder,
    uri != undefined ? uri + 'User_AlternativeAddress' : 'http://localhost:5000/odata/User_AlternativeAddress',
    User_AlternativeAddressParser,
    User_AlternativeAddressOrderBy("")
  )

  export const User_DefaultAddressQuery = (uri?: string) => mk_query<User_DefaultAddress>(
    FilterBuilderUser_DefaultAddress(), 
    User_DefaultAddressRelationBuilder,
    uri != undefined ? uri + 'User_DefaultAddress' : 'http://localhost:5000/odata/User_DefaultAddress',
    User_DefaultAddressParser,
    User_DefaultAddressOrderBy("")
  )

  export const WaitingTimesQuery = (uri?: string) => mk_query<WaitingTimes>(
    FilterBuilderWaitingTimes(), 
    WaitingTimesRelationBuilder,
    uri != undefined ? uri + 'WaitingTimes' : 'http://localhost:5000/odata/WaitingTime',
    WaitingTimesParser,
    WaitingTimesOrderBy("")
  )

  export const DataProtectionKeyQuery = (uri?: string) => mk_query<DataProtectionKey>(
    FilterBuilderDataProtectionKey(), 
    DataProtectionKeyRelationBuilder,
    uri != undefined ? uri + 'DataProtectionKey' : 'http://localhost:5000/odata/DataProtectionKey',
    DataProtectionKeyParser,
    DataProtectionKeyOrderBy("")
  )



export type Address = {
    Id: number
    CreatedDate: Date
    IsDefault: boolean
    Street: string | null
    Number: string | null
    Postcode: string | null
    City: string | null
} & {

}


function FilterBuilderAddress(prefix?: string) : FilterBuilderComplex<Address> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      IsDefault: new FilterBuilderBoolean(mk_prefix('IsDefault', prefix)),
      Street: new FilterBuilderString(mk_prefix('Street', prefix)),
      Number: new FilterBuilderString(mk_prefix('Number', prefix)),
      Postcode: new FilterBuilderString(mk_prefix('Postcode', prefix)),
      City: new FilterBuilderString(mk_prefix('City', prefix)),
    
  }
}


const AddressRelationBuilder: RelationBuilder<RelationsOf<Address>> = {

}


const  AddressParser : EntityParser<Address>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      IsDefault: parseBoolean,
      Street: parseNullString,
      Number: parseNullString,
      Postcode: parseNullString,
      City: parseNullString,
  
}


const AddressOrderBy : (p: string) => OrderByBuilderComplex<Address> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      IsDefault:  new OrderByProp(p ? p + "/IsDefault" : "IsDefault"),
      Street:  new OrderByProp(p ? p + "/Street" : "Street"),
      Number:  new OrderByProp(p ? p + "/Number" : "Number"),
      Postcode:  new OrderByProp(p ? p + "/Postcode" : "Postcode"),
      City:  new OrderByProp(p ? p + "/City" : "City"),
  
})



export type Admin = {
    Id: number
    CreatedDate: Date
    Username: string | null
    Email: string | null
    Language: string | null
    PasswordHash: string | null
    PasswordSalt: string | null
    LastLoginAttempt: Date
    SecurityStamp: string | null
    EmailConfirmed: boolean
} & {

}


function FilterBuilderAdmin(prefix?: string) : FilterBuilderComplex<Admin> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Username: new FilterBuilderString(mk_prefix('Username', prefix)),
      Email: new FilterBuilderString(mk_prefix('Email', prefix)),
      Language: new FilterBuilderString(mk_prefix('Language', prefix)),
      PasswordHash: new FilterBuilderString(mk_prefix('PasswordHash', prefix)),
      PasswordSalt: new FilterBuilderString(mk_prefix('PasswordSalt', prefix)),
      LastLoginAttempt: new FilterBuilderDate(mk_prefix('LastLoginAttempt', prefix)),
      SecurityStamp: new FilterBuilderString(mk_prefix('SecurityStamp', prefix)),
      EmailConfirmed: new FilterBuilderBoolean(mk_prefix('EmailConfirmed', prefix)),
    
  }
}


const AdminRelationBuilder: RelationBuilder<RelationsOf<Admin>> = {

}


const  AdminParser : EntityParser<Admin>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Username: parseNullString,
      Email: parseNullString,
      Language: parseNullString,
      PasswordHash: parseNullString,
      PasswordSalt: parseNullString,
      LastLoginAttempt: parseDate,
      SecurityStamp: parseNullString,
      EmailConfirmed: parseBoolean,
  
}


const AdminOrderBy : (p: string) => OrderByBuilderComplex<Admin> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Username:  new OrderByProp(p ? p + "/Username" : "Username"),
      Email:  new OrderByProp(p ? p + "/Email" : "Email"),
      Language:  new OrderByProp(p ? p + "/Language" : "Language"),
      PasswordHash:  new OrderByProp(p ? p + "/PasswordHash" : "PasswordHash"),
      PasswordSalt:  new OrderByProp(p ? p + "/PasswordSalt" : "PasswordSalt"),
      LastLoginAttempt:  new OrderByProp(p ? p + "/LastLoginAttempt" : "LastLoginAttempt"),
      SecurityStamp:  new OrderByProp(p ? p + "/SecurityStamp" : "SecurityStamp"),
      EmailConfirmed:  new OrderByProp(p ? p + "/EmailConfirmed" : "EmailConfirmed"),
  
})



export type AlternativeAddress = {
    Id: number
    CreatedDate: Date
    IsDefault: boolean
    Street: string | null
    Number: string | null
    Postcode: string | null
    City: string | null
} & {
  User_AlternativeAddress: List<User_AlternativeAddress>
}


function FilterBuilderAlternativeAddress(prefix?: string) : FilterBuilderComplex<AlternativeAddress> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      IsDefault: new FilterBuilderBoolean(mk_prefix('IsDefault', prefix)),
      Street: new FilterBuilderString(mk_prefix('Street', prefix)),
      Number: new FilterBuilderString(mk_prefix('Number', prefix)),
      Postcode: new FilterBuilderString(mk_prefix('Postcode', prefix)),
      City: new FilterBuilderString(mk_prefix('City', prefix)),
    User_AlternativeAddress: new FilterBuilderCollection<User_AlternativeAddress>(mk_prefix('User_AlternativeAddress', prefix), s => FilterBuilderUser_AlternativeAddress(s)),
  }
}


const AlternativeAddressRelationBuilder: RelationBuilder<RelationsOf<AlternativeAddress>> = {
User_AlternativeAddress: () => mk_rel_query(
    'User_AlternativeAddress',
    FilterBuilderUser_AlternativeAddress(),
    User_AlternativeAddressRelationBuilder,
    User_AlternativeAddressParser
  ),

}


const  AlternativeAddressParser : EntityParser<AlternativeAddress>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      IsDefault: parseBoolean,
      Street: parseNullString,
      Number: parseNullString,
      Postcode: parseNullString,
      City: parseNullString,
  User_AlternativeAddress: p => parseCollection(x => parseEntity(x, p)),
}


const AlternativeAddressOrderBy : (p: string) => OrderByBuilderComplex<AlternativeAddress> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      IsDefault:  new OrderByProp(p ? p + "/IsDefault" : "IsDefault"),
      Street:  new OrderByProp(p ? p + "/Street" : "Street"),
      Number:  new OrderByProp(p ? p + "/Number" : "Number"),
      Postcode:  new OrderByProp(p ? p + "/Postcode" : "Postcode"),
      City:  new OrderByProp(p ? p + "/City" : "City"),
  User_AlternativeAddress:  p => User_AlternativeAddressOrderBy(p ? p + "/User_AlternativeAddress" : "User_AlternativeAddress"),
})



export type Application = {
    Id: number
    CreatedDate: Date
} & {

}


function FilterBuilderApplication(prefix?: string) : FilterBuilderComplex<Application> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
    
  }
}


const ApplicationRelationBuilder: RelationBuilder<RelationsOf<Application>> = {

}


const  ApplicationParser : EntityParser<Application>= {
  Id: parseNumber,
      CreatedDate: parseDate,
  
}


const ApplicationOrderBy : (p: string) => OrderByBuilderComplex<Application> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
  
})



export type Category = {
    Id: number
    CreatedDate: Date
    WedStartMinute: number
    SatStartHour: number
    FriEndHour: number
    TueStartHour: number
    MonEndMinute: number
    SunEndMinute: number
    FriActive: boolean
    ThuStartHour: number
    Active: boolean
    ThuActive: boolean
    InternalName: string | null
    FriStartHour: number
    WedEndHour: number
    Position: number
    SatEndMinute: number
    TueActive: boolean
    MonStartMinute: number
    SunStartMinute: number
    TueEndMinute: number
    Name: string | null
    SatActive: boolean
    ThuEndMinute: number
    SatStartMinute: number
    WedStartHour: number
    SunActive: boolean
    TueStartMinute: number
    MonActive: boolean
    FriEndMinute: number
    MonEndHour: number
    SunEndHour: number
    ThuStartMinute: number
    WedEndMinute: number
    SatEndHour: number
    FriStartMinute: number
    SunStartHour: number
    MonStartHour: number
    TueEndHour: number
    WedActive: boolean
    Description: string | null
    ThuEndHour: number
} & {
  Category_ProductPosition: List<Category_ProductPosition>
    Category_Restaurant: List<Category_Restaurant>
}


function FilterBuilderCategory(prefix?: string) : FilterBuilderComplex<Category> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      WedStartMinute: new FilterBuilderNumber(mk_prefix('WedStartMinute', prefix)),
      SatStartHour: new FilterBuilderNumber(mk_prefix('SatStartHour', prefix)),
      FriEndHour: new FilterBuilderNumber(mk_prefix('FriEndHour', prefix)),
      TueStartHour: new FilterBuilderNumber(mk_prefix('TueStartHour', prefix)),
      MonEndMinute: new FilterBuilderNumber(mk_prefix('MonEndMinute', prefix)),
      SunEndMinute: new FilterBuilderNumber(mk_prefix('SunEndMinute', prefix)),
      FriActive: new FilterBuilderBoolean(mk_prefix('FriActive', prefix)),
      ThuStartHour: new FilterBuilderNumber(mk_prefix('ThuStartHour', prefix)),
      Active: new FilterBuilderBoolean(mk_prefix('Active', prefix)),
      ThuActive: new FilterBuilderBoolean(mk_prefix('ThuActive', prefix)),
      InternalName: new FilterBuilderString(mk_prefix('InternalName', prefix)),
      FriStartHour: new FilterBuilderNumber(mk_prefix('FriStartHour', prefix)),
      WedEndHour: new FilterBuilderNumber(mk_prefix('WedEndHour', prefix)),
      Position: new FilterBuilderNumber(mk_prefix('Position', prefix)),
      SatEndMinute: new FilterBuilderNumber(mk_prefix('SatEndMinute', prefix)),
      TueActive: new FilterBuilderBoolean(mk_prefix('TueActive', prefix)),
      MonStartMinute: new FilterBuilderNumber(mk_prefix('MonStartMinute', prefix)),
      SunStartMinute: new FilterBuilderNumber(mk_prefix('SunStartMinute', prefix)),
      TueEndMinute: new FilterBuilderNumber(mk_prefix('TueEndMinute', prefix)),
      Name: new FilterBuilderString(mk_prefix('Name', prefix)),
      SatActive: new FilterBuilderBoolean(mk_prefix('SatActive', prefix)),
      ThuEndMinute: new FilterBuilderNumber(mk_prefix('ThuEndMinute', prefix)),
      SatStartMinute: new FilterBuilderNumber(mk_prefix('SatStartMinute', prefix)),
      WedStartHour: new FilterBuilderNumber(mk_prefix('WedStartHour', prefix)),
      SunActive: new FilterBuilderBoolean(mk_prefix('SunActive', prefix)),
      TueStartMinute: new FilterBuilderNumber(mk_prefix('TueStartMinute', prefix)),
      MonActive: new FilterBuilderBoolean(mk_prefix('MonActive', prefix)),
      FriEndMinute: new FilterBuilderNumber(mk_prefix('FriEndMinute', prefix)),
      MonEndHour: new FilterBuilderNumber(mk_prefix('MonEndHour', prefix)),
      SunEndHour: new FilterBuilderNumber(mk_prefix('SunEndHour', prefix)),
      ThuStartMinute: new FilterBuilderNumber(mk_prefix('ThuStartMinute', prefix)),
      WedEndMinute: new FilterBuilderNumber(mk_prefix('WedEndMinute', prefix)),
      SatEndHour: new FilterBuilderNumber(mk_prefix('SatEndHour', prefix)),
      FriStartMinute: new FilterBuilderNumber(mk_prefix('FriStartMinute', prefix)),
      SunStartHour: new FilterBuilderNumber(mk_prefix('SunStartHour', prefix)),
      MonStartHour: new FilterBuilderNumber(mk_prefix('MonStartHour', prefix)),
      TueEndHour: new FilterBuilderNumber(mk_prefix('TueEndHour', prefix)),
      WedActive: new FilterBuilderBoolean(mk_prefix('WedActive', prefix)),
      Description: new FilterBuilderString(mk_prefix('Description', prefix)),
      ThuEndHour: new FilterBuilderNumber(mk_prefix('ThuEndHour', prefix)),
    Category_ProductPosition: new FilterBuilderCollection<Category_ProductPosition>(mk_prefix('Category_ProductPosition', prefix), s => FilterBuilderCategory_ProductPosition(s)),
      Category_Restaurant: new FilterBuilderCollection<Category_Restaurant>(mk_prefix('Category_Restaurant', prefix), s => FilterBuilderCategory_Restaurant(s)),
  }
}


const CategoryRelationBuilder: RelationBuilder<RelationsOf<Category>> = {
Category_ProductPosition: () => mk_rel_query(
    'Category_ProductPosition',
    FilterBuilderCategory_ProductPosition(),
    Category_ProductPositionRelationBuilder,
    Category_ProductPositionParser
  ),

Category_Restaurant: () => mk_rel_query(
    'Category_Restaurant',
    FilterBuilderCategory_Restaurant(),
    Category_RestaurantRelationBuilder,
    Category_RestaurantParser
  ),

}


const  CategoryParser : EntityParser<Category>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      WedStartMinute: parseNumber,
      SatStartHour: parseNumber,
      FriEndHour: parseNumber,
      TueStartHour: parseNumber,
      MonEndMinute: parseNumber,
      SunEndMinute: parseNumber,
      FriActive: parseBoolean,
      ThuStartHour: parseNumber,
      Active: parseBoolean,
      ThuActive: parseBoolean,
      InternalName: parseNullString,
      FriStartHour: parseNumber,
      WedEndHour: parseNumber,
      Position: parseNumber,
      SatEndMinute: parseNumber,
      TueActive: parseBoolean,
      MonStartMinute: parseNumber,
      SunStartMinute: parseNumber,
      TueEndMinute: parseNumber,
      Name: parseNullString,
      SatActive: parseBoolean,
      ThuEndMinute: parseNumber,
      SatStartMinute: parseNumber,
      WedStartHour: parseNumber,
      SunActive: parseBoolean,
      TueStartMinute: parseNumber,
      MonActive: parseBoolean,
      FriEndMinute: parseNumber,
      MonEndHour: parseNumber,
      SunEndHour: parseNumber,
      ThuStartMinute: parseNumber,
      WedEndMinute: parseNumber,
      SatEndHour: parseNumber,
      FriStartMinute: parseNumber,
      SunStartHour: parseNumber,
      MonStartHour: parseNumber,
      TueEndHour: parseNumber,
      WedActive: parseBoolean,
      Description: parseNullString,
      ThuEndHour: parseNumber,
  Category_ProductPosition: p => parseCollection(x => parseEntity(x, p)),
      Category_Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const CategoryOrderBy : (p: string) => OrderByBuilderComplex<Category> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      WedStartMinute:  new OrderByProp(p ? p + "/WedStartMinute" : "WedStartMinute"),
      SatStartHour:  new OrderByProp(p ? p + "/SatStartHour" : "SatStartHour"),
      FriEndHour:  new OrderByProp(p ? p + "/FriEndHour" : "FriEndHour"),
      TueStartHour:  new OrderByProp(p ? p + "/TueStartHour" : "TueStartHour"),
      MonEndMinute:  new OrderByProp(p ? p + "/MonEndMinute" : "MonEndMinute"),
      SunEndMinute:  new OrderByProp(p ? p + "/SunEndMinute" : "SunEndMinute"),
      FriActive:  new OrderByProp(p ? p + "/FriActive" : "FriActive"),
      ThuStartHour:  new OrderByProp(p ? p + "/ThuStartHour" : "ThuStartHour"),
      Active:  new OrderByProp(p ? p + "/Active" : "Active"),
      ThuActive:  new OrderByProp(p ? p + "/ThuActive" : "ThuActive"),
      InternalName:  new OrderByProp(p ? p + "/InternalName" : "InternalName"),
      FriStartHour:  new OrderByProp(p ? p + "/FriStartHour" : "FriStartHour"),
      WedEndHour:  new OrderByProp(p ? p + "/WedEndHour" : "WedEndHour"),
      Position:  new OrderByProp(p ? p + "/Position" : "Position"),
      SatEndMinute:  new OrderByProp(p ? p + "/SatEndMinute" : "SatEndMinute"),
      TueActive:  new OrderByProp(p ? p + "/TueActive" : "TueActive"),
      MonStartMinute:  new OrderByProp(p ? p + "/MonStartMinute" : "MonStartMinute"),
      SunStartMinute:  new OrderByProp(p ? p + "/SunStartMinute" : "SunStartMinute"),
      TueEndMinute:  new OrderByProp(p ? p + "/TueEndMinute" : "TueEndMinute"),
      Name:  new OrderByProp(p ? p + "/Name" : "Name"),
      SatActive:  new OrderByProp(p ? p + "/SatActive" : "SatActive"),
      ThuEndMinute:  new OrderByProp(p ? p + "/ThuEndMinute" : "ThuEndMinute"),
      SatStartMinute:  new OrderByProp(p ? p + "/SatStartMinute" : "SatStartMinute"),
      WedStartHour:  new OrderByProp(p ? p + "/WedStartHour" : "WedStartHour"),
      SunActive:  new OrderByProp(p ? p + "/SunActive" : "SunActive"),
      TueStartMinute:  new OrderByProp(p ? p + "/TueStartMinute" : "TueStartMinute"),
      MonActive:  new OrderByProp(p ? p + "/MonActive" : "MonActive"),
      FriEndMinute:  new OrderByProp(p ? p + "/FriEndMinute" : "FriEndMinute"),
      MonEndHour:  new OrderByProp(p ? p + "/MonEndHour" : "MonEndHour"),
      SunEndHour:  new OrderByProp(p ? p + "/SunEndHour" : "SunEndHour"),
      ThuStartMinute:  new OrderByProp(p ? p + "/ThuStartMinute" : "ThuStartMinute"),
      WedEndMinute:  new OrderByProp(p ? p + "/WedEndMinute" : "WedEndMinute"),
      SatEndHour:  new OrderByProp(p ? p + "/SatEndHour" : "SatEndHour"),
      FriStartMinute:  new OrderByProp(p ? p + "/FriStartMinute" : "FriStartMinute"),
      SunStartHour:  new OrderByProp(p ? p + "/SunStartHour" : "SunStartHour"),
      MonStartHour:  new OrderByProp(p ? p + "/MonStartHour" : "MonStartHour"),
      TueEndHour:  new OrderByProp(p ? p + "/TueEndHour" : "TueEndHour"),
      WedActive:  new OrderByProp(p ? p + "/WedActive" : "WedActive"),
      Description:  new OrderByProp(p ? p + "/Description" : "Description"),
      ThuEndHour:  new OrderByProp(p ? p + "/ThuEndHour" : "ThuEndHour"),
  Category_ProductPosition:  p => Category_ProductPositionOrderBy(p ? p + "/Category_ProductPosition" : "Category_ProductPosition"),
      Category_Restaurant:  p => Category_RestaurantOrderBy(p ? p + "/Category_Restaurant" : "Category_Restaurant"),
})



export type Category_ProductPosition = {
    Id: number
    CategoryId: number
    ProductPositionId: number
} & {
  Category: List<Category>
    ProductPosition: List<ProductPosition>
}


function FilterBuilderCategory_ProductPosition(prefix?: string) : FilterBuilderComplex<Category_ProductPosition> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CategoryId: new FilterBuilderNumber(mk_prefix('CategoryId', prefix)),
      ProductPositionId: new FilterBuilderNumber(mk_prefix('ProductPositionId', prefix)),
    Category: new FilterBuilderCollection<Category>(mk_prefix('Category', prefix), s => FilterBuilderCategory(s)),
      ProductPosition: new FilterBuilderCollection<ProductPosition>(mk_prefix('ProductPosition', prefix), s => FilterBuilderProductPosition(s)),
  }
}


const Category_ProductPositionRelationBuilder: RelationBuilder<RelationsOf<Category_ProductPosition>> = {
Category: () => mk_rel_query(
    'Category',
    FilterBuilderCategory(),
    CategoryRelationBuilder,
    CategoryParser
  ),

ProductPosition: () => mk_rel_query(
    'ProductPosition',
    FilterBuilderProductPosition(),
    ProductPositionRelationBuilder,
    ProductPositionParser
  ),

}


const  Category_ProductPositionParser : EntityParser<Category_ProductPosition>= {
  Id: parseNumber,
      CategoryId: parseNumber,
      ProductPositionId: parseNumber,
  Category: p => parseCollection(x => parseEntity(x, p)),
      ProductPosition: p => parseCollection(x => parseEntity(x, p)),
}


const Category_ProductPositionOrderBy : (p: string) => OrderByBuilderComplex<Category_ProductPosition> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CategoryId:  new OrderByProp(p ? p + "/CategoryId" : "CategoryId"),
      ProductPositionId:  new OrderByProp(p ? p + "/ProductPositionId" : "ProductPositionId"),
  Category:  p => CategoryOrderBy(p ? p + "/Category" : "Category"),
      ProductPosition:  p => ProductPositionOrderBy(p ? p + "/ProductPosition" : "ProductPosition"),
})



export type Category_Restaurant = {
    Id: number
    CategoryId: number
    RestaurantId: number
} & {
  Category: List<Category>
    Restaurant: List<Restaurant>
}


function FilterBuilderCategory_Restaurant(prefix?: string) : FilterBuilderComplex<Category_Restaurant> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CategoryId: new FilterBuilderNumber(mk_prefix('CategoryId', prefix)),
      RestaurantId: new FilterBuilderNumber(mk_prefix('RestaurantId', prefix)),
    Category: new FilterBuilderCollection<Category>(mk_prefix('Category', prefix), s => FilterBuilderCategory(s)),
      Restaurant: new FilterBuilderCollection<Restaurant>(mk_prefix('Restaurant', prefix), s => FilterBuilderRestaurant(s)),
  }
}


const Category_RestaurantRelationBuilder: RelationBuilder<RelationsOf<Category_Restaurant>> = {
Category: () => mk_rel_query(
    'Category',
    FilterBuilderCategory(),
    CategoryRelationBuilder,
    CategoryParser
  ),

Restaurant: () => mk_rel_query(
    'Restaurant',
    FilterBuilderRestaurant(),
    RestaurantRelationBuilder,
    RestaurantParser
  ),

}


const  Category_RestaurantParser : EntityParser<Category_Restaurant>= {
  Id: parseNumber,
      CategoryId: parseNumber,
      RestaurantId: parseNumber,
  Category: p => parseCollection(x => parseEntity(x, p)),
      Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const Category_RestaurantOrderBy : (p: string) => OrderByBuilderComplex<Category_Restaurant> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CategoryId:  new OrderByProp(p ? p + "/CategoryId" : "CategoryId"),
      RestaurantId:  new OrderByProp(p ? p + "/RestaurantId" : "RestaurantId"),
  Category:  p => CategoryOrderBy(p ? p + "/Category" : "Category"),
      Restaurant:  p => RestaurantOrderBy(p ? p + "/Restaurant" : "Restaurant"),
})



export type Company = {
    Id: number
    CreatedDate: Date
    InvoiceMailbox: string | null
    KVKNumber: string | null
    Inactive: boolean
    Name: string | null
    Postcode: string | null
    InvoiceEmail: string | null
    InvoiceStreet: string | null
    InvoiceCity: string | null
    InvoicePostCode: string | null
    InvoiceCompanyName: string | null
    InvoiceHouseNumber: string | null
    Comment: string | null
    InvoiceDepartment: string | null
} & {
  Company_User: List<Company_User>
    Restaurant_Company: List<Restaurant_Company>
}


function FilterBuilderCompany(prefix?: string) : FilterBuilderComplex<Company> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      InvoiceMailbox: new FilterBuilderString(mk_prefix('InvoiceMailbox', prefix)),
      KVKNumber: new FilterBuilderString(mk_prefix('KVKNumber', prefix)),
      Inactive: new FilterBuilderBoolean(mk_prefix('Inactive', prefix)),
      Name: new FilterBuilderString(mk_prefix('Name', prefix)),
      Postcode: new FilterBuilderString(mk_prefix('Postcode', prefix)),
      InvoiceEmail: new FilterBuilderString(mk_prefix('InvoiceEmail', prefix)),
      InvoiceStreet: new FilterBuilderString(mk_prefix('InvoiceStreet', prefix)),
      InvoiceCity: new FilterBuilderString(mk_prefix('InvoiceCity', prefix)),
      InvoicePostCode: new FilterBuilderString(mk_prefix('InvoicePostCode', prefix)),
      InvoiceCompanyName: new FilterBuilderString(mk_prefix('InvoiceCompanyName', prefix)),
      InvoiceHouseNumber: new FilterBuilderString(mk_prefix('InvoiceHouseNumber', prefix)),
      Comment: new FilterBuilderString(mk_prefix('Comment', prefix)),
      InvoiceDepartment: new FilterBuilderString(mk_prefix('InvoiceDepartment', prefix)),
    Company_User: new FilterBuilderCollection<Company_User>(mk_prefix('Company_User', prefix), s => FilterBuilderCompany_User(s)),
      Restaurant_Company: new FilterBuilderCollection<Restaurant_Company>(mk_prefix('Restaurant_Company', prefix), s => FilterBuilderRestaurant_Company(s)),
  }
}


const CompanyRelationBuilder: RelationBuilder<RelationsOf<Company>> = {
Company_User: () => mk_rel_query(
    'Company_User',
    FilterBuilderCompany_User(),
    Company_UserRelationBuilder,
    Company_UserParser
  ),

Restaurant_Company: () => mk_rel_query(
    'Restaurant_Company',
    FilterBuilderRestaurant_Company(),
    Restaurant_CompanyRelationBuilder,
    Restaurant_CompanyParser
  ),

}


const  CompanyParser : EntityParser<Company>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      InvoiceMailbox: parseNullString,
      KVKNumber: parseNullString,
      Inactive: parseBoolean,
      Name: parseNullString,
      Postcode: parseNullString,
      InvoiceEmail: parseNullString,
      InvoiceStreet: parseNullString,
      InvoiceCity: parseNullString,
      InvoicePostCode: parseNullString,
      InvoiceCompanyName: parseNullString,
      InvoiceHouseNumber: parseNullString,
      Comment: parseNullString,
      InvoiceDepartment: parseNullString,
  Company_User: p => parseCollection(x => parseEntity(x, p)),
      Restaurant_Company: p => parseCollection(x => parseEntity(x, p)),
}


const CompanyOrderBy : (p: string) => OrderByBuilderComplex<Company> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      InvoiceMailbox:  new OrderByProp(p ? p + "/InvoiceMailbox" : "InvoiceMailbox"),
      KVKNumber:  new OrderByProp(p ? p + "/KVKNumber" : "KVKNumber"),
      Inactive:  new OrderByProp(p ? p + "/Inactive" : "Inactive"),
      Name:  new OrderByProp(p ? p + "/Name" : "Name"),
      Postcode:  new OrderByProp(p ? p + "/Postcode" : "Postcode"),
      InvoiceEmail:  new OrderByProp(p ? p + "/InvoiceEmail" : "InvoiceEmail"),
      InvoiceStreet:  new OrderByProp(p ? p + "/InvoiceStreet" : "InvoiceStreet"),
      InvoiceCity:  new OrderByProp(p ? p + "/InvoiceCity" : "InvoiceCity"),
      InvoicePostCode:  new OrderByProp(p ? p + "/InvoicePostCode" : "InvoicePostCode"),
      InvoiceCompanyName:  new OrderByProp(p ? p + "/InvoiceCompanyName" : "InvoiceCompanyName"),
      InvoiceHouseNumber:  new OrderByProp(p ? p + "/InvoiceHouseNumber" : "InvoiceHouseNumber"),
      Comment:  new OrderByProp(p ? p + "/Comment" : "Comment"),
      InvoiceDepartment:  new OrderByProp(p ? p + "/InvoiceDepartment" : "InvoiceDepartment"),
  Company_User:  p => Company_UserOrderBy(p ? p + "/Company_User" : "Company_User"),
      Restaurant_Company:  p => Restaurant_CompanyOrderBy(p ? p + "/Restaurant_Company" : "Restaurant_Company"),
})



export type Company_User = {
    Id: number
    CompanyId: number
    UserId: number
} & {
  Company: List<Company>
    User: List<User>
}


function FilterBuilderCompany_User(prefix?: string) : FilterBuilderComplex<Company_User> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CompanyId: new FilterBuilderNumber(mk_prefix('CompanyId', prefix)),
      UserId: new FilterBuilderNumber(mk_prefix('UserId', prefix)),
    Company: new FilterBuilderCollection<Company>(mk_prefix('Company', prefix), s => FilterBuilderCompany(s)),
      User: new FilterBuilderCollection<User>(mk_prefix('User', prefix), s => FilterBuilderUser(s)),
  }
}


const Company_UserRelationBuilder: RelationBuilder<RelationsOf<Company_User>> = {
Company: () => mk_rel_query(
    'Company',
    FilterBuilderCompany(),
    CompanyRelationBuilder,
    CompanyParser
  ),

User: () => mk_rel_query(
    'User',
    FilterBuilderUser(),
    UserRelationBuilder,
    UserParser
  ),

}


const  Company_UserParser : EntityParser<Company_User>= {
  Id: parseNumber,
      CompanyId: parseNumber,
      UserId: parseNumber,
  Company: p => parseCollection(x => parseEntity(x, p)),
      User: p => parseCollection(x => parseEntity(x, p)),
}


const Company_UserOrderBy : (p: string) => OrderByBuilderComplex<Company_User> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CompanyId:  new OrderByProp(p ? p + "/CompanyId" : "CompanyId"),
      UserId:  new OrderByProp(p ? p + "/UserId" : "UserId"),
  Company:  p => CompanyOrderBy(p ? p + "/Company" : "Company"),
      User:  p => UserOrderBy(p ? p + "/User" : "User"),
})



export type DefaultAddress = {
    Id: number
    CreatedDate: Date
    IsDefault: boolean
    Street: string | null
    Number: string | null
    Postcode: string | null
    City: string | null
} & {
  User_DefaultAddress: List<User_DefaultAddress>
}


function FilterBuilderDefaultAddress(prefix?: string) : FilterBuilderComplex<DefaultAddress> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      IsDefault: new FilterBuilderBoolean(mk_prefix('IsDefault', prefix)),
      Street: new FilterBuilderString(mk_prefix('Street', prefix)),
      Number: new FilterBuilderString(mk_prefix('Number', prefix)),
      Postcode: new FilterBuilderString(mk_prefix('Postcode', prefix)),
      City: new FilterBuilderString(mk_prefix('City', prefix)),
    User_DefaultAddress: new FilterBuilderCollection<User_DefaultAddress>(mk_prefix('User_DefaultAddress', prefix), s => FilterBuilderUser_DefaultAddress(s)),
  }
}


const DefaultAddressRelationBuilder: RelationBuilder<RelationsOf<DefaultAddress>> = {
User_DefaultAddress: () => mk_rel_query(
    'User_DefaultAddress',
    FilterBuilderUser_DefaultAddress(),
    User_DefaultAddressRelationBuilder,
    User_DefaultAddressParser
  ),

}


const  DefaultAddressParser : EntityParser<DefaultAddress>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      IsDefault: parseBoolean,
      Street: parseNullString,
      Number: parseNullString,
      Postcode: parseNullString,
      City: parseNullString,
  User_DefaultAddress: p => parseCollection(x => parseEntity(x, p)),
}


const DefaultAddressOrderBy : (p: string) => OrderByBuilderComplex<DefaultAddress> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      IsDefault:  new OrderByProp(p ? p + "/IsDefault" : "IsDefault"),
      Street:  new OrderByProp(p ? p + "/Street" : "Street"),
      Number:  new OrderByProp(p ? p + "/Number" : "Number"),
      Postcode:  new OrderByProp(p ? p + "/Postcode" : "Postcode"),
      City:  new OrderByProp(p ? p + "/City" : "City"),
  User_DefaultAddress:  p => User_DefaultAddressOrderBy(p ? p + "/User_DefaultAddress" : "User_DefaultAddress"),
})



export type DeliveryClosingTime = {
    Id: number
    CreatedDate: Date
    Start: Date
    End: Date
    Message: string | null
} & {
  Restaurant_DeliveryClosingTime: List<Restaurant_DeliveryClosingTime>
}


function FilterBuilderDeliveryClosingTime(prefix?: string) : FilterBuilderComplex<DeliveryClosingTime> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Start: new FilterBuilderDate(mk_prefix('Start', prefix)),
      End: new FilterBuilderDate(mk_prefix('End', prefix)),
      Message: new FilterBuilderString(mk_prefix('Message', prefix)),
    Restaurant_DeliveryClosingTime: new FilterBuilderCollection<Restaurant_DeliveryClosingTime>(mk_prefix('Restaurant_DeliveryClosingTime', prefix), s => FilterBuilderRestaurant_DeliveryClosingTime(s)),
  }
}


const DeliveryClosingTimeRelationBuilder: RelationBuilder<RelationsOf<DeliveryClosingTime>> = {
Restaurant_DeliveryClosingTime: () => mk_rel_query(
    'Restaurant_DeliveryClosingTime',
    FilterBuilderRestaurant_DeliveryClosingTime(),
    Restaurant_DeliveryClosingTimeRelationBuilder,
    Restaurant_DeliveryClosingTimeParser
  ),

}


const  DeliveryClosingTimeParser : EntityParser<DeliveryClosingTime>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Start: parseDate,
      End: parseDate,
      Message: parseNullString,
  Restaurant_DeliveryClosingTime: p => parseCollection(x => parseEntity(x, p)),
}


const DeliveryClosingTimeOrderBy : (p: string) => OrderByBuilderComplex<DeliveryClosingTime> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Start:  new OrderByProp(p ? p + "/Start" : "Start"),
      End:  new OrderByProp(p ? p + "/End" : "End"),
      Message:  new OrderByProp(p ? p + "/Message" : "Message"),
  Restaurant_DeliveryClosingTime:  p => Restaurant_DeliveryClosingTimeOrderBy(p ? p + "/Restaurant_DeliveryClosingTime" : "Restaurant_DeliveryClosingTime"),
})



export type DeliveryRestaurant_DeliveryPayMethod = {
    Id: number
    DeliveryRestaurantId: number
    DeliveryPayMethodId: number
} & {
  PayMethod: List<PayMethod>
    Restaurant: List<Restaurant>
}


function FilterBuilderDeliveryRestaurant_DeliveryPayMethod(prefix?: string) : FilterBuilderComplex<DeliveryRestaurant_DeliveryPayMethod> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      DeliveryRestaurantId: new FilterBuilderNumber(mk_prefix('DeliveryRestaurantId', prefix)),
      DeliveryPayMethodId: new FilterBuilderNumber(mk_prefix('DeliveryPayMethodId', prefix)),
    PayMethod: new FilterBuilderCollection<PayMethod>(mk_prefix('PayMethod', prefix), s => FilterBuilderPayMethod(s)),
      Restaurant: new FilterBuilderCollection<Restaurant>(mk_prefix('Restaurant', prefix), s => FilterBuilderRestaurant(s)),
  }
}


const DeliveryRestaurant_DeliveryPayMethodRelationBuilder: RelationBuilder<RelationsOf<DeliveryRestaurant_DeliveryPayMethod>> = {
PayMethod: () => mk_rel_query(
    'PayMethod',
    FilterBuilderPayMethod(),
    PayMethodRelationBuilder,
    PayMethodParser
  ),

Restaurant: () => mk_rel_query(
    'Restaurant',
    FilterBuilderRestaurant(),
    RestaurantRelationBuilder,
    RestaurantParser
  ),

}


const  DeliveryRestaurant_DeliveryPayMethodParser : EntityParser<DeliveryRestaurant_DeliveryPayMethod>= {
  Id: parseNumber,
      DeliveryRestaurantId: parseNumber,
      DeliveryPayMethodId: parseNumber,
  PayMethod: p => parseCollection(x => parseEntity(x, p)),
      Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const DeliveryRestaurant_DeliveryPayMethodOrderBy : (p: string) => OrderByBuilderComplex<DeliveryRestaurant_DeliveryPayMethod> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      DeliveryRestaurantId:  new OrderByProp(p ? p + "/DeliveryRestaurantId" : "DeliveryRestaurantId"),
      DeliveryPayMethodId:  new OrderByProp(p ? p + "/DeliveryPayMethodId" : "DeliveryPayMethodId"),
  PayMethod:  p => PayMethodOrderBy(p ? p + "/PayMethod" : "PayMethod"),
      Restaurant:  p => RestaurantOrderBy(p ? p + "/Restaurant" : "Restaurant"),
})



export type DeliveryTimes = {
    Id: number
    CreatedDate: Date
    SunDelivery: boolean
    SatDeliveryEnd: Date
    SunDeliveryStart: Date
    TueDeliveryEnd: Date
    MondayDelivery: boolean
    ThuDeliveryEnd: Date
    SatDeliveryStart: Date
    TueDeliveryStart: Date
    WedDelivery: boolean
    FriDeliveryEnd: Date
    ThuDeliveryStart: Date
    FriDelivery: boolean
    FriDeliveryStart: Date
    WedDeliveryEnd: Date
    ThuDelivery: boolean
    MondayDeliveryEnd: Date
    WedDeliveryStart: Date
    TueDelivery: boolean
    SatDelivery: boolean
    SunDeliveryEnd: Date
    MondayDeliveryStart: Date
} & {
  Restaurant_DeliveryTimes: List<Restaurant_DeliveryTimes>
}


function FilterBuilderDeliveryTimes(prefix?: string) : FilterBuilderComplex<DeliveryTimes> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      SunDelivery: new FilterBuilderBoolean(mk_prefix('SunDelivery', prefix)),
      SatDeliveryEnd: new FilterBuilderDate(mk_prefix('SatDeliveryEnd', prefix)),
      SunDeliveryStart: new FilterBuilderDate(mk_prefix('SunDeliveryStart', prefix)),
      TueDeliveryEnd: new FilterBuilderDate(mk_prefix('TueDeliveryEnd', prefix)),
      MondayDelivery: new FilterBuilderBoolean(mk_prefix('MondayDelivery', prefix)),
      ThuDeliveryEnd: new FilterBuilderDate(mk_prefix('ThuDeliveryEnd', prefix)),
      SatDeliveryStart: new FilterBuilderDate(mk_prefix('SatDeliveryStart', prefix)),
      TueDeliveryStart: new FilterBuilderDate(mk_prefix('TueDeliveryStart', prefix)),
      WedDelivery: new FilterBuilderBoolean(mk_prefix('WedDelivery', prefix)),
      FriDeliveryEnd: new FilterBuilderDate(mk_prefix('FriDeliveryEnd', prefix)),
      ThuDeliveryStart: new FilterBuilderDate(mk_prefix('ThuDeliveryStart', prefix)),
      FriDelivery: new FilterBuilderBoolean(mk_prefix('FriDelivery', prefix)),
      FriDeliveryStart: new FilterBuilderDate(mk_prefix('FriDeliveryStart', prefix)),
      WedDeliveryEnd: new FilterBuilderDate(mk_prefix('WedDeliveryEnd', prefix)),
      ThuDelivery: new FilterBuilderBoolean(mk_prefix('ThuDelivery', prefix)),
      MondayDeliveryEnd: new FilterBuilderDate(mk_prefix('MondayDeliveryEnd', prefix)),
      WedDeliveryStart: new FilterBuilderDate(mk_prefix('WedDeliveryStart', prefix)),
      TueDelivery: new FilterBuilderBoolean(mk_prefix('TueDelivery', prefix)),
      SatDelivery: new FilterBuilderBoolean(mk_prefix('SatDelivery', prefix)),
      SunDeliveryEnd: new FilterBuilderDate(mk_prefix('SunDeliveryEnd', prefix)),
      MondayDeliveryStart: new FilterBuilderDate(mk_prefix('MondayDeliveryStart', prefix)),
    Restaurant_DeliveryTimes: new FilterBuilderCollection<Restaurant_DeliveryTimes>(mk_prefix('Restaurant_DeliveryTimes', prefix), s => FilterBuilderRestaurant_DeliveryTimes(s)),
  }
}


const DeliveryTimesRelationBuilder: RelationBuilder<RelationsOf<DeliveryTimes>> = {
Restaurant_DeliveryTimes: () => mk_rel_query(
    'Restaurant_DeliveryTimes',
    FilterBuilderRestaurant_DeliveryTimes(),
    Restaurant_DeliveryTimesRelationBuilder,
    Restaurant_DeliveryTimesParser
  ),

}


const  DeliveryTimesParser : EntityParser<DeliveryTimes>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      SunDelivery: parseBoolean,
      SatDeliveryEnd: parseDate,
      SunDeliveryStart: parseDate,
      TueDeliveryEnd: parseDate,
      MondayDelivery: parseBoolean,
      ThuDeliveryEnd: parseDate,
      SatDeliveryStart: parseDate,
      TueDeliveryStart: parseDate,
      WedDelivery: parseBoolean,
      FriDeliveryEnd: parseDate,
      ThuDeliveryStart: parseDate,
      FriDelivery: parseBoolean,
      FriDeliveryStart: parseDate,
      WedDeliveryEnd: parseDate,
      ThuDelivery: parseBoolean,
      MondayDeliveryEnd: parseDate,
      WedDeliveryStart: parseDate,
      TueDelivery: parseBoolean,
      SatDelivery: parseBoolean,
      SunDeliveryEnd: parseDate,
      MondayDeliveryStart: parseDate,
  Restaurant_DeliveryTimes: p => parseCollection(x => parseEntity(x, p)),
}


const DeliveryTimesOrderBy : (p: string) => OrderByBuilderComplex<DeliveryTimes> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      SunDelivery:  new OrderByProp(p ? p + "/SunDelivery" : "SunDelivery"),
      SatDeliveryEnd:  new OrderByProp(p ? p + "/SatDeliveryEnd" : "SatDeliveryEnd"),
      SunDeliveryStart:  new OrderByProp(p ? p + "/SunDeliveryStart" : "SunDeliveryStart"),
      TueDeliveryEnd:  new OrderByProp(p ? p + "/TueDeliveryEnd" : "TueDeliveryEnd"),
      MondayDelivery:  new OrderByProp(p ? p + "/MondayDelivery" : "MondayDelivery"),
      ThuDeliveryEnd:  new OrderByProp(p ? p + "/ThuDeliveryEnd" : "ThuDeliveryEnd"),
      SatDeliveryStart:  new OrderByProp(p ? p + "/SatDeliveryStart" : "SatDeliveryStart"),
      TueDeliveryStart:  new OrderByProp(p ? p + "/TueDeliveryStart" : "TueDeliveryStart"),
      WedDelivery:  new OrderByProp(p ? p + "/WedDelivery" : "WedDelivery"),
      FriDeliveryEnd:  new OrderByProp(p ? p + "/FriDeliveryEnd" : "FriDeliveryEnd"),
      ThuDeliveryStart:  new OrderByProp(p ? p + "/ThuDeliveryStart" : "ThuDeliveryStart"),
      FriDelivery:  new OrderByProp(p ? p + "/FriDelivery" : "FriDelivery"),
      FriDeliveryStart:  new OrderByProp(p ? p + "/FriDeliveryStart" : "FriDeliveryStart"),
      WedDeliveryEnd:  new OrderByProp(p ? p + "/WedDeliveryEnd" : "WedDeliveryEnd"),
      ThuDelivery:  new OrderByProp(p ? p + "/ThuDelivery" : "ThuDelivery"),
      MondayDeliveryEnd:  new OrderByProp(p ? p + "/MondayDeliveryEnd" : "MondayDeliveryEnd"),
      WedDeliveryStart:  new OrderByProp(p ? p + "/WedDeliveryStart" : "WedDeliveryStart"),
      TueDelivery:  new OrderByProp(p ? p + "/TueDelivery" : "TueDelivery"),
      SatDelivery:  new OrderByProp(p ? p + "/SatDelivery" : "SatDelivery"),
      SunDeliveryEnd:  new OrderByProp(p ? p + "/SunDeliveryEnd" : "SunDeliveryEnd"),
      MondayDeliveryStart:  new OrderByProp(p ? p + "/MondayDeliveryStart" : "MondayDeliveryStart"),
  Restaurant_DeliveryTimes:  p => Restaurant_DeliveryTimesOrderBy(p ? p + "/Restaurant_DeliveryTimes" : "Restaurant_DeliveryTimes"),
})



export type Discount = {
    Id: number
    CreatedDate: Date
    ErrMessage: string | null
    MaxUse: number | null
    EndDate: Date
    Code: string | null
    StartDate: Date
    Value: number
    Delivery: boolean
    Title: string | null
    Percentage: number
    Type: string | null
    Pickup: boolean
    Description: string | null
    Used: number | null
    MinValueCart: number | null
} & {
  Order_Discount: List<Order_Discount>
    Product_Discount: List<Product_Discount>
    Restaurant_Discount: List<Restaurant_Discount>
}


function FilterBuilderDiscount(prefix?: string) : FilterBuilderComplex<Discount> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      ErrMessage: new FilterBuilderString(mk_prefix('ErrMessage', prefix)),
      MaxUse: new FilterBuilderNumber(mk_prefix('MaxUse', prefix)),
      EndDate: new FilterBuilderDate(mk_prefix('EndDate', prefix)),
      Code: new FilterBuilderString(mk_prefix('Code', prefix)),
      StartDate: new FilterBuilderDate(mk_prefix('StartDate', prefix)),
      Value: new FilterBuilderNumber(mk_prefix('Value', prefix)),
      Delivery: new FilterBuilderBoolean(mk_prefix('Delivery', prefix)),
      Title: new FilterBuilderString(mk_prefix('Title', prefix)),
      Percentage: new FilterBuilderNumber(mk_prefix('Percentage', prefix)),
      Type: new FilterBuilderString(mk_prefix('Type', prefix)),
      Pickup: new FilterBuilderBoolean(mk_prefix('Pickup', prefix)),
      Description: new FilterBuilderString(mk_prefix('Description', prefix)),
      Used: new FilterBuilderNumber(mk_prefix('Used', prefix)),
      MinValueCart: new FilterBuilderNumber(mk_prefix('MinValueCart', prefix)),
    Order_Discount: new FilterBuilderCollection<Order_Discount>(mk_prefix('Order_Discount', prefix), s => FilterBuilderOrder_Discount(s)),
      Product_Discount: new FilterBuilderCollection<Product_Discount>(mk_prefix('Product_Discount', prefix), s => FilterBuilderProduct_Discount(s)),
      Restaurant_Discount: new FilterBuilderCollection<Restaurant_Discount>(mk_prefix('Restaurant_Discount', prefix), s => FilterBuilderRestaurant_Discount(s)),
  }
}


const DiscountRelationBuilder: RelationBuilder<RelationsOf<Discount>> = {
Order_Discount: () => mk_rel_query(
    'Order_Discount',
    FilterBuilderOrder_Discount(),
    Order_DiscountRelationBuilder,
    Order_DiscountParser
  ),

Product_Discount: () => mk_rel_query(
    'Product_Discount',
    FilterBuilderProduct_Discount(),
    Product_DiscountRelationBuilder,
    Product_DiscountParser
  ),

Restaurant_Discount: () => mk_rel_query(
    'Restaurant_Discount',
    FilterBuilderRestaurant_Discount(),
    Restaurant_DiscountRelationBuilder,
    Restaurant_DiscountParser
  ),

}


const  DiscountParser : EntityParser<Discount>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      ErrMessage: parseNullString,
      MaxUse: parseNullNumber,
      EndDate: parseDate,
      Code: parseNullString,
      StartDate: parseDate,
      Value: parseNumber,
      Delivery: parseBoolean,
      Title: parseNullString,
      Percentage: parseNumber,
      Type: parseNullString,
      Pickup: parseBoolean,
      Description: parseNullString,
      Used: parseNullNumber,
      MinValueCart: parseNullNumber,
  Order_Discount: p => parseCollection(x => parseEntity(x, p)),
      Product_Discount: p => parseCollection(x => parseEntity(x, p)),
      Restaurant_Discount: p => parseCollection(x => parseEntity(x, p)),
}


const DiscountOrderBy : (p: string) => OrderByBuilderComplex<Discount> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      ErrMessage:  new OrderByProp(p ? p + "/ErrMessage" : "ErrMessage"),
      MaxUse:  new OrderByProp(p ? p + "/MaxUse" : "MaxUse"),
      EndDate:  new OrderByProp(p ? p + "/EndDate" : "EndDate"),
      Code:  new OrderByProp(p ? p + "/Code" : "Code"),
      StartDate:  new OrderByProp(p ? p + "/StartDate" : "StartDate"),
      Value:  new OrderByProp(p ? p + "/Value" : "Value"),
      Delivery:  new OrderByProp(p ? p + "/Delivery" : "Delivery"),
      Title:  new OrderByProp(p ? p + "/Title" : "Title"),
      Percentage:  new OrderByProp(p ? p + "/Percentage" : "Percentage"),
      Type:  new OrderByProp(p ? p + "/Type" : "Type"),
      Pickup:  new OrderByProp(p ? p + "/Pickup" : "Pickup"),
      Description:  new OrderByProp(p ? p + "/Description" : "Description"),
      Used:  new OrderByProp(p ? p + "/Used" : "Used"),
      MinValueCart:  new OrderByProp(p ? p + "/MinValueCart" : "MinValueCart"),
  Order_Discount:  p => Order_DiscountOrderBy(p ? p + "/Order_Discount" : "Order_Discount"),
      Product_Discount:  p => Product_DiscountOrderBy(p ? p + "/Product_Discount" : "Product_Discount"),
      Restaurant_Discount:  p => Restaurant_DiscountOrderBy(p ? p + "/Restaurant_Discount" : "Restaurant_Discount"),
})



export type FranchiseManager = {
    Id: number
    CreatedDate: Date
    Username: string | null
    Email: string | null
    Language: string | null
    PasswordHash: string | null
    PasswordSalt: string | null
    LastLoginAttempt: Date
    SecurityStamp: string | null
    EmailConfirmed: boolean
} & {
  FranchiseManager_Restaurant: List<FranchiseManager_Restaurant>
}


function FilterBuilderFranchiseManager(prefix?: string) : FilterBuilderComplex<FranchiseManager> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Username: new FilterBuilderString(mk_prefix('Username', prefix)),
      Email: new FilterBuilderString(mk_prefix('Email', prefix)),
      Language: new FilterBuilderString(mk_prefix('Language', prefix)),
      PasswordHash: new FilterBuilderString(mk_prefix('PasswordHash', prefix)),
      PasswordSalt: new FilterBuilderString(mk_prefix('PasswordSalt', prefix)),
      LastLoginAttempt: new FilterBuilderDate(mk_prefix('LastLoginAttempt', prefix)),
      SecurityStamp: new FilterBuilderString(mk_prefix('SecurityStamp', prefix)),
      EmailConfirmed: new FilterBuilderBoolean(mk_prefix('EmailConfirmed', prefix)),
    FranchiseManager_Restaurant: new FilterBuilderCollection<FranchiseManager_Restaurant>(mk_prefix('FranchiseManager_Restaurant', prefix), s => FilterBuilderFranchiseManager_Restaurant(s)),
  }
}


const FranchiseManagerRelationBuilder: RelationBuilder<RelationsOf<FranchiseManager>> = {
FranchiseManager_Restaurant: () => mk_rel_query(
    'FranchiseManager_Restaurant',
    FilterBuilderFranchiseManager_Restaurant(),
    FranchiseManager_RestaurantRelationBuilder,
    FranchiseManager_RestaurantParser
  ),

}


const  FranchiseManagerParser : EntityParser<FranchiseManager>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Username: parseNullString,
      Email: parseNullString,
      Language: parseNullString,
      PasswordHash: parseNullString,
      PasswordSalt: parseNullString,
      LastLoginAttempt: parseDate,
      SecurityStamp: parseNullString,
      EmailConfirmed: parseBoolean,
  FranchiseManager_Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const FranchiseManagerOrderBy : (p: string) => OrderByBuilderComplex<FranchiseManager> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Username:  new OrderByProp(p ? p + "/Username" : "Username"),
      Email:  new OrderByProp(p ? p + "/Email" : "Email"),
      Language:  new OrderByProp(p ? p + "/Language" : "Language"),
      PasswordHash:  new OrderByProp(p ? p + "/PasswordHash" : "PasswordHash"),
      PasswordSalt:  new OrderByProp(p ? p + "/PasswordSalt" : "PasswordSalt"),
      LastLoginAttempt:  new OrderByProp(p ? p + "/LastLoginAttempt" : "LastLoginAttempt"),
      SecurityStamp:  new OrderByProp(p ? p + "/SecurityStamp" : "SecurityStamp"),
      EmailConfirmed:  new OrderByProp(p ? p + "/EmailConfirmed" : "EmailConfirmed"),
  FranchiseManager_Restaurant:  p => FranchiseManager_RestaurantOrderBy(p ? p + "/FranchiseManager_Restaurant" : "FranchiseManager_Restaurant"),
})



export type FranchiseManager_Restaurant = {
    Id: number
    FranchiseManagerId: number
    RestaurantId: number
} & {
  FranchiseManager: List<FranchiseManager>
    Restaurant: List<Restaurant>
}


function FilterBuilderFranchiseManager_Restaurant(prefix?: string) : FilterBuilderComplex<FranchiseManager_Restaurant> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      FranchiseManagerId: new FilterBuilderNumber(mk_prefix('FranchiseManagerId', prefix)),
      RestaurantId: new FilterBuilderNumber(mk_prefix('RestaurantId', prefix)),
    FranchiseManager: new FilterBuilderCollection<FranchiseManager>(mk_prefix('FranchiseManager', prefix), s => FilterBuilderFranchiseManager(s)),
      Restaurant: new FilterBuilderCollection<Restaurant>(mk_prefix('Restaurant', prefix), s => FilterBuilderRestaurant(s)),
  }
}


const FranchiseManager_RestaurantRelationBuilder: RelationBuilder<RelationsOf<FranchiseManager_Restaurant>> = {
FranchiseManager: () => mk_rel_query(
    'FranchiseManager',
    FilterBuilderFranchiseManager(),
    FranchiseManagerRelationBuilder,
    FranchiseManagerParser
  ),

Restaurant: () => mk_rel_query(
    'Restaurant',
    FilterBuilderRestaurant(),
    RestaurantRelationBuilder,
    RestaurantParser
  ),

}


const  FranchiseManager_RestaurantParser : EntityParser<FranchiseManager_Restaurant>= {
  Id: parseNumber,
      FranchiseManagerId: parseNumber,
      RestaurantId: parseNumber,
  FranchiseManager: p => parseCollection(x => parseEntity(x, p)),
      Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const FranchiseManager_RestaurantOrderBy : (p: string) => OrderByBuilderComplex<FranchiseManager_Restaurant> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      FranchiseManagerId:  new OrderByProp(p ? p + "/FranchiseManagerId" : "FranchiseManagerId"),
      RestaurantId:  new OrderByProp(p ? p + "/RestaurantId" : "RestaurantId"),
  FranchiseManager:  p => FranchiseManagerOrderBy(p ? p + "/FranchiseManager" : "FranchiseManager"),
      Restaurant:  p => RestaurantOrderBy(p ? p + "/Restaurant" : "Restaurant"),
})



export type LoginAttempt = {
    IpAddress: string | null
    Email: string | null
    Attempts: number
    LastAttempt: Date
} & {

}


function FilterBuilderLoginAttempt(prefix?: string) : FilterBuilderComplex<LoginAttempt> {
  return {
    IpAddress: new FilterBuilderString(mk_prefix('IpAddress', prefix)),
      Email: new FilterBuilderString(mk_prefix('Email', prefix)),
      Attempts: new FilterBuilderNumber(mk_prefix('Attempts', prefix)),
      LastAttempt: new FilterBuilderDate(mk_prefix('LastAttempt', prefix)),
    
  }
}


const LoginAttemptRelationBuilder: RelationBuilder<RelationsOf<LoginAttempt>> = {

}


const  LoginAttemptParser : EntityParser<LoginAttempt>= {
  IpAddress: parseNullString,
      Email: parseNullString,
      Attempts: parseNumber,
      LastAttempt: parseDate,
  
}


const LoginAttemptOrderBy : (p: string) => OrderByBuilderComplex<LoginAttempt> = p => ({
  IpAddress:  new OrderByProp(p ? p + "/IpAddress" : "IpAddress"),
      Email:  new OrderByProp(p ? p + "/Email" : "Email"),
      Attempts:  new OrderByProp(p ? p + "/Attempts" : "Attempts"),
      LastAttempt:  new OrderByProp(p ? p + "/LastAttempt" : "LastAttempt"),
  
})



export type MollieKeys = {
    Id: number
    CreatedDate: Date
    Key: string | null
} & {
  Restaurant_MollieKeys: List<Restaurant_MollieKeys>
}


function FilterBuilderMollieKeys(prefix?: string) : FilterBuilderComplex<MollieKeys> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Key: new FilterBuilderString(mk_prefix('Key', prefix)),
    Restaurant_MollieKeys: new FilterBuilderCollection<Restaurant_MollieKeys>(mk_prefix('Restaurant_MollieKeys', prefix), s => FilterBuilderRestaurant_MollieKeys(s)),
  }
}


const MollieKeysRelationBuilder: RelationBuilder<RelationsOf<MollieKeys>> = {
Restaurant_MollieKeys: () => mk_rel_query(
    'Restaurant_MollieKeys',
    FilterBuilderRestaurant_MollieKeys(),
    Restaurant_MollieKeysRelationBuilder,
    Restaurant_MollieKeysParser
  ),

}


const  MollieKeysParser : EntityParser<MollieKeys>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Key: parseNullString,
  Restaurant_MollieKeys: p => parseCollection(x => parseEntity(x, p)),
}


const MollieKeysOrderBy : (p: string) => OrderByBuilderComplex<MollieKeys> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Key:  new OrderByProp(p ? p + "/Key" : "Key"),
  Restaurant_MollieKeys:  p => Restaurant_MollieKeysOrderBy(p ? p + "/Restaurant_MollieKeys" : "Restaurant_MollieKeys"),
})



export type Option = {
    Id: number
    CreatedDate: Date
    Name: string | null
    POS4: string | null
    Description: string | null
    InternalName: string | null
    Price: number
} & {
  OptionGroup_Option: List<OptionGroup_Option>
    Option_Restaurant: List<Option_Restaurant>
    OrderItemOption_Option: List<OrderItemOption_Option>
}


function FilterBuilderOption(prefix?: string) : FilterBuilderComplex<Option> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Name: new FilterBuilderString(mk_prefix('Name', prefix)),
      POS4: new FilterBuilderString(mk_prefix('POS4', prefix)),
      Description: new FilterBuilderString(mk_prefix('Description', prefix)),
      InternalName: new FilterBuilderString(mk_prefix('InternalName', prefix)),
      Price: new FilterBuilderNumber(mk_prefix('Price', prefix)),
    OptionGroup_Option: new FilterBuilderCollection<OptionGroup_Option>(mk_prefix('OptionGroup_Option', prefix), s => FilterBuilderOptionGroup_Option(s)),
      Option_Restaurant: new FilterBuilderCollection<Option_Restaurant>(mk_prefix('Option_Restaurant', prefix), s => FilterBuilderOption_Restaurant(s)),
      OrderItemOption_Option: new FilterBuilderCollection<OrderItemOption_Option>(mk_prefix('OrderItemOption_Option', prefix), s => FilterBuilderOrderItemOption_Option(s)),
  }
}


const OptionRelationBuilder: RelationBuilder<RelationsOf<Option>> = {
OptionGroup_Option: () => mk_rel_query(
    'OptionGroup_Option',
    FilterBuilderOptionGroup_Option(),
    OptionGroup_OptionRelationBuilder,
    OptionGroup_OptionParser
  ),

Option_Restaurant: () => mk_rel_query(
    'Option_Restaurant',
    FilterBuilderOption_Restaurant(),
    Option_RestaurantRelationBuilder,
    Option_RestaurantParser
  ),

OrderItemOption_Option: () => mk_rel_query(
    'OrderItemOption_Option',
    FilterBuilderOrderItemOption_Option(),
    OrderItemOption_OptionRelationBuilder,
    OrderItemOption_OptionParser
  ),

}


const  OptionParser : EntityParser<Option>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Name: parseNullString,
      POS4: parseNullString,
      Description: parseNullString,
      InternalName: parseNullString,
      Price: parseNumber,
  OptionGroup_Option: p => parseCollection(x => parseEntity(x, p)),
      Option_Restaurant: p => parseCollection(x => parseEntity(x, p)),
      OrderItemOption_Option: p => parseCollection(x => parseEntity(x, p)),
}


const OptionOrderBy : (p: string) => OrderByBuilderComplex<Option> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Name:  new OrderByProp(p ? p + "/Name" : "Name"),
      POS4:  new OrderByProp(p ? p + "/POS4" : "POS4"),
      Description:  new OrderByProp(p ? p + "/Description" : "Description"),
      InternalName:  new OrderByProp(p ? p + "/InternalName" : "InternalName"),
      Price:  new OrderByProp(p ? p + "/Price" : "Price"),
  OptionGroup_Option:  p => OptionGroup_OptionOrderBy(p ? p + "/OptionGroup_Option" : "OptionGroup_Option"),
      Option_Restaurant:  p => Option_RestaurantOrderBy(p ? p + "/Option_Restaurant" : "Option_Restaurant"),
      OrderItemOption_Option:  p => OrderItemOption_OptionOrderBy(p ? p + "/OrderItemOption_Option" : "OrderItemOption_Option"),
})



export type OptionGroup = {
    Id: number
    CreatedDate: Date
    Name: string | null
    Description: string | null
    InternalName: string | null
    SelectionType: string | null
} & {
  OptionGroup_Option: List<OptionGroup_Option>
    OptionGroup_OptionGroupPosition: List<OptionGroup_OptionGroupPosition>
}


function FilterBuilderOptionGroup(prefix?: string) : FilterBuilderComplex<OptionGroup> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Name: new FilterBuilderString(mk_prefix('Name', prefix)),
      Description: new FilterBuilderString(mk_prefix('Description', prefix)),
      InternalName: new FilterBuilderString(mk_prefix('InternalName', prefix)),
      SelectionType: new FilterBuilderString(mk_prefix('SelectionType', prefix)),
    OptionGroup_Option: new FilterBuilderCollection<OptionGroup_Option>(mk_prefix('OptionGroup_Option', prefix), s => FilterBuilderOptionGroup_Option(s)),
      OptionGroup_OptionGroupPosition: new FilterBuilderCollection<OptionGroup_OptionGroupPosition>(mk_prefix('OptionGroup_OptionGroupPosition', prefix), s => FilterBuilderOptionGroup_OptionGroupPosition(s)),
  }
}


const OptionGroupRelationBuilder: RelationBuilder<RelationsOf<OptionGroup>> = {
OptionGroup_Option: () => mk_rel_query(
    'OptionGroup_Option',
    FilterBuilderOptionGroup_Option(),
    OptionGroup_OptionRelationBuilder,
    OptionGroup_OptionParser
  ),

OptionGroup_OptionGroupPosition: () => mk_rel_query(
    'OptionGroup_OptionGroupPosition',
    FilterBuilderOptionGroup_OptionGroupPosition(),
    OptionGroup_OptionGroupPositionRelationBuilder,
    OptionGroup_OptionGroupPositionParser
  ),

}


const  OptionGroupParser : EntityParser<OptionGroup>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Name: parseNullString,
      Description: parseNullString,
      InternalName: parseNullString,
      SelectionType: parseNullString,
  OptionGroup_Option: p => parseCollection(x => parseEntity(x, p)),
      OptionGroup_OptionGroupPosition: p => parseCollection(x => parseEntity(x, p)),
}


const OptionGroupOrderBy : (p: string) => OrderByBuilderComplex<OptionGroup> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Name:  new OrderByProp(p ? p + "/Name" : "Name"),
      Description:  new OrderByProp(p ? p + "/Description" : "Description"),
      InternalName:  new OrderByProp(p ? p + "/InternalName" : "InternalName"),
      SelectionType:  new OrderByProp(p ? p + "/SelectionType" : "SelectionType"),
  OptionGroup_Option:  p => OptionGroup_OptionOrderBy(p ? p + "/OptionGroup_Option" : "OptionGroup_Option"),
      OptionGroup_OptionGroupPosition:  p => OptionGroup_OptionGroupPositionOrderBy(p ? p + "/OptionGroup_OptionGroupPosition" : "OptionGroup_OptionGroupPosition"),
})



export type OptionGroupPosition = {
    Id: number
    CreatedDate: Date
    Position: number
} & {
  OptionGroup_OptionGroupPosition: List<OptionGroup_OptionGroupPosition>
    Product_OptionGroupPosition: List<Product_OptionGroupPosition>
}


function FilterBuilderOptionGroupPosition(prefix?: string) : FilterBuilderComplex<OptionGroupPosition> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Position: new FilterBuilderNumber(mk_prefix('Position', prefix)),
    OptionGroup_OptionGroupPosition: new FilterBuilderCollection<OptionGroup_OptionGroupPosition>(mk_prefix('OptionGroup_OptionGroupPosition', prefix), s => FilterBuilderOptionGroup_OptionGroupPosition(s)),
      Product_OptionGroupPosition: new FilterBuilderCollection<Product_OptionGroupPosition>(mk_prefix('Product_OptionGroupPosition', prefix), s => FilterBuilderProduct_OptionGroupPosition(s)),
  }
}


const OptionGroupPositionRelationBuilder: RelationBuilder<RelationsOf<OptionGroupPosition>> = {
OptionGroup_OptionGroupPosition: () => mk_rel_query(
    'OptionGroup_OptionGroupPosition',
    FilterBuilderOptionGroup_OptionGroupPosition(),
    OptionGroup_OptionGroupPositionRelationBuilder,
    OptionGroup_OptionGroupPositionParser
  ),

Product_OptionGroupPosition: () => mk_rel_query(
    'Product_OptionGroupPosition',
    FilterBuilderProduct_OptionGroupPosition(),
    Product_OptionGroupPositionRelationBuilder,
    Product_OptionGroupPositionParser
  ),

}


const  OptionGroupPositionParser : EntityParser<OptionGroupPosition>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Position: parseNumber,
  OptionGroup_OptionGroupPosition: p => parseCollection(x => parseEntity(x, p)),
      Product_OptionGroupPosition: p => parseCollection(x => parseEntity(x, p)),
}


const OptionGroupPositionOrderBy : (p: string) => OrderByBuilderComplex<OptionGroupPosition> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Position:  new OrderByProp(p ? p + "/Position" : "Position"),
  OptionGroup_OptionGroupPosition:  p => OptionGroup_OptionGroupPositionOrderBy(p ? p + "/OptionGroup_OptionGroupPosition" : "OptionGroup_OptionGroupPosition"),
      Product_OptionGroupPosition:  p => Product_OptionGroupPositionOrderBy(p ? p + "/Product_OptionGroupPosition" : "Product_OptionGroupPosition"),
})



export type OptionGroup_Option = {
    Id: number
    OptionGroupId: number
    OptionId: number
} & {
  OptionGroup: List<OptionGroup>
    Option: List<Option>
}


function FilterBuilderOptionGroup_Option(prefix?: string) : FilterBuilderComplex<OptionGroup_Option> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      OptionGroupId: new FilterBuilderNumber(mk_prefix('OptionGroupId', prefix)),
      OptionId: new FilterBuilderNumber(mk_prefix('OptionId', prefix)),
    OptionGroup: new FilterBuilderCollection<OptionGroup>(mk_prefix('OptionGroup', prefix), s => FilterBuilderOptionGroup(s)),
      Option: new FilterBuilderCollection<Option>(mk_prefix('Option', prefix), s => FilterBuilderOption(s)),
  }
}


const OptionGroup_OptionRelationBuilder: RelationBuilder<RelationsOf<OptionGroup_Option>> = {
OptionGroup: () => mk_rel_query(
    'OptionGroup',
    FilterBuilderOptionGroup(),
    OptionGroupRelationBuilder,
    OptionGroupParser
  ),

Option: () => mk_rel_query(
    'Option',
    FilterBuilderOption(),
    OptionRelationBuilder,
    OptionParser
  ),

}


const  OptionGroup_OptionParser : EntityParser<OptionGroup_Option>= {
  Id: parseNumber,
      OptionGroupId: parseNumber,
      OptionId: parseNumber,
  OptionGroup: p => parseCollection(x => parseEntity(x, p)),
      Option: p => parseCollection(x => parseEntity(x, p)),
}


const OptionGroup_OptionOrderBy : (p: string) => OrderByBuilderComplex<OptionGroup_Option> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      OptionGroupId:  new OrderByProp(p ? p + "/OptionGroupId" : "OptionGroupId"),
      OptionId:  new OrderByProp(p ? p + "/OptionId" : "OptionId"),
  OptionGroup:  p => OptionGroupOrderBy(p ? p + "/OptionGroup" : "OptionGroup"),
      Option:  p => OptionOrderBy(p ? p + "/Option" : "Option"),
})



export type OptionGroup_OptionGroupPosition = {
    Id: number
    OptionGroupId: number
    OptionGroupPositionId: number
} & {
  OptionGroup: List<OptionGroup>
    OptionGroupPosition: List<OptionGroupPosition>
}


function FilterBuilderOptionGroup_OptionGroupPosition(prefix?: string) : FilterBuilderComplex<OptionGroup_OptionGroupPosition> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      OptionGroupId: new FilterBuilderNumber(mk_prefix('OptionGroupId', prefix)),
      OptionGroupPositionId: new FilterBuilderNumber(mk_prefix('OptionGroupPositionId', prefix)),
    OptionGroup: new FilterBuilderCollection<OptionGroup>(mk_prefix('OptionGroup', prefix), s => FilterBuilderOptionGroup(s)),
      OptionGroupPosition: new FilterBuilderCollection<OptionGroupPosition>(mk_prefix('OptionGroupPosition', prefix), s => FilterBuilderOptionGroupPosition(s)),
  }
}


const OptionGroup_OptionGroupPositionRelationBuilder: RelationBuilder<RelationsOf<OptionGroup_OptionGroupPosition>> = {
OptionGroup: () => mk_rel_query(
    'OptionGroup',
    FilterBuilderOptionGroup(),
    OptionGroupRelationBuilder,
    OptionGroupParser
  ),

OptionGroupPosition: () => mk_rel_query(
    'OptionGroupPosition',
    FilterBuilderOptionGroupPosition(),
    OptionGroupPositionRelationBuilder,
    OptionGroupPositionParser
  ),

}


const  OptionGroup_OptionGroupPositionParser : EntityParser<OptionGroup_OptionGroupPosition>= {
  Id: parseNumber,
      OptionGroupId: parseNumber,
      OptionGroupPositionId: parseNumber,
  OptionGroup: p => parseCollection(x => parseEntity(x, p)),
      OptionGroupPosition: p => parseCollection(x => parseEntity(x, p)),
}


const OptionGroup_OptionGroupPositionOrderBy : (p: string) => OrderByBuilderComplex<OptionGroup_OptionGroupPosition> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      OptionGroupId:  new OrderByProp(p ? p + "/OptionGroupId" : "OptionGroupId"),
      OptionGroupPositionId:  new OrderByProp(p ? p + "/OptionGroupPositionId" : "OptionGroupPositionId"),
  OptionGroup:  p => OptionGroupOrderBy(p ? p + "/OptionGroup" : "OptionGroup"),
      OptionGroupPosition:  p => OptionGroupPositionOrderBy(p ? p + "/OptionGroupPosition" : "OptionGroupPosition"),
})



export type Option_Restaurant = {
    Id: number
    OptionId: number
    RestaurantId: number
} & {
  Option: List<Option>
    Restaurant: List<Restaurant>
}


function FilterBuilderOption_Restaurant(prefix?: string) : FilterBuilderComplex<Option_Restaurant> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      OptionId: new FilterBuilderNumber(mk_prefix('OptionId', prefix)),
      RestaurantId: new FilterBuilderNumber(mk_prefix('RestaurantId', prefix)),
    Option: new FilterBuilderCollection<Option>(mk_prefix('Option', prefix), s => FilterBuilderOption(s)),
      Restaurant: new FilterBuilderCollection<Restaurant>(mk_prefix('Restaurant', prefix), s => FilterBuilderRestaurant(s)),
  }
}


const Option_RestaurantRelationBuilder: RelationBuilder<RelationsOf<Option_Restaurant>> = {
Option: () => mk_rel_query(
    'Option',
    FilterBuilderOption(),
    OptionRelationBuilder,
    OptionParser
  ),

Restaurant: () => mk_rel_query(
    'Restaurant',
    FilterBuilderRestaurant(),
    RestaurantRelationBuilder,
    RestaurantParser
  ),

}


const  Option_RestaurantParser : EntityParser<Option_Restaurant>= {
  Id: parseNumber,
      OptionId: parseNumber,
      RestaurantId: parseNumber,
  Option: p => parseCollection(x => parseEntity(x, p)),
      Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const Option_RestaurantOrderBy : (p: string) => OrderByBuilderComplex<Option_Restaurant> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      OptionId:  new OrderByProp(p ? p + "/OptionId" : "OptionId"),
      RestaurantId:  new OrderByProp(p ? p + "/RestaurantId" : "RestaurantId"),
  Option:  p => OptionOrderBy(p ? p + "/Option" : "Option"),
      Restaurant:  p => RestaurantOrderBy(p ? p + "/Restaurant" : "Restaurant"),
})



export type Order = {
    Id: number
    CreatedDate: Date
    DeliveryOrPickupTime: Date
    TransferredToPOS4: boolean
    Street: string | null
    PaymentReference: string | null
    CompanyName: string | null
    Number: string | null
    City: string | null
    Name: string | null
    Postcode: string | null
    Phone: string | null
    Comments: string | null
    ReferenceNumber: string | null
    PaymentStatus: string | null
    Pickup: boolean
    Email: string | null
} & {
  Order_Discount: List<Order_Discount>
    Order_OrderItem: List<Order_OrderItem>
    Order_PayMethod: List<Order_PayMethod>
    Order_Restaurant: List<Order_Restaurant>
    Order_User: List<Order_User>
}


function FilterBuilderOrder(prefix?: string) : FilterBuilderComplex<Order> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      DeliveryOrPickupTime: new FilterBuilderDate(mk_prefix('DeliveryOrPickupTime', prefix)),
      TransferredToPOS4: new FilterBuilderBoolean(mk_prefix('TransferredToPOS4', prefix)),
      Street: new FilterBuilderString(mk_prefix('Street', prefix)),
      PaymentReference: new FilterBuilderString(mk_prefix('PaymentReference', prefix)),
      CompanyName: new FilterBuilderString(mk_prefix('CompanyName', prefix)),
      Number: new FilterBuilderString(mk_prefix('Number', prefix)),
      City: new FilterBuilderString(mk_prefix('City', prefix)),
      Name: new FilterBuilderString(mk_prefix('Name', prefix)),
      Postcode: new FilterBuilderString(mk_prefix('Postcode', prefix)),
      Phone: new FilterBuilderString(mk_prefix('Phone', prefix)),
      Comments: new FilterBuilderString(mk_prefix('Comments', prefix)),
      ReferenceNumber: new FilterBuilderString(mk_prefix('ReferenceNumber', prefix)),
      PaymentStatus: new FilterBuilderString(mk_prefix('PaymentStatus', prefix)),
      Pickup: new FilterBuilderBoolean(mk_prefix('Pickup', prefix)),
      Email: new FilterBuilderString(mk_prefix('Email', prefix)),
    Order_Discount: new FilterBuilderCollection<Order_Discount>(mk_prefix('Order_Discount', prefix), s => FilterBuilderOrder_Discount(s)),
      Order_OrderItem: new FilterBuilderCollection<Order_OrderItem>(mk_prefix('Order_OrderItem', prefix), s => FilterBuilderOrder_OrderItem(s)),
      Order_PayMethod: new FilterBuilderCollection<Order_PayMethod>(mk_prefix('Order_PayMethod', prefix), s => FilterBuilderOrder_PayMethod(s)),
      Order_Restaurant: new FilterBuilderCollection<Order_Restaurant>(mk_prefix('Order_Restaurant', prefix), s => FilterBuilderOrder_Restaurant(s)),
      Order_User: new FilterBuilderCollection<Order_User>(mk_prefix('Order_User', prefix), s => FilterBuilderOrder_User(s)),
  }
}


const OrderRelationBuilder: RelationBuilder<RelationsOf<Order>> = {
Order_Discount: () => mk_rel_query(
    'Order_Discount',
    FilterBuilderOrder_Discount(),
    Order_DiscountRelationBuilder,
    Order_DiscountParser
  ),

Order_OrderItem: () => mk_rel_query(
    'Order_OrderItem',
    FilterBuilderOrder_OrderItem(),
    Order_OrderItemRelationBuilder,
    Order_OrderItemParser
  ),

Order_PayMethod: () => mk_rel_query(
    'Order_PayMethod',
    FilterBuilderOrder_PayMethod(),
    Order_PayMethodRelationBuilder,
    Order_PayMethodParser
  ),

Order_Restaurant: () => mk_rel_query(
    'Order_Restaurant',
    FilterBuilderOrder_Restaurant(),
    Order_RestaurantRelationBuilder,
    Order_RestaurantParser
  ),

Order_User: () => mk_rel_query(
    'Order_User',
    FilterBuilderOrder_User(),
    Order_UserRelationBuilder,
    Order_UserParser
  ),

}


const  OrderParser : EntityParser<Order>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      DeliveryOrPickupTime: parseDate,
      TransferredToPOS4: parseBoolean,
      Street: parseNullString,
      PaymentReference: parseNullString,
      CompanyName: parseNullString,
      Number: parseNullString,
      City: parseNullString,
      Name: parseNullString,
      Postcode: parseNullString,
      Phone: parseNullString,
      Comments: parseNullString,
      ReferenceNumber: parseNullString,
      PaymentStatus: parseNullString,
      Pickup: parseBoolean,
      Email: parseNullString,
  Order_Discount: p => parseCollection(x => parseEntity(x, p)),
      Order_OrderItem: p => parseCollection(x => parseEntity(x, p)),
      Order_PayMethod: p => parseCollection(x => parseEntity(x, p)),
      Order_Restaurant: p => parseCollection(x => parseEntity(x, p)),
      Order_User: p => parseCollection(x => parseEntity(x, p)),
}


const OrderOrderBy : (p: string) => OrderByBuilderComplex<Order> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      DeliveryOrPickupTime:  new OrderByProp(p ? p + "/DeliveryOrPickupTime" : "DeliveryOrPickupTime"),
      TransferredToPOS4:  new OrderByProp(p ? p + "/TransferredToPOS4" : "TransferredToPOS4"),
      Street:  new OrderByProp(p ? p + "/Street" : "Street"),
      PaymentReference:  new OrderByProp(p ? p + "/PaymentReference" : "PaymentReference"),
      CompanyName:  new OrderByProp(p ? p + "/CompanyName" : "CompanyName"),
      Number:  new OrderByProp(p ? p + "/Number" : "Number"),
      City:  new OrderByProp(p ? p + "/City" : "City"),
      Name:  new OrderByProp(p ? p + "/Name" : "Name"),
      Postcode:  new OrderByProp(p ? p + "/Postcode" : "Postcode"),
      Phone:  new OrderByProp(p ? p + "/Phone" : "Phone"),
      Comments:  new OrderByProp(p ? p + "/Comments" : "Comments"),
      ReferenceNumber:  new OrderByProp(p ? p + "/ReferenceNumber" : "ReferenceNumber"),
      PaymentStatus:  new OrderByProp(p ? p + "/PaymentStatus" : "PaymentStatus"),
      Pickup:  new OrderByProp(p ? p + "/Pickup" : "Pickup"),
      Email:  new OrderByProp(p ? p + "/Email" : "Email"),
  Order_Discount:  p => Order_DiscountOrderBy(p ? p + "/Order_Discount" : "Order_Discount"),
      Order_OrderItem:  p => Order_OrderItemOrderBy(p ? p + "/Order_OrderItem" : "Order_OrderItem"),
      Order_PayMethod:  p => Order_PayMethodOrderBy(p ? p + "/Order_PayMethod" : "Order_PayMethod"),
      Order_Restaurant:  p => Order_RestaurantOrderBy(p ? p + "/Order_Restaurant" : "Order_Restaurant"),
      Order_User:  p => Order_UserOrderBy(p ? p + "/Order_User" : "Order_User"),
})



export type OrderItem = {
    Id: number
    CreatedDate: Date
    Quantity: number
    UnitPrice: number
} & {
  OrderItem_OrderItemOption: List<OrderItem_OrderItemOption>
    OrderItem_OrderItemProduct: List<OrderItem_OrderItemProduct>
    Order_OrderItem: List<Order_OrderItem>
}


function FilterBuilderOrderItem(prefix?: string) : FilterBuilderComplex<OrderItem> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Quantity: new FilterBuilderNumber(mk_prefix('Quantity', prefix)),
      UnitPrice: new FilterBuilderNumber(mk_prefix('UnitPrice', prefix)),
    OrderItem_OrderItemOption: new FilterBuilderCollection<OrderItem_OrderItemOption>(mk_prefix('OrderItem_OrderItemOption', prefix), s => FilterBuilderOrderItem_OrderItemOption(s)),
      OrderItem_OrderItemProduct: new FilterBuilderCollection<OrderItem_OrderItemProduct>(mk_prefix('OrderItem_OrderItemProduct', prefix), s => FilterBuilderOrderItem_OrderItemProduct(s)),
      Order_OrderItem: new FilterBuilderCollection<Order_OrderItem>(mk_prefix('Order_OrderItem', prefix), s => FilterBuilderOrder_OrderItem(s)),
  }
}


const OrderItemRelationBuilder: RelationBuilder<RelationsOf<OrderItem>> = {
OrderItem_OrderItemOption: () => mk_rel_query(
    'OrderItem_OrderItemOption',
    FilterBuilderOrderItem_OrderItemOption(),
    OrderItem_OrderItemOptionRelationBuilder,
    OrderItem_OrderItemOptionParser
  ),

OrderItem_OrderItemProduct: () => mk_rel_query(
    'OrderItem_OrderItemProduct',
    FilterBuilderOrderItem_OrderItemProduct(),
    OrderItem_OrderItemProductRelationBuilder,
    OrderItem_OrderItemProductParser
  ),

Order_OrderItem: () => mk_rel_query(
    'Order_OrderItem',
    FilterBuilderOrder_OrderItem(),
    Order_OrderItemRelationBuilder,
    Order_OrderItemParser
  ),

}


const  OrderItemParser : EntityParser<OrderItem>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Quantity: parseNumber,
      UnitPrice: parseNumber,
  OrderItem_OrderItemOption: p => parseCollection(x => parseEntity(x, p)),
      OrderItem_OrderItemProduct: p => parseCollection(x => parseEntity(x, p)),
      Order_OrderItem: p => parseCollection(x => parseEntity(x, p)),
}


const OrderItemOrderBy : (p: string) => OrderByBuilderComplex<OrderItem> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Quantity:  new OrderByProp(p ? p + "/Quantity" : "Quantity"),
      UnitPrice:  new OrderByProp(p ? p + "/UnitPrice" : "UnitPrice"),
  OrderItem_OrderItemOption:  p => OrderItem_OrderItemOptionOrderBy(p ? p + "/OrderItem_OrderItemOption" : "OrderItem_OrderItemOption"),
      OrderItem_OrderItemProduct:  p => OrderItem_OrderItemProductOrderBy(p ? p + "/OrderItem_OrderItemProduct" : "OrderItem_OrderItemProduct"),
      Order_OrderItem:  p => Order_OrderItemOrderBy(p ? p + "/Order_OrderItem" : "Order_OrderItem"),
})



export type OrderItemOption = {
    Id: number
    CreatedDate: Date
    Price: number
} & {
  OrderItemOption_Option: List<OrderItemOption_Option>
    OrderItem_OrderItemOption: List<OrderItem_OrderItemOption>
}


function FilterBuilderOrderItemOption(prefix?: string) : FilterBuilderComplex<OrderItemOption> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Price: new FilterBuilderNumber(mk_prefix('Price', prefix)),
    OrderItemOption_Option: new FilterBuilderCollection<OrderItemOption_Option>(mk_prefix('OrderItemOption_Option', prefix), s => FilterBuilderOrderItemOption_Option(s)),
      OrderItem_OrderItemOption: new FilterBuilderCollection<OrderItem_OrderItemOption>(mk_prefix('OrderItem_OrderItemOption', prefix), s => FilterBuilderOrderItem_OrderItemOption(s)),
  }
}


const OrderItemOptionRelationBuilder: RelationBuilder<RelationsOf<OrderItemOption>> = {
OrderItemOption_Option: () => mk_rel_query(
    'OrderItemOption_Option',
    FilterBuilderOrderItemOption_Option(),
    OrderItemOption_OptionRelationBuilder,
    OrderItemOption_OptionParser
  ),

OrderItem_OrderItemOption: () => mk_rel_query(
    'OrderItem_OrderItemOption',
    FilterBuilderOrderItem_OrderItemOption(),
    OrderItem_OrderItemOptionRelationBuilder,
    OrderItem_OrderItemOptionParser
  ),

}


const  OrderItemOptionParser : EntityParser<OrderItemOption>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Price: parseNumber,
  OrderItemOption_Option: p => parseCollection(x => parseEntity(x, p)),
      OrderItem_OrderItemOption: p => parseCollection(x => parseEntity(x, p)),
}


const OrderItemOptionOrderBy : (p: string) => OrderByBuilderComplex<OrderItemOption> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Price:  new OrderByProp(p ? p + "/Price" : "Price"),
  OrderItemOption_Option:  p => OrderItemOption_OptionOrderBy(p ? p + "/OrderItemOption_Option" : "OrderItemOption_Option"),
      OrderItem_OrderItemOption:  p => OrderItem_OrderItemOptionOrderBy(p ? p + "/OrderItem_OrderItemOption" : "OrderItem_OrderItemOption"),
})



export type OrderItemOption_Option = {
    Id: number
    OrderItemOptionId: number
    OptionId: number
} & {
  Option: List<Option>
    OrderItemOption: List<OrderItemOption>
}


function FilterBuilderOrderItemOption_Option(prefix?: string) : FilterBuilderComplex<OrderItemOption_Option> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      OrderItemOptionId: new FilterBuilderNumber(mk_prefix('OrderItemOptionId', prefix)),
      OptionId: new FilterBuilderNumber(mk_prefix('OptionId', prefix)),
    Option: new FilterBuilderCollection<Option>(mk_prefix('Option', prefix), s => FilterBuilderOption(s)),
      OrderItemOption: new FilterBuilderCollection<OrderItemOption>(mk_prefix('OrderItemOption', prefix), s => FilterBuilderOrderItemOption(s)),
  }
}


const OrderItemOption_OptionRelationBuilder: RelationBuilder<RelationsOf<OrderItemOption_Option>> = {
Option: () => mk_rel_query(
    'Option',
    FilterBuilderOption(),
    OptionRelationBuilder,
    OptionParser
  ),

OrderItemOption: () => mk_rel_query(
    'OrderItemOption',
    FilterBuilderOrderItemOption(),
    OrderItemOptionRelationBuilder,
    OrderItemOptionParser
  ),

}


const  OrderItemOption_OptionParser : EntityParser<OrderItemOption_Option>= {
  Id: parseNumber,
      OrderItemOptionId: parseNumber,
      OptionId: parseNumber,
  Option: p => parseCollection(x => parseEntity(x, p)),
      OrderItemOption: p => parseCollection(x => parseEntity(x, p)),
}


const OrderItemOption_OptionOrderBy : (p: string) => OrderByBuilderComplex<OrderItemOption_Option> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      OrderItemOptionId:  new OrderByProp(p ? p + "/OrderItemOptionId" : "OrderItemOptionId"),
      OptionId:  new OrderByProp(p ? p + "/OptionId" : "OptionId"),
  Option:  p => OptionOrderBy(p ? p + "/Option" : "Option"),
      OrderItemOption:  p => OrderItemOptionOrderBy(p ? p + "/OrderItemOption" : "OrderItemOption"),
})



export type OrderItemProduct = {
    Id: number
    CreatedDate: Date
    Price: number
} & {
  OrderItemProduct_Product: List<OrderItemProduct_Product>
    OrderItem_OrderItemProduct: List<OrderItem_OrderItemProduct>
}


function FilterBuilderOrderItemProduct(prefix?: string) : FilterBuilderComplex<OrderItemProduct> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Price: new FilterBuilderNumber(mk_prefix('Price', prefix)),
    OrderItemProduct_Product: new FilterBuilderCollection<OrderItemProduct_Product>(mk_prefix('OrderItemProduct_Product', prefix), s => FilterBuilderOrderItemProduct_Product(s)),
      OrderItem_OrderItemProduct: new FilterBuilderCollection<OrderItem_OrderItemProduct>(mk_prefix('OrderItem_OrderItemProduct', prefix), s => FilterBuilderOrderItem_OrderItemProduct(s)),
  }
}


const OrderItemProductRelationBuilder: RelationBuilder<RelationsOf<OrderItemProduct>> = {
OrderItemProduct_Product: () => mk_rel_query(
    'OrderItemProduct_Product',
    FilterBuilderOrderItemProduct_Product(),
    OrderItemProduct_ProductRelationBuilder,
    OrderItemProduct_ProductParser
  ),

OrderItem_OrderItemProduct: () => mk_rel_query(
    'OrderItem_OrderItemProduct',
    FilterBuilderOrderItem_OrderItemProduct(),
    OrderItem_OrderItemProductRelationBuilder,
    OrderItem_OrderItemProductParser
  ),

}


const  OrderItemProductParser : EntityParser<OrderItemProduct>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Price: parseNumber,
  OrderItemProduct_Product: p => parseCollection(x => parseEntity(x, p)),
      OrderItem_OrderItemProduct: p => parseCollection(x => parseEntity(x, p)),
}


const OrderItemProductOrderBy : (p: string) => OrderByBuilderComplex<OrderItemProduct> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Price:  new OrderByProp(p ? p + "/Price" : "Price"),
  OrderItemProduct_Product:  p => OrderItemProduct_ProductOrderBy(p ? p + "/OrderItemProduct_Product" : "OrderItemProduct_Product"),
      OrderItem_OrderItemProduct:  p => OrderItem_OrderItemProductOrderBy(p ? p + "/OrderItem_OrderItemProduct" : "OrderItem_OrderItemProduct"),
})



export type OrderItemProduct_Product = {
    Id: number
    OrderItemProductId: number
    ProductId: number
} & {
  OrderItemProduct: List<OrderItemProduct>
    Product: List<Product>
}


function FilterBuilderOrderItemProduct_Product(prefix?: string) : FilterBuilderComplex<OrderItemProduct_Product> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      OrderItemProductId: new FilterBuilderNumber(mk_prefix('OrderItemProductId', prefix)),
      ProductId: new FilterBuilderNumber(mk_prefix('ProductId', prefix)),
    OrderItemProduct: new FilterBuilderCollection<OrderItemProduct>(mk_prefix('OrderItemProduct', prefix), s => FilterBuilderOrderItemProduct(s)),
      Product: new FilterBuilderCollection<Product>(mk_prefix('Product', prefix), s => FilterBuilderProduct(s)),
  }
}


const OrderItemProduct_ProductRelationBuilder: RelationBuilder<RelationsOf<OrderItemProduct_Product>> = {
OrderItemProduct: () => mk_rel_query(
    'OrderItemProduct',
    FilterBuilderOrderItemProduct(),
    OrderItemProductRelationBuilder,
    OrderItemProductParser
  ),

Product: () => mk_rel_query(
    'Product',
    FilterBuilderProduct(),
    ProductRelationBuilder,
    ProductParser
  ),

}


const  OrderItemProduct_ProductParser : EntityParser<OrderItemProduct_Product>= {
  Id: parseNumber,
      OrderItemProductId: parseNumber,
      ProductId: parseNumber,
  OrderItemProduct: p => parseCollection(x => parseEntity(x, p)),
      Product: p => parseCollection(x => parseEntity(x, p)),
}


const OrderItemProduct_ProductOrderBy : (p: string) => OrderByBuilderComplex<OrderItemProduct_Product> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      OrderItemProductId:  new OrderByProp(p ? p + "/OrderItemProductId" : "OrderItemProductId"),
      ProductId:  new OrderByProp(p ? p + "/ProductId" : "ProductId"),
  OrderItemProduct:  p => OrderItemProductOrderBy(p ? p + "/OrderItemProduct" : "OrderItemProduct"),
      Product:  p => ProductOrderBy(p ? p + "/Product" : "Product"),
})



export type OrderItem_OrderItemOption = {
    Id: number
    OrderItemId: number
    OrderItemOptionId: number
} & {
  OrderItem: List<OrderItem>
    OrderItemOption: List<OrderItemOption>
}


function FilterBuilderOrderItem_OrderItemOption(prefix?: string) : FilterBuilderComplex<OrderItem_OrderItemOption> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      OrderItemId: new FilterBuilderNumber(mk_prefix('OrderItemId', prefix)),
      OrderItemOptionId: new FilterBuilderNumber(mk_prefix('OrderItemOptionId', prefix)),
    OrderItem: new FilterBuilderCollection<OrderItem>(mk_prefix('OrderItem', prefix), s => FilterBuilderOrderItem(s)),
      OrderItemOption: new FilterBuilderCollection<OrderItemOption>(mk_prefix('OrderItemOption', prefix), s => FilterBuilderOrderItemOption(s)),
  }
}


const OrderItem_OrderItemOptionRelationBuilder: RelationBuilder<RelationsOf<OrderItem_OrderItemOption>> = {
OrderItem: () => mk_rel_query(
    'OrderItem',
    FilterBuilderOrderItem(),
    OrderItemRelationBuilder,
    OrderItemParser
  ),

OrderItemOption: () => mk_rel_query(
    'OrderItemOption',
    FilterBuilderOrderItemOption(),
    OrderItemOptionRelationBuilder,
    OrderItemOptionParser
  ),

}


const  OrderItem_OrderItemOptionParser : EntityParser<OrderItem_OrderItemOption>= {
  Id: parseNumber,
      OrderItemId: parseNumber,
      OrderItemOptionId: parseNumber,
  OrderItem: p => parseCollection(x => parseEntity(x, p)),
      OrderItemOption: p => parseCollection(x => parseEntity(x, p)),
}


const OrderItem_OrderItemOptionOrderBy : (p: string) => OrderByBuilderComplex<OrderItem_OrderItemOption> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      OrderItemId:  new OrderByProp(p ? p + "/OrderItemId" : "OrderItemId"),
      OrderItemOptionId:  new OrderByProp(p ? p + "/OrderItemOptionId" : "OrderItemOptionId"),
  OrderItem:  p => OrderItemOrderBy(p ? p + "/OrderItem" : "OrderItem"),
      OrderItemOption:  p => OrderItemOptionOrderBy(p ? p + "/OrderItemOption" : "OrderItemOption"),
})



export type OrderItem_OrderItemProduct = {
    Id: number
    OrderItemId: number
    OrderItemProductId: number
} & {
  OrderItem: List<OrderItem>
    OrderItemProduct: List<OrderItemProduct>
}


function FilterBuilderOrderItem_OrderItemProduct(prefix?: string) : FilterBuilderComplex<OrderItem_OrderItemProduct> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      OrderItemId: new FilterBuilderNumber(mk_prefix('OrderItemId', prefix)),
      OrderItemProductId: new FilterBuilderNumber(mk_prefix('OrderItemProductId', prefix)),
    OrderItem: new FilterBuilderCollection<OrderItem>(mk_prefix('OrderItem', prefix), s => FilterBuilderOrderItem(s)),
      OrderItemProduct: new FilterBuilderCollection<OrderItemProduct>(mk_prefix('OrderItemProduct', prefix), s => FilterBuilderOrderItemProduct(s)),
  }
}


const OrderItem_OrderItemProductRelationBuilder: RelationBuilder<RelationsOf<OrderItem_OrderItemProduct>> = {
OrderItem: () => mk_rel_query(
    'OrderItem',
    FilterBuilderOrderItem(),
    OrderItemRelationBuilder,
    OrderItemParser
  ),

OrderItemProduct: () => mk_rel_query(
    'OrderItemProduct',
    FilterBuilderOrderItemProduct(),
    OrderItemProductRelationBuilder,
    OrderItemProductParser
  ),

}


const  OrderItem_OrderItemProductParser : EntityParser<OrderItem_OrderItemProduct>= {
  Id: parseNumber,
      OrderItemId: parseNumber,
      OrderItemProductId: parseNumber,
  OrderItem: p => parseCollection(x => parseEntity(x, p)),
      OrderItemProduct: p => parseCollection(x => parseEntity(x, p)),
}


const OrderItem_OrderItemProductOrderBy : (p: string) => OrderByBuilderComplex<OrderItem_OrderItemProduct> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      OrderItemId:  new OrderByProp(p ? p + "/OrderItemId" : "OrderItemId"),
      OrderItemProductId:  new OrderByProp(p ? p + "/OrderItemProductId" : "OrderItemProductId"),
  OrderItem:  p => OrderItemOrderBy(p ? p + "/OrderItem" : "OrderItem"),
      OrderItemProduct:  p => OrderItemProductOrderBy(p ? p + "/OrderItemProduct" : "OrderItemProduct"),
})



export type Order_Discount = {
    Id: number
    OrderId: number
    DiscountId: number
} & {
  Discount: List<Discount>
    Order: List<Order>
}


function FilterBuilderOrder_Discount(prefix?: string) : FilterBuilderComplex<Order_Discount> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      OrderId: new FilterBuilderNumber(mk_prefix('OrderId', prefix)),
      DiscountId: new FilterBuilderNumber(mk_prefix('DiscountId', prefix)),
    Discount: new FilterBuilderCollection<Discount>(mk_prefix('Discount', prefix), s => FilterBuilderDiscount(s)),
      Order: new FilterBuilderCollection<Order>(mk_prefix('Order', prefix), s => FilterBuilderOrder(s)),
  }
}


const Order_DiscountRelationBuilder: RelationBuilder<RelationsOf<Order_Discount>> = {
Discount: () => mk_rel_query(
    'Discount',
    FilterBuilderDiscount(),
    DiscountRelationBuilder,
    DiscountParser
  ),

Order: () => mk_rel_query(
    'Order',
    FilterBuilderOrder(),
    OrderRelationBuilder,
    OrderParser
  ),

}


const  Order_DiscountParser : EntityParser<Order_Discount>= {
  Id: parseNumber,
      OrderId: parseNumber,
      DiscountId: parseNumber,
  Discount: p => parseCollection(x => parseEntity(x, p)),
      Order: p => parseCollection(x => parseEntity(x, p)),
}


const Order_DiscountOrderBy : (p: string) => OrderByBuilderComplex<Order_Discount> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      OrderId:  new OrderByProp(p ? p + "/OrderId" : "OrderId"),
      DiscountId:  new OrderByProp(p ? p + "/DiscountId" : "DiscountId"),
  Discount:  p => DiscountOrderBy(p ? p + "/Discount" : "Discount"),
      Order:  p => OrderOrderBy(p ? p + "/Order" : "Order"),
})



export type Order_OrderItem = {
    Id: number
    OrderId: number
    OrderItemId: number
} & {
  Order: List<Order>
    OrderItem: List<OrderItem>
}


function FilterBuilderOrder_OrderItem(prefix?: string) : FilterBuilderComplex<Order_OrderItem> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      OrderId: new FilterBuilderNumber(mk_prefix('OrderId', prefix)),
      OrderItemId: new FilterBuilderNumber(mk_prefix('OrderItemId', prefix)),
    Order: new FilterBuilderCollection<Order>(mk_prefix('Order', prefix), s => FilterBuilderOrder(s)),
      OrderItem: new FilterBuilderCollection<OrderItem>(mk_prefix('OrderItem', prefix), s => FilterBuilderOrderItem(s)),
  }
}


const Order_OrderItemRelationBuilder: RelationBuilder<RelationsOf<Order_OrderItem>> = {
Order: () => mk_rel_query(
    'Order',
    FilterBuilderOrder(),
    OrderRelationBuilder,
    OrderParser
  ),

OrderItem: () => mk_rel_query(
    'OrderItem',
    FilterBuilderOrderItem(),
    OrderItemRelationBuilder,
    OrderItemParser
  ),

}


const  Order_OrderItemParser : EntityParser<Order_OrderItem>= {
  Id: parseNumber,
      OrderId: parseNumber,
      OrderItemId: parseNumber,
  Order: p => parseCollection(x => parseEntity(x, p)),
      OrderItem: p => parseCollection(x => parseEntity(x, p)),
}


const Order_OrderItemOrderBy : (p: string) => OrderByBuilderComplex<Order_OrderItem> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      OrderId:  new OrderByProp(p ? p + "/OrderId" : "OrderId"),
      OrderItemId:  new OrderByProp(p ? p + "/OrderItemId" : "OrderItemId"),
  Order:  p => OrderOrderBy(p ? p + "/Order" : "Order"),
      OrderItem:  p => OrderItemOrderBy(p ? p + "/OrderItem" : "OrderItem"),
})



export type Order_PayMethod = {
    Id: number
    OrderId: number
    PayMethodId: number
} & {
  Order: List<Order>
    PayMethod: List<PayMethod>
}


function FilterBuilderOrder_PayMethod(prefix?: string) : FilterBuilderComplex<Order_PayMethod> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      OrderId: new FilterBuilderNumber(mk_prefix('OrderId', prefix)),
      PayMethodId: new FilterBuilderNumber(mk_prefix('PayMethodId', prefix)),
    Order: new FilterBuilderCollection<Order>(mk_prefix('Order', prefix), s => FilterBuilderOrder(s)),
      PayMethod: new FilterBuilderCollection<PayMethod>(mk_prefix('PayMethod', prefix), s => FilterBuilderPayMethod(s)),
  }
}


const Order_PayMethodRelationBuilder: RelationBuilder<RelationsOf<Order_PayMethod>> = {
Order: () => mk_rel_query(
    'Order',
    FilterBuilderOrder(),
    OrderRelationBuilder,
    OrderParser
  ),

PayMethod: () => mk_rel_query(
    'PayMethod',
    FilterBuilderPayMethod(),
    PayMethodRelationBuilder,
    PayMethodParser
  ),

}


const  Order_PayMethodParser : EntityParser<Order_PayMethod>= {
  Id: parseNumber,
      OrderId: parseNumber,
      PayMethodId: parseNumber,
  Order: p => parseCollection(x => parseEntity(x, p)),
      PayMethod: p => parseCollection(x => parseEntity(x, p)),
}


const Order_PayMethodOrderBy : (p: string) => OrderByBuilderComplex<Order_PayMethod> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      OrderId:  new OrderByProp(p ? p + "/OrderId" : "OrderId"),
      PayMethodId:  new OrderByProp(p ? p + "/PayMethodId" : "PayMethodId"),
  Order:  p => OrderOrderBy(p ? p + "/Order" : "Order"),
      PayMethod:  p => PayMethodOrderBy(p ? p + "/PayMethod" : "PayMethod"),
})



export type Order_Restaurant = {
    Id: number
    OrderId: number
    RestaurantId: number
} & {
  Order: List<Order>
    Restaurant: List<Restaurant>
}


function FilterBuilderOrder_Restaurant(prefix?: string) : FilterBuilderComplex<Order_Restaurant> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      OrderId: new FilterBuilderNumber(mk_prefix('OrderId', prefix)),
      RestaurantId: new FilterBuilderNumber(mk_prefix('RestaurantId', prefix)),
    Order: new FilterBuilderCollection<Order>(mk_prefix('Order', prefix), s => FilterBuilderOrder(s)),
      Restaurant: new FilterBuilderCollection<Restaurant>(mk_prefix('Restaurant', prefix), s => FilterBuilderRestaurant(s)),
  }
}


const Order_RestaurantRelationBuilder: RelationBuilder<RelationsOf<Order_Restaurant>> = {
Order: () => mk_rel_query(
    'Order',
    FilterBuilderOrder(),
    OrderRelationBuilder,
    OrderParser
  ),

Restaurant: () => mk_rel_query(
    'Restaurant',
    FilterBuilderRestaurant(),
    RestaurantRelationBuilder,
    RestaurantParser
  ),

}


const  Order_RestaurantParser : EntityParser<Order_Restaurant>= {
  Id: parseNumber,
      OrderId: parseNumber,
      RestaurantId: parseNumber,
  Order: p => parseCollection(x => parseEntity(x, p)),
      Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const Order_RestaurantOrderBy : (p: string) => OrderByBuilderComplex<Order_Restaurant> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      OrderId:  new OrderByProp(p ? p + "/OrderId" : "OrderId"),
      RestaurantId:  new OrderByProp(p ? p + "/RestaurantId" : "RestaurantId"),
  Order:  p => OrderOrderBy(p ? p + "/Order" : "Order"),
      Restaurant:  p => RestaurantOrderBy(p ? p + "/Restaurant" : "Restaurant"),
})



export type Order_User = {
    Id: number
    OrderId: number
    UserId: number
} & {
  Order: List<Order>
    User: List<User>
}


function FilterBuilderOrder_User(prefix?: string) : FilterBuilderComplex<Order_User> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      OrderId: new FilterBuilderNumber(mk_prefix('OrderId', prefix)),
      UserId: new FilterBuilderNumber(mk_prefix('UserId', prefix)),
    Order: new FilterBuilderCollection<Order>(mk_prefix('Order', prefix), s => FilterBuilderOrder(s)),
      User: new FilterBuilderCollection<User>(mk_prefix('User', prefix), s => FilterBuilderUser(s)),
  }
}


const Order_UserRelationBuilder: RelationBuilder<RelationsOf<Order_User>> = {
Order: () => mk_rel_query(
    'Order',
    FilterBuilderOrder(),
    OrderRelationBuilder,
    OrderParser
  ),

User: () => mk_rel_query(
    'User',
    FilterBuilderUser(),
    UserRelationBuilder,
    UserParser
  ),

}


const  Order_UserParser : EntityParser<Order_User>= {
  Id: parseNumber,
      OrderId: parseNumber,
      UserId: parseNumber,
  Order: p => parseCollection(x => parseEntity(x, p)),
      User: p => parseCollection(x => parseEntity(x, p)),
}


const Order_UserOrderBy : (p: string) => OrderByBuilderComplex<Order_User> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      OrderId:  new OrderByProp(p ? p + "/OrderId" : "OrderId"),
      UserId:  new OrderByProp(p ? p + "/UserId" : "UserId"),
  Order:  p => OrderOrderBy(p ? p + "/Order" : "Order"),
      User:  p => UserOrderBy(p ? p + "/User" : "User"),
})



export type POS4 = {
    Id: number
    CreatedDate: Date
    Username: string | null
    FirstName: string | null
    LastName: string | null
    Email: string | null
    Language: string | null
    Token: string | null
    TokenExpiration: Date
    PasswordHash: string | null
    PasswordSalt: string | null
    LastLoginAttempt: Date
    SecurityStamp: string | null
    EmailConfirmed: boolean
} & {
  POS4_POS4LoginEvent: List<POS4_POS4LoginEvent>
    POS4_POS4OrderFetchEvent: List<POS4_POS4OrderFetchEvent>
    Restaurant_POS4: List<Restaurant_POS4>
}


function FilterBuilderPOS4(prefix?: string) : FilterBuilderComplex<POS4> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Username: new FilterBuilderString(mk_prefix('Username', prefix)),
      FirstName: new FilterBuilderString(mk_prefix('FirstName', prefix)),
      LastName: new FilterBuilderString(mk_prefix('LastName', prefix)),
      Email: new FilterBuilderString(mk_prefix('Email', prefix)),
      Language: new FilterBuilderString(mk_prefix('Language', prefix)),
      Token: new FilterBuilderString(mk_prefix('Token', prefix)),
      TokenExpiration: new FilterBuilderDate(mk_prefix('TokenExpiration', prefix)),
      PasswordHash: new FilterBuilderString(mk_prefix('PasswordHash', prefix)),
      PasswordSalt: new FilterBuilderString(mk_prefix('PasswordSalt', prefix)),
      LastLoginAttempt: new FilterBuilderDate(mk_prefix('LastLoginAttempt', prefix)),
      SecurityStamp: new FilterBuilderString(mk_prefix('SecurityStamp', prefix)),
      EmailConfirmed: new FilterBuilderBoolean(mk_prefix('EmailConfirmed', prefix)),
    POS4_POS4LoginEvent: new FilterBuilderCollection<POS4_POS4LoginEvent>(mk_prefix('POS4_POS4LoginEvent', prefix), s => FilterBuilderPOS4_POS4LoginEvent(s)),
      POS4_POS4OrderFetchEvent: new FilterBuilderCollection<POS4_POS4OrderFetchEvent>(mk_prefix('POS4_POS4OrderFetchEvent', prefix), s => FilterBuilderPOS4_POS4OrderFetchEvent(s)),
      Restaurant_POS4: new FilterBuilderCollection<Restaurant_POS4>(mk_prefix('Restaurant_POS4', prefix), s => FilterBuilderRestaurant_POS4(s)),
  }
}


const POS4RelationBuilder: RelationBuilder<RelationsOf<POS4>> = {
POS4_POS4LoginEvent: () => mk_rel_query(
    'POS4_POS4LoginEvent',
    FilterBuilderPOS4_POS4LoginEvent(),
    POS4_POS4LoginEventRelationBuilder,
    POS4_POS4LoginEventParser
  ),

POS4_POS4OrderFetchEvent: () => mk_rel_query(
    'POS4_POS4OrderFetchEvent',
    FilterBuilderPOS4_POS4OrderFetchEvent(),
    POS4_POS4OrderFetchEventRelationBuilder,
    POS4_POS4OrderFetchEventParser
  ),

Restaurant_POS4: () => mk_rel_query(
    'Restaurant_POS4',
    FilterBuilderRestaurant_POS4(),
    Restaurant_POS4RelationBuilder,
    Restaurant_POS4Parser
  ),

}


const  POS4Parser : EntityParser<POS4>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Username: parseNullString,
      FirstName: parseNullString,
      LastName: parseNullString,
      Email: parseNullString,
      Language: parseNullString,
      Token: parseNullString,
      TokenExpiration: parseDate,
      PasswordHash: parseNullString,
      PasswordSalt: parseNullString,
      LastLoginAttempt: parseDate,
      SecurityStamp: parseNullString,
      EmailConfirmed: parseBoolean,
  POS4_POS4LoginEvent: p => parseCollection(x => parseEntity(x, p)),
      POS4_POS4OrderFetchEvent: p => parseCollection(x => parseEntity(x, p)),
      Restaurant_POS4: p => parseCollection(x => parseEntity(x, p)),
}


const POS4OrderBy : (p: string) => OrderByBuilderComplex<POS4> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Username:  new OrderByProp(p ? p + "/Username" : "Username"),
      FirstName:  new OrderByProp(p ? p + "/FirstName" : "FirstName"),
      LastName:  new OrderByProp(p ? p + "/LastName" : "LastName"),
      Email:  new OrderByProp(p ? p + "/Email" : "Email"),
      Language:  new OrderByProp(p ? p + "/Language" : "Language"),
      Token:  new OrderByProp(p ? p + "/Token" : "Token"),
      TokenExpiration:  new OrderByProp(p ? p + "/TokenExpiration" : "TokenExpiration"),
      PasswordHash:  new OrderByProp(p ? p + "/PasswordHash" : "PasswordHash"),
      PasswordSalt:  new OrderByProp(p ? p + "/PasswordSalt" : "PasswordSalt"),
      LastLoginAttempt:  new OrderByProp(p ? p + "/LastLoginAttempt" : "LastLoginAttempt"),
      SecurityStamp:  new OrderByProp(p ? p + "/SecurityStamp" : "SecurityStamp"),
      EmailConfirmed:  new OrderByProp(p ? p + "/EmailConfirmed" : "EmailConfirmed"),
  POS4_POS4LoginEvent:  p => POS4_POS4LoginEventOrderBy(p ? p + "/POS4_POS4LoginEvent" : "POS4_POS4LoginEvent"),
      POS4_POS4OrderFetchEvent:  p => POS4_POS4OrderFetchEventOrderBy(p ? p + "/POS4_POS4OrderFetchEvent" : "POS4_POS4OrderFetchEvent"),
      Restaurant_POS4:  p => Restaurant_POS4OrderBy(p ? p + "/Restaurant_POS4" : "Restaurant_POS4"),
})



export type POS4Event = {
    Id: number
    CreatedDate: Date
    Date: Date
    Status: string | null
    Message: string | null
} & {

}


function FilterBuilderPOS4Event(prefix?: string) : FilterBuilderComplex<POS4Event> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Date: new FilterBuilderDate(mk_prefix('Date', prefix)),
      Status: new FilterBuilderString(mk_prefix('Status', prefix)),
      Message: new FilterBuilderString(mk_prefix('Message', prefix)),
    
  }
}


const POS4EventRelationBuilder: RelationBuilder<RelationsOf<POS4Event>> = {

}


const  POS4EventParser : EntityParser<POS4Event>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Date: parseDate,
      Status: parseNullString,
      Message: parseNullString,
  
}


const POS4EventOrderBy : (p: string) => OrderByBuilderComplex<POS4Event> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Date:  new OrderByProp(p ? p + "/Date" : "Date"),
      Status:  new OrderByProp(p ? p + "/Status" : "Status"),
      Message:  new OrderByProp(p ? p + "/Message" : "Message"),
  
})



export type POS4LoginEvent = {
    Id: number
    CreatedDate: Date
    Date: Date
    Status: string | null
    Message: string | null
} & {
  POS4_POS4LoginEvent: List<POS4_POS4LoginEvent>
}


function FilterBuilderPOS4LoginEvent(prefix?: string) : FilterBuilderComplex<POS4LoginEvent> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Date: new FilterBuilderDate(mk_prefix('Date', prefix)),
      Status: new FilterBuilderString(mk_prefix('Status', prefix)),
      Message: new FilterBuilderString(mk_prefix('Message', prefix)),
    POS4_POS4LoginEvent: new FilterBuilderCollection<POS4_POS4LoginEvent>(mk_prefix('POS4_POS4LoginEvent', prefix), s => FilterBuilderPOS4_POS4LoginEvent(s)),
  }
}


const POS4LoginEventRelationBuilder: RelationBuilder<RelationsOf<POS4LoginEvent>> = {
POS4_POS4LoginEvent: () => mk_rel_query(
    'POS4_POS4LoginEvent',
    FilterBuilderPOS4_POS4LoginEvent(),
    POS4_POS4LoginEventRelationBuilder,
    POS4_POS4LoginEventParser
  ),

}


const  POS4LoginEventParser : EntityParser<POS4LoginEvent>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Date: parseDate,
      Status: parseNullString,
      Message: parseNullString,
  POS4_POS4LoginEvent: p => parseCollection(x => parseEntity(x, p)),
}


const POS4LoginEventOrderBy : (p: string) => OrderByBuilderComplex<POS4LoginEvent> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Date:  new OrderByProp(p ? p + "/Date" : "Date"),
      Status:  new OrderByProp(p ? p + "/Status" : "Status"),
      Message:  new OrderByProp(p ? p + "/Message" : "Message"),
  POS4_POS4LoginEvent:  p => POS4_POS4LoginEventOrderBy(p ? p + "/POS4_POS4LoginEvent" : "POS4_POS4LoginEvent"),
})



export type POS4OrderFetchEvent = {
    Id: number
    CreatedDate: Date
    Date: Date
    Status: string | null
    Message: string | null
} & {
  POS4_POS4OrderFetchEvent: List<POS4_POS4OrderFetchEvent>
}


function FilterBuilderPOS4OrderFetchEvent(prefix?: string) : FilterBuilderComplex<POS4OrderFetchEvent> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Date: new FilterBuilderDate(mk_prefix('Date', prefix)),
      Status: new FilterBuilderString(mk_prefix('Status', prefix)),
      Message: new FilterBuilderString(mk_prefix('Message', prefix)),
    POS4_POS4OrderFetchEvent: new FilterBuilderCollection<POS4_POS4OrderFetchEvent>(mk_prefix('POS4_POS4OrderFetchEvent', prefix), s => FilterBuilderPOS4_POS4OrderFetchEvent(s)),
  }
}


const POS4OrderFetchEventRelationBuilder: RelationBuilder<RelationsOf<POS4OrderFetchEvent>> = {
POS4_POS4OrderFetchEvent: () => mk_rel_query(
    'POS4_POS4OrderFetchEvent',
    FilterBuilderPOS4_POS4OrderFetchEvent(),
    POS4_POS4OrderFetchEventRelationBuilder,
    POS4_POS4OrderFetchEventParser
  ),

}


const  POS4OrderFetchEventParser : EntityParser<POS4OrderFetchEvent>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Date: parseDate,
      Status: parseNullString,
      Message: parseNullString,
  POS4_POS4OrderFetchEvent: p => parseCollection(x => parseEntity(x, p)),
}


const POS4OrderFetchEventOrderBy : (p: string) => OrderByBuilderComplex<POS4OrderFetchEvent> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Date:  new OrderByProp(p ? p + "/Date" : "Date"),
      Status:  new OrderByProp(p ? p + "/Status" : "Status"),
      Message:  new OrderByProp(p ? p + "/Message" : "Message"),
  POS4_POS4OrderFetchEvent:  p => POS4_POS4OrderFetchEventOrderBy(p ? p + "/POS4_POS4OrderFetchEvent" : "POS4_POS4OrderFetchEvent"),
})



export type POS4_POS4LoginEvent = {
    Id: number
    POS4Id: number
    POS4LoginEventId: number
} & {
  POS4: List<POS4>
    POS4LoginEvent: List<POS4LoginEvent>
}


function FilterBuilderPOS4_POS4LoginEvent(prefix?: string) : FilterBuilderComplex<POS4_POS4LoginEvent> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      POS4Id: new FilterBuilderNumber(mk_prefix('POS4Id', prefix)),
      POS4LoginEventId: new FilterBuilderNumber(mk_prefix('POS4LoginEventId', prefix)),
    POS4: new FilterBuilderCollection<POS4>(mk_prefix('POS4', prefix), s => FilterBuilderPOS4(s)),
      POS4LoginEvent: new FilterBuilderCollection<POS4LoginEvent>(mk_prefix('POS4LoginEvent', prefix), s => FilterBuilderPOS4LoginEvent(s)),
  }
}


const POS4_POS4LoginEventRelationBuilder: RelationBuilder<RelationsOf<POS4_POS4LoginEvent>> = {
POS4: () => mk_rel_query(
    'POS4',
    FilterBuilderPOS4(),
    POS4RelationBuilder,
    POS4Parser
  ),

POS4LoginEvent: () => mk_rel_query(
    'POS4LoginEvent',
    FilterBuilderPOS4LoginEvent(),
    POS4LoginEventRelationBuilder,
    POS4LoginEventParser
  ),

}


const  POS4_POS4LoginEventParser : EntityParser<POS4_POS4LoginEvent>= {
  Id: parseNumber,
      POS4Id: parseNumber,
      POS4LoginEventId: parseNumber,
  POS4: p => parseCollection(x => parseEntity(x, p)),
      POS4LoginEvent: p => parseCollection(x => parseEntity(x, p)),
}


const POS4_POS4LoginEventOrderBy : (p: string) => OrderByBuilderComplex<POS4_POS4LoginEvent> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      POS4Id:  new OrderByProp(p ? p + "/POS4Id" : "POS4Id"),
      POS4LoginEventId:  new OrderByProp(p ? p + "/POS4LoginEventId" : "POS4LoginEventId"),
  POS4:  p => POS4OrderBy(p ? p + "/POS4" : "POS4"),
      POS4LoginEvent:  p => POS4LoginEventOrderBy(p ? p + "/POS4LoginEvent" : "POS4LoginEvent"),
})



export type POS4_POS4OrderFetchEvent = {
    Id: number
    POS4Id: number
    POS4OrderFetchEventId: number
} & {
  POS4: List<POS4>
    POS4OrderFetchEvent: List<POS4OrderFetchEvent>
}


function FilterBuilderPOS4_POS4OrderFetchEvent(prefix?: string) : FilterBuilderComplex<POS4_POS4OrderFetchEvent> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      POS4Id: new FilterBuilderNumber(mk_prefix('POS4Id', prefix)),
      POS4OrderFetchEventId: new FilterBuilderNumber(mk_prefix('POS4OrderFetchEventId', prefix)),
    POS4: new FilterBuilderCollection<POS4>(mk_prefix('POS4', prefix), s => FilterBuilderPOS4(s)),
      POS4OrderFetchEvent: new FilterBuilderCollection<POS4OrderFetchEvent>(mk_prefix('POS4OrderFetchEvent', prefix), s => FilterBuilderPOS4OrderFetchEvent(s)),
  }
}


const POS4_POS4OrderFetchEventRelationBuilder: RelationBuilder<RelationsOf<POS4_POS4OrderFetchEvent>> = {
POS4: () => mk_rel_query(
    'POS4',
    FilterBuilderPOS4(),
    POS4RelationBuilder,
    POS4Parser
  ),

POS4OrderFetchEvent: () => mk_rel_query(
    'POS4OrderFetchEvent',
    FilterBuilderPOS4OrderFetchEvent(),
    POS4OrderFetchEventRelationBuilder,
    POS4OrderFetchEventParser
  ),

}


const  POS4_POS4OrderFetchEventParser : EntityParser<POS4_POS4OrderFetchEvent>= {
  Id: parseNumber,
      POS4Id: parseNumber,
      POS4OrderFetchEventId: parseNumber,
  POS4: p => parseCollection(x => parseEntity(x, p)),
      POS4OrderFetchEvent: p => parseCollection(x => parseEntity(x, p)),
}


const POS4_POS4OrderFetchEventOrderBy : (p: string) => OrderByBuilderComplex<POS4_POS4OrderFetchEvent> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      POS4Id:  new OrderByProp(p ? p + "/POS4Id" : "POS4Id"),
      POS4OrderFetchEventId:  new OrderByProp(p ? p + "/POS4OrderFetchEventId" : "POS4OrderFetchEventId"),
  POS4:  p => POS4OrderBy(p ? p + "/POS4" : "POS4"),
      POS4OrderFetchEvent:  p => POS4OrderFetchEventOrderBy(p ? p + "/POS4OrderFetchEvent" : "POS4OrderFetchEvent"),
})



export type PayMethod = {
    Id: number
    CreatedDate: Date
    Method: string | null
    POS4: string | null
    PaymentType: string | null
    DisplayTitle: string | null
} & {
  DeliveryRestaurant_DeliveryPayMethod: List<DeliveryRestaurant_DeliveryPayMethod>
    Order_PayMethod: List<Order_PayMethod>
    PickUpRestaurant_PickUpPayMethod: List<PickUpRestaurant_PickUpPayMethod>
}


function FilterBuilderPayMethod(prefix?: string) : FilterBuilderComplex<PayMethod> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Method: new FilterBuilderString(mk_prefix('Method', prefix)),
      POS4: new FilterBuilderString(mk_prefix('POS4', prefix)),
      PaymentType: new FilterBuilderString(mk_prefix('PaymentType', prefix)),
      DisplayTitle: new FilterBuilderString(mk_prefix('DisplayTitle', prefix)),
    DeliveryRestaurant_DeliveryPayMethod: new FilterBuilderCollection<DeliveryRestaurant_DeliveryPayMethod>(mk_prefix('DeliveryRestaurant_DeliveryPayMethod', prefix), s => FilterBuilderDeliveryRestaurant_DeliveryPayMethod(s)),
      Order_PayMethod: new FilterBuilderCollection<Order_PayMethod>(mk_prefix('Order_PayMethod', prefix), s => FilterBuilderOrder_PayMethod(s)),
      PickUpRestaurant_PickUpPayMethod: new FilterBuilderCollection<PickUpRestaurant_PickUpPayMethod>(mk_prefix('PickUpRestaurant_PickUpPayMethod', prefix), s => FilterBuilderPickUpRestaurant_PickUpPayMethod(s)),
  }
}


const PayMethodRelationBuilder: RelationBuilder<RelationsOf<PayMethod>> = {
DeliveryRestaurant_DeliveryPayMethod: () => mk_rel_query(
    'DeliveryRestaurant_DeliveryPayMethod',
    FilterBuilderDeliveryRestaurant_DeliveryPayMethod(),
    DeliveryRestaurant_DeliveryPayMethodRelationBuilder,
    DeliveryRestaurant_DeliveryPayMethodParser
  ),

Order_PayMethod: () => mk_rel_query(
    'Order_PayMethod',
    FilterBuilderOrder_PayMethod(),
    Order_PayMethodRelationBuilder,
    Order_PayMethodParser
  ),

PickUpRestaurant_PickUpPayMethod: () => mk_rel_query(
    'PickUpRestaurant_PickUpPayMethod',
    FilterBuilderPickUpRestaurant_PickUpPayMethod(),
    PickUpRestaurant_PickUpPayMethodRelationBuilder,
    PickUpRestaurant_PickUpPayMethodParser
  ),

}


const  PayMethodParser : EntityParser<PayMethod>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Method: parseNullString,
      POS4: parseNullString,
      PaymentType: parseNullString,
      DisplayTitle: parseNullString,
  DeliveryRestaurant_DeliveryPayMethod: p => parseCollection(x => parseEntity(x, p)),
      Order_PayMethod: p => parseCollection(x => parseEntity(x, p)),
      PickUpRestaurant_PickUpPayMethod: p => parseCollection(x => parseEntity(x, p)),
}


const PayMethodOrderBy : (p: string) => OrderByBuilderComplex<PayMethod> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Method:  new OrderByProp(p ? p + "/Method" : "Method"),
      POS4:  new OrderByProp(p ? p + "/POS4" : "POS4"),
      PaymentType:  new OrderByProp(p ? p + "/PaymentType" : "PaymentType"),
      DisplayTitle:  new OrderByProp(p ? p + "/DisplayTitle" : "DisplayTitle"),
  DeliveryRestaurant_DeliveryPayMethod:  p => DeliveryRestaurant_DeliveryPayMethodOrderBy(p ? p + "/DeliveryRestaurant_DeliveryPayMethod" : "DeliveryRestaurant_DeliveryPayMethod"),
      Order_PayMethod:  p => Order_PayMethodOrderBy(p ? p + "/Order_PayMethod" : "Order_PayMethod"),
      PickUpRestaurant_PickUpPayMethod:  p => PickUpRestaurant_PickUpPayMethodOrderBy(p ? p + "/PickUpRestaurant_PickUpPayMethod" : "PickUpRestaurant_PickUpPayMethod"),
})



export type PickUpRestaurant_PickUpPayMethod = {
    Id: number
    PickUpRestaurantId: number
    PickUpPayMethodId: number
} & {
  PayMethod: List<PayMethod>
    Restaurant: List<Restaurant>
}


function FilterBuilderPickUpRestaurant_PickUpPayMethod(prefix?: string) : FilterBuilderComplex<PickUpRestaurant_PickUpPayMethod> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      PickUpRestaurantId: new FilterBuilderNumber(mk_prefix('PickUpRestaurantId', prefix)),
      PickUpPayMethodId: new FilterBuilderNumber(mk_prefix('PickUpPayMethodId', prefix)),
    PayMethod: new FilterBuilderCollection<PayMethod>(mk_prefix('PayMethod', prefix), s => FilterBuilderPayMethod(s)),
      Restaurant: new FilterBuilderCollection<Restaurant>(mk_prefix('Restaurant', prefix), s => FilterBuilderRestaurant(s)),
  }
}


const PickUpRestaurant_PickUpPayMethodRelationBuilder: RelationBuilder<RelationsOf<PickUpRestaurant_PickUpPayMethod>> = {
PayMethod: () => mk_rel_query(
    'PayMethod',
    FilterBuilderPayMethod(),
    PayMethodRelationBuilder,
    PayMethodParser
  ),

Restaurant: () => mk_rel_query(
    'Restaurant',
    FilterBuilderRestaurant(),
    RestaurantRelationBuilder,
    RestaurantParser
  ),

}


const  PickUpRestaurant_PickUpPayMethodParser : EntityParser<PickUpRestaurant_PickUpPayMethod>= {
  Id: parseNumber,
      PickUpRestaurantId: parseNumber,
      PickUpPayMethodId: parseNumber,
  PayMethod: p => parseCollection(x => parseEntity(x, p)),
      Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const PickUpRestaurant_PickUpPayMethodOrderBy : (p: string) => OrderByBuilderComplex<PickUpRestaurant_PickUpPayMethod> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      PickUpRestaurantId:  new OrderByProp(p ? p + "/PickUpRestaurantId" : "PickUpRestaurantId"),
      PickUpPayMethodId:  new OrderByProp(p ? p + "/PickUpPayMethodId" : "PickUpPayMethodId"),
  PayMethod:  p => PayMethodOrderBy(p ? p + "/PayMethod" : "PayMethod"),
      Restaurant:  p => RestaurantOrderBy(p ? p + "/Restaurant" : "Restaurant"),
})



export type PickupClosingTime = {
    Id: number
    CreatedDate: Date
    Start: Date
    End: Date
    Message: string | null
} & {
  Restaurant_PickupClosingTime: List<Restaurant_PickupClosingTime>
}


function FilterBuilderPickupClosingTime(prefix?: string) : FilterBuilderComplex<PickupClosingTime> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Start: new FilterBuilderDate(mk_prefix('Start', prefix)),
      End: new FilterBuilderDate(mk_prefix('End', prefix)),
      Message: new FilterBuilderString(mk_prefix('Message', prefix)),
    Restaurant_PickupClosingTime: new FilterBuilderCollection<Restaurant_PickupClosingTime>(mk_prefix('Restaurant_PickupClosingTime', prefix), s => FilterBuilderRestaurant_PickupClosingTime(s)),
  }
}


const PickupClosingTimeRelationBuilder: RelationBuilder<RelationsOf<PickupClosingTime>> = {
Restaurant_PickupClosingTime: () => mk_rel_query(
    'Restaurant_PickupClosingTime',
    FilterBuilderRestaurant_PickupClosingTime(),
    Restaurant_PickupClosingTimeRelationBuilder,
    Restaurant_PickupClosingTimeParser
  ),

}


const  PickupClosingTimeParser : EntityParser<PickupClosingTime>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Start: parseDate,
      End: parseDate,
      Message: parseNullString,
  Restaurant_PickupClosingTime: p => parseCollection(x => parseEntity(x, p)),
}


const PickupClosingTimeOrderBy : (p: string) => OrderByBuilderComplex<PickupClosingTime> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Start:  new OrderByProp(p ? p + "/Start" : "Start"),
      End:  new OrderByProp(p ? p + "/End" : "End"),
      Message:  new OrderByProp(p ? p + "/Message" : "Message"),
  Restaurant_PickupClosingTime:  p => Restaurant_PickupClosingTimeOrderBy(p ? p + "/Restaurant_PickupClosingTime" : "Restaurant_PickupClosingTime"),
})



export type PickupTimes = {
    Id: number
    CreatedDate: Date
    TuePickup: boolean
    SatPickupStart: Date
    FriPickupEnd: Date
    SatPickup: boolean
    TuePickupStart: Date
    ThuPickupStart: Date
    SunPickup: boolean
    FriPickupStart: Date
    WedPickupEnd: Date
    MondayPickup: boolean
    MondayPickupEnd: Date
    WedPickupStart: Date
    WedPickup: boolean
    SunPickupEnd: Date
    MondayPickupStart: Date
    FriPickup: boolean
    SatPickupEnd: Date
    SunPickupStart: Date
    TuePickupEnd: Date
    ThuPickup: boolean
    ThuPickupEnd: Date
} & {
  Restaurant_PickupTimes: List<Restaurant_PickupTimes>
}


function FilterBuilderPickupTimes(prefix?: string) : FilterBuilderComplex<PickupTimes> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      TuePickup: new FilterBuilderBoolean(mk_prefix('TuePickup', prefix)),
      SatPickupStart: new FilterBuilderDate(mk_prefix('SatPickupStart', prefix)),
      FriPickupEnd: new FilterBuilderDate(mk_prefix('FriPickupEnd', prefix)),
      SatPickup: new FilterBuilderBoolean(mk_prefix('SatPickup', prefix)),
      TuePickupStart: new FilterBuilderDate(mk_prefix('TuePickupStart', prefix)),
      ThuPickupStart: new FilterBuilderDate(mk_prefix('ThuPickupStart', prefix)),
      SunPickup: new FilterBuilderBoolean(mk_prefix('SunPickup', prefix)),
      FriPickupStart: new FilterBuilderDate(mk_prefix('FriPickupStart', prefix)),
      WedPickupEnd: new FilterBuilderDate(mk_prefix('WedPickupEnd', prefix)),
      MondayPickup: new FilterBuilderBoolean(mk_prefix('MondayPickup', prefix)),
      MondayPickupEnd: new FilterBuilderDate(mk_prefix('MondayPickupEnd', prefix)),
      WedPickupStart: new FilterBuilderDate(mk_prefix('WedPickupStart', prefix)),
      WedPickup: new FilterBuilderBoolean(mk_prefix('WedPickup', prefix)),
      SunPickupEnd: new FilterBuilderDate(mk_prefix('SunPickupEnd', prefix)),
      MondayPickupStart: new FilterBuilderDate(mk_prefix('MondayPickupStart', prefix)),
      FriPickup: new FilterBuilderBoolean(mk_prefix('FriPickup', prefix)),
      SatPickupEnd: new FilterBuilderDate(mk_prefix('SatPickupEnd', prefix)),
      SunPickupStart: new FilterBuilderDate(mk_prefix('SunPickupStart', prefix)),
      TuePickupEnd: new FilterBuilderDate(mk_prefix('TuePickupEnd', prefix)),
      ThuPickup: new FilterBuilderBoolean(mk_prefix('ThuPickup', prefix)),
      ThuPickupEnd: new FilterBuilderDate(mk_prefix('ThuPickupEnd', prefix)),
    Restaurant_PickupTimes: new FilterBuilderCollection<Restaurant_PickupTimes>(mk_prefix('Restaurant_PickupTimes', prefix), s => FilterBuilderRestaurant_PickupTimes(s)),
  }
}


const PickupTimesRelationBuilder: RelationBuilder<RelationsOf<PickupTimes>> = {
Restaurant_PickupTimes: () => mk_rel_query(
    'Restaurant_PickupTimes',
    FilterBuilderRestaurant_PickupTimes(),
    Restaurant_PickupTimesRelationBuilder,
    Restaurant_PickupTimesParser
  ),

}


const  PickupTimesParser : EntityParser<PickupTimes>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      TuePickup: parseBoolean,
      SatPickupStart: parseDate,
      FriPickupEnd: parseDate,
      SatPickup: parseBoolean,
      TuePickupStart: parseDate,
      ThuPickupStart: parseDate,
      SunPickup: parseBoolean,
      FriPickupStart: parseDate,
      WedPickupEnd: parseDate,
      MondayPickup: parseBoolean,
      MondayPickupEnd: parseDate,
      WedPickupStart: parseDate,
      WedPickup: parseBoolean,
      SunPickupEnd: parseDate,
      MondayPickupStart: parseDate,
      FriPickup: parseBoolean,
      SatPickupEnd: parseDate,
      SunPickupStart: parseDate,
      TuePickupEnd: parseDate,
      ThuPickup: parseBoolean,
      ThuPickupEnd: parseDate,
  Restaurant_PickupTimes: p => parseCollection(x => parseEntity(x, p)),
}


const PickupTimesOrderBy : (p: string) => OrderByBuilderComplex<PickupTimes> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      TuePickup:  new OrderByProp(p ? p + "/TuePickup" : "TuePickup"),
      SatPickupStart:  new OrderByProp(p ? p + "/SatPickupStart" : "SatPickupStart"),
      FriPickupEnd:  new OrderByProp(p ? p + "/FriPickupEnd" : "FriPickupEnd"),
      SatPickup:  new OrderByProp(p ? p + "/SatPickup" : "SatPickup"),
      TuePickupStart:  new OrderByProp(p ? p + "/TuePickupStart" : "TuePickupStart"),
      ThuPickupStart:  new OrderByProp(p ? p + "/ThuPickupStart" : "ThuPickupStart"),
      SunPickup:  new OrderByProp(p ? p + "/SunPickup" : "SunPickup"),
      FriPickupStart:  new OrderByProp(p ? p + "/FriPickupStart" : "FriPickupStart"),
      WedPickupEnd:  new OrderByProp(p ? p + "/WedPickupEnd" : "WedPickupEnd"),
      MondayPickup:  new OrderByProp(p ? p + "/MondayPickup" : "MondayPickup"),
      MondayPickupEnd:  new OrderByProp(p ? p + "/MondayPickupEnd" : "MondayPickupEnd"),
      WedPickupStart:  new OrderByProp(p ? p + "/WedPickupStart" : "WedPickupStart"),
      WedPickup:  new OrderByProp(p ? p + "/WedPickup" : "WedPickup"),
      SunPickupEnd:  new OrderByProp(p ? p + "/SunPickupEnd" : "SunPickupEnd"),
      MondayPickupStart:  new OrderByProp(p ? p + "/MondayPickupStart" : "MondayPickupStart"),
      FriPickup:  new OrderByProp(p ? p + "/FriPickup" : "FriPickup"),
      SatPickupEnd:  new OrderByProp(p ? p + "/SatPickupEnd" : "SatPickupEnd"),
      SunPickupStart:  new OrderByProp(p ? p + "/SunPickupStart" : "SunPickupStart"),
      TuePickupEnd:  new OrderByProp(p ? p + "/TuePickupEnd" : "TuePickupEnd"),
      ThuPickup:  new OrderByProp(p ? p + "/ThuPickup" : "ThuPickup"),
      ThuPickupEnd:  new OrderByProp(p ? p + "/ThuPickupEnd" : "ThuPickupEnd"),
  Restaurant_PickupTimes:  p => Restaurant_PickupTimesOrderBy(p ? p + "/Restaurant_PickupTimes" : "Restaurant_PickupTimes"),
})



export type PostCodeExclusion = {
    Id: number
    CreatedDate: Date
    PostCodeStart: string | null
    PostCodeEnd: string | null
} & {
  PostCodeRange_PostCodeExclusion: List<PostCodeRange_PostCodeExclusion>
}


function FilterBuilderPostCodeExclusion(prefix?: string) : FilterBuilderComplex<PostCodeExclusion> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      PostCodeStart: new FilterBuilderString(mk_prefix('PostCodeStart', prefix)),
      PostCodeEnd: new FilterBuilderString(mk_prefix('PostCodeEnd', prefix)),
    PostCodeRange_PostCodeExclusion: new FilterBuilderCollection<PostCodeRange_PostCodeExclusion>(mk_prefix('PostCodeRange_PostCodeExclusion', prefix), s => FilterBuilderPostCodeRange_PostCodeExclusion(s)),
  }
}


const PostCodeExclusionRelationBuilder: RelationBuilder<RelationsOf<PostCodeExclusion>> = {
PostCodeRange_PostCodeExclusion: () => mk_rel_query(
    'PostCodeRange_PostCodeExclusion',
    FilterBuilderPostCodeRange_PostCodeExclusion(),
    PostCodeRange_PostCodeExclusionRelationBuilder,
    PostCodeRange_PostCodeExclusionParser
  ),

}


const  PostCodeExclusionParser : EntityParser<PostCodeExclusion>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      PostCodeStart: parseNullString,
      PostCodeEnd: parseNullString,
  PostCodeRange_PostCodeExclusion: p => parseCollection(x => parseEntity(x, p)),
}


const PostCodeExclusionOrderBy : (p: string) => OrderByBuilderComplex<PostCodeExclusion> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      PostCodeStart:  new OrderByProp(p ? p + "/PostCodeStart" : "PostCodeStart"),
      PostCodeEnd:  new OrderByProp(p ? p + "/PostCodeEnd" : "PostCodeEnd"),
  PostCodeRange_PostCodeExclusion:  p => PostCodeRange_PostCodeExclusionOrderBy(p ? p + "/PostCodeRange_PostCodeExclusion" : "PostCodeRange_PostCodeExclusion"),
})



export type PostCodeRange = {
    Id: number
    CreatedDate: Date
    Active: boolean
    PostCodeStart: string | null
    PostCodeEnd: string | null
    DeliveryCost: number | null
    DeliveryMinimumCartValue: number | null
    DeliveryFreeAfterValue: number | null
} & {
  PostCodeRange_PostCodeExclusion: List<PostCodeRange_PostCodeExclusion>
    Restaurant_PostCodeRange: List<Restaurant_PostCodeRange>
}


function FilterBuilderPostCodeRange(prefix?: string) : FilterBuilderComplex<PostCodeRange> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Active: new FilterBuilderBoolean(mk_prefix('Active', prefix)),
      PostCodeStart: new FilterBuilderString(mk_prefix('PostCodeStart', prefix)),
      PostCodeEnd: new FilterBuilderString(mk_prefix('PostCodeEnd', prefix)),
      DeliveryCost: new FilterBuilderNumber(mk_prefix('DeliveryCost', prefix)),
      DeliveryMinimumCartValue: new FilterBuilderNumber(mk_prefix('DeliveryMinimumCartValue', prefix)),
      DeliveryFreeAfterValue: new FilterBuilderNumber(mk_prefix('DeliveryFreeAfterValue', prefix)),
    PostCodeRange_PostCodeExclusion: new FilterBuilderCollection<PostCodeRange_PostCodeExclusion>(mk_prefix('PostCodeRange_PostCodeExclusion', prefix), s => FilterBuilderPostCodeRange_PostCodeExclusion(s)),
      Restaurant_PostCodeRange: new FilterBuilderCollection<Restaurant_PostCodeRange>(mk_prefix('Restaurant_PostCodeRange', prefix), s => FilterBuilderRestaurant_PostCodeRange(s)),
  }
}


const PostCodeRangeRelationBuilder: RelationBuilder<RelationsOf<PostCodeRange>> = {
PostCodeRange_PostCodeExclusion: () => mk_rel_query(
    'PostCodeRange_PostCodeExclusion',
    FilterBuilderPostCodeRange_PostCodeExclusion(),
    PostCodeRange_PostCodeExclusionRelationBuilder,
    PostCodeRange_PostCodeExclusionParser
  ),

Restaurant_PostCodeRange: () => mk_rel_query(
    'Restaurant_PostCodeRange',
    FilterBuilderRestaurant_PostCodeRange(),
    Restaurant_PostCodeRangeRelationBuilder,
    Restaurant_PostCodeRangeParser
  ),

}


const  PostCodeRangeParser : EntityParser<PostCodeRange>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Active: parseBoolean,
      PostCodeStart: parseNullString,
      PostCodeEnd: parseNullString,
      DeliveryCost: parseNullNumber,
      DeliveryMinimumCartValue: parseNullNumber,
      DeliveryFreeAfterValue: parseNullNumber,
  PostCodeRange_PostCodeExclusion: p => parseCollection(x => parseEntity(x, p)),
      Restaurant_PostCodeRange: p => parseCollection(x => parseEntity(x, p)),
}


const PostCodeRangeOrderBy : (p: string) => OrderByBuilderComplex<PostCodeRange> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Active:  new OrderByProp(p ? p + "/Active" : "Active"),
      PostCodeStart:  new OrderByProp(p ? p + "/PostCodeStart" : "PostCodeStart"),
      PostCodeEnd:  new OrderByProp(p ? p + "/PostCodeEnd" : "PostCodeEnd"),
      DeliveryCost:  new OrderByProp(p ? p + "/DeliveryCost" : "DeliveryCost"),
      DeliveryMinimumCartValue:  new OrderByProp(p ? p + "/DeliveryMinimumCartValue" : "DeliveryMinimumCartValue"),
      DeliveryFreeAfterValue:  new OrderByProp(p ? p + "/DeliveryFreeAfterValue" : "DeliveryFreeAfterValue"),
  PostCodeRange_PostCodeExclusion:  p => PostCodeRange_PostCodeExclusionOrderBy(p ? p + "/PostCodeRange_PostCodeExclusion" : "PostCodeRange_PostCodeExclusion"),
      Restaurant_PostCodeRange:  p => Restaurant_PostCodeRangeOrderBy(p ? p + "/Restaurant_PostCodeRange" : "Restaurant_PostCodeRange"),
})



export type PostCodeRange_PostCodeExclusion = {
    Id: number
    PostCodeRangeId: number
    PostCodeExclusionId: number
} & {
  PostCodeExclusion: List<PostCodeExclusion>
    PostCodeRange: List<PostCodeRange>
}


function FilterBuilderPostCodeRange_PostCodeExclusion(prefix?: string) : FilterBuilderComplex<PostCodeRange_PostCodeExclusion> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      PostCodeRangeId: new FilterBuilderNumber(mk_prefix('PostCodeRangeId', prefix)),
      PostCodeExclusionId: new FilterBuilderNumber(mk_prefix('PostCodeExclusionId', prefix)),
    PostCodeExclusion: new FilterBuilderCollection<PostCodeExclusion>(mk_prefix('PostCodeExclusion', prefix), s => FilterBuilderPostCodeExclusion(s)),
      PostCodeRange: new FilterBuilderCollection<PostCodeRange>(mk_prefix('PostCodeRange', prefix), s => FilterBuilderPostCodeRange(s)),
  }
}


const PostCodeRange_PostCodeExclusionRelationBuilder: RelationBuilder<RelationsOf<PostCodeRange_PostCodeExclusion>> = {
PostCodeExclusion: () => mk_rel_query(
    'PostCodeExclusion',
    FilterBuilderPostCodeExclusion(),
    PostCodeExclusionRelationBuilder,
    PostCodeExclusionParser
  ),

PostCodeRange: () => mk_rel_query(
    'PostCodeRange',
    FilterBuilderPostCodeRange(),
    PostCodeRangeRelationBuilder,
    PostCodeRangeParser
  ),

}


const  PostCodeRange_PostCodeExclusionParser : EntityParser<PostCodeRange_PostCodeExclusion>= {
  Id: parseNumber,
      PostCodeRangeId: parseNumber,
      PostCodeExclusionId: parseNumber,
  PostCodeExclusion: p => parseCollection(x => parseEntity(x, p)),
      PostCodeRange: p => parseCollection(x => parseEntity(x, p)),
}


const PostCodeRange_PostCodeExclusionOrderBy : (p: string) => OrderByBuilderComplex<PostCodeRange_PostCodeExclusion> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      PostCodeRangeId:  new OrderByProp(p ? p + "/PostCodeRangeId" : "PostCodeRangeId"),
      PostCodeExclusionId:  new OrderByProp(p ? p + "/PostCodeExclusionId" : "PostCodeExclusionId"),
  PostCodeExclusion:  p => PostCodeExclusionOrderBy(p ? p + "/PostCodeExclusion" : "PostCodeExclusion"),
      PostCodeRange:  p => PostCodeRangeOrderBy(p ? p + "/PostCodeRange" : "PostCodeRange"),
})



export type Product = {
    Id: number
    CreatedDate: Date
    POS4: string | null
    Vegan: boolean
    ImageBig: string | null
    Active: boolean
    InternalName: string | null
    Price: number
    Name: string | null
    ImageSmall: string | null
    Upsell: boolean
    AlergyInfo: string | null
    Description: string | null
    Vegetarian: boolean
} & {
  OrderItemProduct_Product: List<OrderItemProduct_Product>
    Product_Discount: List<Product_Discount>
    Product_ImageBigData: List<Product_ImageBigData>
    Product_ImageSmallData: List<Product_ImageSmallData>
    Product_OptionGroupPosition: List<Product_OptionGroupPosition>
    Product_ProductPosition: List<Product_ProductPosition>
    Product_Restaurant: List<Product_Restaurant>
}


function FilterBuilderProduct(prefix?: string) : FilterBuilderComplex<Product> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      POS4: new FilterBuilderString(mk_prefix('POS4', prefix)),
      Vegan: new FilterBuilderBoolean(mk_prefix('Vegan', prefix)),
      ImageBig: new FilterBuilderString(mk_prefix('ImageBig', prefix)),
      Active: new FilterBuilderBoolean(mk_prefix('Active', prefix)),
      InternalName: new FilterBuilderString(mk_prefix('InternalName', prefix)),
      Price: new FilterBuilderNumber(mk_prefix('Price', prefix)),
      Name: new FilterBuilderString(mk_prefix('Name', prefix)),
      ImageSmall: new FilterBuilderString(mk_prefix('ImageSmall', prefix)),
      Upsell: new FilterBuilderBoolean(mk_prefix('Upsell', prefix)),
      AlergyInfo: new FilterBuilderString(mk_prefix('AlergyInfo', prefix)),
      Description: new FilterBuilderString(mk_prefix('Description', prefix)),
      Vegetarian: new FilterBuilderBoolean(mk_prefix('Vegetarian', prefix)),
    OrderItemProduct_Product: new FilterBuilderCollection<OrderItemProduct_Product>(mk_prefix('OrderItemProduct_Product', prefix), s => FilterBuilderOrderItemProduct_Product(s)),
      Product_Discount: new FilterBuilderCollection<Product_Discount>(mk_prefix('Product_Discount', prefix), s => FilterBuilderProduct_Discount(s)),
      Product_ImageBigData: new FilterBuilderCollection<Product_ImageBigData>(mk_prefix('Product_ImageBigData', prefix), s => FilterBuilderProduct_ImageBigData(s)),
      Product_ImageSmallData: new FilterBuilderCollection<Product_ImageSmallData>(mk_prefix('Product_ImageSmallData', prefix), s => FilterBuilderProduct_ImageSmallData(s)),
      Product_OptionGroupPosition: new FilterBuilderCollection<Product_OptionGroupPosition>(mk_prefix('Product_OptionGroupPosition', prefix), s => FilterBuilderProduct_OptionGroupPosition(s)),
      Product_ProductPosition: new FilterBuilderCollection<Product_ProductPosition>(mk_prefix('Product_ProductPosition', prefix), s => FilterBuilderProduct_ProductPosition(s)),
      Product_Restaurant: new FilterBuilderCollection<Product_Restaurant>(mk_prefix('Product_Restaurant', prefix), s => FilterBuilderProduct_Restaurant(s)),
  }
}


const ProductRelationBuilder: RelationBuilder<RelationsOf<Product>> = {
OrderItemProduct_Product: () => mk_rel_query(
    'OrderItemProduct_Product',
    FilterBuilderOrderItemProduct_Product(),
    OrderItemProduct_ProductRelationBuilder,
    OrderItemProduct_ProductParser
  ),

Product_Discount: () => mk_rel_query(
    'Product_Discount',
    FilterBuilderProduct_Discount(),
    Product_DiscountRelationBuilder,
    Product_DiscountParser
  ),

Product_ImageBigData: () => mk_rel_query(
    'Product_ImageBigData',
    FilterBuilderProduct_ImageBigData(),
    Product_ImageBigDataRelationBuilder,
    Product_ImageBigDataParser
  ),

Product_ImageSmallData: () => mk_rel_query(
    'Product_ImageSmallData',
    FilterBuilderProduct_ImageSmallData(),
    Product_ImageSmallDataRelationBuilder,
    Product_ImageSmallDataParser
  ),

Product_OptionGroupPosition: () => mk_rel_query(
    'Product_OptionGroupPosition',
    FilterBuilderProduct_OptionGroupPosition(),
    Product_OptionGroupPositionRelationBuilder,
    Product_OptionGroupPositionParser
  ),

Product_ProductPosition: () => mk_rel_query(
    'Product_ProductPosition',
    FilterBuilderProduct_ProductPosition(),
    Product_ProductPositionRelationBuilder,
    Product_ProductPositionParser
  ),

Product_Restaurant: () => mk_rel_query(
    'Product_Restaurant',
    FilterBuilderProduct_Restaurant(),
    Product_RestaurantRelationBuilder,
    Product_RestaurantParser
  ),

}


const  ProductParser : EntityParser<Product>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      POS4: parseNullString,
      Vegan: parseBoolean,
      ImageBig: parseNullString,
      Active: parseBoolean,
      InternalName: parseNullString,
      Price: parseNumber,
      Name: parseNullString,
      ImageSmall: parseNullString,
      Upsell: parseBoolean,
      AlergyInfo: parseNullString,
      Description: parseNullString,
      Vegetarian: parseBoolean,
  OrderItemProduct_Product: p => parseCollection(x => parseEntity(x, p)),
      Product_Discount: p => parseCollection(x => parseEntity(x, p)),
      Product_ImageBigData: p => parseCollection(x => parseEntity(x, p)),
      Product_ImageSmallData: p => parseCollection(x => parseEntity(x, p)),
      Product_OptionGroupPosition: p => parseCollection(x => parseEntity(x, p)),
      Product_ProductPosition: p => parseCollection(x => parseEntity(x, p)),
      Product_Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const ProductOrderBy : (p: string) => OrderByBuilderComplex<Product> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      POS4:  new OrderByProp(p ? p + "/POS4" : "POS4"),
      Vegan:  new OrderByProp(p ? p + "/Vegan" : "Vegan"),
      ImageBig:  new OrderByProp(p ? p + "/ImageBig" : "ImageBig"),
      Active:  new OrderByProp(p ? p + "/Active" : "Active"),
      InternalName:  new OrderByProp(p ? p + "/InternalName" : "InternalName"),
      Price:  new OrderByProp(p ? p + "/Price" : "Price"),
      Name:  new OrderByProp(p ? p + "/Name" : "Name"),
      ImageSmall:  new OrderByProp(p ? p + "/ImageSmall" : "ImageSmall"),
      Upsell:  new OrderByProp(p ? p + "/Upsell" : "Upsell"),
      AlergyInfo:  new OrderByProp(p ? p + "/AlergyInfo" : "AlergyInfo"),
      Description:  new OrderByProp(p ? p + "/Description" : "Description"),
      Vegetarian:  new OrderByProp(p ? p + "/Vegetarian" : "Vegetarian"),
  OrderItemProduct_Product:  p => OrderItemProduct_ProductOrderBy(p ? p + "/OrderItemProduct_Product" : "OrderItemProduct_Product"),
      Product_Discount:  p => Product_DiscountOrderBy(p ? p + "/Product_Discount" : "Product_Discount"),
      Product_ImageBigData:  p => Product_ImageBigDataOrderBy(p ? p + "/Product_ImageBigData" : "Product_ImageBigData"),
      Product_ImageSmallData:  p => Product_ImageSmallDataOrderBy(p ? p + "/Product_ImageSmallData" : "Product_ImageSmallData"),
      Product_OptionGroupPosition:  p => Product_OptionGroupPositionOrderBy(p ? p + "/Product_OptionGroupPosition" : "Product_OptionGroupPosition"),
      Product_ProductPosition:  p => Product_ProductPositionOrderBy(p ? p + "/Product_ProductPosition" : "Product_ProductPosition"),
      Product_Restaurant:  p => Product_RestaurantOrderBy(p ? p + "/Product_Restaurant" : "Product_Restaurant"),
})



export type ProductPosition = {
    Id: number
    CreatedDate: Date
    Position: number
} & {
  Category_ProductPosition: List<Category_ProductPosition>
    Product_ProductPosition: List<Product_ProductPosition>
}


function FilterBuilderProductPosition(prefix?: string) : FilterBuilderComplex<ProductPosition> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Position: new FilterBuilderNumber(mk_prefix('Position', prefix)),
    Category_ProductPosition: new FilterBuilderCollection<Category_ProductPosition>(mk_prefix('Category_ProductPosition', prefix), s => FilterBuilderCategory_ProductPosition(s)),
      Product_ProductPosition: new FilterBuilderCollection<Product_ProductPosition>(mk_prefix('Product_ProductPosition', prefix), s => FilterBuilderProduct_ProductPosition(s)),
  }
}


const ProductPositionRelationBuilder: RelationBuilder<RelationsOf<ProductPosition>> = {
Category_ProductPosition: () => mk_rel_query(
    'Category_ProductPosition',
    FilterBuilderCategory_ProductPosition(),
    Category_ProductPositionRelationBuilder,
    Category_ProductPositionParser
  ),

Product_ProductPosition: () => mk_rel_query(
    'Product_ProductPosition',
    FilterBuilderProduct_ProductPosition(),
    Product_ProductPositionRelationBuilder,
    Product_ProductPositionParser
  ),

}


const  ProductPositionParser : EntityParser<ProductPosition>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Position: parseNumber,
  Category_ProductPosition: p => parseCollection(x => parseEntity(x, p)),
      Product_ProductPosition: p => parseCollection(x => parseEntity(x, p)),
}


const ProductPositionOrderBy : (p: string) => OrderByBuilderComplex<ProductPosition> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Position:  new OrderByProp(p ? p + "/Position" : "Position"),
  Category_ProductPosition:  p => Category_ProductPositionOrderBy(p ? p + "/Category_ProductPosition" : "Category_ProductPosition"),
      Product_ProductPosition:  p => Product_ProductPositionOrderBy(p ? p + "/Product_ProductPosition" : "Product_ProductPosition"),
})



export type Product_Discount = {
    Id: number
    ProductId: number
    DiscountId: number
} & {
  Discount: List<Discount>
    Product: List<Product>
}


function FilterBuilderProduct_Discount(prefix?: string) : FilterBuilderComplex<Product_Discount> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      ProductId: new FilterBuilderNumber(mk_prefix('ProductId', prefix)),
      DiscountId: new FilterBuilderNumber(mk_prefix('DiscountId', prefix)),
    Discount: new FilterBuilderCollection<Discount>(mk_prefix('Discount', prefix), s => FilterBuilderDiscount(s)),
      Product: new FilterBuilderCollection<Product>(mk_prefix('Product', prefix), s => FilterBuilderProduct(s)),
  }
}


const Product_DiscountRelationBuilder: RelationBuilder<RelationsOf<Product_Discount>> = {
Discount: () => mk_rel_query(
    'Discount',
    FilterBuilderDiscount(),
    DiscountRelationBuilder,
    DiscountParser
  ),

Product: () => mk_rel_query(
    'Product',
    FilterBuilderProduct(),
    ProductRelationBuilder,
    ProductParser
  ),

}


const  Product_DiscountParser : EntityParser<Product_Discount>= {
  Id: parseNumber,
      ProductId: parseNumber,
      DiscountId: parseNumber,
  Discount: p => parseCollection(x => parseEntity(x, p)),
      Product: p => parseCollection(x => parseEntity(x, p)),
}


const Product_DiscountOrderBy : (p: string) => OrderByBuilderComplex<Product_Discount> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      ProductId:  new OrderByProp(p ? p + "/ProductId" : "ProductId"),
      DiscountId:  new OrderByProp(p ? p + "/DiscountId" : "DiscountId"),
  Discount:  p => DiscountOrderBy(p ? p + "/Discount" : "Discount"),
      Product:  p => ProductOrderBy(p ? p + "/Product" : "Product"),
})



export type Product_ImageBigData = {
    Id: number
    Content: string | null
    FileName: string | null
    ContentType: string | null
    ProductId: number
} & {
  Product: List<Product>
}


function FilterBuilderProduct_ImageBigData(prefix?: string) : FilterBuilderComplex<Product_ImageBigData> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      Content: new FilterBuilderString(mk_prefix('Content', prefix)),
      FileName: new FilterBuilderString(mk_prefix('FileName', prefix)),
      ContentType: new FilterBuilderString(mk_prefix('ContentType', prefix)),
      ProductId: new FilterBuilderNumber(mk_prefix('ProductId', prefix)),
    Product: new FilterBuilderCollection<Product>(mk_prefix('Product', prefix), s => FilterBuilderProduct(s)),
  }
}


const Product_ImageBigDataRelationBuilder: RelationBuilder<RelationsOf<Product_ImageBigData>> = {
Product: () => mk_rel_query(
    'Product',
    FilterBuilderProduct(),
    ProductRelationBuilder,
    ProductParser
  ),

}


const  Product_ImageBigDataParser : EntityParser<Product_ImageBigData>= {
  Id: parseNumber,
      Content: parseNullString,
      FileName: parseNullString,
      ContentType: parseNullString,
      ProductId: parseNumber,
  Product: p => parseCollection(x => parseEntity(x, p)),
}


const Product_ImageBigDataOrderBy : (p: string) => OrderByBuilderComplex<Product_ImageBigData> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      Content:  new OrderByProp(p ? p + "/Content" : "Content"),
      FileName:  new OrderByProp(p ? p + "/FileName" : "FileName"),
      ContentType:  new OrderByProp(p ? p + "/ContentType" : "ContentType"),
      ProductId:  new OrderByProp(p ? p + "/ProductId" : "ProductId"),
  Product:  p => ProductOrderBy(p ? p + "/Product" : "Product"),
})



export type Product_ImageSmallData = {
    Id: number
    Content: string | null
    FileName: string | null
    ContentType: string | null
    ProductId: number
} & {
  Product: List<Product>
}


function FilterBuilderProduct_ImageSmallData(prefix?: string) : FilterBuilderComplex<Product_ImageSmallData> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      Content: new FilterBuilderString(mk_prefix('Content', prefix)),
      FileName: new FilterBuilderString(mk_prefix('FileName', prefix)),
      ContentType: new FilterBuilderString(mk_prefix('ContentType', prefix)),
      ProductId: new FilterBuilderNumber(mk_prefix('ProductId', prefix)),
    Product: new FilterBuilderCollection<Product>(mk_prefix('Product', prefix), s => FilterBuilderProduct(s)),
  }
}


const Product_ImageSmallDataRelationBuilder: RelationBuilder<RelationsOf<Product_ImageSmallData>> = {
Product: () => mk_rel_query(
    'Product',
    FilterBuilderProduct(),
    ProductRelationBuilder,
    ProductParser
  ),

}


const  Product_ImageSmallDataParser : EntityParser<Product_ImageSmallData>= {
  Id: parseNumber,
      Content: parseNullString,
      FileName: parseNullString,
      ContentType: parseNullString,
      ProductId: parseNumber,
  Product: p => parseCollection(x => parseEntity(x, p)),
}


const Product_ImageSmallDataOrderBy : (p: string) => OrderByBuilderComplex<Product_ImageSmallData> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      Content:  new OrderByProp(p ? p + "/Content" : "Content"),
      FileName:  new OrderByProp(p ? p + "/FileName" : "FileName"),
      ContentType:  new OrderByProp(p ? p + "/ContentType" : "ContentType"),
      ProductId:  new OrderByProp(p ? p + "/ProductId" : "ProductId"),
  Product:  p => ProductOrderBy(p ? p + "/Product" : "Product"),
})



export type Product_OptionGroupPosition = {
    Id: number
    ProductId: number
    OptionGroupPositionId: number
} & {
  OptionGroupPosition: List<OptionGroupPosition>
    Product: List<Product>
}


function FilterBuilderProduct_OptionGroupPosition(prefix?: string) : FilterBuilderComplex<Product_OptionGroupPosition> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      ProductId: new FilterBuilderNumber(mk_prefix('ProductId', prefix)),
      OptionGroupPositionId: new FilterBuilderNumber(mk_prefix('OptionGroupPositionId', prefix)),
    OptionGroupPosition: new FilterBuilderCollection<OptionGroupPosition>(mk_prefix('OptionGroupPosition', prefix), s => FilterBuilderOptionGroupPosition(s)),
      Product: new FilterBuilderCollection<Product>(mk_prefix('Product', prefix), s => FilterBuilderProduct(s)),
  }
}


const Product_OptionGroupPositionRelationBuilder: RelationBuilder<RelationsOf<Product_OptionGroupPosition>> = {
OptionGroupPosition: () => mk_rel_query(
    'OptionGroupPosition',
    FilterBuilderOptionGroupPosition(),
    OptionGroupPositionRelationBuilder,
    OptionGroupPositionParser
  ),

Product: () => mk_rel_query(
    'Product',
    FilterBuilderProduct(),
    ProductRelationBuilder,
    ProductParser
  ),

}


const  Product_OptionGroupPositionParser : EntityParser<Product_OptionGroupPosition>= {
  Id: parseNumber,
      ProductId: parseNumber,
      OptionGroupPositionId: parseNumber,
  OptionGroupPosition: p => parseCollection(x => parseEntity(x, p)),
      Product: p => parseCollection(x => parseEntity(x, p)),
}


const Product_OptionGroupPositionOrderBy : (p: string) => OrderByBuilderComplex<Product_OptionGroupPosition> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      ProductId:  new OrderByProp(p ? p + "/ProductId" : "ProductId"),
      OptionGroupPositionId:  new OrderByProp(p ? p + "/OptionGroupPositionId" : "OptionGroupPositionId"),
  OptionGroupPosition:  p => OptionGroupPositionOrderBy(p ? p + "/OptionGroupPosition" : "OptionGroupPosition"),
      Product:  p => ProductOrderBy(p ? p + "/Product" : "Product"),
})



export type Product_ProductPosition = {
    Id: number
    ProductId: number
    ProductPositionId: number
} & {
  Product: List<Product>
    ProductPosition: List<ProductPosition>
}


function FilterBuilderProduct_ProductPosition(prefix?: string) : FilterBuilderComplex<Product_ProductPosition> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      ProductId: new FilterBuilderNumber(mk_prefix('ProductId', prefix)),
      ProductPositionId: new FilterBuilderNumber(mk_prefix('ProductPositionId', prefix)),
    Product: new FilterBuilderCollection<Product>(mk_prefix('Product', prefix), s => FilterBuilderProduct(s)),
      ProductPosition: new FilterBuilderCollection<ProductPosition>(mk_prefix('ProductPosition', prefix), s => FilterBuilderProductPosition(s)),
  }
}


const Product_ProductPositionRelationBuilder: RelationBuilder<RelationsOf<Product_ProductPosition>> = {
Product: () => mk_rel_query(
    'Product',
    FilterBuilderProduct(),
    ProductRelationBuilder,
    ProductParser
  ),

ProductPosition: () => mk_rel_query(
    'ProductPosition',
    FilterBuilderProductPosition(),
    ProductPositionRelationBuilder,
    ProductPositionParser
  ),

}


const  Product_ProductPositionParser : EntityParser<Product_ProductPosition>= {
  Id: parseNumber,
      ProductId: parseNumber,
      ProductPositionId: parseNumber,
  Product: p => parseCollection(x => parseEntity(x, p)),
      ProductPosition: p => parseCollection(x => parseEntity(x, p)),
}


const Product_ProductPositionOrderBy : (p: string) => OrderByBuilderComplex<Product_ProductPosition> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      ProductId:  new OrderByProp(p ? p + "/ProductId" : "ProductId"),
      ProductPositionId:  new OrderByProp(p ? p + "/ProductPositionId" : "ProductPositionId"),
  Product:  p => ProductOrderBy(p ? p + "/Product" : "Product"),
      ProductPosition:  p => ProductPositionOrderBy(p ? p + "/ProductPosition" : "ProductPosition"),
})



export type Product_Restaurant = {
    Id: number
    ProductId: number
    RestaurantId: number
} & {
  Product: List<Product>
    Restaurant: List<Restaurant>
}


function FilterBuilderProduct_Restaurant(prefix?: string) : FilterBuilderComplex<Product_Restaurant> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      ProductId: new FilterBuilderNumber(mk_prefix('ProductId', prefix)),
      RestaurantId: new FilterBuilderNumber(mk_prefix('RestaurantId', prefix)),
    Product: new FilterBuilderCollection<Product>(mk_prefix('Product', prefix), s => FilterBuilderProduct(s)),
      Restaurant: new FilterBuilderCollection<Restaurant>(mk_prefix('Restaurant', prefix), s => FilterBuilderRestaurant(s)),
  }
}


const Product_RestaurantRelationBuilder: RelationBuilder<RelationsOf<Product_Restaurant>> = {
Product: () => mk_rel_query(
    'Product',
    FilterBuilderProduct(),
    ProductRelationBuilder,
    ProductParser
  ),

Restaurant: () => mk_rel_query(
    'Restaurant',
    FilterBuilderRestaurant(),
    RestaurantRelationBuilder,
    RestaurantParser
  ),

}


const  Product_RestaurantParser : EntityParser<Product_Restaurant>= {
  Id: parseNumber,
      ProductId: parseNumber,
      RestaurantId: parseNumber,
  Product: p => parseCollection(x => parseEntity(x, p)),
      Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const Product_RestaurantOrderBy : (p: string) => OrderByBuilderComplex<Product_Restaurant> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      ProductId:  new OrderByProp(p ? p + "/ProductId" : "ProductId"),
      RestaurantId:  new OrderByProp(p ? p + "/RestaurantId" : "RestaurantId"),
  Product:  p => ProductOrderBy(p ? p + "/Product" : "Product"),
      Restaurant:  p => RestaurantOrderBy(p ? p + "/Restaurant" : "Restaurant"),
})



export type Restaurant = {
    Id: number
    CreatedDate: Date
    DeliveryCost: number
    Active: boolean
    City: string | null
    Name: string | null
    POS4Code: number
    PostCode: string | null
    Delivery: boolean
    HasNewFlow: boolean
    Address: string | null
    DeliveryFreeAfterValue: number
    DeliveryMinimumCartValue: number
    PhoneNumber: string | null
    EmailAddress: string | null
    Pickup: boolean
} & {
  Category_Restaurant: List<Category_Restaurant>
    DeliveryRestaurant_DeliveryPayMethod: List<DeliveryRestaurant_DeliveryPayMethod>
    FranchiseManager_Restaurant: List<FranchiseManager_Restaurant>
    Option_Restaurant: List<Option_Restaurant>
    Order_Restaurant: List<Order_Restaurant>
    PickUpRestaurant_PickUpPayMethod: List<PickUpRestaurant_PickUpPayMethod>
    Product_Restaurant: List<Product_Restaurant>
    Restaurant_Company: List<Restaurant_Company>
    Restaurant_DeliveryClosingTime: List<Restaurant_DeliveryClosingTime>
    Restaurant_DeliveryTimes: List<Restaurant_DeliveryTimes>
    Restaurant_Discount: List<Restaurant_Discount>
    Restaurant_MollieKeys: List<Restaurant_MollieKeys>
    Restaurant_POS4: List<Restaurant_POS4>
    Restaurant_PickupClosingTime: List<Restaurant_PickupClosingTime>
    Restaurant_PickupTimes: List<Restaurant_PickupTimes>
    Restaurant_PostCodeRange: List<Restaurant_PostCodeRange>
    Restaurant_WaitingTimes: List<Restaurant_WaitingTimes>
}


function FilterBuilderRestaurant(prefix?: string) : FilterBuilderComplex<Restaurant> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      DeliveryCost: new FilterBuilderNumber(mk_prefix('DeliveryCost', prefix)),
      Active: new FilterBuilderBoolean(mk_prefix('Active', prefix)),
      City: new FilterBuilderString(mk_prefix('City', prefix)),
      Name: new FilterBuilderString(mk_prefix('Name', prefix)),
      POS4Code: new FilterBuilderNumber(mk_prefix('POS4Code', prefix)),
      PostCode: new FilterBuilderString(mk_prefix('PostCode', prefix)),
      Delivery: new FilterBuilderBoolean(mk_prefix('Delivery', prefix)),
      HasNewFlow: new FilterBuilderBoolean(mk_prefix('HasNewFlow', prefix)),
      Address: new FilterBuilderString(mk_prefix('Address', prefix)),
      DeliveryFreeAfterValue: new FilterBuilderNumber(mk_prefix('DeliveryFreeAfterValue', prefix)),
      DeliveryMinimumCartValue: new FilterBuilderNumber(mk_prefix('DeliveryMinimumCartValue', prefix)),
      PhoneNumber: new FilterBuilderString(mk_prefix('PhoneNumber', prefix)),
      EmailAddress: new FilterBuilderString(mk_prefix('EmailAddress', prefix)),
      Pickup: new FilterBuilderBoolean(mk_prefix('Pickup', prefix)),
    Category_Restaurant: new FilterBuilderCollection<Category_Restaurant>(mk_prefix('Category_Restaurant', prefix), s => FilterBuilderCategory_Restaurant(s)),
      DeliveryRestaurant_DeliveryPayMethod: new FilterBuilderCollection<DeliveryRestaurant_DeliveryPayMethod>(mk_prefix('DeliveryRestaurant_DeliveryPayMethod', prefix), s => FilterBuilderDeliveryRestaurant_DeliveryPayMethod(s)),
      FranchiseManager_Restaurant: new FilterBuilderCollection<FranchiseManager_Restaurant>(mk_prefix('FranchiseManager_Restaurant', prefix), s => FilterBuilderFranchiseManager_Restaurant(s)),
      Option_Restaurant: new FilterBuilderCollection<Option_Restaurant>(mk_prefix('Option_Restaurant', prefix), s => FilterBuilderOption_Restaurant(s)),
      Order_Restaurant: new FilterBuilderCollection<Order_Restaurant>(mk_prefix('Order_Restaurant', prefix), s => FilterBuilderOrder_Restaurant(s)),
      PickUpRestaurant_PickUpPayMethod: new FilterBuilderCollection<PickUpRestaurant_PickUpPayMethod>(mk_prefix('PickUpRestaurant_PickUpPayMethod', prefix), s => FilterBuilderPickUpRestaurant_PickUpPayMethod(s)),
      Product_Restaurant: new FilterBuilderCollection<Product_Restaurant>(mk_prefix('Product_Restaurant', prefix), s => FilterBuilderProduct_Restaurant(s)),
      Restaurant_Company: new FilterBuilderCollection<Restaurant_Company>(mk_prefix('Restaurant_Company', prefix), s => FilterBuilderRestaurant_Company(s)),
      Restaurant_DeliveryClosingTime: new FilterBuilderCollection<Restaurant_DeliveryClosingTime>(mk_prefix('Restaurant_DeliveryClosingTime', prefix), s => FilterBuilderRestaurant_DeliveryClosingTime(s)),
      Restaurant_DeliveryTimes: new FilterBuilderCollection<Restaurant_DeliveryTimes>(mk_prefix('Restaurant_DeliveryTimes', prefix), s => FilterBuilderRestaurant_DeliveryTimes(s)),
      Restaurant_Discount: new FilterBuilderCollection<Restaurant_Discount>(mk_prefix('Restaurant_Discount', prefix), s => FilterBuilderRestaurant_Discount(s)),
      Restaurant_MollieKeys: new FilterBuilderCollection<Restaurant_MollieKeys>(mk_prefix('Restaurant_MollieKeys', prefix), s => FilterBuilderRestaurant_MollieKeys(s)),
      Restaurant_POS4: new FilterBuilderCollection<Restaurant_POS4>(mk_prefix('Restaurant_POS4', prefix), s => FilterBuilderRestaurant_POS4(s)),
      Restaurant_PickupClosingTime: new FilterBuilderCollection<Restaurant_PickupClosingTime>(mk_prefix('Restaurant_PickupClosingTime', prefix), s => FilterBuilderRestaurant_PickupClosingTime(s)),
      Restaurant_PickupTimes: new FilterBuilderCollection<Restaurant_PickupTimes>(mk_prefix('Restaurant_PickupTimes', prefix), s => FilterBuilderRestaurant_PickupTimes(s)),
      Restaurant_PostCodeRange: new FilterBuilderCollection<Restaurant_PostCodeRange>(mk_prefix('Restaurant_PostCodeRange', prefix), s => FilterBuilderRestaurant_PostCodeRange(s)),
      Restaurant_WaitingTimes: new FilterBuilderCollection<Restaurant_WaitingTimes>(mk_prefix('Restaurant_WaitingTimes', prefix), s => FilterBuilderRestaurant_WaitingTimes(s)),
  }
}


const RestaurantRelationBuilder: RelationBuilder<RelationsOf<Restaurant>> = {
Category_Restaurant: () => mk_rel_query(
    'Category_Restaurant',
    FilterBuilderCategory_Restaurant(),
    Category_RestaurantRelationBuilder,
    Category_RestaurantParser
  ),

DeliveryRestaurant_DeliveryPayMethod: () => mk_rel_query(
    'DeliveryRestaurant_DeliveryPayMethod',
    FilterBuilderDeliveryRestaurant_DeliveryPayMethod(),
    DeliveryRestaurant_DeliveryPayMethodRelationBuilder,
    DeliveryRestaurant_DeliveryPayMethodParser
  ),

FranchiseManager_Restaurant: () => mk_rel_query(
    'FranchiseManager_Restaurant',
    FilterBuilderFranchiseManager_Restaurant(),
    FranchiseManager_RestaurantRelationBuilder,
    FranchiseManager_RestaurantParser
  ),

Option_Restaurant: () => mk_rel_query(
    'Option_Restaurant',
    FilterBuilderOption_Restaurant(),
    Option_RestaurantRelationBuilder,
    Option_RestaurantParser
  ),

Order_Restaurant: () => mk_rel_query(
    'Order_Restaurant',
    FilterBuilderOrder_Restaurant(),
    Order_RestaurantRelationBuilder,
    Order_RestaurantParser
  ),

PickUpRestaurant_PickUpPayMethod: () => mk_rel_query(
    'PickUpRestaurant_PickUpPayMethod',
    FilterBuilderPickUpRestaurant_PickUpPayMethod(),
    PickUpRestaurant_PickUpPayMethodRelationBuilder,
    PickUpRestaurant_PickUpPayMethodParser
  ),

Product_Restaurant: () => mk_rel_query(
    'Product_Restaurant',
    FilterBuilderProduct_Restaurant(),
    Product_RestaurantRelationBuilder,
    Product_RestaurantParser
  ),

Restaurant_Company: () => mk_rel_query(
    'Restaurant_Company',
    FilterBuilderRestaurant_Company(),
    Restaurant_CompanyRelationBuilder,
    Restaurant_CompanyParser
  ),

Restaurant_DeliveryClosingTime: () => mk_rel_query(
    'Restaurant_DeliveryClosingTime',
    FilterBuilderRestaurant_DeliveryClosingTime(),
    Restaurant_DeliveryClosingTimeRelationBuilder,
    Restaurant_DeliveryClosingTimeParser
  ),

Restaurant_DeliveryTimes: () => mk_rel_query(
    'Restaurant_DeliveryTimes',
    FilterBuilderRestaurant_DeliveryTimes(),
    Restaurant_DeliveryTimesRelationBuilder,
    Restaurant_DeliveryTimesParser
  ),

Restaurant_Discount: () => mk_rel_query(
    'Restaurant_Discount',
    FilterBuilderRestaurant_Discount(),
    Restaurant_DiscountRelationBuilder,
    Restaurant_DiscountParser
  ),

Restaurant_MollieKeys: () => mk_rel_query(
    'Restaurant_MollieKeys',
    FilterBuilderRestaurant_MollieKeys(),
    Restaurant_MollieKeysRelationBuilder,
    Restaurant_MollieKeysParser
  ),

Restaurant_POS4: () => mk_rel_query(
    'Restaurant_POS4',
    FilterBuilderRestaurant_POS4(),
    Restaurant_POS4RelationBuilder,
    Restaurant_POS4Parser
  ),

Restaurant_PickupClosingTime: () => mk_rel_query(
    'Restaurant_PickupClosingTime',
    FilterBuilderRestaurant_PickupClosingTime(),
    Restaurant_PickupClosingTimeRelationBuilder,
    Restaurant_PickupClosingTimeParser
  ),

Restaurant_PickupTimes: () => mk_rel_query(
    'Restaurant_PickupTimes',
    FilterBuilderRestaurant_PickupTimes(),
    Restaurant_PickupTimesRelationBuilder,
    Restaurant_PickupTimesParser
  ),

Restaurant_PostCodeRange: () => mk_rel_query(
    'Restaurant_PostCodeRange',
    FilterBuilderRestaurant_PostCodeRange(),
    Restaurant_PostCodeRangeRelationBuilder,
    Restaurant_PostCodeRangeParser
  ),

Restaurant_WaitingTimes: () => mk_rel_query(
    'Restaurant_WaitingTimes',
    FilterBuilderRestaurant_WaitingTimes(),
    Restaurant_WaitingTimesRelationBuilder,
    Restaurant_WaitingTimesParser
  ),

}


const  RestaurantParser : EntityParser<Restaurant>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      DeliveryCost: parseNumber,
      Active: parseBoolean,
      City: parseNullString,
      Name: parseNullString,
      POS4Code: parseNumber,
      PostCode: parseNullString,
      Delivery: parseBoolean,
      HasNewFlow: parseBoolean,
      Address: parseNullString,
      DeliveryFreeAfterValue: parseNumber,
      DeliveryMinimumCartValue: parseNumber,
      PhoneNumber: parseNullString,
      EmailAddress: parseNullString,
      Pickup: parseBoolean,
  Category_Restaurant: p => parseCollection(x => parseEntity(x, p)),
      DeliveryRestaurant_DeliveryPayMethod: p => parseCollection(x => parseEntity(x, p)),
      FranchiseManager_Restaurant: p => parseCollection(x => parseEntity(x, p)),
      Option_Restaurant: p => parseCollection(x => parseEntity(x, p)),
      Order_Restaurant: p => parseCollection(x => parseEntity(x, p)),
      PickUpRestaurant_PickUpPayMethod: p => parseCollection(x => parseEntity(x, p)),
      Product_Restaurant: p => parseCollection(x => parseEntity(x, p)),
      Restaurant_Company: p => parseCollection(x => parseEntity(x, p)),
      Restaurant_DeliveryClosingTime: p => parseCollection(x => parseEntity(x, p)),
      Restaurant_DeliveryTimes: p => parseCollection(x => parseEntity(x, p)),
      Restaurant_Discount: p => parseCollection(x => parseEntity(x, p)),
      Restaurant_MollieKeys: p => parseCollection(x => parseEntity(x, p)),
      Restaurant_POS4: p => parseCollection(x => parseEntity(x, p)),
      Restaurant_PickupClosingTime: p => parseCollection(x => parseEntity(x, p)),
      Restaurant_PickupTimes: p => parseCollection(x => parseEntity(x, p)),
      Restaurant_PostCodeRange: p => parseCollection(x => parseEntity(x, p)),
      Restaurant_WaitingTimes: p => parseCollection(x => parseEntity(x, p)),
}


const RestaurantOrderBy : (p: string) => OrderByBuilderComplex<Restaurant> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      DeliveryCost:  new OrderByProp(p ? p + "/DeliveryCost" : "DeliveryCost"),
      Active:  new OrderByProp(p ? p + "/Active" : "Active"),
      City:  new OrderByProp(p ? p + "/City" : "City"),
      Name:  new OrderByProp(p ? p + "/Name" : "Name"),
      POS4Code:  new OrderByProp(p ? p + "/POS4Code" : "POS4Code"),
      PostCode:  new OrderByProp(p ? p + "/PostCode" : "PostCode"),
      Delivery:  new OrderByProp(p ? p + "/Delivery" : "Delivery"),
      HasNewFlow:  new OrderByProp(p ? p + "/HasNewFlow" : "HasNewFlow"),
      Address:  new OrderByProp(p ? p + "/Address" : "Address"),
      DeliveryFreeAfterValue:  new OrderByProp(p ? p + "/DeliveryFreeAfterValue" : "DeliveryFreeAfterValue"),
      DeliveryMinimumCartValue:  new OrderByProp(p ? p + "/DeliveryMinimumCartValue" : "DeliveryMinimumCartValue"),
      PhoneNumber:  new OrderByProp(p ? p + "/PhoneNumber" : "PhoneNumber"),
      EmailAddress:  new OrderByProp(p ? p + "/EmailAddress" : "EmailAddress"),
      Pickup:  new OrderByProp(p ? p + "/Pickup" : "Pickup"),
  Category_Restaurant:  p => Category_RestaurantOrderBy(p ? p + "/Category_Restaurant" : "Category_Restaurant"),
      DeliveryRestaurant_DeliveryPayMethod:  p => DeliveryRestaurant_DeliveryPayMethodOrderBy(p ? p + "/DeliveryRestaurant_DeliveryPayMethod" : "DeliveryRestaurant_DeliveryPayMethod"),
      FranchiseManager_Restaurant:  p => FranchiseManager_RestaurantOrderBy(p ? p + "/FranchiseManager_Restaurant" : "FranchiseManager_Restaurant"),
      Option_Restaurant:  p => Option_RestaurantOrderBy(p ? p + "/Option_Restaurant" : "Option_Restaurant"),
      Order_Restaurant:  p => Order_RestaurantOrderBy(p ? p + "/Order_Restaurant" : "Order_Restaurant"),
      PickUpRestaurant_PickUpPayMethod:  p => PickUpRestaurant_PickUpPayMethodOrderBy(p ? p + "/PickUpRestaurant_PickUpPayMethod" : "PickUpRestaurant_PickUpPayMethod"),
      Product_Restaurant:  p => Product_RestaurantOrderBy(p ? p + "/Product_Restaurant" : "Product_Restaurant"),
      Restaurant_Company:  p => Restaurant_CompanyOrderBy(p ? p + "/Restaurant_Company" : "Restaurant_Company"),
      Restaurant_DeliveryClosingTime:  p => Restaurant_DeliveryClosingTimeOrderBy(p ? p + "/Restaurant_DeliveryClosingTime" : "Restaurant_DeliveryClosingTime"),
      Restaurant_DeliveryTimes:  p => Restaurant_DeliveryTimesOrderBy(p ? p + "/Restaurant_DeliveryTimes" : "Restaurant_DeliveryTimes"),
      Restaurant_Discount:  p => Restaurant_DiscountOrderBy(p ? p + "/Restaurant_Discount" : "Restaurant_Discount"),
      Restaurant_MollieKeys:  p => Restaurant_MollieKeysOrderBy(p ? p + "/Restaurant_MollieKeys" : "Restaurant_MollieKeys"),
      Restaurant_POS4:  p => Restaurant_POS4OrderBy(p ? p + "/Restaurant_POS4" : "Restaurant_POS4"),
      Restaurant_PickupClosingTime:  p => Restaurant_PickupClosingTimeOrderBy(p ? p + "/Restaurant_PickupClosingTime" : "Restaurant_PickupClosingTime"),
      Restaurant_PickupTimes:  p => Restaurant_PickupTimesOrderBy(p ? p + "/Restaurant_PickupTimes" : "Restaurant_PickupTimes"),
      Restaurant_PostCodeRange:  p => Restaurant_PostCodeRangeOrderBy(p ? p + "/Restaurant_PostCodeRange" : "Restaurant_PostCodeRange"),
      Restaurant_WaitingTimes:  p => Restaurant_WaitingTimesOrderBy(p ? p + "/Restaurant_WaitingTimes" : "Restaurant_WaitingTimes"),
})



export type Restaurant_Company = {
    Id: number
    RestaurantId: number
    CompanyId: number
} & {
  Company: List<Company>
    Restaurant: List<Restaurant>
}


function FilterBuilderRestaurant_Company(prefix?: string) : FilterBuilderComplex<Restaurant_Company> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      RestaurantId: new FilterBuilderNumber(mk_prefix('RestaurantId', prefix)),
      CompanyId: new FilterBuilderNumber(mk_prefix('CompanyId', prefix)),
    Company: new FilterBuilderCollection<Company>(mk_prefix('Company', prefix), s => FilterBuilderCompany(s)),
      Restaurant: new FilterBuilderCollection<Restaurant>(mk_prefix('Restaurant', prefix), s => FilterBuilderRestaurant(s)),
  }
}


const Restaurant_CompanyRelationBuilder: RelationBuilder<RelationsOf<Restaurant_Company>> = {
Company: () => mk_rel_query(
    'Company',
    FilterBuilderCompany(),
    CompanyRelationBuilder,
    CompanyParser
  ),

Restaurant: () => mk_rel_query(
    'Restaurant',
    FilterBuilderRestaurant(),
    RestaurantRelationBuilder,
    RestaurantParser
  ),

}


const  Restaurant_CompanyParser : EntityParser<Restaurant_Company>= {
  Id: parseNumber,
      RestaurantId: parseNumber,
      CompanyId: parseNumber,
  Company: p => parseCollection(x => parseEntity(x, p)),
      Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const Restaurant_CompanyOrderBy : (p: string) => OrderByBuilderComplex<Restaurant_Company> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      RestaurantId:  new OrderByProp(p ? p + "/RestaurantId" : "RestaurantId"),
      CompanyId:  new OrderByProp(p ? p + "/CompanyId" : "CompanyId"),
  Company:  p => CompanyOrderBy(p ? p + "/Company" : "Company"),
      Restaurant:  p => RestaurantOrderBy(p ? p + "/Restaurant" : "Restaurant"),
})



export type Restaurant_DeliveryClosingTime = {
    Id: number
    RestaurantId: number
    DeliveryClosingTimeId: number
} & {
  DeliveryClosingTime: List<DeliveryClosingTime>
    Restaurant: List<Restaurant>
}


function FilterBuilderRestaurant_DeliveryClosingTime(prefix?: string) : FilterBuilderComplex<Restaurant_DeliveryClosingTime> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      RestaurantId: new FilterBuilderNumber(mk_prefix('RestaurantId', prefix)),
      DeliveryClosingTimeId: new FilterBuilderNumber(mk_prefix('DeliveryClosingTimeId', prefix)),
    DeliveryClosingTime: new FilterBuilderCollection<DeliveryClosingTime>(mk_prefix('DeliveryClosingTime', prefix), s => FilterBuilderDeliveryClosingTime(s)),
      Restaurant: new FilterBuilderCollection<Restaurant>(mk_prefix('Restaurant', prefix), s => FilterBuilderRestaurant(s)),
  }
}


const Restaurant_DeliveryClosingTimeRelationBuilder: RelationBuilder<RelationsOf<Restaurant_DeliveryClosingTime>> = {
DeliveryClosingTime: () => mk_rel_query(
    'DeliveryClosingTime',
    FilterBuilderDeliveryClosingTime(),
    DeliveryClosingTimeRelationBuilder,
    DeliveryClosingTimeParser
  ),

Restaurant: () => mk_rel_query(
    'Restaurant',
    FilterBuilderRestaurant(),
    RestaurantRelationBuilder,
    RestaurantParser
  ),

}


const  Restaurant_DeliveryClosingTimeParser : EntityParser<Restaurant_DeliveryClosingTime>= {
  Id: parseNumber,
      RestaurantId: parseNumber,
      DeliveryClosingTimeId: parseNumber,
  DeliveryClosingTime: p => parseCollection(x => parseEntity(x, p)),
      Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const Restaurant_DeliveryClosingTimeOrderBy : (p: string) => OrderByBuilderComplex<Restaurant_DeliveryClosingTime> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      RestaurantId:  new OrderByProp(p ? p + "/RestaurantId" : "RestaurantId"),
      DeliveryClosingTimeId:  new OrderByProp(p ? p + "/DeliveryClosingTimeId" : "DeliveryClosingTimeId"),
  DeliveryClosingTime:  p => DeliveryClosingTimeOrderBy(p ? p + "/DeliveryClosingTime" : "DeliveryClosingTime"),
      Restaurant:  p => RestaurantOrderBy(p ? p + "/Restaurant" : "Restaurant"),
})



export type Restaurant_DeliveryTimes = {
    Id: number
    RestaurantId: number
    DeliveryTimesId: number
} & {
  DeliveryTimes: List<DeliveryTimes>
    Restaurant: List<Restaurant>
}


function FilterBuilderRestaurant_DeliveryTimes(prefix?: string) : FilterBuilderComplex<Restaurant_DeliveryTimes> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      RestaurantId: new FilterBuilderNumber(mk_prefix('RestaurantId', prefix)),
      DeliveryTimesId: new FilterBuilderNumber(mk_prefix('DeliveryTimesId', prefix)),
    DeliveryTimes: new FilterBuilderCollection<DeliveryTimes>(mk_prefix('DeliveryTimes', prefix), s => FilterBuilderDeliveryTimes(s)),
      Restaurant: new FilterBuilderCollection<Restaurant>(mk_prefix('Restaurant', prefix), s => FilterBuilderRestaurant(s)),
  }
}


const Restaurant_DeliveryTimesRelationBuilder: RelationBuilder<RelationsOf<Restaurant_DeliveryTimes>> = {
DeliveryTimes: () => mk_rel_query(
    'DeliveryTimes',
    FilterBuilderDeliveryTimes(),
    DeliveryTimesRelationBuilder,
    DeliveryTimesParser
  ),

Restaurant: () => mk_rel_query(
    'Restaurant',
    FilterBuilderRestaurant(),
    RestaurantRelationBuilder,
    RestaurantParser
  ),

}


const  Restaurant_DeliveryTimesParser : EntityParser<Restaurant_DeliveryTimes>= {
  Id: parseNumber,
      RestaurantId: parseNumber,
      DeliveryTimesId: parseNumber,
  DeliveryTimes: p => parseCollection(x => parseEntity(x, p)),
      Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const Restaurant_DeliveryTimesOrderBy : (p: string) => OrderByBuilderComplex<Restaurant_DeliveryTimes> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      RestaurantId:  new OrderByProp(p ? p + "/RestaurantId" : "RestaurantId"),
      DeliveryTimesId:  new OrderByProp(p ? p + "/DeliveryTimesId" : "DeliveryTimesId"),
  DeliveryTimes:  p => DeliveryTimesOrderBy(p ? p + "/DeliveryTimes" : "DeliveryTimes"),
      Restaurant:  p => RestaurantOrderBy(p ? p + "/Restaurant" : "Restaurant"),
})



export type Restaurant_Discount = {
    Id: number
    RestaurantId: number
    DiscountId: number
} & {
  Discount: List<Discount>
    Restaurant: List<Restaurant>
}


function FilterBuilderRestaurant_Discount(prefix?: string) : FilterBuilderComplex<Restaurant_Discount> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      RestaurantId: new FilterBuilderNumber(mk_prefix('RestaurantId', prefix)),
      DiscountId: new FilterBuilderNumber(mk_prefix('DiscountId', prefix)),
    Discount: new FilterBuilderCollection<Discount>(mk_prefix('Discount', prefix), s => FilterBuilderDiscount(s)),
      Restaurant: new FilterBuilderCollection<Restaurant>(mk_prefix('Restaurant', prefix), s => FilterBuilderRestaurant(s)),
  }
}


const Restaurant_DiscountRelationBuilder: RelationBuilder<RelationsOf<Restaurant_Discount>> = {
Discount: () => mk_rel_query(
    'Discount',
    FilterBuilderDiscount(),
    DiscountRelationBuilder,
    DiscountParser
  ),

Restaurant: () => mk_rel_query(
    'Restaurant',
    FilterBuilderRestaurant(),
    RestaurantRelationBuilder,
    RestaurantParser
  ),

}


const  Restaurant_DiscountParser : EntityParser<Restaurant_Discount>= {
  Id: parseNumber,
      RestaurantId: parseNumber,
      DiscountId: parseNumber,
  Discount: p => parseCollection(x => parseEntity(x, p)),
      Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const Restaurant_DiscountOrderBy : (p: string) => OrderByBuilderComplex<Restaurant_Discount> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      RestaurantId:  new OrderByProp(p ? p + "/RestaurantId" : "RestaurantId"),
      DiscountId:  new OrderByProp(p ? p + "/DiscountId" : "DiscountId"),
  Discount:  p => DiscountOrderBy(p ? p + "/Discount" : "Discount"),
      Restaurant:  p => RestaurantOrderBy(p ? p + "/Restaurant" : "Restaurant"),
})



export type Restaurant_MollieKeys = {
    Id: number
    RestaurantId: number
    MollieKeysId: number
} & {
  MollieKeys: List<MollieKeys>
    Restaurant: List<Restaurant>
}


function FilterBuilderRestaurant_MollieKeys(prefix?: string) : FilterBuilderComplex<Restaurant_MollieKeys> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      RestaurantId: new FilterBuilderNumber(mk_prefix('RestaurantId', prefix)),
      MollieKeysId: new FilterBuilderNumber(mk_prefix('MollieKeysId', prefix)),
    MollieKeys: new FilterBuilderCollection<MollieKeys>(mk_prefix('MollieKeys', prefix), s => FilterBuilderMollieKeys(s)),
      Restaurant: new FilterBuilderCollection<Restaurant>(mk_prefix('Restaurant', prefix), s => FilterBuilderRestaurant(s)),
  }
}


const Restaurant_MollieKeysRelationBuilder: RelationBuilder<RelationsOf<Restaurant_MollieKeys>> = {
MollieKeys: () => mk_rel_query(
    'MollieKeys',
    FilterBuilderMollieKeys(),
    MollieKeysRelationBuilder,
    MollieKeysParser
  ),

Restaurant: () => mk_rel_query(
    'Restaurant',
    FilterBuilderRestaurant(),
    RestaurantRelationBuilder,
    RestaurantParser
  ),

}


const  Restaurant_MollieKeysParser : EntityParser<Restaurant_MollieKeys>= {
  Id: parseNumber,
      RestaurantId: parseNumber,
      MollieKeysId: parseNumber,
  MollieKeys: p => parseCollection(x => parseEntity(x, p)),
      Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const Restaurant_MollieKeysOrderBy : (p: string) => OrderByBuilderComplex<Restaurant_MollieKeys> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      RestaurantId:  new OrderByProp(p ? p + "/RestaurantId" : "RestaurantId"),
      MollieKeysId:  new OrderByProp(p ? p + "/MollieKeysId" : "MollieKeysId"),
  MollieKeys:  p => MollieKeysOrderBy(p ? p + "/MollieKeys" : "MollieKeys"),
      Restaurant:  p => RestaurantOrderBy(p ? p + "/Restaurant" : "Restaurant"),
})



export type Restaurant_POS4 = {
    Id: number
    RestaurantId: number
    POS4Id: number
} & {
  POS4: List<POS4>
    Restaurant: List<Restaurant>
}


function FilterBuilderRestaurant_POS4(prefix?: string) : FilterBuilderComplex<Restaurant_POS4> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      RestaurantId: new FilterBuilderNumber(mk_prefix('RestaurantId', prefix)),
      POS4Id: new FilterBuilderNumber(mk_prefix('POS4Id', prefix)),
    POS4: new FilterBuilderCollection<POS4>(mk_prefix('POS4', prefix), s => FilterBuilderPOS4(s)),
      Restaurant: new FilterBuilderCollection<Restaurant>(mk_prefix('Restaurant', prefix), s => FilterBuilderRestaurant(s)),
  }
}


const Restaurant_POS4RelationBuilder: RelationBuilder<RelationsOf<Restaurant_POS4>> = {
POS4: () => mk_rel_query(
    'POS4',
    FilterBuilderPOS4(),
    POS4RelationBuilder,
    POS4Parser
  ),

Restaurant: () => mk_rel_query(
    'Restaurant',
    FilterBuilderRestaurant(),
    RestaurantRelationBuilder,
    RestaurantParser
  ),

}


const  Restaurant_POS4Parser : EntityParser<Restaurant_POS4>= {
  Id: parseNumber,
      RestaurantId: parseNumber,
      POS4Id: parseNumber,
  POS4: p => parseCollection(x => parseEntity(x, p)),
      Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const Restaurant_POS4OrderBy : (p: string) => OrderByBuilderComplex<Restaurant_POS4> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      RestaurantId:  new OrderByProp(p ? p + "/RestaurantId" : "RestaurantId"),
      POS4Id:  new OrderByProp(p ? p + "/POS4Id" : "POS4Id"),
  POS4:  p => POS4OrderBy(p ? p + "/POS4" : "POS4"),
      Restaurant:  p => RestaurantOrderBy(p ? p + "/Restaurant" : "Restaurant"),
})



export type Restaurant_PickupClosingTime = {
    Id: number
    RestaurantId: number
    PickupClosingTimeId: number
} & {
  PickupClosingTime: List<PickupClosingTime>
    Restaurant: List<Restaurant>
}


function FilterBuilderRestaurant_PickupClosingTime(prefix?: string) : FilterBuilderComplex<Restaurant_PickupClosingTime> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      RestaurantId: new FilterBuilderNumber(mk_prefix('RestaurantId', prefix)),
      PickupClosingTimeId: new FilterBuilderNumber(mk_prefix('PickupClosingTimeId', prefix)),
    PickupClosingTime: new FilterBuilderCollection<PickupClosingTime>(mk_prefix('PickupClosingTime', prefix), s => FilterBuilderPickupClosingTime(s)),
      Restaurant: new FilterBuilderCollection<Restaurant>(mk_prefix('Restaurant', prefix), s => FilterBuilderRestaurant(s)),
  }
}


const Restaurant_PickupClosingTimeRelationBuilder: RelationBuilder<RelationsOf<Restaurant_PickupClosingTime>> = {
PickupClosingTime: () => mk_rel_query(
    'PickupClosingTime',
    FilterBuilderPickupClosingTime(),
    PickupClosingTimeRelationBuilder,
    PickupClosingTimeParser
  ),

Restaurant: () => mk_rel_query(
    'Restaurant',
    FilterBuilderRestaurant(),
    RestaurantRelationBuilder,
    RestaurantParser
  ),

}


const  Restaurant_PickupClosingTimeParser : EntityParser<Restaurant_PickupClosingTime>= {
  Id: parseNumber,
      RestaurantId: parseNumber,
      PickupClosingTimeId: parseNumber,
  PickupClosingTime: p => parseCollection(x => parseEntity(x, p)),
      Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const Restaurant_PickupClosingTimeOrderBy : (p: string) => OrderByBuilderComplex<Restaurant_PickupClosingTime> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      RestaurantId:  new OrderByProp(p ? p + "/RestaurantId" : "RestaurantId"),
      PickupClosingTimeId:  new OrderByProp(p ? p + "/PickupClosingTimeId" : "PickupClosingTimeId"),
  PickupClosingTime:  p => PickupClosingTimeOrderBy(p ? p + "/PickupClosingTime" : "PickupClosingTime"),
      Restaurant:  p => RestaurantOrderBy(p ? p + "/Restaurant" : "Restaurant"),
})



export type Restaurant_PickupTimes = {
    Id: number
    RestaurantId: number
    PickupTimesId: number
} & {
  PickupTimes: List<PickupTimes>
    Restaurant: List<Restaurant>
}


function FilterBuilderRestaurant_PickupTimes(prefix?: string) : FilterBuilderComplex<Restaurant_PickupTimes> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      RestaurantId: new FilterBuilderNumber(mk_prefix('RestaurantId', prefix)),
      PickupTimesId: new FilterBuilderNumber(mk_prefix('PickupTimesId', prefix)),
    PickupTimes: new FilterBuilderCollection<PickupTimes>(mk_prefix('PickupTimes', prefix), s => FilterBuilderPickupTimes(s)),
      Restaurant: new FilterBuilderCollection<Restaurant>(mk_prefix('Restaurant', prefix), s => FilterBuilderRestaurant(s)),
  }
}


const Restaurant_PickupTimesRelationBuilder: RelationBuilder<RelationsOf<Restaurant_PickupTimes>> = {
PickupTimes: () => mk_rel_query(
    'PickupTimes',
    FilterBuilderPickupTimes(),
    PickupTimesRelationBuilder,
    PickupTimesParser
  ),

Restaurant: () => mk_rel_query(
    'Restaurant',
    FilterBuilderRestaurant(),
    RestaurantRelationBuilder,
    RestaurantParser
  ),

}


const  Restaurant_PickupTimesParser : EntityParser<Restaurant_PickupTimes>= {
  Id: parseNumber,
      RestaurantId: parseNumber,
      PickupTimesId: parseNumber,
  PickupTimes: p => parseCollection(x => parseEntity(x, p)),
      Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const Restaurant_PickupTimesOrderBy : (p: string) => OrderByBuilderComplex<Restaurant_PickupTimes> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      RestaurantId:  new OrderByProp(p ? p + "/RestaurantId" : "RestaurantId"),
      PickupTimesId:  new OrderByProp(p ? p + "/PickupTimesId" : "PickupTimesId"),
  PickupTimes:  p => PickupTimesOrderBy(p ? p + "/PickupTimes" : "PickupTimes"),
      Restaurant:  p => RestaurantOrderBy(p ? p + "/Restaurant" : "Restaurant"),
})



export type Restaurant_PostCodeRange = {
    Id: number
    RestaurantId: number
    PostCodeRangeId: number
} & {
  PostCodeRange: List<PostCodeRange>
    Restaurant: List<Restaurant>
}


function FilterBuilderRestaurant_PostCodeRange(prefix?: string) : FilterBuilderComplex<Restaurant_PostCodeRange> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      RestaurantId: new FilterBuilderNumber(mk_prefix('RestaurantId', prefix)),
      PostCodeRangeId: new FilterBuilderNumber(mk_prefix('PostCodeRangeId', prefix)),
    PostCodeRange: new FilterBuilderCollection<PostCodeRange>(mk_prefix('PostCodeRange', prefix), s => FilterBuilderPostCodeRange(s)),
      Restaurant: new FilterBuilderCollection<Restaurant>(mk_prefix('Restaurant', prefix), s => FilterBuilderRestaurant(s)),
  }
}


const Restaurant_PostCodeRangeRelationBuilder: RelationBuilder<RelationsOf<Restaurant_PostCodeRange>> = {
PostCodeRange: () => mk_rel_query(
    'PostCodeRange',
    FilterBuilderPostCodeRange(),
    PostCodeRangeRelationBuilder,
    PostCodeRangeParser
  ),

Restaurant: () => mk_rel_query(
    'Restaurant',
    FilterBuilderRestaurant(),
    RestaurantRelationBuilder,
    RestaurantParser
  ),

}


const  Restaurant_PostCodeRangeParser : EntityParser<Restaurant_PostCodeRange>= {
  Id: parseNumber,
      RestaurantId: parseNumber,
      PostCodeRangeId: parseNumber,
  PostCodeRange: p => parseCollection(x => parseEntity(x, p)),
      Restaurant: p => parseCollection(x => parseEntity(x, p)),
}


const Restaurant_PostCodeRangeOrderBy : (p: string) => OrderByBuilderComplex<Restaurant_PostCodeRange> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      RestaurantId:  new OrderByProp(p ? p + "/RestaurantId" : "RestaurantId"),
      PostCodeRangeId:  new OrderByProp(p ? p + "/PostCodeRangeId" : "PostCodeRangeId"),
  PostCodeRange:  p => PostCodeRangeOrderBy(p ? p + "/PostCodeRange" : "PostCodeRange"),
      Restaurant:  p => RestaurantOrderBy(p ? p + "/Restaurant" : "Restaurant"),
})



export type Restaurant_WaitingTimes = {
    Id: number
    RestaurantId: number
    WaitingTimesId: number
} & {
  Restaurant: List<Restaurant>
    WaitingTimes: List<WaitingTimes>
}


function FilterBuilderRestaurant_WaitingTimes(prefix?: string) : FilterBuilderComplex<Restaurant_WaitingTimes> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      RestaurantId: new FilterBuilderNumber(mk_prefix('RestaurantId', prefix)),
      WaitingTimesId: new FilterBuilderNumber(mk_prefix('WaitingTimesId', prefix)),
    Restaurant: new FilterBuilderCollection<Restaurant>(mk_prefix('Restaurant', prefix), s => FilterBuilderRestaurant(s)),
      WaitingTimes: new FilterBuilderCollection<WaitingTimes>(mk_prefix('WaitingTimes', prefix), s => FilterBuilderWaitingTimes(s)),
  }
}


const Restaurant_WaitingTimesRelationBuilder: RelationBuilder<RelationsOf<Restaurant_WaitingTimes>> = {
Restaurant: () => mk_rel_query(
    'Restaurant',
    FilterBuilderRestaurant(),
    RestaurantRelationBuilder,
    RestaurantParser
  ),

WaitingTimes: () => mk_rel_query(
    'WaitingTimes',
    FilterBuilderWaitingTimes(),
    WaitingTimesRelationBuilder,
    WaitingTimesParser
  ),

}


const  Restaurant_WaitingTimesParser : EntityParser<Restaurant_WaitingTimes>= {
  Id: parseNumber,
      RestaurantId: parseNumber,
      WaitingTimesId: parseNumber,
  Restaurant: p => parseCollection(x => parseEntity(x, p)),
      WaitingTimes: p => parseCollection(x => parseEntity(x, p)),
}


const Restaurant_WaitingTimesOrderBy : (p: string) => OrderByBuilderComplex<Restaurant_WaitingTimes> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      RestaurantId:  new OrderByProp(p ? p + "/RestaurantId" : "RestaurantId"),
      WaitingTimesId:  new OrderByProp(p ? p + "/WaitingTimesId" : "WaitingTimesId"),
  Restaurant:  p => RestaurantOrderBy(p ? p + "/Restaurant" : "Restaurant"),
      WaitingTimes:  p => WaitingTimesOrderBy(p ? p + "/WaitingTimes" : "WaitingTimes"),
})



export type Session = {
    Id: number
    LoggedEntityId: number | null
    LoggedEntityName: string | null
    AdditionalInfo: string | null
    CookieName: string | null
    Content: string | null
    CreatedAt: Date
    LastInteraction: Date
} & {

}


function FilterBuilderSession(prefix?: string) : FilterBuilderComplex<Session> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      LoggedEntityId: new FilterBuilderNumber(mk_prefix('LoggedEntityId', prefix)),
      LoggedEntityName: new FilterBuilderString(mk_prefix('LoggedEntityName', prefix)),
      AdditionalInfo: new FilterBuilderString(mk_prefix('AdditionalInfo', prefix)),
      CookieName: new FilterBuilderString(mk_prefix('CookieName', prefix)),
      Content: new FilterBuilderString(mk_prefix('Content', prefix)),
      CreatedAt: new FilterBuilderDate(mk_prefix('CreatedAt', prefix)),
      LastInteraction: new FilterBuilderDate(mk_prefix('LastInteraction', prefix)),
    
  }
}


const SessionRelationBuilder: RelationBuilder<RelationsOf<Session>> = {

}


const  SessionParser : EntityParser<Session>= {
  Id: parseNumber,
      LoggedEntityId: parseNullNumber,
      LoggedEntityName: parseNullString,
      AdditionalInfo: parseNullString,
      CookieName: parseNullString,
      Content: parseNullString,
      CreatedAt: parseDate,
      LastInteraction: parseDate,
  
}


const SessionOrderBy : (p: string) => OrderByBuilderComplex<Session> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      LoggedEntityId:  new OrderByProp(p ? p + "/LoggedEntityId" : "LoggedEntityId"),
      LoggedEntityName:  new OrderByProp(p ? p + "/LoggedEntityName" : "LoggedEntityName"),
      AdditionalInfo:  new OrderByProp(p ? p + "/AdditionalInfo" : "AdditionalInfo"),
      CookieName:  new OrderByProp(p ? p + "/CookieName" : "CookieName"),
      Content:  new OrderByProp(p ? p + "/Content" : "Content"),
      CreatedAt:  new OrderByProp(p ? p + "/CreatedAt" : "CreatedAt"),
      LastInteraction:  new OrderByProp(p ? p + "/LastInteraction" : "LastInteraction"),
  
})



export type User = {
    Id: number
    CreatedDate: Date
    Username: string | null
    FirstName: string | null
    LastName: string | null
    Email: string | null
    Phonenumber: string | null
    Language: string | null
    PasswordHash: string | null
    PasswordSalt: string | null
    LastLoginAttempt: Date
    SecurityStamp: string | null
    EmailConfirmed: boolean
} & {
  Company_User: List<Company_User>
    Order_User: List<Order_User>
    User_AlternativeAddress: List<User_AlternativeAddress>
    User_DefaultAddress: List<User_DefaultAddress>
}


function FilterBuilderUser(prefix?: string) : FilterBuilderComplex<User> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      Username: new FilterBuilderString(mk_prefix('Username', prefix)),
      FirstName: new FilterBuilderString(mk_prefix('FirstName', prefix)),
      LastName: new FilterBuilderString(mk_prefix('LastName', prefix)),
      Email: new FilterBuilderString(mk_prefix('Email', prefix)),
      Phonenumber: new FilterBuilderString(mk_prefix('Phonenumber', prefix)),
      Language: new FilterBuilderString(mk_prefix('Language', prefix)),
      PasswordHash: new FilterBuilderString(mk_prefix('PasswordHash', prefix)),
      PasswordSalt: new FilterBuilderString(mk_prefix('PasswordSalt', prefix)),
      LastLoginAttempt: new FilterBuilderDate(mk_prefix('LastLoginAttempt', prefix)),
      SecurityStamp: new FilterBuilderString(mk_prefix('SecurityStamp', prefix)),
      EmailConfirmed: new FilterBuilderBoolean(mk_prefix('EmailConfirmed', prefix)),
    Company_User: new FilterBuilderCollection<Company_User>(mk_prefix('Company_User', prefix), s => FilterBuilderCompany_User(s)),
      Order_User: new FilterBuilderCollection<Order_User>(mk_prefix('Order_User', prefix), s => FilterBuilderOrder_User(s)),
      User_AlternativeAddress: new FilterBuilderCollection<User_AlternativeAddress>(mk_prefix('User_AlternativeAddress', prefix), s => FilterBuilderUser_AlternativeAddress(s)),
      User_DefaultAddress: new FilterBuilderCollection<User_DefaultAddress>(mk_prefix('User_DefaultAddress', prefix), s => FilterBuilderUser_DefaultAddress(s)),
  }
}


const UserRelationBuilder: RelationBuilder<RelationsOf<User>> = {
Company_User: () => mk_rel_query(
    'Company_User',
    FilterBuilderCompany_User(),
    Company_UserRelationBuilder,
    Company_UserParser
  ),

Order_User: () => mk_rel_query(
    'Order_User',
    FilterBuilderOrder_User(),
    Order_UserRelationBuilder,
    Order_UserParser
  ),

User_AlternativeAddress: () => mk_rel_query(
    'User_AlternativeAddress',
    FilterBuilderUser_AlternativeAddress(),
    User_AlternativeAddressRelationBuilder,
    User_AlternativeAddressParser
  ),

User_DefaultAddress: () => mk_rel_query(
    'User_DefaultAddress',
    FilterBuilderUser_DefaultAddress(),
    User_DefaultAddressRelationBuilder,
    User_DefaultAddressParser
  ),

}


const  UserParser : EntityParser<User>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      Username: parseNullString,
      FirstName: parseNullString,
      LastName: parseNullString,
      Email: parseNullString,
      Phonenumber: parseNullString,
      Language: parseNullString,
      PasswordHash: parseNullString,
      PasswordSalt: parseNullString,
      LastLoginAttempt: parseDate,
      SecurityStamp: parseNullString,
      EmailConfirmed: parseBoolean,
  Company_User: p => parseCollection(x => parseEntity(x, p)),
      Order_User: p => parseCollection(x => parseEntity(x, p)),
      User_AlternativeAddress: p => parseCollection(x => parseEntity(x, p)),
      User_DefaultAddress: p => parseCollection(x => parseEntity(x, p)),
}


const UserOrderBy : (p: string) => OrderByBuilderComplex<User> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      Username:  new OrderByProp(p ? p + "/Username" : "Username"),
      FirstName:  new OrderByProp(p ? p + "/FirstName" : "FirstName"),
      LastName:  new OrderByProp(p ? p + "/LastName" : "LastName"),
      Email:  new OrderByProp(p ? p + "/Email" : "Email"),
      Phonenumber:  new OrderByProp(p ? p + "/Phonenumber" : "Phonenumber"),
      Language:  new OrderByProp(p ? p + "/Language" : "Language"),
      PasswordHash:  new OrderByProp(p ? p + "/PasswordHash" : "PasswordHash"),
      PasswordSalt:  new OrderByProp(p ? p + "/PasswordSalt" : "PasswordSalt"),
      LastLoginAttempt:  new OrderByProp(p ? p + "/LastLoginAttempt" : "LastLoginAttempt"),
      SecurityStamp:  new OrderByProp(p ? p + "/SecurityStamp" : "SecurityStamp"),
      EmailConfirmed:  new OrderByProp(p ? p + "/EmailConfirmed" : "EmailConfirmed"),
  Company_User:  p => Company_UserOrderBy(p ? p + "/Company_User" : "Company_User"),
      Order_User:  p => Order_UserOrderBy(p ? p + "/Order_User" : "Order_User"),
      User_AlternativeAddress:  p => User_AlternativeAddressOrderBy(p ? p + "/User_AlternativeAddress" : "User_AlternativeAddress"),
      User_DefaultAddress:  p => User_DefaultAddressOrderBy(p ? p + "/User_DefaultAddress" : "User_DefaultAddress"),
})



export type User_AlternativeAddress = {
    Id: number
    UserId: number
    AlternativeAddressId: number
} & {
  AlternativeAddress: List<AlternativeAddress>
    User: List<User>
}


function FilterBuilderUser_AlternativeAddress(prefix?: string) : FilterBuilderComplex<User_AlternativeAddress> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      UserId: new FilterBuilderNumber(mk_prefix('UserId', prefix)),
      AlternativeAddressId: new FilterBuilderNumber(mk_prefix('AlternativeAddressId', prefix)),
    AlternativeAddress: new FilterBuilderCollection<AlternativeAddress>(mk_prefix('AlternativeAddress', prefix), s => FilterBuilderAlternativeAddress(s)),
      User: new FilterBuilderCollection<User>(mk_prefix('User', prefix), s => FilterBuilderUser(s)),
  }
}


const User_AlternativeAddressRelationBuilder: RelationBuilder<RelationsOf<User_AlternativeAddress>> = {
AlternativeAddress: () => mk_rel_query(
    'AlternativeAddress',
    FilterBuilderAlternativeAddress(),
    AlternativeAddressRelationBuilder,
    AlternativeAddressParser
  ),

User: () => mk_rel_query(
    'User',
    FilterBuilderUser(),
    UserRelationBuilder,
    UserParser
  ),

}


const  User_AlternativeAddressParser : EntityParser<User_AlternativeAddress>= {
  Id: parseNumber,
      UserId: parseNumber,
      AlternativeAddressId: parseNumber,
  AlternativeAddress: p => parseCollection(x => parseEntity(x, p)),
      User: p => parseCollection(x => parseEntity(x, p)),
}


const User_AlternativeAddressOrderBy : (p: string) => OrderByBuilderComplex<User_AlternativeAddress> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      UserId:  new OrderByProp(p ? p + "/UserId" : "UserId"),
      AlternativeAddressId:  new OrderByProp(p ? p + "/AlternativeAddressId" : "AlternativeAddressId"),
  AlternativeAddress:  p => AlternativeAddressOrderBy(p ? p + "/AlternativeAddress" : "AlternativeAddress"),
      User:  p => UserOrderBy(p ? p + "/User" : "User"),
})



export type User_DefaultAddress = {
    Id: number
    UserId: number
    DefaultAddressId: number
} & {
  DefaultAddress: List<DefaultAddress>
    User: List<User>
}


function FilterBuilderUser_DefaultAddress(prefix?: string) : FilterBuilderComplex<User_DefaultAddress> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      UserId: new FilterBuilderNumber(mk_prefix('UserId', prefix)),
      DefaultAddressId: new FilterBuilderNumber(mk_prefix('DefaultAddressId', prefix)),
    DefaultAddress: new FilterBuilderCollection<DefaultAddress>(mk_prefix('DefaultAddress', prefix), s => FilterBuilderDefaultAddress(s)),
      User: new FilterBuilderCollection<User>(mk_prefix('User', prefix), s => FilterBuilderUser(s)),
  }
}


const User_DefaultAddressRelationBuilder: RelationBuilder<RelationsOf<User_DefaultAddress>> = {
DefaultAddress: () => mk_rel_query(
    'DefaultAddress',
    FilterBuilderDefaultAddress(),
    DefaultAddressRelationBuilder,
    DefaultAddressParser
  ),

User: () => mk_rel_query(
    'User',
    FilterBuilderUser(),
    UserRelationBuilder,
    UserParser
  ),

}


const  User_DefaultAddressParser : EntityParser<User_DefaultAddress>= {
  Id: parseNumber,
      UserId: parseNumber,
      DefaultAddressId: parseNumber,
  DefaultAddress: p => parseCollection(x => parseEntity(x, p)),
      User: p => parseCollection(x => parseEntity(x, p)),
}


const User_DefaultAddressOrderBy : (p: string) => OrderByBuilderComplex<User_DefaultAddress> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      UserId:  new OrderByProp(p ? p + "/UserId" : "UserId"),
      DefaultAddressId:  new OrderByProp(p ? p + "/DefaultAddressId" : "DefaultAddressId"),
  DefaultAddress:  p => DefaultAddressOrderBy(p ? p + "/DefaultAddress" : "DefaultAddress"),
      User:  p => UserOrderBy(p ? p + "/User" : "User"),
})



export type WaitingTimes = {
    Id: number
    CreatedDate: Date
    DeliveryTimeMin: number
    DeliveryTimeMax: number
    PickupTimeMin: number
    PickupTimeMax: number
} & {
  Restaurant_WaitingTimes: List<Restaurant_WaitingTimes>
}


function FilterBuilderWaitingTimes(prefix?: string) : FilterBuilderComplex<WaitingTimes> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      CreatedDate: new FilterBuilderDate(mk_prefix('CreatedDate', prefix)),
      DeliveryTimeMin: new FilterBuilderNumber(mk_prefix('DeliveryTimeMin', prefix)),
      DeliveryTimeMax: new FilterBuilderNumber(mk_prefix('DeliveryTimeMax', prefix)),
      PickupTimeMin: new FilterBuilderNumber(mk_prefix('PickupTimeMin', prefix)),
      PickupTimeMax: new FilterBuilderNumber(mk_prefix('PickupTimeMax', prefix)),
    Restaurant_WaitingTimes: new FilterBuilderCollection<Restaurant_WaitingTimes>(mk_prefix('Restaurant_WaitingTimes', prefix), s => FilterBuilderRestaurant_WaitingTimes(s)),
  }
}


const WaitingTimesRelationBuilder: RelationBuilder<RelationsOf<WaitingTimes>> = {
Restaurant_WaitingTimes: () => mk_rel_query(
    'Restaurant_WaitingTimes',
    FilterBuilderRestaurant_WaitingTimes(),
    Restaurant_WaitingTimesRelationBuilder,
    Restaurant_WaitingTimesParser
  ),

}


const  WaitingTimesParser : EntityParser<WaitingTimes>= {
  Id: parseNumber,
      CreatedDate: parseDate,
      DeliveryTimeMin: parseNumber,
      DeliveryTimeMax: parseNumber,
      PickupTimeMin: parseNumber,
      PickupTimeMax: parseNumber,
  Restaurant_WaitingTimes: p => parseCollection(x => parseEntity(x, p)),
}


const WaitingTimesOrderBy : (p: string) => OrderByBuilderComplex<WaitingTimes> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      CreatedDate:  new OrderByProp(p ? p + "/CreatedDate" : "CreatedDate"),
      DeliveryTimeMin:  new OrderByProp(p ? p + "/DeliveryTimeMin" : "DeliveryTimeMin"),
      DeliveryTimeMax:  new OrderByProp(p ? p + "/DeliveryTimeMax" : "DeliveryTimeMax"),
      PickupTimeMin:  new OrderByProp(p ? p + "/PickupTimeMin" : "PickupTimeMin"),
      PickupTimeMax:  new OrderByProp(p ? p + "/PickupTimeMax" : "PickupTimeMax"),
  Restaurant_WaitingTimes:  p => Restaurant_WaitingTimesOrderBy(p ? p + "/Restaurant_WaitingTimes" : "Restaurant_WaitingTimes"),
})



export type DataProtectionKey = {
    Id: number
    FriendlyName: string | null
    Xml: string | null
} & {

}


function FilterBuilderDataProtectionKey(prefix?: string) : FilterBuilderComplex<DataProtectionKey> {
  return {
    Id: new FilterBuilderNumber(mk_prefix('Id', prefix)),
      FriendlyName: new FilterBuilderString(mk_prefix('FriendlyName', prefix)),
      Xml: new FilterBuilderString(mk_prefix('Xml', prefix)),
    
  }
}


const DataProtectionKeyRelationBuilder: RelationBuilder<RelationsOf<DataProtectionKey>> = {

}


const  DataProtectionKeyParser : EntityParser<DataProtectionKey>= {
  Id: parseNumber,
      FriendlyName: parseNullString,
      Xml: parseNullString,
  
}


const DataProtectionKeyOrderBy : (p: string) => OrderByBuilderComplex<DataProtectionKey> = p => ({
  Id:  new OrderByProp(p ? p + "/Id" : "Id"),
      FriendlyName:  new OrderByProp(p ? p + "/FriendlyName" : "FriendlyName"),
      Xml:  new OrderByProp(p ? p + "/Xml" : "Xml"),
  
})

