import * as React from "react"
import { Widget, mk_widget } from "widgets-for-react"

export const compositionHomepage: Widget<never> = mk_widget({
  run: _ => (
    <section id="compositions-homepage" className="styleguide-section styleguide-section--compositions">
      <header className="styleguide--styled styleguide-section__header">
        <h3 className="styleguide-section__title">Homepage</h3>
        <p className="styleguide-section__intro"></p>
      </header>

      <div className="styleguide-section__content">
        <section className="styleguide-part">
          <header className="styleguide--styled styleguide-part__header"></header>

          <div className="styleguide-part__content styleguide-part__content--grey">
            <div className="styleguide--styled styleguide-part__intro">
              <p className="styleguide-part__description"></p>
            </div>

            <section className="styleguide-example">
              <div className="styleguide--styled">
                <h5 className="styleguide-example__title">Example(s)</h5>
              </div>

              <div className="styleguide-example__section cf">
                <div className="fs">
                  <div className="fs__inner">
                    {/* Implement composition (collection of components to represent a full page) here! */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </section>
  )
})

export const styleguideCompositions: Widget<never> = mk_widget({
  run: _ => (
    <>
      <section id="compositions" className="styleguide-chapter styleguide-chapter--compositions">
        <header className="styleguide--styled styleguide-chapter__header">
          <h2 className="styleguide__title styleguide-chapter__title">Design Compositions</h2>
          <p className="styleguide-chapter__intro"></p>
        </header>

        <div className="styleguide-chapter__content">
          {compositionHomepage.run(_)}
        </div>
      </section>
    </>
  )
})
