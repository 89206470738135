import * as React from "react"
import { Action, IOWidget, mk_widget } from "widgets-for-react"
import { CMSState } from "./state"
import { CookieSensitiveContentWrapper } from "../public_site/cookiebar";

export type IframeBlockProps = {
  link: string
  height: number
}

export type IframeBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
}>

const getClassName = (
  options: { style?: IframeBlockStyle },
  className: keyof IframeBlockStyle,
  notset: string = ""
): string =>
  options && options.style && (options.style[className] || options.style[className] == null) ? options.style[className] : notset

export function IframeBlock<CustomBlockData, extra_images>(
  props: IframeBlockProps,
  options?: { style?: IframeBlockStyle; key?: string }
): IOWidget<
  CMSState<CustomBlockData, extra_images>,
  Action<CMSState<CustomBlockData, extra_images>>
> {
  return (s: CMSState<CustomBlockData, extra_images>) =>
    mk_widget({
      run: onDone => (
        <section className={getClassName(options, "block_classname", "container")} key={options && options.key ? options.key : "iframe-block"}>
          <div className={getClassName(options, "inner_classname", "py-5")}>
            <CookieSensitiveContentWrapper
              placeholder={(
                  <p className="cookiebar-placeholder__text text--styled">
                    Je kunt deze inhoud alleen bekijken als je de cookies accepteert. <a href="/cookies">Meer informatie over cookies</a>.
                </p>
              )}>
                <iframe src={props.link} height={props.height} />
              </CookieSensitiveContentWrapper>
          </div>
        </section>
      )
    })
}