import { ResetPasswordRequestData, ChangePasswordState, LoginState, ActivateAccountData, ResetPasswordData, SignUpData, LoginData, LoggedInState, LoginDataTwoFactorData, PrivacyKind, ChooseRoleData, ResendActivationData, VerifyResetPasswordTokenData, BasicCredentials, emptyLoginData, SessionSetter, User, ChangePasswordData } from './state';
import { Widget, promise, Action, loadingAsyncState } from 'widgets-for-react'
import { signUpApi } from '../../portal_generated_api';
import { Api } from "./mocked_api";
import { getUser } from '../../custom_components/order_flow/server-communication/data_retrieval';

export type Api<a> = {
  request_reset_password : (email: string) => Promise<Api.ResetPasswordRequestResult> | undefined
  resend_account_activation : (x: ResendActivationData) => Promise<Api.ResendActivationResult> | undefined
  activate_account : (aad:ActivateAccountData, appState: a, setSession: SessionSetter, rootpath:string) => Promise<Api.ActivateAccountResult> | undefined
  verifyResetPasswordToken : (x: VerifyResetPasswordTokenData) => Promise<Api.ResetPasswordResult> | undefined
  reset_password : (x: ResetPasswordData) => Promise<Api.ResetPasswordResult> | undefined
  sign_up : (x: SignUpData) => Promise<Api.SignUpResult> | undefined
  login : (x: BasicCredentials & {role?: string}, useTwoFactor?: string | false) => Promise<Api.LoginResult> | undefined
  request_new_pin : (x: LoginDataTwoFactorData) => Promise<Api.TwoFactorResult> | undefined
  logout : (u: User, s: string, rootpath:string) => Promise<{}> | undefined
  change_pasword : (u: User, cpd: ChangePasswordData) => Promise<Api.ChangePasswordResult> | undefined
  scorePassword: (i:string) =>  {score:number, res: "strong" | "almost good" | "weak"} | undefined
  isValidEmail: (i:string) => boolean | undefined
}

export let getMockedApi = <a>() : Api<a> => ({
  request_reset_password : Api.request_reset_password,
  resend_account_activation : Api.resend_account_activation,
  activate_account : Api.activate_account,
  verifyResetPasswordToken : Api.verify_reset_password_token,
  reset_password : Api.reset_password,
  sign_up : signUpApi,
  login:Api.login,
  request_new_pin:Api.request_new_pin,
  logout:Api.logout,
  change_pasword:Api.change_pasword,
  scorePassword: Api.scorePassword,
  isValidEmail: Api.is_valid_email
})

export let reset_password_request = <a> (api : Api<a>, rprd:ResetPasswordRequestData, security_kind:PrivacyKind) : Widget<Action<LoginState<a>>> =>
  api.request_reset_password == undefined ? null :
  promise<{}, Action<LoginState<a>>>(_ => api.request_reset_password(rprd.email!).then<Action<LoginState<a>>>(res =>
    (s:LoginState<a>) : LoginState<a> =>
      res.status == "success" ? {status:"reset password request - success", login_data: rprd }
      : res.status == "error email not valid" ? {status:"reset password request - error email not valid", login_data:rprd }
      : res.status == "error email not found" && security_kind == "strict" && console.warn("Inconsistent api result: this might lead to a privacy issue") == undefined ? {status:"reset password request - success", login_data: rprd }
      : res.status == "error email not found" && security_kind == "standard" ? {status:"reset password request - error email not found", login_data: rprd }
      : res.status == "error account not activated" ? {status:"reset password request - error user not activated", login_data:rprd }
      : res.status == "error too many attempts" ? {status: "reset password request - error too many attempts", login_data: rprd }
      : {status:"reset password request - error email not found", login_data:rprd }),
    { key: "login-widget-reset-password-request-promise", on_fail:reason => (s => ({status:"reset password request - api error", login_data:rprd })) }
  )({})
 
export let resend_account_activation = <a>(api : Api<a>, rad: ResendActivationData): Widget<Action<LoginState<a>>> => 
    api.resend_account_activation == undefined ? null :
    promise<{}, Action<LoginState<a>>>(_ => api.resend_account_activation(rad).then<Action<LoginState<a>>>( res =>
      (s:LoginState<a>): LoginState<a> =>
        res.status == "success" ? { status: "resend activation - success", login_data: rad }
        : res.status == "error too many attempts" ? { status: "resend activation - error too many attempts", login_data: rad }
        : { status: "resend activation - api error", login_data: rad }
      ),
      { on_fail:reason => s => ({status:"resend activation - api error", login_data: rad}) }
    )({})

export let activate_account = <a>(api : Api<a>, aad:ActivateAccountData, appState: a, setSession: SessionSetter, rootpath:string) : Widget<Action<LoginState<a>>> =>
  api.activate_account == undefined ? null :
  promise<{}, Action<LoginState<a>>>(_ => api.activate_account(aad, appState, setSession, rootpath).then<Action<LoginState<a>>>(res =>
    (s:LoginState<a>) : LoginState<a> => {
      if (res.status == "success") {
        setSession(res.user, res.session, res.role)
        window.location.href = rootpath + "/"
        return {status:"logged in - activation success", user: res.user, role: res.role, session: res.session, appState, info: loadingAsyncState(() => getUser(res.user.username))}
      }
      return res.status == "token expired" ? {status: "resend activation - error token expired", login_data: { email: res.email } }
      : res.status == "account already active" ? {status: "logged out - account already active", login_data: emptyLoginData}
      : res.status == "token not found" ? {status:"logged out - error account activation token not found", login_data: emptyLoginData }
      : res.status == "error too many attempts" ? {status: "logged out - error too many account activation attempts", login_data: emptyLoginData }
      : {status:"logged out - login not authorized", login_data:emptyLoginData }
    }),
    { key: "login-widget-activate-account-promise", on_fail:reason => (s => ({status:"activate account - api error", login_data:aad })) }
  )({})

export let verify_reset_password_token =  <a>(api : Api<a>, vtd:VerifyResetPasswordTokenData) : Widget<Action<LoginState<a>>> => 
  api.verifyResetPasswordToken == undefined ? null :
  promise<{}, Action<LoginState<a>>>(_ => api.verifyResetPasswordToken(vtd).then<Action<LoginState<a>>>(res =>
    (s:LoginState<a>) : LoginState<a> =>
      ( res.status == "error too many attempts" ? {status:"logged out - error too many reset password attempts", login_data: emptyLoginData }
      : res.status == "error token has expired" ? {status:"logged out - error reset password token has expired", login_data: emptyLoginData }
      : res.status == "error token not found" ? {status:"logged out - error reset password token not found", login_data: emptyLoginData }
      : {status:"reset password", login_data:{...vtd, password: "", confirmed_password: ""} })),
    { key: "login-widget-verify-reset-password-token-promise", on_fail:reason => (s => ({status:"verify reset password token - api error", login_data:{...vtd, password: "", confirmed_password: ""} })) }
  )({})

export let reset_password = <a>(api : Api<a>, rpd:ResetPasswordData) : Widget<Action<LoginState<a>>> =>
  api.reset_password == undefined ? null :
  promise<{}, Action<LoginState<a>>>(_ => api.reset_password(rpd).then<Action<LoginState<a>>>(res =>
    (s:LoginState<a>) : LoginState<a> =>
      ( res.status == "success" ? {status:"reset password success", login_data: {username: rpd.email, password: rpd.password || ""} }
      : res.status == "error token not found" ? {status:"logged out - error reset password token not found", login_data: emptyLoginData }
      : res.status == "error token has expired" ? {status:"logged out - error reset password token has expired", login_data:emptyLoginData }
      : res.status == "error too many attempts" ? {status:"reset password - error too many attempts", login_data:rpd }
      : {status:"reset password - error weak password", login_data:rpd })),
    { key: "login-widget-reset-password-promise", on_fail:reason => (s => ({status:"reset password - api error", login_data:rpd })) }
  )({})

export let login = <a>(api : Api<a>, ld:LoginData, security_kind:PrivacyKind, appState:a, setSession: SessionSetter) : Widget<Action<LoginState<a>>> =>
  api.login == undefined ? null :
  promise<{}, Action<LoginState<a>>>(_ => api.login(ld).then<Action<LoginState<a>>>(res =>
    (s:LoginState<a>) : LoginState<a> => {
      if (res.status == "success") {
        setSession(res.user, res.session, ld.role ? ld.role : res.role)
        return {status:"logged in", user:res.user, session:res.session, role: res.role, appState:appState, info: loadingAsyncState(() => getUser(res.user.username)) }
      }
      return ((security_kind == "strict" &&  res.status == "error account not active" && console.warn("Inconsistent api result: this might lead to a privacy issue") == undefined)) ||
        security_kind == "standard" && res.status == "error account not active" ? {status:"logged out - error account not active", login_data:{...ld, email: res.email} }
      : res.status == "proceed with two factor" ? {status:"two factor", login_data:{password:ld.password, username:ld.username, pin:""} }
      : res.status == "error too many attempts" ? {status:"logged out - too many attempts", login_data:ld }
      : res.status == "choose role" ? {status:"choose role", login_data:{username: ld.username, password: ld.password, roles:res.roles} }
      : {status:"logged out - login not authorized", login_data:ld }
    }),
    { key: "login-widget-login-promise", on_fail:reason => s => ({status:"logged out - login api error", login_data:ld }) }
  )({})

export let send_new_pin = <a>(api : Api<a>, ld:LoginDataTwoFactorData) : Widget<Action<LoginState<a>>> => 
  api.request_new_pin == undefined ? null :
  promise<{}, Action<LoginState<a>>>(_ => api.request_new_pin(ld).then<Action<LoginState<a>>>(res =>
    (s:LoginState<a>) : LoginState<a> =>
      (res.status == "success" ?  {status:"two factor", login_data:ld }
      : res.status == "error too many attempts" ?  {status:"two factor - too many attempts", login_data:ld }
      : {status:"logged out - login not authorized", login_data:{show_password:false, password:ld.password, username:ld.username} })),
    { on_fail:reason => s => ({status:"two factor - resend api error", login_data:{...ld, pin:""} }) }
  )({})

export let login_two_factor = <a>(api : Api<a>, ld:LoginDataTwoFactorData, appState:a, setSession: SessionSetter) : Widget<Action<LoginState<a>>> =>
  api.login == undefined ? null :
  promise<{}, Action<LoginState<a>>>(_ => api.login(ld, ld.pin).then<Action<LoginState<a>>>(res =>
    (s:LoginState<a>) : LoginState<a> => {
      if (res.status == "success") {
        setSession(res.user, res.session, res.role)
        return {status:"logged in", user:res.user, session:res.session, role: res.role, appState, info: loadingAsyncState(() => getUser(res.user.username)) }
      }
      return res.status == "error two factor empty code" ? {status:"two factor - empty code", login_data:ld}
      : res.status == "error too many attempts" ? {status:"two factor - too many attempts", login_data:ld}
      : res.status == "error two factor wrong code" ? {status:"two factor - wrong code", login_data:ld }
      : res.status == "choose role" ? {status:"choose role", login_data: {...ld, roles: res.roles}}
      : {status:"logged out - login not authorized", login_data:{username: ld.username, password: ld.password, show_password: false} }
    }),
    { key: "login-widget-login-two-factor-promise", on_fail:reason => (s => ({status:"two factor - login api error", login_data:ld })) }
  )({})

export let login_choose_role = <a>(api : Api<a>, ld:ChooseRoleData, appState:a, setSession: SessionSetter) : Widget<Action<LoginState<a>>> =>
  api.login == undefined ? null :
  promise<{}, Action<LoginState<a>>>(_ => api.login(ld, ld.pin).then<Action<LoginState<a>>>(res =>
    (s:LoginState<a>) : LoginState<a> => {
      if (res.status == "success") {
        setSession(res.user, res.session, ld.role ? ld.role : res.role)
        return {status:"logged in", user:res.user, session:res.session, role: res.role, appState, info: loadingAsyncState(() => getUser(res.user.username)) }
      }
      return res.status == "proceed with two factor" ? {status:"two factor", login_data:{password:ld.password, username:ld.username, pin:""} }
      : res.status == "error account not active" ? {status:"logged out - error account not active", login_data:{username: ld.username, password: ld.password, show_password: false} }
      : res.status == "choose role" ? {status: "choose role - error no role selected", login_data: {...ld, roles: res.roles} }
      : res.status == "error choose role incompatible role" ? {status: "choose role - error incompatible role for user", login_data: {...ld, roles: res.roles, role: undefined} }
      : res.status == "error too many attempts" ? {status: "choose role - error too many attempts", login_data: ld }
      : {status:"logged out - login not authorized", login_data:{username: ld.username, password: ld.password, show_password: false} }
    }),
    { key: "login-widget-login-choose-role-promise", on_fail:reason => (s => ({status:"choose role - api error", login_data:ld })) }
  )({})

export let basic_login = <a>(api : Api<a>, ld:BasicCredentials, appState:a, setSession: SessionSetter, on_fail: (reason: any) => Action<LoginState<a>>, rootpath:string) : Widget<Action<LoginState<a>>> =>
  api.login == undefined ? null :
  promise<{}, Action<LoginState<a>>>(_ => api.login(ld).then<Action<LoginState<a>>>(res =>
    (s: LoginState<a>): LoginState<a> => {
      if (res.status == "success") {
        setSession(res.user, res.session, res.role)
        window.location.href = rootpath + "/"
        return {status: "logged in", user: res.user, session: res.session, role: res.role, appState, info: loadingAsyncState(() => getUser(res.user.username)) }
      }
      if (res.status == "proceed with two factor") return {status: "two factor", login_data:{password:ld.password, username:ld.username, pin:""} }
      if (res.status == "choose role") return {status: "choose role", login_data: {...ld, roles: res.roles} }
      if (res.status == "error too many attempts") return {status:"logged out - too many attempts", login_data:{username: ld.username, password: ld.password, show_password: false} }
      if (res.status == "error account not active") return {status:"logged out - error account not active", login_data:{username: ld.username, password: ld.password, show_password: false} }
      return {status:"logged out - login not authorized", login_data:{username: ld.username, password: ld.password, show_password: false} }
    }),
    { key: "login-widget-basic-login-promise", on_fail }
  )({})

export let logout = <a>(api : Api<a>, s0:LoggedInState<a>, appState:a, setSession: SessionSetter, rootpath:string) : Widget<Action<LoginState<a>>> =>
  api.logout == undefined ? null :
  promise<{}, Action<LoginState<a>>>(_ => api.logout(s0.user, s0.session, rootpath).then<Action<LoginState<a>>>(res =>
    (s:LoginState<a>) : LoginState<a> => {
      setSession(undefined, undefined, undefined)
      window.location.href = "/"
      return {status:"logged out", login_data:{ username:"", password:"", show_password:false } }
    }),
    { key: "login-widget-logout-promise", on_fail:reason => s => ({status:"logged in - logout api error", user:s0.user, session:s0.session, role: s0.role, appState, info: loadingAsyncState(() => getUser(s0.user.username)) }) }
  )({})

export let change_password = <a>(api : Api<a>, s0: ChangePasswordState<a>) : Widget<Action<LoginState<a>>> =>
  api.change_pasword == undefined ? null :
  promise<{}, Action<LoginState<a>>>(_ => api.change_pasword(s0.user, s0.change_password_data).then<Action<LoginState<a>>>(res =>
    (s: LoginState<a>): LoginState<a> => s.status != "change password - sending" ? s :
      res.status == "success" ? {...s, status: "change password - success"}
      : res.status == "error incorrect password" ? {...s, status: "change password - error incorrect password"}
      : res.status == "error missing fields" ? {...s, status: "change password - error missing fields"}
      : res.status == "error passwords do not match" ? {...s, status: "change password - error passwords do not match"}
      : res.status == "error too weak password" ? {...s, status: "change password - error too weak password"}
      : {...s, status: "change password - error incorrect password"}
    ),
    { key: "login-widget-change-password-promise", on_fail: reason => s => s.status == "change password - sending" ? ({...s, status: "change password - api error"}) : s}
  )({})

export interface NewUserObject extends BasicCredentials {
  email:string,
  name:string,
  surname:string,
  errors: boolean
}

export let sign_up = <a>(api : Api<a>, sud:SignUpData, security_kind: PrivacyKind, appState: a, setSession: SessionSetter) : Widget<Action<LoginState<a>>> => 
  signUpApi == undefined ? null :
  promise<{}, Action<LoginState<a>>>(_ => signUpApi(sud).then<Action<LoginState<a>>>(res =>
    (s:LoginState<a>) : LoginState<a> => {
      return res.status == "success inactive" ? {status: "resend activation - signup success", login_data: sud}
      : res.status == "too weak password" ? {status: "sign up - error weak password", login_data: sud}
      : res.status == "email invalid" ? {status: "sign up - error invalid email", login_data: sud}
      : (res.status == "username invalid" && security_kind == "strict" && console.warn("Inconsistent api result: this might lead to a privacy issue") == undefined) ||
         res.status == "username invalid" ? {status: "sign up - error invalid username", login_data: sud}
      : (res.status == "account already exists" && security_kind == "strict" && console.warn("Inconsistent api result: this might lead to a privacy issue") == undefined) ||
         res.status == "account already exists" ? {status: "sign up - error account already exists", login_data: sud}
      : (res.status == "account not active" && security_kind == "strict" && console.warn("Inconsistent api result: this might lead to a privacy issue") == undefined) ||
         res.status == "account not active" ? {status: "sign up - error account not active", login_data: sud}
      : res.status == "error too many attempts" ? {status: "sign up - error too many attempts", login_data: sud}
      : {status:"sign up - error", login_data:sud }
    }),
    { on_fail:reason => (s => ({status:"sign up - api error", login_data:sud })) }
  )({})