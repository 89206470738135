import * as React from "react"
import { Action, IOWidget, mk_widget, navLink } from "widgets-for-react"
import { CMSState } from "./state"
import { Url } from "./types"
import { link } from "./utils"

export type CtaBlockProps = {
  title: string
  intro: string
  buttons: "none" | "primary" | "primary_secondary"
  primary_button?: ({
    kind: "link"
    is_link_external: boolean
    target: "_blank" | "_self"
    url: Url
  }) & { title: string }
  secondary_button?: ({
    kind: "link"
    is_link_external: boolean
    target: "_blank" | "_self"
    url: Url
  }) & { title: string }
}

export type CtaBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
  header_classname: string
  title_classname: string
  content_classname: string
  text_classname: string
  footer_classname: string
  clickable_primary_classname: string
  clickable_secondary_classname: string
}>

const getClassName = (
  options: { style?: CtaBlockStyle },
  className: keyof CtaBlockStyle,
  notset: string = ""
): string =>
  options && options.style && (options.style[className] || options.style[className] == null) ? options.style[className] : notset

export function CtaBlock<customBlockData, extra_images>(
  props: CtaBlockProps,
  options?: { style?: CtaBlockStyle; key?: string }
): IOWidget<CMSState<customBlockData, extra_images>, Action<CMSState<customBlockData, extra_images>>> {
  return (s: CMSState<customBlockData, extra_images>) =>
    mk_widget({
      run: _ => (
        <section className={getClassName(options, "block_classname", "container")} key={options && options.key ? options.key : "cta-block"}>
          <div className={getClassName(options, "inner_classname", "py-5")}>
            { props.title &&
              <header className={getClassName(options, "header_classname", "")}>
                <h2 className={getClassName(options, "title_classname", "text-center mb-3")}>{props.title}</h2>
              </header>
            }

            { props.intro &&
              <div className={getClassName(options, "content_classname", "")}>
                <p className={getClassName(options, "text_classname", "lead text-center")}>{props.intro}</p>
              </div>
            }

            {props.buttons !== "none"
              ? <footer className={getClassName(options, "footer_classname", "text-center")}>
                  {props.primary_button && props.primary_button.title && props.primary_button.url
                    ? link(props.primary_button,
                          {classname: getClassName(options, "clickable_primary_classname", "button mr-2" )},
                          props.primary_button.title)
                    : null
                  }
                  {props.secondary_button && props.secondary_button.title && props.secondary_button.url && props.buttons === "primary_secondary"
                    ? link(props.secondary_button,
                          {classname: getClassName(options, "clickable_secondary_classname", "button button-link" )},
                          props.secondary_button.title)
                    : null
                  }
                </footer>
              : null
            }
          </div>
        </section>
      ),
    })
}