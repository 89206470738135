import * as React from "react"
import { Action, IOWidget, mk_widget, navLink } from "widgets-for-react"
import { CMSState } from "./state"
import { Url, Link } from "./types"
import { link } from "./utils"

export type LinkBlockProps = {
  title: string
  intro?: string
  items: Link[]
}

export type LinkBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
  header_classname: string
  title_classname: string,
  intro_classname: string,
  content_classname: string
  list_classname: string,
  item_classname: string
  link_classname: string
}>

export type LinkProps = { key?: string; title: string; url: string, target?: "_blank" | "_self" }

const getClassName = (
  options: { style?: LinkBlockStyle },
  className: keyof LinkBlockStyle,
  notset: string = ""
): string =>
  options && options.style && (options.style[className] || options.style[className] == null) ? options.style[className] : notset

export function LinkBlock<customBlockData, extra_images> (
  props: LinkBlockProps,
  options?: {
    style?: LinkBlockStyle
    key?: string
  }
): IOWidget<CMSState<customBlockData, extra_images> , Action<CMSState<customBlockData, extra_images> >> {
  return (s: CMSState<customBlockData, extra_images> ) =>
    mk_widget({
      run: _ => (
        <section className={getClassName(options, "block_classname", "container")} key={options && options.key ? options.key : "link-block"}>
          <div className={getClassName(options, "inner_classname", "py-5")}>
            <header className={getClassName(options, "header_classname", "")}>
              {props.title && (<h2 className={getClassName(options, "title_classname", "mb-3")}>{props.title}</h2>)}
              {props.intro && (<p className={getClassName(options, "intro_classname", "")}>{props.intro}</p>)}
            </header>

            <div className={getClassName(options, "content_classname", "")}>
              <ul className={getClassName(options, "list_classname", "list-unstyled mb-0 row")}>
                {props.items.map(
                  (l, key) => (
                    <li className={getClassName(options, "item_classname", "col-md-6")} key={`${l.url}-${key}`}>
                      {l.title && l.url
                        ? link(
                            l,
                            {classname: getClassName(options, "link_classname", "button button-link w-100 text-left border-bottom rounded-0 pl-0")},
                            `${l.title}`
                          )
                        : null
                      }
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </section>
      ),
    })
}