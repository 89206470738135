import {Action, any, IOWidget, mk_pair, nothing, Option, some, Widget} from "widgets-for-react";
import { CustomPages, router } from "./router_state";
import { PairAsyncImage } from "./types/images";
import { AppState, DefaultState, SetCurrentWidget } from "./types/state";
import { StandardBlockImagesLoader, StandardBlockToWidget } from "./types/utils";
import PageTemplateComponent from "./PageTemplate";



function Object_values<t extends object>(obj: t) {
  let vals: t[Extract<keyof t, string>][] = [];
  for (const prop in obj) {

    vals.push(obj[prop]);
  }
  return vals;
}

export const EntryClient = <
  CustomBlockData extends { kind: CustomBlockData["kind"] },
  CustomBlocks extends { kind: CustomBlocks["kind"] },
  StandardBlocks,
  CurrentState extends DefaultState<
    CustomBlockData,
    CustomBlocks,
    StandardBlocks,
    extra_images
  >,
  ContentTypes extends keyof CurrentState,
  CustomRoutes extends {kind:ContentTypes},
  extra_images 
>(props: {
  rootpath:string,
  rootpath_to_ignore:string,
  initState: CurrentState;
  custom_pages: CustomPages<
    CustomBlockData,
    CustomBlocks,
    StandardBlocks,
    CurrentState,
    ContentTypes,
    CustomRoutes,
    extra_images
  >;
  StandardBlockImagesLoader: StandardBlockImagesLoader<
    CustomBlocks,
    StandardBlocks,
    extra_images
  >;
  StandardBlock_To_Widget: StandardBlockToWidget<
    CustomBlockData,
    CustomBlocks,
    StandardBlocks,
    extra_images
  >;
  errorPage: Widget<never>;
  loadingPage: Widget<never>;
  acceptAllCookies?: () => void;
  getCookie?: (name: string) => string;
  videoCookie?: string;
  CustomBLockImagesLoader?: (block: CustomBlocks) => Array<PairAsyncImage<extra_images>>;
  CustomBlock_To_Widget?: (block: CustomBlocks, index: number) => Option<CustomBlockData>;
}) => (s0: AppState<CurrentState, CustomRoutes>) =>
    any<Action<AppState<CurrentState, CustomRoutes>>>()([
      //app
      s0.current_widget.v.kind == "r"
        ? s0.current_widget.v
          .v(s0.state)
          .map<Action<AppState<CurrentState, CustomRoutes>>>(
            a => s1 => {
              return {
                ...s1,
                state: a(s1.state),
              };
            }
          )
        : nothing(),
      //router
      router<CurrentState, CustomRoutes>(
        Object_values(props.custom_pages).map(e => e.route),
        props.rootpath,
        props.rootpath_to_ignore
      ).map<Action<AppState<CurrentState, CustomRoutes>>>(
        newRoute => s1 => {
          let oldRoute = s1.route;
          let page = newRoute as { kind: "page"; slug: string };
          let pageWidget: IOWidget<
            CurrentState,
            Action<CurrentState>
          > = s2 => {
            return PageTemplateComponent<
              StandardBlocks,
              CustomBlocks,
              CustomBlockData,
              extra_images
            >({
              pageDisplay: p => s2.page.pagedisplay(p),
              Display: b => s2.page.display(b),
              CustomBlock_To_Widget: props.CustomBlock_To_Widget,
              CustomBLockImagesLoader: props.CustomBLockImagesLoader,
              StandardBlockImagesLoader: props.StandardBlockImagesLoader,
              StandardBlock_To_Widget: props.StandardBlock_To_Widget,
              errorPage: props.errorPage,
              loadingPage: props.loadingPage,
              acceptAllCookies: props.acceptAllCookies,
              getCookie: props.getCookie,
              videoCookie: props.videoCookie,
            })({
              ...s2.page,
              page: s2.page.page.kind == "loading" || s2.page.page.kind == "unloaded"
                ? s2.page.get_page(page.slug)
                : s2.page.page
            } as any).map<Action<CurrentState>>(a => s3 => {
              //@ts-ignore
              return { ...s3, page: a(s3.page) };
            });
          };

          switch (newRoute.kind) {
            case "404":
              return SetCurrentWidget(
                s1,
                newRoute,
                some<IOWidget<CurrentState, Action<CurrentState>>>(_ =>
                  props.errorPage
                )
              );

            case "page": {
              return SetCurrentWidget(s1, newRoute, some(pageWidget));
            }
            case "ignore": {
              let widget: IOWidget<CurrentState, Action<CurrentState>> = _ => nothing()
              return {...s1, 
                current_widget: some(widget),
                route: newRoute}
            }

            case 'init': {
              return SetCurrentWidget(s1, newRoute, some(pageWidget));
            }

            // custom pages
            default: {
              let currentCustomPage = props.custom_pages[newRoute.kind];
              let widget: IOWidget<CurrentState, Action<CurrentState>> = s2 =>
                currentCustomPage
                  .widget(mk_pair(newRoute, currentCustomPage.getState(s2)))
                  .map<Action<CurrentState>>(a => s3 =>
                    currentCustomPage.setState(
                      s3,
                      a(currentCustomPage.getState(s3))
                    )
                  );
              return {
                ...s1,
                route: newRoute,
                current_widget: some(widget),
                state: currentCustomPage.updateStateBasesOnRoute(
                  s1.state,
                  newRoute
                ),
              };
            }
          }
        }
      ),
    ])
