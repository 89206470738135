import { LoginState, ResetPasswordSuccessState, isResetPasswordSuccessState } from "../state";
import { Widget, Action, button, string, div, hn } from "widgets-for-react";
import i18next = require('i18next');

export let reset_password_success = <a>(s0: ResetPasswordSuccessState, readonly: boolean): Widget<Action<LoginState<a>>> =>
  div<Action<LoginState<a>>>({ className: "login", key: "login-widget-reset-pasword-success" })(
    div<Action<LoginState<a>>>({ className: "login__inner" })([
      hn<Action<LoginState<a>>>(1, i18next.t('reset_password_success:password_changed'), { className: "login__title title--l" })(),
      string({ readonly: true, readonly_element: "p", key: "reset-password-success-text" })(i18next.t('reset_password_success:password_changed_text')).never(),
      button<Action<LoginState<a>>>(i18next.t('reset_password_success:login'), { key: "reset-password-success-button", className: "button button--primary button--full-width", disabled: readonly })(() => s => isResetPasswordSuccessState(s) ? { ...s, status: "reset password success - logging in" } : s)
    ])
  )
