import { ActivateAccountState, LoginState, LoggedOutState, LoggedInState, ResendActivationState, ResetPasswordState, ResetPasswordSuccessState, VerifyResetPasswordTokenState, ResetPasswordRequestState, SignUpState, LoginTwoFactorState, ChangePasswordState, ChooseRoleState, isLoginTwoFactorState, Role, isChooseRoleState, PortalAppState, isLoggedInState } from './state';
import { Action, Widget, any, div, label, string, button, a, nothing, dropdown, link, hn, IOWidget } from "widgets-for-react";
import { Api } from './api_to_state';
import { login_form } from './pages/login_form';
import { resend_activation_form } from './pages/resend_activation_form';
import { resend_activation_success } from './pages/resend_activation_success';
import { change_password_form } from './pages/change_password_form';
import { reset_password_request_form } from './pages/reset_password_request_form';
import { activate_account_form } from './pages/activate_account_form';
import { reset_password_form } from './pages/reset_password_form';
import { sign_up_form } from './pages/sign_up_form';
import { activate_account_success } from './pages/activate_account_success';
import { signup_success } from './pages/signup_success';
import { reset_password_request_success } from './pages/reset_password_request_success';
import { reset_password_success } from './pages/reset_password_success';

export type PagesRenderer<a> = {
  activate_account_form: (s0: ActivateAccountState, rootpath: string, readonly: boolean) => Widget<Action<LoginState<a>>>
  login_form: (s0: LoggedOutState, readonly: boolean, rootpath: string) => Widget<Action<LoginState<a>>>
  activate_account_success: (s0: LoggedInState<a>, readonly: boolean) => Widget<Action<LoginState<a>>>
  resend_activation_form: (s0: ResendActivationState, readonly: boolean, rootpath: string, api?: Api<a>) => Widget<Action<LoginState<a>>>
  resend_activation_success: (s0: ResendActivationState, rootpath: string) => Widget<Action<LoginState<a>>>
  reset_password_form: (s0: ResetPasswordState, readonly: boolean, rootpath: string, api?: Api<a>) => Widget<Action<LoginState<a>>>
  reset_password_success: (s0: ResetPasswordSuccessState, readonly: boolean) => Widget<Action<LoginState<a>>>
  verify_reset_password_form: (s0: VerifyResetPasswordTokenState, rootpath: string) => Widget<Action<LoginState<a>>>
  reset_password_request_form<a>(s0: ResetPasswordRequestState, readonly: boolean, rootpath: string, api?: Api<a>): Widget<Action<LoginState<a>>>
  reset_password_request_success<a>(s0: ResetPasswordRequestState, rootpath: string): Widget<Action<LoginState<a>>>
  sign_up_form: (s0: SignUpState, readonly: boolean, rootpath: string, api?: Api<a>) => Widget<Action<LoginState<a>>>
  signup_success: (s0: ResendActivationState, readonly: boolean, rootpath: string, api?: Api<a>) => Widget<Action<LoginState<a>>>
  login_two_factor_form: (s0: LoginTwoFactorState, readonly: boolean) => Widget<Action<LoginState<a>>>
  change_password_form: (s0: ChangePasswordState<a>, readonly: boolean, rootpath: string) => Widget<Action<LoginState<a>>>
  change_password_success: (s0: ChangePasswordState<a>, rootpath: string) => Widget<Action<LoginState<a>>>
  choose_roles_form: (s0: ChooseRoleState, readonly: boolean) => Widget<Action<LoginState<a>>>
}

export let getMockedPagesRenderer = <a>() => ({
  activate_account_form: activate_account_form,
  login_form: login_form,
  activate_account_success: activate_account_success,
  resend_activation_form: resend_activation_form,
  resend_activation_success: resend_activation_success,
  reset_password_form: reset_password_form,
  reset_password_success: reset_password_success,
  verify_reset_password_form: verify_reset_password_form,
  reset_password_request_form: reset_password_request_form,
  reset_password_request_success: reset_password_request_success,
  sign_up_form: sign_up_form,
  signup_success: signup_success,
  login_two_factor_form: login_two_factor_form,
  change_password_form: change_password_form,
  change_password_success: change_password_success,
  choose_roles_form: choose_roles_form,
})

export let sample_home = <a, b extends { kind: string }>(rootpath: string): IOWidget<PortalAppState<a, b>, Action<PortalAppState<a, b>>> => (s0: PortalAppState<a, b>) =>
  !isLoggedInState(s0.login) ? nothing() :
    any<Action<LoginState<a>>>()([
      div<Action<LoginState<a>>>({ className: "login"})(
        div<Action<LoginState<a>>>({ className: "login__inner"})([
          string({ readonly: true, readonly_element: "p", className: `alert alert-success` })(`Welcome ${s0.login.role}, ${s0.login.info.kind == 'loaded' ? s0.login.info.value.FirstName : s0.login.user.username }`).never(),
          string({ readonly: true, readonly_element: "p" })(`This should be a proper page for logged in users.`).never(),
          link("Change password", rootpath + "/change-password", { className: "button button-link" }).never()
        ])
      )
    ]).map<Action<PortalAppState<a, b>>>(a => s => ({ ...s, login: a(s.login) }))

export let page_404 = (): Widget<never> =>
  div()(
    hn(1, "Page not found")()
  ).never()

export let login_two_factor_form = <a>(s0: LoginTwoFactorState, readonly: boolean): Widget<Action<LoginState<a>>> =>
  any<Action<LoginState<a>>>({ key: "login-widget-two-factor-form" })([
    div<Action<LoginState<a>>>({ className: "row form-group", key: "login-widget-two-factor-form-inner-div" })(
      any<Action<LoginState<a>>>({ key: "login-widget-two-factor-form-inner-any" })([
        label<Action<LoginState<a>>>("Pin", { key: "login-widget-two-factor-form-input-label", htmlFor: "pin", label_position: "before", className: "label" })(
          div<Action<LoginState<a>>>({ key: "login-widget-two-factor-form-inner-any-div" })(
            string({ readonly, readonly_element: "input", id: "pin", className: "form-control" })(s0.login_data.pin)
              .map<Action<LoginState<a>>>(x => s => !isLoginTwoFactorState(s) ? s : { ...s, status: "two factor", login_data: { ...s.login_data, pin: x } })
          )
        ),

        div<Action<LoginState<a>>>({ className: "row", key: "login-widget-two-factor-form-pin-field" })(
          button<Action<LoginState<a>>>("Submit pin",
            { className: "btn btn-primary btn-md", disabled: readonly })
            (() => s => ({ ...s, status: "two factor - logging", login_data: s0.login_data }))
        )
      ])
    ),
    div<Action<LoginState<a>>>({ className: "row form-group" })(
      button<Action<LoginState<a>>>("Send new pin",
        { className: "btn btn-light btn-md", disabled: readonly, key: "login-widget-two-factor-form-send-new-pin-button" })
        (() => s => ({ ...s, status: "two factor - resending pin", login_data: s0.login_data }))
    ),
    div<Action<LoginState<a>>>({ className: "row form-group" })(
      button<Action<LoginState<a>>>("Cancel",
        { className: "btn btn-light btn-md", disabled: readonly, key: "login-widget-two-factor-form-cancel-button" })
        (() => s => !isLoginTwoFactorState(s) ? s : { status: "logged out", login_data: { username: s.login_data.username, password: s.login_data.password, show_password: false } }),
    )
  ])
    .map<Action<LoginState<a>>>(a => s => !readonly ? a(s) : s)

export let verify_reset_password_form = <a>(s0: VerifyResetPasswordTokenState, rootpath: string): Widget<Action<LoginState<a>>> =>
  any<Action<LoginState<a>>>({ key: "login-widget-verify-reset-password-form" })([
    string({ readonly: true, readonly_element: "p", key: "verify-pswd-token-disclaimer" })("This form is exclusively meant for debugging purposes while no routing is available.").never(),
    div<Action<LoginState<a>>>({ className: "row form-group", key: "verify-pswd-token-field" })(
      label<Action<LoginState<a>>>("Activation token", { htmlFor: "activation-token", label_position: "before", className: "label" })(
        div<Action<LoginState<a>>>()(
          string({ id: "activation-token", className: "form-control" })(s0.login_data.reset_password_token!)
            .map<Action<LoginState<a>>>(x => s => s.status == "verify reset password token" ? { ...s, login_data: { reset_password_token: x, email: s0.login_data.email } } : s)
        ),
      )),
    div<Action<LoginState<a>>>({ className: "row form-group", key: "verify-pswd-email-field" })(
      label<Action<LoginState<a>>>("Email", { htmlFor: "activation-mail", label_position: "before", className: "label" })(
        div<Action<LoginState<a>>>()(
          string({ id: "activation-email", className: "form-control" })(s0.login_data.email!)
            .map<Action<LoginState<a>>>(x => s => s.status == "verify reset password token" ? { ...s, login_data: { reset_password_token: s0.login_data.reset_password_token, email: x } } : s)
        ),
      )),
    div<Action<LoginState<a>>>({ className: "row form-group", key: "verify-pswd-token-buttons" })(
      any<Action<LoginState<a>>>({ key: "login-widget-verify-reset-password-form-inner-any" })([
        div<Action<LoginState<a>>>({ className: "row form-group", key: "verify-pswd-token-submit" })(
          div<Action<LoginState<a>>>()(
            link("Reset password", `/${rootpath}reset-password?token=${s0.login_data.reset_password_token}&email=${s0.login_data.email}`, { className: "btn btn-primary btn-md" }).never()
          )),
        div<Action<LoginState<a>>>({ key: "verify-pswd-token-field-cancel" })(
          link("Sign in", rootpath).never()
        )
      ]))
  ])

// export let resend_activation_form = <a>(s0:ResendActivationState, readonly: boolean): Widget<Action<LoginState<a>>> =>
// any<Action<LoginState<a>>>({ key: "login-widget-reset-activation-form" })([
//   div<Action<LoginState<a>>>({key:"resend_activation_title", className: "row" })(
//     div<Action<LoginState<a>>>()([
//       string({ className: "lead", readonly: true, readonly_element: "p"})(`We've sent an e-mail to ${s0.login_data.email}`).never(),
//       string({ readonly: true, readonly_element: "p"})("Click the link in the e-mail to activate your account. If you need assistance, please contact support@vps.com").never()
//     ])
//   ),
//   div<Action<LoginState<a>>>({key:"resend_activation_text", className: "row" })(
//     div<Action<LoginState<a>>>()(
//       button<Action<LoginState<a>>>("Send new activation email", { disabled: readonly, className: "btn btn-light btn-md" })
//       (() => s => isResendActivationState(s) && !readonly ? {status:"resend activation - resending", login_data: s0.login_data } : s)
//       )
//   ),
//   div<Action<LoginState<a>>>({key:"resend_activation_cancel", className: "row" })(
//     div<Action<LoginState<a>>>()(
//       a<Action<LoginState<a>>>("sign in")
//         (() => s => isResendActivationState(s) && !readonly ? {status:"logged out", login_data:{password:"", username:"", pin:{kind:"no pin"}, show_password:false} } : s)
//       )
//   )
// ])

// export let signup_success = <a>(s0: LoggedInState<a>): Widget<Action<LoginState<a>>> =>
//   any<Action<LoginState<a>>>({ key: "login-widget-sign-up-success" })([
//     string({ key: "login-widget-signup-success-string", readonly: true, readonly_element: "p" })("Your account has been created and you are now logged in.").never(),
//     div<Action<LoginState<a>>>({ key: "login-widget-sign-up-success-div", className: "row form-group" })(
//       a<Action<LoginState<a>>>("Continue", { className: "btn btn-primary" })(() => s => s.status !== "logged in - signup success" ? s : { ...s, status: "logged in" })
//     )
//   ])

export let change_password_success = <a>(s0: ChangePasswordState<a>, rootpath: string): Widget<Action<LoginState<a>>> =>
  div<Action<LoginState<a>>>({ className: "reset", key: "login-widget-reset-pasword-success" })(
    div<Action<LoginState<a>>>({ className: "reset__form" })([
      string({ readonly: true, readonly_element: "p", key: "reset-password-success-text" })("Your password has been set and you are now logged in.").never(),
      div<Action<LoginState<a>>>({ className: "row" })(
        link("Continue", `${rootpath}/`, { key: "reset-password-success-button", className: "button button--primary link--center" }).never(),
      )
    ])
  )


export let choose_roles_form = <a>(s0: ChooseRoleState, readonly: boolean): Widget<Action<LoginState<a>>> =>
  div<Action<LoginState<a>>>({ key: "login-widget-choose-roles-form" })([
    div<Action<LoginState<a>>>({ className: "row form-group" })(
      label<Action<LoginState<a>>>("Select Role", { htmlFor: "role_select", label_position: "before" })(
        dropdown<Role>(s => s, s0.login_data.roles, { className: "form-control", id: "role_select" })(s0.login_data.role).map<Action<LoginState<a>>>(role =>
          s => isChooseRoleState(s) && role != null ? { status: "choose role", login_data: { ...s.login_data, role } } : s)
      )
    ),
    div<Action<LoginState<a>>>({ className: "row form-group" })(
      button<Action<LoginState<a>>>(s0.login_data.role ? `Continue as ${s0.login_data.role}` : "Continue", { className: "btn btn-primary", disabled: readonly })(() =>
        s => isChooseRoleState(s) ? { ...s, status: "choose role - logging in" } : s)
    ),
    div<Action<LoginState<a>>>({ className: "row form-group" })(
      button<Action<LoginState<a>>>("Cancel", { className: "btn btn-light btn-md", disabled: readonly })(() =>
        s => isChooseRoleState(s) ? { status: "logged out", login_data: { username: s.login_data.username, password: s.login_data.password, show_password: false } } : s)
    )
  ]).map<Action<LoginState<a>>>(a => s => readonly ? s : a(s))
