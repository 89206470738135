import { Map } from "immutable"
import * as React from "react"
import { Action, any, AsyncState, IOWidget, mk_widget, Pair } from "widgets-for-react"
import { ImageContainer } from "./ImageContainer"
import { CMSState } from "./state"
import { Id, ImageSize, Url, Link } from "./types"
import { link } from "./utils"

export type ButtonProps<customBlockData, extra_images> = (
  | { kind: "button"; on_click: () => Action<CMSState<customBlockData, extra_images>> }
  | Link ) & { title: string }

export type FeaturedContentBlockProps<customBlockData, extra_images> = {
  imageLocation: "left" | "right"
  image: Id
  image_size: ImageSize<extra_images>
  image_alt_text: string
  title: string
  intro?: string
  primary_button?: ButtonProps<customBlockData, extra_images>
  secondary_button?: ButtonProps<customBlockData, extra_images>
}

export type FeaturedContentBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
  content_classname: string
  header_classname: string
  title_classname: string
  text_classname: string
  intro_classname: string
  footer_classname: string
  clickable_primary_classname: string
  clickable_secondary_classname: string
  figure_classname: string
  image_classname: string
}>

export function FeaturedContentComponent<customBlockData, extra_images>(
  itemsOptions: Array<
    Pair<FeaturedContentBlockProps<customBlockData, extra_images>, { style?: FeaturedContentBlockStyle; key?: string }>
  >
): IOWidget<CMSState<customBlockData, extra_images>, Action<CMSState<customBlockData, extra_images>>> {
  return s =>
    any<Action<CMSState<customBlockData, extra_images>>>()(
      itemsOptions.map((e, i) =>
        FeaturedContentBlock(e.fst, { ...e.snd, key: i.toString() })(s).map<Action<CMSState<customBlockData, extra_images>>>(
          a => s => a(s)
        )
      )
    )
}

const getClassName = (
  options: { style?: FeaturedContentBlockStyle },
  className: keyof FeaturedContentBlockStyle,
  notset: string = ""
): string =>
  options && options.style && (options.style[className] || options.style[className] == null) ? options.style[className] : notset

function FeaturedContentBlock<customBlockData, extra_images>(
  props: FeaturedContentBlockProps<customBlockData, extra_images>,
  options?: { style?: FeaturedContentBlockStyle; key?: string }
): IOWidget<CMSState<customBlockData, extra_images>, Action<CMSState<customBlockData, extra_images>>> {
  return cmsState => {
    return mk_widget({
      run: onDone => {
        return (
          <section className={getClassName(options, "block_classname", "container")} key={options && options.key ? options.key : "featured-content-block"}>
            <div className={getClassName(options, "inner_classname", "py-5 row")}>
              <div className={getClassName(options, "content_classname", `col-md-6 mb-4 order-${props.imageLocation == "left" ? 2 : 1}`)}>
                <header className={getClassName(options, "header_classname", "")}>
                  <h2 className={getClassName(options, "title_classname", "mb-3")}>{props.title}</h2>
                </header>

                <div className={getClassName(options, "text_classname", "")}>
                  {props.intro && <p className={getClassName(options, "intro_classname", "")}>{props.intro}</p>}
                </div>

                {props.primary_button || props.secondary_button
                  ? <footer className={getClassName(options, "footer_classname", "")}>
                      {props.primary_button
                        ? (props.primary_button.kind === "button"
                          ? (<button
                              className={getClassName(options, "clickable_primary_classname", "button mr-2")}
                              onClick={() =>
                                props.primary_button.kind === "button" &&
                                onDone(props.primary_button.on_click())
                              }>
                                {props.primary_button.title}
                            </button>)
                          : props.primary_button.title && props.primary_button.url
                            ? (link(props.primary_button,
                                    {classname: getClassName(options, "clickable_primary_classname", "button mr-2")},
                                    props.primary_button.title))
                            : null
                          )
                        : null
                      }
                      {props.secondary_button
                        ? (props.secondary_button.kind === "button"
                          ? (<button
                              className={getClassName(options, "clickable_secondary_classname", "button")}
                              onClick={() =>
                                props.secondary_button.kind === "button" &&
                                onDone(props.secondary_button.on_click())
                              }>
                              {props.secondary_button.title}
                            </button>)
                          : props.secondary_button.title && props.secondary_button.url
                            ? (link(props.secondary_button,
                                    {classname: getClassName(options, "clickable_secondary_classname", "button button-link")},
                                    props.secondary_button.title))
                            : null
                          )
                        : null
                      }
                    </footer>
                  : null}
              </div>
              <div className={getClassName(options, "figure_classname", `col-md-6 mb-4 order-${props.imageLocation == "left" ? 1 : 2}`)}>
                {cmsState.images.has(props.image) &&
                  ImageContainer(
                    cmsState.images.get(props.image) as AsyncState<Map<ImageSize<extra_images>, string>>,
                    props.image_size,
                    getClassName(options, "image_classname", "w-100"),
                    props.image_alt_text
                  ).run(_ => null)}
              </div>
            </div>
          </section>
        )
      },
    })
  }
}