import { LoginState, ResetPasswordState, isResetPasswordState } from '../state';
import { Action, Widget, any, div, label, string, button, onlyIf, hn } from "widgets-for-react";
import { Api } from '../api_to_state';
import { Api as MockAPI } from '../mocked_api';
import i18next = require('i18next');


export let reset_password_form = <a>(s0: ResetPasswordState, readonly: boolean, rootpath: string, api?: Api<a>): Widget<Action<LoginState<a>>> =>
  div<Action<LoginState<a>>>({ key: "reset-password-form", className: "login" })([
    div<Action<LoginState<a>>>({ className: "login__inner" })([
      hn<Action<LoginState<a>>>(1, i18next.t('reset_password_form:change_password'), { className: "login__title title--l" })(),
      div<Action<LoginState<a>>>({ className: "field" })(
        label<Action<LoginState<a>>>(i18next.t('reset_password_form:new_password'), { htmlFor: "password", label_position: "before", className: "label" })(
          any<Action<LoginState<a>>>()([
            div<Action<ResetPasswordState>>()(
              string({ readonly, readonly_element: "input", id: "password", type: "password", className: `${s0.login_data.password != undefined && api && api.scorePassword && api.scorePassword(s0.login_data.password).res != "strong" ? "input--error" : ""}` })(s0.login_data.password!)
                .map<Action<ResetPasswordState>>(x => s => ({ ...s, status: "reset password", login_data: { ...s.login_data, password: x } }))
            ).map<Action<LoginState<a>>>(a => s => isResetPasswordState(s) ? a(s) : s),
            onlyIf<LoginState<a>>(s0.login_data.password != '' && MockAPI.scorePassword(s0.login_data.password).res != "strong",
              div<LoginState<a>>({ key: "login-widget-reset-password-form-inner-any-5-score-password", className:"inline-error" })(
                string({ readonly:true, readonly_element: "span" })(
                  MockAPI.scorePassword(s0.login_data.password!).res === 'weak'
                    ? i18next.t('reset_password_form:password_too_weak')
                    : MockAPI.scorePassword(s0.login_data.password).res === 'almost good'
                      ? i18next.t('reset_password_form:password_almost_good')
                      : '' ).never()
              )
            ).never()
          ])
        )
      ),
      div<Action<LoginState<a>>>({ className: "field" })(
        label<Action<LoginState<a>>>(i18next.t('reset_password_form:repeat_password'), { htmlFor: "confirm-password", label_position: "before", className: "label" })(
          any<Action<LoginState<a>>>()([
            div<Action<ResetPasswordState>>()(
              string({ readonly, readonly_element: "input", id: "confirm-password", className: `${s0.login_data.password != undefined && s0.login_data.confirmed_password != s0.login_data.password ? "input--error" : ""}`, type: "password" })(s0.login_data.confirmed_password!)
                .map<Action<ResetPasswordState>>(x => s => ({ ...s, status: "reset password", login_data: { ...s.login_data, confirmed_password: x } }))
            ).map<Action<LoginState<a>>>(a => s => isResetPasswordState(s) ? a(s) : s),
            onlyIf<LoginState<a>>(s0.login_data.password != undefined && s0.login_data.confirmed_password != s0.login_data.password,
              div<LoginState<a>>({ className: "inline-error" })(
                string({ readonly: true, readonly_element: "p" })(i18next.t('reset_password_form:passwords_dont_match')).never()
              )
            ).never()
          ])
        )
      ),
      div<Action<LoginState<a>>>({ className: "field" })(
        any<Action<LoginState<a>>>()([
          div<Action<LoginState<a>>>()(
            button<Action<LoginState<a>>>(i18next.t('reset_password_form:change_password_button'),
              { className: "button button--primary button--full-width", disabled: readonly })
              (() => s =>
                api && api.scorePassword && api.scorePassword(s0.login_data.password!).res == "strong" &&
                  s0.login_data.password == s0.login_data.confirmed_password
                  ? ({ status: "reset password - resetting password", login_data: s0.login_data })
                  : ({
                    ...s0, login_data: {
                      ...s0.login_data, password: s0.login_data.password == undefined ? "" : s0.login_data.password,
                      reset_password_token: s0.login_data.reset_password_token == undefined ? "" : s0.login_data.reset_password_token
                    }
                  })
            )
          ).map<Action<LoginState<a>>>(a => s => isResetPasswordState(s) ? a(s) : s)
        ])
      )
    ]),
  ]).map<Action<LoginState<a>>>(a => s => isResetPasswordState(s) && !readonly ? a(s) : s)
