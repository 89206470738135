import { Map } from "immutable"
import * as React from "react"
import { Action, AsyncState, IOWidget, mk_widget } from "widgets-for-react"
import { ImageContainer } from "./ImageContainer"
import { CMSState } from "./state"
import { Id, ImageSize, Url, Link } from "./types"
import { link } from "./utils"

export type LinkGridItem<extra_images> = {
  image: Id
  image_size: ImageSize<extra_images>
  image_alt: string
  title: string
  intro: string
  link?: Link
  show_image: "yes" | "no"
}

export type LinkGridBlockProps<extra_images> = {
  title?: string
  intro?: string
  items: LinkGridItem<extra_images>[]
  link?: Link
  show_image: "yes" | "no"
}

export type LinkGridBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
  header_classname: string
  title_classname: string
  intro_classname: string
  content_classname: string
  list_classname: string
  grid_item_classname: string
  grid_link_classname: string
  grid_content_classname: string
  grid_title_classname: string
  grid_intro_classname: string
  grid_fauxlink_classname: string
  grid_visual_classname: string
  grid_image_classname: string
  footer_classname: string
  clickable_classname: string
}>

const getClassName = (
  options: { style?: LinkGridBlockStyle },
  className: keyof LinkGridBlockStyle,
  notset: string = ""
): string =>
  options && options.style && (options.style[className] || options.style[className] == null) ? options.style[className] : notset

// TODO: make gridItem separate component so that it looks and works the same way as expandBlock
export function LinkGridBlock<customBlockData, extra_images>(
  props: LinkGridBlockProps<extra_images>,
  options?: { style?: LinkGridBlockStyle; key?: string }
): IOWidget<CMSState<customBlockData, extra_images>, Action<CMSState<customBlockData, extra_images>>> {
  return cmsState =>
    mk_widget({
      run: _ => {
        const gridItem = (item: LinkGridItem<extra_images>, index: number) => {
          return (
            <>
              {item.show_image === "yes" && item.image && cmsState.images.has(item.image)
                ? <figure className={getClassName(options, "grid_visual_classname", "card-img-top")}>
                    {ImageContainer(
                      cmsState.images.get(item.image) as AsyncState<Map<ImageSize<extra_images>, string>>,
                      item.image_size,
                      getClassName(options, "grid_image_classname", "w-100"),
                      item.image_alt
                    ).run(_ => null)}
                  </figure>
                : null
              }
              <div className={getClassName(options, "grid_content_classname", "card-body px-0")}>
                { item.title &&
                  <h3 className={getClassName(options, "grid_title_classname", "mb-2")}>{item.title}</h3>
                }
                { item.intro &&
                  <p className={getClassName(options, "grid_intro_classname", "")}>{item.intro}</p>
                }
                { item.link &&
                  <span className={getClassName(options, "grid_fauxlink_classname", "button button-link pl-0")}>{item.link.title}</span>
                }
              </div>
            </>
          )
        }
        return (
          <section className={getClassName(options, "block_classname", "container")} key={options && options.key ? options.key : "link-grid-block"}>
            <div className={getClassName(options, "inner_classname", "py-5")}>
              { (props.title || props.intro) &&
                <header className={getClassName(options, "header_classname", "text-center")}>
                  {props.title && (<h2 className={getClassName(options, "title_classname", "mb-3")}>{props.title}</h2>)}
                  {props.intro && (<p className={getClassName(options, "intro_classname", "")}>{props.intro}</p>)}
                </header>
              }

              {props.items && (
                <div className={getClassName(options, "content_classname", "")}>
                  <ul className={getClassName(options, "list_classname", "row list-unstyled mb-0")}>
                    {props.items.map((item: LinkGridItem<extra_images>, index: number) =>
                      <li className={getClassName(options, "grid_item_classname", "col-md-6 col-lg-4")}>
                        {item.link
                          ? link(item.link,
                                {classname: getClassName(options, "grid_link_classname", "d-flex flex-column-reverse text-secondary")},
                                gridItem(item, index))
                          : <div className={getClassName(options, "grid_link_classname", " d-flex flex-column-reverse")}>{ gridItem(item, index) }</div>
                        }
                      </li>
                    )}
                  </ul>
                </div>
              )}

              {props.link &&
                <footer className={getClassName(options, "footer_classname", "text-center")}>
                  {link(props.link,
                       {classname: getClassName(options, "clickable_classname", "button")},
                        props.link.title)
                  }
                </footer>
              }
            </div>
          </section>
        )
      },
    })
}