import * as React from "react"
import { Action, IOWidget, mk_widget } from "widgets-for-react"
import { ExtraImages } from "../public_site_utils/extra_images"
import { CMSState } from "./state"
import { Link } from "./types"
import { link } from "./utils"

export type TextCampaignBlockProps = {
  title?: string
  text: string
  title_options?: {
    size: string,
    font: string
  }
  text_options?: {
    size: string,
    font: string
  }
  primary_button?: Link & { title: string }
  link?: Link
}

export type TextCampaignBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
  header_classname: string
  title_classname: string
  content_classname: string
  text_classname: string
  footer_classname: string
  clickable_primary_classname: string
  clickable_secondary_classname: string
}>

const getClassName = (
  options: { style?: TextCampaignBlockStyle },
  className: keyof TextCampaignBlockStyle,
  notset: string = ""
): string =>
  options && options.style && (options.style[className] || options.style[className] == null) ? options.style[className] : notset

export function TextCampaignBlock<CustomBlockData>(
  props: TextCampaignBlockProps,
  options?: { style?: TextCampaignBlockStyle; key?: string }
): IOWidget<CMSState<CustomBlockData, ExtraImages>, Action<CMSState<CustomBlockData, ExtraImages>>> {
  return (cmsState: CMSState<CustomBlockData, ExtraImages>) =>
    mk_widget({
      run: onDone =>
        <section className={getClassName(options, "block_classname", "container")} key={options && options.key ? options.key : "text-campaign-block"}>
          <div className={getClassName(options, "inner_classname", "py-5")}>
            {props.title &&
              <header className={getClassName(options, "header_classname", "")}>
                <h2
                  className={getClassName(options, "title_classname", "mb-3")}
                  dangerouslySetInnerHTML={{ __html: props.title }}
                />
              </header>
            }

            <div className={getClassName(options, "content_classname", "")}>
              <div
                className={getClassName(options, "text_classname", "")}
                dangerouslySetInnerHTML={{ __html: props.text }}
              />
            </div>

            {(props.primary_button || (props.link && props.link.title && props.link.url)) && (
              <footer className={getClassName(options, "footer_classname", "")}>
                {props.primary_button && props.primary_button.title && props.primary_button.url
                  ? (link(props.primary_button,
                    { classname: getClassName(options, "clickable_primary_classname", "button mr-2") },
                    props.primary_button.title))
                  : null
                }
                {props.link && props.link.title && props.link.url &&
                  link(props.link,
                    { classname: getClassName(options, "clickable_secondary_classname", "button button-link") },
                    props.link.title)
                }
              </footer>
            )}
          </div>
        </section>
    })
}
