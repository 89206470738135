import * as React from "react"
import { Action, IOWidget, mk_widget } from "widgets-for-react"
import { CMSState } from "./state"
import { Url } from "./types"

export type DownloadBlockProps = {
  title?: string
  intro?: string
  items: DownloadProps[]
}

export type DownloadBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
  header_classname: string
  title_classname: string,
  intro_classname: string,
  content_classname: string
  list_classname: string,
  item_classname: string
  link_classname: string
}>

export type DownloadProps = { key?: string; title: string; file_url: string }

const getClassName = (
  options: { style?: DownloadBlockStyle },
  className: keyof DownloadBlockStyle,
  notset: string = ""
): string =>
  options && options.style && (options.style[className] || options.style[className] == null) ? options.style[className] : notset

export function DownloadBlock<customBlockData, extra_images>(
  props: DownloadBlockProps,
  options?: { style?: DownloadBlockStyle; key?: string }
): IOWidget<CMSState<customBlockData, extra_images>, Action<CMSState<customBlockData, extra_images>>> {
  return (s: CMSState<customBlockData, extra_images>) =>
    mk_widget({
      run: _ => (
        <section className={getClassName(options, "block_classname", "container")} key={options && options.key ? options.key : "download-block"}>
          <div className={getClassName(options, "inner_classname", "py-5")}>
            <header className={getClassName(options, "header_classname", "")}>
              {props.title && <h2 className={getClassName(options, "title_classname", "mb-3")}>{props.title}</h2>}
              {props.intro && <p className={getClassName(options, "intro_classname", "")}>{props.intro}</p>}
            </header>

            <div className={getClassName(options, "content_classname", "")}>
              <ul className={getClassName(options, "list_classname", "list-unstyled row mb-0")}>
                {props.items.map((link, index) => (
                  <li className={getClassName(options, "item_classname", "col-md-6")}>
                    <a
                      download={true}
                      target="_blank"
                      key={`link-${index}`}
                      href={link.file_url}
                      className={getClassName(options, "link_classname", "button button-link w-100 text-left border-bottom rounded-0 pl-0")}
                    >
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      ),
    })
}
