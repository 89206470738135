import * as i18next from "i18next";
import { List } from "immutable";
import { Action, IOWidget, nothing, Pair, route, Route, unloadedAsyncState } from "widgets-for-react";
import { PortalState, PublicSiteState } from "../../public_site";
import * as styleguideEntry from "../../styleguide/main";
import { login_state, getSession } from "../custom_portal/login";
import { isLoggedInState } from "../custom_portal/state";
import { Breadcrumb } from "../drupal/drupal_utils/types";
import { bookingPage } from "../public_site/booking_page";
import { CustomRouteParams } from "../widget_blocks_client/router_state";
import { PublicSiteCustomRoutes } from "./custom_route";

export type CustomPage<CustomPageState> = {
  widget: IOWidget<Pair<CustomRouteParams<PublicSiteState, PublicSiteCustomRoutes>, CustomPageState>, Action<CustomPageState>>
  route: Route<CustomRouteParams<PublicSiteState, PublicSiteCustomRoutes>>
  setState: (s: PublicSiteState, ps: CustomPageState) => PublicSiteState
  getState: (_: PublicSiteState) => CustomPageState
  updateStateBasesOnRoute: (s: PublicSiteState, r: CustomRouteParams<CustomPagesState, PublicSiteCustomRoutes>) => PublicSiteState
};

export type CustomPages = {
  styleguide: CustomPage<CustomPagesState["styleguide"]>
  booking_page: CustomPage<CustomPagesState["booking_page"]>
};

export type CustomPagesState = {
  styleguide: {}
  booking_page: {}
};

export const styleguide: CustomPage<CustomPagesState["styleguide"]> = {
  widget: r => styleguideEntry.styleguide,
  route: route<{}, CustomRouteParams<PublicSiteState, PublicSiteCustomRoutes>>("/styleguide", s => ({
    kind: "styleguide",
    title: "Styleguide",
    breadcrumbs: List<Breadcrumb>([
      { url: "/", title: "home" },
      { url: "/styleguide", title: "Styleguide" }
    ])
  })),
  getState: (s: PublicSiteState) => s.styleguide,
  setState: (s: PublicSiteState, ss: {}) => ({ ...s, styleguide: ss }),
  updateStateBasesOnRoute: (s, r) => (r.kind != "styleguide" ? s : { ...s, styleguide: { ...s.styleguide } })
};

export const booking_page: CustomPage<CustomPagesState["booking_page"]> = {
  widget: r => {
    if (r.fst.kind === "booking_page") {
      return bookingPage(r.fst.breadcrumbs).never();
    } else { return nothing(); }
  },
  route: route<{}, CustomRouteParams<PublicSiteState, PublicSiteCustomRoutes>>("/reserveer", s => ({
    kind: "booking_page",
    lang: "nl",
    title: "Reserveren",
    breadcrumbs: List<Breadcrumb>([
      { url: "/", title: "Home" },
      { url: "/reserveer", title: "Reserveren" }
    ])
  })),
  getState: (s: PublicSiteState) => s.booking_page,
  setState: (s: PublicSiteState, ss: {}) => s,
  updateStateBasesOnRoute: (s, r) => (s)
};