import { List, Map } from 'immutable';
import { Action, any, async, AsyncState, IOWidget, jsxToWidget, nothing, Option, Widget } from 'widgets-for-react';
import { BlockData, CMSState } from '../widget_blocks/state';
import { Id, ImageSize } from '../widget_blocks/types';
import { PairAsyncImage } from './types/images';
import { ParsedNode } from '../drupal/drupal_generated/types';
import { StandardBlockImagesLoader, StandardBlockToWidget } from './types/utils';





export type Page<CustomBlocks extends { kind: CustomBlocks['kind'] }, StandardBlock> = {node: ParsedNode, blocks: (CustomBlocks | StandardBlock)[]}
export type PageStateTemplate<CustomBlocks extends { kind: CustomBlocks['kind'] }, StandardBlock> =  {page: AsyncState< Page<CustomBlocks, StandardBlock>>}

export type TemplateState<
  CustomBlockData extends { kind: CustomBlockData['kind'] },
  CustomBlocks extends { kind: CustomBlocks['kind'] },
  StandardBlocks,
  extra_images
> = CMSState<CustomBlockData, extra_images> & PageStateTemplate<CustomBlocks, StandardBlocks>

type TemplateProps<
  StandardBlocks,
  CustomBlocks extends { kind: CustomBlocks['kind'] },
  CustomBlockData extends { kind: CustomBlockData['kind'] },
  extra_images
> = PageProps<StandardBlocks, CustomBlocks, CustomBlockData, extra_images>

type PageProps<
  StandardBlocks,
  CustomBlocks extends { kind: CustomBlocks['kind'] },
  CustomBlockData extends { kind: CustomBlockData['kind'] },
  extra_images
> = {
  StandardBlockImagesLoader: StandardBlockImagesLoader<CustomBlocks, StandardBlocks, extra_images>
  StandardBlock_To_Widget: StandardBlockToWidget<CustomBlockData, CustomBlocks, StandardBlocks, extra_images>

  errorPage: Widget<never>
  loadingPage: Widget<never>
  pageDisplay: (
    page: (CustomBlocks | StandardBlocks)[]
  ) => IOWidget<
    TemplateState<CustomBlockData, CustomBlocks, StandardBlocks, extra_images>,
    Action<TemplateState<CustomBlockData, CustomBlocks, StandardBlocks, extra_images>>
  >
  Display: (placeholder: Widget<Action<TemplateState<CustomBlockData, CustomBlocks, StandardBlocks, extra_images>>>) => Widget<Action<TemplateState<CustomBlockData, CustomBlocks, StandardBlocks, extra_images>>>
  CustomBLockImagesLoader?: (block: CustomBlocks) => Array<PairAsyncImage<extra_images>>
  CustomBlock_To_Widget?: (block: CustomBlocks, index: number) => Option<CustomBlockData>
  acceptAllCookies?: () => void
  getCookie?: (name: string) => string
  videoCookie?: string
}

const PageTemplateComponent = <
  StandardBlocks,
  CustomBlocks extends { kind: CustomBlocks['kind'] },
  CustomBlockData extends { kind: CustomBlockData['kind'] },
  extra_images
>(
  props: TemplateProps<StandardBlocks, CustomBlocks, CustomBlockData, extra_images>
): IOWidget<
  TemplateState<CustomBlockData, CustomBlocks, StandardBlocks, extra_images>,
  Action<TemplateState<CustomBlockData, CustomBlocks, StandardBlocks, extra_images>>
> => s0 =>
  any<Action<TemplateState<CustomBlockData, CustomBlocks, StandardBlocks, extra_images>>>()([
    async<Page<CustomBlocks, StandardBlocks>>(_ => nothing(), {
      loading: _ => props.loadingPage,
      connectionError: props.errorPage,
      otherError: props.errorPage
    })(s0.page)
     .map<Action<TemplateState<CustomBlockData, CustomBlocks, StandardBlocks, extra_images>>>(
        updatePage => s1 => {
          let newPage = updatePage(s1.page)
          let images = s1.images
          let blocks = s1.blocks
          if (newPage.kind === 'loaded') {
            
            newPage.value.blocks.forEach(b => {
              let images_to_load = props.StandardBlockImagesLoader(b, props.CustomBLockImagesLoader)
              images_to_load.forEach(i => {
                if(!images.has(i.fst)){
                  images = images.set(i.fst, i.snd)
                }
              })
            })


            blocks = Map<number, BlockData<CustomBlockData, extra_images> | CustomBlockData>(
              List(newPage.value.blocks)
               .flatMap((b, i: number) => {
                  let res = props.StandardBlock_To_Widget(
                    b,
                    i,
                    props.CustomBlock_To_Widget
                    // props.acceptAllCookies,
                    // props.getCookie,
                    // props.videoCookie
                  )
                return res.v.kind === 'l' ? [] : [[i, res.v.v]]
              })
            )
          }
          return { ...s1, blocks: blocks, images: images, page: newPage }
        }),
      s0.page.kind == "loaded"
      ? props.Display(props.pageDisplay(s0.page.value.blocks)(s0))
      : nothing(),


      ...s0.images
        .map((e, i) =>
          async<Map<ImageSize<extra_images>, string>>(_ => nothing())(e).map<
            Action<TemplateState<CustomBlockData, CustomBlocks, StandardBlocks, extra_images>>
            >(a => s => ({ ...s, images: s.images.set(i, a(s.images.get(i))) }))
        )
        .valueSeq()
        .toArray()
    ])

export default PageTemplateComponent
