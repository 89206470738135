import { mk_widget, Fun, Widget, nothing, lifecycle, IOWidget, Action } from "widgets-for-react"
import { ParsedNode, ParsedNodeRestaurant } from "../drupal/drupal_generated/types"
import * as React from 'react'
import * as i18next from 'i18next'
import { cleanPhonenumber } from '../../custom_components/utils';
import { pushToDataLayer } from "./ecommerce";
import { etender } from "./etender";
import { scrollFromHashTo } from "./scroll";

export const bodyHeader: Fun<ParsedNode, Widget<{}>> = n => (
  n.bundle == "restaurant"
    ? bodyHeaderRestaurantAutoScroll(n)
    : nothing()
)

const bodyHeaderRestaurant: IOWidget<ParsedNodeRestaurant, Action<{}>> = (node) => {
  return mk_widget({
    run: () => (
      <div className="content">
        <div className="block block--contact">
          <div className="block__inner">
            {(node.field_etender_access_key && node.field_dine_in == true) && (
              etender(node.field_etender_access_key).run(() => null)
            )}
            <div className="block__content">
              <div className="text--styled">
                <h2 className="block__title title--m">{i18next.t("location:contact")}</h2>
                {node.field_street_and_house_number
                  || node.field_postal_code
                  || node.field_city
                  || node.field_route ? (
                  <p>
                    {node.field_street_and_house_number && <>{node.field_street_and_house_number} <br /></>}
                    {node.field_postal_code && <>{node.field_postal_code}</>}
                    {node.field_postal_code && node.field_city ? " / " : ""}
                    {node.field_city && <>{node.field_city}<br /></>}
                    {node.field_route && (
                      <a
                        href={node.field_route.url}
                        target="_blank"
                        onClick={
                          () => pushToDataLayer({
                            event: "event-beren",
                            category: "Contact",
                            action: "Route",
                            label: node.label,
                          })
                        }
                      >
                        {i18next.t("location:route")}
                      </a>
                    )}
                  </p>
                ) : null}

                {node.field_telephone_number || node.field_email_address ? (
                  <>
                    <p>
                      {node.field_telephone_number && (
                        <>
                          <a
                            href={`tel:${cleanPhonenumber(node.field_telephone_number)}`}
                            onClick={
                              () => pushToDataLayer({
                                event: "event-beren",
                                category: "Contact",
                                action: "Telefoonnummer",
                                label: node.label,
                              })
                            }
                          >
                            {node.field_telephone_number}
                          </a>
                          <br />
                        </>
                      )}
                      {node.field_email_address && (
                        <a
                          href={`mailto:${node.field_email_address}`}
                          onClick={
                            () => pushToDataLayer({
                              event: "event-beren",
                              category: "Contact",
                              action: "E-mailadres",
                              label: node.label,
                            })
                          }
                        >
                          {node.field_email_address}
                        </a>
                      )}
                    </p>
                  </>
                ) : null}
              </div>

              {node.field_facilities && node.field_facilities.size > 0 && (
                <div className="facilities">
                  <h2 className="block__title title--m">{i18next.t("location:facilities:title")}</h2>
                  <ul className="facilities__list">
                    {node.field_facilities.map((v, k) => {
                      if (v == 'Gratis parkeren') {
                        return (
                          <li key={`f-${k}`} className="facilities__item">
                            <div className="facilities__icon" title={i18next.t("location:facilities:parking")}>
                              <span className="sr-text">{i18next.t("location:facilities:parking")}</span>
                              <img src="/images/frontend/facilities.svg#parking" width="72" height="72" alt="" aria-hidden="true" />
                            </div>
                          </li>
                        )
                      } else if (v == 'Elektrisch laden mogelijk') {
                        return (
                          <li key={`f-${k}`} className="facilities__item">
                            <div className="facilities__icon" title={i18next.t("location:facilities:charging")}>
                              <span className="sr-text">{i18next.t("location:facilities:charging")}</span>
                              <img src="/images/frontend/facilities.svg#charging" width="72" height="72" alt="" aria-hidden="true" />
                            </div>
                          </li>
                        )
                      } else if (v == 'Kinderspeelhoek') {
                        return (
                          <li key={`f-${k}`} className="facilities__item">
                            <div className="facilities__icon" title={i18next.t("location:facilities:childArea")}>
                              <span className="sr-text">{i18next.t("location:facilities:childArea")}</span>
                              <img src="/images/frontend/facilities.svg#childArea" width="72" height="72" alt="" aria-hidden="true" />
                            </div>
                          </li>
                        )
                      } else if (v == 'Kindvriendelijk (Baby verschonen, kinderstoeltjes etc)') {
                        return (
                          <li key={`f-${k}`} className="facilities__item">
                            <div className="facilities__icon" title={i18next.t("location:facilities:children")}>
                              <span className="sr-text">{i18next.t("location:facilities:children")}</span>
                              <img src="/images/frontend/facilities.svg#children" width="72" height="72" alt="" aria-hidden="true" />
                            </div>
                          </li>
                        )
                      } else if (v == 'Honden welkom') {
                        return (
                          <li key={`f-${k}`} className="facilities__item">
                            <div className="facilities__icon" title={i18next.t("location:facilities:dogs")}>
                              <span className="sr-text">{i18next.t("location:facilities:dogs")}</span>
                              <img src="/images/frontend/facilities.svg#dogs" width="72" height="72" alt="" aria-hidden="true" />
                            </div>
                          </li>
                        )
                      } else if (v == "Dagmenu's") {
                        return (
                          <li key={`f-${k}`} className="facilities__item">
                            <div className="facilities__icon" title={i18next.t("location:facilities:dayMenu")}>
                              <span className="sr-text">{i18next.t("location:facilities:dayMenu")}</span>
                              <img src="/images/frontend/facilities.svg#dayMenu" width="72" height="72" alt="" aria-hidden="true" />
                            </div>
                          </li>
                        )
                      } else if (v == 'Gratis WiFi') {
                        return (
                          <li key={`f-${k}`} className="facilities__item">
                            <div className="facilities__icon" title={i18next.t("location:facilities:wifi")}>
                              <span className="sr-text">{i18next.t("location:facilities:wifi")}</span>
                              <img src="/images/frontend/facilities.svg#wifi" width="72" height="72" alt="" aria-hidden="true" />
                            </div>
                          </li>
                        )
                      } else if (v == 'Langs snelweg') {
                        return (
                          <li key={`f-${k}`} className="facilities__item">
                            <div className="facilities__icon" title={i18next.t("location:facilities:highway")}>
                              <span className="sr-text">{i18next.t("location:facilities:highway")}</span>
                              <img src="/images/frontend/facilities.svg#highway" width="72" height="72" alt="" aria-hidden="true" />
                            </div>
                          </li>
                        )
                      } else if (v == 'Rolstoelvriendelijk') {
                        return (
                          <li key={`f-${k}`} className="facilities__item">
                            <div className="facilities__icon" title={i18next.t("location:facilities:wheelchair")}>
                              <span className="sr-text">{i18next.t("location:facilities:wheelchair")}</span>
                              <img src="/images/frontend/facilities.svg#wheelchair" width="72" height="72" alt="" aria-hidden="true" />
                            </div>
                          </li>
                        )
                      } else if (v == 'Terras') {
                        return (
                          <li key={`f-${k}`} className="facilities__item">
                            <div className="facilities__icon" title={i18next.t("location:facilities:terras")}>
                              <span className="sr-text">{i18next.t("location:facilities:terras")}</span>
                              <img src="/images/frontend/facilities.svg#terras" width="72" height="72" alt="" aria-hidden="true" />
                            </div>
                          </li>
                        )
                      } else if (v == 'Ontbijt') {
                        return (
                          <li key={`f-${k}`} className="facilities__item">
                            <div className="facilities__icon" title={i18next.t("location:facilities:breakfast")}>
                              <span className="sr-text">{i18next.t("location:facilities:breakfast")}</span>
                              <img src="/images/frontend/facilities.svg#breakfast" width="72" height="72" alt="" aria-hidden="true" />
                            </div>
                          </li>
                        )
                      } else if (v == 'Late Night') {
                        return (
                          <li key={`f-${k}`} className="facilities__item">
                            <div className="facilities__icon" title={i18next.t("location:facilities:latenight")}>
                              <span className="sr-text">{i18next.t("location:facilities:latenight")}</span>
                              <img src="/images/frontend/facilities.svg#latenight" width="72" height="72" alt="" aria-hidden="true" />
                            </div>
                          </li>
                        )
                      } else if (v == 'Bioscoop') {
                        return (
                          <li key={`f-${k}`} className="facilities__item">
                            <div className="facilities__icon" title={i18next.t("location:facilities:cinema")}>
                              <span className="sr-text">{i18next.t("location:facilities:cinema")}</span>
                              <img src="/images/frontend/facilities.svg#cinema" width="72" height="72" alt="" aria-hidden="true" />
                            </div>
                          </li>
                        )
                      } else {
                        return <></>
                      }
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  })

}

const bodyHeaderRestaurantAutoScroll = lifecycle({
  componentDidMount: () => {
    setTimeout(() => scrollFromHashTo('#reserveren', document.querySelector('#etender')), 350)
  }
})(bodyHeaderRestaurant)
