import { List } from "immutable";
import { latLng, LatLng } from "leaflet";
import { AsyncState, HttpResult, loadingAsyncState } from "widgets-for-react";
import { entity_parser } from "./drupal/drupal_generated/parser";
import { ParsedEntity } from "./drupal/drupal_generated/types";
import { Breadcrumb, MenuItem, ParsedMenuItem } from "./drupal/drupal_utils/types";

export async function GetDrupalNode(slug?: string): Promise<HttpResult<ParsedEntity>> {
  const url = slug == null || slug == "" || slug == '/'
    ? '/api/drupal/api/homepage'
    : `/api/drupal/${slug}`

  const res = await fetch(
    url,
    {
      method: 'GET',
      credentials: "include"
    }
  )

  if (!res.ok) return { kind: "failed", status: 400 }

  const json = await res.json()

  if (Array.isArray(json)) {
    if (json.length == 0) return { kind: "failed", status: 400 }

    const parsed = entity_parser(json.shift() as any)
    return { kind: "result", status: 200, value: parsed }
  }

  const parsed = entity_parser(json as any)
  return { kind: "result", status: 200, value: parsed }
}

export async function getMenu(url: string): Promise<HttpResult<List<ParsedMenuItem>>> {
  const res = await fetch(
    url,
    {
      method: 'GET',
      credentials: 'include'
    }
  )
  if (!res.ok) return { kind: 'failed', status: 404 }
  const json = await res.json()
  return { kind: 'result', value: parse_menu(json), status: 200 }
}

const parse_menu = (t: MenuItem[]): List<ParsedMenuItem> =>
  List<ParsedMenuItem>(t.map(menu_item_parser))

let menu_item_parser: (t: MenuItem) => ParsedMenuItem = t => {
  return {
    key: t.key,
    label: t.title,
    relative: !!t.relative,
    url: t.relative ? t.relative : t.absolute,
    external: t.external,
    below: List<ParsedMenuItem>(t.below ? t.below.map(menu_item_parser) : [])
  }
}

export const loadBreadcrumbsApiCall = async (alias: string): Promise<HttpResult<List<Breadcrumb>>> => {
  const breadcrumbCall = await fetch(alias != undefined ? `/api/drupal/api/breadcrumb?path=${removeLeadingSlash(alias)}` : "/s", {
    method: "GET",
    credentials: "include"
  })

  const res = await breadcrumbCall

  return res.ok
    ? {
      kind: "result",
      status: 200,
      value: List(await res.json())
    }
    : {
      kind: "failed",
      status: res.status
    }
}

export const loadBreadcrumbs = (alias: string): AsyncState<List<Breadcrumb>> =>
  loadingAsyncState(_ =>
    loadBreadcrumbsApiCall(alias).then<HttpResult<List<Breadcrumb>>>(bread => {
      return bread.kind == "result"
        ? { kind: "result", value: bread.value.filter(x => x.url != "/user") as List<Breadcrumb>, status: 200 }
        : { kind: "failed", status: 404 }
    })
  )

export const loadMenu = async (name: 'main' | 'footer'): Promise<HttpResult<List<ParsedMenuItem>>> => {
  const menuListCall = fetch(`/api/drupal/api/menu_items/${name}`, { credentials: 'include' })

  const menuList = await (await menuListCall).json()

  return {
    kind: 'result',
    status: 200,
    value: parse_menu(menuList)
  }
}

const removeLeadingSlash = (s: string): string =>
  s.startsWith('/') ? s.slice(1) : s

export type RestaurantListFilterType = "dineIn" | "pickUp" | "delivery"
export type RestaurantListFilter = Partial<Record<RestaurantListFilterType, boolean>>

export type RestaurantList = List<RestaurantListItem>
export type RestaurantListItem = {
  id: string
  title: string
  emailAddress: string
  url: string
  telephoneNumber: string
  delivery: "0" | "1"
  dineIn: "0" | "1"
  pickUp: "0" | "1"
  field_street_and_house_number: string
  field_postal_code: string
  field_city: string
  field_latitude: string 
  field_longitude: string
  field_route: string
}

interface OpenStreetMapResponse {
  place_id: number;
  licence: string;
  osm_type: string;
  osm_id: number;
  boundingbox: string[];
  lat: string;
  lon: string;
  display_name: string;
  class: string;
  type: string;
  importance: number;
  icon: string;
}


export const loadRestaurantList = async (f?: RestaurantListFilter): Promise<HttpResult<RestaurantList>> => {

  let params = List<string>()

  if (f !== undefined) {
    if (f.delivery != undefined) params = params.push(`delivery=${f.delivery ? "1" : "0"}`)
    if (f.dineIn != undefined) params = params.push(`dine_in=${f.dineIn ? "1" : "0"}`)
    if (f.pickUp != undefined) params = params.push(`pick_up=${f.pickUp ? "1" : "0"}`)
  }

  let queryString = !params.isEmpty() ? "?" + params.join("&") : ""

  const restaurantListCall = fetch(`/api/drupal/api/restaurant_list${queryString}`, { credentials: "include" })

  const restaurantList: RestaurantListItem[] = await (await restaurantListCall).json()

  return {
    kind: "result",
    status: 200,
    value: List<RestaurantListItem>(restaurantList)
  }
}