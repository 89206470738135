import { Map } from "immutable"
import * as React from "react"
import { AsyncState, Widget } from "widgets-for-react"
import { ImageSize, Url } from "./types"
import { jsxToWidget } from "./utils"

export type ImageSrcsetProps<extra_images> = {
  srcSize: ImageSize<extra_images>
  imgSize: string
}

export type ImageContainerExtendedProps<extra_images> = {
  url: AsyncState<Map<ImageSize<extra_images>, Url>>,
  srcSize: ImageSize<extra_images>,
  srcsetSize?: ImageSrcsetProps<extra_images>[],
  extraClasses?: string,
  alt: string
  width: string
  height: string
  lazyload: boolean
}

export function ImageContainerExtended<extra_images> (p: ImageContainerExtendedProps<extra_images>): Widget<never>{
  return jsxToWidget(
    p.url.kind === "loaded" && (
      <img 
        width={p.width}
        height={p.height}
        src={p.lazyload ? '/images/frontend/empty.png' : p.url.value.get(p.srcSize || 'full')}
        data-src={p.lazyload ? p.url.value.get(p.srcSize || 'full') : null}
        data-srcset={p.lazyload ? p.srcsetSize.map((v, k) => { return p.url.kind === "loaded" ? `${p.url.value.get(v.srcSize || 'full')} ${v.imgSize}` : null }).join(', ') : null}
        data-sizes={p.lazyload ? 'auto' : null}
        className={`${p.extraClasses ? p.extraClasses : ''}${p.lazyload ? ' lazyload': ''}`}
        alt={p.alt}
      />
    )
  )
}
