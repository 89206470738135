import moment = require("moment-timezone")
import * as React from "react"
import Slider from "react-slick"
import { Fun, mk_widget, Widget } from "widgets-for-react"
import { ParsedNodeCampaign } from "../drupal/drupal_generated/types"
import { parseImageUrl } from "../drupal/drupal_utils/utils"
import { Link } from "../public_site_utils/Link"

type CountdownCampaignState = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

export interface CountdownCampaignProps {
  node: ParsedNodeCampaign
}

let counterInterval = null
export class CountdownCampaign extends React.Component<CountdownCampaignProps, CountdownCampaignState> {

  constructor(props: CountdownCampaignProps) {
    super(props)

    this.state = this.initState()

    this.initState = this.initState.bind(this)
    this.calculate_days_left = this.calculate_days_left.bind(this)
    this.calculate_hours_left = this.calculate_hours_left.bind(this)
    this.calculate_minutes_left = this.calculate_minutes_left.bind(this)
    this.calculate_seconds_left = this.calculate_seconds_left.bind(this)
  }

  initState = (): CountdownCampaignState => {
    let now = moment.now().valueOf()
    let time_left = this.props.node.field_countdown.valueOf() - now
    return ({
      days: this.calculate_days_left(time_left),
      hours: this.calculate_hours_left(time_left),
      minutes: this.calculate_minutes_left(time_left),
      seconds: this.calculate_seconds_left(time_left)
    })
  }

  calculate_days_left = (time_left: number) => Math.floor(time_left / 86400000)
  calculate_hours_left = (time_left: number) => Math.floor((time_left % 86400000) / 3600000)
  calculate_minutes_left = (time_left: number) => Math.floor((time_left % 3600000) / 60000)
  calculate_seconds_left = (time_left: number) => Math.floor((time_left % 60000) / 1000)

  componentDidMount() {
    console.log('counter mounted')
    counterInterval = setInterval(() => {
      let now = moment.now().valueOf()
      let time_left = this.props.node.field_countdown.valueOf() - now
      this.setState(s => ({
        ...s,
        days: this.calculate_days_left(time_left),
        hours: this.calculate_hours_left(time_left),
        minutes: this.calculate_minutes_left(time_left),
        seconds: this.calculate_seconds_left(time_left)
      }))
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(counterInterval)
  }


  render() {
    let start_campaign = this.props.node.field_countdown_start.valueOf()
    let end_campaign = this.props.node.field_countdown.valueOf()
    let time_difference = end_campaign - start_campaign
    let days_difference = Math.floor(time_difference / 86400000)


    return (
      <div className="counter">
        <span className="sr-text">Deze campagne duurt nog {this.state.days == 1 ? 'dag' : 'dagen'}, {this.state.hours == 1 ? 'uur' : 'uren'}, {this.state.minutes == 1 ? 'minuut' : 'minuten'} en {this.state.seconds == 1 ? 'seconde' : 'seconden'}</span>
        <div className="content counter__inner" aria-hidden="true">
          <div className="counter__item">
            <div className="counter__section">
              <div className="counter__clock">
                <div className="counter__text">
                  <span className="counter__value">{this.state.days}</span>
                  <span className="counter__label">dag{this.state.days == 1 ? '' : 'en'}</span>
                </div>
                <svg className="counter__progress" id="c-days" height="310" viewBox="0 0 310 310" width="310" xmlns="http://www.w3.org/2000/svg"><circle cx="155" cy="155" fill="none" r="145" stroke="#CDAD72" strokeWidth="20" style={{ strokeDashoffset: `${912 * this.state.days / days_difference}px` }} /></svg>
              </div>
            </div>
          </div>

          <div className="counter__item">
            <div className="counter__section">
              <div className="counter__clock">
                <div className="counter__text">
                  <span className="counter__value">{this.state.hours}</span>
                  <span className="counter__label">{this.state.hours == 1 ? 'uur' : 'uren'}</span>
                </div>
                <svg className="counter__progress" id="c-hours" height="310" viewBox="0 0 310 310" width="310" xmlns="http://www.w3.org/2000/svg"><circle cx="155" cy="155" fill="none" r="145" stroke="#CDAD72" strokeWidth="20" style={{ strokeDashoffset: `${912 * this.state.hours / 24}px` }} /></svg>
              </div>
            </div>
          </div>

          <div className="counter__item">
            <div className="counter__section">
              <div className="counter__clock">
                <div className="counter__text">
                  <span className="counter__value">{this.state.minutes}</span>
                  <span className="counter__label">{this.state.minutes == 1 ? 'minuut' : 'minuten'}</span>
                </div>
                <svg className="counter__progress" id="c-minutes" height="310" viewBox="0 0 310 310" width="310" xmlns="http://www.w3.org/2000/svg"><circle cx="155" cy="155" fill="none" r="145" stroke="#CDAD72" strokeWidth="20" style={{ strokeDashoffset: `${912 * this.state.minutes / 60}px` }} /></svg>
              </div>
            </div>
          </div>

          <div className="counter__item">
            <div className="counter__section">
              <div className="counter__clock">
                <div className="counter__text">
                  <span className="counter__value">{this.state.seconds}</span>
                  <span className="counter__label">{this.state.seconds == 1 ? 'seconde' : 'seconden'}</span>
                </div>
                <svg className="counter__progress" id="c-seconds" height="310" viewBox="0 0 310 310" width="310" xmlns="http://www.w3.org/2000/svg"><circle cx="155" cy="155" fill="none" r="145" stroke="#CDAD72" strokeWidth="20" style={{ strokeDashoffset: `${912 * this.state.seconds / 60}px` }} /></svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


const SliderSettings = {
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  pauseOnDotsHover: true,
  speed: 2000,
  autoplaySpeed: 6000,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true
}

export const pageHeaderCampaign: Fun<ParsedNodeCampaign, Widget<never>> = p =>
  mk_widget({
    run: () => {
      return (
        <>
          <header className='hero hero--campaign hero--with-slider'>
            {p.field_header && (
              <Slider className="hero__banners" {...SliderSettings}>
                {p.field_header.map((v, k) => {
                  return (
                    <div>
                      <div className="hero__banner banner">
                        {v.field_media && (
                          <figure className={`banner__visual${(v.field_campaign_title || v.field_campaign_introduction) ? ' banner__visual--gradient' : ''}`}>
                            {v.field_media.bundle == 'image' && (
                              <img
                                width='720w'
                                src={parseImageUrl(v.field_media.field_media_image.image.style_urls['w720'])}
                                srcSet={`
                                  ${parseImageUrl(v.field_media.field_media_image.image.style_urls['w720'])} 720w,
                                  ${parseImageUrl(v.field_media.field_media_image.image.style_urls['w1440'])} 1440w,
                                  ${parseImageUrl(v.field_media.field_media_image.image.style_urls['w2160'])} 2160w,
                                  ${parseImageUrl(v.field_media.field_media_image.image.style_urls['w2880'])} 2880w
                                `}
                                sizes='100vw'
                                className='hero__image'
                                alt={v.field_media.field_media_image.alt}
                              />
                            )}
                          </figure>
                        )}

                        <div className="banner__content">
                          <div className="banner__inner">
                            {v.field_campaign_title && (
                              <h1
                                className={`banner__title title--xxl${v.field_fonts_font_size_title && v.field_fonts_font_size_title.field_size ? ` c-${v.field_fonts_font_size_title.field_size}` : ''}${v.field_fonts_font_size_title && v.field_fonts_font_size_title.field_font ? ` c-font--${v.field_fonts_font_size_title.field_font}` : ''}`}
                                dangerouslySetInnerHTML={{ __html: v.field_campaign_title }}
                              />
                            )}

                            {v.field_campaign_introduction && (
                              <div
                                className={`banner__intro title--l${v.field_fonts_font_size_text && v.field_fonts_font_size_text.field_size ? ` c-${v.field_fonts_font_size_text.field_size}` : ''}${v.field_fonts_font_size_text && v.field_fonts_font_size_text.field_font ? ` c-font--${v.field_fonts_font_size_text.field_font}` : ''}`}
                                dangerouslySetInnerHTML={{ __html: v.field_campaign_introduction }}
                              />
                            )}

                            {v.field_links.map((l, i) => (
                              <Link
                                className={`banner__button button ${i == 0 ? "button--primary" : "button--secondary"}`}
                                to={l.url}
                                type="link"
                              >
                                {l.title}
                              </Link>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            )}
          </header>
        </>
      )
    }
  })
