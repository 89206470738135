import { Map } from "immutable";
import { HttpResult, none, some } from "widgets-for-react";
import { RestaurantQuery, UserQuery } from "../../../generated_context";
import { RestaurantsQueryResult, User, UserAddress } from "./data_types";
import moment = require("moment-timezone");

const odataQueryUri: string = "/api/v1/odata/GetQuery?Query="
const uncachedOdataQueryUri: string = "/api/v1/odata/GetQueryUnCached?Query="
const userOdataQueryUri: string = "/api/v1/odata/GetUser?Query="

export const getRestaurantsAsync = async (): Promise<HttpResult<RestaurantsQueryResult[]>> => {
  try {
    let restaurants = await RestaurantQuery(odataQueryUri)
      .Select("Id", "Name", "HasNewFlow", "Delivery", "Pickup", "DeliveryCost", "DeliveryFreeAfterValue", "DeliveryMinimumCartValue", "Address")
      .Where(rest => rest.Active.Equals(true))
        .ExpandStrict("Restaurant_PostCodeRange", r_pcr => r_pcr.Select("Id", "RestaurantId", "PostCodeRangeId")
            .ExpandStrict("PostCodeRange", pcr => pcr.Select("PostCodeStart", "PostCodeEnd", "Active", "DeliveryCost", "DeliveryFreeAfterValue", "DeliveryMinimumCartValue")
                .Expand("PostCodeRange_PostCodeExclusion", pcr_pce => pcr_pce.Select("Id","PostCodeRangeId", "PostCodeExclusionId")
                    .Expand("PostCodeExclusion", pce => pce.Select("PostCodeStart", "PostCodeEnd")))))
        .Expand("Restaurant_DeliveryTimes", r_dt => r_dt.Select("Id", "RestaurantId", "DeliveryTimesId")
            .Expand("DeliveryTimes", dt => dt.Select("Id", "MondayDelivery", "MondayDeliveryStart", "MondayDeliveryEnd", "TueDelivery", "TueDeliveryStart", "TueDeliveryEnd", "WedDelivery", "WedDeliveryStart", "WedDeliveryEnd", "ThuDelivery", "ThuDeliveryStart", "ThuDeliveryEnd", "FriDelivery", "FriDeliveryStart", "FriDeliveryEnd", "SatDelivery", "SatDeliveryStart", "SatDeliveryEnd", "SunDelivery", "SunDeliveryStart", "SunDeliveryEnd")))
        .Expand("Restaurant_DeliveryClosingTime", r_dct => r_dct.Select("Id", "RestaurantId", "DeliveryClosingTimeId")
            .Expand("DeliveryClosingTime", x => x.Select("Start", "End", "Message")))
        .Expand("Restaurant_PickupTimes", r_pt => r_pt.Select("Id", "PickupTimesId", "RestaurantId")
            .Expand("PickupTimes", pt => pt.Select("Id", "MondayPickup", "MondayPickupStart", "MondayPickupEnd", "TuePickup", "TuePickupStart", "TuePickupEnd", "WedPickup", "WedPickupStart", "WedPickupEnd", "ThuPickup", "ThuPickupStart", "ThuPickupEnd", "FriPickup", "FriPickupStart", "FriPickupEnd", "SatPickup", "SatPickupStart", "SatPickupEnd", "SunPickup", "SunPickupStart", "SunPickupEnd")))
        .Expand("Restaurant_PickupClosingTime", r_pcr => r_pcr.Select("Id", "RestaurantId", "PickupClosingTimeId")
            .Expand("PickupClosingTime", pct => pct.Select("Start", "End", "Message")))
        .ExpandStrict("Restaurant_WaitingTimes", r_wt => r_wt.Select("Id", "RestaurantId", "WaitingTimesId")
            .ExpandStrict("WaitingTimes", wt => wt.Select("PickupTimeMin", "PickupTimeMax", "DeliveryTimeMin", "DeliveryTimeMax")))
        .Expand("DeliveryRestaurant_DeliveryPayMethod", dr_dpm => dr_dpm.Select("DeliveryRestaurantId", "DeliveryPayMethodId")
            .Expand("PayMethod", pm => pm.Select("Method", "DisplayTitle")))
        .Expand("PickUpRestaurant_PickUpPayMethod", pur_pupm => pur_pupm.Select("PickUpRestaurantId", "PickUpPayMethodId")
            .Expand("PayMethod", pm => pm.Select("Method", "DisplayTitle")))

    .ToList({Fetch: url => fetch(url, {credentials: 'include'})})
    let res = restaurants.filter (restaurant => (restaurant.Restaurant_WaitingTimes.first() != undefined) && (restaurant.Restaurant_WaitingTimes.first().WaitingTimes != null))
      .map(restaurant => ({...restaurant,
                              Delivery: restaurant.Delivery && !restaurant.Restaurant_PostCodeRange.isEmpty(),
                              DeliveryCost: restaurant.DeliveryCost,
                              DeliveryFreeAfterValue: restaurant.DeliveryFreeAfterValue,
                              DeliveryMinimumCartValue: restaurant.DeliveryMinimumCartValue,
                              WaitingTimes:
                              {
                                PickupTimeMin: restaurant.Restaurant_WaitingTimes.first().WaitingTimes.first().PickupTimeMin,
                                PickupTimeMax: restaurant.Restaurant_WaitingTimes.first().WaitingTimes.first().PickupTimeMax,
                                DeliveryTimeMin: restaurant.Restaurant_WaitingTimes.first().WaitingTimes.first().DeliveryTimeMin,
                                DeliveryTimeMax: restaurant.Restaurant_WaitingTimes.first().WaitingTimes.first().DeliveryTimeMax
                              },
                              PostCodeRanges: restaurant.Restaurant_PostCodeRange.flatMap(rpcr => rpcr.PostCodeRange.filter(pcr => pcr.Active).map(pcr =>
                                        ({
                                          Start: pcr.PostCodeStart,
                                          End: pcr.PostCodeEnd,
                                          Exclusions: pcr.PostCodeRange_PostCodeExclusion.flatMap(pcr_pce => pcr_pce.PostCodeExclusion).toList(),
                                          DeliveryCost: pcr.DeliveryCost,
                                          DeliveryFreeAfterValue: pcr.DeliveryFreeAfterValue,
                                          DeliveryMinimumCartValue: pcr.DeliveryMinimumCartValue,
                                        }))).toList(),
                              DeliveryClosingTimes: restaurant.Restaurant_DeliveryClosingTime.flatMap(r_dct => r_dct.DeliveryClosingTime.map(dct =>
                                        ({
                                          Start: dct.Start,
                                          End: dct.End,
                                          Message: dct.Message
                                        }))).toList(),
                              DeliveryOpeningTimes: restaurant.Restaurant_DeliveryTimes.flatMap(r_dt => r_dt.DeliveryTimes.map(dt =>
                                        [{
                                            Open: dt.SunDelivery,
                                            Start: dt.SunDeliveryStart,
                                            End:dt.SunDeliveryEnd
                                          },
                                          {
                                            Open: dt.MondayDelivery,
                                            Start: dt.MondayDeliveryStart,
                                            End:dt.MondayDeliveryEnd
                                          },
                                          {
                                            Open: dt.TueDelivery,
                                            Start: dt.TueDeliveryStart,
                                            End:dt.TueDeliveryEnd
                                          },
                                          {
                                            Open: dt.WedDelivery,
                                            Start: dt.WedDeliveryStart,
                                            End:dt.WedDeliveryEnd
                                          },
                                          {
                                            Open: dt.ThuDelivery,
                                            Start: dt.ThuDeliveryStart,
                                            End:dt.ThuDeliveryEnd
                                          },
                                          {
                                            Open: dt.FriDelivery,
                                            Start: dt.FriDeliveryStart,
                                            End:dt.FriDeliveryEnd
                                          },
                                          {
                                            Open: dt.SatDelivery,
                                            Start: dt.SatDeliveryStart,
                                            End:dt.SatDeliveryEnd
                                          }
                                        ])).first(),
                              PickupClosingTimes: restaurant.Restaurant_PickupClosingTime.flatMap(r_pct => r_pct.PickupClosingTime.map(pct =>
                                        ({
                                          Start: pct.Start,
                                          End: pct.End,
                                          Message: pct.Message
                                        }))).toList(),
                              PickupOpeningTimes: restaurant.Restaurant_PickupTimes.flatMap(r_pt => r_pt.PickupTimes.map(pt =>
                                        [{
                                            Open: pt.SunPickup,
                                            Start: pt.SunPickupStart,
                                            End:pt.SunPickupEnd
                                          },
                                          {
                                            Open: pt.MondayPickup,
                                            Start: pt.MondayPickupStart,
                                            End:pt.MondayPickupEnd
                                          },
                                          {
                                            Open: pt.TuePickup,
                                            Start: pt.TuePickupStart,
                                            End:pt.TuePickupEnd
                                          },
                                          {
                                            Open: pt.WedPickup,
                                            Start: pt.WedPickupStart,
                                            End:pt.WedPickupEnd
                                          },
                                          {
                                            Open: pt.ThuPickup,
                                            Start: pt.ThuPickupStart,
                                            End:pt.ThuPickupEnd
                                          },
                                          {
                                            Open: pt.FriPickup,
                                            Start: pt.FriPickupStart,
                                            End:pt.FriPickupEnd
                                          },
                                          {
                                            Open: pt.SatPickup,
                                            Start: pt.SatPickupStart,
                                            End:pt.SatPickupEnd
                                          }
                                        ])).first(),
                              DeliveryPayMethods: restaurant.DeliveryRestaurant_DeliveryPayMethod.sortBy(dr_dpm => dr_dpm.DeliveryPayMethodId).flatMap(dr_dpm => dr_dpm.PayMethod.map(pm => ({Method: pm.Method, DisplayTitle: pm.DisplayTitle}))).toList(),
                              PickUpPayMethods: restaurant.PickUpRestaurant_PickUpPayMethod.sortBy(pur_pupm => pur_pupm.PickUpPayMethodId).flatMap(pur_pupm => pur_pupm.PayMethod.map(pm => ({Method: pm.Method, DisplayTitle: pm.DisplayTitle}))).toList()
                           }))
                           .sort((a, b) => a.Name.localeCompare(b.Name))
    return {
      kind: "result",
      status: 200,
      value: res.toArray()
    }
  } catch (err) {
    console.error("error: ", err)
    return {
      kind: "failed",
      status: 500
    }
  }
}

export const getUser = async (username: string): Promise<HttpResult<User>> => {
   try {
      let users = await UserQuery(userOdataQueryUri)
      .Select('Id', 'FirstName', 'LastName', 'Email', 'Username', 'CreatedDate', 'Language', 'EmailConfirmed', 'Phonenumber')
      .Include('User_DefaultAddress', ua => ua
        .Select('Id', 'DefaultAddressId', 'UserId')
        .IncludeStrict('DefaultAddress', a => a
          .Select('Id', 'Street', 'Number', 'IsDefault', 'Postcode', 'City', 'CreatedDate')
        )
      )
      .Include('User_AlternativeAddress', ua => ua
        .Select('Id', 'AlternativeAddressId', 'UserId')
        .IncludeStrict('AlternativeAddress', a => a
          .Select('Id', 'Street', 'Number', 'IsDefault', 'Postcode', 'City', 'CreatedDate')
        )
      )
      .Include('Company_User', cu => cu
        .Select('Id', 'CompanyId', 'UserId')
        .IncludeStrict('Company', c => c
          .Select('Id', 'CreatedDate', 'Name', 'Inactive', 'KVKNumber', 'Postcode', 'InvoiceCompanyName', 'InvoiceDepartment', 'InvoiceEmail', 'InvoiceHouseNumber', 'InvoiceStreet', 'InvoicePostCode', 'InvoiceCity', 'InvoiceMailbox')
          .IncludeStrict('Restaurant_Company', crc => crc
            .Select('Id', 'CompanyId', 'RestaurantId')
            .IncludeStrict('Restaurant', crcr => crcr.Select('Id', 'Name'))
          )
        )
      )
      .Where(c => c.Username.Equals(encodeURIComponent(username)))
      .ToList()

    if (users.isEmpty()) throw new Error('User not found')

    let user = users.first()

    let defaultAddresses = user.User_DefaultAddress.reduce(
          (map, ua) => {
            let address = ua.DefaultAddress.first()
            return map.set(address.Id, {
              Id: address.Id,
              CreatedDate: moment(address.CreatedDate),
              IsDefault: address.IsDefault,
              Street: address.Street,
              Number: address.Number,
              Postcode: address.Postcode,
              City: address.City,
              Kind: "DefaultAddress"
            })
          },
          Map<number, UserAddress>()
        )

    let alternativeAddresses = user.User_AlternativeAddress.reduce(
          (map, ua) => {
            let address = ua.AlternativeAddress.first()
            return map.set(address.Id, {
              Id: address.Id,
              CreatedDate: moment(address.CreatedDate),
              IsDefault: address.IsDefault,
              Street: address.Street,
              Number: address.Number,
              Postcode: address.Postcode,
              City: address.City,
              Kind: "AlternativeAddress"
            })
          }, Map<number, UserAddress>()
        )

    let company = !user.Company_User.isEmpty()
      ? user.Company_User.first().Company.first()
      : undefined

    return {
      kind: 'result',
      status: 200,
      value: {
        Id: user.Id,
        Username: user.Username,
        FirstName: user.FirstName,
        LastName: user.LastName,
        Email: user.Email,
        CreatedDate: moment(user.CreatedDate),
        EmailConfirmed: user.EmailConfirmed,
        Phonenumber: user.Phonenumber,
        HasPassword: true,
        Language: user.Language,
        Addresses: defaultAddresses.merge(alternativeAddresses),
        Company: !company ? none() : some({
          Id: company.Id,
          CreatedDate: moment(company.CreatedDate),
          Name: company.Name,
          Inactive: company.Inactive,
          KVKNumber: company.KVKNumber,
          Postcode: company.Postcode,
          InvoiceDepartment: company.InvoiceDepartment,
          InvoiceCompanyName: company.InvoiceCompanyName,
          InvoiceEmail: company.InvoiceEmail,
          InvoiceHouseNumber: company.InvoiceHouseNumber,
          InvoiceStreet: company.InvoiceStreet,
          InvoicePostCode: company.InvoicePostCode,
          InvoiceCity: company.InvoiceCity,
          InvoiceMailbox: company.InvoiceMailbox,
          Restaurant: {
            Id: company.Restaurant_Company.first().Restaurant.first().Id,
            Name: company.Restaurant_Company.first().Restaurant.first().Name
          }
        })
      }
    }
  } catch (e) {
    console.error("error: ", e)
    return { kind: 'failed', status: 500 }
  }
}