import { Fun, nothing, Widget, any } from "widgets-for-react"
import { ParsedNode, ParsedNodeRestaurant } from "../drupal/drupal_generated/types"
import { restaurantSchemaOrg } from "./schema_org"

export const bodyFooter: Fun<ParsedNode, Widget<{}>> = n => (
  n.bundle == "restaurant" 
    ? bodyFooterRestaurant(n) 
    : nothing()
  )

const bodyFooterRestaurant = (node: ParsedNodeRestaurant) => any()([
  restaurantSchemaOrg(node)
])
