import { List } from "immutable";
import * as React from "react";
import { Action, IOWidget } from "widgets-for-react";
import { ExtraImages } from "../public_site_utils/extra_images";
import { scrollTo } from "../public_site_utils/scroll";
import { ImageContainer } from "./ImageContainer";
import { CMSState } from "./state";
import { Id, ImageSize } from "./types";
import { jsxToWidget } from "./utils";
import * as i18next from "i18next"

export type MenuBlockProps = {
  groups: List<MenuGroup>
}

export type MenuGroup = {
  title: string
  items: List<MenuItem>
}

export type MenuItem =  MenuItemProduct | MenuItemPhoto

export type MenuItemProduct = {
  kind: "product"
  title: string
  text: string
  allergens: string
  price: string
  image: Id
  imageSize: ImageSize<ExtraImages>
  imageAltText: string
}

export type MenuItemPhoto = {
  kind: "photo"
  image: Id
  imageSize: ImageSize<ExtraImages>
  imageAltText: string
}

export type MenuBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
}>

export type MenuBlockState = {}


export function MenuBlock<CustomBlockData>(
  props: MenuBlockProps,
  options?: { style?: MenuBlockStyle; key?: string }
): IOWidget<CMSState<CustomBlockData, ExtraImages>, Action<CMSState<CustomBlockData, ExtraImages>>> {
  return (cmsState: CMSState<CustomBlockData, ExtraImages>) =>
    jsxToWidget(
      <div>
        {props.groups.count() > 1 && (
          <div className="block">
            <div className="block__inner">
              <h2>{i18next.t("products:categories")}</h2>
              <div className="categories">
                <ul className="categories__inner text--m">
                  {props.groups.map((group, i) => (
                    <li key={`menu-quick_link-${i}`} className="category">
                      <button className="category__link" onClick={() => scrollTo(document.querySelector(`[data-menu-group="${i}"]`))}>
                        <svg className="category__link-icon" aria-hidden="true"><use xlinkHref="#icon--caret-right" /></svg>
                        {group.title}
                      </button>
                    </li>
                  ))}
                </ul>
                <span className="categories__spacer hide--tablet-desktop" aria-hidden="true"></span>
              </div>
            </div>
          </div>
        )}

        {props.groups.map((group, i) => (
          <div
            key={`menu-group-${i}`}
            data-menu-group={i}
            className="block"
          >
            <div className="block__inner">
              <h2 className="products__title title--m">{group.title}</h2>

              {group.items.map((item, ii) => {
                const itemImage = cmsState.images.has(item.image)
                  ? ImageContainer(
                      cmsState.images.get(item.image),
                      item.imageSize,
                      "",
                      item.imageAltText
                    ).run(() => null)
                  : null

                return (
                  <>
                    {item.kind == "product" ? (
                      <div className="product product--menu" key={`menu-group-item-${i}-${ii}`}>
                        <div className="product__row">
                          <div className="product__titles">
                            <h3 className="product__title title--xs">
                              {item.title}
                              {item.price && (
                                <span className="product__price">{item.price}</span>
                              )}
                            </h3>
                            <p className="product__subtitle">{item.text}</p>
                            <p className="text--xs">{item.allergens}</p>
                          </div>
                          {item.image ? (
                            <figure className="product__visual">
                              {itemImage}
                            </figure>
                          ) : null }
                        </div>
                      </div>
                    ) : (
                      <div key={`menu-group-item-${i}-${ii}`} className="menu-image">{itemImage}</div>
                    )}
                  </>
                )
              })}
            </div>
          </div>
        ))}
      </div>
    )
}
