import * as Models from './generated_models'
import * as Immutable from 'immutable'
import * as Moment from 'moment'
import 'whatwg-fetch'
import {
  UrlTemplate, application, get_context, Route, Url, make_url, fallback_url, link_to_route,
  Option, C, Mode, unit, bind, string, number, bool, button, selector, some, none, multi_selector, label, h1, h2, div, form, image, link, file, overlay,
  custom, repeat, all, any, lift_promise, retract, delay,
  simple_menu, mk_menu_entry, mk_submenu_entry, MenuEntry, MenuEntryValue, MenuEntrySubMenu,
  rich_text, paginate, Page, list, editable_list
} from 'monadic_react'
import { LoginData, ResetData, ApiResult, ApiResultWithMessage, RegisterData, InviteData, ChangeData} from 'monadic_react_lib';
import { SearchQueryItem } from './generated_views/view_utils';
import { readDBTime } from './custom_components/utils'

export type ItemWithEditable<T> = {Item:T, Editable:boolean, JustCreated:boolean}

export type RawPage<T> = {
  Items:ItemWithEditable<T>[]
  PageIndex:number
  SearchQuery:string
  NumPages:number
  PageSize:number
  TotalCount:number
  CanCreate:boolean
  CanDelete:boolean
}

export let parse_date = <T>(e:any) : T&{CreatedDate:Moment.Moment} => { return { ...e, CreatedDate: readDBTime(e.CreatedDate) }}

export let make_page = <T>(res:any, parse_other_args:(e:any) => T) : RawPage<T> => { return {
  Items: res.Items.map((i:any) => { return{ ...i, Item:parse_date(i.Item)} }).map((i:any) => { return{ ...i, Item:parse_other_args(i.Item)} }),
  PageIndex: res.PageIndex,
  SearchQuery:res.SearchQuery,
  NumPages: res.NumPages,
  PageSize: res.PageSize,
  TotalCount: res.TotalCount,
  CanCreate: res.CanCreate,
  CanDelete: res.CanDelete
}}

var _global_project_name: string = null;
export let project_name_set = (project_name: string) => _global_project_name = project_name
export let project_name_get = () => (_global_project_name === null) ? "" : _global_project_name

export async function get_POS4_Application_POS4S(source:Models.POS4, page_index:number, page_size:number) : Promise<RawPage<Models.Application>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_Applications?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Application>(json, e => { return {...e, }})
}







export async function get_POS4_Application_POS4S_Application(source:Models.POS4, page_index:number, page_size:number, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}

export async function get_POS4_Application_POS4S_Application_by_id(source:Models.POS4, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}




export async function get_POS4_Restaurant_POS4S(source:Models.POS4, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}







export async function get_POS4_Restaurant_POS4S_Restaurant(source:Models.POS4, page_index:number, page_size:number, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function get_POS4_Restaurant_POS4S_Restaurant_by_id(source:Models.POS4, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}


export async function get_unlinked_POS4_Restaurant_POS4S(source:Models.POS4, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/unlinked/POS4_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}

    
export async function create_linked_POS4_Restaurant_POS4S_Restaurant(source:Models.POS4) : Promise<Models.Restaurant[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_Restaurants_Restaurant`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Restaurant[]
}

export async function link_POS4_Restaurant_POS4S(source:Models.POS4, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_Restaurants/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_POS4_Restaurant_POS4S(source:Models.POS4, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_Restaurants/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_POS4_POS4_POS4LoginEvents(source:Models.POS4, page_index:number, page_size:number) : Promise<RawPage<Models.POS4LoginEvent>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_POS4LoginEvents?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.POS4LoginEvent>(json, e => { return {...e, Date: readDBTime(e.Date)}})
}







export async function get_POS4_POS4_POS4LoginEvents_POS4LoginEvent(source:Models.POS4, page_index:number, page_size:number, id:number) : Promise<Models.POS4LoginEvent> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_POS4LoginEvents/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), Date: readDBTime(json.Date) } as Models.POS4LoginEvent
}

export async function get_POS4_POS4_POS4LoginEvents_POS4LoginEvent_by_id(source:Models.POS4, id:number) : Promise<Models.POS4LoginEvent> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_POS4LoginEvents/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), Date: readDBTime(json.Date) } as Models.POS4LoginEvent
}


export async function get_unlinked_POS4_POS4_POS4LoginEvents(source:Models.POS4, page_index:number, page_size:number) : Promise<RawPage<Models.POS4LoginEvent>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/unlinked/POS4_POS4LoginEvents?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.POS4LoginEvent>(json, e => { return {...e, Date: readDBTime(e.Date)}})
}

    
export async function create_linked_POS4_POS4_POS4LoginEvents_POS4LoginEvent(source:Models.POS4) : Promise<Models.POS4LoginEvent[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_POS4LoginEvents_POS4LoginEvent`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate), Date: readDBTime(e.Date) }}) as Models.POS4LoginEvent[]
}

export async function link_POS4_POS4_POS4LoginEvents(source:Models.POS4, target:Models.POS4LoginEvent) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_POS4LoginEvents/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_POS4_POS4_POS4LoginEvents(source:Models.POS4, target:Models.POS4LoginEvent) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_POS4LoginEvents/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_POS4_POS4_POS4OrderFetchEvents(source:Models.POS4, page_index:number, page_size:number) : Promise<RawPage<Models.POS4OrderFetchEvent>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_POS4OrderFetchEvents?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.POS4OrderFetchEvent>(json, e => { return {...e, Date: readDBTime(e.Date)}})
}







export async function get_POS4_POS4_POS4OrderFetchEvents_POS4OrderFetchEvent(source:Models.POS4, page_index:number, page_size:number, id:number) : Promise<Models.POS4OrderFetchEvent> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_POS4OrderFetchEvents/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), Date: readDBTime(json.Date) } as Models.POS4OrderFetchEvent
}

export async function get_POS4_POS4_POS4OrderFetchEvents_POS4OrderFetchEvent_by_id(source:Models.POS4, id:number) : Promise<Models.POS4OrderFetchEvent> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_POS4OrderFetchEvents/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), Date: readDBTime(json.Date) } as Models.POS4OrderFetchEvent
}


export async function get_unlinked_POS4_POS4_POS4OrderFetchEvents(source:Models.POS4, page_index:number, page_size:number) : Promise<RawPage<Models.POS4OrderFetchEvent>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/unlinked/POS4_POS4OrderFetchEvents?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.POS4OrderFetchEvent>(json, e => { return {...e, Date: readDBTime(e.Date)}})
}

    
export async function create_linked_POS4_POS4_POS4OrderFetchEvents_POS4OrderFetchEvent(source:Models.POS4) : Promise<Models.POS4OrderFetchEvent[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_POS4OrderFetchEvents_POS4OrderFetchEvent`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate), Date: readDBTime(e.Date) }}) as Models.POS4OrderFetchEvent[]
}

export async function link_POS4_POS4_POS4OrderFetchEvents(source:Models.POS4, target:Models.POS4OrderFetchEvent) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_POS4OrderFetchEvents/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_POS4_POS4_POS4OrderFetchEvents(source:Models.POS4, target:Models.POS4OrderFetchEvent) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${source.Id}/POS4_POS4OrderFetchEvents/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_POS4() : Promise<Models.POS4> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/POS4/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), TokenExpiration: readDBTime(json.TokenExpiration) } as Models.POS4
}

export async function update_POS4(item:Models.POS4) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/POS4/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_POS4_with_pictures(item:Models.POS4) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/POS4/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_POS4(source:Models.POS4) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/POS4/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_POS4(id:number) : Promise<ItemWithEditable<Models.POS4>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), TokenExpiration: readDBTime(json.Item.TokenExpiration) } as Models.POS4,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_POS4_with_pictures(id:number) : Promise<ItemWithEditable<Models.POS4>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), TokenExpiration: readDBTime(json.Item.TokenExpiration) } as Models.POS4,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_POS4S(page_index:number, page_size:number) : Promise<RawPage<Models.POS4>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.POS4>(json, e => { return {...e, TokenExpiration: readDBTime(e.TokenExpiration)}})
}










export async function delete_POS4_sessions() : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/POS4/DeleteSessions`,
    { method: 'post', credentials: 'include',
      headers: headers
    })
  return
}

export async function active_POS4_sessions() : Promise<Array<{Item1: string, Item2: Date}>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/POS4/ActiveSessions`,
    { method: 'post', credentials: 'include',
      headers: headers
    })
  if (!res.ok) return []
  let json = await res.json()
  return json as Array<{Item1: string, Item2: Date}>
}

export async function change_POS4_Email(oldEmail:string, newEmail: string, password: string) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  
  let res = await fetch(`/api/v1/POS4/ChangeEmail`,
    { method: 'post', credentials: 'include',
      body: JSON.stringify({OldEmail: oldEmail, NewEmail: newEmail, Password: password}),
      headers: headers})
    if (!res.ok) throw Error(res.statusText)
    return
}

export async function verify_POS4_EmailToken(oldEmail:string, newEmail: string, token: string) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  
  let res = await fetch(`/api/v1/POS4/ChangeEmailConfirm`,
    { method: 'post', credentials: 'include',
      body: JSON.stringify({OldEmail:oldEmail, NewEmail: newEmail, Token: token}),
      headers: headers})
    if (!res.ok) throw Error(res.statusText)
    return
}

export async function change_POS4_Username(email: string, username: string, password: string) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  
  let res = await fetch(`/api/v1/POS4/ChangeUsername`,
    { method: 'post', credentials: 'include',
      body: JSON.stringify({Email: email, Username: username, Password: password}),
      headers: headers})
    if (!res.ok) throw Error(res.statusText)
    return
}

export async function change_POS4_password(username:string, email:string, password:string, new_password:string, new_password_confirmation:string) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  
  let res = await fetch(`/api/v1/POS4/ChangePassword`,
    { method: 'post', credentials: 'include',
      body: JSON.stringify({Username:username, Email:email, Password:password, NewPassword:new_password, NewPasswordConfirmation:new_password_confirmation}),
      headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}
  
  
export async function get_Discount_Application_Discounts(source:Models.Discount, page_index:number, page_size:number) : Promise<RawPage<Models.Application>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Applications?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Application>(json, e => { return {...e, }})
}







export async function get_Discount_Application_Discounts_Application(source:Models.Discount, page_index:number, page_size:number, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}

export async function get_Discount_Application_Discounts_Application_by_id(source:Models.Discount, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}




export async function get_Discount_Order_Discounts(source:Models.Discount, page_index:number, page_size:number) : Promise<RawPage<Models.Order>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Orders?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Order>(json, e => { return {...e, DeliveryOrPickupTime: readDBTime(e.DeliveryOrPickupTime)}})
}







export async function get_Discount_Order_Discounts_Order(source:Models.Discount, page_index:number, page_size:number, id:number) : Promise<Models.Order> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Orders/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), DeliveryOrPickupTime: readDBTime(json.DeliveryOrPickupTime) } as Models.Order
}

export async function get_Discount_Order_Discounts_Order_by_id(source:Models.Discount, id:number) : Promise<Models.Order> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Orders/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), DeliveryOrPickupTime: readDBTime(json.DeliveryOrPickupTime) } as Models.Order
}


export async function get_unlinked_Discount_Order_Discounts(source:Models.Discount, page_index:number, page_size:number) : Promise<RawPage<Models.Order>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/unlinked/Discount_Orders?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Order>(json, e => { return {...e, DeliveryOrPickupTime: readDBTime(e.DeliveryOrPickupTime)}})
}

    
export async function create_linked_Discount_Order_Discounts_Order(source:Models.Discount) : Promise<Models.Order[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Orders_Order`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate), DeliveryOrPickupTime: readDBTime(e.DeliveryOrPickupTime) }}) as Models.Order[]
}

export async function link_Discount_Order_Discounts(source:Models.Discount, target:Models.Order) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Orders/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Discount_Order_Discounts(source:Models.Discount, target:Models.Order) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Orders/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Discount_Product_Discounts(source:Models.Discount, page_index:number, page_size:number) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Products?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}


export async function search_Discount_Product_Discounts(source:Models.Discount, page_index:number, page_size:number, search_value: SearchQueryItem) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let body;
  if(search_value.searchQueryString && search_value.searchQueryString.length > 0)
    body = {
    "relationship": {
          "firstTable": "Product",
          "joins": [
            {
              "item1": {
                "table": "Product_Discount",
                "source": "Product",
                "target": "Discount"
              },
              "item2": "Discount"
            }
          ]
      }
      , "expression" :  {
  "kind": "And",
  "left": {
  "kind": "Or",
  "left": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Product.Name"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
},
  "right": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Product.InternalName"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
}
},
  "right": {
    "kind": "Eq",
    "left": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Discount.Id"
      }
    },
    "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "Integer",
        "value": source.Id
      }
    }
  }
}
      , "aggregations": []
  }
  else
    body = {
    "relationship": {
          "firstTable": "Product",
          "joins": [
            {
              "item1": {
                "table": "Product_Discount",
                "source": "Product",
                "target": "Discount"
              },
              "item2": "Discount"
            }
          ]
      }
      , "expression" :  {
    "kind": "Eq",
    "left": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Discount.Id"
      }
    },
    "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "Integer",
        "value": source.Id
      }
    }
  }
      , "aggregations": []
  }

  let res = await fetch(`/api/search?page_index=${page_index}&page_size=${page_size}`, { method: 'post', credentials: 'include', headers: headers, body: JSON.stringify(body) })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}






export async function get_Discount_Product_Discounts_Product(source:Models.Discount, page_index:number, page_size:number, id:number) : Promise<Models.Product> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Products/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Product
}

export async function get_Discount_Product_Discounts_Product_by_id(source:Models.Discount, id:number) : Promise<Models.Product> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Products/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Product
}


export async function get_unlinked_Discount_Product_Discounts(source:Models.Discount, page_index:number, page_size:number) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/unlinked/Discount_Products?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}

    
export async function create_linked_Discount_Product_Discounts_Product(source:Models.Discount) : Promise<Models.Product[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Products_Product`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Product[]
}

export async function link_Discount_Product_Discounts(source:Models.Discount, target:Models.Product) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Products/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Discount_Product_Discounts(source:Models.Discount, target:Models.Product) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Products/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Discount_Restaurant_Discounts(source:Models.Discount, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}







export async function get_Discount_Restaurant_Discounts_Restaurant(source:Models.Discount, page_index:number, page_size:number, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function get_Discount_Restaurant_Discounts_Restaurant_by_id(source:Models.Discount, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}


export async function get_unlinked_Discount_Restaurant_Discounts(source:Models.Discount, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/unlinked/Discount_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}

    
export async function create_linked_Discount_Restaurant_Discounts_Restaurant(source:Models.Discount) : Promise<Models.Restaurant[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Restaurants_Restaurant`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Restaurant[]
}

export async function link_Discount_Restaurant_Discounts(source:Models.Discount, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Restaurants/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Discount_Restaurant_Discounts(source:Models.Discount, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${source.Id}/Discount_Restaurants/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_Discount() : Promise<Models.Discount> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Discount/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), EndDate: readDBTime(json.EndDate), StartDate: readDBTime(json.StartDate) } as Models.Discount
}

export async function update_Discount(item:Models.Discount) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Discount/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_Discount_with_pictures(item:Models.Discount) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Discount/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_Discount(source:Models.Discount) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/Discount/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_Discount(id:number) : Promise<ItemWithEditable<Models.Discount>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), EndDate: readDBTime(json.Item.EndDate), StartDate: readDBTime(json.Item.StartDate) } as Models.Discount,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Discount_with_pictures(id:number) : Promise<ItemWithEditable<Models.Discount>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), EndDate: readDBTime(json.Item.EndDate), StartDate: readDBTime(json.Item.StartDate) } as Models.Discount,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Discounts(page_index:number, page_size:number) : Promise<RawPage<Models.Discount>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Discount?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Discount>(json, e => { return {...e, EndDate: readDBTime(e.EndDate), StartDate: readDBTime(e.StartDate)}})
}










  
  
export async function get_PostCodeExclusion_PostCodeRange_PostCodeExclusions(source:Models.PostCodeExclusion, page_index:number, page_size:number) : Promise<RawPage<Models.PostCodeRange>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeExclusion/${source.Id}/PostCodeExclusion_PostCodeRanges?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PostCodeRange>(json, e => { return {...e, }})
}







export async function get_PostCodeExclusion_PostCodeRange_PostCodeExclusions_PostCodeRange(source:Models.PostCodeExclusion, page_index:number, page_size:number, id:number) : Promise<Models.PostCodeRange> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeExclusion/${source.Id}/PostCodeExclusion_PostCodeRanges/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.PostCodeRange
}

export async function get_PostCodeExclusion_PostCodeRange_PostCodeExclusions_PostCodeRange_by_id(source:Models.PostCodeExclusion, id:number) : Promise<Models.PostCodeRange> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeExclusion/${source.Id}/PostCodeExclusion_PostCodeRanges/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.PostCodeRange
}


export async function get_unlinked_PostCodeExclusion_PostCodeRange_PostCodeExclusions(source:Models.PostCodeExclusion, page_index:number, page_size:number) : Promise<RawPage<Models.PostCodeRange>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeExclusion/${source.Id}/unlinked/PostCodeExclusion_PostCodeRanges?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PostCodeRange>(json, e => { return {...e, }})
}

    
export async function create_linked_PostCodeExclusion_PostCodeRange_PostCodeExclusions_PostCodeRange(source:Models.PostCodeExclusion) : Promise<Models.PostCodeRange[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeExclusion/${source.Id}/PostCodeExclusion_PostCodeRanges_PostCodeRange`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.PostCodeRange[]
}

export async function link_PostCodeExclusion_PostCodeRange_PostCodeExclusions(source:Models.PostCodeExclusion, target:Models.PostCodeRange) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeExclusion/${source.Id}/PostCodeExclusion_PostCodeRanges/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_PostCodeExclusion_PostCodeRange_PostCodeExclusions(source:Models.PostCodeExclusion, target:Models.PostCodeRange) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeExclusion/${source.Id}/PostCodeExclusion_PostCodeRanges/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_PostCodeExclusion() : Promise<Models.PostCodeExclusion> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/PostCodeExclusion/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.PostCodeExclusion
}

export async function update_PostCodeExclusion(item:Models.PostCodeExclusion) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/PostCodeExclusion/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_PostCodeExclusion_with_pictures(item:Models.PostCodeExclusion) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/PostCodeExclusion/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_PostCodeExclusion(source:Models.PostCodeExclusion) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/PostCodeExclusion/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_PostCodeExclusion(id:number) : Promise<ItemWithEditable<Models.PostCodeExclusion>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeExclusion/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.PostCodeExclusion,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_PostCodeExclusion_with_pictures(id:number) : Promise<ItemWithEditable<Models.PostCodeExclusion>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeExclusion/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.PostCodeExclusion,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_PostCodeExclusions(page_index:number, page_size:number) : Promise<RawPage<Models.PostCodeExclusion>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeExclusion?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PostCodeExclusion>(json, e => { return {...e, }})
}










  
  
export async function get_OrderItem_Order_OrderItems(source:Models.OrderItem, page_index:number, page_size:number) : Promise<RawPage<Models.Order>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/OrderItem_Orders?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Order>(json, e => { return {...e, DeliveryOrPickupTime: readDBTime(e.DeliveryOrPickupTime)}})
}







export async function get_OrderItem_Order_OrderItems_Order(source:Models.OrderItem, page_index:number, page_size:number, id:number) : Promise<Models.Order> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/OrderItem_Orders/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), DeliveryOrPickupTime: readDBTime(json.DeliveryOrPickupTime) } as Models.Order
}

export async function get_OrderItem_Order_OrderItems_Order_by_id(source:Models.OrderItem, id:number) : Promise<Models.Order> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/OrderItem_Orders/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), DeliveryOrPickupTime: readDBTime(json.DeliveryOrPickupTime) } as Models.Order
}


export async function get_unlinked_OrderItem_Order_OrderItems(source:Models.OrderItem, page_index:number, page_size:number) : Promise<RawPage<Models.Order>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/unlinked/OrderItem_Orders?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Order>(json, e => { return {...e, DeliveryOrPickupTime: readDBTime(e.DeliveryOrPickupTime)}})
}

    
export async function create_linked_OrderItem_Order_OrderItems_Order(source:Models.OrderItem) : Promise<Models.Order[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/OrderItem_Orders_Order`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate), DeliveryOrPickupTime: readDBTime(e.DeliveryOrPickupTime) }}) as Models.Order[]
}

export async function link_OrderItem_Order_OrderItems(source:Models.OrderItem, target:Models.Order) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/OrderItem_Orders/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_OrderItem_Order_OrderItems(source:Models.OrderItem, target:Models.Order) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/OrderItem_Orders/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_OrderItem_OrderItem_OrderItemProducts(source:Models.OrderItem, page_index:number, page_size:number) : Promise<RawPage<Models.OrderItemProduct>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/OrderItem_OrderItemProducts?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OrderItemProduct>(json, e => { return {...e, }})
}







export async function get_OrderItem_OrderItem_OrderItemProducts_OrderItemProduct(source:Models.OrderItem, page_index:number, page_size:number, id:number) : Promise<Models.OrderItemProduct> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/OrderItem_OrderItemProducts/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OrderItemProduct
}

export async function get_OrderItem_OrderItem_OrderItemProducts_OrderItemProduct_by_id(source:Models.OrderItem, id:number) : Promise<Models.OrderItemProduct> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/OrderItem_OrderItemProducts/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OrderItemProduct
}


export async function get_unlinked_OrderItem_OrderItem_OrderItemProducts(source:Models.OrderItem, page_index:number, page_size:number) : Promise<RawPage<Models.OrderItemProduct>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/unlinked/OrderItem_OrderItemProducts?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OrderItemProduct>(json, e => { return {...e, }})
}

    
export async function create_linked_OrderItem_OrderItem_OrderItemProducts_OrderItemProduct(source:Models.OrderItem) : Promise<Models.OrderItemProduct[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/OrderItem_OrderItemProducts_OrderItemProduct`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.OrderItemProduct[]
}

export async function link_OrderItem_OrderItem_OrderItemProducts(source:Models.OrderItem, target:Models.OrderItemProduct) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/OrderItem_OrderItemProducts/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_OrderItem_OrderItem_OrderItemProducts(source:Models.OrderItem, target:Models.OrderItemProduct) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/OrderItem_OrderItemProducts/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_OrderItem_OrderItem_OrderItemOptions(source:Models.OrderItem, page_index:number, page_size:number) : Promise<RawPage<Models.OrderItemOption>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/OrderItem_OrderItemOptions?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OrderItemOption>(json, e => { return {...e, }})
}







export async function get_OrderItem_OrderItem_OrderItemOptions_OrderItemOption(source:Models.OrderItem, page_index:number, page_size:number, id:number) : Promise<Models.OrderItemOption> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/OrderItem_OrderItemOptions/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OrderItemOption
}

export async function get_OrderItem_OrderItem_OrderItemOptions_OrderItemOption_by_id(source:Models.OrderItem, id:number) : Promise<Models.OrderItemOption> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/OrderItem_OrderItemOptions/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OrderItemOption
}


export async function get_unlinked_OrderItem_OrderItem_OrderItemOptions(source:Models.OrderItem, page_index:number, page_size:number) : Promise<RawPage<Models.OrderItemOption>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/unlinked/OrderItem_OrderItemOptions?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OrderItemOption>(json, e => { return {...e, }})
}

    
export async function create_linked_OrderItem_OrderItem_OrderItemOptions_OrderItemOption(source:Models.OrderItem) : Promise<Models.OrderItemOption[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/OrderItem_OrderItemOptions_OrderItemOption`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.OrderItemOption[]
}

export async function link_OrderItem_OrderItem_OrderItemOptions(source:Models.OrderItem, target:Models.OrderItemOption) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/OrderItem_OrderItemOptions/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_OrderItem_OrderItem_OrderItemOptions(source:Models.OrderItem, target:Models.OrderItemOption) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${source.Id}/OrderItem_OrderItemOptions/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_OrderItem() : Promise<Models.OrderItem> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/OrderItem/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OrderItem
}

export async function update_OrderItem(item:Models.OrderItem) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/OrderItem/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_OrderItem_with_pictures(item:Models.OrderItem) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/OrderItem/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_OrderItem(source:Models.OrderItem) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/OrderItem/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_OrderItem(id:number) : Promise<ItemWithEditable<Models.OrderItem>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.OrderItem,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_OrderItem_with_pictures(id:number) : Promise<ItemWithEditable<Models.OrderItem>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.OrderItem,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_OrderItems(page_index:number, page_size:number) : Promise<RawPage<Models.OrderItem>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItem?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OrderItem>(json, e => { return {...e, }})
}










  
  
export async function get_PickupClosingTime_Restaurant_PickupClosingTimes(source:Models.PickupClosingTime, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupClosingTime/${source.Id}/PickupClosingTime_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}







export async function get_PickupClosingTime_Restaurant_PickupClosingTimes_Restaurant(source:Models.PickupClosingTime, page_index:number, page_size:number, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupClosingTime/${source.Id}/PickupClosingTime_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function get_PickupClosingTime_Restaurant_PickupClosingTimes_Restaurant_by_id(source:Models.PickupClosingTime, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupClosingTime/${source.Id}/PickupClosingTime_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}


export async function get_unlinked_PickupClosingTime_Restaurant_PickupClosingTimes(source:Models.PickupClosingTime, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupClosingTime/${source.Id}/unlinked/PickupClosingTime_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}

    
export async function create_linked_PickupClosingTime_Restaurant_PickupClosingTimes_Restaurant(source:Models.PickupClosingTime) : Promise<Models.Restaurant[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupClosingTime/${source.Id}/PickupClosingTime_Restaurants_Restaurant`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Restaurant[]
}

export async function link_PickupClosingTime_Restaurant_PickupClosingTimes(source:Models.PickupClosingTime, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupClosingTime/${source.Id}/PickupClosingTime_Restaurants/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_PickupClosingTime_Restaurant_PickupClosingTimes(source:Models.PickupClosingTime, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupClosingTime/${source.Id}/PickupClosingTime_Restaurants/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_PickupClosingTime() : Promise<Models.PickupClosingTime> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/PickupClosingTime/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), Start: readDBTime(json.Start), End: readDBTime(json.End) } as Models.PickupClosingTime
}

export async function update_PickupClosingTime(item:Models.PickupClosingTime) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/PickupClosingTime/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_PickupClosingTime_with_pictures(item:Models.PickupClosingTime) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/PickupClosingTime/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_PickupClosingTime(source:Models.PickupClosingTime) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/PickupClosingTime/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_PickupClosingTime(id:number) : Promise<ItemWithEditable<Models.PickupClosingTime>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupClosingTime/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), Start: readDBTime(json.Item.Start), End: readDBTime(json.Item.End) } as Models.PickupClosingTime,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_PickupClosingTime_with_pictures(id:number) : Promise<ItemWithEditable<Models.PickupClosingTime>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupClosingTime/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), Start: readDBTime(json.Item.Start), End: readDBTime(json.Item.End) } as Models.PickupClosingTime,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_PickupClosingTimes(page_index:number, page_size:number) : Promise<RawPage<Models.PickupClosingTime>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupClosingTime?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PickupClosingTime>(json, e => { return {...e, Start: readDBTime(e.Start), End: readDBTime(e.End)}})
}










  
  
export async function get_AlternativeAddress_User_AlternativeAddresses(source:Models.AlternativeAddress, page_index:number, page_size:number) : Promise<RawPage<Models.User>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/AlternativeAddress/${source.Id}/AlternativeAddress_Users?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.User>(json, e => { return {...e, }})
}







export async function get_AlternativeAddress_User_AlternativeAddresses_User(source:Models.AlternativeAddress, page_index:number, page_size:number, id:number) : Promise<Models.User> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/AlternativeAddress/${source.Id}/AlternativeAddress_Users/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.User
}

export async function get_AlternativeAddress_User_AlternativeAddresses_User_by_id(source:Models.AlternativeAddress, id:number) : Promise<Models.User> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/AlternativeAddress/${source.Id}/AlternativeAddress_Users/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.User
}


export async function get_unlinked_AlternativeAddress_User_AlternativeAddresses(source:Models.AlternativeAddress, page_index:number, page_size:number) : Promise<RawPage<Models.User>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/AlternativeAddress/${source.Id}/unlinked/AlternativeAddress_Users?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.User>(json, e => { return {...e, }})
}

    
export async function create_linked_AlternativeAddress_User_AlternativeAddresses_User(source:Models.AlternativeAddress) : Promise<Models.User[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/AlternativeAddress/${source.Id}/AlternativeAddress_Users_User`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.User[]
}

export async function link_AlternativeAddress_User_AlternativeAddresses(source:Models.AlternativeAddress, target:Models.User) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/AlternativeAddress/${source.Id}/AlternativeAddress_Users/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_AlternativeAddress_User_AlternativeAddresses(source:Models.AlternativeAddress, target:Models.User) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/AlternativeAddress/${source.Id}/AlternativeAddress_Users/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_AlternativeAddress() : Promise<Models.AlternativeAddress> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/AlternativeAddress/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.AlternativeAddress
}

export async function update_AlternativeAddress(item:Models.AlternativeAddress) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/AlternativeAddress/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_AlternativeAddress_with_pictures(item:Models.AlternativeAddress) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/AlternativeAddress/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_AlternativeAddress(source:Models.AlternativeAddress) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/AlternativeAddress/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_AlternativeAddress(id:number) : Promise<ItemWithEditable<Models.AlternativeAddress>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/AlternativeAddress/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.AlternativeAddress,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_AlternativeAddress_with_pictures(id:number) : Promise<ItemWithEditable<Models.AlternativeAddress>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/AlternativeAddress/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.AlternativeAddress,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_AlternativeAddresses(page_index:number, page_size:number) : Promise<RawPage<Models.AlternativeAddress>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/AlternativeAddress?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.AlternativeAddress>(json, e => { return {...e, }})
}










  
  
export async function get_PayMethod_Application_PayMethods(source:Models.PayMethod, page_index:number, page_size:number) : Promise<RawPage<Models.Application>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/PayMethod_Applications?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Application>(json, e => { return {...e, }})
}







export async function get_PayMethod_Application_PayMethods_Application(source:Models.PayMethod, page_index:number, page_size:number, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/PayMethod_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}

export async function get_PayMethod_Application_PayMethods_Application_by_id(source:Models.PayMethod, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/PayMethod_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}




export async function get_PickUpPayMethod_PickUpRestaurant_PickUpPayMethods(source:Models.PayMethod, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/PickUpPayMethod_PickUpRestaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}







export async function get_PickUpPayMethod_PickUpRestaurant_PickUpPayMethods_Restaurant(source:Models.PayMethod, page_index:number, page_size:number, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/PickUpPayMethod_PickUpRestaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function get_PickUpPayMethod_PickUpRestaurant_PickUpPayMethods_Restaurant_by_id(source:Models.PayMethod, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/PickUpPayMethod_PickUpRestaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}


export async function get_unlinked_PickUpPayMethod_PickUpRestaurant_PickUpPayMethods(source:Models.PayMethod, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/unlinked/PickUpPayMethod_PickUpRestaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}

    
export async function create_linked_PickUpPayMethod_PickUpRestaurant_PickUpPayMethods_Restaurant(source:Models.PayMethod) : Promise<Models.Restaurant[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/PickUpPayMethod_PickUpRestaurants_Restaurant`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Restaurant[]
}

export async function link_PickUpPayMethod_PickUpRestaurant_PickUpPayMethods(source:Models.PayMethod, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/PickUpPayMethod_PickUpRestaurants/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_PickUpPayMethod_PickUpRestaurant_PickUpPayMethods(source:Models.PayMethod, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/PickUpPayMethod_PickUpRestaurants/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_DeliveryPayMethod_DeliveryRestaurant_DeliveryPayMethods(source:Models.PayMethod, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/DeliveryPayMethod_DeliveryRestaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}







export async function get_DeliveryPayMethod_DeliveryRestaurant_DeliveryPayMethods_Restaurant(source:Models.PayMethod, page_index:number, page_size:number, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/DeliveryPayMethod_DeliveryRestaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function get_DeliveryPayMethod_DeliveryRestaurant_DeliveryPayMethods_Restaurant_by_id(source:Models.PayMethod, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/DeliveryPayMethod_DeliveryRestaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}


export async function get_unlinked_DeliveryPayMethod_DeliveryRestaurant_DeliveryPayMethods(source:Models.PayMethod, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/unlinked/DeliveryPayMethod_DeliveryRestaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}

    
export async function create_linked_DeliveryPayMethod_DeliveryRestaurant_DeliveryPayMethods_Restaurant(source:Models.PayMethod) : Promise<Models.Restaurant[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/DeliveryPayMethod_DeliveryRestaurants_Restaurant`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Restaurant[]
}

export async function link_DeliveryPayMethod_DeliveryRestaurant_DeliveryPayMethods(source:Models.PayMethod, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/DeliveryPayMethod_DeliveryRestaurants/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_DeliveryPayMethod_DeliveryRestaurant_DeliveryPayMethods(source:Models.PayMethod, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/DeliveryPayMethod_DeliveryRestaurants/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_PayMethod_Order_PayMethods(source:Models.PayMethod, page_index:number, page_size:number) : Promise<RawPage<Models.Order>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/PayMethod_Orders?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Order>(json, e => { return {...e, DeliveryOrPickupTime: readDBTime(e.DeliveryOrPickupTime)}})
}







export async function get_PayMethod_Order_PayMethods_Order(source:Models.PayMethod, page_index:number, page_size:number, id:number) : Promise<Models.Order> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/PayMethod_Orders/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), DeliveryOrPickupTime: readDBTime(json.DeliveryOrPickupTime) } as Models.Order
}

export async function get_PayMethod_Order_PayMethods_Order_by_id(source:Models.PayMethod, id:number) : Promise<Models.Order> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/PayMethod_Orders/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), DeliveryOrPickupTime: readDBTime(json.DeliveryOrPickupTime) } as Models.Order
}


export async function get_unlinked_PayMethod_Order_PayMethods(source:Models.PayMethod, page_index:number, page_size:number) : Promise<RawPage<Models.Order>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/unlinked/PayMethod_Orders?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Order>(json, e => { return {...e, DeliveryOrPickupTime: readDBTime(e.DeliveryOrPickupTime)}})
}

    
export async function create_linked_PayMethod_Order_PayMethods_Order(source:Models.PayMethod) : Promise<Models.Order[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/PayMethod_Orders_Order`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate), DeliveryOrPickupTime: readDBTime(e.DeliveryOrPickupTime) }}) as Models.Order[]
}

export async function link_PayMethod_Order_PayMethods(source:Models.PayMethod, target:Models.Order) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/PayMethod_Orders/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_PayMethod_Order_PayMethods(source:Models.PayMethod, target:Models.Order) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${source.Id}/PayMethod_Orders/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_PayMethod() : Promise<Models.PayMethod> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/PayMethod/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.PayMethod
}

export async function update_PayMethod(item:Models.PayMethod) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/PayMethod/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_PayMethod_with_pictures(item:Models.PayMethod) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/PayMethod/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_PayMethod(source:Models.PayMethod) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/PayMethod/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_PayMethod(id:number) : Promise<ItemWithEditable<Models.PayMethod>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.PayMethod,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_PayMethod_with_pictures(id:number) : Promise<ItemWithEditable<Models.PayMethod>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.PayMethod,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_PayMethods(page_index:number, page_size:number) : Promise<RawPage<Models.PayMethod>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PayMethod?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PayMethod>(json, e => { return {...e, }})
}










  
  
export async function get_PickupTimes_Restaurant_PickupTimess(source:Models.PickupTimes, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupTimes/${source.Id}/PickupTimes_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}







export async function get_PickupTimes_Restaurant_PickupTimess_Restaurant(source:Models.PickupTimes, page_index:number, page_size:number, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupTimes/${source.Id}/PickupTimes_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function get_PickupTimes_Restaurant_PickupTimess_Restaurant_by_id(source:Models.PickupTimes, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupTimes/${source.Id}/PickupTimes_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}


export async function get_unlinked_PickupTimes_Restaurant_PickupTimess(source:Models.PickupTimes, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupTimes/${source.Id}/unlinked/PickupTimes_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}

    
export async function create_linked_PickupTimes_Restaurant_PickupTimess_Restaurant(source:Models.PickupTimes) : Promise<Models.Restaurant[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupTimes/${source.Id}/PickupTimes_Restaurants_Restaurant`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Restaurant[]
}

export async function link_PickupTimes_Restaurant_PickupTimess(source:Models.PickupTimes, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupTimes/${source.Id}/PickupTimes_Restaurants/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_PickupTimes_Restaurant_PickupTimess(source:Models.PickupTimes, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupTimes/${source.Id}/PickupTimes_Restaurants/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_PickupTimes() : Promise<Models.PickupTimes> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/PickupTimes/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), SatPickupStart: readDBTime(json.SatPickupStart), FriPickupEnd: readDBTime(json.FriPickupEnd), TuePickupStart: readDBTime(json.TuePickupStart), ThuPickupStart: readDBTime(json.ThuPickupStart), FriPickupStart: readDBTime(json.FriPickupStart), WedPickupEnd: readDBTime(json.WedPickupEnd), MondayPickupEnd: readDBTime(json.MondayPickupEnd), WedPickupStart: readDBTime(json.WedPickupStart), SunPickupEnd: readDBTime(json.SunPickupEnd), MondayPickupStart: readDBTime(json.MondayPickupStart), SatPickupEnd: readDBTime(json.SatPickupEnd), SunPickupStart: readDBTime(json.SunPickupStart), TuePickupEnd: readDBTime(json.TuePickupEnd), ThuPickupEnd: readDBTime(json.ThuPickupEnd) } as Models.PickupTimes
}

export async function update_PickupTimes(item:Models.PickupTimes) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/PickupTimes/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_PickupTimes_with_pictures(item:Models.PickupTimes) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/PickupTimes/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_PickupTimes(source:Models.PickupTimes) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/PickupTimes/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_PickupTimes(id:number) : Promise<ItemWithEditable<Models.PickupTimes>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupTimes/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), SatPickupStart: readDBTime(json.Item.SatPickupStart), FriPickupEnd: readDBTime(json.Item.FriPickupEnd), TuePickupStart: readDBTime(json.Item.TuePickupStart), ThuPickupStart: readDBTime(json.Item.ThuPickupStart), FriPickupStart: readDBTime(json.Item.FriPickupStart), WedPickupEnd: readDBTime(json.Item.WedPickupEnd), MondayPickupEnd: readDBTime(json.Item.MondayPickupEnd), WedPickupStart: readDBTime(json.Item.WedPickupStart), SunPickupEnd: readDBTime(json.Item.SunPickupEnd), MondayPickupStart: readDBTime(json.Item.MondayPickupStart), SatPickupEnd: readDBTime(json.Item.SatPickupEnd), SunPickupStart: readDBTime(json.Item.SunPickupStart), TuePickupEnd: readDBTime(json.Item.TuePickupEnd), ThuPickupEnd: readDBTime(json.Item.ThuPickupEnd) } as Models.PickupTimes,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_PickupTimes_with_pictures(id:number) : Promise<ItemWithEditable<Models.PickupTimes>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupTimes/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), SatPickupStart: readDBTime(json.Item.SatPickupStart), FriPickupEnd: readDBTime(json.Item.FriPickupEnd), TuePickupStart: readDBTime(json.Item.TuePickupStart), ThuPickupStart: readDBTime(json.Item.ThuPickupStart), FriPickupStart: readDBTime(json.Item.FriPickupStart), WedPickupEnd: readDBTime(json.Item.WedPickupEnd), MondayPickupEnd: readDBTime(json.Item.MondayPickupEnd), WedPickupStart: readDBTime(json.Item.WedPickupStart), SunPickupEnd: readDBTime(json.Item.SunPickupEnd), MondayPickupStart: readDBTime(json.Item.MondayPickupStart), SatPickupEnd: readDBTime(json.Item.SatPickupEnd), SunPickupStart: readDBTime(json.Item.SunPickupStart), TuePickupEnd: readDBTime(json.Item.TuePickupEnd), ThuPickupEnd: readDBTime(json.Item.ThuPickupEnd) } as Models.PickupTimes,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_PickupTimess(page_index:number, page_size:number) : Promise<RawPage<Models.PickupTimes>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PickupTimes?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PickupTimes>(json, e => { return {...e, SatPickupStart: readDBTime(e.SatPickupStart), FriPickupEnd: readDBTime(e.FriPickupEnd), TuePickupStart: readDBTime(e.TuePickupStart), ThuPickupStart: readDBTime(e.ThuPickupStart), FriPickupStart: readDBTime(e.FriPickupStart), WedPickupEnd: readDBTime(e.WedPickupEnd), MondayPickupEnd: readDBTime(e.MondayPickupEnd), WedPickupStart: readDBTime(e.WedPickupStart), SunPickupEnd: readDBTime(e.SunPickupEnd), MondayPickupStart: readDBTime(e.MondayPickupStart), SatPickupEnd: readDBTime(e.SatPickupEnd), SunPickupStart: readDBTime(e.SunPickupStart), TuePickupEnd: readDBTime(e.TuePickupEnd), ThuPickupEnd: readDBTime(e.ThuPickupEnd)}})
}










  
  
export async function get_OptionGroup_Application_OptionGroups(source:Models.OptionGroup, page_index:number, page_size:number) : Promise<RawPage<Models.Application>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${source.Id}/OptionGroup_Applications?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Application>(json, e => { return {...e, }})
}







export async function get_OptionGroup_Application_OptionGroups_Application(source:Models.OptionGroup, page_index:number, page_size:number, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${source.Id}/OptionGroup_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}

export async function get_OptionGroup_Application_OptionGroups_Application_by_id(source:Models.OptionGroup, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${source.Id}/OptionGroup_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}




export async function get_OptionGroup_OptionGroup_OptionGroupPositions(source:Models.OptionGroup, page_index:number, page_size:number) : Promise<RawPage<Models.OptionGroupPosition>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${source.Id}/OptionGroup_OptionGroupPositions?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OptionGroupPosition>(json, e => { return {...e, }})
}







export async function get_OptionGroup_OptionGroup_OptionGroupPositions_OptionGroupPosition(source:Models.OptionGroup, page_index:number, page_size:number, id:number) : Promise<Models.OptionGroupPosition> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${source.Id}/OptionGroup_OptionGroupPositions/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OptionGroupPosition
}

export async function get_OptionGroup_OptionGroup_OptionGroupPositions_OptionGroupPosition_by_id(source:Models.OptionGroup, id:number) : Promise<Models.OptionGroupPosition> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${source.Id}/OptionGroup_OptionGroupPositions/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OptionGroupPosition
}


export async function get_unlinked_OptionGroup_OptionGroup_OptionGroupPositions(source:Models.OptionGroup, page_index:number, page_size:number) : Promise<RawPage<Models.OptionGroupPosition>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${source.Id}/unlinked/OptionGroup_OptionGroupPositions?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OptionGroupPosition>(json, e => { return {...e, }})
}

    
export async function create_linked_OptionGroup_OptionGroup_OptionGroupPositions_OptionGroupPosition(source:Models.OptionGroup) : Promise<Models.OptionGroupPosition[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${source.Id}/OptionGroup_OptionGroupPositions_OptionGroupPosition`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.OptionGroupPosition[]
}

export async function link_OptionGroup_OptionGroup_OptionGroupPositions(source:Models.OptionGroup, target:Models.OptionGroupPosition) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${source.Id}/OptionGroup_OptionGroupPositions/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_OptionGroup_OptionGroup_OptionGroupPositions(source:Models.OptionGroup, target:Models.OptionGroupPosition) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${source.Id}/OptionGroup_OptionGroupPositions/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_OptionGroup_OptionGroup_Options(source:Models.OptionGroup, page_index:number, page_size:number) : Promise<RawPage<Models.Option>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${source.Id}/OptionGroup_Options?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Option>(json, e => { return {...e, }})
}


export async function search_OptionGroup_OptionGroup_Options(source:Models.OptionGroup, page_index:number, page_size:number, search_value: SearchQueryItem) : Promise<RawPage<Models.Option>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let body;
  if(search_value.searchQueryString && search_value.searchQueryString.length > 0)
    body = {
    "relationship": {
          "firstTable": "Option",
          "joins": [
            {
              "item1": {
                "table": "OptionGroup_Option",
                "source": "OptionGroup",
                "target": "Option"
              },
              "item2": "OptionGroup"
            }
          ]
      }
      , "expression" :  {
  "kind": "And",
  "left": {
  "kind": "Or",
  "left": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Option.Name"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
},
  "right": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Option.InternalName"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
}
},
  "right": {
    "kind": "Eq",
    "left": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "OptionGroup.Id"
      }
    },
    "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "Integer",
        "value": source.Id
      }
    }
  }
}
      , "aggregations": []
  }
  else
    body = {
    "relationship": {
          "firstTable": "Option",
          "joins": [
            {
              "item1": {
                "table": "OptionGroup_Option",
                "source": "OptionGroup",
                "target": "Option"
              },
              "item2": "OptionGroup"
            }
          ]
      }
      , "expression" :  {
    "kind": "Eq",
    "left": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "OptionGroup.Id"
      }
    },
    "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "Integer",
        "value": source.Id
      }
    }
  }
      , "aggregations": []
  }

  let res = await fetch(`/api/search?page_index=${page_index}&page_size=${page_size}`, { method: 'post', credentials: 'include', headers: headers, body: JSON.stringify(body) })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Option>(json, e => { return {...e, }})
}






export async function get_OptionGroup_OptionGroup_Options_Option(source:Models.OptionGroup, page_index:number, page_size:number, id:number) : Promise<Models.Option> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${source.Id}/OptionGroup_Options/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Option
}

export async function get_OptionGroup_OptionGroup_Options_Option_by_id(source:Models.OptionGroup, id:number) : Promise<Models.Option> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${source.Id}/OptionGroup_Options/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Option
}


export async function get_unlinked_OptionGroup_OptionGroup_Options(source:Models.OptionGroup, page_index:number, page_size:number) : Promise<RawPage<Models.Option>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${source.Id}/unlinked/OptionGroup_Options?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Option>(json, e => { return {...e, }})
}

    
export async function create_linked_OptionGroup_OptionGroup_Options_Option(source:Models.OptionGroup) : Promise<Models.Option[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${source.Id}/OptionGroup_Options_Option`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Option[]
}

export async function link_OptionGroup_OptionGroup_Options(source:Models.OptionGroup, target:Models.Option) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${source.Id}/OptionGroup_Options/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_OptionGroup_OptionGroup_Options(source:Models.OptionGroup, target:Models.Option) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${source.Id}/OptionGroup_Options/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_OptionGroup() : Promise<Models.OptionGroup> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/OptionGroup/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OptionGroup
}

export async function update_OptionGroup(item:Models.OptionGroup) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/OptionGroup/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_OptionGroup_with_pictures(item:Models.OptionGroup) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/OptionGroup/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_OptionGroup(source:Models.OptionGroup) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/OptionGroup/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_OptionGroup(id:number) : Promise<ItemWithEditable<Models.OptionGroup>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.OptionGroup,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_OptionGroup_with_pictures(id:number) : Promise<ItemWithEditable<Models.OptionGroup>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.OptionGroup,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_OptionGroups(page_index:number, page_size:number) : Promise<RawPage<Models.OptionGroup>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroup?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OptionGroup>(json, e => { return {...e, }})
}










  
  
export async function get_User_Application_Users(source:Models.User, page_index:number, page_size:number) : Promise<RawPage<Models.Application>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_Applications?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Application>(json, e => { return {...e, }})
}







export async function get_User_Application_Users_Application(source:Models.User, page_index:number, page_size:number, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}

export async function get_User_Application_Users_Application_by_id(source:Models.User, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}




export async function get_User_User_AlternativeAddresses(source:Models.User, page_index:number, page_size:number) : Promise<RawPage<Models.AlternativeAddress>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_AlternativeAddresses?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.AlternativeAddress>(json, e => { return {...e, }})
}







export async function get_User_User_AlternativeAddresses_AlternativeAddress(source:Models.User, page_index:number, page_size:number, id:number) : Promise<Models.AlternativeAddress> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_AlternativeAddresses/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.AlternativeAddress
}

export async function get_User_User_AlternativeAddresses_AlternativeAddress_by_id(source:Models.User, id:number) : Promise<Models.AlternativeAddress> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_AlternativeAddresses/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.AlternativeAddress
}


export async function get_unlinked_User_User_AlternativeAddresses(source:Models.User, page_index:number, page_size:number) : Promise<RawPage<Models.AlternativeAddress>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/unlinked/User_AlternativeAddresses?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.AlternativeAddress>(json, e => { return {...e, }})
}

    
export async function create_linked_User_User_AlternativeAddresses_AlternativeAddress(source:Models.User) : Promise<Models.AlternativeAddress[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_AlternativeAddresses_AlternativeAddress`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.AlternativeAddress[]
}

export async function link_User_User_AlternativeAddresses(source:Models.User, target:Models.AlternativeAddress) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_AlternativeAddresses/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_User_User_AlternativeAddresses(source:Models.User, target:Models.AlternativeAddress) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_AlternativeAddresses/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_User_User_DefaultAddresses(source:Models.User, page_index:number, page_size:number) : Promise<RawPage<Models.DefaultAddress>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_DefaultAddresses?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.DefaultAddress>(json, e => { return {...e, }})
}







export async function get_User_User_DefaultAddresses_DefaultAddress(source:Models.User, page_index:number, page_size:number, id:number) : Promise<Models.DefaultAddress> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_DefaultAddresses/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.DefaultAddress
}

export async function get_User_User_DefaultAddresses_DefaultAddress_by_id(source:Models.User, id:number) : Promise<Models.DefaultAddress> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_DefaultAddresses/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.DefaultAddress
}


export async function get_unlinked_User_User_DefaultAddresses(source:Models.User, page_index:number, page_size:number) : Promise<RawPage<Models.DefaultAddress>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/unlinked/User_DefaultAddresses?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.DefaultAddress>(json, e => { return {...e, }})
}

    
export async function create_linked_User_User_DefaultAddresses_DefaultAddress(source:Models.User) : Promise<Models.DefaultAddress[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_DefaultAddresses_DefaultAddress`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.DefaultAddress[]
}

export async function link_User_User_DefaultAddresses(source:Models.User, target:Models.DefaultAddress) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_DefaultAddresses/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_User_User_DefaultAddresses(source:Models.User, target:Models.DefaultAddress) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_DefaultAddresses/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_User_Order_Users(source:Models.User, page_index:number, page_size:number) : Promise<RawPage<Models.Order>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_Orders?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Order>(json, e => { return {...e, DeliveryOrPickupTime: readDBTime(e.DeliveryOrPickupTime)}})
}







export async function get_User_Order_Users_Order(source:Models.User, page_index:number, page_size:number, id:number) : Promise<Models.Order> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_Orders/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), DeliveryOrPickupTime: readDBTime(json.DeliveryOrPickupTime) } as Models.Order
}

export async function get_User_Order_Users_Order_by_id(source:Models.User, id:number) : Promise<Models.Order> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_Orders/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), DeliveryOrPickupTime: readDBTime(json.DeliveryOrPickupTime) } as Models.Order
}


export async function get_unlinked_User_Order_Users(source:Models.User, page_index:number, page_size:number) : Promise<RawPage<Models.Order>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/unlinked/User_Orders?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Order>(json, e => { return {...e, DeliveryOrPickupTime: readDBTime(e.DeliveryOrPickupTime)}})
}

    
export async function create_linked_User_Order_Users_Order(source:Models.User) : Promise<Models.Order[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_Orders_Order`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate), DeliveryOrPickupTime: readDBTime(e.DeliveryOrPickupTime) }}) as Models.Order[]
}

export async function link_User_Order_Users(source:Models.User, target:Models.Order) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_Orders/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_User_Order_Users(source:Models.User, target:Models.Order) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_Orders/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_User_Company_Users(source:Models.User, page_index:number, page_size:number) : Promise<RawPage<Models.Company>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_Companies?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Company>(json, e => { return {...e, }})
}







export async function get_User_Company_Users_Company(source:Models.User, page_index:number, page_size:number, id:number) : Promise<Models.Company> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_Companies/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Company
}

export async function get_User_Company_Users_Company_by_id(source:Models.User, id:number) : Promise<Models.Company> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_Companies/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Company
}


export async function get_unlinked_User_Company_Users(source:Models.User, page_index:number, page_size:number) : Promise<RawPage<Models.Company>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/unlinked/User_Companies?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Company>(json, e => { return {...e, }})
}

    
export async function create_linked_User_Company_Users_Company(source:Models.User) : Promise<Models.Company[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_Companies_Company`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Company[]
}

export async function link_User_Company_Users(source:Models.User, target:Models.Company) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_Companies/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_User_Company_Users(source:Models.User, target:Models.Company) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${source.Id}/User_Companies/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_User() : Promise<Models.User> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/User/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.User
}

export async function update_User(item:Models.User) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/User/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_User_with_pictures(item:Models.User) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/User/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_User(source:Models.User) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/User/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_User(id:number) : Promise<ItemWithEditable<Models.User>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.User,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_User_with_pictures(id:number) : Promise<ItemWithEditable<Models.User>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.User,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Users(page_index:number, page_size:number) : Promise<RawPage<Models.User>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/User?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.User>(json, e => { return {...e, }})
}










export async function delete_User_sessions() : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/User/DeleteSessions`,
    { method: 'post', credentials: 'include',
      headers: headers
    })
  return
}

export async function active_User_sessions() : Promise<Array<{Item1: string, Item2: Date}>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/User/ActiveSessions`,
    { method: 'post', credentials: 'include',
      headers: headers
    })
  if (!res.ok) return []
  let json = await res.json()
  return json as Array<{Item1: string, Item2: Date}>
}

export async function change_User_Email(oldEmail:string, newEmail: string, password: string) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  
  let res = await fetch(`/api/v1/User/ChangeEmail`,
    { method: 'post', credentials: 'include',
      body: JSON.stringify({OldEmail: oldEmail, NewEmail: newEmail, Password: password}),
      headers: headers})
    if (!res.ok) throw Error(res.statusText)
    return
}

export async function verify_User_EmailToken(oldEmail:string, newEmail: string, token: string) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  
  let res = await fetch(`/api/v1/User/ChangeEmailConfirm`,
    { method: 'post', credentials: 'include',
      body: JSON.stringify({OldEmail:oldEmail, NewEmail: newEmail, Token: token}),
      headers: headers})
    if (!res.ok) throw Error(res.statusText)
    return
}

export async function change_User_Username(email: string, username: string, password: string) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  
  let res = await fetch(`/api/v1/User/ChangeUsername`,
    { method: 'post', credentials: 'include',
      body: JSON.stringify({Email: email, Username: username, Password: password}),
      headers: headers})
    if (!res.ok) throw Error(res.statusText)
    return
}

export async function change_User_password(username:string, email:string, password:string, new_password:string, new_password_confirmation:string) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  
  let res = await fetch(`/api/v1/User/ChangePassword`,
    { method: 'post', credentials: 'include',
      body: JSON.stringify({Username:username, Email:email, Password:password, NewPassword:new_password, NewPasswordConfirmation:new_password_confirmation}),
      headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}
  
  
export async function get_DeliveryClosingTime_Restaurant_DeliveryClosingTimes(source:Models.DeliveryClosingTime, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryClosingTime/${source.Id}/DeliveryClosingTime_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}







export async function get_DeliveryClosingTime_Restaurant_DeliveryClosingTimes_Restaurant(source:Models.DeliveryClosingTime, page_index:number, page_size:number, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryClosingTime/${source.Id}/DeliveryClosingTime_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function get_DeliveryClosingTime_Restaurant_DeliveryClosingTimes_Restaurant_by_id(source:Models.DeliveryClosingTime, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryClosingTime/${source.Id}/DeliveryClosingTime_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}


export async function get_unlinked_DeliveryClosingTime_Restaurant_DeliveryClosingTimes(source:Models.DeliveryClosingTime, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryClosingTime/${source.Id}/unlinked/DeliveryClosingTime_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}

    
export async function create_linked_DeliveryClosingTime_Restaurant_DeliveryClosingTimes_Restaurant(source:Models.DeliveryClosingTime) : Promise<Models.Restaurant[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryClosingTime/${source.Id}/DeliveryClosingTime_Restaurants_Restaurant`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Restaurant[]
}

export async function link_DeliveryClosingTime_Restaurant_DeliveryClosingTimes(source:Models.DeliveryClosingTime, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryClosingTime/${source.Id}/DeliveryClosingTime_Restaurants/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_DeliveryClosingTime_Restaurant_DeliveryClosingTimes(source:Models.DeliveryClosingTime, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryClosingTime/${source.Id}/DeliveryClosingTime_Restaurants/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_DeliveryClosingTime() : Promise<Models.DeliveryClosingTime> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/DeliveryClosingTime/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), Start: readDBTime(json.Start), End: readDBTime(json.End) } as Models.DeliveryClosingTime
}

export async function update_DeliveryClosingTime(item:Models.DeliveryClosingTime) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/DeliveryClosingTime/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_DeliveryClosingTime_with_pictures(item:Models.DeliveryClosingTime) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/DeliveryClosingTime/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_DeliveryClosingTime(source:Models.DeliveryClosingTime) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/DeliveryClosingTime/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_DeliveryClosingTime(id:number) : Promise<ItemWithEditable<Models.DeliveryClosingTime>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryClosingTime/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), Start: readDBTime(json.Item.Start), End: readDBTime(json.Item.End) } as Models.DeliveryClosingTime,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_DeliveryClosingTime_with_pictures(id:number) : Promise<ItemWithEditable<Models.DeliveryClosingTime>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryClosingTime/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), Start: readDBTime(json.Item.Start), End: readDBTime(json.Item.End) } as Models.DeliveryClosingTime,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_DeliveryClosingTimes(page_index:number, page_size:number) : Promise<RawPage<Models.DeliveryClosingTime>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryClosingTime?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.DeliveryClosingTime>(json, e => { return {...e, Start: readDBTime(e.Start), End: readDBTime(e.End)}})
}










  
  
export async function get_POS4OrderFetchEvent_Application_POS4OrderFetchEvents(source:Models.POS4OrderFetchEvent, page_index:number, page_size:number) : Promise<RawPage<Models.Application>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4OrderFetchEvent/${source.Id}/POS4OrderFetchEvent_Applications?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Application>(json, e => { return {...e, }})
}







export async function get_POS4OrderFetchEvent_Application_POS4OrderFetchEvents_Application(source:Models.POS4OrderFetchEvent, page_index:number, page_size:number, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4OrderFetchEvent/${source.Id}/POS4OrderFetchEvent_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}

export async function get_POS4OrderFetchEvent_Application_POS4OrderFetchEvents_Application_by_id(source:Models.POS4OrderFetchEvent, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4OrderFetchEvent/${source.Id}/POS4OrderFetchEvent_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}




export async function get_POS4OrderFetchEvent_POS4_POS4OrderFetchEvents(source:Models.POS4OrderFetchEvent, page_index:number, page_size:number) : Promise<RawPage<Models.POS4>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4OrderFetchEvent/${source.Id}/POS4OrderFetchEvent_POS4S?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.POS4>(json, e => { return {...e, TokenExpiration: readDBTime(e.TokenExpiration)}})
}







export async function get_POS4OrderFetchEvent_POS4_POS4OrderFetchEvents_POS4(source:Models.POS4OrderFetchEvent, page_index:number, page_size:number, id:number) : Promise<Models.POS4> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4OrderFetchEvent/${source.Id}/POS4OrderFetchEvent_POS4S/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), TokenExpiration: readDBTime(json.TokenExpiration) } as Models.POS4
}

export async function get_POS4OrderFetchEvent_POS4_POS4OrderFetchEvents_POS4_by_id(source:Models.POS4OrderFetchEvent, id:number) : Promise<Models.POS4> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4OrderFetchEvent/${source.Id}/POS4OrderFetchEvent_POS4S/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), TokenExpiration: readDBTime(json.TokenExpiration) } as Models.POS4
}


export async function get_unlinked_POS4OrderFetchEvent_POS4_POS4OrderFetchEvents(source:Models.POS4OrderFetchEvent, page_index:number, page_size:number) : Promise<RawPage<Models.POS4>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4OrderFetchEvent/${source.Id}/unlinked/POS4OrderFetchEvent_POS4S?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.POS4>(json, e => { return {...e, TokenExpiration: readDBTime(e.TokenExpiration)}})
}

    
export async function create_linked_POS4OrderFetchEvent_POS4_POS4OrderFetchEvents_POS4(source:Models.POS4OrderFetchEvent) : Promise<Models.POS4[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4OrderFetchEvent/${source.Id}/POS4OrderFetchEvent_POS4S_POS4`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate), TokenExpiration: readDBTime(e.TokenExpiration) }}) as Models.POS4[]
}

export async function link_POS4OrderFetchEvent_POS4_POS4OrderFetchEvents(source:Models.POS4OrderFetchEvent, target:Models.POS4) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4OrderFetchEvent/${source.Id}/POS4OrderFetchEvent_POS4S/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_POS4OrderFetchEvent_POS4_POS4OrderFetchEvents(source:Models.POS4OrderFetchEvent, target:Models.POS4) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4OrderFetchEvent/${source.Id}/POS4OrderFetchEvent_POS4S/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_POS4OrderFetchEvent() : Promise<Models.POS4OrderFetchEvent> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/POS4OrderFetchEvent/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), Date: readDBTime(json.Date) } as Models.POS4OrderFetchEvent
}

export async function update_POS4OrderFetchEvent(item:Models.POS4OrderFetchEvent) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/POS4OrderFetchEvent/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_POS4OrderFetchEvent_with_pictures(item:Models.POS4OrderFetchEvent) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/POS4OrderFetchEvent/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_POS4OrderFetchEvent(source:Models.POS4OrderFetchEvent) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/POS4OrderFetchEvent/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_POS4OrderFetchEvent(id:number) : Promise<ItemWithEditable<Models.POS4OrderFetchEvent>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4OrderFetchEvent/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), Date: readDBTime(json.Item.Date) } as Models.POS4OrderFetchEvent,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_POS4OrderFetchEvent_with_pictures(id:number) : Promise<ItemWithEditable<Models.POS4OrderFetchEvent>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4OrderFetchEvent/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), Date: readDBTime(json.Item.Date) } as Models.POS4OrderFetchEvent,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_POS4OrderFetchEvents(page_index:number, page_size:number) : Promise<RawPage<Models.POS4OrderFetchEvent>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4OrderFetchEvent?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.POS4OrderFetchEvent>(json, e => { return {...e, Date: readDBTime(e.Date)}})
}










  
  
export async function get_OrderItemProduct_OrderItem_OrderItemProducts(source:Models.OrderItemProduct, page_index:number, page_size:number) : Promise<RawPage<Models.OrderItem>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemProduct/${source.Id}/OrderItemProduct_OrderItems?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OrderItem>(json, e => { return {...e, }})
}







export async function get_OrderItemProduct_OrderItem_OrderItemProducts_OrderItem(source:Models.OrderItemProduct, page_index:number, page_size:number, id:number) : Promise<Models.OrderItem> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemProduct/${source.Id}/OrderItemProduct_OrderItems/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OrderItem
}

export async function get_OrderItemProduct_OrderItem_OrderItemProducts_OrderItem_by_id(source:Models.OrderItemProduct, id:number) : Promise<Models.OrderItem> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemProduct/${source.Id}/OrderItemProduct_OrderItems/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OrderItem
}


export async function get_unlinked_OrderItemProduct_OrderItem_OrderItemProducts(source:Models.OrderItemProduct, page_index:number, page_size:number) : Promise<RawPage<Models.OrderItem>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemProduct/${source.Id}/unlinked/OrderItemProduct_OrderItems?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OrderItem>(json, e => { return {...e, }})
}

    
export async function create_linked_OrderItemProduct_OrderItem_OrderItemProducts_OrderItem(source:Models.OrderItemProduct) : Promise<Models.OrderItem[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemProduct/${source.Id}/OrderItemProduct_OrderItems_OrderItem`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.OrderItem[]
}

export async function link_OrderItemProduct_OrderItem_OrderItemProducts(source:Models.OrderItemProduct, target:Models.OrderItem) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemProduct/${source.Id}/OrderItemProduct_OrderItems/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_OrderItemProduct_OrderItem_OrderItemProducts(source:Models.OrderItemProduct, target:Models.OrderItem) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemProduct/${source.Id}/OrderItemProduct_OrderItems/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_OrderItemProduct_OrderItemProduct_Products(source:Models.OrderItemProduct, page_index:number, page_size:number) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemProduct/${source.Id}/OrderItemProduct_Products?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}


export async function search_OrderItemProduct_OrderItemProduct_Products(source:Models.OrderItemProduct, page_index:number, page_size:number, search_value: SearchQueryItem) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let body;
  if(search_value.searchQueryString && search_value.searchQueryString.length > 0)
    body = {
    "relationship": {
          "firstTable": "Product",
          "joins": [
            {
              "item1": {
                "table": "OrderItemProduct_Product",
                "source": "OrderItemProduct",
                "target": "Product"
              },
              "item2": "OrderItemProduct"
            }
          ]
      }
      , "expression" :  {
  "kind": "And",
  "left": {
  "kind": "Or",
  "left": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Product.Name"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
},
  "right": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Product.InternalName"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
}
},
  "right": {
    "kind": "Eq",
    "left": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "OrderItemProduct.Id"
      }
    },
    "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "Integer",
        "value": source.Id
      }
    }
  }
}
      , "aggregations": []
  }
  else
    body = {
    "relationship": {
          "firstTable": "Product",
          "joins": [
            {
              "item1": {
                "table": "OrderItemProduct_Product",
                "source": "OrderItemProduct",
                "target": "Product"
              },
              "item2": "OrderItemProduct"
            }
          ]
      }
      , "expression" :  {
    "kind": "Eq",
    "left": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "OrderItemProduct.Id"
      }
    },
    "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "Integer",
        "value": source.Id
      }
    }
  }
      , "aggregations": []
  }

  let res = await fetch(`/api/search?page_index=${page_index}&page_size=${page_size}`, { method: 'post', credentials: 'include', headers: headers, body: JSON.stringify(body) })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}






export async function get_OrderItemProduct_OrderItemProduct_Products_Product(source:Models.OrderItemProduct, page_index:number, page_size:number, id:number) : Promise<Models.Product> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemProduct/${source.Id}/OrderItemProduct_Products/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Product
}

export async function get_OrderItemProduct_OrderItemProduct_Products_Product_by_id(source:Models.OrderItemProduct, id:number) : Promise<Models.Product> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemProduct/${source.Id}/OrderItemProduct_Products/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Product
}


export async function get_unlinked_OrderItemProduct_OrderItemProduct_Products(source:Models.OrderItemProduct, page_index:number, page_size:number) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemProduct/${source.Id}/unlinked/OrderItemProduct_Products?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}

    
export async function create_linked_OrderItemProduct_OrderItemProduct_Products_Product(source:Models.OrderItemProduct) : Promise<Models.Product[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemProduct/${source.Id}/OrderItemProduct_Products_Product`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Product[]
}

export async function link_OrderItemProduct_OrderItemProduct_Products(source:Models.OrderItemProduct, target:Models.Product) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemProduct/${source.Id}/OrderItemProduct_Products/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_OrderItemProduct_OrderItemProduct_Products(source:Models.OrderItemProduct, target:Models.Product) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemProduct/${source.Id}/OrderItemProduct_Products/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_OrderItemProduct() : Promise<Models.OrderItemProduct> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/OrderItemProduct/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OrderItemProduct
}

export async function update_OrderItemProduct(item:Models.OrderItemProduct) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/OrderItemProduct/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_OrderItemProduct_with_pictures(item:Models.OrderItemProduct) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/OrderItemProduct/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_OrderItemProduct(source:Models.OrderItemProduct) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/OrderItemProduct/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_OrderItemProduct(id:number) : Promise<ItemWithEditable<Models.OrderItemProduct>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemProduct/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.OrderItemProduct,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_OrderItemProduct_with_pictures(id:number) : Promise<ItemWithEditable<Models.OrderItemProduct>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemProduct/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.OrderItemProduct,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_OrderItemProducts(page_index:number, page_size:number) : Promise<RawPage<Models.OrderItemProduct>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemProduct?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OrderItemProduct>(json, e => { return {...e, }})
}










  
  
export async function get_Order_Application_Orders(source:Models.Order, page_index:number, page_size:number) : Promise<RawPage<Models.Application>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Applications?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Application>(json, e => { return {...e, }})
}







export async function get_Order_Application_Orders_Application(source:Models.Order, page_index:number, page_size:number, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}

export async function get_Order_Application_Orders_Application_by_id(source:Models.Order, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}




export async function get_Order_Order_OrderItems(source:Models.Order, page_index:number, page_size:number) : Promise<RawPage<Models.OrderItem>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_OrderItems?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OrderItem>(json, e => { return {...e, }})
}







export async function get_Order_Order_OrderItems_OrderItem(source:Models.Order, page_index:number, page_size:number, id:number) : Promise<Models.OrderItem> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_OrderItems/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OrderItem
}

export async function get_Order_Order_OrderItems_OrderItem_by_id(source:Models.Order, id:number) : Promise<Models.OrderItem> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_OrderItems/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OrderItem
}


export async function get_unlinked_Order_Order_OrderItems(source:Models.Order, page_index:number, page_size:number) : Promise<RawPage<Models.OrderItem>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/unlinked/Order_OrderItems?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OrderItem>(json, e => { return {...e, }})
}

    
export async function create_linked_Order_Order_OrderItems_OrderItem(source:Models.Order) : Promise<Models.OrderItem[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_OrderItems_OrderItem`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.OrderItem[]
}

export async function link_Order_Order_OrderItems(source:Models.Order, target:Models.OrderItem) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_OrderItems/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Order_Order_OrderItems(source:Models.Order, target:Models.OrderItem) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_OrderItems/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Order_Order_PayMethods(source:Models.Order, page_index:number, page_size:number) : Promise<RawPage<Models.PayMethod>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_PayMethods?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PayMethod>(json, e => { return {...e, }})
}







export async function get_Order_Order_PayMethods_PayMethod(source:Models.Order, page_index:number, page_size:number, id:number) : Promise<Models.PayMethod> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_PayMethods/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.PayMethod
}

export async function get_Order_Order_PayMethods_PayMethod_by_id(source:Models.Order, id:number) : Promise<Models.PayMethod> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_PayMethods/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.PayMethod
}


export async function get_unlinked_Order_Order_PayMethods(source:Models.Order, page_index:number, page_size:number) : Promise<RawPage<Models.PayMethod>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/unlinked/Order_PayMethods?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PayMethod>(json, e => { return {...e, }})
}

    
export async function create_linked_Order_Order_PayMethods_PayMethod(source:Models.Order) : Promise<Models.PayMethod[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_PayMethods_PayMethod`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.PayMethod[]
}

export async function link_Order_Order_PayMethods(source:Models.Order, target:Models.PayMethod) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_PayMethods/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Order_Order_PayMethods(source:Models.Order, target:Models.PayMethod) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_PayMethods/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Order_Order_Discounts(source:Models.Order, page_index:number, page_size:number) : Promise<RawPage<Models.Discount>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Discounts?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Discount>(json, e => { return {...e, EndDate: readDBTime(e.EndDate), StartDate: readDBTime(e.StartDate)}})
}







export async function get_Order_Order_Discounts_Discount(source:Models.Order, page_index:number, page_size:number, id:number) : Promise<Models.Discount> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Discounts/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), EndDate: readDBTime(json.EndDate), StartDate: readDBTime(json.StartDate) } as Models.Discount
}

export async function get_Order_Order_Discounts_Discount_by_id(source:Models.Order, id:number) : Promise<Models.Discount> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Discounts/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), EndDate: readDBTime(json.EndDate), StartDate: readDBTime(json.StartDate) } as Models.Discount
}


export async function get_unlinked_Order_Order_Discounts(source:Models.Order, page_index:number, page_size:number) : Promise<RawPage<Models.Discount>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/unlinked/Order_Discounts?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Discount>(json, e => { return {...e, EndDate: readDBTime(e.EndDate), StartDate: readDBTime(e.StartDate)}})
}

    
export async function create_linked_Order_Order_Discounts_Discount(source:Models.Order) : Promise<Models.Discount[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Discounts_Discount`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate), EndDate: readDBTime(e.EndDate), StartDate: readDBTime(e.StartDate) }}) as Models.Discount[]
}

export async function link_Order_Order_Discounts(source:Models.Order, target:Models.Discount) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Discounts/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Order_Order_Discounts(source:Models.Order, target:Models.Discount) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Discounts/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Order_Order_Restaurants(source:Models.Order, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}







export async function get_Order_Order_Restaurants_Restaurant(source:Models.Order, page_index:number, page_size:number, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function get_Order_Order_Restaurants_Restaurant_by_id(source:Models.Order, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}


export async function get_unlinked_Order_Order_Restaurants(source:Models.Order, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/unlinked/Order_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}

    
export async function create_linked_Order_Order_Restaurants_Restaurant(source:Models.Order) : Promise<Models.Restaurant[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Restaurants_Restaurant`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Restaurant[]
}

export async function link_Order_Order_Restaurants(source:Models.Order, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Restaurants/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Order_Order_Restaurants(source:Models.Order, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Restaurants/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Order_Order_Users(source:Models.Order, page_index:number, page_size:number) : Promise<RawPage<Models.User>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Users?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.User>(json, e => { return {...e, }})
}







export async function get_Order_Order_Users_User(source:Models.Order, page_index:number, page_size:number, id:number) : Promise<Models.User> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Users/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.User
}

export async function get_Order_Order_Users_User_by_id(source:Models.Order, id:number) : Promise<Models.User> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Users/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.User
}


export async function get_unlinked_Order_Order_Users(source:Models.Order, page_index:number, page_size:number) : Promise<RawPage<Models.User>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/unlinked/Order_Users?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.User>(json, e => { return {...e, }})
}

    
export async function create_linked_Order_Order_Users_User(source:Models.Order) : Promise<Models.User[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Users_User`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.User[]
}

export async function link_Order_Order_Users(source:Models.Order, target:Models.User) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Users/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Order_Order_Users(source:Models.Order, target:Models.User) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${source.Id}/Order_Users/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_Order() : Promise<Models.Order> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Order/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), DeliveryOrPickupTime: readDBTime(json.DeliveryOrPickupTime) } as Models.Order
}

export async function update_Order(item:Models.Order) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Order/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_Order_with_pictures(item:Models.Order) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Order/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_Order(source:Models.Order) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/Order/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_Order(id:number) : Promise<ItemWithEditable<Models.Order>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), DeliveryOrPickupTime: readDBTime(json.Item.DeliveryOrPickupTime) } as Models.Order,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Order_with_pictures(id:number) : Promise<ItemWithEditable<Models.Order>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), DeliveryOrPickupTime: readDBTime(json.Item.DeliveryOrPickupTime) } as Models.Order,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Orders(page_index:number, page_size:number) : Promise<RawPage<Models.Order>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Order?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Order>(json, e => { return {...e, DeliveryOrPickupTime: readDBTime(e.DeliveryOrPickupTime)}})
}










  
  
export async function get_Product_Application_Products(source:Models.Product, page_index:number, page_size:number) : Promise<RawPage<Models.Application>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_Applications?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Application>(json, e => { return {...e, }})
}







export async function get_Product_Application_Products_Application(source:Models.Product, page_index:number, page_size:number, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}

export async function get_Product_Application_Products_Application_by_id(source:Models.Product, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}




export async function get_Product_Product_OptionGroupPositions(source:Models.Product, page_index:number, page_size:number) : Promise<RawPage<Models.OptionGroupPosition>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_OptionGroupPositions?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OptionGroupPosition>(json, e => { return {...e, }})
}







export async function get_Product_Product_OptionGroupPositions_OptionGroupPosition(source:Models.Product, page_index:number, page_size:number, id:number) : Promise<Models.OptionGroupPosition> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_OptionGroupPositions/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OptionGroupPosition
}

export async function get_Product_Product_OptionGroupPositions_OptionGroupPosition_by_id(source:Models.Product, id:number) : Promise<Models.OptionGroupPosition> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_OptionGroupPositions/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OptionGroupPosition
}


export async function get_unlinked_Product_Product_OptionGroupPositions(source:Models.Product, page_index:number, page_size:number) : Promise<RawPage<Models.OptionGroupPosition>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/unlinked/Product_OptionGroupPositions?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OptionGroupPosition>(json, e => { return {...e, }})
}

    
export async function create_linked_Product_Product_OptionGroupPositions_OptionGroupPosition(source:Models.Product) : Promise<Models.OptionGroupPosition[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_OptionGroupPositions_OptionGroupPosition`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.OptionGroupPosition[]
}

export async function link_Product_Product_OptionGroupPositions(source:Models.Product, target:Models.OptionGroupPosition) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_OptionGroupPositions/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Product_Product_OptionGroupPositions(source:Models.Product, target:Models.OptionGroupPosition) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_OptionGroupPositions/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Product_Product_ProductPositions(source:Models.Product, page_index:number, page_size:number) : Promise<RawPage<Models.ProductPosition>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_ProductPositions?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.ProductPosition>(json, e => { return {...e, }})
}







export async function get_Product_Product_ProductPositions_ProductPosition(source:Models.Product, page_index:number, page_size:number, id:number) : Promise<Models.ProductPosition> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_ProductPositions/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.ProductPosition
}

export async function get_Product_Product_ProductPositions_ProductPosition_by_id(source:Models.Product, id:number) : Promise<Models.ProductPosition> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_ProductPositions/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.ProductPosition
}


export async function get_unlinked_Product_Product_ProductPositions(source:Models.Product, page_index:number, page_size:number) : Promise<RawPage<Models.ProductPosition>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/unlinked/Product_ProductPositions?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.ProductPosition>(json, e => { return {...e, }})
}

    
export async function create_linked_Product_Product_ProductPositions_ProductPosition(source:Models.Product) : Promise<Models.ProductPosition[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_ProductPositions_ProductPosition`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.ProductPosition[]
}

export async function link_Product_Product_ProductPositions(source:Models.Product, target:Models.ProductPosition) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_ProductPositions/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Product_Product_ProductPositions(source:Models.Product, target:Models.ProductPosition) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_ProductPositions/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Product_Product_Restaurants(source:Models.Product, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}







export async function get_Product_Product_Restaurants_Restaurant(source:Models.Product, page_index:number, page_size:number, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function get_Product_Product_Restaurants_Restaurant_by_id(source:Models.Product, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}


export async function get_unlinked_Product_Product_Restaurants(source:Models.Product, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/unlinked/Product_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}

    
export async function create_linked_Product_Product_Restaurants_Restaurant(source:Models.Product) : Promise<Models.Restaurant[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_Restaurants_Restaurant`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Restaurant[]
}

export async function link_Product_Product_Restaurants(source:Models.Product, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_Restaurants/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Product_Product_Restaurants(source:Models.Product, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_Restaurants/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Product_OrderItemProduct_Products(source:Models.Product, page_index:number, page_size:number) : Promise<RawPage<Models.OrderItemProduct>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_OrderItemProducts?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OrderItemProduct>(json, e => { return {...e, }})
}







export async function get_Product_OrderItemProduct_Products_OrderItemProduct(source:Models.Product, page_index:number, page_size:number, id:number) : Promise<Models.OrderItemProduct> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_OrderItemProducts/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OrderItemProduct
}

export async function get_Product_OrderItemProduct_Products_OrderItemProduct_by_id(source:Models.Product, id:number) : Promise<Models.OrderItemProduct> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_OrderItemProducts/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OrderItemProduct
}


export async function get_unlinked_Product_OrderItemProduct_Products(source:Models.Product, page_index:number, page_size:number) : Promise<RawPage<Models.OrderItemProduct>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/unlinked/Product_OrderItemProducts?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OrderItemProduct>(json, e => { return {...e, }})
}

    
export async function create_linked_Product_OrderItemProduct_Products_OrderItemProduct(source:Models.Product) : Promise<Models.OrderItemProduct[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_OrderItemProducts_OrderItemProduct`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.OrderItemProduct[]
}

export async function link_Product_OrderItemProduct_Products(source:Models.Product, target:Models.OrderItemProduct) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_OrderItemProducts/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Product_OrderItemProduct_Products(source:Models.Product, target:Models.OrderItemProduct) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_OrderItemProducts/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Product_Product_Discounts(source:Models.Product, page_index:number, page_size:number) : Promise<RawPage<Models.Discount>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_Discounts?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Discount>(json, e => { return {...e, EndDate: readDBTime(e.EndDate), StartDate: readDBTime(e.StartDate)}})
}







export async function get_Product_Product_Discounts_Discount(source:Models.Product, page_index:number, page_size:number, id:number) : Promise<Models.Discount> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_Discounts/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), EndDate: readDBTime(json.EndDate), StartDate: readDBTime(json.StartDate) } as Models.Discount
}

export async function get_Product_Product_Discounts_Discount_by_id(source:Models.Product, id:number) : Promise<Models.Discount> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_Discounts/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), EndDate: readDBTime(json.EndDate), StartDate: readDBTime(json.StartDate) } as Models.Discount
}


export async function get_unlinked_Product_Product_Discounts(source:Models.Product, page_index:number, page_size:number) : Promise<RawPage<Models.Discount>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/unlinked/Product_Discounts?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Discount>(json, e => { return {...e, EndDate: readDBTime(e.EndDate), StartDate: readDBTime(e.StartDate)}})
}

    
export async function create_linked_Product_Product_Discounts_Discount(source:Models.Product) : Promise<Models.Discount[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_Discounts_Discount`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate), EndDate: readDBTime(e.EndDate), StartDate: readDBTime(e.StartDate) }}) as Models.Discount[]
}

export async function link_Product_Product_Discounts(source:Models.Product, target:Models.Discount) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_Discounts/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Product_Product_Discounts(source:Models.Product, target:Models.Discount) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${source.Id}/Product_Discounts/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_Product() : Promise<Models.Product> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Product/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Product
}

export async function update_Product(item:Models.Product) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Product/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_Product_with_pictures(item:Models.Product) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Product/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_Product(source:Models.Product) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/Product/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_Product(id:number) : Promise<ItemWithEditable<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.Product,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Product_with_pictures(id:number) : Promise<ItemWithEditable<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.Product,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Products(page_index:number, page_size:number) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Product?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}





export async function download_Product_ImageBig(id:number) : Promise<string> {
  let res = await fetch(`/api/v1/Product/${id}/ImageBigDownload`, { method: 'get', credentials: 'include' })
  let json = await res.text()
  if (!res.ok) throw Error(res.statusText)
  return json as string
}

export async function upload_Product_ImageBig(source:Models.Product,file:File) : Promise<void> {
  let files_data = new FormData()
  files_data.append(file.name, file)

  let headers = new Headers()
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/Product/${source.Id}/ImageBigUpload`,  {
    method: "POST",
    body: files_data, credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}
export async function download_Product_ImageSmall(id:number) : Promise<string> {
  let res = await fetch(`/api/v1/Product/${id}/ImageSmallDownload`, { method: 'get', credentials: 'include' })
  let json = await res.text()
  if (!res.ok) throw Error(res.statusText)
  return json as string
}

export async function upload_Product_ImageSmall(source:Models.Product,file:File) : Promise<void> {
  let files_data = new FormData()
  files_data.append(file.name, file)

  let headers = new Headers()
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/Product/${source.Id}/ImageSmallUpload`,  {
    method: "POST",
    body: files_data, credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}




  
  
export async function get_Admin_Application_Admins(source:Models.Admin, page_index:number, page_size:number) : Promise<RawPage<Models.Application>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Admin/${source.Id}/Admin_Applications?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Application>(json, e => { return {...e, }})
}







export async function get_Admin_Application_Admins_Application(source:Models.Admin, page_index:number, page_size:number, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Admin/${source.Id}/Admin_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}

export async function get_Admin_Application_Admins_Application_by_id(source:Models.Admin, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Admin/${source.Id}/Admin_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}




export async function create_Admin() : Promise<Models.Admin> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Admin/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Admin
}

export async function update_Admin(item:Models.Admin) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Admin/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_Admin_with_pictures(item:Models.Admin) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Admin/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_Admin(source:Models.Admin) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/Admin/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_Admin(id:number) : Promise<ItemWithEditable<Models.Admin>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Admin/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.Admin,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Admin_with_pictures(id:number) : Promise<ItemWithEditable<Models.Admin>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Admin/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.Admin,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Admins(page_index:number, page_size:number) : Promise<RawPage<Models.Admin>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Admin?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Admin>(json, e => { return {...e, }})
}










export async function delete_Admin_sessions() : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Admin/DeleteSessions`,
    { method: 'post', credentials: 'include',
      headers: headers
    })
  return
}

export async function active_Admin_sessions() : Promise<Array<{Item1: string, Item2: Date}>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Admin/ActiveSessions`,
    { method: 'post', credentials: 'include',
      headers: headers
    })
  if (!res.ok) return []
  let json = await res.json()
  return json as Array<{Item1: string, Item2: Date}>
}

export async function change_Admin_Email(oldEmail:string, newEmail: string, password: string) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  
  let res = await fetch(`/api/v1/Admin/ChangeEmail`,
    { method: 'post', credentials: 'include',
      body: JSON.stringify({OldEmail: oldEmail, NewEmail: newEmail, Password: password}),
      headers: headers})
    if (!res.ok) throw Error(res.statusText)
    return
}

export async function verify_Admin_EmailToken(oldEmail:string, newEmail: string, token: string) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  
  let res = await fetch(`/api/v1/Admin/ChangeEmailConfirm`,
    { method: 'post', credentials: 'include',
      body: JSON.stringify({OldEmail:oldEmail, NewEmail: newEmail, Token: token}),
      headers: headers})
    if (!res.ok) throw Error(res.statusText)
    return
}

export async function change_Admin_Username(email: string, username: string, password: string) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  
  let res = await fetch(`/api/v1/Admin/ChangeUsername`,
    { method: 'post', credentials: 'include',
      body: JSON.stringify({Email: email, Username: username, Password: password}),
      headers: headers})
    if (!res.ok) throw Error(res.statusText)
    return
}

export async function change_Admin_password(username:string, email:string, password:string, new_password:string, new_password_confirmation:string) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  
  let res = await fetch(`/api/v1/Admin/ChangePassword`,
    { method: 'post', credentials: 'include',
      body: JSON.stringify({Username:username, Email:email, Password:password, NewPassword:new_password, NewPasswordConfirmation:new_password_confirmation}),
      headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}
  
  
export async function get_Application_Application_Admins(source:Models.Application, page_index:number, page_size:number) : Promise<RawPage<Models.Admin>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Admins?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Admin>(json, e => { return {...e, }})
}







export async function get_Application_Application_Admins_Admin(source:Models.Application, page_index:number, page_size:number, id:number) : Promise<Models.Admin> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Admins/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Admin
}

export async function get_Application_Application_Admins_Admin_by_id(source:Models.Application, id:number) : Promise<Models.Admin> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Admins/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Admin
}




export async function get_Application_Application_Users(source:Models.Application, page_index:number, page_size:number) : Promise<RawPage<Models.User>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Users?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.User>(json, e => { return {...e, }})
}







export async function get_Application_Application_Users_User(source:Models.Application, page_index:number, page_size:number, id:number) : Promise<Models.User> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Users/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.User
}

export async function get_Application_Application_Users_User_by_id(source:Models.Application, id:number) : Promise<Models.User> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Users/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.User
}




export async function get_Application_Application_FranchiseManagers(source:Models.Application, page_index:number, page_size:number) : Promise<RawPage<Models.FranchiseManager>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_FranchiseManagers?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.FranchiseManager>(json, e => { return {...e, }})
}







export async function get_Application_Application_FranchiseManagers_FranchiseManager(source:Models.Application, page_index:number, page_size:number, id:number) : Promise<Models.FranchiseManager> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_FranchiseManagers/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.FranchiseManager
}

export async function get_Application_Application_FranchiseManagers_FranchiseManager_by_id(source:Models.Application, id:number) : Promise<Models.FranchiseManager> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_FranchiseManagers/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.FranchiseManager
}




export async function get_Application_Application_Categories(source:Models.Application, page_index:number, page_size:number) : Promise<RawPage<Models.Category>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Categories?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Category>(json, e => { return {...e, }})
}







export async function get_Application_Application_Categories_Category(source:Models.Application, page_index:number, page_size:number, id:number) : Promise<Models.Category> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Categories/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Category
}

export async function get_Application_Application_Categories_Category_by_id(source:Models.Application, id:number) : Promise<Models.Category> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Categories/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Category
}




export async function get_Application_Application_Products(source:Models.Application, page_index:number, page_size:number) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Products?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}


export async function search_Application_Application_Products(source:Models.Application, page_index:number, page_size:number, search_value: SearchQueryItem) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let body;
  if(search_value.searchQueryString && search_value.searchQueryString.length > 0)
    body = {
    "relationship": {
          "firstTable": "Product",
          "joins": []
      }
      , "expression" :  {
  "kind": "Or",
  "left": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Product.Name"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
},
  "right": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Product.InternalName"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
}
}
      , "aggregations": []
  }
  else
    body = {
    "relationship": {
          "firstTable": "Product",
          "joins": []
      }
      
      , "aggregations": []
  }

  let res = await fetch(`/api/search?page_index=${page_index}&page_size=${page_size}`, { method: 'post', credentials: 'include', headers: headers, body: JSON.stringify(body) })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}





export async function search_unlinked_Application_Application_Products(source:Models.Application, page_index:number, page_size:number, search_value: SearchQueryItem) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let body;
  if(search_value.searchQueryString && search_value.searchQueryString.length > 0)
    body = {
    "relationship": {
          "firstTable": "Product",
          "joins": [ ]
      }
      , "expression" :  {
  "kind": "Or",
  "left": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Product.Name"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
},
  "right": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Product.InternalName"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
}
}
      , "aggregations": []
  }
  else
    body = {
    "relationship": {
          "firstTable": "Product",
          "joins": [ ]
      }
      
      , "aggregations": []
  }

  let res = await fetch(`/api/v1/Application/${source.Id}/search_unlinked/Application_Products?page_index=${page_index}&page_size=${page_size}`, { method: 'post', credentials: 'include', headers: headers, body: JSON.stringify(body) })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}


export async function get_Application_Application_Products_Product(source:Models.Application, page_index:number, page_size:number, id:number) : Promise<Models.Product> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Products/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Product
}

export async function get_Application_Application_Products_Product_by_id(source:Models.Application, id:number) : Promise<Models.Product> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Products/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Product
}




export async function get_Application_Application_Restaurants(source:Models.Application, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}







export async function get_Application_Application_Restaurants_Restaurant(source:Models.Application, page_index:number, page_size:number, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function get_Application_Application_Restaurants_Restaurant_by_id(source:Models.Application, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}




export async function get_Application_Application_Orders(source:Models.Application, page_index:number, page_size:number) : Promise<RawPage<Models.Order>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Orders?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Order>(json, e => { return {...e, DeliveryOrPickupTime: readDBTime(e.DeliveryOrPickupTime)}})
}







export async function get_Application_Application_Orders_Order(source:Models.Application, page_index:number, page_size:number, id:number) : Promise<Models.Order> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Orders/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), DeliveryOrPickupTime: readDBTime(json.DeliveryOrPickupTime) } as Models.Order
}

export async function get_Application_Application_Orders_Order_by_id(source:Models.Application, id:number) : Promise<Models.Order> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Orders/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), DeliveryOrPickupTime: readDBTime(json.DeliveryOrPickupTime) } as Models.Order
}




export async function get_Application_Application_Options(source:Models.Application, page_index:number, page_size:number) : Promise<RawPage<Models.Option>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Options?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Option>(json, e => { return {...e, }})
}


export async function search_Application_Application_Options(source:Models.Application, page_index:number, page_size:number, search_value: SearchQueryItem) : Promise<RawPage<Models.Option>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let body;
  if(search_value.searchQueryString && search_value.searchQueryString.length > 0)
    body = {
    "relationship": {
          "firstTable": "Option",
          "joins": []
      }
      , "expression" :  {
  "kind": "Or",
  "left": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Option.Name"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
},
  "right": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Option.InternalName"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
}
}
      , "aggregations": []
  }
  else
    body = {
    "relationship": {
          "firstTable": "Option",
          "joins": []
      }
      
      , "aggregations": []
  }

  let res = await fetch(`/api/search?page_index=${page_index}&page_size=${page_size}`, { method: 'post', credentials: 'include', headers: headers, body: JSON.stringify(body) })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Option>(json, e => { return {...e, }})
}





export async function search_unlinked_Application_Application_Options(source:Models.Application, page_index:number, page_size:number, search_value: SearchQueryItem) : Promise<RawPage<Models.Option>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let body;
  if(search_value.searchQueryString && search_value.searchQueryString.length > 0)
    body = {
    "relationship": {
          "firstTable": "Option",
          "joins": [ ]
      }
      , "expression" :  {
  "kind": "Or",
  "left": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Option.Name"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
},
  "right": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Option.InternalName"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
}
}
      , "aggregations": []
  }
  else
    body = {
    "relationship": {
          "firstTable": "Option",
          "joins": [ ]
      }
      
      , "aggregations": []
  }

  let res = await fetch(`/api/v1/Application/${source.Id}/search_unlinked/Application_Options?page_index=${page_index}&page_size=${page_size}`, { method: 'post', credentials: 'include', headers: headers, body: JSON.stringify(body) })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Option>(json, e => { return {...e, }})
}


export async function get_Application_Application_Options_Option(source:Models.Application, page_index:number, page_size:number, id:number) : Promise<Models.Option> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Options/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Option
}

export async function get_Application_Application_Options_Option_by_id(source:Models.Application, id:number) : Promise<Models.Option> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Options/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Option
}




export async function get_Application_Application_OptionGroups(source:Models.Application, page_index:number, page_size:number) : Promise<RawPage<Models.OptionGroup>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_OptionGroups?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OptionGroup>(json, e => { return {...e, }})
}







export async function get_Application_Application_OptionGroups_OptionGroup(source:Models.Application, page_index:number, page_size:number, id:number) : Promise<Models.OptionGroup> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_OptionGroups/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OptionGroup
}

export async function get_Application_Application_OptionGroups_OptionGroup_by_id(source:Models.Application, id:number) : Promise<Models.OptionGroup> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_OptionGroups/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OptionGroup
}




export async function get_Application_Application_Discounts(source:Models.Application, page_index:number, page_size:number) : Promise<RawPage<Models.Discount>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Discounts?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Discount>(json, e => { return {...e, EndDate: readDBTime(e.EndDate), StartDate: readDBTime(e.StartDate)}})
}







export async function get_Application_Application_Discounts_Discount(source:Models.Application, page_index:number, page_size:number, id:number) : Promise<Models.Discount> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Discounts/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), EndDate: readDBTime(json.EndDate), StartDate: readDBTime(json.StartDate) } as Models.Discount
}

export async function get_Application_Application_Discounts_Discount_by_id(source:Models.Application, id:number) : Promise<Models.Discount> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Discounts/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), EndDate: readDBTime(json.EndDate), StartDate: readDBTime(json.StartDate) } as Models.Discount
}




export async function get_Application_Application_PayMethods(source:Models.Application, page_index:number, page_size:number) : Promise<RawPage<Models.PayMethod>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_PayMethods?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PayMethod>(json, e => { return {...e, }})
}







export async function get_Application_Application_PayMethods_PayMethod(source:Models.Application, page_index:number, page_size:number, id:number) : Promise<Models.PayMethod> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_PayMethods/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.PayMethod
}

export async function get_Application_Application_PayMethods_PayMethod_by_id(source:Models.Application, id:number) : Promise<Models.PayMethod> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_PayMethods/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.PayMethod
}




export async function get_Application_Application_POS4S(source:Models.Application, page_index:number, page_size:number) : Promise<RawPage<Models.POS4>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_POS4S?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.POS4>(json, e => { return {...e, TokenExpiration: readDBTime(e.TokenExpiration)}})
}







export async function get_Application_Application_POS4S_POS4(source:Models.Application, page_index:number, page_size:number, id:number) : Promise<Models.POS4> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_POS4S/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), TokenExpiration: readDBTime(json.TokenExpiration) } as Models.POS4
}

export async function get_Application_Application_POS4S_POS4_by_id(source:Models.Application, id:number) : Promise<Models.POS4> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_POS4S/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), TokenExpiration: readDBTime(json.TokenExpiration) } as Models.POS4
}




export async function get_Application_Application_Companies(source:Models.Application, page_index:number, page_size:number) : Promise<RawPage<Models.Company>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Companies?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Company>(json, e => { return {...e, }})
}







export async function get_Application_Application_Companies_Company(source:Models.Application, page_index:number, page_size:number, id:number) : Promise<Models.Company> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Companies/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Company
}

export async function get_Application_Application_Companies_Company_by_id(source:Models.Application, id:number) : Promise<Models.Company> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_Companies/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Company
}




export async function get_Application_Application_POS4LoginEvents(source:Models.Application, page_index:number, page_size:number) : Promise<RawPage<Models.POS4LoginEvent>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_POS4LoginEvents?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.POS4LoginEvent>(json, e => { return {...e, Date: readDBTime(e.Date)}})
}







export async function get_Application_Application_POS4LoginEvents_POS4LoginEvent(source:Models.Application, page_index:number, page_size:number, id:number) : Promise<Models.POS4LoginEvent> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_POS4LoginEvents/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), Date: readDBTime(json.Date) } as Models.POS4LoginEvent
}

export async function get_Application_Application_POS4LoginEvents_POS4LoginEvent_by_id(source:Models.Application, id:number) : Promise<Models.POS4LoginEvent> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_POS4LoginEvents/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), Date: readDBTime(json.Date) } as Models.POS4LoginEvent
}




export async function get_Application_Application_POS4OrderFetchEvents(source:Models.Application, page_index:number, page_size:number) : Promise<RawPage<Models.POS4OrderFetchEvent>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_POS4OrderFetchEvents?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.POS4OrderFetchEvent>(json, e => { return {...e, Date: readDBTime(e.Date)}})
}







export async function get_Application_Application_POS4OrderFetchEvents_POS4OrderFetchEvent(source:Models.Application, page_index:number, page_size:number, id:number) : Promise<Models.POS4OrderFetchEvent> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_POS4OrderFetchEvents/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), Date: readDBTime(json.Date) } as Models.POS4OrderFetchEvent
}

export async function get_Application_Application_POS4OrderFetchEvents_POS4OrderFetchEvent_by_id(source:Models.Application, id:number) : Promise<Models.POS4OrderFetchEvent> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${source.Id}/Application_POS4OrderFetchEvents/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), Date: readDBTime(json.Date) } as Models.POS4OrderFetchEvent
}




export async function create_Application() : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Application/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}

export async function update_Application(item:Models.Application) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Application/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_Application_with_pictures(item:Models.Application) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Application/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_Application(source:Models.Application) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/Application/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_Application(id:number) : Promise<ItemWithEditable<Models.Application>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.Application,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Application_with_pictures(id:number) : Promise<ItemWithEditable<Models.Application>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.Application,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Applications(page_index:number, page_size:number) : Promise<RawPage<Models.Application>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Application?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Application>(json, e => { return {...e, }})
}










  
  
export async function get_FranchiseManager_Application_FranchiseManagers(source:Models.FranchiseManager, page_index:number, page_size:number) : Promise<RawPage<Models.Application>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/FranchiseManager/${source.Id}/FranchiseManager_Applications?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Application>(json, e => { return {...e, }})
}







export async function get_FranchiseManager_Application_FranchiseManagers_Application(source:Models.FranchiseManager, page_index:number, page_size:number, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/FranchiseManager/${source.Id}/FranchiseManager_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}

export async function get_FranchiseManager_Application_FranchiseManagers_Application_by_id(source:Models.FranchiseManager, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/FranchiseManager/${source.Id}/FranchiseManager_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}




export async function get_FranchiseManager_FranchiseManager_Restaurants(source:Models.FranchiseManager, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/FranchiseManager/${source.Id}/FranchiseManager_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}







export async function get_FranchiseManager_FranchiseManager_Restaurants_Restaurant(source:Models.FranchiseManager, page_index:number, page_size:number, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/FranchiseManager/${source.Id}/FranchiseManager_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function get_FranchiseManager_FranchiseManager_Restaurants_Restaurant_by_id(source:Models.FranchiseManager, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/FranchiseManager/${source.Id}/FranchiseManager_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}


export async function get_unlinked_FranchiseManager_FranchiseManager_Restaurants(source:Models.FranchiseManager, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/FranchiseManager/${source.Id}/unlinked/FranchiseManager_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}

    
export async function create_linked_FranchiseManager_FranchiseManager_Restaurants_Restaurant(source:Models.FranchiseManager) : Promise<Models.Restaurant[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/FranchiseManager/${source.Id}/FranchiseManager_Restaurants_Restaurant`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Restaurant[]
}

export async function link_FranchiseManager_FranchiseManager_Restaurants(source:Models.FranchiseManager, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/FranchiseManager/${source.Id}/FranchiseManager_Restaurants/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_FranchiseManager_FranchiseManager_Restaurants(source:Models.FranchiseManager, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/FranchiseManager/${source.Id}/FranchiseManager_Restaurants/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_FranchiseManager() : Promise<Models.FranchiseManager> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/FranchiseManager/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.FranchiseManager
}

export async function update_FranchiseManager(item:Models.FranchiseManager) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/FranchiseManager/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_FranchiseManager_with_pictures(item:Models.FranchiseManager) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/FranchiseManager/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_FranchiseManager(source:Models.FranchiseManager) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/FranchiseManager/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_FranchiseManager(id:number) : Promise<ItemWithEditable<Models.FranchiseManager>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/FranchiseManager/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.FranchiseManager,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_FranchiseManager_with_pictures(id:number) : Promise<ItemWithEditable<Models.FranchiseManager>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/FranchiseManager/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.FranchiseManager,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_FranchiseManagers(page_index:number, page_size:number) : Promise<RawPage<Models.FranchiseManager>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/FranchiseManager?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.FranchiseManager>(json, e => { return {...e, }})
}










export async function delete_FranchiseManager_sessions() : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/FranchiseManager/DeleteSessions`,
    { method: 'post', credentials: 'include',
      headers: headers
    })
  return
}

export async function active_FranchiseManager_sessions() : Promise<Array<{Item1: string, Item2: Date}>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/FranchiseManager/ActiveSessions`,
    { method: 'post', credentials: 'include',
      headers: headers
    })
  if (!res.ok) return []
  let json = await res.json()
  return json as Array<{Item1: string, Item2: Date}>
}

export async function change_FranchiseManager_Email(oldEmail:string, newEmail: string, password: string) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  
  let res = await fetch(`/api/v1/FranchiseManager/ChangeEmail`,
    { method: 'post', credentials: 'include',
      body: JSON.stringify({OldEmail: oldEmail, NewEmail: newEmail, Password: password}),
      headers: headers})
    if (!res.ok) throw Error(res.statusText)
    return
}

export async function verify_FranchiseManager_EmailToken(oldEmail:string, newEmail: string, token: string) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  
  let res = await fetch(`/api/v1/FranchiseManager/ChangeEmailConfirm`,
    { method: 'post', credentials: 'include',
      body: JSON.stringify({OldEmail:oldEmail, NewEmail: newEmail, Token: token}),
      headers: headers})
    if (!res.ok) throw Error(res.statusText)
    return
}

export async function change_FranchiseManager_Username(email: string, username: string, password: string) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  
  let res = await fetch(`/api/v1/FranchiseManager/ChangeUsername`,
    { method: 'post', credentials: 'include',
      body: JSON.stringify({Email: email, Username: username, Password: password}),
      headers: headers})
    if (!res.ok) throw Error(res.statusText)
    return
}

export async function change_FranchiseManager_password(username:string, email:string, password:string, new_password:string, new_password_confirmation:string) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  
  let res = await fetch(`/api/v1/FranchiseManager/ChangePassword`,
    { method: 'post', credentials: 'include',
      body: JSON.stringify({Username:username, Email:email, Password:password, NewPassword:new_password, NewPasswordConfirmation:new_password_confirmation}),
      headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}
  
  
export async function get_PostCodeRange_Restaurant_PostCodeRanges(source:Models.PostCodeRange, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeRange/${source.Id}/PostCodeRange_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}







export async function get_PostCodeRange_Restaurant_PostCodeRanges_Restaurant(source:Models.PostCodeRange, page_index:number, page_size:number, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeRange/${source.Id}/PostCodeRange_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function get_PostCodeRange_Restaurant_PostCodeRanges_Restaurant_by_id(source:Models.PostCodeRange, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeRange/${source.Id}/PostCodeRange_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}


export async function get_unlinked_PostCodeRange_Restaurant_PostCodeRanges(source:Models.PostCodeRange, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeRange/${source.Id}/unlinked/PostCodeRange_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}

    
export async function create_linked_PostCodeRange_Restaurant_PostCodeRanges_Restaurant(source:Models.PostCodeRange) : Promise<Models.Restaurant[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeRange/${source.Id}/PostCodeRange_Restaurants_Restaurant`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Restaurant[]
}

export async function link_PostCodeRange_Restaurant_PostCodeRanges(source:Models.PostCodeRange, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeRange/${source.Id}/PostCodeRange_Restaurants/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_PostCodeRange_Restaurant_PostCodeRanges(source:Models.PostCodeRange, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeRange/${source.Id}/PostCodeRange_Restaurants/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_PostCodeRange_PostCodeRange_PostCodeExclusions(source:Models.PostCodeRange, page_index:number, page_size:number) : Promise<RawPage<Models.PostCodeExclusion>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeRange/${source.Id}/PostCodeRange_PostCodeExclusions?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PostCodeExclusion>(json, e => { return {...e, }})
}







export async function get_PostCodeRange_PostCodeRange_PostCodeExclusions_PostCodeExclusion(source:Models.PostCodeRange, page_index:number, page_size:number, id:number) : Promise<Models.PostCodeExclusion> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeRange/${source.Id}/PostCodeRange_PostCodeExclusions/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.PostCodeExclusion
}

export async function get_PostCodeRange_PostCodeRange_PostCodeExclusions_PostCodeExclusion_by_id(source:Models.PostCodeRange, id:number) : Promise<Models.PostCodeExclusion> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeRange/${source.Id}/PostCodeRange_PostCodeExclusions/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.PostCodeExclusion
}


export async function get_unlinked_PostCodeRange_PostCodeRange_PostCodeExclusions(source:Models.PostCodeRange, page_index:number, page_size:number) : Promise<RawPage<Models.PostCodeExclusion>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeRange/${source.Id}/unlinked/PostCodeRange_PostCodeExclusions?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PostCodeExclusion>(json, e => { return {...e, }})
}

    
export async function create_linked_PostCodeRange_PostCodeRange_PostCodeExclusions_PostCodeExclusion(source:Models.PostCodeRange) : Promise<Models.PostCodeExclusion[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeRange/${source.Id}/PostCodeRange_PostCodeExclusions_PostCodeExclusion`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.PostCodeExclusion[]
}

export async function link_PostCodeRange_PostCodeRange_PostCodeExclusions(source:Models.PostCodeRange, target:Models.PostCodeExclusion) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeRange/${source.Id}/PostCodeRange_PostCodeExclusions/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_PostCodeRange_PostCodeRange_PostCodeExclusions(source:Models.PostCodeRange, target:Models.PostCodeExclusion) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeRange/${source.Id}/PostCodeRange_PostCodeExclusions/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_PostCodeRange() : Promise<Models.PostCodeRange> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/PostCodeRange/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.PostCodeRange
}

export async function update_PostCodeRange(item:Models.PostCodeRange) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/PostCodeRange/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_PostCodeRange_with_pictures(item:Models.PostCodeRange) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/PostCodeRange/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_PostCodeRange(source:Models.PostCodeRange) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/PostCodeRange/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_PostCodeRange(id:number) : Promise<ItemWithEditable<Models.PostCodeRange>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeRange/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.PostCodeRange,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_PostCodeRange_with_pictures(id:number) : Promise<ItemWithEditable<Models.PostCodeRange>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeRange/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.PostCodeRange,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_PostCodeRanges(page_index:number, page_size:number) : Promise<RawPage<Models.PostCodeRange>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/PostCodeRange?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PostCodeRange>(json, e => { return {...e, }})
}










  
  
export async function get_POS4LoginEvent_Application_POS4LoginEvents(source:Models.POS4LoginEvent, page_index:number, page_size:number) : Promise<RawPage<Models.Application>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4LoginEvent/${source.Id}/POS4LoginEvent_Applications?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Application>(json, e => { return {...e, }})
}







export async function get_POS4LoginEvent_Application_POS4LoginEvents_Application(source:Models.POS4LoginEvent, page_index:number, page_size:number, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4LoginEvent/${source.Id}/POS4LoginEvent_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}

export async function get_POS4LoginEvent_Application_POS4LoginEvents_Application_by_id(source:Models.POS4LoginEvent, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4LoginEvent/${source.Id}/POS4LoginEvent_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}




export async function get_POS4LoginEvent_POS4_POS4LoginEvents(source:Models.POS4LoginEvent, page_index:number, page_size:number) : Promise<RawPage<Models.POS4>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4LoginEvent/${source.Id}/POS4LoginEvent_POS4S?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.POS4>(json, e => { return {...e, TokenExpiration: readDBTime(e.TokenExpiration)}})
}







export async function get_POS4LoginEvent_POS4_POS4LoginEvents_POS4(source:Models.POS4LoginEvent, page_index:number, page_size:number, id:number) : Promise<Models.POS4> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4LoginEvent/${source.Id}/POS4LoginEvent_POS4S/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), TokenExpiration: readDBTime(json.TokenExpiration) } as Models.POS4
}

export async function get_POS4LoginEvent_POS4_POS4LoginEvents_POS4_by_id(source:Models.POS4LoginEvent, id:number) : Promise<Models.POS4> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4LoginEvent/${source.Id}/POS4LoginEvent_POS4S/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), TokenExpiration: readDBTime(json.TokenExpiration) } as Models.POS4
}


export async function get_unlinked_POS4LoginEvent_POS4_POS4LoginEvents(source:Models.POS4LoginEvent, page_index:number, page_size:number) : Promise<RawPage<Models.POS4>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4LoginEvent/${source.Id}/unlinked/POS4LoginEvent_POS4S?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.POS4>(json, e => { return {...e, TokenExpiration: readDBTime(e.TokenExpiration)}})
}

    
export async function create_linked_POS4LoginEvent_POS4_POS4LoginEvents_POS4(source:Models.POS4LoginEvent) : Promise<Models.POS4[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4LoginEvent/${source.Id}/POS4LoginEvent_POS4S_POS4`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate), TokenExpiration: readDBTime(e.TokenExpiration) }}) as Models.POS4[]
}

export async function link_POS4LoginEvent_POS4_POS4LoginEvents(source:Models.POS4LoginEvent, target:Models.POS4) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4LoginEvent/${source.Id}/POS4LoginEvent_POS4S/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_POS4LoginEvent_POS4_POS4LoginEvents(source:Models.POS4LoginEvent, target:Models.POS4) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4LoginEvent/${source.Id}/POS4LoginEvent_POS4S/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_POS4LoginEvent() : Promise<Models.POS4LoginEvent> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/POS4LoginEvent/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), Date: readDBTime(json.Date) } as Models.POS4LoginEvent
}

export async function update_POS4LoginEvent(item:Models.POS4LoginEvent) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/POS4LoginEvent/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_POS4LoginEvent_with_pictures(item:Models.POS4LoginEvent) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/POS4LoginEvent/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_POS4LoginEvent(source:Models.POS4LoginEvent) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/POS4LoginEvent/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_POS4LoginEvent(id:number) : Promise<ItemWithEditable<Models.POS4LoginEvent>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4LoginEvent/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), Date: readDBTime(json.Item.Date) } as Models.POS4LoginEvent,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_POS4LoginEvent_with_pictures(id:number) : Promise<ItemWithEditable<Models.POS4LoginEvent>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4LoginEvent/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), Date: readDBTime(json.Item.Date) } as Models.POS4LoginEvent,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_POS4LoginEvents(page_index:number, page_size:number) : Promise<RawPage<Models.POS4LoginEvent>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4LoginEvent?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.POS4LoginEvent>(json, e => { return {...e, Date: readDBTime(e.Date)}})
}










  
  
export async function get_MollieKeys_Restaurant_MollieKeyss(source:Models.MollieKeys, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/MollieKeys/${source.Id}/MollieKeys_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}







export async function get_MollieKeys_Restaurant_MollieKeyss_Restaurant(source:Models.MollieKeys, page_index:number, page_size:number, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/MollieKeys/${source.Id}/MollieKeys_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function get_MollieKeys_Restaurant_MollieKeyss_Restaurant_by_id(source:Models.MollieKeys, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/MollieKeys/${source.Id}/MollieKeys_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}


export async function get_unlinked_MollieKeys_Restaurant_MollieKeyss(source:Models.MollieKeys, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/MollieKeys/${source.Id}/unlinked/MollieKeys_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}

    
export async function create_linked_MollieKeys_Restaurant_MollieKeyss_Restaurant(source:Models.MollieKeys) : Promise<Models.Restaurant[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/MollieKeys/${source.Id}/MollieKeys_Restaurants_Restaurant`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Restaurant[]
}

export async function link_MollieKeys_Restaurant_MollieKeyss(source:Models.MollieKeys, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/MollieKeys/${source.Id}/MollieKeys_Restaurants/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_MollieKeys_Restaurant_MollieKeyss(source:Models.MollieKeys, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/MollieKeys/${source.Id}/MollieKeys_Restaurants/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_MollieKeys() : Promise<Models.MollieKeys> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/MollieKeys/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.MollieKeys
}

export async function update_MollieKeys(item:Models.MollieKeys) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/MollieKeys/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_MollieKeys_with_pictures(item:Models.MollieKeys) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/MollieKeys/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_MollieKeys(source:Models.MollieKeys) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/MollieKeys/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_MollieKeys(id:number) : Promise<ItemWithEditable<Models.MollieKeys>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/MollieKeys/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.MollieKeys,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_MollieKeys_with_pictures(id:number) : Promise<ItemWithEditable<Models.MollieKeys>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/MollieKeys/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.MollieKeys,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_MollieKeyss(page_index:number, page_size:number) : Promise<RawPage<Models.MollieKeys>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/MollieKeys?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.MollieKeys>(json, e => { return {...e, }})
}










  
  
export async function get_DeliveryTimes_Restaurant_DeliveryTimess(source:Models.DeliveryTimes, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryTimes/${source.Id}/DeliveryTimes_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}







export async function get_DeliveryTimes_Restaurant_DeliveryTimess_Restaurant(source:Models.DeliveryTimes, page_index:number, page_size:number, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryTimes/${source.Id}/DeliveryTimes_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function get_DeliveryTimes_Restaurant_DeliveryTimess_Restaurant_by_id(source:Models.DeliveryTimes, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryTimes/${source.Id}/DeliveryTimes_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}


export async function get_unlinked_DeliveryTimes_Restaurant_DeliveryTimess(source:Models.DeliveryTimes, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryTimes/${source.Id}/unlinked/DeliveryTimes_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}

    
export async function create_linked_DeliveryTimes_Restaurant_DeliveryTimess_Restaurant(source:Models.DeliveryTimes) : Promise<Models.Restaurant[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryTimes/${source.Id}/DeliveryTimes_Restaurants_Restaurant`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Restaurant[]
}

export async function link_DeliveryTimes_Restaurant_DeliveryTimess(source:Models.DeliveryTimes, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryTimes/${source.Id}/DeliveryTimes_Restaurants/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_DeliveryTimes_Restaurant_DeliveryTimess(source:Models.DeliveryTimes, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryTimes/${source.Id}/DeliveryTimes_Restaurants/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_DeliveryTimes() : Promise<Models.DeliveryTimes> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/DeliveryTimes/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), SatDeliveryEnd: readDBTime(json.SatDeliveryEnd), SunDeliveryStart: readDBTime(json.SunDeliveryStart), TueDeliveryEnd: readDBTime(json.TueDeliveryEnd), ThuDeliveryEnd: readDBTime(json.ThuDeliveryEnd), SatDeliveryStart: readDBTime(json.SatDeliveryStart), TueDeliveryStart: readDBTime(json.TueDeliveryStart), FriDeliveryEnd: readDBTime(json.FriDeliveryEnd), ThuDeliveryStart: readDBTime(json.ThuDeliveryStart), FriDeliveryStart: readDBTime(json.FriDeliveryStart), WedDeliveryEnd: readDBTime(json.WedDeliveryEnd), MondayDeliveryEnd: readDBTime(json.MondayDeliveryEnd), WedDeliveryStart: readDBTime(json.WedDeliveryStart), SunDeliveryEnd: readDBTime(json.SunDeliveryEnd), MondayDeliveryStart: readDBTime(json.MondayDeliveryStart) } as Models.DeliveryTimes
}

export async function update_DeliveryTimes(item:Models.DeliveryTimes) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/DeliveryTimes/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_DeliveryTimes_with_pictures(item:Models.DeliveryTimes) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/DeliveryTimes/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_DeliveryTimes(source:Models.DeliveryTimes) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/DeliveryTimes/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_DeliveryTimes(id:number) : Promise<ItemWithEditable<Models.DeliveryTimes>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryTimes/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), SatDeliveryEnd: readDBTime(json.Item.SatDeliveryEnd), SunDeliveryStart: readDBTime(json.Item.SunDeliveryStart), TueDeliveryEnd: readDBTime(json.Item.TueDeliveryEnd), ThuDeliveryEnd: readDBTime(json.Item.ThuDeliveryEnd), SatDeliveryStart: readDBTime(json.Item.SatDeliveryStart), TueDeliveryStart: readDBTime(json.Item.TueDeliveryStart), FriDeliveryEnd: readDBTime(json.Item.FriDeliveryEnd), ThuDeliveryStart: readDBTime(json.Item.ThuDeliveryStart), FriDeliveryStart: readDBTime(json.Item.FriDeliveryStart), WedDeliveryEnd: readDBTime(json.Item.WedDeliveryEnd), MondayDeliveryEnd: readDBTime(json.Item.MondayDeliveryEnd), WedDeliveryStart: readDBTime(json.Item.WedDeliveryStart), SunDeliveryEnd: readDBTime(json.Item.SunDeliveryEnd), MondayDeliveryStart: readDBTime(json.Item.MondayDeliveryStart) } as Models.DeliveryTimes,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_DeliveryTimes_with_pictures(id:number) : Promise<ItemWithEditable<Models.DeliveryTimes>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryTimes/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), SatDeliveryEnd: readDBTime(json.Item.SatDeliveryEnd), SunDeliveryStart: readDBTime(json.Item.SunDeliveryStart), TueDeliveryEnd: readDBTime(json.Item.TueDeliveryEnd), ThuDeliveryEnd: readDBTime(json.Item.ThuDeliveryEnd), SatDeliveryStart: readDBTime(json.Item.SatDeliveryStart), TueDeliveryStart: readDBTime(json.Item.TueDeliveryStart), FriDeliveryEnd: readDBTime(json.Item.FriDeliveryEnd), ThuDeliveryStart: readDBTime(json.Item.ThuDeliveryStart), FriDeliveryStart: readDBTime(json.Item.FriDeliveryStart), WedDeliveryEnd: readDBTime(json.Item.WedDeliveryEnd), MondayDeliveryEnd: readDBTime(json.Item.MondayDeliveryEnd), WedDeliveryStart: readDBTime(json.Item.WedDeliveryStart), SunDeliveryEnd: readDBTime(json.Item.SunDeliveryEnd), MondayDeliveryStart: readDBTime(json.Item.MondayDeliveryStart) } as Models.DeliveryTimes,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_DeliveryTimess(page_index:number, page_size:number) : Promise<RawPage<Models.DeliveryTimes>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DeliveryTimes?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.DeliveryTimes>(json, e => { return {...e, SatDeliveryEnd: readDBTime(e.SatDeliveryEnd), SunDeliveryStart: readDBTime(e.SunDeliveryStart), TueDeliveryEnd: readDBTime(e.TueDeliveryEnd), ThuDeliveryEnd: readDBTime(e.ThuDeliveryEnd), SatDeliveryStart: readDBTime(e.SatDeliveryStart), TueDeliveryStart: readDBTime(e.TueDeliveryStart), FriDeliveryEnd: readDBTime(e.FriDeliveryEnd), ThuDeliveryStart: readDBTime(e.ThuDeliveryStart), FriDeliveryStart: readDBTime(e.FriDeliveryStart), WedDeliveryEnd: readDBTime(e.WedDeliveryEnd), MondayDeliveryEnd: readDBTime(e.MondayDeliveryEnd), WedDeliveryStart: readDBTime(e.WedDeliveryStart), SunDeliveryEnd: readDBTime(e.SunDeliveryEnd), MondayDeliveryStart: readDBTime(e.MondayDeliveryStart)}})
}










  
  
export async function get_OptionGroupPosition_Product_OptionGroupPositions(source:Models.OptionGroupPosition, page_index:number, page_size:number) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroupPosition/${source.Id}/OptionGroupPosition_Products?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}


export async function search_OptionGroupPosition_Product_OptionGroupPositions(source:Models.OptionGroupPosition, page_index:number, page_size:number, search_value: SearchQueryItem) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let body;
  if(search_value.searchQueryString && search_value.searchQueryString.length > 0)
    body = {
    "relationship": {
          "firstTable": "Product",
          "joins": [
            {
              "item1": {
                "table": "Product_OptionGroupPosition",
                "source": "Product",
                "target": "OptionGroupPosition"
              },
              "item2": "OptionGroupPosition"
            }
          ]
      }
      , "expression" :  {
  "kind": "And",
  "left": {
  "kind": "Or",
  "left": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Product.Name"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
},
  "right": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Product.InternalName"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
}
},
  "right": {
    "kind": "Eq",
    "left": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "OptionGroupPosition.Id"
      }
    },
    "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "Integer",
        "value": source.Id
      }
    }
  }
}
      , "aggregations": []
  }
  else
    body = {
    "relationship": {
          "firstTable": "Product",
          "joins": [
            {
              "item1": {
                "table": "Product_OptionGroupPosition",
                "source": "Product",
                "target": "OptionGroupPosition"
              },
              "item2": "OptionGroupPosition"
            }
          ]
      }
      , "expression" :  {
    "kind": "Eq",
    "left": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "OptionGroupPosition.Id"
      }
    },
    "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "Integer",
        "value": source.Id
      }
    }
  }
      , "aggregations": []
  }

  let res = await fetch(`/api/search?page_index=${page_index}&page_size=${page_size}`, { method: 'post', credentials: 'include', headers: headers, body: JSON.stringify(body) })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}






export async function get_OptionGroupPosition_Product_OptionGroupPositions_Product(source:Models.OptionGroupPosition, page_index:number, page_size:number, id:number) : Promise<Models.Product> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroupPosition/${source.Id}/OptionGroupPosition_Products/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Product
}

export async function get_OptionGroupPosition_Product_OptionGroupPositions_Product_by_id(source:Models.OptionGroupPosition, id:number) : Promise<Models.Product> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroupPosition/${source.Id}/OptionGroupPosition_Products/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Product
}


export async function get_unlinked_OptionGroupPosition_Product_OptionGroupPositions(source:Models.OptionGroupPosition, page_index:number, page_size:number) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroupPosition/${source.Id}/unlinked/OptionGroupPosition_Products?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}

    
export async function create_linked_OptionGroupPosition_Product_OptionGroupPositions_Product(source:Models.OptionGroupPosition) : Promise<Models.Product[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroupPosition/${source.Id}/OptionGroupPosition_Products_Product`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Product[]
}

export async function link_OptionGroupPosition_Product_OptionGroupPositions(source:Models.OptionGroupPosition, target:Models.Product) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroupPosition/${source.Id}/OptionGroupPosition_Products/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_OptionGroupPosition_Product_OptionGroupPositions(source:Models.OptionGroupPosition, target:Models.Product) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroupPosition/${source.Id}/OptionGroupPosition_Products/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_OptionGroupPosition_OptionGroup_OptionGroupPositions(source:Models.OptionGroupPosition, page_index:number, page_size:number) : Promise<RawPage<Models.OptionGroup>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroupPosition/${source.Id}/OptionGroupPosition_OptionGroups?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OptionGroup>(json, e => { return {...e, }})
}







export async function get_OptionGroupPosition_OptionGroup_OptionGroupPositions_OptionGroup(source:Models.OptionGroupPosition, page_index:number, page_size:number, id:number) : Promise<Models.OptionGroup> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroupPosition/${source.Id}/OptionGroupPosition_OptionGroups/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OptionGroup
}

export async function get_OptionGroupPosition_OptionGroup_OptionGroupPositions_OptionGroup_by_id(source:Models.OptionGroupPosition, id:number) : Promise<Models.OptionGroup> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroupPosition/${source.Id}/OptionGroupPosition_OptionGroups/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OptionGroup
}


export async function get_unlinked_OptionGroupPosition_OptionGroup_OptionGroupPositions(source:Models.OptionGroupPosition, page_index:number, page_size:number) : Promise<RawPage<Models.OptionGroup>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroupPosition/${source.Id}/unlinked/OptionGroupPosition_OptionGroups?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OptionGroup>(json, e => { return {...e, }})
}

    
export async function create_linked_OptionGroupPosition_OptionGroup_OptionGroupPositions_OptionGroup(source:Models.OptionGroupPosition) : Promise<Models.OptionGroup[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroupPosition/${source.Id}/OptionGroupPosition_OptionGroups_OptionGroup`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.OptionGroup[]
}

export async function link_OptionGroupPosition_OptionGroup_OptionGroupPositions(source:Models.OptionGroupPosition, target:Models.OptionGroup) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroupPosition/${source.Id}/OptionGroupPosition_OptionGroups/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_OptionGroupPosition_OptionGroup_OptionGroupPositions(source:Models.OptionGroupPosition, target:Models.OptionGroup) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroupPosition/${source.Id}/OptionGroupPosition_OptionGroups/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_OptionGroupPosition() : Promise<Models.OptionGroupPosition> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/OptionGroupPosition/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OptionGroupPosition
}

export async function update_OptionGroupPosition(item:Models.OptionGroupPosition) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/OptionGroupPosition/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_OptionGroupPosition_with_pictures(item:Models.OptionGroupPosition) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/OptionGroupPosition/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_OptionGroupPosition(source:Models.OptionGroupPosition) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/OptionGroupPosition/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_OptionGroupPosition(id:number) : Promise<ItemWithEditable<Models.OptionGroupPosition>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroupPosition/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.OptionGroupPosition,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_OptionGroupPosition_with_pictures(id:number) : Promise<ItemWithEditable<Models.OptionGroupPosition>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroupPosition/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.OptionGroupPosition,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_OptionGroupPositions(page_index:number, page_size:number) : Promise<RawPage<Models.OptionGroupPosition>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OptionGroupPosition?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OptionGroupPosition>(json, e => { return {...e, }})
}










  
  
export async function get_DefaultAddress_User_DefaultAddresses(source:Models.DefaultAddress, page_index:number, page_size:number) : Promise<RawPage<Models.User>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DefaultAddress/${source.Id}/DefaultAddress_Users?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.User>(json, e => { return {...e, }})
}







export async function get_DefaultAddress_User_DefaultAddresses_User(source:Models.DefaultAddress, page_index:number, page_size:number, id:number) : Promise<Models.User> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DefaultAddress/${source.Id}/DefaultAddress_Users/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.User
}

export async function get_DefaultAddress_User_DefaultAddresses_User_by_id(source:Models.DefaultAddress, id:number) : Promise<Models.User> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DefaultAddress/${source.Id}/DefaultAddress_Users/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.User
}


export async function get_unlinked_DefaultAddress_User_DefaultAddresses(source:Models.DefaultAddress, page_index:number, page_size:number) : Promise<RawPage<Models.User>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DefaultAddress/${source.Id}/unlinked/DefaultAddress_Users?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.User>(json, e => { return {...e, }})
}

    
export async function create_linked_DefaultAddress_User_DefaultAddresses_User(source:Models.DefaultAddress) : Promise<Models.User[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DefaultAddress/${source.Id}/DefaultAddress_Users_User`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.User[]
}

export async function link_DefaultAddress_User_DefaultAddresses(source:Models.DefaultAddress, target:Models.User) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DefaultAddress/${source.Id}/DefaultAddress_Users/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_DefaultAddress_User_DefaultAddresses(source:Models.DefaultAddress, target:Models.User) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DefaultAddress/${source.Id}/DefaultAddress_Users/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_DefaultAddress() : Promise<Models.DefaultAddress> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/DefaultAddress/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.DefaultAddress
}

export async function update_DefaultAddress(item:Models.DefaultAddress) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/DefaultAddress/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_DefaultAddress_with_pictures(item:Models.DefaultAddress) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/DefaultAddress/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_DefaultAddress(source:Models.DefaultAddress) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/DefaultAddress/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_DefaultAddress(id:number) : Promise<ItemWithEditable<Models.DefaultAddress>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DefaultAddress/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.DefaultAddress,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_DefaultAddress_with_pictures(id:number) : Promise<ItemWithEditable<Models.DefaultAddress>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DefaultAddress/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.DefaultAddress,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_DefaultAddresses(page_index:number, page_size:number) : Promise<RawPage<Models.DefaultAddress>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/DefaultAddress?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.DefaultAddress>(json, e => { return {...e, }})
}










  
  
export async function create_Address() : Promise<Models.Address> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Address/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Address
}

export async function update_Address(item:Models.Address) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = item.Kind == "AlternativeAddress" ? await update_AlternativeAddress(item as Models.AlternativeAddress) : item.Kind == "DefaultAddress" ? await update_DefaultAddress(item as Models.DefaultAddress) : null
  
  return
}

export async function update_Address_with_pictures(item:Models.Address) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = item.Kind == "AlternativeAddress" ? await update_AlternativeAddress(item as Models.AlternativeAddress) : item.Kind == "DefaultAddress" ? await update_DefaultAddress(item as Models.DefaultAddress) : null
  
  return
}

export async function delete_Address(source:Models.Address) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/Address/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_Address(id:number) : Promise<ItemWithEditable<Models.Address>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Address/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.Address,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Address_with_pictures(id:number) : Promise<ItemWithEditable<Models.Address>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Address/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.Address,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Addresses(page_index:number, page_size:number) : Promise<RawPage<Models.Address>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Address?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Address>(json, e => { return {...e, }})
}










  
  
export async function get_Option_Application_Options(source:Models.Option, page_index:number, page_size:number) : Promise<RawPage<Models.Application>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_Applications?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Application>(json, e => { return {...e, }})
}







export async function get_Option_Application_Options_Application(source:Models.Option, page_index:number, page_size:number, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}

export async function get_Option_Application_Options_Application_by_id(source:Models.Option, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}




export async function get_Option_Option_Restaurants(source:Models.Option, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}







export async function get_Option_Option_Restaurants_Restaurant(source:Models.Option, page_index:number, page_size:number, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function get_Option_Option_Restaurants_Restaurant_by_id(source:Models.Option, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}


export async function get_unlinked_Option_Option_Restaurants(source:Models.Option, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/unlinked/Option_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}

    
export async function create_linked_Option_Option_Restaurants_Restaurant(source:Models.Option) : Promise<Models.Restaurant[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_Restaurants_Restaurant`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Restaurant[]
}

export async function link_Option_Option_Restaurants(source:Models.Option, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_Restaurants/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Option_Option_Restaurants(source:Models.Option, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_Restaurants/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Option_OrderItemOption_Options(source:Models.Option, page_index:number, page_size:number) : Promise<RawPage<Models.OrderItemOption>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_OrderItemOptions?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OrderItemOption>(json, e => { return {...e, }})
}







export async function get_Option_OrderItemOption_Options_OrderItemOption(source:Models.Option, page_index:number, page_size:number, id:number) : Promise<Models.OrderItemOption> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_OrderItemOptions/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OrderItemOption
}

export async function get_Option_OrderItemOption_Options_OrderItemOption_by_id(source:Models.Option, id:number) : Promise<Models.OrderItemOption> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_OrderItemOptions/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OrderItemOption
}


export async function get_unlinked_Option_OrderItemOption_Options(source:Models.Option, page_index:number, page_size:number) : Promise<RawPage<Models.OrderItemOption>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/unlinked/Option_OrderItemOptions?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OrderItemOption>(json, e => { return {...e, }})
}

    
export async function create_linked_Option_OrderItemOption_Options_OrderItemOption(source:Models.Option) : Promise<Models.OrderItemOption[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_OrderItemOptions_OrderItemOption`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.OrderItemOption[]
}

export async function link_Option_OrderItemOption_Options(source:Models.Option, target:Models.OrderItemOption) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_OrderItemOptions/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Option_OrderItemOption_Options(source:Models.Option, target:Models.OrderItemOption) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_OrderItemOptions/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Option_OptionGroup_Options(source:Models.Option, page_index:number, page_size:number) : Promise<RawPage<Models.OptionGroup>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_OptionGroups?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OptionGroup>(json, e => { return {...e, }})
}







export async function get_Option_OptionGroup_Options_OptionGroup(source:Models.Option, page_index:number, page_size:number, id:number) : Promise<Models.OptionGroup> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_OptionGroups/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OptionGroup
}

export async function get_Option_OptionGroup_Options_OptionGroup_by_id(source:Models.Option, id:number) : Promise<Models.OptionGroup> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_OptionGroups/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OptionGroup
}


export async function get_unlinked_Option_OptionGroup_Options(source:Models.Option, page_index:number, page_size:number) : Promise<RawPage<Models.OptionGroup>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/unlinked/Option_OptionGroups?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OptionGroup>(json, e => { return {...e, }})
}

    
export async function create_linked_Option_OptionGroup_Options_OptionGroup(source:Models.Option) : Promise<Models.OptionGroup[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_OptionGroups_OptionGroup`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.OptionGroup[]
}

export async function link_Option_OptionGroup_Options(source:Models.Option, target:Models.OptionGroup) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_OptionGroups/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Option_OptionGroup_Options(source:Models.Option, target:Models.OptionGroup) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${source.Id}/Option_OptionGroups/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_Option() : Promise<Models.Option> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Option/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Option
}

export async function update_Option(item:Models.Option) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Option/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_Option_with_pictures(item:Models.Option) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Option/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_Option(source:Models.Option) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/Option/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_Option(id:number) : Promise<ItemWithEditable<Models.Option>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.Option,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Option_with_pictures(id:number) : Promise<ItemWithEditable<Models.Option>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.Option,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Options(page_index:number, page_size:number) : Promise<RawPage<Models.Option>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Option?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Option>(json, e => { return {...e, }})
}










  
  
export async function get_OrderItemOption_OrderItem_OrderItemOptions(source:Models.OrderItemOption, page_index:number, page_size:number) : Promise<RawPage<Models.OrderItem>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemOption/${source.Id}/OrderItemOption_OrderItems?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OrderItem>(json, e => { return {...e, }})
}







export async function get_OrderItemOption_OrderItem_OrderItemOptions_OrderItem(source:Models.OrderItemOption, page_index:number, page_size:number, id:number) : Promise<Models.OrderItem> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemOption/${source.Id}/OrderItemOption_OrderItems/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OrderItem
}

export async function get_OrderItemOption_OrderItem_OrderItemOptions_OrderItem_by_id(source:Models.OrderItemOption, id:number) : Promise<Models.OrderItem> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemOption/${source.Id}/OrderItemOption_OrderItems/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OrderItem
}


export async function get_unlinked_OrderItemOption_OrderItem_OrderItemOptions(source:Models.OrderItemOption, page_index:number, page_size:number) : Promise<RawPage<Models.OrderItem>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemOption/${source.Id}/unlinked/OrderItemOption_OrderItems?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OrderItem>(json, e => { return {...e, }})
}

    
export async function create_linked_OrderItemOption_OrderItem_OrderItemOptions_OrderItem(source:Models.OrderItemOption) : Promise<Models.OrderItem[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemOption/${source.Id}/OrderItemOption_OrderItems_OrderItem`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.OrderItem[]
}

export async function link_OrderItemOption_OrderItem_OrderItemOptions(source:Models.OrderItemOption, target:Models.OrderItem) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemOption/${source.Id}/OrderItemOption_OrderItems/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_OrderItemOption_OrderItem_OrderItemOptions(source:Models.OrderItemOption, target:Models.OrderItem) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemOption/${source.Id}/OrderItemOption_OrderItems/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_OrderItemOption_OrderItemOption_Options(source:Models.OrderItemOption, page_index:number, page_size:number) : Promise<RawPage<Models.Option>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemOption/${source.Id}/OrderItemOption_Options?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Option>(json, e => { return {...e, }})
}


export async function search_OrderItemOption_OrderItemOption_Options(source:Models.OrderItemOption, page_index:number, page_size:number, search_value: SearchQueryItem) : Promise<RawPage<Models.Option>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let body;
  if(search_value.searchQueryString && search_value.searchQueryString.length > 0)
    body = {
    "relationship": {
          "firstTable": "Option",
          "joins": [
            {
              "item1": {
                "table": "OrderItemOption_Option",
                "source": "OrderItemOption",
                "target": "Option"
              },
              "item2": "OrderItemOption"
            }
          ]
      }
      , "expression" :  {
  "kind": "And",
  "left": {
  "kind": "Or",
  "left": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Option.Name"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
},
  "right": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Option.InternalName"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
}
},
  "right": {
    "kind": "Eq",
    "left": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "OrderItemOption.Id"
      }
    },
    "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "Integer",
        "value": source.Id
      }
    }
  }
}
      , "aggregations": []
  }
  else
    body = {
    "relationship": {
          "firstTable": "Option",
          "joins": [
            {
              "item1": {
                "table": "OrderItemOption_Option",
                "source": "OrderItemOption",
                "target": "Option"
              },
              "item2": "OrderItemOption"
            }
          ]
      }
      , "expression" :  {
    "kind": "Eq",
    "left": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "OrderItemOption.Id"
      }
    },
    "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "Integer",
        "value": source.Id
      }
    }
  }
      , "aggregations": []
  }

  let res = await fetch(`/api/search?page_index=${page_index}&page_size=${page_size}`, { method: 'post', credentials: 'include', headers: headers, body: JSON.stringify(body) })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Option>(json, e => { return {...e, }})
}






export async function get_OrderItemOption_OrderItemOption_Options_Option(source:Models.OrderItemOption, page_index:number, page_size:number, id:number) : Promise<Models.Option> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemOption/${source.Id}/OrderItemOption_Options/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Option
}

export async function get_OrderItemOption_OrderItemOption_Options_Option_by_id(source:Models.OrderItemOption, id:number) : Promise<Models.Option> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemOption/${source.Id}/OrderItemOption_Options/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Option
}


export async function get_unlinked_OrderItemOption_OrderItemOption_Options(source:Models.OrderItemOption, page_index:number, page_size:number) : Promise<RawPage<Models.Option>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemOption/${source.Id}/unlinked/OrderItemOption_Options?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Option>(json, e => { return {...e, }})
}

    
export async function create_linked_OrderItemOption_OrderItemOption_Options_Option(source:Models.OrderItemOption) : Promise<Models.Option[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemOption/${source.Id}/OrderItemOption_Options_Option`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Option[]
}

export async function link_OrderItemOption_OrderItemOption_Options(source:Models.OrderItemOption, target:Models.Option) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemOption/${source.Id}/OrderItemOption_Options/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_OrderItemOption_OrderItemOption_Options(source:Models.OrderItemOption, target:Models.Option) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemOption/${source.Id}/OrderItemOption_Options/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_OrderItemOption() : Promise<Models.OrderItemOption> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/OrderItemOption/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.OrderItemOption
}

export async function update_OrderItemOption(item:Models.OrderItemOption) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/OrderItemOption/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_OrderItemOption_with_pictures(item:Models.OrderItemOption) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/OrderItemOption/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_OrderItemOption(source:Models.OrderItemOption) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/OrderItemOption/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_OrderItemOption(id:number) : Promise<ItemWithEditable<Models.OrderItemOption>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemOption/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.OrderItemOption,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_OrderItemOption_with_pictures(id:number) : Promise<ItemWithEditable<Models.OrderItemOption>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemOption/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.OrderItemOption,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_OrderItemOptions(page_index:number, page_size:number) : Promise<RawPage<Models.OrderItemOption>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/OrderItemOption?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.OrderItemOption>(json, e => { return {...e, }})
}










  
  
export async function get_ProductPosition_Category_ProductPositions(source:Models.ProductPosition, page_index:number, page_size:number) : Promise<RawPage<Models.Category>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/ProductPosition/${source.Id}/ProductPosition_Categories?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Category>(json, e => { return {...e, }})
}







export async function get_ProductPosition_Category_ProductPositions_Category(source:Models.ProductPosition, page_index:number, page_size:number, id:number) : Promise<Models.Category> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/ProductPosition/${source.Id}/ProductPosition_Categories/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Category
}

export async function get_ProductPosition_Category_ProductPositions_Category_by_id(source:Models.ProductPosition, id:number) : Promise<Models.Category> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/ProductPosition/${source.Id}/ProductPosition_Categories/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Category
}


export async function get_unlinked_ProductPosition_Category_ProductPositions(source:Models.ProductPosition, page_index:number, page_size:number) : Promise<RawPage<Models.Category>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/ProductPosition/${source.Id}/unlinked/ProductPosition_Categories?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Category>(json, e => { return {...e, }})
}

    
export async function create_linked_ProductPosition_Category_ProductPositions_Category(source:Models.ProductPosition) : Promise<Models.Category[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/ProductPosition/${source.Id}/ProductPosition_Categories_Category`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Category[]
}

export async function link_ProductPosition_Category_ProductPositions(source:Models.ProductPosition, target:Models.Category) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/ProductPosition/${source.Id}/ProductPosition_Categories/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_ProductPosition_Category_ProductPositions(source:Models.ProductPosition, target:Models.Category) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/ProductPosition/${source.Id}/ProductPosition_Categories/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_ProductPosition_Product_ProductPositions(source:Models.ProductPosition, page_index:number, page_size:number) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/ProductPosition/${source.Id}/ProductPosition_Products?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}


export async function search_ProductPosition_Product_ProductPositions(source:Models.ProductPosition, page_index:number, page_size:number, search_value: SearchQueryItem) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let body;
  if(search_value.searchQueryString && search_value.searchQueryString.length > 0)
    body = {
    "relationship": {
          "firstTable": "Product",
          "joins": [
            {
              "item1": {
                "table": "Product_ProductPosition",
                "source": "Product",
                "target": "ProductPosition"
              },
              "item2": "ProductPosition"
            }
          ]
      }
      , "expression" :  {
  "kind": "And",
  "left": {
  "kind": "Or",
  "left": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Product.Name"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
},
  "right": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Product.InternalName"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
}
},
  "right": {
    "kind": "Eq",
    "left": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "ProductPosition.Id"
      }
    },
    "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "Integer",
        "value": source.Id
      }
    }
  }
}
      , "aggregations": []
  }
  else
    body = {
    "relationship": {
          "firstTable": "Product",
          "joins": [
            {
              "item1": {
                "table": "Product_ProductPosition",
                "source": "Product",
                "target": "ProductPosition"
              },
              "item2": "ProductPosition"
            }
          ]
      }
      , "expression" :  {
    "kind": "Eq",
    "left": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "ProductPosition.Id"
      }
    },
    "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "Integer",
        "value": source.Id
      }
    }
  }
      , "aggregations": []
  }

  let res = await fetch(`/api/search?page_index=${page_index}&page_size=${page_size}`, { method: 'post', credentials: 'include', headers: headers, body: JSON.stringify(body) })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}






export async function get_ProductPosition_Product_ProductPositions_Product(source:Models.ProductPosition, page_index:number, page_size:number, id:number) : Promise<Models.Product> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/ProductPosition/${source.Id}/ProductPosition_Products/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Product
}

export async function get_ProductPosition_Product_ProductPositions_Product_by_id(source:Models.ProductPosition, id:number) : Promise<Models.Product> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/ProductPosition/${source.Id}/ProductPosition_Products/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Product
}


export async function get_unlinked_ProductPosition_Product_ProductPositions(source:Models.ProductPosition, page_index:number, page_size:number) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/ProductPosition/${source.Id}/unlinked/ProductPosition_Products?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}

    
export async function create_linked_ProductPosition_Product_ProductPositions_Product(source:Models.ProductPosition) : Promise<Models.Product[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/ProductPosition/${source.Id}/ProductPosition_Products_Product`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Product[]
}

export async function link_ProductPosition_Product_ProductPositions(source:Models.ProductPosition, target:Models.Product) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/ProductPosition/${source.Id}/ProductPosition_Products/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_ProductPosition_Product_ProductPositions(source:Models.ProductPosition, target:Models.Product) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/ProductPosition/${source.Id}/ProductPosition_Products/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_ProductPosition() : Promise<Models.ProductPosition> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/ProductPosition/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.ProductPosition
}

export async function update_ProductPosition(item:Models.ProductPosition) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/ProductPosition/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_ProductPosition_with_pictures(item:Models.ProductPosition) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/ProductPosition/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_ProductPosition(source:Models.ProductPosition) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/ProductPosition/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_ProductPosition(id:number) : Promise<ItemWithEditable<Models.ProductPosition>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/ProductPosition/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.ProductPosition,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_ProductPosition_with_pictures(id:number) : Promise<ItemWithEditable<Models.ProductPosition>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/ProductPosition/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.ProductPosition,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_ProductPositions(page_index:number, page_size:number) : Promise<RawPage<Models.ProductPosition>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/ProductPosition?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.ProductPosition>(json, e => { return {...e, }})
}










  
  
export async function get_WaitingTimes_Restaurant_WaitingTimess(source:Models.WaitingTimes, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/WaitingTimes/${source.Id}/WaitingTimes_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}







export async function get_WaitingTimes_Restaurant_WaitingTimess_Restaurant(source:Models.WaitingTimes, page_index:number, page_size:number, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/WaitingTimes/${source.Id}/WaitingTimes_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function get_WaitingTimes_Restaurant_WaitingTimess_Restaurant_by_id(source:Models.WaitingTimes, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/WaitingTimes/${source.Id}/WaitingTimes_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}


export async function get_unlinked_WaitingTimes_Restaurant_WaitingTimess(source:Models.WaitingTimes, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/WaitingTimes/${source.Id}/unlinked/WaitingTimes_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}

    
export async function create_linked_WaitingTimes_Restaurant_WaitingTimess_Restaurant(source:Models.WaitingTimes) : Promise<Models.Restaurant[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/WaitingTimes/${source.Id}/WaitingTimes_Restaurants_Restaurant`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Restaurant[]
}

export async function link_WaitingTimes_Restaurant_WaitingTimess(source:Models.WaitingTimes, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/WaitingTimes/${source.Id}/WaitingTimes_Restaurants/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_WaitingTimes_Restaurant_WaitingTimess(source:Models.WaitingTimes, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/WaitingTimes/${source.Id}/WaitingTimes_Restaurants/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_WaitingTimes() : Promise<Models.WaitingTimes> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/WaitingTimes/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.WaitingTimes
}

export async function update_WaitingTimes(item:Models.WaitingTimes) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/WaitingTimes/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_WaitingTimes_with_pictures(item:Models.WaitingTimes) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/WaitingTimes/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_WaitingTimes(source:Models.WaitingTimes) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/WaitingTimes/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_WaitingTimes(id:number) : Promise<ItemWithEditable<Models.WaitingTimes>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/WaitingTimes/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.WaitingTimes,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_WaitingTimes_with_pictures(id:number) : Promise<ItemWithEditable<Models.WaitingTimes>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/WaitingTimes/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.WaitingTimes,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_WaitingTimess(page_index:number, page_size:number) : Promise<RawPage<Models.WaitingTimes>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/WaitingTimes?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.WaitingTimes>(json, e => { return {...e, }})
}










  
  
export async function create_POS4Event() : Promise<Models.POS4Event> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/POS4Event/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), Date: readDBTime(json.Date) } as Models.POS4Event
}

export async function update_POS4Event(item:Models.POS4Event) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = item.Kind == "POS4OrderFetchEvent" ? await update_POS4OrderFetchEvent(item as Models.POS4OrderFetchEvent) : item.Kind == "POS4LoginEvent" ? await update_POS4LoginEvent(item as Models.POS4LoginEvent) : null
  
  return
}

export async function update_POS4Event_with_pictures(item:Models.POS4Event) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = item.Kind == "POS4OrderFetchEvent" ? await update_POS4OrderFetchEvent(item as Models.POS4OrderFetchEvent) : item.Kind == "POS4LoginEvent" ? await update_POS4LoginEvent(item as Models.POS4LoginEvent) : null
  
  return
}

export async function delete_POS4Event(source:Models.POS4Event) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/POS4Event/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_POS4Event(id:number) : Promise<ItemWithEditable<Models.POS4Event>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4Event/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), Date: readDBTime(json.Item.Date) } as Models.POS4Event,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_POS4Event_with_pictures(id:number) : Promise<ItemWithEditable<Models.POS4Event>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4Event/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate), Date: readDBTime(json.Item.Date) } as Models.POS4Event,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_POS4Events(page_index:number, page_size:number) : Promise<RawPage<Models.POS4Event>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/POS4Event?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.POS4Event>(json, e => { return {...e, Date: readDBTime(e.Date)}})
}










  
  
export async function get_Company_Restaurant_Companies(source:Models.Company, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${source.Id}/Company_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}







export async function get_Company_Restaurant_Companies_Restaurant(source:Models.Company, page_index:number, page_size:number, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${source.Id}/Company_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function get_Company_Restaurant_Companies_Restaurant_by_id(source:Models.Company, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${source.Id}/Company_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}


export async function get_unlinked_Company_Restaurant_Companies(source:Models.Company, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${source.Id}/unlinked/Company_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}

    
export async function create_linked_Company_Restaurant_Companies_Restaurant(source:Models.Company) : Promise<Models.Restaurant[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${source.Id}/Company_Restaurants_Restaurant`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Restaurant[]
}

export async function link_Company_Restaurant_Companies(source:Models.Company, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${source.Id}/Company_Restaurants/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Company_Restaurant_Companies(source:Models.Company, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${source.Id}/Company_Restaurants/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Company_Company_Users(source:Models.Company, page_index:number, page_size:number) : Promise<RawPage<Models.User>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${source.Id}/Company_Users?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.User>(json, e => { return {...e, }})
}







export async function get_Company_Company_Users_User(source:Models.Company, page_index:number, page_size:number, id:number) : Promise<Models.User> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${source.Id}/Company_Users/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.User
}

export async function get_Company_Company_Users_User_by_id(source:Models.Company, id:number) : Promise<Models.User> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${source.Id}/Company_Users/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.User
}


export async function get_unlinked_Company_Company_Users(source:Models.Company, page_index:number, page_size:number) : Promise<RawPage<Models.User>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${source.Id}/unlinked/Company_Users?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.User>(json, e => { return {...e, }})
}

    
export async function create_linked_Company_Company_Users_User(source:Models.Company) : Promise<Models.User[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${source.Id}/Company_Users_User`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.User[]
}

export async function link_Company_Company_Users(source:Models.Company, target:Models.User) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${source.Id}/Company_Users/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Company_Company_Users(source:Models.Company, target:Models.User) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${source.Id}/Company_Users/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Company_Application_Companies(source:Models.Company, page_index:number, page_size:number) : Promise<RawPage<Models.Application>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${source.Id}/Company_Applications?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Application>(json, e => { return {...e, }})
}







export async function get_Company_Application_Companies_Application(source:Models.Company, page_index:number, page_size:number, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${source.Id}/Company_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}

export async function get_Company_Application_Companies_Application_by_id(source:Models.Company, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${source.Id}/Company_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}




export async function create_Company() : Promise<Models.Company> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Company/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Company
}

export async function update_Company(item:Models.Company) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Company/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_Company_with_pictures(item:Models.Company) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Company/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_Company(source:Models.Company) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/Company/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_Company(id:number) : Promise<ItemWithEditable<Models.Company>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.Company,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Company_with_pictures(id:number) : Promise<ItemWithEditable<Models.Company>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.Company,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Companies(page_index:number, page_size:number) : Promise<RawPage<Models.Company>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Company?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Company>(json, e => { return {...e, }})
}










  
  
export async function get_Restaurant_Application_Restaurants(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.Application>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Applications?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Application>(json, e => { return {...e, }})
}







export async function get_Restaurant_Application_Restaurants_Application(source:Models.Restaurant, page_index:number, page_size:number, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}

export async function get_Restaurant_Application_Restaurants_Application_by_id(source:Models.Restaurant, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}




export async function get_Restaurant_FranchiseManager_Restaurants(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.FranchiseManager>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_FranchiseManagers?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.FranchiseManager>(json, e => { return {...e, }})
}







export async function get_Restaurant_FranchiseManager_Restaurants_FranchiseManager(source:Models.Restaurant, page_index:number, page_size:number, id:number) : Promise<Models.FranchiseManager> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_FranchiseManagers/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.FranchiseManager
}

export async function get_Restaurant_FranchiseManager_Restaurants_FranchiseManager_by_id(source:Models.Restaurant, id:number) : Promise<Models.FranchiseManager> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_FranchiseManagers/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.FranchiseManager
}


export async function get_unlinked_Restaurant_FranchiseManager_Restaurants(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.FranchiseManager>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/unlinked/Restaurant_FranchiseManagers?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.FranchiseManager>(json, e => { return {...e, }})
}

    
export async function create_linked_Restaurant_FranchiseManager_Restaurants_FranchiseManager(source:Models.Restaurant) : Promise<Models.FranchiseManager[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_FranchiseManagers_FranchiseManager`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.FranchiseManager[]
}

export async function link_Restaurant_FranchiseManager_Restaurants(source:Models.Restaurant, target:Models.FranchiseManager) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_FranchiseManagers/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Restaurant_FranchiseManager_Restaurants(source:Models.Restaurant, target:Models.FranchiseManager) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_FranchiseManagers/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Restaurant_Restaurant_WaitingTimess(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.WaitingTimes>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_WaitingTimess?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.WaitingTimes>(json, e => { return {...e, }})
}







export async function get_Restaurant_Restaurant_WaitingTimess_WaitingTimes(source:Models.Restaurant, page_index:number, page_size:number, id:number) : Promise<Models.WaitingTimes> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_WaitingTimess/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.WaitingTimes
}

export async function get_Restaurant_Restaurant_WaitingTimess_WaitingTimes_by_id(source:Models.Restaurant, id:number) : Promise<Models.WaitingTimes> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_WaitingTimess/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.WaitingTimes
}


export async function get_unlinked_Restaurant_Restaurant_WaitingTimess(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.WaitingTimes>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/unlinked/Restaurant_WaitingTimess?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.WaitingTimes>(json, e => { return {...e, }})
}

    
export async function create_linked_Restaurant_Restaurant_WaitingTimess_WaitingTimes(source:Models.Restaurant) : Promise<Models.WaitingTimes[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_WaitingTimess_WaitingTimes`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.WaitingTimes[]
}

export async function link_Restaurant_Restaurant_WaitingTimess(source:Models.Restaurant, target:Models.WaitingTimes) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_WaitingTimess/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Restaurant_Restaurant_WaitingTimess(source:Models.Restaurant, target:Models.WaitingTimes) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_WaitingTimess/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Restaurant_Restaurant_MollieKeyss(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.MollieKeys>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_MollieKeyss?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.MollieKeys>(json, e => { return {...e, }})
}







export async function get_Restaurant_Restaurant_MollieKeyss_MollieKeys(source:Models.Restaurant, page_index:number, page_size:number, id:number) : Promise<Models.MollieKeys> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_MollieKeyss/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.MollieKeys
}

export async function get_Restaurant_Restaurant_MollieKeyss_MollieKeys_by_id(source:Models.Restaurant, id:number) : Promise<Models.MollieKeys> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_MollieKeyss/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.MollieKeys
}


export async function get_unlinked_Restaurant_Restaurant_MollieKeyss(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.MollieKeys>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/unlinked/Restaurant_MollieKeyss?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.MollieKeys>(json, e => { return {...e, }})
}

    
export async function create_linked_Restaurant_Restaurant_MollieKeyss_MollieKeys(source:Models.Restaurant) : Promise<Models.MollieKeys[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_MollieKeyss_MollieKeys`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.MollieKeys[]
}

export async function link_Restaurant_Restaurant_MollieKeyss(source:Models.Restaurant, target:Models.MollieKeys) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_MollieKeyss/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Restaurant_Restaurant_MollieKeyss(source:Models.Restaurant, target:Models.MollieKeys) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_MollieKeyss/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Restaurant_Restaurant_PickupTimess(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.PickupTimes>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_PickupTimess?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PickupTimes>(json, e => { return {...e, SatPickupStart: readDBTime(e.SatPickupStart), FriPickupEnd: readDBTime(e.FriPickupEnd), TuePickupStart: readDBTime(e.TuePickupStart), ThuPickupStart: readDBTime(e.ThuPickupStart), FriPickupStart: readDBTime(e.FriPickupStart), WedPickupEnd: readDBTime(e.WedPickupEnd), MondayPickupEnd: readDBTime(e.MondayPickupEnd), WedPickupStart: readDBTime(e.WedPickupStart), SunPickupEnd: readDBTime(e.SunPickupEnd), MondayPickupStart: readDBTime(e.MondayPickupStart), SatPickupEnd: readDBTime(e.SatPickupEnd), SunPickupStart: readDBTime(e.SunPickupStart), TuePickupEnd: readDBTime(e.TuePickupEnd), ThuPickupEnd: readDBTime(e.ThuPickupEnd)}})
}







export async function get_Restaurant_Restaurant_PickupTimess_PickupTimes(source:Models.Restaurant, page_index:number, page_size:number, id:number) : Promise<Models.PickupTimes> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_PickupTimess/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), SatPickupStart: readDBTime(json.SatPickupStart), FriPickupEnd: readDBTime(json.FriPickupEnd), TuePickupStart: readDBTime(json.TuePickupStart), ThuPickupStart: readDBTime(json.ThuPickupStart), FriPickupStart: readDBTime(json.FriPickupStart), WedPickupEnd: readDBTime(json.WedPickupEnd), MondayPickupEnd: readDBTime(json.MondayPickupEnd), WedPickupStart: readDBTime(json.WedPickupStart), SunPickupEnd: readDBTime(json.SunPickupEnd), MondayPickupStart: readDBTime(json.MondayPickupStart), SatPickupEnd: readDBTime(json.SatPickupEnd), SunPickupStart: readDBTime(json.SunPickupStart), TuePickupEnd: readDBTime(json.TuePickupEnd), ThuPickupEnd: readDBTime(json.ThuPickupEnd) } as Models.PickupTimes
}

export async function get_Restaurant_Restaurant_PickupTimess_PickupTimes_by_id(source:Models.Restaurant, id:number) : Promise<Models.PickupTimes> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_PickupTimess/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), SatPickupStart: readDBTime(json.SatPickupStart), FriPickupEnd: readDBTime(json.FriPickupEnd), TuePickupStart: readDBTime(json.TuePickupStart), ThuPickupStart: readDBTime(json.ThuPickupStart), FriPickupStart: readDBTime(json.FriPickupStart), WedPickupEnd: readDBTime(json.WedPickupEnd), MondayPickupEnd: readDBTime(json.MondayPickupEnd), WedPickupStart: readDBTime(json.WedPickupStart), SunPickupEnd: readDBTime(json.SunPickupEnd), MondayPickupStart: readDBTime(json.MondayPickupStart), SatPickupEnd: readDBTime(json.SatPickupEnd), SunPickupStart: readDBTime(json.SunPickupStart), TuePickupEnd: readDBTime(json.TuePickupEnd), ThuPickupEnd: readDBTime(json.ThuPickupEnd) } as Models.PickupTimes
}


export async function get_unlinked_Restaurant_Restaurant_PickupTimess(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.PickupTimes>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/unlinked/Restaurant_PickupTimess?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PickupTimes>(json, e => { return {...e, SatPickupStart: readDBTime(e.SatPickupStart), FriPickupEnd: readDBTime(e.FriPickupEnd), TuePickupStart: readDBTime(e.TuePickupStart), ThuPickupStart: readDBTime(e.ThuPickupStart), FriPickupStart: readDBTime(e.FriPickupStart), WedPickupEnd: readDBTime(e.WedPickupEnd), MondayPickupEnd: readDBTime(e.MondayPickupEnd), WedPickupStart: readDBTime(e.WedPickupStart), SunPickupEnd: readDBTime(e.SunPickupEnd), MondayPickupStart: readDBTime(e.MondayPickupStart), SatPickupEnd: readDBTime(e.SatPickupEnd), SunPickupStart: readDBTime(e.SunPickupStart), TuePickupEnd: readDBTime(e.TuePickupEnd), ThuPickupEnd: readDBTime(e.ThuPickupEnd)}})
}

    
export async function create_linked_Restaurant_Restaurant_PickupTimess_PickupTimes(source:Models.Restaurant) : Promise<Models.PickupTimes[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_PickupTimess_PickupTimes`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate), SatPickupStart: readDBTime(e.SatPickupStart), FriPickupEnd: readDBTime(e.FriPickupEnd), TuePickupStart: readDBTime(e.TuePickupStart), ThuPickupStart: readDBTime(e.ThuPickupStart), FriPickupStart: readDBTime(e.FriPickupStart), WedPickupEnd: readDBTime(e.WedPickupEnd), MondayPickupEnd: readDBTime(e.MondayPickupEnd), WedPickupStart: readDBTime(e.WedPickupStart), SunPickupEnd: readDBTime(e.SunPickupEnd), MondayPickupStart: readDBTime(e.MondayPickupStart), SatPickupEnd: readDBTime(e.SatPickupEnd), SunPickupStart: readDBTime(e.SunPickupStart), TuePickupEnd: readDBTime(e.TuePickupEnd), ThuPickupEnd: readDBTime(e.ThuPickupEnd) }}) as Models.PickupTimes[]
}

export async function link_Restaurant_Restaurant_PickupTimess(source:Models.Restaurant, target:Models.PickupTimes) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_PickupTimess/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Restaurant_Restaurant_PickupTimess(source:Models.Restaurant, target:Models.PickupTimes) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_PickupTimess/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Restaurant_Restaurant_PickupClosingTimes(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.PickupClosingTime>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_PickupClosingTimes?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PickupClosingTime>(json, e => { return {...e, Start: readDBTime(e.Start), End: readDBTime(e.End)}})
}







export async function get_Restaurant_Restaurant_PickupClosingTimes_PickupClosingTime(source:Models.Restaurant, page_index:number, page_size:number, id:number) : Promise<Models.PickupClosingTime> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_PickupClosingTimes/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), Start: readDBTime(json.Start), End: readDBTime(json.End) } as Models.PickupClosingTime
}

export async function get_Restaurant_Restaurant_PickupClosingTimes_PickupClosingTime_by_id(source:Models.Restaurant, id:number) : Promise<Models.PickupClosingTime> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_PickupClosingTimes/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), Start: readDBTime(json.Start), End: readDBTime(json.End) } as Models.PickupClosingTime
}


export async function get_unlinked_Restaurant_Restaurant_PickupClosingTimes(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.PickupClosingTime>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/unlinked/Restaurant_PickupClosingTimes?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PickupClosingTime>(json, e => { return {...e, Start: readDBTime(e.Start), End: readDBTime(e.End)}})
}

    
export async function create_linked_Restaurant_Restaurant_PickupClosingTimes_PickupClosingTime(source:Models.Restaurant) : Promise<Models.PickupClosingTime[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_PickupClosingTimes_PickupClosingTime`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate), Start: readDBTime(e.Start), End: readDBTime(e.End) }}) as Models.PickupClosingTime[]
}

export async function link_Restaurant_Restaurant_PickupClosingTimes(source:Models.Restaurant, target:Models.PickupClosingTime) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_PickupClosingTimes/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Restaurant_Restaurant_PickupClosingTimes(source:Models.Restaurant, target:Models.PickupClosingTime) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_PickupClosingTimes/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Restaurant_Restaurant_DeliveryTimess(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.DeliveryTimes>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_DeliveryTimess?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.DeliveryTimes>(json, e => { return {...e, SatDeliveryEnd: readDBTime(e.SatDeliveryEnd), SunDeliveryStart: readDBTime(e.SunDeliveryStart), TueDeliveryEnd: readDBTime(e.TueDeliveryEnd), ThuDeliveryEnd: readDBTime(e.ThuDeliveryEnd), SatDeliveryStart: readDBTime(e.SatDeliveryStart), TueDeliveryStart: readDBTime(e.TueDeliveryStart), FriDeliveryEnd: readDBTime(e.FriDeliveryEnd), ThuDeliveryStart: readDBTime(e.ThuDeliveryStart), FriDeliveryStart: readDBTime(e.FriDeliveryStart), WedDeliveryEnd: readDBTime(e.WedDeliveryEnd), MondayDeliveryEnd: readDBTime(e.MondayDeliveryEnd), WedDeliveryStart: readDBTime(e.WedDeliveryStart), SunDeliveryEnd: readDBTime(e.SunDeliveryEnd), MondayDeliveryStart: readDBTime(e.MondayDeliveryStart)}})
}







export async function get_Restaurant_Restaurant_DeliveryTimess_DeliveryTimes(source:Models.Restaurant, page_index:number, page_size:number, id:number) : Promise<Models.DeliveryTimes> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_DeliveryTimess/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), SatDeliveryEnd: readDBTime(json.SatDeliveryEnd), SunDeliveryStart: readDBTime(json.SunDeliveryStart), TueDeliveryEnd: readDBTime(json.TueDeliveryEnd), ThuDeliveryEnd: readDBTime(json.ThuDeliveryEnd), SatDeliveryStart: readDBTime(json.SatDeliveryStart), TueDeliveryStart: readDBTime(json.TueDeliveryStart), FriDeliveryEnd: readDBTime(json.FriDeliveryEnd), ThuDeliveryStart: readDBTime(json.ThuDeliveryStart), FriDeliveryStart: readDBTime(json.FriDeliveryStart), WedDeliveryEnd: readDBTime(json.WedDeliveryEnd), MondayDeliveryEnd: readDBTime(json.MondayDeliveryEnd), WedDeliveryStart: readDBTime(json.WedDeliveryStart), SunDeliveryEnd: readDBTime(json.SunDeliveryEnd), MondayDeliveryStart: readDBTime(json.MondayDeliveryStart) } as Models.DeliveryTimes
}

export async function get_Restaurant_Restaurant_DeliveryTimess_DeliveryTimes_by_id(source:Models.Restaurant, id:number) : Promise<Models.DeliveryTimes> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_DeliveryTimess/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), SatDeliveryEnd: readDBTime(json.SatDeliveryEnd), SunDeliveryStart: readDBTime(json.SunDeliveryStart), TueDeliveryEnd: readDBTime(json.TueDeliveryEnd), ThuDeliveryEnd: readDBTime(json.ThuDeliveryEnd), SatDeliveryStart: readDBTime(json.SatDeliveryStart), TueDeliveryStart: readDBTime(json.TueDeliveryStart), FriDeliveryEnd: readDBTime(json.FriDeliveryEnd), ThuDeliveryStart: readDBTime(json.ThuDeliveryStart), FriDeliveryStart: readDBTime(json.FriDeliveryStart), WedDeliveryEnd: readDBTime(json.WedDeliveryEnd), MondayDeliveryEnd: readDBTime(json.MondayDeliveryEnd), WedDeliveryStart: readDBTime(json.WedDeliveryStart), SunDeliveryEnd: readDBTime(json.SunDeliveryEnd), MondayDeliveryStart: readDBTime(json.MondayDeliveryStart) } as Models.DeliveryTimes
}


export async function get_unlinked_Restaurant_Restaurant_DeliveryTimess(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.DeliveryTimes>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/unlinked/Restaurant_DeliveryTimess?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.DeliveryTimes>(json, e => { return {...e, SatDeliveryEnd: readDBTime(e.SatDeliveryEnd), SunDeliveryStart: readDBTime(e.SunDeliveryStart), TueDeliveryEnd: readDBTime(e.TueDeliveryEnd), ThuDeliveryEnd: readDBTime(e.ThuDeliveryEnd), SatDeliveryStart: readDBTime(e.SatDeliveryStart), TueDeliveryStart: readDBTime(e.TueDeliveryStart), FriDeliveryEnd: readDBTime(e.FriDeliveryEnd), ThuDeliveryStart: readDBTime(e.ThuDeliveryStart), FriDeliveryStart: readDBTime(e.FriDeliveryStart), WedDeliveryEnd: readDBTime(e.WedDeliveryEnd), MondayDeliveryEnd: readDBTime(e.MondayDeliveryEnd), WedDeliveryStart: readDBTime(e.WedDeliveryStart), SunDeliveryEnd: readDBTime(e.SunDeliveryEnd), MondayDeliveryStart: readDBTime(e.MondayDeliveryStart)}})
}

    
export async function create_linked_Restaurant_Restaurant_DeliveryTimess_DeliveryTimes(source:Models.Restaurant) : Promise<Models.DeliveryTimes[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_DeliveryTimess_DeliveryTimes`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate), SatDeliveryEnd: readDBTime(e.SatDeliveryEnd), SunDeliveryStart: readDBTime(e.SunDeliveryStart), TueDeliveryEnd: readDBTime(e.TueDeliveryEnd), ThuDeliveryEnd: readDBTime(e.ThuDeliveryEnd), SatDeliveryStart: readDBTime(e.SatDeliveryStart), TueDeliveryStart: readDBTime(e.TueDeliveryStart), FriDeliveryEnd: readDBTime(e.FriDeliveryEnd), ThuDeliveryStart: readDBTime(e.ThuDeliveryStart), FriDeliveryStart: readDBTime(e.FriDeliveryStart), WedDeliveryEnd: readDBTime(e.WedDeliveryEnd), MondayDeliveryEnd: readDBTime(e.MondayDeliveryEnd), WedDeliveryStart: readDBTime(e.WedDeliveryStart), SunDeliveryEnd: readDBTime(e.SunDeliveryEnd), MondayDeliveryStart: readDBTime(e.MondayDeliveryStart) }}) as Models.DeliveryTimes[]
}

export async function link_Restaurant_Restaurant_DeliveryTimess(source:Models.Restaurant, target:Models.DeliveryTimes) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_DeliveryTimess/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Restaurant_Restaurant_DeliveryTimess(source:Models.Restaurant, target:Models.DeliveryTimes) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_DeliveryTimess/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Restaurant_Restaurant_DeliveryClosingTimes(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.DeliveryClosingTime>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_DeliveryClosingTimes?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.DeliveryClosingTime>(json, e => { return {...e, Start: readDBTime(e.Start), End: readDBTime(e.End)}})
}







export async function get_Restaurant_Restaurant_DeliveryClosingTimes_DeliveryClosingTime(source:Models.Restaurant, page_index:number, page_size:number, id:number) : Promise<Models.DeliveryClosingTime> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_DeliveryClosingTimes/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), Start: readDBTime(json.Start), End: readDBTime(json.End) } as Models.DeliveryClosingTime
}

export async function get_Restaurant_Restaurant_DeliveryClosingTimes_DeliveryClosingTime_by_id(source:Models.Restaurant, id:number) : Promise<Models.DeliveryClosingTime> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_DeliveryClosingTimes/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), Start: readDBTime(json.Start), End: readDBTime(json.End) } as Models.DeliveryClosingTime
}


export async function get_unlinked_Restaurant_Restaurant_DeliveryClosingTimes(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.DeliveryClosingTime>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/unlinked/Restaurant_DeliveryClosingTimes?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.DeliveryClosingTime>(json, e => { return {...e, Start: readDBTime(e.Start), End: readDBTime(e.End)}})
}

    
export async function create_linked_Restaurant_Restaurant_DeliveryClosingTimes_DeliveryClosingTime(source:Models.Restaurant) : Promise<Models.DeliveryClosingTime[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_DeliveryClosingTimes_DeliveryClosingTime`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate), Start: readDBTime(e.Start), End: readDBTime(e.End) }}) as Models.DeliveryClosingTime[]
}

export async function link_Restaurant_Restaurant_DeliveryClosingTimes(source:Models.Restaurant, target:Models.DeliveryClosingTime) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_DeliveryClosingTimes/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Restaurant_Restaurant_DeliveryClosingTimes(source:Models.Restaurant, target:Models.DeliveryClosingTime) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_DeliveryClosingTimes/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Restaurant_Restaurant_PostCodeRanges(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.PostCodeRange>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_PostCodeRanges?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PostCodeRange>(json, e => { return {...e, }})
}







export async function get_Restaurant_Restaurant_PostCodeRanges_PostCodeRange(source:Models.Restaurant, page_index:number, page_size:number, id:number) : Promise<Models.PostCodeRange> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_PostCodeRanges/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.PostCodeRange
}

export async function get_Restaurant_Restaurant_PostCodeRanges_PostCodeRange_by_id(source:Models.Restaurant, id:number) : Promise<Models.PostCodeRange> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_PostCodeRanges/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.PostCodeRange
}


export async function get_unlinked_Restaurant_Restaurant_PostCodeRanges(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.PostCodeRange>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/unlinked/Restaurant_PostCodeRanges?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PostCodeRange>(json, e => { return {...e, }})
}

    
export async function create_linked_Restaurant_Restaurant_PostCodeRanges_PostCodeRange(source:Models.Restaurant) : Promise<Models.PostCodeRange[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_PostCodeRanges_PostCodeRange`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.PostCodeRange[]
}

export async function link_Restaurant_Restaurant_PostCodeRanges(source:Models.Restaurant, target:Models.PostCodeRange) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_PostCodeRanges/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Restaurant_Restaurant_PostCodeRanges(source:Models.Restaurant, target:Models.PostCodeRange) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_PostCodeRanges/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Restaurant_Category_Restaurants(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.Category>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Categories?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Category>(json, e => { return {...e, }})
}







export async function get_Restaurant_Category_Restaurants_Category(source:Models.Restaurant, page_index:number, page_size:number, id:number) : Promise<Models.Category> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Categories/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Category
}

export async function get_Restaurant_Category_Restaurants_Category_by_id(source:Models.Restaurant, id:number) : Promise<Models.Category> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Categories/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Category
}


export async function get_unlinked_Restaurant_Category_Restaurants(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.Category>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/unlinked/Restaurant_Categories?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Category>(json, e => { return {...e, }})
}

    
export async function create_linked_Restaurant_Category_Restaurants_Category(source:Models.Restaurant) : Promise<Models.Category[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Categories_Category`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Category[]
}

export async function link_Restaurant_Category_Restaurants(source:Models.Restaurant, target:Models.Category) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Categories/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Restaurant_Category_Restaurants(source:Models.Restaurant, target:Models.Category) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Categories/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Restaurant_Product_Restaurants(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Products?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}


export async function search_Restaurant_Product_Restaurants(source:Models.Restaurant, page_index:number, page_size:number, search_value: SearchQueryItem) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let body;
  if(search_value.searchQueryString && search_value.searchQueryString.length > 0)
    body = {
    "relationship": {
          "firstTable": "Product",
          "joins": [
            {
              "item1": {
                "table": "Product_Restaurant",
                "source": "Product",
                "target": "Restaurant"
              },
              "item2": "Restaurant"
            }
          ]
      }
      , "expression" :  {
  "kind": "And",
  "left": {
  "kind": "Or",
  "left": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Product.Name"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
},
  "right": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Product.InternalName"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
}
},
  "right": {
    "kind": "Eq",
    "left": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Restaurant.Id"
      }
    },
    "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "Integer",
        "value": source.Id
      }
    }
  }
}
      , "aggregations": []
  }
  else
    body = {
    "relationship": {
          "firstTable": "Product",
          "joins": [
            {
              "item1": {
                "table": "Product_Restaurant",
                "source": "Product",
                "target": "Restaurant"
              },
              "item2": "Restaurant"
            }
          ]
      }
      , "expression" :  {
    "kind": "Eq",
    "left": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Restaurant.Id"
      }
    },
    "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "Integer",
        "value": source.Id
      }
    }
  }
      , "aggregations": []
  }

  let res = await fetch(`/api/search?page_index=${page_index}&page_size=${page_size}`, { method: 'post', credentials: 'include', headers: headers, body: JSON.stringify(body) })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}






export async function get_Restaurant_Product_Restaurants_Product(source:Models.Restaurant, page_index:number, page_size:number, id:number) : Promise<Models.Product> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Products/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Product
}

export async function get_Restaurant_Product_Restaurants_Product_by_id(source:Models.Restaurant, id:number) : Promise<Models.Product> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Products/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Product
}


export async function get_unlinked_Restaurant_Product_Restaurants(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.Product>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/unlinked/Restaurant_Products?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Product>(json, e => { return {...e, }})
}

    
export async function create_linked_Restaurant_Product_Restaurants_Product(source:Models.Restaurant) : Promise<Models.Product[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Products_Product`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Product[]
}

export async function link_Restaurant_Product_Restaurants(source:Models.Restaurant, target:Models.Product) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Products/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Restaurant_Product_Restaurants(source:Models.Restaurant, target:Models.Product) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Products/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Restaurant_Option_Restaurants(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.Option>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Options?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Option>(json, e => { return {...e, }})
}


export async function search_Restaurant_Option_Restaurants(source:Models.Restaurant, page_index:number, page_size:number, search_value: SearchQueryItem) : Promise<RawPage<Models.Option>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let body;
  if(search_value.searchQueryString && search_value.searchQueryString.length > 0)
    body = {
    "relationship": {
          "firstTable": "Option",
          "joins": [
            {
              "item1": {
                "table": "Option_Restaurant",
                "source": "Option",
                "target": "Restaurant"
              },
              "item2": "Restaurant"
            }
          ]
      }
      , "expression" :  {
  "kind": "And",
  "left": {
  "kind": "Or",
  "left": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Option.Name"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
},
  "right": {
  "kind": "Includes",
  "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Option.InternalName"
      }
  },
  "value": {
    "kind": "Atomic",
    "atomic": {
      "kind": "String",
      "value": `${search_value.searchQueryString.trim()}`
    }
  }
}
},
  "right": {
    "kind": "Eq",
    "left": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Restaurant.Id"
      }
    },
    "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "Integer",
        "value": source.Id
      }
    }
  }
}
      , "aggregations": []
  }
  else
    body = {
    "relationship": {
          "firstTable": "Option",
          "joins": [
            {
              "item1": {
                "table": "Option_Restaurant",
                "source": "Option",
                "target": "Restaurant"
              },
              "item2": "Restaurant"
            }
          ]
      }
      , "expression" :  {
    "kind": "Eq",
    "left": {
      "kind": "Atomic",
      "atomic": {
        "kind": "DottedPath",
        "value": "Restaurant.Id"
      }
    },
    "right": {
      "kind": "Atomic",
      "atomic": {
        "kind": "Integer",
        "value": source.Id
      }
    }
  }
      , "aggregations": []
  }

  let res = await fetch(`/api/search?page_index=${page_index}&page_size=${page_size}`, { method: 'post', credentials: 'include', headers: headers, body: JSON.stringify(body) })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Option>(json, e => { return {...e, }})
}






export async function get_Restaurant_Option_Restaurants_Option(source:Models.Restaurant, page_index:number, page_size:number, id:number) : Promise<Models.Option> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Options/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Option
}

export async function get_Restaurant_Option_Restaurants_Option_by_id(source:Models.Restaurant, id:number) : Promise<Models.Option> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Options/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Option
}


export async function get_unlinked_Restaurant_Option_Restaurants(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.Option>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/unlinked/Restaurant_Options?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Option>(json, e => { return {...e, }})
}

    
export async function create_linked_Restaurant_Option_Restaurants_Option(source:Models.Restaurant) : Promise<Models.Option[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Options_Option`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Option[]
}

export async function link_Restaurant_Option_Restaurants(source:Models.Restaurant, target:Models.Option) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Options/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Restaurant_Option_Restaurants(source:Models.Restaurant, target:Models.Option) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Options/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_PickUpRestaurant_PickUpRestaurant_PickUpPayMethods(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.PayMethod>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/PickUpRestaurant_PickUpPayMethods?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PayMethod>(json, e => { return {...e, }})
}







export async function get_PickUpRestaurant_PickUpRestaurant_PickUpPayMethods_PayMethod(source:Models.Restaurant, page_index:number, page_size:number, id:number) : Promise<Models.PayMethod> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/PickUpRestaurant_PickUpPayMethods/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.PayMethod
}

export async function get_PickUpRestaurant_PickUpRestaurant_PickUpPayMethods_PayMethod_by_id(source:Models.Restaurant, id:number) : Promise<Models.PayMethod> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/PickUpRestaurant_PickUpPayMethods/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.PayMethod
}


export async function get_unlinked_PickUpRestaurant_PickUpRestaurant_PickUpPayMethods(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.PayMethod>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/unlinked/PickUpRestaurant_PickUpPayMethods?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PayMethod>(json, e => { return {...e, }})
}

    
export async function create_linked_PickUpRestaurant_PickUpRestaurant_PickUpPayMethods_PayMethod(source:Models.Restaurant) : Promise<Models.PayMethod[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/PickUpRestaurant_PickUpPayMethods_PayMethod`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.PayMethod[]
}

export async function link_PickUpRestaurant_PickUpRestaurant_PickUpPayMethods(source:Models.Restaurant, target:Models.PayMethod) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/PickUpRestaurant_PickUpPayMethods/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_PickUpRestaurant_PickUpRestaurant_PickUpPayMethods(source:Models.Restaurant, target:Models.PayMethod) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/PickUpRestaurant_PickUpPayMethods/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_DeliveryRestaurant_DeliveryRestaurant_DeliveryPayMethods(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.PayMethod>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/DeliveryRestaurant_DeliveryPayMethods?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PayMethod>(json, e => { return {...e, }})
}







export async function get_DeliveryRestaurant_DeliveryRestaurant_DeliveryPayMethods_PayMethod(source:Models.Restaurant, page_index:number, page_size:number, id:number) : Promise<Models.PayMethod> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/DeliveryRestaurant_DeliveryPayMethods/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.PayMethod
}

export async function get_DeliveryRestaurant_DeliveryRestaurant_DeliveryPayMethods_PayMethod_by_id(source:Models.Restaurant, id:number) : Promise<Models.PayMethod> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/DeliveryRestaurant_DeliveryPayMethods/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.PayMethod
}


export async function get_unlinked_DeliveryRestaurant_DeliveryRestaurant_DeliveryPayMethods(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.PayMethod>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/unlinked/DeliveryRestaurant_DeliveryPayMethods?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.PayMethod>(json, e => { return {...e, }})
}

    
export async function create_linked_DeliveryRestaurant_DeliveryRestaurant_DeliveryPayMethods_PayMethod(source:Models.Restaurant) : Promise<Models.PayMethod[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/DeliveryRestaurant_DeliveryPayMethods_PayMethod`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.PayMethod[]
}

export async function link_DeliveryRestaurant_DeliveryRestaurant_DeliveryPayMethods(source:Models.Restaurant, target:Models.PayMethod) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/DeliveryRestaurant_DeliveryPayMethods/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_DeliveryRestaurant_DeliveryRestaurant_DeliveryPayMethods(source:Models.Restaurant, target:Models.PayMethod) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/DeliveryRestaurant_DeliveryPayMethods/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Restaurant_Order_Restaurants(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.Order>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Orders?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Order>(json, e => { return {...e, DeliveryOrPickupTime: readDBTime(e.DeliveryOrPickupTime)}})
}







export async function get_Restaurant_Order_Restaurants_Order(source:Models.Restaurant, page_index:number, page_size:number, id:number) : Promise<Models.Order> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Orders/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), DeliveryOrPickupTime: readDBTime(json.DeliveryOrPickupTime) } as Models.Order
}

export async function get_Restaurant_Order_Restaurants_Order_by_id(source:Models.Restaurant, id:number) : Promise<Models.Order> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Orders/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), DeliveryOrPickupTime: readDBTime(json.DeliveryOrPickupTime) } as Models.Order
}


export async function get_unlinked_Restaurant_Order_Restaurants(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.Order>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/unlinked/Restaurant_Orders?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Order>(json, e => { return {...e, DeliveryOrPickupTime: readDBTime(e.DeliveryOrPickupTime)}})
}

    
export async function create_linked_Restaurant_Order_Restaurants_Order(source:Models.Restaurant) : Promise<Models.Order[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Orders_Order`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate), DeliveryOrPickupTime: readDBTime(e.DeliveryOrPickupTime) }}) as Models.Order[]
}

export async function link_Restaurant_Order_Restaurants(source:Models.Restaurant, target:Models.Order) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Orders/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Restaurant_Order_Restaurants(source:Models.Restaurant, target:Models.Order) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Orders/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Restaurant_Restaurant_Discounts(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.Discount>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Discounts?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Discount>(json, e => { return {...e, EndDate: readDBTime(e.EndDate), StartDate: readDBTime(e.StartDate)}})
}







export async function get_Restaurant_Restaurant_Discounts_Discount(source:Models.Restaurant, page_index:number, page_size:number, id:number) : Promise<Models.Discount> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Discounts/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), EndDate: readDBTime(json.EndDate), StartDate: readDBTime(json.StartDate) } as Models.Discount
}

export async function get_Restaurant_Restaurant_Discounts_Discount_by_id(source:Models.Restaurant, id:number) : Promise<Models.Discount> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Discounts/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), EndDate: readDBTime(json.EndDate), StartDate: readDBTime(json.StartDate) } as Models.Discount
}


export async function get_unlinked_Restaurant_Restaurant_Discounts(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.Discount>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/unlinked/Restaurant_Discounts?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Discount>(json, e => { return {...e, EndDate: readDBTime(e.EndDate), StartDate: readDBTime(e.StartDate)}})
}

    
export async function create_linked_Restaurant_Restaurant_Discounts_Discount(source:Models.Restaurant) : Promise<Models.Discount[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Discounts_Discount`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate), EndDate: readDBTime(e.EndDate), StartDate: readDBTime(e.StartDate) }}) as Models.Discount[]
}

export async function link_Restaurant_Restaurant_Discounts(source:Models.Restaurant, target:Models.Discount) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Discounts/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Restaurant_Restaurant_Discounts(source:Models.Restaurant, target:Models.Discount) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Discounts/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Restaurant_Restaurant_POS4S(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.POS4>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_POS4S?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.POS4>(json, e => { return {...e, TokenExpiration: readDBTime(e.TokenExpiration)}})
}







export async function get_Restaurant_Restaurant_POS4S_POS4(source:Models.Restaurant, page_index:number, page_size:number, id:number) : Promise<Models.POS4> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_POS4S/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), TokenExpiration: readDBTime(json.TokenExpiration) } as Models.POS4
}

export async function get_Restaurant_Restaurant_POS4S_POS4_by_id(source:Models.Restaurant, id:number) : Promise<Models.POS4> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_POS4S/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate), TokenExpiration: readDBTime(json.TokenExpiration) } as Models.POS4
}


export async function get_unlinked_Restaurant_Restaurant_POS4S(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.POS4>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/unlinked/Restaurant_POS4S?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.POS4>(json, e => { return {...e, TokenExpiration: readDBTime(e.TokenExpiration)}})
}

    
export async function create_linked_Restaurant_Restaurant_POS4S_POS4(source:Models.Restaurant) : Promise<Models.POS4[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_POS4S_POS4`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate), TokenExpiration: readDBTime(e.TokenExpiration) }}) as Models.POS4[]
}

export async function link_Restaurant_Restaurant_POS4S(source:Models.Restaurant, target:Models.POS4) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_POS4S/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Restaurant_Restaurant_POS4S(source:Models.Restaurant, target:Models.POS4) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_POS4S/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Restaurant_Restaurant_Companies(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.Company>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Companies?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Company>(json, e => { return {...e, }})
}







export async function get_Restaurant_Restaurant_Companies_Company(source:Models.Restaurant, page_index:number, page_size:number, id:number) : Promise<Models.Company> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Companies/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Company
}

export async function get_Restaurant_Restaurant_Companies_Company_by_id(source:Models.Restaurant, id:number) : Promise<Models.Company> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Companies/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Company
}


export async function get_unlinked_Restaurant_Restaurant_Companies(source:Models.Restaurant, page_index:number, page_size:number) : Promise<RawPage<Models.Company>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/unlinked/Restaurant_Companies?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Company>(json, e => { return {...e, }})
}

    
export async function create_linked_Restaurant_Restaurant_Companies_Company(source:Models.Restaurant) : Promise<Models.Company[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Companies_Company`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Company[]
}

export async function link_Restaurant_Restaurant_Companies(source:Models.Restaurant, target:Models.Company) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Companies/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Restaurant_Restaurant_Companies(source:Models.Restaurant, target:Models.Company) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${source.Id}/Restaurant_Companies/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_Restaurant() : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Restaurant/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function update_Restaurant(item:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Restaurant/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_Restaurant_with_pictures(item:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Restaurant/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_Restaurant(source:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/Restaurant/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_Restaurant(id:number) : Promise<ItemWithEditable<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.Restaurant,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Restaurant_with_pictures(id:number) : Promise<ItemWithEditable<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.Restaurant,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Restaurants(page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Restaurant?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}










  
  
export async function get_Category_Application_Categories(source:Models.Category, page_index:number, page_size:number) : Promise<RawPage<Models.Application>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${source.Id}/Category_Applications?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Application>(json, e => { return {...e, }})
}







export async function get_Category_Application_Categories_Application(source:Models.Category, page_index:number, page_size:number, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${source.Id}/Category_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}

export async function get_Category_Application_Categories_Application_by_id(source:Models.Category, id:number) : Promise<Models.Application> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${source.Id}/Category_Applications/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Application
}




export async function get_Category_Category_ProductPositions(source:Models.Category, page_index:number, page_size:number) : Promise<RawPage<Models.ProductPosition>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${source.Id}/Category_ProductPositions?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.ProductPosition>(json, e => { return {...e, }})
}







export async function get_Category_Category_ProductPositions_ProductPosition(source:Models.Category, page_index:number, page_size:number, id:number) : Promise<Models.ProductPosition> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${source.Id}/Category_ProductPositions/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.ProductPosition
}

export async function get_Category_Category_ProductPositions_ProductPosition_by_id(source:Models.Category, id:number) : Promise<Models.ProductPosition> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${source.Id}/Category_ProductPositions/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.ProductPosition
}


export async function get_unlinked_Category_Category_ProductPositions(source:Models.Category, page_index:number, page_size:number) : Promise<RawPage<Models.ProductPosition>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${source.Id}/unlinked/Category_ProductPositions?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.ProductPosition>(json, e => { return {...e, }})
}

    
export async function create_linked_Category_Category_ProductPositions_ProductPosition(source:Models.Category) : Promise<Models.ProductPosition[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${source.Id}/Category_ProductPositions_ProductPosition`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.ProductPosition[]
}

export async function link_Category_Category_ProductPositions(source:Models.Category, target:Models.ProductPosition) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${source.Id}/Category_ProductPositions/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Category_Category_ProductPositions(source:Models.Category, target:Models.ProductPosition) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${source.Id}/Category_ProductPositions/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function get_Category_Category_Restaurants(source:Models.Category, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${source.Id}/Category_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}







export async function get_Category_Category_Restaurants_Restaurant(source:Models.Category, page_index:number, page_size:number, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${source.Id}/Category_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}

export async function get_Category_Category_Restaurants_Restaurant_by_id(source:Models.Category, id:number) : Promise<Models.Restaurant> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${source.Id}/Category_Restaurants/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Restaurant
}


export async function get_unlinked_Category_Category_Restaurants(source:Models.Category, page_index:number, page_size:number) : Promise<RawPage<Models.Restaurant>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${source.Id}/unlinked/Category_Restaurants?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Restaurant>(json, e => { return {...e, }})
}

    
export async function create_linked_Category_Category_Restaurants_Restaurant(source:Models.Category) : Promise<Models.Restaurant[]> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${source.Id}/Category_Restaurants_Restaurant`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return json.map(e => { return {...e, CreatedDate: readDBTime(e.CreatedDate),  }}) as Models.Restaurant[]
}

export async function link_Category_Category_Restaurants(source:Models.Category, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${source.Id}/Category_Restaurants/${target.Id}`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function unlink_Category_Category_Restaurants(source:Models.Category, target:Models.Restaurant) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${source.Id}/Category_Restaurants/${target.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}


export async function create_Category() : Promise<Models.Category> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Category/`, { method: 'post', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return {...json, CreatedDate: readDBTime(json.CreatedDate),  } as Models.Category
}

export async function update_Category(item:Models.Category) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Category/`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined, }), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function update_Category_with_pictures(item:Models.Category) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

  let res = await fetch(`/api/v1/Category/WithPictures`, { method: 'put',
      body: JSON.stringify({...item, CreatedDate:undefined}), credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function delete_Category(source:Models.Category) : Promise<void> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
  let res = await fetch(`/api/v1/Category/${source.Id}`, { method: 'delete', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  return
}

export async function get_Category(id:number) : Promise<ItemWithEditable<Models.Category>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${id}`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.Category,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Category_with_pictures(id:number) : Promise<ItemWithEditable<Models.Category>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category/${id}/WithPictures`, { method: 'get', credentials: 'include', headers: headers })
  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return { Item: {...json.Item, CreatedDate: readDBTime(json.Item.CreatedDate),  } as Models.Category,
           Editable: !!json.Editable, JustCreated:false }
}

export async function get_Categories(page_index:number, page_size:number) : Promise<RawPage<Models.Category>> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  let res = await fetch(`/api/v1/Category?page_index=${page_index}&page_size=${page_size}`, { method: 'get', credentials: 'include', headers: headers })

  if (!res.ok) throw Error(res.statusText)
  let json = await res.json()
  return make_page<Models.Category>(json, e => { return {...e, }})
}










  
  
export async function login_promise(ld: LoginData<string>): Promise<ApiResultWithMessage<any>> {
    try {
        let headers = new Headers()
        headers.append('content-type', 'application/json')
        headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

        let res = await fetch('/api/v1/' + ld.role + '/Login',
            {
                method: 'post', credentials: 'include',
                body: JSON.stringify({ Email: ld.email, Password: ld.password }),
                headers: headers
            })

        if (!res.ok && res.status != 401 && res.status != 403) {
          throw (res.statusText)
        } else if (!res.ok) {
          let json = await res.json() as {message: string}
          throw (json.message !== undefined ? json.message : "login_failed")
        }

        let json = await res.json() as ApiResultWithMessage<any>

        return json.status == "failure" ? {status: "failure", message: json.message} : {status: "success", user: json.user}

    } catch (error) {
        return {status: "failure", message: error.toString()}
    }
}

export async function logout_promise(ld: LoginData<string>) : Promise<void> {
    let headers = new Headers()
    headers.append('content-type', 'application/json')
    headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

    let res = await fetch('/api/v1/' + ld.role + '/Logout',
        {
            method: 'post', credentials: 'include',
            headers: headers
        })

    if (!res.ok) throw Error(res.statusText)
    return
  }

export async function forgot_password_promise(ld: LoginData<string>): Promise<ApiResult> {
    try {
        let headers = new Headers()
        headers.append('content-type', 'application/json')
        headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

        let res = await fetch('/api/v1/' + ld.role + '/ForgotPassword',
            {
                method: 'post', credentials: 'include',
                body: JSON.stringify({ Email: ld.email }),
                headers: headers
            })

        if (!res.ok) throw Error(res.statusText)
        return "success"
    } catch (error) {
        return "failure"
    }
}

export async function reset_password_promise(rd: ResetData<string>): Promise<ApiResult> {
    try {
        let headers = new Headers()
        headers.append('content-type', 'application/json')
        headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)

        let res = await fetch('/api/v1/' + rd.role + '/ResetPassword',
        {
          method: 'post', credentials: 'include',
          body: JSON.stringify({ Email: rd.email, Password: rd.new_password, ConfirmPassword: rd.new_password_confirm, Token: rd.token }),
          headers: headers
        })
      if (!res.ok) throw Error(res.statusText)
        return "success"
    } catch (error) {
      return "failure"
    }
  }

export async function register_promise(rd: RegisterData<string>): Promise<ApiResultWithMessage<any>> {
    try {
      let headers = new Headers()
      headers.append('content-type', 'application/json')
      headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
      
      let res = await fetch('/api/v1/' + rd.role + '/Register',
        { method: 'post', credentials: 'include',
          body: JSON.stringify({Username:rd.username, Email:rd.email, EmailConfirmation:rd.emailConfirmation, Password: rd.password, PasswordConfirmation: rd.passwordConfirmation}),
          headers: headers
        })
      if (!res.ok) throw Error(res.statusText)

      let json = await res.json()
      return {status: "success", user: json}

    } catch (error) {
      return {status: "failure", message: "register_failed"}
    }
}

export async function invite_promise(rd: InviteData<string>): Promise<ApiResultWithMessage<any>> {
    try {
      let headers = new Headers()
      headers.append('content-type', 'application/json')
      headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
      
      let res = await fetch('/api/v1/' + rd.role + '/Invite',
        { method: 'post', credentials: 'include',
          body: JSON.stringify({Username:rd.username, Firstname: rd.firstname, Lastname: rd.lastname, Email:rd.email, EmailConfirmation:rd.emailConfirmation}),
          headers: headers
        })
      if (!res.ok) throw Error(res.statusText)

      let json = await res.json()
      return {status: "success", user: json}

    } catch (error) {
      console.log(error)
      return {status: "failure", message: "invite_failed"}
    }
}

export async function change_password_promise(cd: ChangeData) : Promise<ApiResult> {
    try {
      let headers = new Headers()
      headers.append('content-type', 'application/json')
      headers.append('X-XSRF-TOKEN', (document.getElementsByName("__RequestVerificationToken")[0] as any).value)
      
      let res = await fetch('/api/v1/POS4/ChangePassword',
        { method: 'post', credentials: 'include',
          body: JSON.stringify({Password:cd.password, NewPassword:cd.newPassword, NewPasswordConfirmation:cd.newPasswordConfirmation}),
          headers: headers })
      if (!res.ok) throw Error(res.statusText)
        return "success"
    } catch (error) {
      return "failure"
    }
}

export function login(loginData: LoginData<string>) {
    return lift_promise<LoginData<string>, ApiResultWithMessage<any>>(ld => login_promise(ld), "semi exponential")(loginData);
}

export function logout(loginData: LoginData<string>) {
    return lift_promise<LoginData<string>, void>(ld => logout_promise(ld), "semi exponential")(loginData);
}

export function forgot_password(loginData: LoginData<string>) {
    return lift_promise<LoginData<string>, ApiResult>(ld => forgot_password_promise(ld), "semi exponential")(loginData);
}

export function reset_password(resetData: ResetData<string>) {
    return lift_promise<ResetData<string>, ApiResult>(rd => reset_password_promise(rd), "semi exponential")(resetData);
}

export function register(registerData: RegisterData<string>) {
    return lift_promise<RegisterData<string>, ApiResultWithMessage<any>>(rd => register_promise(rd), "semi exponential")(registerData);
}

export function invite(inviteData: InviteData<string>) {
    return lift_promise<InviteData<string>, ApiResultWithMessage<any>>(rd => invite_promise(rd), "semi exponential")(inviteData);
}

export function change_password(changeData: ChangeData) {
    return lift_promise<ChangeData, ApiResult>(cd => change_password_promise(cd), "semi exponential")(changeData);
}
  

  