import * as React from "react"
import { WithContext, Thing, Restaurant } from "schema-dts"
import { mkWidget } from "widgets-for-react";
import { ParsedNodeRestaurant } from "../drupal/drupal_generated/types";
import { parseImageUrl } from "../drupal/drupal_utils/utils";

const createSchemaOrg = <T extends Thing>(schema: T) => mkWidget({
  run: () => {
    const contextSchema: WithContext<T> = ({
      "@context": "https://schema.org",
      ...schema as any
    })

    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{__html:JSON.stringify(contextSchema)}}
      />
    )
  }
})

export const restaurantSchemaOrg = (node: ParsedNodeRestaurant) => (
  createSchemaOrg<Restaurant>({
    "@type": "Restaurant",
    priceRange: "€€",
    name: "De Beren " + node.label,
    servesCuisine: "Amerikaans",
    image: location.origin + (node.field_introduction_image ? parseImageUrl(node.field_introduction_image.field_media_image.image.style_urls.header_1440x500) : "/images/frontend/logo-kodiak.jpg"),
    "address": {
      "@type": "PostalAddress",
      "url": location.origin + location.pathname,
      telephone: node.field_telephone_number,
      addressCountry: "NL",
      streetAddress: node.field_street_and_house_number,
      addressLocality: node.field_city,
      addressRegion: node.field_province,
      postalCode: node.field_postal_code,
    }
  })
)