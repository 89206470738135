import * as React from "react"
import { Action, IOWidget, mk_widget } from "widgets-for-react"
import { CMSState } from "./state"
import { ImageSize } from "./types"
import { CookieSensitiveContentWrapper } from "../public_site/cookiebar";
import { ExtraImages } from "../public_site_utils/extra_images"
import { ImageContainerExtended, ImageSrcsetProps } from "./ImageCampaignContainer"

export type MediaImageProps = {
  id: number
  size: ImageSize<ExtraImages>
  srcsetSize: ImageSrcsetProps<ExtraImages>[]
  alt: string
  width: string
  height: string
  lazyload: boolean
}

export type MediaCampaignBlockProps = {
  kind_media: "image" | "video"
  image?: MediaImageProps
  video?: { url: string } & {
    id: string
    type: string
    width: string
    height: string
    frameBorder: string
    allowFullScreen: boolean
  }
  caption?: string
  text_options?: {
    size: string,
    font: string
  }
  cookieHandler?:{
    acceptAllCookies: () => void
    getCookie: (name: string) => string
    videoCookie: "accepts_video"
  },
  width: boolean
}
export type MediaCampaignBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
  content_classname: string
  figure_classname: string
  media_classname: string
  image_classname: string
  placeholder_classname: string
  video_classname: string
  figcaption_classname: string
  caption_classname: string
  cookies_classname: string
  cookies_inner_classname: string
  cookies_text_classname: string
  cookies_clickable_classname: string
}>

const getClassName = (
  options: { style?: MediaCampaignBlockStyle },
  className: keyof MediaCampaignBlockStyle,
  notset: string = ""
): string =>
  options && options.style && (options.style[className] || options.style[className] == null) ? options.style[className] : notset

export function MediaCampaignBlock<CustomBlockData>(
  props: MediaCampaignBlockProps,
  options?: { style?: MediaCampaignBlockStyle; key?: string }
): IOWidget<CMSState<CustomBlockData, ExtraImages>, Action<CMSState<CustomBlockData, ExtraImages>>> {
  return (cmsState: CMSState<CustomBlockData, ExtraImages>) =>
    mk_widget({
      run: _ => {
        return (
          <section className={getClassName(options, "block_classname", "container")} key={options && options.key ? options.key : "media-block"}>
            <div className={getClassName(options, "inner_classname", "py-5")}>
              <div className={getClassName(options, "content_classname", "")}>
                <figure className={getClassName(options, "figure_classname", "")}>
                  {props.kind_media === "image" && props.image
                    ? cmsState.images.has(props.image.id)
                      ? <div className={getClassName(options, "media_classname", "text-center")}>
                          {ImageContainerExtended({
                            url: cmsState.images.get(props.image.id),
                            srcSize: props.image.size,
                            srcsetSize: props.image.srcsetSize,
                            extraClasses: getClassName(options, "image_classname", "w-100"),
                            alt: props.image.alt,
                            width: props.image.width,
                            height: props.image.height,
                            lazyload: props.image.lazyload
                          }).run(_ => {})}
                          
                          {props.caption && (
                            <figcaption className={getClassName(options, "figcaption_classname", "")}>
                              <div dangerouslySetInnerHTML={{ __html: props.caption }} />
                            </figcaption>
                          )}
                        </div>
                      : null
                    : <CookieSensitiveContentWrapper
                        placeholder={(
                          <div className={getClassName(options, "cookies_classname", "text-center bg-light")}>
                            <div className={getClassName(options, "cookies_inner_classname", "py-5")}>
                              <p className="cookiebar-placeholder__text text--styled">Deze video kun je alleen bekijken als je de cookies accepteert. <a href="/cookies">Meer informatie over cookies</a>.</p>
                            </div>
                          </div>
                        )}>
                      <div className={getClassName(options, "video_classname", "text-center")}>
                        <iframe
                          className="embed-responsive-item"
                          width={props.video.width}
                          height={props.video.height}
                          frameBorder={props.video.frameBorder}
                          allowFullScreen={props.video.allowFullScreen}
                          src={props.video.url}
                        />
                      </div>
                      {props.caption && (
                        <figcaption className={getClassName(options, "figcaption_classname", "")}>
                          <div
                            className={getClassName(options, "caption_classname", "small text-secondary text-center mt-2")}
                            dangerouslySetInnerHTML={{ __html: props.caption }}
                          />
                        </figcaption>
                      )}
                    </CookieSensitiveContentWrapper>
                  }
                </figure>
              </div>
            </div>
          </section>
        )
      }
    })
}
