import {
  LanguageLinks,
  ParsedLanguageLinks,
  Language,
  EntityOperation,
  ParsedEntityOperation,
  EntityOperations,
  ParsedEntityOperations,
  IntegerItem,
  FieldItemList,
  UuidItem,
  LanguageItem,
  EntityReferenceNodeTypeItem,
  ParsedEntityReferenceNodeTypeItem,
  TimestampItem,
  EntityReferenceUserItem,
  ParsedEntityReferenceUserItem,
  StringItem,
  BooleanItem,
  MetatagField,
  Metatags,
  PathItem,
  NodeBase,
  ParsedNodeBase,
  DatetimeItem,
  EntityReferenceParagraphsTypeItem,
  ParsedEntityReferenceParagraphsTypeItem,
  ParagraphBase,
  ParsedParagraphBase,
  TextItem,
  EntityReferenceRevisionsParagraphWithTargetParagraphBannerFieldFontsFontSizeTextItem,
  ParsedParagraphFontsFontSize,
  ParagraphFontsFontSize,
  EntityReferenceRevisionsParagraphWithTargetParagraphBannerFieldFontsFontSizeTitleItem,
  LinkItem,
  ParsedLinkItem,
  EntityReferenceMediaTypeItem,
  ParsedEntityReferenceMediaTypeItem,
  FileUriItem,
  FileBase,
  ParsedFileBase,
  Styles,
  ParsedStyles,
  FileImage,
  ParsedFileImage,
  File,
  FileOther,
  ParsedFileOther,
  ParsedFile,
  Entity,
  ImageFileWithTargetItem,
  ParsedImageFileWithTargetItem,
  MediaBase,
  ParsedMediaBase,
  FileFileWithTargetItem,
  ParsedFileFileWithTargetItem,
  MediaDocument,
  ParsedMediaDocument,
  Media,
  MediaImage,
  ParsedMediaImage,
  VideoEmbedFieldItem,
  ParsedVideoEmbedFieldItem,
  MediaVideo,
  ParsedMediaVideo,
  ParsedMedia,
  EntityReferenceMediaWithTargetParagraphBannerFieldMediaItem,
  ParagraphBanner,
  ParsedParagraphBanner,
  Paragraph,
  EntityReferenceRevisionsParagraphWithTargetParagraphCampaignCtaFieldFontsFontSizeTextItem,
  EntityReferenceRevisionsParagraphWithTargetParagraphCampaignCtaFieldFontsFontSizeTitleItem,
  ParagraphCampaignCta,
  ParsedParagraphCampaignCta,
  ListStringItem,
  EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridFieldFontsFontSizeTextItem,
  EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridFieldFontsFontSizeTitleItem,
  EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridFieldGridBlocksItem,
  ParsedParagraphCampaignGridBlockImage,
  ParagraphCampaignGridBlockImage,
  ParagraphCampaignGrid,
  ParsedParagraphCampaignGrid,
  EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridBlockImageFieldFontsFontSizeTextItem,
  EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridBlockImageFieldFontsFontSizeTitleItem,
  EntityReferenceMediaWithTargetParagraphCampaignGridBlockImageFieldImageItem,
  EntityReferenceRevisionsParagraphWithTargetParagraphCampaignMediaFieldFontsFontSizeTitleItem,
  EntityReferenceMediaWithTargetParagraphCampaignMediaFieldMediaItem,
  ParagraphCampaignMedia,
  ParsedParagraphCampaignMedia,
  EntityReferenceRevisionsParagraphWithTargetParagraphCampaignTextFieldFontsFontSizeTextItem,
  EntityReferenceRevisionsParagraphWithTargetParagraphCampaignTextFieldFontsFontSizeTitleItem,
  ParagraphCampaignText,
  ParsedParagraphCampaignText,
  EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklapperFieldFontsFontSizeTextItem,
  EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklapperFieldFontsFontSizeTitleItem,
  ParagraphCampaignUitklapper,
  ParsedParagraphCampaignUitklapper,
  EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklappersFieldExpandableContentBlocksItem,
  EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklappersFieldFontsFontSizeTextItem,
  EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklappersFieldFontsFontSizeTitleItem,
  ParagraphCampaignUitklappers,
  ParsedParagraphCampaignUitklappers,
  ParagraphCta,
  ParsedParagraphCta,
  EntityReferenceRevisionsParagraphWithTargetParagraphCtaWithImageFieldFontsFontSizeTextItem,
  EntityReferenceRevisionsParagraphWithTargetParagraphCtaWithImageFieldFontsFontSizeTitleItem,
  EntityReferenceMediaWithTargetParagraphCtaWithImageFieldImageItem,
  ParagraphCtaWithImage,
  ParsedParagraphCtaWithImage,
  EntityReferenceMediaWithTargetParagraphDownloadsFieldDownloadsItem,
  ParagraphDownloads,
  ParsedParagraphDownloads,
  ParagraphEmbed,
  ParsedParagraphEmbed,
  ParagraphExpandableContentBlock,
  ParsedParagraphExpandableContentBlock,
  EntityReferenceRevisionsParagraphWithTargetParagraphExpandableContentBlocksFieldExpandableContentBlocksItem,
  ParagraphExpandableContentBlocks,
  ParsedParagraphExpandableContentBlocks,
  EntityReferenceMediaWithTargetParagraphFeaturedContentFieldImageItem,
  ParagraphFeaturedContent,
  ParsedParagraphFeaturedContent,
  EntityReferenceMediaWithTargetParagraphGridBlockImageFieldImageItem,
  ParagraphGridBlockImage,
  ParsedParagraphGridBlockImage,
  ParagraphGridBlockPlain,
  ParsedParagraphGridBlockPlain,
  EntityReferenceRevisionsParagraphWithTargetParagraphGridBlocksImageFieldGridBlocksItem,
  ParagraphGridBlocksImage,
  ParsedParagraphGridBlocksImage,
  EntityReferenceRevisionsParagraphWithTargetParagraphGridBlocksPlainFieldGridBlocksItem,
  ParagraphGridBlocksPlain,
  ParsedParagraphGridBlocksPlain,
  ParagraphIframe,
  ParsedParagraphIframe,
  ParagraphLinksList,
  ParsedParagraphLinksList,
  ParagraphMap,
  ParsedParagraphMap,
  EntityReferenceMediaWithTargetParagraphMediaFieldMediaItem,
  ParagraphMedia,
  ParsedParagraphMedia,
  EntityReferenceRevisionsParagraphWithTargetParagraphMenuFieldMenuGroupsItem,
  ParsedParagraphMenuGroup,
  ParagraphMenuGroup,
  ParagraphMenu,
  ParsedParagraphMenu,
  EntityReferenceRevisionsParagraphWithTargetParagraphMenuGroupFieldMenuItemsItem,
  ParsedParagraphMenuProduct,
  ParsedParagraphMenuImage,
  ParagraphMenuProduct,
  ParagraphMenuImage,
  EntityReferenceMediaWithTargetParagraphMenuImageFieldImageItem,
  EntityReferenceMediaWithTargetParagraphMenuProductFieldImageItem,
  DecimalItem,
  EntityReferenceMediaWithTargetParagraphQuoteFieldImageItem,
  ParagraphQuote,
  ParsedParagraphQuote,
  ParagraphRestaurantList,
  ParsedParagraphRestaurantList,
  ParagraphText,
  ParsedParagraphText,
  ParsedParagraph,
  EntityReferenceRevisionsParagraphWithTargetNodeCampaignFieldHeaderItem,
  MetatagItem,
  EntityReferenceRevisionsParagraphWithTargetNodeCampaignFieldPageContentItem,
  NodeCampaign,
  ParsedNodeCampaign,
  Node,
  EntityReferenceRevisionsParagraphWithTargetNodeHomepageFieldHeaderSingleItem,
  EntityReferenceRevisionsParagraphWithTargetNodeHomepageFieldPageContentItem,
  NodeHomepage,
  ParsedNodeHomepage,
  EntityReferenceMediaWithTargetNodePageFieldIntroductionImageItem,
  EntityReferenceRevisionsParagraphWithTargetNodePageFieldPageContentItem,
  NodePage,
  ParsedNodePage,
  EmailItem,
  EntityReferenceMediaWithTargetNodeRestaurantFieldIntroductionImageItem,
  EntityReferenceRevisionsParagraphWithTargetNodeRestaurantFieldPageContentItem,
  NodeRestaurant,
  ParsedNodeRestaurant,
  EntityReferenceMediaWithTargetNodeRestaurantOverviewFieldIntroductionImageItem,
  EntityReferenceRevisionsParagraphWithTargetNodeRestaurantOverviewFieldPageContentItem,
  NodeRestaurantOverview,
  ParsedNodeRestaurantOverview,
  ParsedNode,
  EntityReferenceTaxonomyVocabularyItem,
  ParsedEntityReferenceTaxonomyVocabularyItem,
  EntityReferenceTaxonomyTermItem,
  ParsedEntityReferenceTaxonomyTermItem,
  TaxonomyTermBase,
  ParsedTaxonomyTermBase,
  TaxonomyTerm,
  ParsedTaxonomyTerm,
  InputEntity,
  ParsedInputEntity,
  ParsedEntity
} from './types'
import {
  Map,
  OrderedMap,
  List
} from 'immutable'
import * as Moment from 'moment'

export const noop_parser = <T>(t: T): T => t

export const language_links_parser = (l: LanguageLinks): ParsedLanguageLinks => Map<Language, string>(l)

export const entity_operation_parser = (t: EntityOperation): ParsedEntityOperation => ({
  title: t.title,
  url: t.url
})

export const entity_operations_parser = (l: EntityOperations | undefined): ParsedEntityOperations => l ? OrderedMap<string, EntityOperation>(l).sortBy(o => (o as EntityOperation).weight).map(o => entity_operation_parser(o as EntityOperation)).toOrderedMap() : OrderedMap<string, EntityOperation>()

export const integer_item_parser = (t: IntegerItem): number => noop_parser<number>(t.value)

export const singular_optional_integer_parser = (f: FieldItemList<IntegerItem>): number | null => f && f.length > 0 ? integer_item_parser(f[0]) : null

export const uuid_item_parser = (t: UuidItem): string => noop_parser<string>(t.value)

export const singular_optional_uuid_parser = (f: FieldItemList<UuidItem>): string | null => f && f.length > 0 ? uuid_item_parser(f[0]) : null

export const language_item_parser = (t: LanguageItem): Language => t.value

export const singular_optional_language_parser = (f: FieldItemList<LanguageItem>): Language | null => f && f.length > 0 ? language_item_parser(f[0]) : null

export const entity_reference_node_type_item_parser = (t: EntityReferenceNodeTypeItem): ParsedEntityReferenceNodeTypeItem => ({
  target_id: noop_parser<string>(t.target_id),
  label: t.target_label
})

export const singular_required_entity_reference_node_type_parser = (f: FieldItemList<EntityReferenceNodeTypeItem>): ParsedEntityReferenceNodeTypeItem => entity_reference_node_type_item_parser(f[0])

export const node_bundle_parser = (f: FieldItemList<EntityReferenceNodeTypeItem>): string => f[0].target_id

export const moment_parser = (t: string): Moment.Moment => Moment.utc(t).local()

export const required_date_time_parser = (t: string): Moment.Moment => moment_parser(t)

export const timestamp_item_parser = (t: TimestampItem): Moment.Moment => required_date_time_parser(t.value)

export const singular_optional_created_parser = (f: FieldItemList<TimestampItem>): Moment.Moment | null => f && f.length > 0 ? timestamp_item_parser(f[0]) : null

export const entity_reference_user_item_parser = (t: EntityReferenceUserItem): ParsedEntityReferenceUserItem => ({
  id: noop_parser<number>(t.target_id),
  url: t.url,
  label: t.target_label
})

export const singular_optional_entity_reference_user_parser = (f: FieldItemList<EntityReferenceUserItem>): ParsedEntityReferenceUserItem | null => f && f.length > 0 ? entity_reference_user_item_parser(f[0]) : null

export const string_item_parser = (t: StringItem): string => t != undefined && t.value != undefined ? noop_parser<string>(t.value) : ""

export const singular_optional_string_long_parser = (f: FieldItemList<StringItem>): string | null => f && f.length > 0 ? string_item_parser(f[0]) : null

export const boolean_item_parser = (t: BooleanItem): boolean => noop_parser<boolean>(t.value)

export const singular_optional_boolean_parser = (f: FieldItemList<BooleanItem>): boolean | null => f && f.length > 0 ? boolean_item_parser(f[0]) : null

export const singular_required_string_parser = (f: FieldItemList<StringItem>): string => string_item_parser(f[0])

export const singular_optional_changed_parser = (f: FieldItemList<TimestampItem>): Moment.Moment | null => f && f.length > 0 ? timestamp_item_parser(f[0]) : null

export const singular_optional_string_parser = (f: FieldItemList<StringItem>): string | null => f && f.length > 0 ? string_item_parser(f[0]) : null

export const metatag_field_parser = (t: MetatagField): Metatags => t ? (t.value) : {}

export const path_item_parser = (t: PathItem): string | null => noop_parser<string | null>(t.alias)

export const singular_optional_path_parser = (f: FieldItemList<PathItem> | undefined): string | null => f && f.length > 0 ? path_item_parser(f[0]) : null

export const node_base_parser = (t: NodeBase): ParsedNodeBase => ({
  entity_type: ((_: any): "node" => "node")(t),
  id: singular_optional_integer_parser(t.nid),
  status: singular_optional_boolean_parser(t.status),
  author: singular_optional_entity_reference_user_parser(t.uid),
  language: singular_optional_language_parser(t.langcode),
  label: singular_required_string_parser(t.title),
  revision_timestamp: singular_optional_created_parser(t.revision_timestamp),
  revision_uid: singular_optional_entity_reference_user_parser(t.revision_uid),
  revision_log: singular_optional_string_long_parser(t.revision_log),
  created: singular_optional_created_parser(t.created),
  changed: singular_optional_changed_parser(t.changed),
  promote: singular_optional_boolean_parser(t.promote),
  sticky: singular_optional_boolean_parser(t.sticky),
  default_langcode: singular_optional_boolean_parser(t.default_langcode),
  revision_translation_affected: singular_optional_boolean_parser(t.revision_translation_affected),
  moderation_state: singular_optional_string_parser(t.moderation_state),
  metatag: metatag_field_parser(t.metatag),
  path: singular_optional_path_parser(t.path),
  content_translation_source: singular_optional_language_parser(t.content_translation_source),
  content_translation_outdated: singular_optional_boolean_parser(t.content_translation_outdated),
  language_links: language_links_parser(t.language_links),
  entity_operations: entity_operations_parser(t.entity_operations)
})

export const datetime_item_parser = (t: DatetimeItem): Moment.Moment => required_date_time_parser(t.value)

export const singular_optional_datetime_parser = (f: FieldItemList<DatetimeItem>): Moment.Moment | null => f && f.length > 0 ? datetime_item_parser(f[0]) : null

export const entity_reference_paragraphs_type_item_parser = (t: EntityReferenceParagraphsTypeItem): ParsedEntityReferenceParagraphsTypeItem => ({
  target_id: noop_parser<string>(t.target_id),
  label: t.target_label
})

export const singular_required_entity_reference_paragraphs_type_parser = (f: FieldItemList<EntityReferenceParagraphsTypeItem>): ParsedEntityReferenceParagraphsTypeItem => entity_reference_paragraphs_type_item_parser(f[0])

export const paragraph_bundle_parser = (f: FieldItemList<EntityReferenceParagraphsTypeItem>): string => f[0].target_id

export const paragraph_base_parser = (t: ParagraphBase): ParsedParagraphBase => ({
  entity_type: ((_: any): "paragraph" => "paragraph")(t),
  id: singular_optional_integer_parser(t.id),
  language: singular_optional_language_parser(t.langcode),
  status: singular_optional_boolean_parser(t.status),
  created: singular_optional_created_parser(t.created),
  parent_id: singular_optional_string_parser(t.parent_id),
  parent_type: singular_optional_string_parser(t.parent_type),
  parent_field_name: singular_optional_string_parser(t.parent_field_name),
  behavior_settings: singular_optional_string_long_parser(t.behavior_settings),
  default_langcode: singular_optional_boolean_parser(t.default_langcode),
  revision_translation_affected: singular_optional_boolean_parser(t.revision_translation_affected),
  language_links: language_links_parser(t.language_links),
  entity_operations: entity_operations_parser(t.entity_operations)
})

export const text_item_parser = (t: TextItem): string => t.processed

export const singular_optional_text_long_parser = (f: FieldItemList<TextItem>): string | null => f && f.length > 0 ? text_item_parser(f[0]) : null

export const singular_optional_text_parser = (f: FieldItemList<TextItem>): string | null => f && f.length > 0 ? text_item_parser(f[0]) : null

export const entity_reference_revisions_paragraph_with_target_paragraph_banner_field_fonts_font_size_text_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphBannerFieldFontsFontSizeTextItem): ParsedParagraphFontsFontSize | undefined | undefined => (t.target !== undefined ? ((t: ParagraphFontsFontSize): ParsedParagraphFontsFontSize =>
    paragraph_fonts_font_size_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_banner_field_fonts_font_size_text_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphBannerFieldFontsFontSizeTextItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const singular_required_entity_reference_revisions_paragraph_with_target_paragraph_banner_field_fonts_font_size_text_parser = (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphBannerFieldFontsFontSizeTextItem>): ParsedParagraphFontsFontSize | undefined => entity_reference_revisions_paragraph_with_target_paragraph_banner_field_fonts_font_size_text_item_parser(f[0])

export const entity_reference_revisions_paragraph_with_target_paragraph_banner_field_fonts_font_size_title_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphBannerFieldFontsFontSizeTitleItem): ParsedParagraphFontsFontSize | undefined | undefined => (t.target !== undefined ? ((t: ParagraphFontsFontSize): ParsedParagraphFontsFontSize =>
    paragraph_fonts_font_size_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_banner_field_fonts_font_size_title_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphBannerFieldFontsFontSizeTitleItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const singular_required_entity_reference_revisions_paragraph_with_target_paragraph_banner_field_fonts_font_size_title_parser = (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphBannerFieldFontsFontSizeTitleItem>): ParsedParagraphFontsFontSize | undefined => entity_reference_revisions_paragraph_with_target_paragraph_banner_field_fonts_font_size_title_item_parser(f[0])

export const link_item_parser = (t: LinkItem): ParsedLinkItem => ({
  title: noop_parser<string | null>(t.title),
  url: t.processed_url
})

export const plural_link_parser =
  (f: FieldItemList<LinkItem>): List<ParsedLinkItem> =>
    List<ParsedLinkItem>(f.map(i => link_item_parser(i)))

export const entity_reference_media_type_item_parser = (t: EntityReferenceMediaTypeItem): ParsedEntityReferenceMediaTypeItem => ({
  target_id: noop_parser<string>(t.target_id),
  label: t.target_label
})

export const singular_required_entity_reference_media_type_parser = (f: FieldItemList<EntityReferenceMediaTypeItem>): ParsedEntityReferenceMediaTypeItem => entity_reference_media_type_item_parser(f[0])

export const media_bundle_parser = (f: FieldItemList<EntityReferenceMediaTypeItem>): string => f[0].target_id

export const file_uri_item_parser = (t: FileUriItem): string | null => noop_parser<string | null>(t.url)

export const singular_optional_file_uri_parser = (f: FieldItemList<FileUriItem>): string | null => f && f.length > 0 ? file_uri_item_parser(f[0]) : null

export const file_base_parser = (t: FileBase): ParsedFileBase => ({
  entity_type: ((_: any): "file" => "file")(t),
  id: singular_optional_integer_parser(t.fid),
  label: singular_optional_string_parser(t.filename),
  url: singular_optional_file_uri_parser(t.uri)
})

export const styles_parser = (t: Styles): ParsedStyles => ({
  "1280x640": t["1280x640"],
  "480x240": t["480x240"],
  "640x320": t["640x320"],
  "960x480": t["960x480"],
  crop_thumbnail: t.crop_thumbnail,
  grid_400x200: t.grid_400x200,
  grid_with_image_750_375: t.grid_with_image_750_375,
  header_1440x500: t.header_1440x500,
  header_1440x670: t.header_1440x670,
  large: t.large,
  media_880w: t.media_880w,
  medium: t.medium,
  person_140x140: t.person_140x140,
  product_small: t.product_small,
  thumbnail: t.thumbnail,
  w1320: t.w1320,
  w1440: t.w1440,
  w1980: t.w1980,
  w2160: t.w2160,
  w2880: t.w2880,
  w660: t.w660,
  w720: t.w720
})

export const file_image_parser = (t: FileImage): ParsedFileImage => ({
  ...file_base_parser(t),
  style_urls: styles_parser(t.style_urls)
})

export const file_image_guard = (t:File): t is FileImage => (t as any).style_urls !== undefined

export const file_other_parser = (t: FileOther): ParsedFileOther => ({
  ...file_base_parser(t)
})

export const file_other_guard = (t:File): t is FileOther => (t as any).style_urls === undefined

export const file_parser = (t: File): ParsedFile => file_image_guard(t) ? file_image_parser(t) : file_other_parser(t)

export const file_guard = (t: Entity): t is File => (
  (t as any).fid !== undefined &&
  (t as any).uuid !== undefined &&
  (t as any).langcode !== undefined &&
  (t as any).uid !== undefined &&
  (t as any).filename !== undefined &&
  (t as any).uri !== undefined &&
  (t as any).filemime !== undefined &&
  (t as any).filesize !== undefined &&
  (t as any).status !== undefined &&
  (t as any).created !== undefined &&
  (t as any).changed !== undefined
)

export const image_file_with_target_item_parser = (t: ImageFileWithTargetItem): ParsedImageFileWithTargetItem => ({
  alt: noop_parser<string | null>(t.alt),
  image: (t.target !== undefined ? file_image_parser(t.target) : undefined)
})

export const image_file_with_target_item_guard = (t: any): t is ImageFileWithTargetItem => (t.target_id !== undefined) && (t.alt !== undefined) && (t.title !== undefined) && (t.width !== undefined) && (t.height !== undefined) && ((t.target === undefined) || file_image_guard(t.target))

export const singular_optional_image_file_with_target_parser = (f: FieldItemList<ImageFileWithTargetItem>): ParsedImageFileWithTargetItem | null => f && f.length > 0 && image_file_with_target_item_guard(f[0]) ? image_file_with_target_item_parser(f[0]) : null

export const media_base_parser = (t: MediaBase): ParsedMediaBase => ({
  entity_type: ((_: any): "media" => "media")(t),
  id: singular_optional_integer_parser(t.mid),
  language: singular_optional_language_parser(t.langcode),
  label: singular_required_string_parser(t.name),
  revision_created: singular_optional_created_parser(t.revision_created),
  revision_user: singular_optional_entity_reference_user_parser(t.revision_user),
  revision_log_message: singular_optional_string_long_parser(t.revision_log_message),
  status: singular_optional_boolean_parser(t.status),
  uid: singular_optional_entity_reference_user_parser(t.uid),
  thumbnail: singular_optional_image_file_with_target_parser(t.thumbnail),
  created: singular_optional_created_parser(t.created),
  changed: singular_optional_changed_parser(t.changed),
  default_langcode: singular_optional_boolean_parser(t.default_langcode),
  revision_translation_affected: singular_optional_boolean_parser(t.revision_translation_affected),
  metatag: metatag_field_parser(t.metatag),
  path: singular_optional_path_parser(t.path),
  language_links: language_links_parser(t.language_links),
  entity_operations: entity_operations_parser(t.entity_operations)
})

export const file_file_with_target_item_parser = (t: FileFileWithTargetItem): ParsedFileFileWithTargetItem => ({
  description: noop_parser<string | null>(t.description),
  file: (t.target !== undefined ? file_other_parser(t.target) : undefined)
})

export const file_file_with_target_item_guard = (t: any): t is FileFileWithTargetItem => (t.target_id !== undefined) && (t.display !== undefined) && (t.description !== undefined) && ((t.target === undefined) || file_other_guard(t.target))

export const singular_required_file_file_with_target_parser = (f: FieldItemList<FileFileWithTargetItem>): ParsedFileFileWithTargetItem => file_file_with_target_item_parser(f[0])

export const media_document_parser = (t: MediaDocument): ParsedMediaDocument => ({
  ...media_base_parser(t),
  bundle: ((_: any): "document" => "document")(t.bundle),
  field_media_file: singular_required_file_file_with_target_parser(t.field_media_file)
})

export const media_document_guard = (t: Media): t is MediaDocument => media_bundle_parser(t.bundle) == "document"

export const singular_required_image_file_with_target_parser = (f: FieldItemList<ImageFileWithTargetItem>): ParsedImageFileWithTargetItem => image_file_with_target_item_parser(f[0])

export const media_image_parser = (t: MediaImage): ParsedMediaImage => ({
  ...media_base_parser(t),
  bundle: ((_: any): "image" => "image")(t.bundle),
  field_media_image: singular_required_image_file_with_target_parser(t.field_media_image)
})

export const media_image_guard = (t: Media): t is MediaImage => media_bundle_parser(t.bundle) == "image"

export const video_embed_field_item_parser = (t: VideoEmbedFieldItem): ParsedVideoEmbedFieldItem => ({
  type: t.video_type,
  id: t.video_id,
  iframe_url: t.iframe_url
})

export const singular_required_video_embed_field_parser = (f: FieldItemList<VideoEmbedFieldItem>): ParsedVideoEmbedFieldItem => video_embed_field_item_parser(f[0])

export const media_video_parser = (t: MediaVideo): ParsedMediaVideo => ({
  ...media_base_parser(t),
  bundle: ((_: any): "video" => "video")(t.bundle),
  field_media_video_embed_field: singular_required_video_embed_field_parser(t.field_media_video_embed_field)
})

export const media_video_guard = (t: Media): t is MediaVideo => media_bundle_parser(t.bundle) == "video"

export const media_parser = (t: Media): ParsedMedia =>
  media_document_guard(t) ?
    media_document_parser(t) :
  media_image_guard(t) ?
    media_image_parser(t) :
    media_video_parser(t)

export const media_guard = (t: Entity): t is Media => (
  (t as any).mid !== undefined &&
  (t as any).uuid !== undefined &&
  (t as any).vid !== undefined &&
  (t as any).langcode !== undefined &&
  (t as any).bundle !== undefined &&
  (t as any).bundle[0].target_type == "media_type" &&
  (t as any).revision_created !== undefined &&
  (t as any).revision_user !== undefined &&
  (t as any).revision_log_message !== undefined &&
  (t as any).status !== undefined &&
  (t as any).uid !== undefined &&
  (t as any).name !== undefined &&
  (t as any).thumbnail !== undefined &&
  (t as any).created !== undefined &&
  (t as any).changed !== undefined &&
  (t as any).default_langcode !== undefined &&
  (t as any).revision_translation_affected !== undefined &&
  (t as any).metatag !== undefined
)

export const entity_reference_media_with_target_paragraph_banner_field_media_item_parser = (t: EntityReferenceMediaWithTargetParagraphBannerFieldMediaItem): ParsedMediaImage | undefined | undefined => (t.target !== undefined ? ((t: MediaImage): ParsedMediaImage =>
    media_image_parser(t))(t.target) : undefined)

export const entity_reference_media_with_target_paragraph_banner_field_media_item_guard = (t: any): t is EntityReferenceMediaWithTargetParagraphBannerFieldMediaItem => (t.target_id !== undefined)

export const singular_required_entity_reference_media_with_target_paragraph_banner_field_media_parser = (f: FieldItemList<EntityReferenceMediaWithTargetParagraphBannerFieldMediaItem>): ParsedMediaImage | undefined => entity_reference_media_with_target_paragraph_banner_field_media_item_parser(f[0])

export const paragraph_banner_parser = (t: ParagraphBanner): ParsedParagraphBanner => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "banner" => "banner")(t.type),
  field_campaign_introduction: singular_optional_text_long_parser(t.field_campaign_introduction),
  field_campaign_title: singular_optional_text_parser(t.field_campaign_title),
  field_fonts_font_size_text: singular_required_entity_reference_revisions_paragraph_with_target_paragraph_banner_field_fonts_font_size_text_parser(t.field_fonts_font_size_text),
  field_fonts_font_size_title: singular_required_entity_reference_revisions_paragraph_with_target_paragraph_banner_field_fonts_font_size_title_parser(t.field_fonts_font_size_title),
  field_links: plural_link_parser(t.field_links),
  field_media: singular_required_entity_reference_media_with_target_paragraph_banner_field_media_parser(t.field_media)
})

export const paragraph_banner_guard = (t: Paragraph): t is ParagraphBanner => paragraph_bundle_parser(t.type) == "banner"

export const singular_required_text_long_parser = (f: FieldItemList<TextItem>): string => text_item_parser(f[0])

export const singular_required_text_parser = (f: FieldItemList<TextItem>): string => text_item_parser(f[0])

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_cta_field_fonts_font_size_text_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphCampaignCtaFieldFontsFontSizeTextItem): ParsedParagraphFontsFontSize | undefined | undefined => (t.target !== undefined ? ((t: ParagraphFontsFontSize): ParsedParagraphFontsFontSize =>
    paragraph_fonts_font_size_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_cta_field_fonts_font_size_text_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphCampaignCtaFieldFontsFontSizeTextItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_cta_field_fonts_font_size_text_parser = (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphCampaignCtaFieldFontsFontSizeTextItem>): ParsedParagraphFontsFontSize | undefined | null => f && f.length > 0 && entity_reference_revisions_paragraph_with_target_paragraph_campaign_cta_field_fonts_font_size_text_item_guard(f[0]) ? entity_reference_revisions_paragraph_with_target_paragraph_campaign_cta_field_fonts_font_size_text_item_parser(f[0]) : null

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_cta_field_fonts_font_size_title_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphCampaignCtaFieldFontsFontSizeTitleItem): ParsedParagraphFontsFontSize | undefined | undefined => (t.target !== undefined ? ((t: ParagraphFontsFontSize): ParsedParagraphFontsFontSize =>
    paragraph_fonts_font_size_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_cta_field_fonts_font_size_title_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphCampaignCtaFieldFontsFontSizeTitleItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_cta_field_fonts_font_size_title_parser = (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphCampaignCtaFieldFontsFontSizeTitleItem>): ParsedParagraphFontsFontSize | undefined | null => f && f.length > 0 && entity_reference_revisions_paragraph_with_target_paragraph_campaign_cta_field_fonts_font_size_title_item_guard(f[0]) ? entity_reference_revisions_paragraph_with_target_paragraph_campaign_cta_field_fonts_font_size_title_item_parser(f[0]) : null

export const paragraph_campaign_cta_parser = (t: ParagraphCampaignCta): ParsedParagraphCampaignCta => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "campaign_cta" => "campaign_cta")(t.type),
  field_campaign_introduction: singular_required_text_long_parser(t.field_campaign_introduction),
  field_campaign_title: singular_required_text_parser(t.field_campaign_title),
  field_fonts_font_size_text: singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_cta_field_fonts_font_size_text_parser(t.field_fonts_font_size_text),
  field_fonts_font_size_title: singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_cta_field_fonts_font_size_title_parser(t.field_fonts_font_size_title),
  field_links: plural_link_parser(t.field_links)
})

export const paragraph_campaign_cta_guard = (t: Paragraph): t is ParagraphCampaignCta => paragraph_bundle_parser(t.type) == "campaign_cta"

export const list_string_item_parser = (t: ListStringItem): string => noop_parser<string>(t.value)

export const singular_required_list_string_parser = (f: FieldItemList<ListStringItem>): string => list_string_item_parser(f[0])

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_field_fonts_font_size_text_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridFieldFontsFontSizeTextItem): ParsedParagraphFontsFontSize | undefined | undefined => (t.target !== undefined ? ((t: ParagraphFontsFontSize): ParsedParagraphFontsFontSize =>
    paragraph_fonts_font_size_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_field_fonts_font_size_text_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridFieldFontsFontSizeTextItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_field_fonts_font_size_text_parser = (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridFieldFontsFontSizeTextItem>): ParsedParagraphFontsFontSize | undefined | null => f && f.length > 0 && entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_field_fonts_font_size_text_item_guard(f[0]) ? entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_field_fonts_font_size_text_item_parser(f[0]) : null

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_field_fonts_font_size_title_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridFieldFontsFontSizeTitleItem): ParsedParagraphFontsFontSize | undefined | undefined => (t.target !== undefined ? ((t: ParagraphFontsFontSize): ParsedParagraphFontsFontSize =>
    paragraph_fonts_font_size_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_field_fonts_font_size_title_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridFieldFontsFontSizeTitleItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_field_fonts_font_size_title_parser = (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridFieldFontsFontSizeTitleItem>): ParsedParagraphFontsFontSize | undefined | null => f && f.length > 0 && entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_field_fonts_font_size_title_item_guard(f[0]) ? entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_field_fonts_font_size_title_item_parser(f[0]) : null

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_field_grid_blocks_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridFieldGridBlocksItem): ParsedParagraphCampaignGridBlockImage | undefined | undefined => (t.target !== undefined ? ((t: ParagraphCampaignGridBlockImage): ParsedParagraphCampaignGridBlockImage =>    paragraph_campaign_grid_block_image_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_field_grid_blocks_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridFieldGridBlocksItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const plural_entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_field_grid_blocks_parser =
  (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridFieldGridBlocksItem>): List<ParsedParagraphCampaignGridBlockImage> =>
    List<ParsedParagraphCampaignGridBlockImage>(f.filter(i => entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_field_grid_blocks_item_guard(i)).map(i => entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_field_grid_blocks_item_parser(i)).filter(i => i !== undefined))

export const singular_optional_link_parser = (f: FieldItemList<LinkItem>): ParsedLinkItem | null => f && f.length > 0 ? link_item_parser(f[0]) : null

export const paragraph_campaign_grid_parser = (t: ParagraphCampaignGrid): ParsedParagraphCampaignGrid => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "campaign_grid" => "campaign_grid")(t.type),
  field_alignment: singular_optional_boolean_parser(t.field_alignment),
  field_body: singular_optional_text_long_parser(t.field_body),
  field_campaign_title: singular_optional_text_parser(t.field_campaign_title),
  field_columns: singular_required_list_string_parser(t.field_columns),
  field_fonts_font_size_text: singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_field_fonts_font_size_text_parser(t.field_fonts_font_size_text),
  field_fonts_font_size_title: singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_field_fonts_font_size_title_parser(t.field_fonts_font_size_title),
  field_grid_blocks: plural_entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_field_grid_blocks_parser(t.field_grid_blocks),
  field_link: singular_optional_link_parser(t.field_link),
  field_width: singular_optional_boolean_parser(t.field_width)
})

export const paragraph_campaign_grid_guard = (t: Paragraph): t is ParagraphCampaignGrid => paragraph_bundle_parser(t.type) == "campaign_grid"

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_block_image_field_fonts_font_size_text_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridBlockImageFieldFontsFontSizeTextItem): ParsedParagraphFontsFontSize | undefined | undefined => (t.target !== undefined ? ((t: ParagraphFontsFontSize): ParsedParagraphFontsFontSize =>
    paragraph_fonts_font_size_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_block_image_field_fonts_font_size_text_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridBlockImageFieldFontsFontSizeTextItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_block_image_field_fonts_font_size_text_parser = (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridBlockImageFieldFontsFontSizeTextItem>): ParsedParagraphFontsFontSize | undefined | null => f && f.length > 0 && entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_block_image_field_fonts_font_size_text_item_guard(f[0]) ? entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_block_image_field_fonts_font_size_text_item_parser(f[0]) : null

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_block_image_field_fonts_font_size_title_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridBlockImageFieldFontsFontSizeTitleItem): ParsedParagraphFontsFontSize | undefined | undefined => (t.target !== undefined ? ((t: ParagraphFontsFontSize): ParsedParagraphFontsFontSize =>
    paragraph_fonts_font_size_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_block_image_field_fonts_font_size_title_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridBlockImageFieldFontsFontSizeTitleItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_block_image_field_fonts_font_size_title_parser = (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphCampaignGridBlockImageFieldFontsFontSizeTitleItem>): ParsedParagraphFontsFontSize | undefined | null => f && f.length > 0 && entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_block_image_field_fonts_font_size_title_item_guard(f[0]) ? entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_block_image_field_fonts_font_size_title_item_parser(f[0]) : null

export const entity_reference_media_with_target_paragraph_campaign_grid_block_image_field_image_item_parser = (t: EntityReferenceMediaWithTargetParagraphCampaignGridBlockImageFieldImageItem): ParsedMediaImage | undefined | undefined => (t.target !== undefined ? ((t: MediaImage): ParsedMediaImage =>
    media_image_parser(t))(t.target) : undefined)

export const entity_reference_media_with_target_paragraph_campaign_grid_block_image_field_image_item_guard = (t: any): t is EntityReferenceMediaWithTargetParagraphCampaignGridBlockImageFieldImageItem => (t.target_id !== undefined)

export const singular_required_entity_reference_media_with_target_paragraph_campaign_grid_block_image_field_image_parser = (f: FieldItemList<EntityReferenceMediaWithTargetParagraphCampaignGridBlockImageFieldImageItem>): ParsedMediaImage | undefined => entity_reference_media_with_target_paragraph_campaign_grid_block_image_field_image_item_parser(f[0])

export const paragraph_campaign_grid_block_image_parser = (t: ParagraphCampaignGridBlockImage): ParsedParagraphCampaignGridBlockImage => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "campaign_grid_block_image" => "campaign_grid_block_image")(t.type),
  field_campaign_introduction: singular_required_text_long_parser(t.field_campaign_introduction),
  field_campaign_title: singular_required_text_parser(t.field_campaign_title),
  field_fonts_font_size_text: singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_block_image_field_fonts_font_size_text_parser(t.field_fonts_font_size_text),
  field_fonts_font_size_title: singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_grid_block_image_field_fonts_font_size_title_parser(t.field_fonts_font_size_title),
  field_image: singular_required_entity_reference_media_with_target_paragraph_campaign_grid_block_image_field_image_parser(t.field_image),
  field_link: singular_optional_link_parser(t.field_link)
})

export const paragraph_campaign_grid_block_image_guard = (t: Paragraph): t is ParagraphCampaignGridBlockImage => paragraph_bundle_parser(t.type) == "campaign_grid_block_image"

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_media_field_fonts_font_size_title_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphCampaignMediaFieldFontsFontSizeTitleItem): ParsedParagraphFontsFontSize | undefined | undefined => (t.target !== undefined ? ((t: ParagraphFontsFontSize): ParsedParagraphFontsFontSize =>
    paragraph_fonts_font_size_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_media_field_fonts_font_size_title_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphCampaignMediaFieldFontsFontSizeTitleItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_media_field_fonts_font_size_title_parser = (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphCampaignMediaFieldFontsFontSizeTitleItem>): ParsedParagraphFontsFontSize | undefined | null => f && f.length > 0 && entity_reference_revisions_paragraph_with_target_paragraph_campaign_media_field_fonts_font_size_title_item_guard(f[0]) ? entity_reference_revisions_paragraph_with_target_paragraph_campaign_media_field_fonts_font_size_title_item_parser(f[0]) : null

export const entity_reference_media_with_target_paragraph_campaign_media_field_media_item_parser = (t: EntityReferenceMediaWithTargetParagraphCampaignMediaFieldMediaItem): ParsedMediaImage | ParsedMediaVideo | undefined | undefined => (t.target !== undefined ? ((t: MediaImage | MediaVideo): ParsedMediaImage | ParsedMediaVideo =>
  media_image_guard(t) ?
    media_image_parser(t) :
    media_video_parser(t))(t.target) : undefined)

export const entity_reference_media_with_target_paragraph_campaign_media_field_media_item_guard = (t: any): t is EntityReferenceMediaWithTargetParagraphCampaignMediaFieldMediaItem => (t.target_id !== undefined)

export const singular_required_entity_reference_media_with_target_paragraph_campaign_media_field_media_parser = (f: FieldItemList<EntityReferenceMediaWithTargetParagraphCampaignMediaFieldMediaItem>): ParsedMediaImage | ParsedMediaVideo | undefined => entity_reference_media_with_target_paragraph_campaign_media_field_media_item_parser(f[0])

export const paragraph_campaign_media_parser = (t: ParagraphCampaignMedia): ParsedParagraphCampaignMedia => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "campaign_media" => "campaign_media")(t.type),
  field_campaign_title: singular_optional_text_parser(t.field_campaign_title),
  field_fonts_font_size_title: singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_media_field_fonts_font_size_title_parser(t.field_fonts_font_size_title),
  field_media: singular_required_entity_reference_media_with_target_paragraph_campaign_media_field_media_parser(t.field_media),
  field_width: singular_optional_boolean_parser(t.field_width)
})

export const paragraph_campaign_media_guard = (t: Paragraph): t is ParagraphCampaignMedia => paragraph_bundle_parser(t.type) == "campaign_media"

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_text_field_fonts_font_size_text_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphCampaignTextFieldFontsFontSizeTextItem): ParsedParagraphFontsFontSize | undefined | undefined => (t.target !== undefined ? ((t: ParagraphFontsFontSize): ParsedParagraphFontsFontSize =>
    paragraph_fonts_font_size_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_text_field_fonts_font_size_text_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphCampaignTextFieldFontsFontSizeTextItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_text_field_fonts_font_size_text_parser = (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphCampaignTextFieldFontsFontSizeTextItem>): ParsedParagraphFontsFontSize | undefined | null => f && f.length > 0 && entity_reference_revisions_paragraph_with_target_paragraph_campaign_text_field_fonts_font_size_text_item_guard(f[0]) ? entity_reference_revisions_paragraph_with_target_paragraph_campaign_text_field_fonts_font_size_text_item_parser(f[0]) : null

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_text_field_fonts_font_size_title_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphCampaignTextFieldFontsFontSizeTitleItem): ParsedParagraphFontsFontSize | undefined | undefined => (t.target !== undefined ? ((t: ParagraphFontsFontSize): ParsedParagraphFontsFontSize =>
    paragraph_fonts_font_size_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_text_field_fonts_font_size_title_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphCampaignTextFieldFontsFontSizeTitleItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_text_field_fonts_font_size_title_parser = (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphCampaignTextFieldFontsFontSizeTitleItem>): ParsedParagraphFontsFontSize | undefined | null => f && f.length > 0 && entity_reference_revisions_paragraph_with_target_paragraph_campaign_text_field_fonts_font_size_title_item_guard(f[0]) ? entity_reference_revisions_paragraph_with_target_paragraph_campaign_text_field_fonts_font_size_title_item_parser(f[0]) : null

export const paragraph_campaign_text_parser = (t: ParagraphCampaignText): ParsedParagraphCampaignText => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "campaign_text" => "campaign_text")(t.type),
  field_campaign_introduction: singular_required_text_long_parser(t.field_campaign_introduction),
  field_campaign_title: singular_optional_text_parser(t.field_campaign_title),
  field_fonts_font_size_text: singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_text_field_fonts_font_size_text_parser(t.field_fonts_font_size_text),
  field_fonts_font_size_title: singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_text_field_fonts_font_size_title_parser(t.field_fonts_font_size_title),
  field_links: plural_link_parser(t.field_links)
})

export const paragraph_campaign_text_guard = (t: Paragraph): t is ParagraphCampaignText => paragraph_bundle_parser(t.type) == "campaign_text"

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklapper_field_fonts_font_size_text_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklapperFieldFontsFontSizeTextItem): ParsedParagraphFontsFontSize | undefined | undefined => (t.target !== undefined ? ((t: ParagraphFontsFontSize): ParsedParagraphFontsFontSize =>
    paragraph_fonts_font_size_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklapper_field_fonts_font_size_text_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklapperFieldFontsFontSizeTextItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklapper_field_fonts_font_size_text_parser = (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklapperFieldFontsFontSizeTextItem>): ParsedParagraphFontsFontSize | undefined | null => f && f.length > 0 && entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklapper_field_fonts_font_size_text_item_guard(f[0]) ? entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklapper_field_fonts_font_size_text_item_parser(f[0]) : null

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklapper_field_fonts_font_size_title_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklapperFieldFontsFontSizeTitleItem): ParsedParagraphFontsFontSize | undefined | undefined => (t.target !== undefined ? ((t: ParagraphFontsFontSize): ParsedParagraphFontsFontSize =>
    paragraph_fonts_font_size_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklapper_field_fonts_font_size_title_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklapperFieldFontsFontSizeTitleItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklapper_field_fonts_font_size_title_parser = (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklapperFieldFontsFontSizeTitleItem>): ParsedParagraphFontsFontSize | undefined | null => f && f.length > 0 && entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklapper_field_fonts_font_size_title_item_guard(f[0]) ? entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklapper_field_fonts_font_size_title_item_parser(f[0]) : null

export const paragraph_campaign_uitklapper_parser = (t: ParagraphCampaignUitklapper): ParsedParagraphCampaignUitklapper => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "campaign_uitklapper" => "campaign_uitklapper")(t.type),
  field_body: singular_required_text_long_parser(t.field_body),
  field_campaign_title: singular_required_text_parser(t.field_campaign_title),
  field_fonts_font_size_text: singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklapper_field_fonts_font_size_text_parser(t.field_fonts_font_size_text),
  field_fonts_font_size_title: singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklapper_field_fonts_font_size_title_parser(t.field_fonts_font_size_title)
})

export const paragraph_campaign_uitklapper_guard = (t: Paragraph): t is ParagraphCampaignUitklapper => paragraph_bundle_parser(t.type) == "campaign_uitklapper"

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklappers_field_expandable_content_blocks_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklappersFieldExpandableContentBlocksItem): ParsedParagraphCampaignUitklapper | undefined | undefined => (t.target !== undefined ? ((t: ParagraphCampaignUitklapper): ParsedParagraphCampaignUitklapper =>
    paragraph_campaign_uitklapper_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklappers_field_expandable_content_blocks_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklappersFieldExpandableContentBlocksItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const plural_entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklappers_field_expandable_content_blocks_parser =
  (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklappersFieldExpandableContentBlocksItem>): List<ParsedParagraphCampaignUitklapper> =>
    List<ParsedParagraphCampaignUitklapper>(f.filter(i => entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklappers_field_expandable_content_blocks_item_guard(i)).map(i => entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklappers_field_expandable_content_blocks_item_parser(i)).filter(i => i !== undefined))

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklappers_field_fonts_font_size_text_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklappersFieldFontsFontSizeTextItem): ParsedParagraphFontsFontSize | undefined | undefined => (t.target !== undefined ? ((t: ParagraphFontsFontSize): ParsedParagraphFontsFontSize =>
    paragraph_fonts_font_size_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklappers_field_fonts_font_size_text_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklappersFieldFontsFontSizeTextItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklappers_field_fonts_font_size_text_parser = (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklappersFieldFontsFontSizeTextItem>): ParsedParagraphFontsFontSize | undefined | null => f && f.length > 0 && entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklappers_field_fonts_font_size_text_item_guard(f[0]) ? entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklappers_field_fonts_font_size_text_item_parser(f[0]) : null

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklappers_field_fonts_font_size_title_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklappersFieldFontsFontSizeTitleItem): ParsedParagraphFontsFontSize | undefined | undefined => (t.target !== undefined ? ((t: ParagraphFontsFontSize): ParsedParagraphFontsFontSize =>
    paragraph_fonts_font_size_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklappers_field_fonts_font_size_title_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklappersFieldFontsFontSizeTitleItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklappers_field_fonts_font_size_title_parser = (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphCampaignUitklappersFieldFontsFontSizeTitleItem>): ParsedParagraphFontsFontSize | undefined | null => f && f.length > 0 && entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklappers_field_fonts_font_size_title_item_guard(f[0]) ? entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklappers_field_fonts_font_size_title_item_parser(f[0]) : null

export const paragraph_campaign_uitklappers_parser = (t: ParagraphCampaignUitklappers): ParsedParagraphCampaignUitklappers => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "campaign_uitklappers" => "campaign_uitklappers")(t.type),
  field_campaign_introduction: singular_optional_text_long_parser(t.field_campaign_introduction),
  field_campaign_title: singular_optional_text_parser(t.field_campaign_title),
  field_expandable_content_blocks: plural_entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklappers_field_expandable_content_blocks_parser(t.field_expandable_content_blocks),
  field_fonts_font_size_text: singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklappers_field_fonts_font_size_text_parser(t.field_fonts_font_size_text),
  field_fonts_font_size_title: singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_campaign_uitklappers_field_fonts_font_size_title_parser(t.field_fonts_font_size_title),
  field_link: singular_optional_link_parser(t.field_link)
})

export const paragraph_campaign_uitklappers_guard = (t: Paragraph): t is ParagraphCampaignUitklappers => paragraph_bundle_parser(t.type) == "campaign_uitklappers"

export const singular_required_string_long_parser = (f: FieldItemList<StringItem>): string => string_item_parser(f[0])

export const paragraph_cta_parser = (t: ParagraphCta): ParsedParagraphCta => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "cta" => "cta")(t.type),
  field_introduction: singular_required_string_long_parser(t.field_introduction),
  field_links: plural_link_parser(t.field_links),
  field_title: singular_required_string_parser(t.field_title)
})

export const paragraph_cta_guard = (t: Paragraph): t is ParagraphCta => paragraph_bundle_parser(t.type) == "cta"

export const entity_reference_revisions_paragraph_with_target_paragraph_cta_with_image_field_fonts_font_size_text_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphCtaWithImageFieldFontsFontSizeTextItem): ParsedParagraphFontsFontSize | undefined | undefined => (t.target !== undefined ? ((t: ParagraphFontsFontSize): ParsedParagraphFontsFontSize =>
    paragraph_fonts_font_size_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_cta_with_image_field_fonts_font_size_text_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphCtaWithImageFieldFontsFontSizeTextItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_cta_with_image_field_fonts_font_size_text_parser = (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphCtaWithImageFieldFontsFontSizeTextItem>): ParsedParagraphFontsFontSize | undefined | null => f && f.length > 0 && entity_reference_revisions_paragraph_with_target_paragraph_cta_with_image_field_fonts_font_size_text_item_guard(f[0]) ? entity_reference_revisions_paragraph_with_target_paragraph_cta_with_image_field_fonts_font_size_text_item_parser(f[0]) : null

export const entity_reference_revisions_paragraph_with_target_paragraph_cta_with_image_field_fonts_font_size_title_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphCtaWithImageFieldFontsFontSizeTitleItem): ParsedParagraphFontsFontSize | undefined | undefined => (t.target !== undefined ? ((t: ParagraphFontsFontSize): ParsedParagraphFontsFontSize =>
    paragraph_fonts_font_size_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_cta_with_image_field_fonts_font_size_title_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphCtaWithImageFieldFontsFontSizeTitleItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_cta_with_image_field_fonts_font_size_title_parser = (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphCtaWithImageFieldFontsFontSizeTitleItem>): ParsedParagraphFontsFontSize | undefined | null => f && f.length > 0 && entity_reference_revisions_paragraph_with_target_paragraph_cta_with_image_field_fonts_font_size_title_item_guard(f[0]) ? entity_reference_revisions_paragraph_with_target_paragraph_cta_with_image_field_fonts_font_size_title_item_parser(f[0]) : null

export const entity_reference_media_with_target_paragraph_cta_with_image_field_image_item_parser = (t: EntityReferenceMediaWithTargetParagraphCtaWithImageFieldImageItem): ParsedMediaImage | undefined | undefined => (t.target !== undefined ? ((t: MediaImage): ParsedMediaImage =>
    media_image_parser(t))(t.target) : undefined)

export const entity_reference_media_with_target_paragraph_cta_with_image_field_image_item_guard = (t: any): t is EntityReferenceMediaWithTargetParagraphCtaWithImageFieldImageItem => (t.target_id !== undefined)

export const singular_required_entity_reference_media_with_target_paragraph_cta_with_image_field_image_parser = (f: FieldItemList<EntityReferenceMediaWithTargetParagraphCtaWithImageFieldImageItem>): ParsedMediaImage | undefined => entity_reference_media_with_target_paragraph_cta_with_image_field_image_item_parser(f[0])

export const paragraph_cta_with_image_parser = (t: ParagraphCtaWithImage): ParsedParagraphCtaWithImage => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "cta_with_image" => "cta_with_image")(t.type),
  field_body: singular_optional_text_long_parser(t.field_body),
  field_campaign_title: singular_required_text_parser(t.field_campaign_title),
  field_direction: singular_required_list_string_parser(t.field_direction),
  field_fonts_font_size_text: singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_cta_with_image_field_fonts_font_size_text_parser(t.field_fonts_font_size_text),
  field_fonts_font_size_title: singular_optional_entity_reference_revisions_paragraph_with_target_paragraph_cta_with_image_field_fonts_font_size_title_parser(t.field_fonts_font_size_title),
  field_image: singular_required_entity_reference_media_with_target_paragraph_cta_with_image_field_image_parser(t.field_image),
  field_links: plural_link_parser(t.field_links),
  field_width: singular_optional_boolean_parser(t.field_width)
})

export const paragraph_cta_with_image_guard = (t: Paragraph): t is ParagraphCtaWithImage => paragraph_bundle_parser(t.type) == "cta_with_image"

export const entity_reference_media_with_target_paragraph_downloads_field_downloads_item_parser = (t: EntityReferenceMediaWithTargetParagraphDownloadsFieldDownloadsItem): ParsedMediaDocument | undefined | undefined => (t.target !== undefined ? ((t: MediaDocument): ParsedMediaDocument =>
    media_document_parser(t))(t.target) : undefined)

export const entity_reference_media_with_target_paragraph_downloads_field_downloads_item_guard = (t: any): t is EntityReferenceMediaWithTargetParagraphDownloadsFieldDownloadsItem => (t.target_id !== undefined)

export const plural_entity_reference_media_with_target_paragraph_downloads_field_downloads_parser =
  (f: FieldItemList<EntityReferenceMediaWithTargetParagraphDownloadsFieldDownloadsItem>): List<ParsedMediaDocument> =>
    List<ParsedMediaDocument>(f.filter(i => entity_reference_media_with_target_paragraph_downloads_field_downloads_item_guard(i)).map(i => entity_reference_media_with_target_paragraph_downloads_field_downloads_item_parser(i)).filter(i => i !== undefined))

export const paragraph_downloads_parser = (t: ParagraphDownloads): ParsedParagraphDownloads => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "downloads" => "downloads")(t.type),
  field_downloads: plural_entity_reference_media_with_target_paragraph_downloads_field_downloads_parser(t.field_downloads),
  field_introduction: singular_optional_string_long_parser(t.field_introduction),
  field_title: singular_optional_string_parser(t.field_title)
})

export const paragraph_downloads_guard = (t: Paragraph): t is ParagraphDownloads => paragraph_bundle_parser(t.type) == "downloads"

export const paragraph_embed_parser = (t: ParagraphEmbed): ParsedParagraphEmbed => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "embed" => "embed")(t.type),
  field_embed_html: singular_optional_string_long_parser(t.field_embed_html)
})

export const paragraph_embed_guard = (t: Paragraph): t is ParagraphEmbed => paragraph_bundle_parser(t.type) == "embed"

export const paragraph_expandable_content_block_parser = (t: ParagraphExpandableContentBlock): ParsedParagraphExpandableContentBlock => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "expandable_content_block" => "expandable_content_block")(t.type),
  field_body: singular_required_text_long_parser(t.field_body),
  field_title: singular_required_string_parser(t.field_title)
})

export const paragraph_expandable_content_block_guard = (t: Paragraph): t is ParagraphExpandableContentBlock => paragraph_bundle_parser(t.type) == "expandable_content_block"

export const entity_reference_revisions_paragraph_with_target_paragraph_expandable_content_blocks_field_expandable_content_blocks_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphExpandableContentBlocksFieldExpandableContentBlocksItem): ParsedParagraphExpandableContentBlock | undefined | undefined => (t.target !== undefined ? ((t: ParagraphExpandableContentBlock): ParsedParagraphExpandableContentBlock =>
    paragraph_expandable_content_block_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_expandable_content_blocks_field_expandable_content_blocks_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphExpandableContentBlocksFieldExpandableContentBlocksItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const plural_entity_reference_revisions_paragraph_with_target_paragraph_expandable_content_blocks_field_expandable_content_blocks_parser =
  (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphExpandableContentBlocksFieldExpandableContentBlocksItem>): List<ParsedParagraphExpandableContentBlock> =>
    List<ParsedParagraphExpandableContentBlock>(f.filter(i => entity_reference_revisions_paragraph_with_target_paragraph_expandable_content_blocks_field_expandable_content_blocks_item_guard(i)).map(i => entity_reference_revisions_paragraph_with_target_paragraph_expandable_content_blocks_field_expandable_content_blocks_item_parser(i)).filter(i => i !== undefined))

export const paragraph_expandable_content_blocks_parser = (t: ParagraphExpandableContentBlocks): ParsedParagraphExpandableContentBlocks => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "expandable_content_blocks" => "expandable_content_blocks")(t.type),
  field_expandable_content_blocks: plural_entity_reference_revisions_paragraph_with_target_paragraph_expandable_content_blocks_field_expandable_content_blocks_parser(t.field_expandable_content_blocks),
  field_introduction: singular_optional_string_long_parser(t.field_introduction),
  field_link: singular_optional_link_parser(t.field_link),
  field_title: singular_optional_string_parser(t.field_title)
})

export const paragraph_expandable_content_blocks_guard = (t: Paragraph): t is ParagraphExpandableContentBlocks => paragraph_bundle_parser(t.type) == "expandable_content_blocks"

export const entity_reference_media_with_target_paragraph_featured_content_field_image_item_parser = (t: EntityReferenceMediaWithTargetParagraphFeaturedContentFieldImageItem): ParsedMediaImage | undefined | undefined => (t.target !== undefined ? ((t: MediaImage): ParsedMediaImage =>
    media_image_parser(t))(t.target) : undefined)

export const entity_reference_media_with_target_paragraph_featured_content_field_image_item_guard = (t: any): t is EntityReferenceMediaWithTargetParagraphFeaturedContentFieldImageItem => (t.target_id !== undefined)

export const singular_required_entity_reference_media_with_target_paragraph_featured_content_field_image_parser = (f: FieldItemList<EntityReferenceMediaWithTargetParagraphFeaturedContentFieldImageItem>): ParsedMediaImage | undefined => entity_reference_media_with_target_paragraph_featured_content_field_image_item_parser(f[0])

export const paragraph_featured_content_parser = (t: ParagraphFeaturedContent): ParsedParagraphFeaturedContent => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "featured_content" => "featured_content")(t.type),
  field_featured_content_direction: singular_required_list_string_parser(t.field_featured_content_direction),
  field_image: singular_required_entity_reference_media_with_target_paragraph_featured_content_field_image_parser(t.field_image),
  field_introduction: singular_required_string_long_parser(t.field_introduction),
  field_links: plural_link_parser(t.field_links),
  field_title: singular_required_string_parser(t.field_title)
})

export const paragraph_featured_content_guard = (t: Paragraph): t is ParagraphFeaturedContent => paragraph_bundle_parser(t.type) == "featured_content"

export const singular_optional_list_string_parser = (f: FieldItemList<ListStringItem>): string | null => f && f.length > 0 ? list_string_item_parser(f[0]) : null

export const paragraph_fonts_font_size_parser = (t: ParagraphFontsFontSize): ParsedParagraphFontsFontSize => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "fonts_font_size" => "fonts_font_size")(t.type),
  field_font: singular_optional_list_string_parser(t.field_font),
  field_size: singular_optional_list_string_parser(t.field_size)
})

export const paragraph_fonts_font_size_guard = (t: Paragraph): t is ParagraphFontsFontSize => paragraph_bundle_parser(t.type) == "fonts_font_size"

export const entity_reference_media_with_target_paragraph_grid_block_image_field_image_item_parser = (t: EntityReferenceMediaWithTargetParagraphGridBlockImageFieldImageItem): ParsedMediaImage | undefined | undefined => (t.target !== undefined ? ((t: MediaImage): ParsedMediaImage =>
    media_image_parser(t))(t.target) : undefined)

export const entity_reference_media_with_target_paragraph_grid_block_image_field_image_item_guard = (t: any): t is EntityReferenceMediaWithTargetParagraphGridBlockImageFieldImageItem => (t.target_id !== undefined)

export const singular_required_entity_reference_media_with_target_paragraph_grid_block_image_field_image_parser = (f: FieldItemList<EntityReferenceMediaWithTargetParagraphGridBlockImageFieldImageItem>): ParsedMediaImage | undefined => entity_reference_media_with_target_paragraph_grid_block_image_field_image_item_parser(f[0])

export const paragraph_grid_block_image_parser = (t: ParagraphGridBlockImage): ParsedParagraphGridBlockImage => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "grid_block_image" => "grid_block_image")(t.type),
  field_image: singular_required_entity_reference_media_with_target_paragraph_grid_block_image_field_image_parser(t.field_image),
  field_introduction: singular_required_string_long_parser(t.field_introduction),
  field_link: singular_optional_link_parser(t.field_link),
  field_title: singular_required_string_parser(t.field_title)
})

export const paragraph_grid_block_image_guard = (t: Paragraph): t is ParagraphGridBlockImage => paragraph_bundle_parser(t.type) == "grid_block_image"

export const paragraph_grid_block_plain_parser = (t: ParagraphGridBlockPlain): ParsedParagraphGridBlockPlain => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "grid_block_plain" => "grid_block_plain")(t.type),
  field_introduction: singular_required_string_long_parser(t.field_introduction),
  field_link: singular_optional_link_parser(t.field_link),
  field_title: singular_required_string_parser(t.field_title)
})

export const paragraph_grid_block_plain_guard = (t: Paragraph): t is ParagraphGridBlockPlain => paragraph_bundle_parser(t.type) == "grid_block_plain"

export const entity_reference_revisions_paragraph_with_target_paragraph_grid_blocks_image_field_grid_blocks_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphGridBlocksImageFieldGridBlocksItem): ParsedParagraphGridBlockImage | undefined | undefined => (t.target !== undefined ? ((t: ParagraphGridBlockImage): ParsedParagraphGridBlockImage =>
    paragraph_grid_block_image_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_grid_blocks_image_field_grid_blocks_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphGridBlocksImageFieldGridBlocksItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const plural_entity_reference_revisions_paragraph_with_target_paragraph_grid_blocks_image_field_grid_blocks_parser =
  (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphGridBlocksImageFieldGridBlocksItem>): List<ParsedParagraphGridBlockImage> =>
    List<ParsedParagraphGridBlockImage>(f.filter(i => entity_reference_revisions_paragraph_with_target_paragraph_grid_blocks_image_field_grid_blocks_item_guard(i)).map(i => entity_reference_revisions_paragraph_with_target_paragraph_grid_blocks_image_field_grid_blocks_item_parser(i)).filter(i => i !== undefined))

export const paragraph_grid_blocks_image_parser = (t: ParagraphGridBlocksImage): ParsedParagraphGridBlocksImage => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "grid_blocks_image" => "grid_blocks_image")(t.type),
  field_grid_blocks: plural_entity_reference_revisions_paragraph_with_target_paragraph_grid_blocks_image_field_grid_blocks_parser(t.field_grid_blocks),
  field_introduction: singular_optional_string_long_parser(t.field_introduction),
  field_link: singular_optional_link_parser(t.field_link),
  field_title: singular_optional_string_parser(t.field_title)
})

export const paragraph_grid_blocks_image_guard = (t: Paragraph): t is ParagraphGridBlocksImage => paragraph_bundle_parser(t.type) == "grid_blocks_image"

export const entity_reference_revisions_paragraph_with_target_paragraph_grid_blocks_plain_field_grid_blocks_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphGridBlocksPlainFieldGridBlocksItem): ParsedParagraphGridBlockPlain | undefined | undefined => (t.target !== undefined ? ((t: ParagraphGridBlockPlain): ParsedParagraphGridBlockPlain =>
    paragraph_grid_block_plain_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_grid_blocks_plain_field_grid_blocks_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphGridBlocksPlainFieldGridBlocksItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const plural_entity_reference_revisions_paragraph_with_target_paragraph_grid_blocks_plain_field_grid_blocks_parser =
  (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphGridBlocksPlainFieldGridBlocksItem>): List<ParsedParagraphGridBlockPlain> =>
    List<ParsedParagraphGridBlockPlain>(f.filter(i => entity_reference_revisions_paragraph_with_target_paragraph_grid_blocks_plain_field_grid_blocks_item_guard(i)).map(i => entity_reference_revisions_paragraph_with_target_paragraph_grid_blocks_plain_field_grid_blocks_item_parser(i)).filter(i => i !== undefined))

export const paragraph_grid_blocks_plain_parser = (t: ParagraphGridBlocksPlain): ParsedParagraphGridBlocksPlain => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "grid_blocks_plain" => "grid_blocks_plain")(t.type),
  field_grid_blocks: plural_entity_reference_revisions_paragraph_with_target_paragraph_grid_blocks_plain_field_grid_blocks_parser(t.field_grid_blocks),
  field_introduction: singular_optional_string_long_parser(t.field_introduction),
  field_link: singular_optional_link_parser(t.field_link),
  field_title: singular_optional_string_parser(t.field_title)
})

export const paragraph_grid_blocks_plain_guard = (t: Paragraph): t is ParagraphGridBlocksPlain => paragraph_bundle_parser(t.type) == "grid_blocks_plain"

export const singular_required_link_parser = (f: FieldItemList<LinkItem>): ParsedLinkItem => link_item_parser(f[0])

export const paragraph_iframe_parser = (t: ParagraphIframe): ParsedParagraphIframe => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "iframe" => "iframe")(t.type),
  field_height: singular_optional_integer_parser(t.field_height),
  field_link: singular_required_link_parser(t.field_link)
})

export const paragraph_iframe_guard = (t: Paragraph): t is ParagraphIframe => paragraph_bundle_parser(t.type) == "iframe"

export const paragraph_links_list_parser = (t: ParagraphLinksList): ParsedParagraphLinksList => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "links_list" => "links_list")(t.type),
  field_introduction: singular_optional_string_long_parser(t.field_introduction),
  field_links_list: plural_link_parser(t.field_links_list),
  field_title: singular_required_string_parser(t.field_title)
})

export const paragraph_links_list_guard = (t: Paragraph): t is ParagraphLinksList => paragraph_bundle_parser(t.type) == "links_list"

export const paragraph_map_parser = (t: ParagraphMap): ParsedParagraphMap => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "map" => "map")(t.type)
})

export const paragraph_map_guard = (t: Paragraph): t is ParagraphMap => paragraph_bundle_parser(t.type) == "map"

export const entity_reference_media_with_target_paragraph_media_field_media_item_parser = (t: EntityReferenceMediaWithTargetParagraphMediaFieldMediaItem): ParsedMediaImage | ParsedMediaVideo | undefined | undefined => (t.target !== undefined ? ((t: MediaImage | MediaVideo): ParsedMediaImage | ParsedMediaVideo =>
  media_image_guard(t) ?
    media_image_parser(t) :
    media_video_parser(t))(t.target) : undefined)

export const entity_reference_media_with_target_paragraph_media_field_media_item_guard = (t: any): t is EntityReferenceMediaWithTargetParagraphMediaFieldMediaItem => (t.target_id !== undefined)

export const singular_required_entity_reference_media_with_target_paragraph_media_field_media_parser = (f: FieldItemList<EntityReferenceMediaWithTargetParagraphMediaFieldMediaItem>): ParsedMediaImage | ParsedMediaVideo | undefined => entity_reference_media_with_target_paragraph_media_field_media_item_parser(f[0])

export const paragraph_media_parser = (t: ParagraphMedia): ParsedParagraphMedia => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "media" => "media")(t.type),
  field_caption: singular_optional_string_parser(t.field_caption),
  field_media: singular_required_entity_reference_media_with_target_paragraph_media_field_media_parser(t.field_media)
})

export const paragraph_media_guard = (t: Paragraph): t is ParagraphMedia => paragraph_bundle_parser(t.type) == "media"

export const entity_reference_revisions_paragraph_with_target_paragraph_menu_field_menu_groups_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphMenuFieldMenuGroupsItem): ParsedParagraphMenuGroup | undefined | undefined => (t.target !== undefined ? ((t: ParagraphMenuGroup): ParsedParagraphMenuGroup =>
    paragraph_menu_group_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_menu_field_menu_groups_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphMenuFieldMenuGroupsItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const plural_entity_reference_revisions_paragraph_with_target_paragraph_menu_field_menu_groups_parser =
  (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphMenuFieldMenuGroupsItem>): List<ParsedParagraphMenuGroup> =>
    List<ParsedParagraphMenuGroup>(f.filter(i => entity_reference_revisions_paragraph_with_target_paragraph_menu_field_menu_groups_item_guard(i)).map(i => entity_reference_revisions_paragraph_with_target_paragraph_menu_field_menu_groups_item_parser(i)).filter(i => i !== undefined))

export const paragraph_menu_parser = (t: ParagraphMenu): ParsedParagraphMenu => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "menu" => "menu")(t.type),
  field_menu_groups: plural_entity_reference_revisions_paragraph_with_target_paragraph_menu_field_menu_groups_parser(t.field_menu_groups)
})

export const paragraph_menu_guard = (t: Paragraph): t is ParagraphMenu => paragraph_bundle_parser(t.type) == "menu"

export const entity_reference_revisions_paragraph_with_target_paragraph_menu_group_field_menu_items_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetParagraphMenuGroupFieldMenuItemsItem): ParsedParagraphMenuProduct | ParsedParagraphMenuImage | undefined | undefined => (t.target !== undefined ? ((t: ParagraphMenuProduct | ParagraphMenuImage): ParsedParagraphMenuProduct | ParsedParagraphMenuImage =>
  paragraph_menu_product_guard(t) ?
    paragraph_menu_product_parser(t) :
    paragraph_menu_image_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_paragraph_menu_group_field_menu_items_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetParagraphMenuGroupFieldMenuItemsItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const plural_entity_reference_revisions_paragraph_with_target_paragraph_menu_group_field_menu_items_parser =
  (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetParagraphMenuGroupFieldMenuItemsItem>): List<ParsedParagraphMenuProduct | ParsedParagraphMenuImage> =>
    List<ParsedParagraphMenuProduct | ParsedParagraphMenuImage>(f.filter(i => entity_reference_revisions_paragraph_with_target_paragraph_menu_group_field_menu_items_item_guard(i)).map(i => entity_reference_revisions_paragraph_with_target_paragraph_menu_group_field_menu_items_item_parser(i)).filter(i => i !== undefined))

export const paragraph_menu_group_parser = (t: ParagraphMenuGroup): ParsedParagraphMenuGroup => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "menu_group" => "menu_group")(t.type),
  field_menu_items: plural_entity_reference_revisions_paragraph_with_target_paragraph_menu_group_field_menu_items_parser(t.field_menu_items),
  field_title: singular_required_string_parser(t.field_title)
})

export const paragraph_menu_group_guard = (t: Paragraph): t is ParagraphMenuGroup => paragraph_bundle_parser(t.type) == "menu_group"

export const entity_reference_media_with_target_paragraph_menu_image_field_image_item_parser = (t: EntityReferenceMediaWithTargetParagraphMenuImageFieldImageItem): ParsedMediaImage | undefined | undefined => (t.target !== undefined ? ((t: MediaImage): ParsedMediaImage =>
    media_image_parser(t))(t.target) : undefined)

export const entity_reference_media_with_target_paragraph_menu_image_field_image_item_guard = (t: any): t is EntityReferenceMediaWithTargetParagraphMenuImageFieldImageItem => (t.target_id !== undefined)

export const singular_required_entity_reference_media_with_target_paragraph_menu_image_field_image_parser = (f: FieldItemList<EntityReferenceMediaWithTargetParagraphMenuImageFieldImageItem>): ParsedMediaImage | undefined => entity_reference_media_with_target_paragraph_menu_image_field_image_item_parser(f[0])

export const paragraph_menu_image_parser = (t: ParagraphMenuImage): ParsedParagraphMenuImage => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "menu_image" => "menu_image")(t.type),
  field_image: singular_required_entity_reference_media_with_target_paragraph_menu_image_field_image_parser(t.field_image)
})

export const paragraph_menu_image_guard = (t: Paragraph): t is ParagraphMenuImage => paragraph_bundle_parser(t.type) == "menu_image"

export const entity_reference_media_with_target_paragraph_menu_product_field_image_item_parser = (t: EntityReferenceMediaWithTargetParagraphMenuProductFieldImageItem): ParsedMediaImage | undefined | undefined => (t.target !== undefined ? ((t: MediaImage): ParsedMediaImage =>
    media_image_parser(t))(t.target) : undefined)

export const entity_reference_media_with_target_paragraph_menu_product_field_image_item_guard = (t: any): t is EntityReferenceMediaWithTargetParagraphMenuProductFieldImageItem => (t.target_id !== undefined)

export const singular_optional_entity_reference_media_with_target_paragraph_menu_product_field_image_parser = (f: FieldItemList<EntityReferenceMediaWithTargetParagraphMenuProductFieldImageItem>): ParsedMediaImage | undefined | null => f && f.length > 0 && entity_reference_media_with_target_paragraph_menu_product_field_image_item_guard(f[0]) ? entity_reference_media_with_target_paragraph_menu_product_field_image_item_parser(f[0]) : null

export const decimal_item_parser = (t: DecimalItem): string => noop_parser<string>(t.value)

export const singular_optional_decimal_parser = (f: FieldItemList<DecimalItem>): string | null => f && f.length > 0 ? decimal_item_parser(f[0]) : null

export const paragraph_menu_product_parser = (t: ParagraphMenuProduct): ParsedParagraphMenuProduct => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "menu_product" => "menu_product")(t.type),
  field_allergens: singular_optional_string_parser(t.field_allergens),
  field_image: singular_optional_entity_reference_media_with_target_paragraph_menu_product_field_image_parser(t.field_image),
  field_price: singular_optional_decimal_parser(t.field_price),
  field_text: singular_required_string_long_parser(t.field_text),
  field_title: singular_required_string_parser(t.field_title)
})

export const paragraph_menu_product_guard = (t: Paragraph): t is ParagraphMenuProduct => paragraph_bundle_parser(t.type) == "menu_product"

export const entity_reference_media_with_target_paragraph_quote_field_image_item_parser = (t: EntityReferenceMediaWithTargetParagraphQuoteFieldImageItem): ParsedMediaImage | undefined | undefined => (t.target !== undefined ? ((t: MediaImage): ParsedMediaImage =>
    media_image_parser(t))(t.target) : undefined)

export const entity_reference_media_with_target_paragraph_quote_field_image_item_guard = (t: any): t is EntityReferenceMediaWithTargetParagraphQuoteFieldImageItem => (t.target_id !== undefined)

export const singular_optional_entity_reference_media_with_target_paragraph_quote_field_image_parser = (f: FieldItemList<EntityReferenceMediaWithTargetParagraphQuoteFieldImageItem>): ParsedMediaImage | undefined | null => f && f.length > 0 && entity_reference_media_with_target_paragraph_quote_field_image_item_guard(f[0]) ? entity_reference_media_with_target_paragraph_quote_field_image_item_parser(f[0]) : null

export const paragraph_quote_parser = (t: ParagraphQuote): ParsedParagraphQuote => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "quote" => "quote")(t.type),
  field_author_name: singular_optional_string_parser(t.field_author_name),
  field_image: singular_optional_entity_reference_media_with_target_paragraph_quote_field_image_parser(t.field_image),
  field_link: singular_optional_link_parser(t.field_link),
  field_text: singular_required_string_long_parser(t.field_text)
})

export const paragraph_quote_guard = (t: Paragraph): t is ParagraphQuote => paragraph_bundle_parser(t.type) == "quote"

export const paragraph_restaurant_list_parser = (t: ParagraphRestaurantList): ParsedParagraphRestaurantList => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "restaurant_list" => "restaurant_list")(t.type)
})

export const paragraph_restaurant_list_guard = (t: Paragraph): t is ParagraphRestaurantList => paragraph_bundle_parser(t.type) == "restaurant_list"

export const paragraph_text_parser = (t: ParagraphText): ParsedParagraphText => ({
  ...paragraph_base_parser(t),
  bundle: ((_: any): "text" => "text")(t.type),
  field_body: singular_required_text_long_parser(t.field_body),
  field_links: plural_link_parser(t.field_links),
  field_title: singular_optional_string_parser(t.field_title)
})

export const paragraph_text_guard = (t: Paragraph): t is ParagraphText => paragraph_bundle_parser(t.type) == "text"

export const paragraph_parser = (t: Paragraph): ParsedParagraph =>
  paragraph_banner_guard(t) ?
    paragraph_banner_parser(t) :
  paragraph_campaign_cta_guard(t) ?
    paragraph_campaign_cta_parser(t) :
  paragraph_campaign_grid_guard(t) ?
    paragraph_campaign_grid_parser(t) :
  paragraph_campaign_grid_block_image_guard(t) ?
    paragraph_campaign_grid_block_image_parser(t) :
  paragraph_campaign_media_guard(t) ?
    paragraph_campaign_media_parser(t) :
  paragraph_campaign_text_guard(t) ?
    paragraph_campaign_text_parser(t) :
  paragraph_campaign_uitklapper_guard(t) ?
    paragraph_campaign_uitklapper_parser(t) :
  paragraph_campaign_uitklappers_guard(t) ?
    paragraph_campaign_uitklappers_parser(t) :
  paragraph_cta_guard(t) ?
    paragraph_cta_parser(t) :
  paragraph_cta_with_image_guard(t) ?
    paragraph_cta_with_image_parser(t) :
  paragraph_downloads_guard(t) ?
    paragraph_downloads_parser(t) :
  paragraph_embed_guard(t) ?
    paragraph_embed_parser(t) :
  paragraph_expandable_content_block_guard(t) ?
    paragraph_expandable_content_block_parser(t) :
  paragraph_expandable_content_blocks_guard(t) ?
    paragraph_expandable_content_blocks_parser(t) :
  paragraph_featured_content_guard(t) ?
    paragraph_featured_content_parser(t) :
  paragraph_fonts_font_size_guard(t) ?
    paragraph_fonts_font_size_parser(t) :
  paragraph_grid_block_image_guard(t) ?
    paragraph_grid_block_image_parser(t) :
  paragraph_grid_block_plain_guard(t) ?
    paragraph_grid_block_plain_parser(t) :
  paragraph_grid_blocks_image_guard(t) ?
    paragraph_grid_blocks_image_parser(t) :
  paragraph_grid_blocks_plain_guard(t) ?
    paragraph_grid_blocks_plain_parser(t) :
  paragraph_iframe_guard(t) ?
    paragraph_iframe_parser(t) :
  paragraph_links_list_guard(t) ?
    paragraph_links_list_parser(t) :
  paragraph_map_guard(t) ?
    paragraph_map_parser(t) :
  paragraph_media_guard(t) ?
    paragraph_media_parser(t) :
  paragraph_menu_guard(t) ?
    paragraph_menu_parser(t) :
  paragraph_menu_group_guard(t) ?
    paragraph_menu_group_parser(t) :
  paragraph_menu_image_guard(t) ?
    paragraph_menu_image_parser(t) :
  paragraph_menu_product_guard(t) ?
    paragraph_menu_product_parser(t) :
  paragraph_quote_guard(t) ?
    paragraph_quote_parser(t) :
  paragraph_restaurant_list_guard(t) ?
    paragraph_restaurant_list_parser(t) :
    paragraph_text_parser(t)

export const paragraph_guard = (t: Entity): t is Paragraph => (
  (t as any).id !== undefined &&
  (t as any).uuid !== undefined &&
  (t as any).revision_id !== undefined &&
  (t as any).langcode !== undefined &&
  (t as any).type !== undefined &&
  (t as any).type[0].target_type == "paragraphs_type" &&
  (t as any).status !== undefined &&
  (t as any).created !== undefined &&
  (t as any).parent_id !== undefined &&
  (t as any).parent_type !== undefined &&
  (t as any).parent_field_name !== undefined &&
  (t as any).behavior_settings !== undefined &&
  (t as any).default_langcode !== undefined &&
  (t as any).revision_translation_affected !== undefined
)

export const entity_reference_revisions_paragraph_with_target_node_campaign_field_header_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetNodeCampaignFieldHeaderItem): ParsedParagraphBanner | undefined | undefined => (t.target !== undefined ? ((t: ParagraphBanner): ParsedParagraphBanner =>
    paragraph_banner_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_node_campaign_field_header_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetNodeCampaignFieldHeaderItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const plural_entity_reference_revisions_paragraph_with_target_node_campaign_field_header_parser =
  (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetNodeCampaignFieldHeaderItem>): List<ParsedParagraphBanner> =>
    List<ParsedParagraphBanner>(f.filter(i => entity_reference_revisions_paragraph_with_target_node_campaign_field_header_item_guard(i)).map(i => entity_reference_revisions_paragraph_with_target_node_campaign_field_header_item_parser(i)).filter(i => i !== undefined))

export const metatag_item_parser = (t: MetatagItem): Metatags => t.value

export const singular_optional_metatag_parser = (f: FieldItemList<MetatagItem>): Metatags | null => f && f.length > 0 ? metatag_item_parser(f[0]) : null

export const entity_reference_revisions_paragraph_with_target_node_campaign_field_page_content_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetNodeCampaignFieldPageContentItem): ParsedParagraphCampaignCta | ParsedParagraphCampaignGrid | ParsedParagraphCampaignMedia | ParsedParagraphCtaWithImage | ParsedParagraphCampaignUitklappers | ParsedParagraphCampaignText | undefined | undefined => (t.target !== undefined ? ((t: ParagraphCampaignCta | ParagraphCampaignGrid | ParagraphCampaignMedia | ParagraphCtaWithImage | ParagraphCampaignUitklappers | ParagraphCampaignText): ParsedParagraphCampaignCta | ParsedParagraphCampaignGrid | ParsedParagraphCampaignMedia | ParsedParagraphCtaWithImage | ParsedParagraphCampaignUitklappers | ParsedParagraphCampaignText =>
  paragraph_campaign_cta_guard(t) ?
    paragraph_campaign_cta_parser(t) :
  paragraph_campaign_grid_guard(t) ?
    paragraph_campaign_grid_parser(t) :
  paragraph_campaign_media_guard(t) ?
    paragraph_campaign_media_parser(t) :
  paragraph_cta_with_image_guard(t) ?
    paragraph_cta_with_image_parser(t) :
  paragraph_campaign_uitklappers_guard(t) ?
    paragraph_campaign_uitklappers_parser(t) :
    paragraph_campaign_text_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_node_campaign_field_page_content_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetNodeCampaignFieldPageContentItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const plural_entity_reference_revisions_paragraph_with_target_node_campaign_field_page_content_parser =
  (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetNodeCampaignFieldPageContentItem>): List<ParsedParagraphCampaignCta | ParsedParagraphCampaignGrid | ParsedParagraphCampaignMedia | ParsedParagraphCtaWithImage | ParsedParagraphCampaignUitklappers | ParsedParagraphCampaignText> =>
    List<ParsedParagraphCampaignCta | ParsedParagraphCampaignGrid | ParsedParagraphCampaignMedia | ParsedParagraphCtaWithImage | ParsedParagraphCampaignUitklappers | ParsedParagraphCampaignText>(f.filter(i => entity_reference_revisions_paragraph_with_target_node_campaign_field_page_content_item_guard(i)).map(i => entity_reference_revisions_paragraph_with_target_node_campaign_field_page_content_item_parser(i)).filter(i => i !== undefined))

export const node_campaign_parser = (t: NodeCampaign): ParsedNodeCampaign => ({
  ...node_base_parser(t),
  bundle: ((_: any): "campaign" => "campaign")(t.type),
  field_countdown: singular_optional_datetime_parser(t.field_countdown),
  field_countdown_start: singular_optional_datetime_parser(t.field_countdown_start),
  field_header: plural_entity_reference_revisions_paragraph_with_target_node_campaign_field_header_parser(t.field_header),
  field_metatags: singular_optional_metatag_parser(t.field_metatags),
  field_page_content: plural_entity_reference_revisions_paragraph_with_target_node_campaign_field_page_content_parser(t.field_page_content),
  field_show_buttons: singular_optional_boolean_parser(t.field_show_buttons)
})

export const node_campaign_guard = (t: Node): t is NodeCampaign => node_bundle_parser(t.type) == "campaign"

export const entity_reference_revisions_paragraph_with_target_node_homepage_field_header_single_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetNodeHomepageFieldHeaderSingleItem): ParsedParagraphBanner | undefined | undefined => (t.target !== undefined ? ((t: ParagraphBanner): ParsedParagraphBanner =>
    paragraph_banner_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_node_homepage_field_header_single_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetNodeHomepageFieldHeaderSingleItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const singular_optional_entity_reference_revisions_paragraph_with_target_node_homepage_field_header_single_parser = (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetNodeHomepageFieldHeaderSingleItem>): ParsedParagraphBanner | undefined | null => f && f.length > 0 && entity_reference_revisions_paragraph_with_target_node_homepage_field_header_single_item_guard(f[0]) ? entity_reference_revisions_paragraph_with_target_node_homepage_field_header_single_item_parser(f[0]) : null

export const entity_reference_revisions_paragraph_with_target_node_homepage_field_page_content_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetNodeHomepageFieldPageContentItem): ParsedParagraphText | ParsedParagraphFeaturedContent | ParsedParagraphQuote | ParsedParagraphCta | ParsedParagraphMedia | ParsedParagraphDownloads | ParsedParagraphEmbed | ParsedParagraphLinksList | ParsedParagraphExpandableContentBlocks | ParsedParagraphGridBlocksPlain | ParsedParagraphGridBlocksImage | ParsedParagraphIframe | undefined | undefined => (t.target !== undefined ? ((t: ParagraphText | ParagraphFeaturedContent | ParagraphQuote | ParagraphCta | ParagraphMedia | ParagraphDownloads | ParagraphEmbed | ParagraphLinksList | ParagraphExpandableContentBlocks | ParagraphGridBlocksPlain | ParagraphGridBlocksImage | ParagraphIframe): ParsedParagraphText | ParsedParagraphFeaturedContent | ParsedParagraphQuote | ParsedParagraphCta | ParsedParagraphMedia | ParsedParagraphDownloads | ParsedParagraphEmbed | ParsedParagraphLinksList | ParsedParagraphExpandableContentBlocks | ParsedParagraphGridBlocksPlain | ParsedParagraphGridBlocksImage | ParsedParagraphIframe =>
  paragraph_text_guard(t) ?
    paragraph_text_parser(t) :
  paragraph_featured_content_guard(t) ?
    paragraph_featured_content_parser(t) :
  paragraph_quote_guard(t) ?
    paragraph_quote_parser(t) :
  paragraph_cta_guard(t) ?
    paragraph_cta_parser(t) :
  paragraph_media_guard(t) ?
    paragraph_media_parser(t) :
  paragraph_downloads_guard(t) ?
    paragraph_downloads_parser(t) :
  paragraph_embed_guard(t) ?
    paragraph_embed_parser(t) :
  paragraph_links_list_guard(t) ?
    paragraph_links_list_parser(t) :
  paragraph_expandable_content_blocks_guard(t) ?
    paragraph_expandable_content_blocks_parser(t) :
  paragraph_grid_blocks_plain_guard(t) ?
    paragraph_grid_blocks_plain_parser(t) :
  paragraph_grid_blocks_image_guard(t) ?
    paragraph_grid_blocks_image_parser(t) :
    paragraph_iframe_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_node_homepage_field_page_content_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetNodeHomepageFieldPageContentItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const plural_entity_reference_revisions_paragraph_with_target_node_homepage_field_page_content_parser =
  (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetNodeHomepageFieldPageContentItem>): List<ParsedParagraphText | ParsedParagraphFeaturedContent | ParsedParagraphQuote | ParsedParagraphCta | ParsedParagraphMedia | ParsedParagraphDownloads | ParsedParagraphEmbed | ParsedParagraphLinksList | ParsedParagraphExpandableContentBlocks | ParsedParagraphGridBlocksPlain | ParsedParagraphGridBlocksImage | ParsedParagraphIframe> =>
    List<ParsedParagraphText | ParsedParagraphFeaturedContent | ParsedParagraphQuote | ParsedParagraphCta | ParsedParagraphMedia | ParsedParagraphDownloads | ParsedParagraphEmbed | ParsedParagraphLinksList | ParsedParagraphExpandableContentBlocks | ParsedParagraphGridBlocksPlain | ParsedParagraphGridBlocksImage | ParsedParagraphIframe>(f.filter(i => entity_reference_revisions_paragraph_with_target_node_homepage_field_page_content_item_guard(i)).map(i => entity_reference_revisions_paragraph_with_target_node_homepage_field_page_content_item_parser(i)).filter(i => i !== undefined))

export const node_homepage_parser = (t: NodeHomepage): ParsedNodeHomepage => ({
  ...node_base_parser(t),
  bundle: ((_: any): "homepage" => "homepage")(t.type),
  field_header_single: singular_optional_entity_reference_revisions_paragraph_with_target_node_homepage_field_header_single_parser(t.field_header_single),
  field_metatags: singular_optional_metatag_parser(t.field_metatags),
  field_page_content: plural_entity_reference_revisions_paragraph_with_target_node_homepage_field_page_content_parser(t.field_page_content)
})

export const node_homepage_guard = (t: Node): t is NodeHomepage => node_bundle_parser(t.type) == "homepage"

export const entity_reference_media_with_target_node_page_field_introduction_image_item_parser = (t: EntityReferenceMediaWithTargetNodePageFieldIntroductionImageItem): ParsedMediaImage | undefined | undefined => (t.target !== undefined ? ((t: MediaImage): ParsedMediaImage =>
    media_image_parser(t))(t.target) : undefined)

export const entity_reference_media_with_target_node_page_field_introduction_image_item_guard = (t: any): t is EntityReferenceMediaWithTargetNodePageFieldIntroductionImageItem => (t.target_id !== undefined)

export const singular_optional_entity_reference_media_with_target_node_page_field_introduction_image_parser = (f: FieldItemList<EntityReferenceMediaWithTargetNodePageFieldIntroductionImageItem>): ParsedMediaImage | undefined | null => f && f.length > 0 && entity_reference_media_with_target_node_page_field_introduction_image_item_guard(f[0]) ? entity_reference_media_with_target_node_page_field_introduction_image_item_parser(f[0]) : null

export const entity_reference_revisions_paragraph_with_target_node_page_field_page_content_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetNodePageFieldPageContentItem): ParsedParagraphText | ParsedParagraphFeaturedContent | ParsedParagraphQuote | ParsedParagraphCta | ParsedParagraphMedia | ParsedParagraphDownloads | ParsedParagraphEmbed | ParsedParagraphMenu | ParsedParagraphLinksList | ParsedParagraphExpandableContentBlocks | ParsedParagraphGridBlocksPlain | ParsedParagraphGridBlocksImage | ParsedParagraphIframe | undefined | undefined => (t.target !== undefined ? ((t: ParagraphText | ParagraphFeaturedContent | ParagraphQuote | ParagraphCta | ParagraphMedia | ParagraphDownloads | ParagraphEmbed | ParagraphMenu | ParagraphLinksList | ParagraphExpandableContentBlocks | ParagraphGridBlocksPlain | ParagraphGridBlocksImage | ParagraphIframe): ParsedParagraphText | ParsedParagraphFeaturedContent | ParsedParagraphQuote | ParsedParagraphCta | ParsedParagraphMedia | ParsedParagraphDownloads | ParsedParagraphEmbed | ParsedParagraphMenu | ParsedParagraphLinksList | ParsedParagraphExpandableContentBlocks | ParsedParagraphGridBlocksPlain | ParsedParagraphGridBlocksImage | ParsedParagraphIframe =>
  paragraph_text_guard(t) ?
    paragraph_text_parser(t) :
  paragraph_featured_content_guard(t) ?
    paragraph_featured_content_parser(t) :
  paragraph_quote_guard(t) ?
    paragraph_quote_parser(t) :
  paragraph_cta_guard(t) ?
    paragraph_cta_parser(t) :
  paragraph_media_guard(t) ?
    paragraph_media_parser(t) :
  paragraph_downloads_guard(t) ?
    paragraph_downloads_parser(t) :
  paragraph_embed_guard(t) ?
    paragraph_embed_parser(t) :
  paragraph_menu_guard(t) ?
    paragraph_menu_parser(t) :
  paragraph_links_list_guard(t) ?
    paragraph_links_list_parser(t) :
  paragraph_expandable_content_blocks_guard(t) ?
    paragraph_expandable_content_blocks_parser(t) :
  paragraph_grid_blocks_plain_guard(t) ?
    paragraph_grid_blocks_plain_parser(t) :
  paragraph_grid_blocks_image_guard(t) ?
    paragraph_grid_blocks_image_parser(t) :
    paragraph_iframe_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_node_page_field_page_content_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetNodePageFieldPageContentItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const plural_entity_reference_revisions_paragraph_with_target_node_page_field_page_content_parser =
  (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetNodePageFieldPageContentItem>): List<ParsedParagraphText | ParsedParagraphFeaturedContent | ParsedParagraphQuote | ParsedParagraphCta | ParsedParagraphMedia | ParsedParagraphDownloads | ParsedParagraphEmbed | ParsedParagraphMenu | ParsedParagraphLinksList | ParsedParagraphExpandableContentBlocks | ParsedParagraphGridBlocksPlain | ParsedParagraphGridBlocksImage | ParsedParagraphIframe> =>
    List<ParsedParagraphText | ParsedParagraphFeaturedContent | ParsedParagraphQuote | ParsedParagraphCta | ParsedParagraphMedia | ParsedParagraphDownloads | ParsedParagraphEmbed | ParsedParagraphMenu | ParsedParagraphLinksList | ParsedParagraphExpandableContentBlocks | ParsedParagraphGridBlocksPlain | ParsedParagraphGridBlocksImage | ParsedParagraphIframe>(f.filter(i => entity_reference_revisions_paragraph_with_target_node_page_field_page_content_item_guard(i)).map(i => entity_reference_revisions_paragraph_with_target_node_page_field_page_content_item_parser(i)).filter(i => i !== undefined))

export const node_page_parser = (t: NodePage): ParsedNodePage => ({
  ...node_base_parser(t),
  bundle: ((_: any): "page" => "page")(t.type),
  field_introduction: singular_optional_string_long_parser(t.field_introduction),
  field_introduction_image: singular_optional_entity_reference_media_with_target_node_page_field_introduction_image_parser(t.field_introduction_image),
  field_links: plural_link_parser(t.field_links),
  field_metatags: singular_optional_metatag_parser(t.field_metatags),
  field_page_content: plural_entity_reference_revisions_paragraph_with_target_node_page_field_page_content_parser(t.field_page_content)
})

export const node_page_guard = (t: Node): t is NodePage => node_bundle_parser(t.type) == "page"

export const email_item_parser = (t: EmailItem): string => noop_parser<string>(t.value)

export const singular_optional_email_parser = (f: FieldItemList<EmailItem>): string | null => f && f.length > 0 ? email_item_parser(f[0]) : null

export const plural_list_string_parser =
  (f: FieldItemList<ListStringItem>): List<string> =>
    List<string>(f.map(i => list_string_item_parser(i)))

export const entity_reference_media_with_target_node_restaurant_field_introduction_image_item_parser = (t: EntityReferenceMediaWithTargetNodeRestaurantFieldIntroductionImageItem): ParsedMediaImage | undefined | undefined => (t.target !== undefined ? ((t: MediaImage): ParsedMediaImage =>
    media_image_parser(t))(t.target) : undefined)

export const entity_reference_media_with_target_node_restaurant_field_introduction_image_item_guard = (t: any): t is EntityReferenceMediaWithTargetNodeRestaurantFieldIntroductionImageItem => (t.target_id !== undefined)

export const singular_optional_entity_reference_media_with_target_node_restaurant_field_introduction_image_parser = (f: FieldItemList<EntityReferenceMediaWithTargetNodeRestaurantFieldIntroductionImageItem>): ParsedMediaImage | undefined | null => f && f.length > 0 && entity_reference_media_with_target_node_restaurant_field_introduction_image_item_guard(f[0]) ? entity_reference_media_with_target_node_restaurant_field_introduction_image_item_parser(f[0]) : null

export const entity_reference_revisions_paragraph_with_target_node_restaurant_field_page_content_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetNodeRestaurantFieldPageContentItem): ParsedParagraphCta | ParsedParagraphDownloads | ParsedParagraphExpandableContentBlocks | ParsedParagraphFeaturedContent | ParsedParagraphGridBlocksImage | ParsedParagraphGridBlocksPlain | ParsedParagraphIframe | ParsedParagraphLinksList | ParsedParagraphMedia | ParsedParagraphQuote | ParsedParagraphText | undefined | undefined => (t.target !== undefined ? ((t: ParagraphCta | ParagraphDownloads | ParagraphExpandableContentBlocks | ParagraphFeaturedContent | ParagraphGridBlocksImage | ParagraphGridBlocksPlain | ParagraphIframe | ParagraphLinksList | ParagraphMedia | ParagraphQuote | ParagraphText): ParsedParagraphCta | ParsedParagraphDownloads | ParsedParagraphExpandableContentBlocks | ParsedParagraphFeaturedContent | ParsedParagraphGridBlocksImage | ParsedParagraphGridBlocksPlain | ParsedParagraphIframe | ParsedParagraphLinksList | ParsedParagraphMedia | ParsedParagraphQuote | ParsedParagraphText =>
  paragraph_cta_guard(t) ?
    paragraph_cta_parser(t) :
  paragraph_downloads_guard(t) ?
    paragraph_downloads_parser(t) :
  paragraph_expandable_content_blocks_guard(t) ?
    paragraph_expandable_content_blocks_parser(t) :
  paragraph_featured_content_guard(t) ?
    paragraph_featured_content_parser(t) :
  paragraph_grid_blocks_image_guard(t) ?
    paragraph_grid_blocks_image_parser(t) :
  paragraph_grid_blocks_plain_guard(t) ?
    paragraph_grid_blocks_plain_parser(t) :
  paragraph_iframe_guard(t) ?
    paragraph_iframe_parser(t) :
  paragraph_links_list_guard(t) ?
    paragraph_links_list_parser(t) :
  paragraph_media_guard(t) ?
    paragraph_media_parser(t) :
  paragraph_quote_guard(t) ?
    paragraph_quote_parser(t) :
    paragraph_text_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_node_restaurant_field_page_content_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetNodeRestaurantFieldPageContentItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const plural_entity_reference_revisions_paragraph_with_target_node_restaurant_field_page_content_parser =
  (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetNodeRestaurantFieldPageContentItem>): List<ParsedParagraphCta | ParsedParagraphDownloads | ParsedParagraphExpandableContentBlocks | ParsedParagraphFeaturedContent | ParsedParagraphGridBlocksImage | ParsedParagraphGridBlocksPlain | ParsedParagraphIframe | ParsedParagraphLinksList | ParsedParagraphMedia | ParsedParagraphQuote | ParsedParagraphText> =>
    List<ParsedParagraphCta | ParsedParagraphDownloads | ParsedParagraphExpandableContentBlocks | ParsedParagraphFeaturedContent | ParsedParagraphGridBlocksImage | ParsedParagraphGridBlocksPlain | ParsedParagraphIframe | ParsedParagraphLinksList | ParsedParagraphMedia | ParsedParagraphQuote | ParsedParagraphText>(f.filter(i => entity_reference_revisions_paragraph_with_target_node_restaurant_field_page_content_item_guard(i)).map(i => entity_reference_revisions_paragraph_with_target_node_restaurant_field_page_content_item_parser(i)).filter(i => i !== undefined))

export const node_restaurant_parser = (t: NodeRestaurant): ParsedNodeRestaurant => ({
  ...node_base_parser(t),
  bundle: ((_: any): "restaurant" => "restaurant")(t.type),
  field_city: singular_optional_string_parser(t.field_city),
  field_delivery: singular_optional_boolean_parser(t.field_delivery),
  field_dine_in: singular_optional_boolean_parser(t.field_dine_in),
  field_email_address: singular_optional_email_parser(t.field_email_address),
  field_etender_access_key: singular_optional_string_parser(t.field_etender_access_key),
  field_etender_id: singular_optional_integer_parser(t.field_etender_id),
  field_facilities: plural_list_string_parser(t.field_facilities),
  field_introduction: singular_optional_string_long_parser(t.field_introduction),
  field_introduction_image: singular_optional_entity_reference_media_with_target_node_restaurant_field_introduction_image_parser(t.field_introduction_image),
  field_latitude: singular_optional_string_parser(t.field_latitude),
  field_links: plural_link_parser(t.field_links),
  field_longitude: singular_optional_string_parser(t.field_longitude),
  field_metatags: singular_optional_metatag_parser(t.field_metatags),
  field_page_content: plural_entity_reference_revisions_paragraph_with_target_node_restaurant_field_page_content_parser(t.field_page_content),
  field_pick_up: singular_optional_boolean_parser(t.field_pick_up),
  field_postal_code: singular_optional_string_parser(t.field_postal_code),
  field_province: singular_optional_list_string_parser(t.field_province),
  field_route: singular_optional_link_parser(t.field_route),
  field_street_and_house_number: singular_optional_string_parser(t.field_street_and_house_number),
  field_telephone_number: singular_optional_string_parser(t.field_telephone_number)
})

export const node_restaurant_guard = (t: Node): t is NodeRestaurant => node_bundle_parser(t.type) == "restaurant"

export const entity_reference_media_with_target_node_restaurant_overview_field_introduction_image_item_parser = (t: EntityReferenceMediaWithTargetNodeRestaurantOverviewFieldIntroductionImageItem): ParsedMediaImage | undefined | undefined => (t.target !== undefined ? ((t: MediaImage): ParsedMediaImage =>
    media_image_parser(t))(t.target) : undefined)

export const entity_reference_media_with_target_node_restaurant_overview_field_introduction_image_item_guard = (t: any): t is EntityReferenceMediaWithTargetNodeRestaurantOverviewFieldIntroductionImageItem => (t.target_id !== undefined)

export const singular_optional_entity_reference_media_with_target_node_restaurant_overview_field_introduction_image_parser = (f: FieldItemList<EntityReferenceMediaWithTargetNodeRestaurantOverviewFieldIntroductionImageItem>): ParsedMediaImage | undefined | null => f && f.length > 0 && entity_reference_media_with_target_node_restaurant_overview_field_introduction_image_item_guard(f[0]) ? entity_reference_media_with_target_node_restaurant_overview_field_introduction_image_item_parser(f[0]) : null

export const entity_reference_revisions_paragraph_with_target_node_restaurant_overview_field_page_content_item_parser = (t: EntityReferenceRevisionsParagraphWithTargetNodeRestaurantOverviewFieldPageContentItem): ParsedParagraphText | ParsedParagraphFeaturedContent | ParsedParagraphCta | ParsedParagraphDownloads | ParsedParagraphExpandableContentBlocks | ParsedParagraphGridBlocksImage | ParsedParagraphGridBlocksPlain | ParsedParagraphIframe | ParsedParagraphLinksList | ParsedParagraphMedia | ParsedParagraphQuote | ParsedParagraphRestaurantList | ParsedParagraphMap | undefined | undefined => (t.target !== undefined ? ((t: ParagraphText | ParagraphFeaturedContent | ParagraphCta | ParagraphDownloads | ParagraphExpandableContentBlocks | ParagraphGridBlocksImage | ParagraphGridBlocksPlain | ParagraphIframe | ParagraphLinksList | ParagraphMedia | ParagraphQuote | ParagraphRestaurantList | ParagraphMap): ParsedParagraphText | ParsedParagraphFeaturedContent | ParsedParagraphCta | ParsedParagraphDownloads | ParsedParagraphExpandableContentBlocks | ParsedParagraphGridBlocksImage | ParsedParagraphGridBlocksPlain | ParsedParagraphIframe | ParsedParagraphLinksList | ParsedParagraphMedia | ParsedParagraphQuote | ParsedParagraphRestaurantList | ParsedParagraphMap =>
  paragraph_text_guard(t) ?
    paragraph_text_parser(t) :
  paragraph_featured_content_guard(t) ?
    paragraph_featured_content_parser(t) :
  paragraph_cta_guard(t) ?
    paragraph_cta_parser(t) :
  paragraph_downloads_guard(t) ?
    paragraph_downloads_parser(t) :
  paragraph_expandable_content_blocks_guard(t) ?
    paragraph_expandable_content_blocks_parser(t) :
  paragraph_grid_blocks_image_guard(t) ?
    paragraph_grid_blocks_image_parser(t) :
  paragraph_grid_blocks_plain_guard(t) ?
    paragraph_grid_blocks_plain_parser(t) :
  paragraph_iframe_guard(t) ?
    paragraph_iframe_parser(t) :
  paragraph_links_list_guard(t) ?
    paragraph_links_list_parser(t) :
  paragraph_media_guard(t) ?
    paragraph_media_parser(t) :
  paragraph_quote_guard(t) ?
    paragraph_quote_parser(t) :
  paragraph_restaurant_list_guard(t) ?
    paragraph_restaurant_list_parser(t) :
    paragraph_map_parser(t))(t.target) : undefined)

export const entity_reference_revisions_paragraph_with_target_node_restaurant_overview_field_page_content_item_guard = (t: any): t is EntityReferenceRevisionsParagraphWithTargetNodeRestaurantOverviewFieldPageContentItem => (t.target_id !== undefined) && (t.target_revision_id !== undefined)

export const plural_entity_reference_revisions_paragraph_with_target_node_restaurant_overview_field_page_content_parser =
  (f: FieldItemList<EntityReferenceRevisionsParagraphWithTargetNodeRestaurantOverviewFieldPageContentItem>): List<ParsedParagraphText | ParsedParagraphFeaturedContent | ParsedParagraphCta | ParsedParagraphDownloads | ParsedParagraphExpandableContentBlocks | ParsedParagraphGridBlocksImage | ParsedParagraphGridBlocksPlain | ParsedParagraphIframe | ParsedParagraphLinksList | ParsedParagraphMedia | ParsedParagraphQuote | ParsedParagraphRestaurantList | ParsedParagraphMap> =>
    List<ParsedParagraphText | ParsedParagraphFeaturedContent | ParsedParagraphCta | ParsedParagraphDownloads | ParsedParagraphExpandableContentBlocks | ParsedParagraphGridBlocksImage | ParsedParagraphGridBlocksPlain | ParsedParagraphIframe | ParsedParagraphLinksList | ParsedParagraphMedia | ParsedParagraphQuote | ParsedParagraphRestaurantList | ParsedParagraphMap>(f.filter(i => entity_reference_revisions_paragraph_with_target_node_restaurant_overview_field_page_content_item_guard(i)).map(i => entity_reference_revisions_paragraph_with_target_node_restaurant_overview_field_page_content_item_parser(i)).filter(i => i !== undefined))

export const node_restaurant_overview_parser = (t: NodeRestaurantOverview): ParsedNodeRestaurantOverview => ({
  ...node_base_parser(t),
  bundle: ((_: any): "restaurant_overview" => "restaurant_overview")(t.type),
  field_introduction: singular_optional_string_long_parser(t.field_introduction),
  field_introduction_image: singular_optional_entity_reference_media_with_target_node_restaurant_overview_field_introduction_image_parser(t.field_introduction_image),
  field_links: plural_link_parser(t.field_links),
  field_metatags: singular_optional_metatag_parser(t.field_metatags),
  field_page_content: plural_entity_reference_revisions_paragraph_with_target_node_restaurant_overview_field_page_content_parser(t.field_page_content)
})

export const node_restaurant_overview_guard = (t: Node): t is NodeRestaurantOverview => node_bundle_parser(t.type) == "restaurant_overview"

export const node_parser = (t: Node): ParsedNode =>
  node_campaign_guard(t) ?
    node_campaign_parser(t) :
  node_homepage_guard(t) ?
    node_homepage_parser(t) :
  node_page_guard(t) ?
    node_page_parser(t) :
  node_restaurant_guard(t) ?
    node_restaurant_parser(t) :
    node_restaurant_overview_parser(t)

export const node_guard = (t: Entity): t is Node => (
  (t as any).nid !== undefined &&
  (t as any).uuid !== undefined &&
  (t as any).vid !== undefined &&
  (t as any).langcode !== undefined &&
  (t as any).type !== undefined &&
  (t as any).type[0].target_type == "node_type" &&
  (t as any).revision_timestamp !== undefined &&
  (t as any).revision_uid !== undefined &&
  (t as any).revision_log !== undefined &&
  (t as any).status !== undefined &&
  (t as any).uid !== undefined &&
  (t as any).title !== undefined &&
  (t as any).created !== undefined &&
  (t as any).changed !== undefined &&
  (t as any).promote !== undefined &&
  (t as any).sticky !== undefined &&
  (t as any).default_langcode !== undefined &&
  (t as any).revision_translation_affected !== undefined &&
  (t as any).moderation_state !== undefined &&
  (t as any).metatag !== undefined &&
  (t as any).content_translation_source !== undefined &&
  (t as any).content_translation_outdated !== undefined
)

export const entity_reference_taxonomy_vocabulary_item_parser = (t: EntityReferenceTaxonomyVocabularyItem): ParsedEntityReferenceTaxonomyVocabularyItem => ({
  target_id: noop_parser<string>(t.target_id),
  label: t.target_label
})

export const singular_required_entity_reference_taxonomy_vocabulary_parser = (f: FieldItemList<EntityReferenceTaxonomyVocabularyItem>): ParsedEntityReferenceTaxonomyVocabularyItem => entity_reference_taxonomy_vocabulary_item_parser(f[0])

export const taxonomy_term_bundle_parser = (f: FieldItemList<EntityReferenceTaxonomyVocabularyItem>): string => f[0].target_id
export const entity_reference_taxonomy_term_item_parser = (t: EntityReferenceTaxonomyTermItem): ParsedEntityReferenceTaxonomyTermItem => ({
  id: noop_parser<number>(t.target_id),
  url: t.url,
  label: t.target_label
})

export const plural_entity_reference_taxonomy_term_parser =
  (f: FieldItemList<EntityReferenceTaxonomyTermItem>): List<ParsedEntityReferenceTaxonomyTermItem> =>
    List<ParsedEntityReferenceTaxonomyTermItem>(f.map(i => entity_reference_taxonomy_term_item_parser(i)))

export const taxonomy_term_base_parser = (t: TaxonomyTermBase): ParsedTaxonomyTermBase => ({
  entity_type: ((_: any): "taxonomy_term" => "taxonomy_term")(t),
  id: singular_optional_integer_parser(t.tid),
  language: singular_optional_language_parser(t.langcode),
  label: singular_required_string_parser(t.name),
  revision_created: singular_optional_created_parser(t.revision_created),
  revision_user: singular_optional_entity_reference_user_parser(t.revision_user),
  revision_log_message: singular_optional_string_long_parser(t.revision_log_message),
  status: singular_optional_boolean_parser(t.status),
  description: singular_optional_text_long_parser(t.description),
  weight: singular_optional_integer_parser(t.weight),
  parent: plural_entity_reference_taxonomy_term_parser(t.parent),
  changed: singular_optional_changed_parser(t.changed),
  default_langcode: singular_optional_boolean_parser(t.default_langcode),
  revision_translation_affected: singular_optional_boolean_parser(t.revision_translation_affected),
  metatag: metatag_field_parser(t.metatag),
  path: singular_optional_path_parser(t.path),
  language_links: language_links_parser(t.language_links),
  entity_operations: entity_operations_parser(t.entity_operations)
})

export const taxonomy_term_parser = (t: TaxonomyTerm): ParsedTaxonomyTerm => taxonomy_term_base_parser(t)

export const taxonomy_term_guard = (t: Entity): t is TaxonomyTerm => (
  (t as any).tid !== undefined &&
  (t as any).uuid !== undefined &&
  (t as any).revision_id !== undefined &&
  (t as any).langcode !== undefined &&
  (t as any).vid !== undefined &&
  (t as any).vid[0].target_type == "taxonomy_vocabulary" &&
  (t as any).revision_created !== undefined &&
  (t as any).revision_user !== undefined &&
  (t as any).revision_log_message !== undefined &&
  (t as any).status !== undefined &&
  (t as any).name !== undefined &&
  (t as any).description !== undefined &&
  (t as any).weight !== undefined &&
  (t as any).parent !== undefined &&
  (t as any).changed !== undefined &&
  (t as any).default_langcode !== undefined &&
  (t as any).revision_translation_affected !== undefined &&
  (t as any).metatag !== undefined
)

export const input_entity_parser = (t: InputEntity): ParsedInputEntity =>
  node_guard(t) ?
    node_parser(t) :
    taxonomy_term_parser(t)

export const entity_parser = (t: Entity): ParsedEntity =>
  node_guard(t) ?
    node_parser(t) :
  paragraph_guard(t) ?
    paragraph_parser(t) :
  media_guard(t) ?
    media_parser(t) :
  file_guard(t) ?
    file_parser(t) :
    taxonomy_term_parser(t)

export const input_entity_guard = (t: any): t is InputEntity => (
  node_guard(t as InputEntity) ||
  taxonomy_term_guard(t as InputEntity)
)

export const entity_guard = (t: any): t is Entity => (
  node_guard(t as Entity) ||
  paragraph_guard(t as Entity) ||
  media_guard(t as Entity) ||
  file_guard(t as Entity) ||
  taxonomy_term_guard(t as Entity)
)