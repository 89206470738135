import { Action, IOWidget, nothing, Pair } from "widgets-for-react"
import { ParsedParagraphRestaurantList, ParsedParagraphMenu, ParsedParagraphCtaWithImage, ParsedParagraphCampaignMedia, ParsedParagraphCampaignGrid, ParsedParagraphCampaignText, ParsedParagraphCampaignCta, ParsedParagraphCampaignUitklappers, ParsedParagraphMap } from "../drupal/drupal_generated/types"
import { Block } from "../drupal/drupal_utils/types"
import { RestaurantListBlock, RestaurantListBlockProps, RestaurantListBlockStyle } from "../widget_blocks/restaurantListBlock"
import { BlockData, CMSState } from "../widget_blocks/state"
import { MapType } from "../widget_blocks_client/types/utils"
import { ExtraImages } from "./extra_images"
import { MenuBlockProps, MenuBlockStyle, MenuBlock } from "../widget_blocks/menuBlock";
import { CtaImageCampaignBlock, CtaImageCampaignBlockProps, CtaImageCampaignBlockStyle } from "../widget_blocks/ctaImageCampaignBlock"
import { ctaCampaignBlockClasses, ctaImageCampaignBlockClasses, expandCampaignBlockClasses, linkGridCampaignBlockClasses, mediaCampaignBlockClasses, textCampaignBlockClasses } from "../blockclasses"
import { MediaCampaignBlock, MediaCampaignBlockProps, MediaCampaignBlockStyle } from "../widget_blocks/mediaCampaignBlock"
import { LinkGridCampaignBlock, LinkGridCampaignBlockProps, LinkGridCampaignBlockStyle } from "../widget_blocks/linkGridCampaignBlock"
import { TextCampaignBlock, TextCampaignBlockProps, TextCampaignBlockStyle } from "../widget_blocks/textCampaignBlock"
import { CtaCampaignBlock, CtaCampaignBlockProps, CtaCampaignBlockStyle } from "../widget_blocks/ctaCampaignBlock"
import { ExpandCampaignBlock, ExpandCampaignBlockProps, ExpandCampaignBlockStyle } from "../widget_blocks/expandCampaignBlock"
import { LeafletMapState } from "../widget_blocks/LeafletMapBlock/LeafletMap.State"
import { LeafletMapBlock, LeafletMapBlockStyle } from "../widget_blocks/LeafletMapBlock/LeafletMap.widget"

export type RestaurantListBlock = MapType<ParsedParagraphRestaurantList, "bundle">
export type MenuBlock = MapType<ParsedParagraphMenu, "bundle">
export type CtaImageCampaignBlock = MapType<ParsedParagraphCtaWithImage, "bundle">
export type MediaCampaignBlock = MapType<ParsedParagraphCampaignMedia, "bundle">
export type LinkGridCampaignBlock = MapType<ParsedParagraphCampaignGrid, "bundle">
export type TextCampaignBlock = MapType<ParsedParagraphCampaignText, "bundle">
export type CtaCampaignBlock = MapType<ParsedParagraphCampaignCta, "bundle">
export type ExpandCampaignBlock = MapType<ParsedParagraphCampaignUitklappers, "bundle">
export type LeafletMapBlock = MapType<ParsedParagraphMap, "bundle">

// Kinds coming from Drupal
export type CustomBlock =
  | RestaurantListBlock
  | MenuBlock
  | CtaImageCampaignBlock
  | MediaCampaignBlock
  | LinkGridCampaignBlock
  | TextCampaignBlock
  | CtaCampaignBlock
  | ExpandCampaignBlock
  | LeafletMapBlock

// Kinds of custom block components in public_site
export type CustomBlockData =
  | { kind: "restaurantListBlock", value: Pair<RestaurantListBlockProps, RestaurantListBlockStyle> }
  | { kind: "menuBlock", value: Pair<MenuBlockProps, MenuBlockStyle> }
  | { kind: "ctaImageCampaignBlock", value: Pair<CtaImageCampaignBlockProps, CtaImageCampaignBlockStyle> }
  | { kind: "mediaCampaignBlock", value: Pair<MediaCampaignBlockProps, MediaCampaignBlockStyle> }
  | { kind: "linkGridCampaignBlock", value: Pair<LinkGridCampaignBlockProps, LinkGridCampaignBlockStyle> }
  | { kind: "textCampaignBlock", value: Pair<TextCampaignBlockProps, TextCampaignBlockStyle> }
  | { kind: "ctaCampaignBlock", value: Pair<CtaCampaignBlockProps, CtaCampaignBlockStyle> }
  | { kind: "expandCampaignBlock", value: Pair<ExpandCampaignBlockProps, ExpandCampaignBlockStyle> }
  | { kind: "leafletMap", value: Pair<LeafletMapState, LeafletMapBlockStyle> }

export const isCustomBlock = <s, i>(b: Block | CustomBlock): b is CustomBlock => (
  b.kind == "restaurant_list"
  || b.kind == "menu"
  || b.kind == "cta_with_image"
  || b.kind == "campaign_media"
  || b.kind == "campaign_grid"
  || b.kind == "campaign_text"
  || b.kind == "campaign_cta"
  || b.kind == "campaign_uitklappers"
  || b.kind == "map"
)

export const isCustomBlockData = <s, i>(b: BlockData<s, i> | CustomBlockData): b is CustomBlockData => (
  b.kind == "restaurantListBlock"
  || b.kind == "menuBlock"
  || b.kind == "ctaImageCampaignBlock"
  || b.kind == "mediaCampaignBlock"
  || b.kind == "linkGridCampaignBlock"
  || b.kind == "textCampaignBlock"
  || b.kind == "ctaCampaignBlock"
  || b.kind == "expandCampaignBlock"
  || b.kind == "leafletMap"
)

export function renderCustomBlock(
  block: CustomBlockData,
  key?: string
): IOWidget<CMSState<CustomBlockData, ExtraImages>, Action<CMSState<CustomBlockData, ExtraImages>>> {
  return s0 => {
    if (block.kind == "restaurantListBlock") {
      return RestaurantListBlock<CustomBlockData>(block.value.fst, { style: {} })(s0)

    } else if (block.kind == "menuBlock") {
      return MenuBlock<CustomBlockData>(block.value.fst, { style: {} })(s0)

    } else if (block.kind == "ctaImageCampaignBlock") {
      return CtaImageCampaignBlock<CustomBlockData>(block.value.fst, {
        style: ctaImageCampaignBlockClasses({
          width: block.value.fst.width ? 'content' : 'full',
          direction: block.value.fst.width ? 'center' : block.value.fst.direction == "left" ? 'right' : 'left',
          titleFont: block.value.fst.title_options && block.value.fst.title_options.font ? block.value.fst.title_options.font : undefined,
          titleSize: block.value.fst.title_options && block.value.fst.title_options.size ? block.value.fst.title_options.size : undefined,
          textFont: block.value.fst.text_options && block.value.fst.text_options.font ? block.value.fst.text_options.font : undefined,
          textSize: block.value.fst.text_options && block.value.fst.text_options.size ? block.value.fst.text_options.size : undefined
        }), key: key
      })(s0)

    } else if (block.kind == "mediaCampaignBlock") {
      return MediaCampaignBlock<CustomBlockData>(block.value.fst, {
        style: mediaCampaignBlockClasses({
          width: block.value.fst.width ? 'content' : 'full',
          textFont: block.value.fst.text_options && block.value.fst.text_options.font ? block.value.fst.text_options.font : undefined,
          textSize: block.value.fst.text_options && block.value.fst.text_options.size ? block.value.fst.text_options.size : undefined
        }), key: key
      })(s0)

    } else if (block.kind == "linkGridCampaignBlock") {
      return LinkGridCampaignBlock<CustomBlockData>(block.value.fst, {
        style: linkGridCampaignBlockClasses({
          width: block.value.fst.width ? 'content' : 'full',
          colunms: block.value.fst.columns,
          alignment: block.value.fst.alignment ? 'center' : 'left',
          titleFont: block.value.fst.title_options && block.value.fst.title_options.font ? block.value.fst.title_options.font : undefined,
          titleSize: block.value.fst.title_options && block.value.fst.title_options.size ? block.value.fst.title_options.size : undefined,
          textFont: block.value.fst.text_options && block.value.fst.text_options.font ? block.value.fst.text_options.font : undefined,
          textSize: block.value.fst.text_options && block.value.fst.text_options.size ? block.value.fst.text_options.size : undefined
        }), key: key
      })(s0)

    } else if (block.kind == "textCampaignBlock") {
      return TextCampaignBlock<CustomBlockData>(block.value.fst, {
        style: textCampaignBlockClasses({
          titleFont: block.value.fst.title_options && block.value.fst.title_options.font ? block.value.fst.title_options.font : undefined,
          titleSize: block.value.fst.title_options && block.value.fst.title_options.size ? block.value.fst.title_options.size : undefined,
          textFont: block.value.fst.text_options && block.value.fst.text_options.font ? block.value.fst.text_options.font : undefined,
          textSize: block.value.fst.text_options && block.value.fst.text_options.size ? block.value.fst.text_options.size : undefined
        }), key: key
      })(s0)

    } else if (block.kind == "ctaCampaignBlock") {
      return CtaCampaignBlock<CustomBlockData>(block.value.fst, {
        style: ctaCampaignBlockClasses({
          titleFont: block.value.fst.title_options && block.value.fst.title_options.font ? block.value.fst.title_options.font : undefined,
          titleSize: block.value.fst.title_options && block.value.fst.title_options.size ? block.value.fst.title_options.size : undefined,
          textFont: block.value.fst.text_options && block.value.fst.text_options.font ? block.value.fst.text_options.font : undefined,
          textSize: block.value.fst.text_options && block.value.fst.text_options.size ? block.value.fst.text_options.size : undefined
        }), key: key
      })(s0)

    } else if (block.kind == "expandCampaignBlock") {
      return ExpandCampaignBlock<CustomBlockData>(block.value.fst, {
        style: expandCampaignBlockClasses({
          titleFont: block.value.fst.title_options && block.value.fst.title_options.font ? block.value.fst.title_options.font : undefined,
          titleSize: block.value.fst.title_options && block.value.fst.title_options.size ? block.value.fst.title_options.size : undefined,
          textFont: block.value.fst.text_options && block.value.fst.text_options.font ? block.value.fst.text_options.font : undefined,
          textSize: block.value.fst.text_options && block.value.fst.text_options.size ? block.value.fst.text_options.size : undefined
        }), key: key
      })(s0)

    } else if (block.kind == "leafletMap") {
      return LeafletMapBlock<CustomBlockData, ExtraImages>(block.value.fst, {style: block.value.snd, key})(s0)
    } else {
      return nothing()
    }
  }
}
