import { List } from "immutable"
import * as React from "react"
import { Fun, mk_widget, Widget } from "widgets-for-react"
import { ParsedNodeHomepage, ParsedNodePage, ParsedNodeRestaurant, ParsedNodeRestaurantOverview } from "../drupal/drupal_generated/types"
import { Breadcrumb } from "../drupal/drupal_utils/types"
import { parseImageUrl } from "../drupal/drupal_utils/utils"
import { Breadcrumbs } from "./breadcrumbs"
import { Link } from "./Link"

type NodeWithTextHeader = ParsedNodePage

type NodeWithImageHeader = ParsedNodePage | ParsedNodeRestaurant | ParsedNodeRestaurantOverview

type NodeWithFWHeader = ParsedNodeHomepage

type NodeWithFallbackHeader = ParsedNodeHomepage

export const pageHeader: Fun<List<Breadcrumb>, Fun<NodeWithTextHeader | NodeWithImageHeader | NodeWithFWHeader, Widget<never>>> = b => p =>
  p.bundle == "page" || p.bundle == "restaurant" || p.bundle == "restaurant_overview"
    ? pageHeaderImage(b)(p)
    : p.bundle == "homepage"
      ? pageHeaderFW(b)(p)
      : pageHeaderText(b)(p)

const pageHeaderImage: Fun<List<Breadcrumb>, Fun<NodeWithImageHeader, Widget<never>>> = breadcrumbs => p =>
  mk_widget({
    run: () => {
      return (
        <>
          <header className={`hero hero--${p.bundle} ${p.field_introduction_image ? "hero--with-image" : "hero--without-image"}`}>
            {p.field_introduction_image && (
              <div className="hero__visual-wrap">
                <figure className="hero__visual">
                  <img
                    className="hero__image"
                    alt={p.field_introduction_image.field_media_image.alt}
                    src={parseImageUrl(
                      p.field_introduction_image.field_media_image.image.style_urls['header_1440x500']
                    )}
                  />
                </figure>
              </div>
            )}

            <div className="content">
              {breadcrumbs.count() > 1 && <Breadcrumbs breadcrumbs={breadcrumbs} />}
              <div className="hero__content">
                <div className="hero__inner">
                  <h1 className="hero__title title--xl">{p.label}</h1>

                  {p.field_introduction && (
                    <div className="hero__intro">
                      <p>{p.field_introduction}</p>
                    </div>
                  )}

                  {p.field_links.map((l, i) => (
                    <Link
                      className={`page-header__button button ${i == 0 ? "button--primary" : "button--link"}`}
                      to={l.url}
                      type="link"
                    >
                      {l.title}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </header>
        </>
      )
    }
  })

const pageHeaderFW: Fun<List<Breadcrumb>, Fun<NodeWithFWHeader, Widget<never>>> = breadcrumbs => p =>
  mk_widget({
    run: () => {
      return (
        <>
          <header className="hero hero--fw">
            {p.field_header_single ? (
              <div className="hero__banner banner">
                {p.field_header_single.field_media && (
                  <div className={`banner__visual${(p.field_header_single.field_campaign_title || p.field_header_single.field_campaign_introduction) ? ' banner__visual--gradient' : ''}`}>
                    {p.field_header_single.field_media.bundle == 'image' && (
                      <img
                        className="hero__image"
                        alt={p.field_header_single.field_media.field_media_image.alt}
                        width='720w'
                        src={parseImageUrl(p.field_header_single.field_media.field_media_image.image.style_urls['w720'])}
                        srcSet={`
                          ${parseImageUrl(p.field_header_single.field_media.field_media_image.image.style_urls['w720'])} 720w,
                          ${parseImageUrl(p.field_header_single.field_media.field_media_image.image.style_urls['w1440'])} 1440w,
                          ${parseImageUrl(p.field_header_single.field_media.field_media_image.image.style_urls['w2160'])} 2160w,
                          ${parseImageUrl(p.field_header_single.field_media.field_media_image.image.style_urls['w2880'])} 2880w
                        `}
                        sizes='100vw'
                      />
                    )}
                  </div>
                )}

                <div className="banner__content">
                  <div className="banner__inner">
                    {p.field_header_single.field_campaign_title && (
                      <h1
                        className={`banner__title title--xl${p.field_header_single.field_fonts_font_size_title && p.field_header_single.field_fonts_font_size_title.field_size ? ` c-${p.field_header_single.field_fonts_font_size_title.field_size}` : ''}${p.field_header_single.field_fonts_font_size_title && p.field_header_single.field_fonts_font_size_title.field_font ? ` c-font--${p.field_header_single.field_fonts_font_size_title.field_font}` : ''}`}
                        dangerouslySetInnerHTML={{ __html: p.field_header_single.field_campaign_title }}
                      />
                    )}
                    {p.field_header_single.field_campaign_introduction && (
                      <div
                        className={`banner__intro title--m${p.field_header_single.field_fonts_font_size_text && p.field_header_single.field_fonts_font_size_text.field_size ? ` c-${p.field_header_single.field_fonts_font_size_text.field_size}` : ''}${p.field_header_single.field_fonts_font_size_text && p.field_header_single.field_fonts_font_size_text.field_font ? ` c-font--${p.field_header_single.field_fonts_font_size_text.field_font}` : ''}`}
                        dangerouslySetInnerHTML={{ __html: p.field_header_single.field_campaign_introduction }}
                      />
                    )}

                    {p.field_header_single.field_links.map((l, i) => (
                      <Link
                        className={`banner__button button ${i == 0 ? "button--primary" : "button--secondary"}`}
                        to={l.url}
                        type="link"
                      >
                        {l.title}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="hero__placeholder banner">
                <div className="banner__content">
                  <div className="banner__inner">
                    <h1 className="banner__title title--xl">{p.label}</h1>
                  </div>
                </div>
              </div>
            )}
          </header>
        </>
      )
    }
  })

const pageHeaderText: Fun<List<Breadcrumb>, Fun<NodeWithTextHeader, Widget<never>>> = breadcrumbs => p =>
  mk_widget({
    run: o => (
      <>
        {p.bundle == "page" && breadcrumbs.count() > 1 && <Breadcrumbs breadcrumbs={breadcrumbs} />}

        <header className={`hero hero--${p.bundle} hero--without-image`}>
          <div className="content hero__content">
            <div className="hero__inner">
              <h1 className="hero__title title--xl">{p.label}</h1>

              {p.field_introduction && (
                <div className="hero__intro">
                  <p>{p.field_introduction}</p>
                </div>
              )}

              {p.field_links.map((l, i) => (
                <Link
                  className={`page-header__button ${i == 0 ? "button--primary" : "button--link"}`}
                  to={l.url}
                  type="link"
                >
                  {l.title}
                </Link>
              ))}
            </div>
          </div>
        </header>
      </>
    )
  })

export const pageHeaderFallback: Fun<List<Breadcrumb>, Fun<NodeWithFallbackHeader, Widget<never>>> = breadcrumbs => p =>
    mk_widget({
      run: () => {
        return (
          <>
            <header className="hero hero--fw">
              <div className="hero__placeholder hero__placeholder--small banner">
                <div className="banner__content">
                  <div className="banner__inner">
                    <h1 className="banner__title title--xl">{p.label}</h1>
                  </div>
                </div>
              </div>
            </header>
          </>
        )
      }
    })