import * as React from "react"
import { Action, IOWidget, mk_widget } from "widgets-for-react"
import { ExtraImages } from "../public_site_utils/extra_images"
import { ImageContainerExtended, ImageSrcsetProps } from "./ImageCampaignContainer"
import { CMSState } from "./state"
import { ImageSize,  Link } from "./types"
import { link } from "./utils"

export type GridImageProps = {
  id: number
  size: ImageSize<ExtraImages>
  srcsetSize: ImageSrcsetProps<ExtraImages>[]
  alt: string
  width: string
  height: string
  lazyload: boolean
}

export type LinkGridCampaignItem = {
  image: GridImageProps
  title: string
  intro: string
  title_options?: {
    size: string,
    font: string
  }
  text_options?: {
    size: string,
    font: string
  }
  link?: Link
  show_image: "yes" | "no"
}

export type LinkGridCampaignBlockProps = {
  title?: string
  intro?: string
  title_options?: {
    size: string,
    font: string
  }
  text_options?: {
    size: string,
    font: string
  }
  items: LinkGridCampaignItem[]
  link?: Link
  show_image: "yes" | "no",
  width: boolean
  columns: string
  alignment: boolean
}

export type LinkGridCampaignBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
  header_classname: string
  title_classname: string
  intro_classname: string
  content_classname: string
  list_classname: string
  grid_item_classname: string
  grid_link_classname: string
  grid_content_classname: string
  grid_title_classname: string
  grid_intro_classname: string
  grid_fauxlink_classname: string
  grid_visual_classname: string
  grid_image_classname: string
  footer_classname: string
  clickable_classname: string
}>

const getClassName = (
  options: { style?: LinkGridCampaignBlockStyle },
  className: keyof LinkGridCampaignBlockStyle,
  notset: string = ""
): string =>
  options && options.style && (options.style[className] || options.style[className] == null) ? options.style[className] : notset

// TODO: make gridItem separate component so that it looks and works the same way as expandBlock
export function LinkGridCampaignBlock<customBlockData>(
  props: LinkGridCampaignBlockProps,
  options?: { style?: LinkGridCampaignBlockStyle; key?: string }
): IOWidget<CMSState<customBlockData, ExtraImages>, Action<CMSState<customBlockData, ExtraImages>>> {
  return cmsState =>
    mk_widget({
      run: _ => {
        const gridItem = (item: LinkGridCampaignItem, index: number) => {
          return (
            <>
              {item.show_image === "yes" && item.image && cmsState.images.has(item.image.id)
                ? <figure className={getClassName(options, "grid_visual_classname", "card-img-top")}>
                    {ImageContainerExtended({
                      url: cmsState.images.get(item.image.id),
                      srcSize: item.image.size,
                      srcsetSize: item.image.srcsetSize,
                      extraClasses: getClassName(options, "grid_image_classname", "w-100"),
                      alt: item.image.alt,
                      width: item.image.width,
                      height: item.image.height,
                      lazyload: item.image.lazyload
                    }).run(_ => {})}
                  </figure>
                : null
              }
              <div className={getClassName(options, "grid_content_classname", "card-body px-0")}>
                { item.title &&
                  <h3
                    className={`${getClassName(options, "grid_title_classname", "mb-2")}${item.title_options && item.title_options.size ? ` c-${item.title_options.size}` : props.alignment ? '' : ''}${item.title_options && item.title_options.font ? ` c-font--${item.title_options.font}` : ''}`}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                }
                { item.intro &&
                  <div
                    className={`${getClassName(options, "grid_intro_classname", "")}${item.text_options && item.text_options.size ? ` c-${item.text_options.size}` : ''}${item.text_options && item.text_options.font ? ` c-font--${item.text_options.font}` : ''}`}
                    dangerouslySetInnerHTML={{ __html: item.intro }}
                  />
                }
                { item.link &&
                  <span className={getClassName(options, "grid_fauxlink_classname", "button button-link pl-0")}>{item.link.title}</span>
                }
              </div>
            </>
          )
        }
        return (
          <section className={getClassName(options, "block_classname", "container")} key={options && options.key ? options.key : "link-grid-block"}>
            <div className={getClassName(options, "inner_classname", "py-5")}>
              { (props.title || props.intro) &&
                <header className={getClassName(options, "header_classname", "text-center")}>
                  {props.title && (
                    <h2
                      className={getClassName(options, "title_classname", "mb-3")}
                      dangerouslySetInnerHTML={{ __html: props.title }}
                    />
                  )}
                  {props.intro && (
                    <div
                      className={getClassName(options, "intro_classname", "")}
                      dangerouslySetInnerHTML={{ __html: props.intro }}
                    />)}
                </header>
              }

              {props.items && (
                <div className={getClassName(options, "content_classname", "")}>
                  <ul className={getClassName(options, "list_classname", "row list-unstyled mb-0")}>
                    {props.items.map((item: LinkGridCampaignItem, index: number) =>
                      <li className={getClassName(options, "grid_item_classname", "col-md-6 col-lg-4")}>
                        {item.link
                          ? link(item.link,
                                {classname: getClassName(options, "grid_link_classname", "d-flex flex-column-reverse text-secondary")},
                                gridItem(item, index))
                          : <div className={getClassName(options, "grid_link_classname", " d-flex flex-column-reverse")}>{ gridItem(item, index) }</div>
                        }
                      </li>
                    )}
                  </ul>
                </div>
              )}

              {props.link &&
                <footer className={getClassName(options, "footer_classname", "text-center")}>
                  {link(props.link,
                       {classname: getClassName(options, "clickable_classname", "button")},
                        props.link.title)
                  }
                </footer>
              }
            </div>
          </section>
        )
      },
    })
}