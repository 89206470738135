import { Widget, mk_widget, Option, none, some } from "widgets-for-react";
import * as React from "react"

export const loader: Widget<never> = mk_widget({
  run: o => <div style={{minHeight:'100vh'}}/>
})

function randomColor(): string {
  const colors = ["#1D89B8", "#F39600", "#CAF0F4", "#EAD4D0", "#707C87", "#BB212D", "#DBD323"];
  return colors[Math.floor(Math.random() * colors.length)];
}

class Loader extends React.Component<{}, {}> {

  private ref: Option<SVGSVGElement> = none<SVGSVGElement>()
  private interval: Option<any> = none<any>()

  constructor(props: {}) {
    super(props)
    this.updateColor = this.updateColor.bind(this)
  }

  setRef(e: SVGSVGElement) {
    if (this.ref.v.kind == 'r') return
    this.ref = some(e)
    if (this.interval.v.kind == 'r') clearInterval(this.interval.v.v)
    let tmp = setInterval(this.updateColor, 1200) as any
    this.interval = some(tmp)
  }

  componentWillUnmount() {
    if (this.interval.v.kind == 'l') return
    clearInterval(this.interval.v.v)
  }

  updateColor(args: any[]) {
    if (this.ref.v.kind == 'l') return
    this.ref.v.v.setAttribute('fill', randomColor());
    this.ref.v.v.setAttribute('fill', randomColor());
    this.ref.v.v.setAttribute('fill', randomColor());
    this.ref.v.v.setAttribute('fill', randomColor());
  }

  render() {
    return (
      <svg className="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" ref={r => this.setRef(r)}>
        <path fill="#fff" d="M30.54008,0H29.299S21.85846,0,21.4965,7.182a2.9144,2.9144,0,0,0-.01146.31026V18.46056s0.09766,0.09193.13788,0.13215l7.95764,8.176a0.47515,0.47515,0,0,0,.67193.00605l0.00605-.00605,7.95763-8.176c0.04594-.04022.15512-0.13215,0.15512-0.13215V7.72781S38.37129,0,30.54008,0" className="loader--top" />
        <path fill="#fff" d="M60,30.62742V29.38063s0-7.44054-7.18777-7.80251a2.62958,2.62958,0,0,0-.30451-0.01724H41.53942s-0.09766.10342-.12642,0.14364l-8.18172,7.95189a0.489,0.489,0,0,0,0,.68947L41.413,38.30352c0.02876,0.04022.12642,0.14364,0.12642,0.14364H52.27218S60,38.44716,60,30.62742" className="loader--right" />
        <path fill="#fff" d="M30.54008,60.00287H29.299s-7.44055,0-7.80252-7.182a2.91444,2.91444,0,0,1-.01146-0.31026V41.54231s0.09766-.09193.13788-0.13215l7.95763-8.176a0.47515,0.47515,0,0,1,.67194-0.006l0.006,0.006,7.95763,8.176c0.04594,0.04022.15512,0.13215,0.15512,0.13215V52.27506s0,7.72781-7.83121,7.72781" className="loader--bottom" />
        <path fill="#fff" d="M0,30.62742V29.38063s0.00573-7.44054,7.18771-7.80251a2.63061,2.63061,0,0,1,.30451-0.01724H18.46631s0.09194,0.10342.12063,0.14364l8.18745,7.95189a0.489,0.489,0,0,1,0,.68947l-8.30808,8.10127H7.72782S0,38.44716,0,30.62742" className="loader--left" />
      </svg>
    )
  }
}