import { List, Map } from 'immutable';
import { ActivateAccountData, BasicCredentials, ChangePasswordData, LoginDataTwoFactorData, PersistentUser, PrivacyKind, ResendActivationData, ResetPasswordData, SessionId, SignUpData, User, VerifyResetPasswordTokenData } from './state';
import { inl, inr, mk_pair, none, Option, Pair, some, Sum } from 'widgets-for-react';

export module Api {

  type Username = string
  type Role = string
  export let roles = ["user", "teacher"]
  export let privacy_kind: PrivacyKind = "standard"
  export let account_validation: boolean = true

  interface UserLink {username: Username, role: Role}
  interface LinkOrUser extends Sum<UserLink,PersistentUser> {}
  const justUser = (u: PersistentUser) => inr<UserLink, PersistentUser>(u)
  const justUserLink = (l: {username: Username, role: Role}) => inl<UserLink, PersistentUser>(l)

  interface Db { sessions:Map<SessionId,PersistentUser>, users:Map<Role, Map<Username, LinkOrUser>>, sms:Map<Role,Map<Username, string>> }
  let db:Db = {
    sessions:Map(),
    users:Map<Role, Map<Username, LinkOrUser>>()
        .set(roles[0],
            Map<Username, LinkOrUser>()
                  .set("user1", justUser({username:"user1", email:"user1@user1.1", password:"test1234", name:"user", surname:"1", password_kind:{kind:"two factor"}, is_confirmed: true, activation_or_reset_password_token:none() }))
                  .set("user2", justUser({ username:"user2", email:"user2@user3.2", password:"test1234", name:"user", surname:"2", password_kind:{kind:"standard"}, is_confirmed: true, activation_or_reset_password_token:none() }))
                  .set("user3", justUser({ username:"user3", email:"user3@user3.3", password:"test1234", name:"user", surname:"3", password_kind:{kind:"standard"}, is_confirmed: false, activation_or_reset_password_token:none() }))
                  .set("user4", justUser({ username:"user4", email:"user4@user4.4", password:"test1234", name:"user", surname:"4", password_kind:{kind:"two factor"}, is_confirmed: true, activation_or_reset_password_token:none() }))
                )
        .set(roles[1], Map<Username, LinkOrUser>()
                  .set("user1", justUserLink({ username: "user1", role: roles[0]}))
                  .set("user2", justUserLink({ username: "user2", role: roles[0]}))
                ),
    sms: Map<string,Map<string, string>>().set(roles[0], Map()).set(roles[1], Map())
  }

  const setUser = (u: PersistentUser, r: Role) => db = {...db, users: db.users.set(r, db.users.get(r)!.set(u.username, justUser(u)))}

  const resolveUser = (lou: LinkOrUser): Option<PersistentUser> =>
    lou.v.kind == 'r'
      ? some<PersistentUser>(lou.v.v)
      : db.users.has(lou.v.v.role)
        ? db.users.get(lou.v.v.role)!.has(lou.v.v.username)
          ? resolveUser(db.users.get(lou.v.v.role)!.get(lou.v.v.username)!)
          : none<PersistentUser>()
        : none<PersistentUser>()

  const userHas = (predicate: (u: PersistentUser) => boolean) => (lou: LinkOrUser) => {
    const u = resolveUser(lou)
    return u.v.kind == 'l' ? false : predicate(u.v.v)
  }

  const reduceUsersWith = (predicate: (u: PersistentUser) => boolean) => (foundUsers: List<UserLink>, users: Map<Username, LinkOrUser>, role: Role): List<UserLink> =>
    users.filter(userHas(predicate))
      .map((lou, username) => lou.v.kind == "r" ? { username, role } : lou.v.v)
      .toList()
      .concat(foundUsers).toList()

  const getUsersWith = (predicate: (u: PersistentUser) => boolean): List<Option<Pair<Role, PersistentUser>>> =>
    db.users.reduce<List<UserLink>>(reduceUsersWith(predicate), List<UserLink>())
      .reduce<List<UserLink>>((l, u) => l.find(uu => uu.role == u.role && uu.username == u.username) ? l : l.push(u), List<UserLink>())
      .map((l) => mk_pair<Role, Option<PersistentUser>>(l.role, resolveUser(justUserLink(l))))
      .map(p => p.snd.v.kind == 'r' ? some<Pair<Role, PersistentUser>>(mk_pair<Role, PersistentUser>(p.fst, p.snd.v.v)) : none<Pair<Role, PersistentUser>>())
      .toList()

  const getUserWith = (predicate: (u: PersistentUser) => boolean): Option<Pair<Role, PersistentUser>> => getUsersWith(predicate).first() || none<Pair<Role, PersistentUser>>()

  const correctCredentialsPredicate = (c: BasicCredentials) => (u: PersistentUser): boolean => u.username == c.username && u.password == c.password

  const getRolesforUser = (username: Username): List<Role> => db.users.reduce<List<Role>>((r, u, role) => u.has(username) ? r.push(role) : r, List<Role>())

  export let scorePassword = (_pass:string) : {score:number, res: "strong" | "almost good" | "weak"} => {
    return _pass && _pass.length >= 6 
      ? { score: 100, res: "strong"} 
      : { score: 0, res: "weak"}
  }

  export let complexScorePassword = (_pass:string) : {score:number, res: "strong" | "almost good" | "weak"} => {
    if (_pass == undefined)
      return {score:0, res:"weak"}
    let pass = _pass.split("")
    var score = 0;
    if (!pass)
        return {score:0, res:"weak"}

    // award every unique letter until 5 repetitions
    var letters : Map<string, number> = Map<string, number>()
    for (var i=0; i<pass.length; i++) {
        let current_letter = pass[i]
        letters = letters.set(current_letter, (letters.has(current_letter) ? letters.get(current_letter)! : 0) + 1);
        score += 5.0 / letters.get(current_letter)!;
    }

    // bonus points for mixing it up
    var variations = [
        /\d/.test(_pass), //digits
        /[a-z]/.test(_pass), //lower
        /[A-Z]/.test(_pass), //upper
        /\W/.test(_pass) //nonWords
    ]

    let variationCount = 0;
    variations.forEach(check => {
      variationCount += check ? 1 : 0;
    })
    score += (variationCount - 1) * 10;

    return {score:score, res: score > 80 ? "strong" : score > 60 ? "almost good" : "weak" }
  }

  export type TwoFactorResult =
    | { status: "success" }
    | { status: "error too many attempts" }
    | { status: "error generic not authorized" }
  export type LoginResult =
    | { status: "success"; user: User; role: Role; session: SessionId }
    | { status: "choose role"; roles: Role[] }
    | { status: "proceed with two factor" }
    | { status: "error generic not authorized" }
    | { status: "error account not active"; email: string }
    | { status: "error too many attempts" }
    | { status: "error two factor empty code" }
    | { status: "error two factor wrong code" }
    | { status: "error choose role incompatible role"; roles: Role[] }
  export type SignUpResult =
    | { status: "success"; user: User; role: Role; session: SessionId }
    | { status: "success inactive" }
    | { status: "error" }
    | { status: "too weak password" }
    | { status: "email invalid" }
    | { status: "password invalid" }
    | { status: "username invalid" }
    | { status: "account already exists" }
    | { status: "account not active" }
    | { status: "error too many attempts" }
  export type ResetPasswordRequestResult =
    | { status: "success" }
    | { status: "error email not valid" }
    | { status: "error email not found" }
    | { status: "error account not activated" }
    | { status: "error too many attempts" }
  export type ResetPasswordResult =
    | { status: "success"; username: Username }
    | { status: "error too weak password" }
    | { status: "error token has expired" }
    | { status: "error token not found" }
    | { status: "error too many attempts" }
  export type ActivateAccountResult =
    | { status: "success"; user: User; role: Role; session: SessionId }
    | { status: "token not found" }
    | { status: "token expired"; email: string }
    | { status: "account already active" }
    | { status: "error too many attempts" }
  export type ChangePasswordResult =
    | { status: "success" }
    | { status: "error incorrect password" }
    | { status: "error passwords do not match" }
    | { status: "error too weak password" }
    | { status: "error missing fields" }
  export type ResendActivationResult = { status: "success" } | { status: "error too many attempts" }

  export let is_valid_email = (email:string) => {
    return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email)
  }

  export let sign_up = (x:SignUpData) : Promise<SignUpResult> =>
    new Promise<SignUpResult>((resolve, reject) => {
      if (Math.random() < 0.25) return setTimeout(() => reject("Cannot connect to server."), 2500)
      if (Math.random() < 0.25) return setTimeout(() => resolve({status: "error too many attempts" }), 200)

      if (x.password == undefined || scorePassword(x.password).res == "weak") return setTimeout(() => resolve({ status:"too weak password" }))
      if (x.email == undefined ||  is_valid_email(x.email) == false) return setTimeout(() => resolve({ status:"email invalid" }))

      let userWithUsername = getUserWith(u => u.username == x.username)
      let userWithEmail = getUserWith(u => u.email == x.email)

      if (privacy_kind == "strict" && (userWithUsername.v.kind == 'r' || userWithEmail.v.kind == 'r')) return setTimeout(() => resolve({ status:"error" }))
      if (userWithUsername.v.kind == "r") return setTimeout(() => resolve({ status:"username invalid" }))
      if (userWithEmail.v.kind == "r") {
        if (userWithEmail.v.v.snd.is_confirmed || !account_validation) return setTimeout(() => resolve({ status:"account already exists" }))
        return setTimeout(() => resolve({ status:"account not active" }))
      }

      let token = account_validation
        ? some(Math.floor(Math.random() * 100000).toString())
        : none<string>()

      if (token.v.kind == 'r') console.log(`Sign up: generated token ${token.v.v} for user ${x.username}`)

      const role = roles[0]
      const user: PersistentUser = {
        name:x.name!,
        email:x.email,
        surname:x.surname!,
        password:x.password,
        username:x.username!,
        is_confirmed:false,
        password_kind:{kind:"standard"},
        activation_or_reset_password_token:token
      }
      setUser(user, role)

      const session = Math.random().toString(36).replace(/[^a-z]+/g, '')
      db = {...db, sessions:db.sessions.set(session, user) }

      return  setTimeout(() => resolve(account_validation
          ? {status: "success inactive"}
          : {status:"success", user, session, role})
        , 200)
    })

  export let request_reset_password = (email:string) : Promise<ResetPasswordRequestResult> =>
    new Promise<ResetPasswordRequestResult>((resolve, reject) => {
      if (Math.random() < 0.05) return setTimeout(() => reject("Cannot connect to server."), 2500)
      if (Math.random() < 0.05) return setTimeout(() => resolve({ status:"error too many attempts" }), 200)
      if (is_valid_email(email) == false) return setTimeout(() => resolve({status: "error email not found" }), 200)

      const roleAndUser = getUserWith(u => u.email == email)

      if (roleAndUser.v.kind == 'l') return setTimeout(() => resolve({status: "error email not found" }), 200)
      const user = roleAndUser.v.v.snd

      if (user.is_confirmed == false && account_validation) return setTimeout(() => resolve({status: "error account not activated" }), 200)

      let token = Math.floor(Math.random() * 100000).toString()
      console.log(`Reset password: generated token ${token} for user ${user.username}`)
      setUser({...user, activation_or_reset_password_token:some(token)}, roleAndUser.v.v.fst)

      setTimeout(() => resolve({status: "success" }), 200)
    })



export let reset_password = (x:ResetPasswordData) : Promise<ResetPasswordResult> =>
    new Promise<ResetPasswordResult>((resolve, reject) => {
      if (Math.random() < 0.1) return setTimeout(() => reject("Cannot connect to server."), 2500)
      if (Math.random() < 0.1) return setTimeout(() => resolve({status: "error too many attempts" }), 200)
      if (Math.random() < 0.1) return setTimeout(() => resolve({status: "error token has expired" }), 200)

      const roleAndUser = getUserWith(u => u.activation_or_reset_password_token.v.kind == 'r' && u.activation_or_reset_password_token.v.v == x.reset_password_token)
      if (roleAndUser.v.kind == 'l') return setTimeout(() => resolve({status: "error token not found" }), 200)
      const role = roleAndUser.v.v.fst
      const user = roleAndUser.v.v.snd

      if(x.password == undefined || scorePassword(x.password).res == "weak" || x.password != x.confirmed_password) return setTimeout(() => resolve({ status:"error too weak password" }), 200)

      setUser({...user, password: x.password, is_confirmed: true, activation_or_reset_password_token: none()}, role)

      return setTimeout(() => resolve({status:"success", username: user.username}), 200)
    })

export let verify_reset_password_token = (x:VerifyResetPasswordTokenData) : Promise<ResetPasswordResult> => reset_password({...x, password: "", confirmed_password: ""})

export let resend_account_activation = (x: ResendActivationData) : Promise<ResendActivationResult> =>
  new Promise<ResendActivationResult>((resolve, reject) => {
    if (Math.random() < 0.5) return setTimeout(() => reject("Cannot connect to server."), 2500)
    if (Math.random() < 0.25) return setTimeout(() => resolve({ status:"error too many attempts" }), 200)

    const roleAndUser = getUserWith(u => u.email == x.email)
    if (roleAndUser.v.kind == 'l') return setTimeout(() => resolve({ status:"success" }), 200) // What to return if the provided e-mail does not exist?
    const user = roleAndUser.v.v.snd

    let token = Math.floor(Math.random() * 100000).toString()
    console.log(`Reset password: generated token ${token} for user ${user.username}`)
    setUser({...user, activation_or_reset_password_token:some(token)}, roleAndUser.v.v.fst)

    return setTimeout(() => resolve({ status:"success" }), 200)
  })

export let activate_account = (x:ActivateAccountData) : Promise<ActivateAccountResult> =>
    new Promise<ActivateAccountResult>((resolve, reject) => {
      if (Math.random() < 0.2) return setTimeout(() => reject("Cannot connect to server."), 2500)
      if (Math.random() < 0.05) return setTimeout(() => resolve({ status:"error too many attempts" }), 200)

      const roleAndUser = getUserWith(u => u.activation_or_reset_password_token.v.kind == "r" && u.activation_or_reset_password_token.v.v == x.token)

      if(roleAndUser.v.kind == "l") {
        if (Math.random() < 0.25) return setTimeout(() => resolve({ status:"account already active" }), 200)
        return setTimeout(() => resolve({ status:"token not found" }), 200)
      }
      let role = roleAndUser.v.v.fst
      let user = roleAndUser.v.v.snd

      if (Math.random() < 0.05) return setTimeout(() => resolve({ status:"token expired", email: user.email }), 200)

      const newUser = {...user, is_confirmed: true, activation_or_reset_password_token:none<string>() }
      setUser(newUser, role)

      const session = Math.random().toString(36).replace(/[^a-z]+/g, '')
      db = {...db, sessions:db.sessions.set(session, user) }

      return setTimeout(() => resolve({status:"success", user: newUser, role, session }), 200)
    })

export let login = (x: BasicCredentials & { role?: Role }, use_two_factor:string|false=false) : Promise<LoginResult> =>
    new Promise<LoginResult>((resolve, reject) => {
      if (Math.random() < 0.01) return setTimeout(() => reject("Cannot connect to server."), 2500)
      if (Math.random() < 0.01) return setTimeout(() => resolve({ status:"error too many attempts" }), 200)

      const roleAndUser = getUserWith(correctCredentialsPredicate(x))

      if (roleAndUser.v.kind == 'l') return setTimeout(() => resolve({ status:"error generic not authorized" }), 200)

      const user = roleAndUser.v.v.snd
      const role = roleAndUser.v.v.fst

      if (user.is_confirmed == false && account_validation) return setTimeout(() => resolve({ status:"error account not active", email: user.email }), 200)

      if (user.password_kind.kind == "two factor") {
        if (db.sms.has(role) == false) return setTimeout(() => resolve({ status:"error generic not authorized" }), 200)
        if (use_two_factor == false) {
          const new_pin = Math.round(Math.random() * 10000).toString()
          console.log(`New pin for user ${x.username} with value ${new_pin} is added to the system.`)
          db = {...db, sms:db.sms.set(role, db.sms.get(role)!.set(user.username, new_pin)) }
          return setTimeout(() => resolve({ status:"proceed with two factor" }), 200)
        }

        if (db.sms.get(role)!.has(user.username) == false) return setTimeout(() => resolve({ status:"error two factor wrong code" }), 200)
        const pinforUser = db.sms.get(role)!.get(user.username)!
        if (use_two_factor != pinforUser) return setTimeout(() => resolve({ status:"error two factor wrong code" }), 200)
      }

      const roles = getRolesforUser(user.username)
      if (roles.count() > 1) {
        if (x.role == undefined) return setTimeout(() => resolve({ status:"choose role", roles: roles.toArray()}), 200)
        if (roles.contains(x.role) == false) return setTimeout(() => resolve({ status:"error choose role incompatible role", roles: roles.toArray()}), 200)
      }

      const session = Math.random().toString(36).replace(/[^a-z]+/g, '')
      db = {...db, sessions:db.sessions.set(session, user) }

      return setTimeout(() => resolve({ status:"success", user, session, role }), 200)
    })

export let request_new_pin = (x:LoginDataTwoFactorData) : Promise<TwoFactorResult> =>
  new Promise<TwoFactorResult>((resolve, reject) => {
    if (Math.random() < 0.25) return setTimeout(() => reject("Cannot connect to server."), 2500)
    if (Math.random() < 0.25) return setTimeout(() => resolve({ status:"error too many attempts" }), 200)

    const roleAndUser = getUserWith(correctCredentialsPredicate(x))

    if (roleAndUser.v.kind == 'l') return setTimeout(() => resolve({ status:"error generic not authorized" }), 200)
    const user = roleAndUser.v.v.snd
    const role = roleAndUser.v.v.fst

    if (db.sms.has(role) == false) return setTimeout(() => resolve({ status:"error generic not authorized" }), 200)

    const new_pin = Math.round(Math.random() * 10000).toString()
    console.log(`New pin for user ${x.username} with value ${new_pin} is added to the system.`)
    db = {...db, sms:db.sms.set(role, db.sms.get(role)!.set(user.username, new_pin)) }

    return setTimeout(() => resolve({ status:"success" }), 200)
  })

  export let logout = (u:User, s:SessionId) : Promise<{}> =>
    new Promise<{}>((resolve, reject) => {
      if (Math.random() < 0.5) return setTimeout(() => reject("Cannot connect to server."), 2500)
      db = {...db, sessions:db.sessions.remove(s) }
      return setTimeout(() => resolve({}), 200)
    })

  export let change_pasword = (u:User, cpd:ChangePasswordData): Promise<ChangePasswordResult> =>
    new Promise<ChangePasswordResult>((resolve, reject) => {
      if (Math.random() < 0.25) return setTimeout(() => reject("Cannot connect to server."), 2500)

      if (cpd.old_password == undefined || cpd.new_password == undefined || cpd.new_password_confirmed == undefined) return resolve({status: "error missing fields"})
      const roleAndUser = getUserWith(correctCredentialsPredicate({ username: u.username, password: cpd.old_password}))

      if (roleAndUser.v.kind == 'l') return resolve({status: "error incorrect password"})
      const user = roleAndUser.v.v.snd
      const role = roleAndUser.v.v.fst

      if (scorePassword(cpd.new_password).res == "weak") return resolve({status: "error too weak password"})
      if (cpd.new_password != cpd.new_password_confirmed) return resolve({status: "error passwords do not match"})

      const password = cpd.new_password

      setUser({...user, password}, role)

      resolve({status: "success"})
    })

}