import * as React from "react"
import { Action, IOWidget, mk_widget } from "widgets-for-react"
import { CMSState } from "./state"
import { List } from "immutable"

export type EmbedBlockProps = {
  embedHtml: string
}

export type EmbedBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
}>

const getClassName = (
  options: { style?: EmbedBlockStyle },
  className: keyof EmbedBlockStyle,
  notset: string = ""
): string =>
  options && options.style && (options.style[className] || options.style[className] == null) ? options.style[className] : notset

  let sources_added = List<string>() //we use a list instead of a flag in case we use this blocks more than once in a page

  class HtmlBlockBody extends React.Component<EmbedBlockProps, { scripts: Element[], doc: Document, executed: boolean }> {
    constructor(props: EmbedBlockProps) {
      super(props)
      var scripts: Element[] = []
  
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.props.embedHtml, "text/html");
      const embeddedScripts = doc.querySelectorAll("script");
      embeddedScripts.forEach(s => {
        scripts.push(s)
        s.remove()
      })
      this.state = {
        scripts,
        doc, 
        executed: false
      }
    }
    render() {
      return <section className='block block--widget widget ls-spacing'>
        <div id="externalScript"
          className='widget__content'
          key={"body"} dangerouslySetInnerHTML={{ __html: this.state.doc.body.innerHTML }}></div>
      </section>
    }
    componentDidMount() {
      let counter = 0
      let run_script = () =>
  
        setTimeout(() => {
          if (counter > 10) return
          counter++
          this.setState(s => s, run_script)
        }, 200)
      run_script()
    }
    componentWillUnmount(): void {
      this.setState(s => ({...s, executed: false}))
    }
    firstDraw = true
    componentDidUpdate() {
      if (this.firstDraw) {
        this.firstDraw = false
        return
      }
      try {
  
        let loadScriptWithPromise = (script: Element) => {
  
          let tag = document.createElement('script');
  
          tag.async = false;
          tag.onload = () => {
          };
          tag.onerror = () => {
          };
  
          let src = script.getAttribute('src')
          let innerHTML = script.innerHTML
  
          if (src) {
            tag.src = src
          }
  
          if (innerHTML) {
            tag.innerHTML = innerHTML
          }
  
  
          if (sources_added.includes(tag.src)) return
  
          tag.className = "external-script"
  
          if (src != "" && src != undefined) {
            sources_added = sources_added.push(tag.src)
            document.head.appendChild(tag);
          }
          else if (!this.state.executed) {
            eval(innerHTML)
            this.setState(s => ({...s, executed: true}))
          }
        };
  
        for (const script of this.state.scripts) {
          loadScriptWithPromise(script)
        }
  
      }
      catch (e: any) {
        console.error(e)
      }
    }
  }

export function EmbedBlock<customBlockData, extra_images>(
  props: EmbedBlockProps,
  options?: { style?: EmbedBlockStyle; key?: string }
): IOWidget<CMSState<customBlockData, extra_images>, Action<CMSState<customBlockData, extra_images>>> {
  return (s: CMSState<customBlockData, extra_images>) =>
    mk_widget({
      run: _ => {

         return (
          <section className={getClassName(options, "block_classname", "container")} 
          key={options && options.key ? options.key : "embed-block"}
          style={{
            textAlign: 'center', 
            marginLeft: 'auto'
          }}
          >
            {props.embedHtml &&
             <HtmlBlockBody {...props} />
            }
          </section>
        )
      },
    })
}