
import { HttpResult } from "widgets-for-react";
import { BasicCredentials, User, ChangePasswordData, VerifyResetPasswordTokenData, ResetPasswordData, ResendActivationData, ActivateAccountData, SignUpData } from "./components/custom_portal/state";
import { Api } from "./components/custom_portal/mocked_api";
import { parse_date } from "./generated_api";
import * as Models from './generated_models';

export type LoginResult = HttpResult<{session_id:string, pos4:Models.POS4} | {session_id:string, user:Models.User} | {session_id:string, admin:Models.Admin} | {session_id:string, franchisemanager:Models.FranchiseManager} | 
                                     {choose_role:"pos4"|"user"|"admin"|"franchisemanager"}>
export async function login(x: BasicCredentials & {role?: string}, useTwoFactor?: string | false) : Promise<Api.LoginResult> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')
  let res = await fetch("/api/portal/ApiLogin" + (useTwoFactor ? "2Factor" : ""),
    {
      method: 'post', credentials: 'include',
      body: JSON.stringify({ Email: x.username, Password: x.password, Role: x.role ? x.role : "", Pin: useTwoFactor || "" }),
      headers: headers
    })

  if (!res.ok) {
    return { status:"error generic not authorized" }
  }

  let json = await res.json()

  if (json.status === "proceed with two factor") {
    return { status: "proceed with two factor" }
  }

  if (json.status === "error too many attempts") return json
  if (json.status === "error account not active") return { ...json, email: json.value }

  if (json.status != "success") {
    return { status: "error generic not authorized" }
  }

  if(json.role == "choose role"){
    return { status:"choose role", roles:json.value }
  }

  
  if(json.role == "pos4"){
    let pos4 = parse_date<Models.POS4>(json.value)
    return { status:"success", role:"pos4", session:json.session, user: { username: pos4.Username } }
  }

  if(json.role == "user"){
    let user = parse_date<Models.User>(json.value)
    return { status:"success", role:"user", session:json.session, user: { username: user.Username } }
  }

  if(json.role == "admin"){
    let admin = parse_date<Models.Admin>(json.value)
    return { status:"success", role:"admin", session:json.session, user: { username: admin.Username } }
  }

  if(json.role == "franchisemanager"){
    let franchisemanager = parse_date<Models.FranchiseManager>(json.value)
    return { status:"success", role:"franchisemanager", session:json.session, user: { username: franchisemanager.Username } }
  }
  return { status:"error generic not authorized" }
}

export async function logout(u: User, s: string, rootpath:string) : Promise<{}> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  await fetch("/api/portal/ApiLogout",
    {
      method: 'post', credentials: 'include',
      body: JSON.stringify({ SessionId:s }),
      headers: headers
    })

  return {}
}

export async function is_session_valid(email:string, session_id:string, role:string): Promise<HttpResult<"valid" | "invalid">> {
  const res = await fetch("/api/portal/IsSessionValid", {
    method: "post",
    credentials: "include",
    body: JSON.stringify({Email: email, CookieName: session_id, UserKind:role}),
    headers: {
      "content-type": "application/json",
      "X-XSRF-TOKEN": (document.getElementsByName("__RequestVerificationToken")[0] as any).value,
    },
  })
  if (!res.ok) { return {kind: "failed", status: 400}}
  const json = await res.json()
  return {kind: "result", status: 200, value: json.status == "error generic not authorized" ? "invalid" : json as boolean ? "valid" : "invalid"}
}


export async function change_password(
  u: User,
  cpd: ChangePasswordData,
): Promise<Api.ChangePasswordResult> {
  const res = await fetch("/api/portal/ChangePassword", {
    method: "post",
    credentials: "include",
    body: JSON.stringify({
      Password: cpd.old_password,
      NewPassword: cpd.new_password,
      NewPasswordConfirmation: cpd.new_password_confirmed,
    }),
    headers: {
      "content-type": "application/json",
      "X-XSRF-TOKEN": (document.getElementsByName("__RequestVerificationToken")[0] as any).value,
    },
  })
  if (!res.ok) {
    const json = await res.json()
    console.error({json})
    return { status:"error missing fields" }
  }
  const json = await res.json()

  if (!json.status) {
    return { status:"error missing fields" }
  }

  return { status: json.status }
}

export async function request_reset_password(email: string): Promise<Api.ResetPasswordRequestResult> {
  const res = await fetch("/api/portal/ForgotPassword", {
    method: "post",
    credentials: "include",
    body: JSON.stringify({ Email: email }),
    headers: {
      "content-type": "application/json",
      "X-XSRF-TOKEN": (document.getElementsByName("__RequestVerificationToken")[0] as any).value,
    },
  })
  if (!res.ok) {
    const json = await res.json()
    throw Error(json.message)
  }
  const jsonRes: Api.ResetPasswordRequestResult = { status: "success" }

  return jsonRes
}

export async function request_activation_email(x: ResendActivationData): Promise<Api.ResendActivationResult> {
  const res = await fetch("/api/portal/RequestActivationEmail", {
    method: "post",
    credentials: "include",
    body: JSON.stringify({ Email: x.email }),
    headers: {
      "content-type": "application/json",
      "X-XSRF-TOKEN": (document.getElementsByName("__RequestVerificationToken")[0] as any).value,
    },
  })
  if (!res.ok) {
    const json = await res.json()
    throw Error(json.message)
  }
  let data = await res.json()
  if (data.status === "error too many attempts") return ({ status: "error too many attempts" })
  return ({status:"success"})
}
export async function reset_password(x: ResetPasswordData): Promise<Api.ResetPasswordResult> {
  const res = await fetch("/api/portal/ResetPassword", {
      method: "post",
      credentials: "include",
      body: JSON.stringify({ Token: x.reset_password_token, Email: x.email, Password: x.password, ConfirmPassword: x.confirmed_password }),
      headers: {
        "content-type": "application/json",
        "X-XSRF-TOKEN": (document.getElementsByName("__RequestVerificationToken")[0] as any).value,
      }
    })
    let data = await res.json()
    if (!data.status) return ({ status: "error token not found" })
    if (data.status === "success") return ({ status: "success", username: x.email })
    return ({ status: data.status })
  }

export async function activate_account(x: ActivateAccountData): Promise<Api.ActivateAccountResult> {
  const res = await fetch("/api/portal/ActivateEmailWithToken", {
      method: "post",
      credentials: "include",
      body: JSON.stringify({ Token: x.token, Email: x.email}),
      headers: {
        "content-type": "application/json",
        "X-XSRF-TOKEN": (document.getElementsByName("__RequestVerificationToken")[0] as any).value,
      }
    })
    let data = await res.json()
    if (!data.status) return ({ status: "token expired", email: x.email})
    if (data.status === "success"){
      
      if(data.role == "pos4"){
        let pos4 = parse_date<Models.POS4>(data.value)
        return { status:"success", role:"pos4", session:data.session, user: { username: pos4.Username } }
      }

      if(data.role == "user"){
        let user = parse_date<Models.User>(data.value)
        return { status:"success", role:"user", session:data.session, user: { username: user.Username } }
      }

      if(data.role == "admin"){
        let admin = parse_date<Models.Admin>(data.value)
        return { status:"success", role:"admin", session:data.session, user: { username: admin.Username } }
      }

      if(data.role == "franchisemanager"){
        let franchisemanager = parse_date<Models.FranchiseManager>(data.value)
        return { status:"success", role:"franchisemanager", session:data.session, user: { username: franchisemanager.Username } }
      }
    }
    if (data.status === "error token not found") return ({ status: "token not found" })
    if (data.status === "error account already active") return ({ status: "account already active" })
    if (data.status === "error token not valid") return ({ status: "token expired", email:x.email })    
    return ({ status: "token not found" })
  }

export let verifyResetPasswordToken = async ( x: VerifyResetPasswordTokenData): Promise<Api.ResetPasswordResult> => {
  const res = await fetch("/api/portal/VerifyResetPasswordToken", {
    method: "post",
    credentials: "include",
    body: JSON.stringify({
      Email: x.email,
      Token: x.reset_password_token,
    }),
    headers: {
      "content-type": "application/json",
      "X-XSRF-TOKEN": (document.getElementsByName("__RequestVerificationToken")[0] as any).value,
    },
  })
  if (!res.ok) {
    const json = await res.json()
    throw Error(json.message)
  }
  let data = await res.json()
  if (data.status) return ({ status: data.status })
  return ({ status: "error token not found" })
}

export async function signUpApi(user: SignUpData) : Promise<Api.SignUpResult> {
  let headers = new Headers()
  headers.append('content-type', 'application/json')

  // Check password strength
  if (Api.scorePassword(user.password).res === 'weak' || Api.scorePassword(user.password).res === 'almost good') {
    return { status: 'too weak password' }
  }

  // Create user data
  const res = await fetch('/api/portal/ApiRegister', {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify({
      ...user,
      FirstName: user.name,
      LastName: user.surname,
      Email: user.email,
      EmailConfirmation: user.confirmed_email,
      Password: user.password,
      PasswordConfirmation: user.confirmed_password }),
    headers: {
      "content-type": "application/json",
      "X-XSRF-TOKEN": (document.getElementsByName("__RequestVerificationToken")[0] as any).value,
    },
  })

  if (!res.ok) {
    return { status: 'error' }
  }

  let json = await res.json()

  switch (json.status) {
    case 'email invalid':
      return json
    case 'password invalid':
      return json
    case 'account already exists':
      return json
    case 'success inactive':
      return json
    case 'too weak password':
      return json
        
    default:
      return { status: 'error' }
  }
}