import * as React from "react"
import { Action, IOWidget, mk_pair, mk_widget, Widget } from "widgets-for-react"
import { CMSState } from "./state"
import { link } from "./utils"
import { Link } from "./types";
import { ExtraImages } from "../public_site_utils/extra_images";

export type ExpandCampaignBlockProps = {
  block_index: number
  title?: string
  intro?: string
  title_options?: {
    size: string,
    font: string
  }
  text_options?: {
    size: string,
    font: string
  }
  items: ExpandCampaignProps[]
  link?: Link
}

export type ExpandCampaignBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
  header_classname: string
  title_classname: string
  intro_classname: string
  content_classname: string
  text_classname: string
  footer_classname: string
  clickable_classname: string
  list_classname: string
  expand_item_classname: string
  expand_button_classname: string
  expand_title_classname: string
  expand_icon_classname: string
  expand_text_classname: string
}>

export type ExpandCampaignProps = {
  key?: string,
  title: string,
  text: string,
  title_options?: {
    size: string,
    font: string
  },
  text_options?: {
    size: string,
    font: string
  },
  open: boolean
}

const getClassName = (
  options: { style?: ExpandCampaignBlockStyle },
  className: keyof ExpandCampaignBlockStyle,
  notset: string = ""
): string =>
  options && options.style && (options.style[className] || options.style[className] == null) ? options.style[className] : notset

export function ExpandCampaignBlock<CustomBlockData>(
  props: ExpandCampaignBlockProps,
  options?: { style?: ExpandCampaignBlockStyle; key?: string }
): IOWidget<CMSState<CustomBlockData, ExtraImages>, Action<CMSState<CustomBlockData, ExtraImages>>> {
  return (s: CMSState<CustomBlockData, ExtraImages>) =>
    mk_widget({
      run: onDone => (
        <section className={getClassName(options, "block_classname", "container")} key={options && options.key ? options.key : "expand-block"}>
          <div className={getClassName(options, "inner_classname", "py-5")}>
            <header className={getClassName(options, "header_classname", "")}>
              {props.title &&
                <h2
                  className={getClassName(options, "title_classname", "mb-3")}
                  dangerouslySetInnerHTML={{ __html: props.title }}
                />
              }
              {props.intro &&
                <div
                  className={getClassName(options, "intro_classname", "")}
                  dangerouslySetInnerHTML={{ __html: props.intro }}
                />                
              }
            </header>

            <div className={getClassName(options, "content_classname", "")}>
              <ul className={getClassName(options, "list_classname", "accordion list-unstyled mb-3")}>
                {props.items.map((value, index) =>
                  ExpandCampaign(
                    {
                      key: value.title.replace(/\s+/g, '-') + `${index}`,
                      text: value.text,
                      open: value.open,
                      title: value.title,
                      title_options: value.title_options,
                      text_options: value.text_options
                    },
                    options
                  ).run(action => {
                    let openNew = action(value.open)
                    onDone(s1 => {
                      let currentBlock = s1.blocks.get(props.block_index) as any
                      if (currentBlock.kind === "expandCampaignBlock") {
                        currentBlock = {
                          ...currentBlock,
                          value: mk_pair(
                            {
                              ...currentBlock.value.fst,
                              items: currentBlock.value.fst.items.map(
                                (e:any, i: number) => (i === index ? { ...e, open: openNew } : e)
                              ),
                            },
                            currentBlock.value.snd
                          ),
                        }
                      }
                      return { ...s1, blocks: s1.blocks.set(props.block_index, currentBlock) }
                    })
                  })
                )}
              </ul>
            </div>

            {props.link &&
              <footer className={getClassName(options, "footer_classname", "")}>
                {link(props.link,
                      {classname: getClassName(options, "clickable_classname", "button button-link pl-0")},
                      props.link.title)
                }
              </footer>
            }
          </div>
        </section>
      ),
    })
}

function ExpandCampaign(
  props: ExpandCampaignProps,
  options?: { style?: ExpandCampaignBlockStyle; key?: string }
): Widget<Action<boolean>> {
  return mk_widget({
    run: onDone =>
      <li className={getClassName(options, "expand_item_classname", "card")} key={props.key} aria-expanded={props.open}>
        <button
          onClick={() => onDone(s => !s)}
          className={getClassName(options, "expand_button_classname", 'card-header text-left border-0')}
          aria-controls={`a11y-${props.key}`}
        >
          <h3
            className={`${getClassName(options, "expand_title_classname", "button button-link m-0 pl-0")}${props.title_options && props.title_options.size ? ` c-${props.title_options.size}` : ''}${props.title_options && props.title_options.font ? ` c-font--${props.title_options.font}` : ''}`}
            dangerouslySetInnerHTML={{ __html: props.title }}
          />
          <svg className={getClassName(options, "expand_icon_classname", "font-weight-bold mr-2 text-primary")} aria-hidden="true"><use xlinkHref="#icon--caret-down-thin" /></svg>
        </button>

        <div
          id={`a11y-${props.key}`}
          className={`${getClassName(options, "expand_text_classname", `card-body pb-1 ${props.open ? "" : "d-none"} text-left border-0`)}${props.text_options && props.text_options.size ? ` c-${props.text_options.size}` : ''}${props.text_options && props.text_options.font ? ` c-font--${props.text_options.font}` : ''}`}
          aria-hidden={!props.open}
          dangerouslySetInnerHTML={{ __html: props.text }}
        />
      </li>
  })
}
