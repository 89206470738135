import { Int } from "io-ts";

export type Ecommerce = {
  event?: string
  category?: string
  action?: string
  label?: string
  ecommerce?: {
    currencyCode?: string
    add?: {
      products: [{
        name: string
        id: string
        price: string
        brand: string
        category: string
        variant: string
        quantity: number
      }]
    }
    remove?: {
      products: [{
        name: string
        id: string
        price: string
        brand: string
        category: string
        variant: string
        quantity: number
      }]
    }
    purchase?: {
      actionField: {
        id: string
        affiliation: string
        revenue: string
        shipping: string
        coupon: string
      }
      products: [{
        name: string
        id: string
        price: string
        brand: string
        category: string
        variant: string
        quantity: number
      }]
    }
    checkout?: {
      actionField: {
        step: number,
        action: string,
      },
      products: [{
        name: string
        id: string
        price: string
        brand: string
        category: string
        variant: string
        quantity: number
      }]
    }
  }
}

 export const pushToDataLayer = (data: Ecommerce) => {
  if (window["dataLayer"] !== null && window["dataLayer"] !== undefined) {
    window["dataLayer"].push(data)
  }
}

 export const pageViewDataLayer = (slug: string) => {
  if (window["dataLayer"] !== null && window["dataLayer"] !== undefined) {
    window["dataLayer"].push({
      event: "Pageview",
      url: slug,
    })
  }
}
