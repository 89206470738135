import * as React from "react"
import { Widget, mk_widget } from "widgets-for-react"

export const styleguideComponents: Widget<never> = mk_widget({
  run: _ => (
    <>
      <section id="components" className="styleguide-chapter styleguide-chapter--components">
        <header className="styleguide--styled styleguide-chapter__header">
          <h2 className="styleguide__title styleguide-chapter__title">Design Components</h2>
          <p className="styleguide-chapter__intro"></p>
        </header>

        <div className="styleguide-chapter__content">
          <section id="components-contentblocks" className="styleguide-section styleguide-section--components">
            <header className="styleguide--styled styleguide-section__header">
              <h3 className="styleguide-section__title">Content blocks</h3>
              <p className="styleguide-section__intro"></p>
            </header>

            <div className="styleguide-section__content">
              <section className="styleguide-part">
                <header className="styleguide--styled styleguide-part__header">
                  <h4 className="styleguide-part__title">Text block</h4>
                </header>

                <div className="styleguide-part__content">
                  <section className="styleguide-example">
                    <div className="styleguide-example__section cf">
                      <section className="block--text">
                        <div className="block__inner gutter--default">
                          <h2 className="block__title title--l">Lorem ipsum dolor sit amet</h2>
                          <div className="text--styled">
                            <p>Consectetuer adipiscing elit. Integer consequat sapien a lectus gravida euismod. Sed vitae nisl non odio <strong>dikgedrukte tekst</strong> accumsan. <em>schuine tekst</em>, vulputate sit amet, luctus vitae, dolor. Cras lacus massa, sagittis ut, <a href="">link in lopende tekst</a> consequat, interdum a, nulla.</p>
                            <ul>
                              <li>Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</li>
                              <li>Aenean lacinia bibendum nulla sed consectetur.</li>
                              <li>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</li>
                              <li>Nullam quis risus eget urna mollis ornare vel eu leo.</li>
                              <li>Nulla vitae elit libero, a pharetra augue.</li>
                            </ul>
                            <h3>Lorem ipsum dolor sit amet</h3>
                            <p>Consectetuer adipiscing elit. Integer consequat sapien a lectus gravida euismod. Sed vitae nisl non odio viverra accumsan. Curabitur nisi neque, egestas sed, vulputate sit amet, luctus vitae, dolor. Cras lacus massa, sagittis ut, volutpat consequat, interdum a, nulla</p>
                            <ol>
                              <li>Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</li>
                              <li>Aenean lacinia bibendum nulla sed consectetur.</li>
                              <li>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</li>
                              <li>Nullam quis risus eget urna mollis ornare vel eu leo.</li>
                              <li>Nulla vitae elit libero, a pharetra augue.</li>
                            </ol>
                            <h4>Lorem ipsum dolor sit amet</h4>
                            <p>Consectetuer adipiscing elit. Integer consequat sapien a lectus gravida euismod. Sed vitae nisl non odio viverra accumsan. Curabitur nisi neque, egestas sed, vulputate sit amet, luctus vitae, dolor. Cras lacus massa, sagittis ut, volutpat consequat, interdum a, nulla</p>
                            <blockquote>
                              <p>Consectetuer adipiscing elit. Integer consequat sapien a lectus gravida euismod. Sed vitae nisl non odio viverra accumsan. Curabitur nisi neque, egestas sed, vulputate sit amet, luctus vitae, dolor. Cras lacus massa, sagittis ut, volutpat consequat, interdum a, nulla</p>
                            </blockquote>
                          </div>

                          <div className="block__footer">
                            <a className="button button--primary">Morbi leo</a>
                            <a className="button button--link">Ridiculus mus</a>
                          </div>
                        </div>
                      </section>
                    </div>
                  </section>
                </div>
              </section>
            </div>
          </section>
        </div>
      </section>
    </>
  )
})