import { Action, any, button, div, hn, label, link, nothing, onlyIf, string, Widget } from "widgets-for-react";
import { pushToDataLayer } from '../../public_site_utils/ecommerce';
import { Api } from '../api_to_state';
import { isResetPasswordRequestState, LoggedOutState, LoginState, ResetPasswordRequestState } from '../state';
import i18next = require('i18next');

export let reset_password_request_form = <a>(s0: ResetPasswordRequestState, readonly: boolean, rootpath: string, api?: Api<a>): Widget<Action<LoginState<a>>> =>
  div<Action<LoginState<a>>>({ className: "login" })(
    div<Action<LoginState<a>>>({ className: "login__inner" })([
      hn<Action<LoggedOutState>>(1, i18next.t('reset_password_request_form:forgot_password'), { className: "login__title title--l" })(),
      div<Action<LoginState<a>>>({ className: "text--styled login__link button--full-width" })([
        string({ readonly: true, readonly_element: 'nothing' })(i18next.t('reset_password_request_form:old_accounts_are_gone:before_link')).never(),
        link(i18next.t('reset_password_request_form:old_accounts_are_gone:link'), `${rootpath}/register`).never(),
        string({ readonly: true, readonly_element: 'nothing' })(i18next.t('reset_password_request_form:old_accounts_are_gone:after_link')).never(),
      ]).never(),
      s0.status == "reset password request - error user not activated" ?
        div<Action<LoginState<a>>>({ className: "alert alert-danger" })([
          string({ readonly: true, readonly_element: "p" })(i18next.t('reset_password_request_form:account_not_yet_activated')).never(),
          button<Action<LoginState<a>>>(i18next.t('reset_password_request_form:send_activationmail'), { className: "button button--primary", disabled: readonly })(() => s => isResetPasswordRequestState(s) ? { status: "resend activation - resending", login_data: s0.login_data } : s)
        ])
        : nothing(),
      any<Action<LoginState<a>>>()([
        div<Action<ResetPasswordRequestState>>({ className: "field" })([
          label<Action<ResetPasswordRequestState>>(i18next.t('reset_password_request_form:email'), { htmlFor: "username", label_position: "before", className: "label" })(
            string({ readonly, readonly_element: "input", type: "email", id: "username", className: `${s0.login_data.email != undefined && !(api && api.isValidEmail && api.isValidEmail(s0.login_data.email)) ? "input--error" : ""}` })(s0.login_data.email || "")
              .map<Action<ResetPasswordRequestState>>(x => s => ({ status: "reset password request", login_data: { ...s.login_data, email: x } })),
          ),
          onlyIf<LoginState<a>>(s0.login_data.email != undefined && !(api && api.isValidEmail && api.isValidEmail(s0.login_data.email)),
            div<LoginState<a>>({ className: "inline-error" })(
              string({ readonly: true, readonly_element: "p" })(i18next.t('reset_password_request_form:invalid_email')).never()
            )
          ).never(),
        ]).map<Action<LoginState<a>>>(a => s => isResetPasswordRequestState(s) ? a(s) : s),
        div<Action<LoginState<a>>>()(
          button<Action<ResetPasswordRequestState>>(i18next.t('reset_password_request_form:send_new_password'),
            { className: "button button--primary button--full-width", disabled: readonly })
            (() => s => {
              pushToDataLayer({
                event: "event-beren",
                category: "Wachtwoord vergeten",
                action: "verzend",
                label: "formulier",
              })
              return (
                api && api.isValidEmail && api.isValidEmail(s0.login_data.email!)
                  ? ({ status: "reset password request - resetting", login_data: s0.login_data })
                  : ({ ...s0, login_data: { ...s0.login_data, email: s0.login_data.email == undefined ? "" : s0.login_data.email } })
              )
            })
        ).map<Action<LoginState<a>>>(a => s => isResetPasswordRequestState(s) ? a(s) : s)
      ]),
      div<Action<LoginState<a>>>({ className: "login__link text--styled" })([
        string({ readonly: true, readonly_element: "p" })(i18next.t('reset_password_request_form:do_you_already_have_an_account')).never(),
        link(i18next.t('reset_password_request_form:login'), rootpath, { key: "sign-in" }).never()
      ]),
    ])
  )
