import * as React from "react"
import { div, any, hn, mk_widget, onlyIf, button, string, Widget, mkWidget } from "widgets-for-react";
import { Link } from "react-router-dom"

type CookiebarState =
  | {
      accepted: "Accepted" | "no"
    }
  | {
      accepted: "SelectFunctional"
      video: boolean
      advertising: boolean
    }

export const cookieName = "viewed_cookie_policy"
export const videoCookie = "accepts_video"
export const analyticsCookie = "accepts_analytics"
export const advertisingCookie = "accepts_advertisements"

export class Cookiebar extends React.Component<{}, CookiebarState> {
  constructor(props: CookiebarState, context: any) {
    super(props, context)
    this.state = {
      accepted: "no",
    }
  }

  componentWillMount() {
    this.setState({
      accepted: getCookie(cookieName)
        ? getCookie(cookieName) === "yes"
          ? "Accepted"
          : "no"
        : "no",
    })
  }

  setCookieBarCookie(cookie: string) {
    setCookie(cookie, "yes", 1000)
  }

  buildProps = (): CookiebarOptions => ({
    cookieBarState: this.state,
    text: {
      title: "Wij plaatsen cookies",
      main_text:
        "Als je cookies accepteert, kan je deze website makkelijker gebruiken, vind je sneller wat je zoekt en zie je meer relevante informatie. Soms zijn cookies noodzakelijk, maar niet altijd. Je kan zelf je cookievoorkeuren aanpassen. Meer informatie vind je onder ",
    },
    acceptButton: {
      caption: "Accepteren",
      onAccept: () => {
        if (this.state.accepted === "no" || (this.state.accepted === "SelectFunctional" && this.state.video)) {
          this.setCookieBarCookie(videoCookie)
        }

        if (this.state.accepted === "no" || (this.state.accepted === "SelectFunctional" && this.state.advertising)) {
          this.setCookieBarCookie(advertisingCookie)
        }

        this.setCookieBarCookie(analyticsCookie)
        this.setCookieBarCookie(cookieName)
        
        window.location.reload()
      },
    },
    acceptOnlyFunctionalLink: {
      caption: "Cookievoorkeuren aanpassen",
      onAccept: () => {
        this.setState({ accepted: "SelectFunctional", video: true, advertising: true })
      },
    },
    privacyStatementLink: {
      caption: "Cookies & privacy.",
      link: "/cookies-privacy",
    },
    videoCheckbox: () => {
      if (this.state.accepted === "SelectFunctional") {
        this.setState({ accepted: "SelectFunctional", video: !this.state.video, advertising: this.state.advertising })
      }
    },
    advertisingCheckbox: () => {
      if (this.state.accepted === 'SelectFunctional') {
        this.setState({ accepted: 'SelectFunctional', video: this.state.video, advertising: !this.state.advertising })
      }
    }
  })
  cookieBar = (): JSX.Element =>
    simpleCookieBar(this.buildProps())
      .widget()
      .run(() => {
        return
      })

  render() {
    return this.state.accepted === "Accepted" ? "" : this.cookieBar()
  }
}

export type CookiebarOptions = {
  cookieBarState: CookiebarState
  text: { title: string; main_text: string }
  acceptButton: { caption: string; onAccept: () => void }
  acceptOnlyFunctionalLink: { caption: string; onAccept: () => void }
  privacyStatementLink: { caption: string; link: string }
  videoCheckbox: () => void
  advertisingCheckbox: () => void
}

export const simpleCookieBar = (options: CookiebarOptions) => ({
  widget: () =>
    div({ className: "cookiebar", key: "cookiebar-key" })(
      div({ className: "cookiebar__inner", key: "cookiebar-key" })(
        any({ key: "cookiebar-any-key" })([
          hn(4, options.text.title, { className: "title--xs", key: "cookiebar-title-key" })().never(),
          mk_widget({
            run: () =>
              cookieBarBody({
                key: "cookiebar-text-key",
                main_text: options.text.main_text,
                privacyStatement: options.privacyStatementLink,
              }),
          }),
          onlyIf(
            options.cookieBarState.accepted === "SelectFunctional",
            mk_widget({ run: () => cookieSelector(options) })
          ).never(),
          div({ className: "cookiebar__buttons" })([
            button<void>(options.acceptButton.caption, {
              className: "cookiebar__button button button--primary",
              key: "cookiebar-accept-key",
            })(() => {
              options.acceptButton.onAccept()
            }).never(),
            onlyIf(
              options.cookieBarState.accepted === "no",
              mkWidget({ run: _ =>
                <button className="cookiebar__link button button-link" key="cookiebar-functional-key" onClick={() => options.acceptOnlyFunctionalLink.onAccept()}>
                  <span>{options.acceptOnlyFunctionalLink.caption}</span>
                </button>
              }),
            ),
          ]),
        ])
      )
    ),
})

const cookieBarBody = (options: {
  key?: string
  main_text: string
  privacyStatement: { caption: string; link: string }
  endStatement?: string | "."
}) => (
  <p className="cookiebar__text text--styled" key={options.key}>
    {options.main_text}
    <Link className="cookiebar__privacy nav-link" to={options.privacyStatement.link}>
      {options.privacyStatement.caption}
    </Link>
    {options.endStatement}
  </p>
)

const cookieSelector = (options: CookiebarOptions) => (
  <div className="cookiebar-selector">
    <div className="cookiebar-selector__option">
      <input id="functional-cookies" type="checkbox" checked={true} disabled={true} />
      <label htmlFor="functional-cookies">
        <strong>Functioneel:</strong>
        <span>
          Deze cookies zijn noodzakelijk voor het goed functioneren van de website (vereist).
        </span>
      </label>
    </div>
    <div className="cookiebar-selector__option">
      <input id="analytical-cookies" type="checkbox" checked={true} disabled={true} />
      <label htmlFor="analytical-cookies">
        <strong>Analytisch:</strong>
        <span>
          Deze cookies zijn bedoeld om het gebruik van de website te kunnen analyseren. Hierbij
          slaan we geen persoonsgegevens op (vereist).
        </span>
      </label>
    </div>
    <div className="cookiebar-selector__option">
      <input
        id="video-cookies"
        type="checkbox"
        // checked={options.cookieBarState.accepted === "SelectFunctional" && options.cookieBarState.video}
        onChange={e => {
          options.videoCheckbox()
        }}
      />
      <label htmlFor="video-cookies">
        <strong>Externe diensten:</strong>
        <span>Deze cookies zijn nodig om bijvoorbeeld een tafel te reserveren of een video te bekijken.</span>
      </label>
    </div>
    <div className="cookiebar-selector__option">
      <input
        id="advertising-cookies"
        type="checkbox"
        // checked={options.cookieBarState.accepted === "SelectFunctional" && options.cookieBarState.advertising}
        onChange={() => options.advertisingCheckbox()}
      />
      <label htmlFor="advertising-cookies">
        <strong>Advertenties:</strong>
        <span>
          Deze cookies worden gebruikt om advertenties via het advertentienetwerk van De Beren 
          weer te geven die toegesneden zijn op en relevant zijn voor de individuele gebruiker.
        </span>
      </label>
    </div>
  </div>
)

export interface CookieSensitiveContentWrapperProps {
  children: React.ReactNode
  placeholder: React.ReactNode
}

export class CookieSensitiveContentWrapper extends React.Component<CookieSensitiveContentWrapperProps, CookieSettingsState> {

  constructor(props: CookieSensitiveContentWrapperProps) {
    super(props)

    this.state = {
      cookieStatus: getCookieStatus(videoCookie),
      unsubListener: () => { }
    }
  }

  componentDidMount() {
    const unsubListener = addAcceptCookiesListener(() => this.setState(updateCookieStatus))
    this.setState(s => ({ ...s, unsubListener }))
  }

  componentWillUnmount() {
    this.state.unsubListener()
  }

  render() {
    if (this.state.cookieStatus == 'accepted') return this.props.children

    return (
      <div className="cookiebar-placeholder">
        <div className="cookiebar-placeholder__inner">
          {this.props.placeholder}

          <footer className="cookiebar-placeholder__actions">
            <button
              className="button button--primary"
              onClick={_ => acceptCookies()}
            >Ik accepteer cookies</button>
          </footer>
        </div>
      </div>
    )
  }
}


export function setCookie(cname: string, cvalue: string, exdays: number) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname: string) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export type CookieStatus = 'accepted' | 'rejected' | 'undecided'

export type CookieSettingsState = { cookieStatus: CookieStatus, unsubListener: () => void }

const cookiesAcceptedEvent = 'cookies_accepted'

export const getCookieStatus = (name: string) : CookieStatus => {
  const cookiesCookie = getCookie(name)
  return cookiesCookie  == 'yes'
    ? 'accepted'
    : 'undecided'
}

export const acceptCookies = () => {
  setCookie(videoCookie, 'yes', 30)
  const event = new CustomEvent(cookiesAcceptedEvent)
  window.dispatchEvent(event)
  if ((window as any).dataLayer) {
    (window as any).dataLayer.push(cookiesAcceptedEvent)
  }
}

export const rejectCookies = (currentStatus: CookieStatus) => {
  setCookie('cookies', 'rejected', 30)
  if (currentStatus === 'accepted') window.location.reload()
}

export const updateCookieStatus = (s: CookieSettingsState): CookieSettingsState => ({...s, cookieStatus: getCookieStatus(videoCookie)})

export const addAcceptCookiesListener = (onCookiesAccepted: () => void): () => void => {
  window.addEventListener(cookiesAcceptedEvent, onCookiesAccepted)

  return () => window.removeEventListener(cookiesAcceptedEvent, onCookiesAccepted)
}