import { LoginState, ResendActivationState, isResendActivationState } from '../state';
import { Action, Widget, any, div, string, button, onlyIf, link, hn, label } from "widgets-for-react";
import { Api } from '../api_to_state';
import * as i18next from 'i18next'

export let signup_success = <a>(s0: ResendActivationState, readonly: boolean, rootpath: string, api?: Api<a>): Widget<Action<LoginState<a>>> =>
  div<Action<LoginState<a>>>({ className: "login" })([
    div<Action<LoginState<a>>>({ className: "login__inner" })([
      hn<Action<LoginState<a>>>(1, i18next.t('signup_success:signup_successful'), { className: "login__title title--l" })(),
      any<Action<LoginState<a>>>()([
        div<ResendActivationState>({ className: 'signup_successful' })(
          string({ readonly: true, readonly_element: 'p' })(i18next.t('signup_success:signup_successful_text')).never()
        ).never(),
      ]),
      div<Action<LoginState<a>>>({ className: "login__link text--styled" })([
        string({ readonly: true, readonly_element: "p" })(i18next.t('signup_success:lost_activationmail')).never(),
        link(i18next.t('signup_success:go_to_resend_activationmail'), `${rootpath}/resend-activation`, { key: "resend-activation" }).never()
      ]),
    ]),
  ])