
import "babel-polyfill";
import "custom-event-polyfill";
import { polyfill as PromisePolyfill } from "es6-promise";
import * as i18next from "i18next";
import { List } from "immutable";
import * as ReactDOM from "react-dom";
import "url-search-params-polyfill";
import "whatwg-fetch";
import 'picturefill';
import 'picturefill/dist/plugins/mutation/pf.mutation.min';
import 'lazysizes';

import { Action, AsyncState, Fun, loadingAsyncState, mk_pair, none, Option, Pair, stateful, Widget } from "widgets-for-react";
import { ParsedNode } from "./components/drupal/drupal_generated/types";
import { Block, Breadcrumb } from "./components/drupal/drupal_utils/types";
import * as Api from "./components/drupal_api";
import { application, get_page, InitPagesStates } from "./components/public_site_client";
import { CustomBlock, CustomBlockData } from "./components/public_site_utils/custom_blocks_types";
import { CustomPagesState, booking_page, styleguide } from "./components/public_site_utils/custom_pages";
import { PublicSiteCustomRoutes } from "./components/public_site_utils/custom_route";
import { notfound_page } from "./components/public_site_utils/error_pages";
import { ExtraImages } from "./components/public_site_utils/extra_images";
import { FooterState } from "./components/public_site_utils/footer";
import { HeaderState, headerSwitcher } from "./components/public_site_utils/header";
import { loader } from "./components/public_site_utils/loader";
import { page } from "./components/public_site_utils/page";
import { blocks_renderer } from "./components/public_site_utils/widget_blocks_renderer";
import { TemplateState } from "./components/widget_blocks_client/PageTemplate";
import { AppState, PageTemplateState } from "./components/widget_blocks_client/types/state";
import * as GeneratedApi from "./generated_api";
import * as Models from "./generated_models";
import * as resources from "./locales";
import { state } from "./components/custom_portal/login";
import { PortalAppState, LoginState } from "./components/custom_portal/state";

PromisePolyfill();

export type PublicSiteState =
  {
    portal: PortalState,
    breadcrumbs: AsyncState<List<Breadcrumb>>,
    restaurant_list: AsyncState<Api.RestaurantList>
    header: HeaderState,
    footer: FooterState,
  } &
  PageTemplateState<CustomBlockData, CustomBlock, Block, ExtraImages> &
  CustomPagesState;

export type PortalState = PortalAppState<{}, { kind: string }>;

export const defaultPage: ContentType = x => x;

export type ContentType = (placeholder: Widget<Action<TemplateState<CustomBlockData, CustomBlock, Block, ExtraImages>>>) => Widget<Action<TemplateState<CustomBlockData, CustomBlock, Block, ExtraImages>>>;

let initPagesStates = InitPagesStates(blocks_renderer<CustomBlockData, ExtraImages>());

export const emptyAppState = (page: Fun<Pair<List<Breadcrumb>, Option<ParsedNode>>, ContentType>): PublicSiteState =>
  ({
    ...initPagesStates,
    breadcrumbs: Api.loadBreadcrumbs(window.location.pathname),
    restaurant_list: loadingAsyncState(() => Api.loadRestaurantList()),
    page: {
      ...initPagesStates.page,
      get_page: get_page,
      display: page(mk_pair(List(), none()))
    },
    header: {
      open: false,
      mode: "closed",
      menu_header: {
        nav_links: loadingAsyncState(() => Api.loadMenu("main"))
      }
    },
    footer: {
      nav_links: loadingAsyncState(() => Api.loadMenu("footer")),
    },
  } as PublicSiteState);

export const emptyLoginState: LoginState<{}> = {
  status: "logged out",
  login_data: {
    username: "",
    password: "",
    show_password: false
  }
};

export const emptyPortalAppState = state<{}, { kind: string }>(() => ({}), emptyLoginState, { kind: "404" }, "standard", none());

export let public_site_to = (slug: string, target_element_id: string, project_name: string, current_User: Models.User, current_Admin: Models.Admin) => {
  i18next.init({
    resources,
    lng: "nl",
    fallbackLng: "nl"
  });
  GeneratedApi.project_name_set(project_name);
  ReactDOM.render(
    stateful<AppState<PublicSiteState, PublicSiteCustomRoutes>>()(
      application({ rootpath: "", rootpath_to_ignore: "/profile", login_address: "/profile" },
        headerSwitcher,
        notfound_page,
        loader,
        page,
        {
          styleguide,
          booking_page,
        }
      ))
      ({
        current_widget: none(),
        route: {
          kind: "init"
        },
        state: emptyAppState(page)
      }).run((s) => null),
    document.getElementById(target_element_id)
  );
};