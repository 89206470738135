import { Map } from "immutable"
import * as React from "react"
import { Action, AsyncState, IOWidget, mk_widget, option } from "widgets-for-react"
import { ImageContainer } from "./ImageContainer"
import { CMSState } from "./state"
import { ImageSize, Url } from "./types"
import { CookieSensitiveContentWrapper } from "../public_site/cookiebar";

export type MediaBlockProps<extra_images> = {
  kind_media: "image" | "video"
  image?: number
  image_alt?: string
  size?: ImageSize<extra_images>
  video?: { url: string } & {
    id: string
    type: string
    width: string
    height: string
    frameBorder: string
    allowFullScreen: boolean
  }
  caption?: string
  cookieHandler?:{
    acceptAllCookies: () => void
    getCookie: (name: string) => string
    videoCookie: "accepts_video"
  }
}
export type MediaBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
  content_classname: string
  figure_classname: string
  media_classname: string
  image_classname: string
  placeholder_classname: string
  video_classname: string
  figcaption_classname: string
  caption_classname: string
  cookies_classname: string
  cookies_inner_classname: string
  cookies_text_classname: string
  cookies_clickable_classname: string
}>

const getClassName = (
  options: { style?: MediaBlockStyle },
  className: keyof MediaBlockStyle,
  notset: string = ""
): string =>
  options && options.style && (options.style[className] || options.style[className] == null) ? options.style[className] : notset

export function MediaBlock<CustomBlockData, extra_images>(
  props: MediaBlockProps<extra_images>,
  options?: { style?: MediaBlockStyle; key?: string }
): IOWidget<CMSState<CustomBlockData, extra_images>, Action<CMSState<CustomBlockData, extra_images>>> {
  return (cmsState: CMSState<CustomBlockData, extra_images>) =>
    mk_widget({
      run: _ => {
        return (
          <section className={getClassName(options, "block_classname", "container")} key={options && options.key ? options.key : "media-block"}>
            <div className={getClassName(options, "inner_classname", "py-5")}>
              <div className={getClassName(options, "content_classname", "")}>
                <figure className={getClassName(options, "figure_classname", "")}>
                  {props.kind_media === "image" && props.image
                    ? cmsState.images.has(props.image)
                      ? <div className={getClassName(options, "media_classname", "text-center")}>
                          {ImageContainer(
                            cmsState.images.get(props.image) as AsyncState<Map<ImageSize<extra_images>, string>>,
                            props.size,
                            getClassName(options, "image_classname", "w-100"),
                            props.image_alt
                          ).run(_ => {})}
                          {props.caption && (
                            <figcaption className={getClassName(options, "figcaption_classname", "")}>
                              <p className={getClassName(options, "caption_classname", "small text-secondary text-center mt-2")}>
                                {props.caption}
                              </p>
                            </figcaption>
                          )}
                        </div>
                      : null
                    : <CookieSensitiveContentWrapper
                        placeholder={(
                          <div className={getClassName(options, "cookies_classname", "text-center bg-light")}>
                            <div className={getClassName(options, "cookies_inner_classname", "py-5")}>
                              <p className="cookiebar-placeholder__text text--styled">Deze video kun je alleen bekijken als je de cookies accepteert. <a href="/cookies">Meer informatie over cookies</a>.</p>
                            </div>
                          </div>
                        )}>
                      <div className={getClassName(options, "video_classname", "text-center")}>
                        <iframe
                          className="embed-responsive-item"
                          width={props.video.width}
                          height={props.video.height}
                          frameBorder={props.video.frameBorder}
                          allowFullScreen={props.video.allowFullScreen}
                          src={props.video.url}
                        />
                      </div>
                      {props.caption && (
                        <figcaption className={getClassName(options, "figcaption_classname", "")}>
                          <p className={getClassName(options, "caption_classname", "small text-secondary text-center mt-2")}>
                            {props.caption}
                          </p>
                        </figcaption>
                      )}
                    </CookieSensitiveContentWrapper>
                  }
                </figure>
              </div>
            </div>
          </section>
        )
      }
    })
}