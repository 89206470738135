import * as React from "react"
import { Redirect } from "react-router-dom"
import { Action, mkWidget, Widget } from "widgets-for-react"
import * as moment from "moment-timezone"

export const formatPrice = (a: number) : string => new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(a)

export const cleanPhonenumber = (tel: string) => {
  let filteredTel = tel.replace(/([ ]+|[-]+|[()]+)/g, "")
  if (!(filteredTel.startsWith('0031') || filteredTel.startsWith('+31')) && filteredTel.startsWith("0")) {
    filteredTel = filteredTel.replace("0", "+31")
  }
  return filteredTel
}

export const readDBTime = (t) => moment.tz(t, "Europe/Amsterdam")
export const getNow = () => moment.tz("Europe/Amsterdam")

moment.fn.toJSON = function (this: moment.Moment) {
  return this.format()
}

export const redirectWidget = (url: string): Widget<any> => {
  return mkWidget({ run: _ => {
    window.location.href = url
    return null
  } })
}