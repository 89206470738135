import { notFoundRouteCase, route, Route, routerSwitch, Widget, IOWidget, Pair, Action, AsyncState, loadingAsyncState, HttpResult, unloadedAsyncState } from 'widgets-for-react'
import { DefaultState } from './types/state'

export type RouteParams = { kind: '404' } | { kind: 'page'; slug?: string } | { kind: "ignore"} | { kind: "init"}

export type CustomPages<
  CustomBlockData extends { kind: CustomBlockData['kind'] },
  CustomBlocks extends { kind: CustomBlocks['kind'] },
  StandardBlocks,
  CurrentState extends DefaultState<CustomBlockData, CustomBlocks, StandardBlocks, extra_images>,
  ContentTypes extends keyof CurrentState,
  CustomRoutes extends { kind: ContentTypes },
  extra_images
> = {
  [x in ContentTypes]: {
    widget: IOWidget<Pair<CustomRouteParams<CurrentState, CustomRoutes>, CurrentState[x]>, Action<CurrentState[x]>>
    route: Route<CustomRouteParams<CurrentState, CustomRoutes>>
    setState: (s: CurrentState, sp: CurrentState[x]) => CurrentState
    getState: (_: CurrentState) => CurrentState[x]
    updateStateBasesOnRoute: (s: CurrentState, r: CustomRouteParams<CurrentState, CustomRoutes>) => CurrentState
  }
}

export type CustomRouteParams<a, b extends { kind: keyof a }> =  b | RouteParams

export let router = <a, b extends { kind: keyof a }>(
  custom_routes: Route<CustomRouteParams<a, b>>[],
  rootpath: string,
  rootpath_to_ignore: string
): Widget<CustomRouteParams<a, b>> =>
  routerSwitch<CustomRouteParams<a, b>>({ key: `router-switch` })([
    //route<{}, CustomRouteParams<a, b>>(rootpath_to_ignore + '/*', _ => ({ kind: "ignore" })),
    //route<{}, CustomRouteParams<a, b>>(rootpath_to_ignore + "/", _ => ({ kind: "ignore" })),
    //other cases?
    ...custom_routes,
    route<{ slug?: string }, CustomRouteParams<a, b>>(rootpath + '/:slug*', a => ({ kind: 'page', slug: a.slug })),
    notFoundRouteCase<CustomRouteParams<a, b>>(() => ({ kind: '404' }))
  ])



