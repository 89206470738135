import { LoginState, ResendActivationState, isResendActivationState } from '../state';
import { Action, Widget, any, div, string, button, onlyIf, link, hn, label } from "widgets-for-react";
import { Api } from '../api_to_state';
import * as i18next from 'i18next'

export let resend_activation_form = <a>(s0: ResendActivationState, readonly: boolean, rootpath: string, api?: Api<a>): Widget<Action<LoginState<a>>> =>
  div<Action<LoginState<a>>>({ className: "login" })([
    div<Action<LoginState<a>>>({ className: "login__inner" })([
      hn<Action<LoginState<a>>>(1, i18next.t('resend_activation_form:resend_activation_email'), { className: "login__title title--l" })(),
      any<Action<LoginState<a>>>()([
        div<Action<ResendActivationState>>({ className: "field" })([
          label<Action<ResendActivationState>>(i18next.t('resend_activation_form:email'), { key: "login-widget-sign-up-form-email-label", htmlFor: "email", label_position: "before", className: "label" })(
            any<Action<ResendActivationState>>()([
              string({ readonly, readonly_element: "input", type: "email", id: "username", className: `${s0.login_data.email != undefined && !(api && api.isValidEmail && api.isValidEmail(s0.login_data.email)) ? "input--error" : ""}` })(s0.login_data.email || "")
                .map<Action<ResendActivationState>>(x => s => ({ status: "resend activation", login_data: { ...s.login_data, email: x } })),
              onlyIf<LoginState<a>>(s0.login_data.email != undefined && !(api && api.isValidEmail && api.isValidEmail(s0.login_data.email)),
                div<LoginState<a>>({ className: "inline-error" })(
                  string({ readonly: true, readonly_element: "p" })(i18next.t('resend_activation_form:invalid_email')).never()
                )
              ).never(),
            ])
          )
        ]).map<Action<LoginState<a>>>(a => s => isResendActivationState(s) ? a(s) : s),
        div<Action<LoginState<a>>>()(
          button<Action<ResendActivationState>>(i18next.t('resend_activation_form:send_activation_mail'),
            { className: "button button--primary button--full-width", disabled: readonly })
            (() => s => api && api.isValidEmail && api.isValidEmail(s0.login_data.email!) ? ({ status: "resend activation - resending", login_data: s0.login_data }) :
              { ...s0, login_data: { ...s0.login_data, email: s0.login_data.email == undefined ? "" : s0.login_data.email } })
        ).map<Action<LoginState<a>>>(a => s => isResendActivationState(s) ? a(s) : s),
      ]),
      div<Action<LoginState<a>>>({ className: "login__link text--styled" })([
        string({ readonly: true, readonly_element: "p" })(i18next.t('resend_activation_form:do_you_already_have_an_account')).never(),
        link(i18next.t('resend_activation_form:login'), rootpath).never()
      ]),
    ]),
  ])