import { Option, number, Widget, AsyncState } from "widgets-for-react";
import { RouteParams, CustomRouteParams } from "./router_state";
import { User as PublicSiteUser } from "../../custom_components/order_flow/server-communication/data_types"

export type SessionSetter = (user: User|undefined, session: SessionId|undefined, role: string|undefined) => void
export type SessionGetter = () => Option<UserSession>

export interface UserSession {
  readonly user: User
  readonly session: SessionId
  readonly role: string | undefined
}

export type Role = string

export interface BasicCredentials { username:string, password:string }
export interface LoginData extends BasicCredentials { show_password:boolean, role?:string, email?: string }

export const emptyLoginData: LoginData = { username: "", password: "", show_password: false}

export interface ChooseRoleData extends BasicCredentials { role?:string, roles:string[], pin?: string }

export interface LoginDataTwoFactorData extends BasicCredentials { pin:string }

export interface ChangePasswordData { old_password: string|undefined,
                                      new_password:string|undefined,
                                      new_password_confirmed:string|undefined,
                                      show_password: boolean }

export interface SignUpData { username:string|undefined
                              password:string|undefined
                              confirmed_password:string|undefined,
                              email:string|undefined,
                              confirmed_email:string|undefined,
                              name:string|undefined,
                              surname:string|undefined
                              agreed: true|false }

export interface ResetPasswordSuccessData extends BasicCredentials {}
export interface SignupSuccessData extends BasicCredentials {}

export interface ResetPasswordData { password:string|undefined,
                                     email:string|undefined,
                                     confirmed_password:string|undefined,
                                     reset_password_token:string|undefined }

export interface VerifyResetPasswordTokenData { reset_password_token:string|undefined, email:string|undefined }

export interface ActivateAccountData { token:string|undefined, email:string|undefined }

export interface ResetPasswordRequestData { email:string|undefined }

export interface ResendActivationData { email:string|undefined }

export interface PasswordKind { kind:"standard" | "two factor" }

export type Token = string

export interface PersistentUser extends BasicCredentials {
                        email:string,
                        password_kind:PasswordKind,
                        name:string,
                        surname:string,
                        is_confirmed: boolean,
                        activation_or_reset_password_token: Option<Token> }

export interface User { username: string }

export type UserInfo = AsyncState<PublicSiteUser>

export type SessionId = string

export type PrivacyKind = "standard"|"strict"

export interface LoggedInState<s> {
  readonly status: "logged in"
                 | "logged in - activation success"
                 | "logged in - signup success"
                 | "logged in - logout api error"
                 | "logged in - logging out",
  readonly user:User,
  readonly info:UserInfo
  readonly role:Role,
  readonly session:SessionId,
  readonly appState:s
}

export function isLoggedInState<a>(s: LoginState<a>): s is LoggedInState<a> {
  return s.status == "logged in" ||
         s.status == "logged in - activation success" ||
         s.status == "logged in - signup success" ||
         s.status == "logged in - logout api error" ||
         s.status == "logged in - logging out"
}

export interface ChangePasswordState<s> {
  readonly status: "change password"
                 | "change password - sending"
                 | "change password - api error"
                 | "change password - success"
                 | "change password - error"
                 | "change password - error missing fields"
                 | "change password - error incorrect password"
                 | "change password - error too weak password"
                 | "change password - error passwords do not match"
  readonly user:User
  readonly info:UserInfo
  readonly change_password_data: ChangePasswordData
  readonly session:SessionId
  readonly appState:s
  readonly role:Role
}

export function isChangePasswordState<a>(s: LoginState<a>): s is ChangePasswordState<a>  {
  return s.status == "change password" ||
         s.status == "change password - sending" ||
         s.status == "change password - api error" ||
         s.status == "change password - success" ||
         s.status == "change password - error" ||
         s.status == "change password - error missing fields" ||
         s.status == "change password - error incorrect password" ||
         s.status == "change password - error too weak password" ||
         s.status == "change password - error passwords do not match"
}

export interface LoggedOutState {
  readonly status: "logged out"
                 | "logged out - logging in"
                 | "logged out - login api error"
                 | "logged out - login not authorized"
                 | "logged out - account already active"
                 | "logged out - error account not active"
                 | "logged out - error too many reset password attempts"
                 | "logged out - error reset password token has expired"
                 | "logged out - error reset password token not found"
                 | "logged out - error too many account activation attempts"
                 | "logged out - error account activation token not found"
                 | "logged out - too many attempts",
  readonly login_data:LoginData
}

export function isLoggedOutState<a>(s: LoginState<a>): s is LoggedOutState  {
  return s.status == "logged out" ||
         s.status == "logged out - logging in" ||
         s.status == "logged out - login api error" ||
         s.status == "logged out - login not authorized" ||
         s.status == "logged out - too many attempts" ||
         s.status == "logged out - error too many reset password attempts" ||
         s.status == "logged out - error reset password token has expired" ||
         s.status == "logged out - error reset password token not found" ||
         s.status == "logged out - error too many account activation attempts" ||
         s.status == "logged out - error account activation token not found" ||
         s.status == "logged out - account already active" ||
         s.status == "logged out - error account not active"
}


export interface LoginTwoFactorState {
  readonly status: "two factor"
                 | "two factor - logging"
                 | "two factor - wrong code"
                 | "two factor - empty code"
                 | "two factor - login api error"
                 | "two factor - resend api error"
                 | "two factor - resending pin"
                 | "two factor - too many attempts",
  readonly login_data:LoginDataTwoFactorData
}


export function isLoginTwoFactorState<a>(s: LoginState<a>): s is LoginTwoFactorState  {
  return s.status == "two factor" ||
         s.status == "two factor - logging" ||
         s.status == "two factor - empty code" ||
         s.status == "two factor - wrong code" ||
         s.status == "two factor - resending pin" ||
         s.status == "two factor - resend api error" ||
         s.status == "two factor - login api error"
}

export interface SignUpState {
  readonly status: "sign up"
                 | "sign up - success"
                 | "sign up - signing up"
                 | "sign up - api error"
                 | "sign up - error"
                 | "sign up - error invalid email"
                 | "sign up - error weak password"
                 | "sign up - error invalid username"
                 | "sign up - error account not active"
                 | "sign up - error account already exists"
                 | "sign up - error too many attempts"
  readonly login_data:SignUpData
}


export function isSignUpState<a>(s: LoginState<a>): s is SignUpState  {
  return s.status == "sign up" ||
         s.status == "sign up - success" ||
         s.status == "sign up - api error" ||
         s.status == "sign up - error" ||
         s.status == "sign up - error invalid email" ||
         s.status == "sign up - error invalid username" ||
         s.status == "sign up - error account not active" ||
         s.status == "sign up - error account already exists" ||
         s.status == "sign up - error too many attempts" ||
         s.status == "sign up - error weak password"
}

export interface ResendActivationState {
  readonly status: "resend activation"
                 | "resend activation - signup success"
                 | "resend activation - success"
                 | "resend activation - api error"
                 | "resend activation - resending"
                 | "resend activation - error token expired"
                 | "resend activation - error too many attempts"
  readonly login_data:ResendActivationData
}

export function isResendActivationState<a>(s: LoginState<a>): s is ResendActivationState  {
  return s.status == "resend activation" ||
         s.status == "resend activation - success" ||
         s.status == "resend activation - api error" ||
         s.status == "resend activation - resending" ||
         s.status == "resend activation - error token expired" ||
         s.status == "resend activation - error too many attempts"
}

export interface ResetPasswordState {
  readonly status: "reset password"
                 | "reset password - resetting password"
                 | "reset password - api error"
                 | "reset password - error weak password"
                 | "reset password - error too many attempts"
  readonly login_data:ResetPasswordData
}

export function isResetPasswordState<a>(s: LoginState<a>): s is ResetPasswordState  {
  return s.status == "reset password" ||
         s.status == "reset password - resetting password" ||
         s.status == "reset password - api error" ||
         s.status == "reset password - error weak password" ||
         s.status == "reset password - error too many attempts"
}
export interface ResetPasswordSuccessState {
  readonly status: "reset password success"
                 | "reset password success - logging in"
                 | "reset password success - api error"
  readonly login_data:ResetPasswordSuccessData
}

export function isResetPasswordSuccessState<a>(s: LoginState<a>): s is ResetPasswordSuccessState {
  return s.status == "reset password success" ||
         s.status == "reset password success - logging in" ||
         s.status == "reset password success - api error"
}

export interface VerifyResetPasswordTokenState {
  readonly status: "verify reset password token"
                 | "verify reset password token - sending"
                 | "verify reset password token - api error"
  readonly login_data:VerifyResetPasswordTokenData
}

export function isVerifyResetPasswordTokenState<a>(s: LoginState<a>): s is VerifyResetPasswordTokenState {
  return s.status ==  "verify reset password token" ||
         s.status ==  "verify reset password token - sending" ||
         s.status ==  "verify reset password token - api error"
}

export interface ActivateAccountState {
  readonly status: "activate account"
                 | "activate account - activating"
                 | "activate account - api error"
                 | "activate account - error token not found"
  readonly login_data:ActivateAccountData
}

export function isActivateAccountState<a>(s: LoginState<a>): s is ActivateAccountState  {
  return s.status == "activate account" ||
         s.status == "activate account - activating" ||
         s.status == "activate account - api error" ||
         s.status == "activate account - error token not found"
}

export interface ResetPasswordRequestState {
  readonly status: "reset password request"
                 | "reset password request - success"
                 | "reset password request - resetting"
                 | "reset password request - api error"
                 | "reset password request - error too many attempts"
                 | "reset password request - error user not activated"
                 | "reset password request - error email not valid"
                 | "reset password request - error email not found"
  readonly login_data:ResetPasswordRequestData
}

export function isResetPasswordRequestState<a>(s: LoginState<a>): s is ResetPasswordRequestState  {
  return s.status == "reset password request" ||
         s.status == "reset password request - success" ||
         s.status == "reset password request - resetting" ||
         s.status == "reset password request - api error" ||
         s.status == "reset password request - error too many attempts" ||
         s.status == "reset password request - error user not activated" ||
         s.status == "reset password request - error email not valid" ||
         s.status == "reset password request - error email not found"
}

export interface ChooseRoleState {
  readonly status: "choose role"
                 | "choose role - logging in"
                 | "choose role - api error"
                 | "choose role - error too many attempts"
                 | "choose role - error no role selected"
                 | "choose role - error incompatible role for user"
  readonly login_data:ChooseRoleData
}

export function isChooseRoleState<a>(s: LoginState<a>): s is ChooseRoleState {
  return s.status == "choose role" ||
         s.status == "choose role - logging in" ||
         s.status == "choose role - api error" ||
         s.status == "choose role - error too many attempts" ||
         s.status == "choose role - error incompatible role for user" ||
         s.status == "choose role - error no role selected"
}
export interface Page404 {
  readonly status: "404"
}
export type LoginState<a> = LoggedInState<a>
                            | LoggedOutState
                            | SignUpState
                            | ChangePasswordState<a>
                            | ResetPasswordState
                            | ResetPasswordRequestState
                            | ActivateAccountState
                            | LoginTwoFactorState
                            | ChooseRoleState
                            | ResendActivationState
                            | VerifyResetPasswordTokenState
                            | ResetPasswordSuccessState
                            | Page404

export type PortalAppState<a, b extends {kind:string}> = { login : LoginState<a>, privacy_kind:"standard"|"strict", get_initial_state:() => a, route: CustomRouteParams<b>, get_404_page:Option<Widget<never>> }

