import { List } from "immutable";
import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import { Action, AsyncState, mk_widget, Widget } from "widgets-for-react";
import { ParsedMenuItem } from "../drupal/drupal_utils/types";
import * as i18next from "i18next";
import { PublicSiteState } from "../../public_site";
import { pushToDataLayer } from "./ecommerce";

export type HeaderState = {
  mode: 'closed' | 'login'
  menu_header: HeaderMenus
  open: boolean
}

export type HeaderMenus = {
  nav_links: AsyncState<List<ParsedMenuItem>>
}

export const renderNavLinks = (links: List<ParsedMenuItem>, callback: () => any, depth?: number): JSX.Element => {
  const level: number = depth ? depth : 0
  const menuType: string = level === 0 ? 'main' : 'sub'

  return (
    <ul className={`nav nav--${menuType} ${depth ? `nav--level-${depth}` : ""}`}>
      {menuType == "main" && (
        <li className="nav__item hide--desktop-xl">
          <Link to="/" onClick={callback} className="nav__link nav__link--main">Home</Link>
        </li>
      )}
      {links.map((e: ParsedMenuItem, k) => (
        <li key={`${menuType}-${depth}-${k}`} className={`nav__item ${!e.below.isEmpty() ? 'nav__item--has-sub' : ''}`}>
          {
            e.external
              ? <a className={`nav__link nav__link--${menuType}`} href={e.url} target="_blank">{e.label}</a>
              : <NavLink className={`nav__link nav__link--${menuType}`} activeClassName="nav__link--active" onClick={callback} to={e.url}>{e.label}</NavLink>
          }
        </li>
      ))}
    </ul>
  )
}

export const headerSwitcher: (s0: PublicSiteState) => Widget<Action<PublicSiteState>> = (s0) =>
  s0.page.page.kind == 'loaded' && s0.page.page.value.node.bundle == 'campaign'
    ? headerCampaign(s0)
    : header(s0)


const header: (s0: PublicSiteState) => Widget<Action<PublicSiteState>> = (s0) => mk_widget({
  run: setState => {
    let props = s0.header

    let isRestaurantPage: boolean = s0.page.page.kind == "loaded" && s0.page.page.value.node.bundle === "restaurant"
    let restaurantHasEtenderKey: boolean = s0.page.page.kind == "loaded" && s0.page.page.value.node.bundle === "restaurant" && s0.page.page.value.node.field_etender_access_key && s0.page.page.value.node.field_etender_access_key.trim().length > 0
    let isDineInRestaurant: boolean = s0.page.page.kind == "loaded" && s0.page.page.value.node.bundle === "restaurant" && s0.page.page.value.node.field_dine_in



    return <header className={`header${props.open ? " menu--open" : ""}`}>
      {/* Render empty top header so that spacing remains intact */}
      <div className="header__top" />
      <div className="header__main">
        <div className="header__inner">
          <div className="header__col header__col--logo">
            <Link className="logo" to={'/'} onClick={() => closeMenu(setState)}>
              <svg aria-hidden="true" width="100" height="100" className="hide--mobile-desktop">
                <use xlinkHref="#image--logo" />
              </svg>
              <svg aria-hidden="true" width="130" height="20" className="hide--desktop-xl">
                <use xlinkHref="#image--logo-word" />
              </svg>
            </Link>
          </div>
          <div className="header__col hide--desktop-xl">
            <button onClick={() => menuToggle(setState)} className={`header__toggle ${props.open ? 'header__toggle--open' : ''}`}><span></span></button>
          </div>
          <div className={`header__col header__col--nav ${props.open ? "header__nav--open" : "header__nav--closed"}`}>
            {props.menu_header.nav_links.kind == "loaded" && renderNavLinks(props.menu_header.nav_links.value, () => {
              props.open = false
              document.documentElement.classList.remove('html--no-scroll-mt')
            })}
          </div>

          <OrderBookButtons
            isRestaurantPage={isRestaurantPage}
            isDineInRestaurant={isDineInRestaurant}
            onClickOrder={() => {
              closeMenu(setState)
              pushToDataLayer({
                event: "event-beren",
                category: "Header button",
                action: "Bestel online",
                label: window.location.pathname,
              })
            }}
            onClickBook={() => {
              closeMenu(setState)
              pushToDataLayer({
                event: "event-beren",
                category: "Header button",
                action: "Reserveer een tafel",
                label: window.location.pathname,
              })
            }}
            extraClasses="header__buttons"
          />
        </div>
      </div>
    </header>
  }
})

const headerCampaign: (s0: PublicSiteState) => Widget<Action<PublicSiteState>> = (s0) => mk_widget({
  run: setState => {
    let show_buttons = s0.page.page.kind == 'loaded' && s0.page.page.value.node.bundle == 'campaign' && s0.page.page.value.node.field_show_buttons

    return <header className="header header--campaign">
      <div className="header__top" /> {/* Render empty top header so that spacing remains intact */}

      <div className="header__main">
        <div className="header__inner">
          <div className="header__col header__col--logo">
            <Link className="logo" to={'/'} onClick={() => closeMenu(setState)}>
              <svg aria-hidden="true" width="100" height="100" className="hide--mobile-desktop">
                <use xlinkHref="#image--logo" />
              </svg>
              <svg aria-hidden="true" width="130" height="20" className="hide--desktop-xl">
                <use xlinkHref="#image--logo-word" />
              </svg>
            </Link>
          </div>

          {show_buttons && <OrderBookButtons
            isRestaurantPage={false}
            isDineInRestaurant={false}
            onClickOrder={() => {
              closeMenu(setState)
              pushToDataLayer({
                event: "event-beren",
                category: "Header button",
                action: "Bestel online",
                label: window.location.pathname,
              })
            }}
            onClickBook={() => {
              closeMenu(setState)
              pushToDataLayer({
                event: "event-beren",
                category: "Header button",
                action: "Reserveer een tafel",
                label: window.location.pathname,
              })
            }}
            extraClasses="header__buttons"
          />}
        </div>
      </div>
    </header>
  }
})


export const OrderBookButtons = ({
  isRestaurantPage,
  isDineInRestaurant,
  onClickOrder,
  onClickBook,
  titleElem,
  extraClasses
}: {
  isRestaurantPage: boolean;
  isDineInRestaurant: boolean;
  onClickOrder?: () => void;
  onClickBook?: () => void;
  titleElem?: JSX.Element
  extraClasses?: string
}
) => {
  return (
    // Hide buttons if same functionality is provided by Etender widget.
    // https://hoppinger.atlassian.net/secure/RapidBoard.jspa?rapidView=69&projectKey=SBH&modal=detail&selectedIssue=SBH-44
    (!isRestaurantPage || (isRestaurantPage && !isDineInRestaurant)) && (
      <div className={extraClasses ? extraClasses : ''}>
        {titleElem && titleElem}
        <a
          href="https://bestel.beren.nl/"
          className="button button--primary"
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClickOrder}
        >
          {i18next.t("common:order_online")}
        </a>

        {/* 
          Show table reservation button on all places except restaurant pages.
          https://hoppinger.atlassian.net/secure/RapidBoard.jspa?rapidView=69&projectKey=SBH&modal=detail&selectedIssue=SBH-44
        */}
        {!isRestaurantPage && (
          <Link
            to={'/reserveer'}
            onClick={onClickBook}
            className="button button--primary"
          >
            {i18next.t("common:book_table")}
          </Link>
        )}
      </div>
    )
  )
}

const menuToggle = (setState: (action: Action<PublicSiteState>) => void) => {
  const action: Action<PublicSiteState> = (b) => {
    b.header.open ? document.documentElement.classList.remove('html--no-scroll-mt') : document.documentElement.classList.add('html--no-scroll-mt')
    return {
      ...b,
      header: {
        ...b.header,
        open: !b.header.open
      }
    }
  }
  setState(action)
}

const closeMenu = (setState: (action: Action<PublicSiteState>) => void) => {
  const action: Action<PublicSiteState> = (b) => {
    document.documentElement.classList.remove('html--no-scroll-mt')
    return {
      ...b,
      header: {
        ...b.header,
        open: false
      }
    }
  }
  setState(action)
}
