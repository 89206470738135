import * as React from "react"
import { Action, any, IOWidget, mk_widget } from "widgets-for-react"
import { CMSState } from "../state"
import { LeafletMapLayout } from "./LeafletMap.Layout"
import { LeafletMapState } from "./LeafletMap.State"

export type LeafletMapBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
  content_classname: string
}>


export function LeafletMapBlock<customBlockData, extra_images>(
  props: LeafletMapState,
  options?: { style?: LeafletMapBlockStyle; key?: string }
): IOWidget<CMSState<customBlockData, extra_images>, Action<CMSState<customBlockData, extra_images>>> {
  return (cmsState: CMSState<customBlockData, extra_images>) =>
    any<Action<CMSState<customBlockData, extra_images>>>()([

      mk_widget({ run: _ => <LeafletMapLayout restaurant_list={cmsState.restaurant_list} /> })

    ])
}