import { List } from "immutable";
import { Action, any, async, AsyncState, browserRouter, div, Fun, HttpResult, loadedAsyncState, loadingAsyncState, mk_pair, none, nothing, onlyIf, Option, Pair, some, unloadedAsyncState, Widget } from "widgets-for-react";
import { ContentType, defaultPage, emptyAppState, PublicSiteState } from "../public_site";
import { ParsedNode, ParsedParagraph } from "./drupal/drupal_generated/types";
import { Block, Breadcrumb, ParsedMenuItem } from "./drupal/drupal_utils/types";
import { GetDrupalBlockImagesLoaders } from "./drupal/drupal_utils/utils";
import * as Api from "./drupal_api";
import { ctaCampaignBlock, ctaImageCampaignBlock, DrupalBlock_To_WidgetBlock, expandCampaignBlock, linkGridCampaignBlock, mediaCampaignBlock, menuBlock, restaurantListBlock, textCampaignBlock, TransformStandardBlock } from "./drupal_block_widgets";
import { Cookiebar } from "./public_site/cookiebar";
import { pageDisplay } from "./public_site_utils/blocks_renderer";
import { CustomBlock, CustomBlockData } from "./public_site_utils/custom_blocks_types";
import { CustomPages } from "./public_site_utils/custom_pages";
import { PublicSiteCustomRoutes } from "./public_site_utils/custom_route";
import { ExtraImages } from "./public_site_utils/extra_images";
import { footer } from "./public_site_utils/footer";
import { BlocksRenderer } from "./widget_blocks/block";
import { jsxToWidget } from "./widget_blocks/utils";
import { EntryClient } from "./widget_blocks_client/EntryClient";
import { Page } from "./widget_blocks_client/PageTemplate";
import { AppState, PageStateZero, PageTemplateState } from "./widget_blocks_client/types/state";
import React = require("react")


export const InitPagesStates: (
  blocks_renderer: BlocksRenderer<CustomBlockData, never>
) => PageTemplateState<CustomBlockData, CustomBlock, Block, ExtraImages> = b => ({
  page: PageStateZero<CustomBlockData, CustomBlock, Block, ExtraImages>(() => unloadedAsyncState(), defaultPage, pageDisplay(b)).page
})

export const get_page = (slug: string): AsyncState<Page<CustomBlock, Block>> =>
  loadingAsyncState(_ =>
    Api.GetDrupalNode(slug)
      .then<HttpResult<Page<CustomBlock, Block>>>(page =>
        page.kind == "result" && page.value.entity_type == "node"
          ? {
            kind: "result",
            value: { blocks: (page.value.field_page_content as List<ParsedParagraph>)
              .map(b => TransformStandardBlock(b)).toArray(), node: page.value },
            status: 200
          }
          : { kind: "failed", status: 404 }
      )
      .catch(e => {
        console.error(e)
        return e
      })
  )

export const application = (
  data: { rootpath: string; rootpath_to_ignore: string; login_address?: string },
  header: (state: PublicSiteState) => Widget<Action<PublicSiteState>>,
  errorPage: Widget<never>,
  loadingPage: Widget<never>,
  page: Fun<Pair<List<Breadcrumb>, Option<ParsedNode>>, ContentType>,
  custom_pages: CustomPages
) => {
  let rootpath = data.rootpath ? data.rootpath : ""
  let rootpath_to_ignore = data.rootpath_to_ignore ? data.rootpath_to_ignore : ""
  return (s0: AppState<PublicSiteState, PublicSiteCustomRoutes>) =>
    browserRouter<Action<AppState<PublicSiteState, PublicSiteCustomRoutes>>>()(
      s0.route.kind == "ignore"
        ? nothing()
        : any<Action<AppState<PublicSiteState, PublicSiteCustomRoutes>>>()([
          div<Action<AppState<PublicSiteState, PublicSiteCustomRoutes>>>({ className: `main main--${s0.state.page.page.kind == 'loaded' && s0.state.page.page.value.node.bundle}` })([
            async<List<ParsedMenuItem>>(_ => nothing())(s0.state.header.menu_header.nav_links).map<
              Action<AppState<PublicSiteState, PublicSiteCustomRoutes>>
            >(a => s1 => ({
              ...s1,
              state: {
                ...s1.state,
                header: {
                  ...s1.state.header,
                  menu_header: { ...s1.state.header.menu_header, nav_links: a(s1.state.header.menu_header.nav_links) }
                }
              }
            })),

            async<List<Breadcrumb>>(_ => nothing(), { retry: { maxAttempts: 1 } })(s0.state.breadcrumbs).map<
              Action<AppState<PublicSiteState, PublicSiteCustomRoutes>>
            >(a => s1 => {
              const bc = a(s1.state.breadcrumbs)

              return {
                ...s1,
                state: {
                  ...s1.state,
                  breadcrumbs: bc,
                  page: {
                    ...s1.state.page,
                    display:
                      bc.kind == "loaded"
                        ? page(mk_pair(bc.value, s1.state.page.page.kind == "loaded" ? some(s1.state.page.page.value.node) : none()))
                        : s1.state.page.display
                  }
                }
              }
            }),

            async<List<Api.RestaurantListItem>>(_ => nothing())(s0.state.restaurant_list).map<
              Action<AppState<PublicSiteState, PublicSiteCustomRoutes>>
            >(a => s1 => ({
              ...s1,
              state: {
                ...s1.state,
                restaurant_list: a(s1.state.restaurant_list),
                page: {
                  ...s1.state.page,
                  restaurant_list: a(s1.state.restaurant_list)
                }
              }
            })),

            async<List<ParsedMenuItem>>(_ => nothing())(s0.state.footer.nav_links).map<Action<AppState<PublicSiteState, PublicSiteCustomRoutes>>>(
              a => s1 => ({
                ...s1,
                state: {
                  ...s1.state,
                  footer: { ...s1.state.footer, nav_links: a(s1.state.header.menu_header.nav_links) }
                }
              })
            ),

            onlyIf(
              s0.route.kind != "styleguide",
              header(s0.state).map<Action<AppState<PublicSiteState, PublicSiteCustomRoutes>>>(a => s1 => ({ ...s1, state: a(s1.state) }))
            ),

            EntryClient<CustomBlockData, CustomBlock, Block, PublicSiteState, keyof CustomPages, PublicSiteCustomRoutes, ExtraImages>({
              initState: emptyAppState(page),
              custom_pages: custom_pages,
              StandardBlockImagesLoader: GetDrupalBlockImagesLoaders,
              StandardBlock_To_Widget: DrupalBlock_To_WidgetBlock,
              errorPage: errorPage,
              loadingPage: loadingPage,
              rootpath: rootpath,
              rootpath_to_ignore: rootpath_to_ignore,
              CustomBlock_To_Widget: (c: CustomBlock, index: number): Option<CustomBlockData> => {
                switch (c.kind) {
                  case "restaurant_list":
                    return some(restaurantListBlock(c))
                  case "menu":
                    return some(menuBlock(c))
                  case "cta_with_image": // Used only on Campaigns post type
                    return some(ctaImageCampaignBlock(c))
                  case "campaign_media": // Used only on Campaigns post type
                    return some(mediaCampaignBlock(c))
                  case "campaign_grid": // Used only on Campaigns post type
                    return some(linkGridCampaignBlock(c))
                  case "campaign_text": // Used only on Campaigns post type
                    return some(textCampaignBlock(c))
                  case "campaign_cta": // Used only on Campaigns post type
                    return some(ctaCampaignBlock(c))
                  case "campaign_uitklappers": // Used only on Campaigns post type
                    return some(expandCampaignBlock(c, index))
                }

                return none()
              }
            })({
              ...s0,
              state: {
                ...s0.state,
                page: {
                  ...s0.state.page,
                  display:
                    s0.state.breadcrumbs.kind == "loaded"
                      ? page({
                        fst: s0.state.breadcrumbs.value,
                        snd: s0.state.page.page.kind == "loaded" ? some(s0.state.page.page.value.node) : none()
                      })
                      : s0.state.page.display
                }
              }
            })
          ]),
          onlyIf(
            s0.route.kind != "styleguide",
            footer(s0.state)(({
              ...s0.state.footer,
              show_buttons: s0.state.page.page.kind == 'loaded' && s0.state.page.page.value.node.bundle == 'campaign' && s0.state.page.page.value.node.field_show_buttons
            })).map<Action<AppState<PublicSiteState, PublicSiteCustomRoutes>>>(a => s1 => ({
              ...s1,
              state: { ...s1.state, footer: a(s1.state.footer) }
            }))
          ),
          jsxToWidget(<Cookiebar />)
        ])
    ).map<AppState<PublicSiteState, PublicSiteCustomRoutes>>(a => {
      let s1 = a(s0)

      // Set breadcrumbs defined in public site custom routes.
      if (s1.route.kind != "404" && s1.route.kind != "ignore" && s1.route.kind != "page" && s1.route.kind != "init") {
        s1 = {
          ...s1,
          state: {
            ...s1.state,
            breadcrumbs: loadedAsyncState(s1.route.breadcrumbs)
          }
        }
        // Set async request for retrieving breadcrumbs for CMS pages.
      } else if (s0.route.kind === "page" && s1.route.kind === "page" && s0.route.slug != s1.route.slug) {
        s1 = {
          ...s1,
          state: {
            ...s1.state,
            breadcrumbs: Api.loadBreadcrumbs(s1.route.slug ? s1.route.slug : "/")
          }
        }
      }

      // Set document title for custom public site pages
      if (s1.route.kind != "404" && s1.route.kind != "ignore" && s1.route.kind != "page" && s1.route.kind != "init") {
        document.title = `${s1.route.title} | De Beren`

        // Set document title for loaded CMS page
      } else if (s1.state.page.page.kind == "loaded") {
        document.title = s1.state.page.page.value.node.metatag.title
      }

      if (
        s1.route.kind == "404" ||
        s1.route.kind == "ignore" ||
        s0.route.kind == "404" ||
        s0.route.kind == "ignore" ||
        (s1.route.kind == "page" && s1.state.page.page.kind == 'failed' && s1.state.page.page.status == 404)
      ) {
        document.title = `O, o, Beren op de weg`
        window.scrollTo(0, 0)
      }
      if (s0.route.kind != s1.route.kind || (s0.route.kind == "page" && s1.route.kind == "page" && s0.route.slug != s1.route.slug)) {
        window.scrollTo(0, 0);
      }

      return s1
    }).map(f => {
      return f
    })
}
