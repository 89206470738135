import { routerSwitch, route, notFoundRouteCase, Route, Widget, nothing } from "widgets-for-react"
import { PortalAppState, isLoggedInState } from "./state"
import { RouteParams, CustomRouteParams } from "./router_state"
import { getSession } from "./login"

const testLoggedIn = <a, b extends { kind: string }>(s0: PortalAppState<a, b>) => {
  if (isLoggedInState(s0.login)) {
    return true
  } else {
    let info = getSession()

    return info.v.kind == "r"
  }
}

export const router = <a, b extends { kind: string }>(
  rootpath: string,
  s0: PortalAppState<a, b>,
  custom_auth_routes: Route<CustomRouteParams<b>>[],
  custom_unauth_routes: Route<CustomRouteParams<b>>[],
): Widget<CustomRouteParams<b>> =>
  routerSwitch<CustomRouteParams<b>>({ key: `login-router-switch` })([
    route<{}, CustomRouteParams<b>>(rootpath + "/", () => ({ kind: "home", title: "Home" })),
    ...(testLoggedIn(s0) ? custom_auth_routes : []),
    route<{ token?: string; email?: string }, CustomRouteParams<b>>(
      rootpath + "/reset-password",
      a =>
        a.token == undefined
          ? { kind: "404" }
          : { kind: "reset password", title: "Reset password", token: a.token, email: a.email },
    ),
    route<{ email?: string }, CustomRouteParams<b>>(
      rootpath + "/resend-activation",
      a => ({ kind: "resend activation", title: "Resend activation" }),
    ),
    ...custom_unauth_routes,
    route<{ token?: string; email?: string }, CustomRouteParams<b>>(
      rootpath + "/activate",
      a =>
        a.token == undefined
          ? { kind: "404" }
          : { kind: "activate", title: "Activate", token: a.token, email: a.email },
    ),
    route<{}, CustomRouteParams<b>>(rootpath + "/register", () => ({
      kind: "register",
      title: "Register",
    })),
    route<{}, CustomRouteParams<b>>(rootpath + "/forgot-password", () => ({
      kind: "forgot password",
      title: "Forgot password",
    })),
    route<{}, CustomRouteParams<b>>(rootpath + "/change-password", () => ({
      kind: "change password",
      title: "Change password",
    })),
    route<{}, CustomRouteParams<b>>(rootpath + "/*", () => ({ kind: "404", title: "Not found" })),
    notFoundRouteCase<CustomRouteParams<b>>(() => ({ kind: "ignore" })),
  ])
