import { string, any, IOWidget, Action } from "widgets-for-react";
import { CMSState } from "../widget_blocks/state";
import { Block } from "../drupal/drupal_utils/types";
import { TemplateState } from "../widget_blocks_client/PageTemplate";
import { renderBlock, getMockedBlockRenderer, BlocksRenderer } from "../widget_blocks/block";
import { renderCustomBlock, CustomBlock, CustomBlockData, isCustomBlockData } from "./custom_blocks_types";
import { ExtraImages } from "./extra_images";



  export function pageDisplay_AUX(blocks: (Block|CustomBlock)[], blocks_renderer:BlocksRenderer<CustomBlockData, never>): IOWidget<TemplateState<CustomBlockData, CustomBlock, Block, ExtraImages>, Action<TemplateState<CustomBlockData, CustomBlock, Block, ExtraImages>>> {
    return s => any<Action<TemplateState<CustomBlockData, CustomBlock, Block, ExtraImages>>>()([
      ...(s.blocks
        .map((b, index) =>
          isCustomBlockData(b) ? renderCustomBlock(b, `key-${index}`)(s).map<Action<TemplateState<CustomBlockData, CustomBlock, Block, ExtraImages>>>(a => s1 => {
            let res = a(s1)
            return { ...s1, images: res.images, blocks: res.blocks }
          }) :
          renderBlock(b, blocks_renderer, `key-${index}`)(s).map<Action<TemplateState<CustomBlockData, CustomBlock, Block, ExtraImages>>>(a => s1 => {
            let res = a(s1)
            return { ...s1, images: res.images, blocks: res.blocks }
          })
        )
        .toArray()
      )
    ])
  }
  export let pageDisplay = 
    (blocks_renderer: BlocksRenderer<CustomBlockData, never>) : 
    (b:(Block|CustomBlock)[]) => IOWidget<TemplateState<CustomBlockData, CustomBlock, Block, ExtraImages>, Action<TemplateState<CustomBlockData, CustomBlock, Block, ExtraImages>>> =>
    (b) => pageDisplay_AUX(b, blocks_renderer)

  