import * as React from "react"
import { Action, IOWidget, mk_widget, option } from "widgets-for-react"
import { CMSState } from "./state"
import { Url, Link } from "./types"
import { link } from "./utils"

export type TextBlockProps<CustomBlockData, extra_images> = {
  title?: string
  text: string
  primary_button?: (
    | { kind: "button"; on_click: () => Action<CMSState<CustomBlockData, extra_images>> }
    | Link ) & {
    title: string
  }
  link?: Link
}

export type TextBlockStyle = Partial<{
  block_classname: string
  inner_classname: string
  header_classname: string
  title_classname: string
  content_classname: string
  text_classname: string
  footer_classname: string
  clickable_primary_classname: string
  clickable_secondary_classname: string
}>

const getClassName = (
  options: { style?: TextBlockStyle },
  className: keyof TextBlockStyle,
  notset: string = ""
): string =>
  options && options.style && (options.style[className] || options.style[className] == null) ? options.style[className] : notset

export function TextBlock<CustomBlockData, extra_images>(
  props: TextBlockProps<CustomBlockData, extra_images>,
  options?: { style?: TextBlockStyle; key?: string }
): IOWidget<CMSState<CustomBlockData, extra_images>, Action<CMSState<CustomBlockData, extra_images>>> {
  return (s: CMSState<CustomBlockData, extra_images>) =>
    mk_widget({
      run: onDone =>
        <section className={getClassName(options, "block_classname", "container")} key={options && options.key ? options.key : "text-block"}>
          <div className={getClassName(options, "inner_classname", "py-5")}>
            { props.title &&
              <header className={getClassName(options, "header_classname", "")}>
                <h2 className={getClassName(options, "title_classname", "mb-3")}>{props.title}</h2>
              </header>
            }

            <div className={getClassName(options, "content_classname", "")}>
              <div className={getClassName(options, "text_classname", "")} dangerouslySetInnerHTML={{ __html: props.text }} />
            </div>

            {(props.primary_button || (props.link && props.link.title && props.link.url)) && (
              <footer className={getClassName(options, "footer_classname", "")}>
                {props.primary_button
                  ? (props.primary_button.kind === "button"
                    ? (<button
                      className={getClassName(options, "clickable_primary_classname", "button")}
                      onClick={() => props.primary_button.kind === "button" && onDone(props.primary_button.on_click())}
                    >
                      {props.primary_button.title}
                    </button>)
                    : props.primary_button.title && props.primary_button.url
                      ? (link(props.primary_button,
                        { classname: getClassName(options, "clickable_primary_classname", "button mr-2") },
                        props.primary_button.title))
                      : null
                  )
                  : null
                }
                {props.link && props.link.title && props.link.url &&
                  link(props.link,
                    { classname: getClassName(options, "clickable_secondary_classname", "button button-link") },
                    props.link.title)
                }
              </footer>
            )}
          </div>
        </section>
    })
}