import { Fun } from "widgets-for-react";
import { FeaturedContentBlockStyle } from "./widget_blocks/featuredContentBlock";
import { TextBlockStyle } from "./widget_blocks/textBlock";
import { CtaBlockStyle } from "./widget_blocks/ctaBlock";
import { DownloadBlockStyle } from "./widget_blocks/downloadBlock";
import { LinkGridBlockStyle } from "./widget_blocks/linkGridBlock";
import { ExpandBlockStyle } from "./widget_blocks/expandBlock";
import { MediaBlockStyle } from "./widget_blocks/mediaBlock";
import { LinkBlockStyle } from "./widget_blocks/linkBlock";
import { QuoteBlockStyle } from "./widget_blocks/quoteBlock";
import { IframeBlockStyle } from "./widget_blocks/iframeBlock";
import { CtaImageCampaignBlockStyle } from "./widget_blocks/ctaImageCampaignBlock";
import { MediaCampaignBlockStyle } from "./widget_blocks/mediaCampaignBlock";
import { TextCampaignBlockStyle } from "./widget_blocks/textCampaignBlock";
import { CtaCampaignBlockStyle } from "./widget_blocks/ctaCampaignBlock";
import { ExpandCampaignBlockStyle } from "./widget_blocks/expandCampaignBlock";
import { EmbedBlockStyle } from "./widget_blocks/embedBlock";

export const featuredContentClasses: Fun<"left" | "right", FeaturedContentBlockStyle> = m => ({
  block_classname: "block",
  inner_classname: "block__inner featured__inner",
  title_classname: "block__title title--m",
  content_classname: "featured__content",
  figure_classname: `featured__figure  featured__figure--${m}`,
  footer_classname: "block__footer-buttons",
  clickable_primary_classname: "button button--tertiary",
  clickable_secondary_classname: ""
})

export const textBlockClasses: TextBlockStyle = {
  block_classname: "block block--text",
  inner_classname: "block__inner",
  header_classname: "block__header",
  title_classname: "block__title title--m",
  content_classname: "block__body text--styled",
  footer_classname: "block__footer-buttons",
  clickable_primary_classname: "button button--secondary",
  clickable_secondary_classname: "button button-link"
}

export const ctaBlockClasses: CtaBlockStyle = {
  block_classname: "block block--cta",
  inner_classname: "block__inner",
  header_classname: "block__header",
  title_classname: "title--l",
  content_classname: "block__body text--m",
  footer_classname: "block__footer",
  clickable_primary_classname: "button button--primary",
  clickable_secondary_classname: "button button-link"
}

export const linkListBlockClasses: LinkBlockStyle = {
  block_classname: "block links",
  inner_classname: "block__inner",
  title_classname: "block__title title--m",
  list_classname: "links__columns",
  item_classname: "text--styled links__item"
}

export const downloadBlockClasses: DownloadBlockStyle = {
  block_classname: "block",
  inner_classname: "block__inner",
  title_classname: "block__title title--m",
  item_classname: "text--styled download__item"
}

export const linkGridBlockClasses: Fun<boolean, LinkGridBlockStyle> = hasbutton => ({
  block_classname: "block block--grid",
  inner_classname: "block__inner",
  header_classname: "block__header",
  title_classname: "block__title title--m",
  intro_classname: "block__intro",
  content_classname: "block__body",
  list_classname: "grid",
  grid_item_classname: "grid__item",
  grid_link_classname: "card",
  grid_visual_classname: "card__visual",
  grid_image_classname: "card__image",
  grid_content_classname: "card__body",
  grid_title_classname: "card__title",
  grid_intro_classname: "card__intro",
  grid_fauxlink_classname: null,
  clickable_classname: "button button-link"
})

export const expandBlockClasses: ExpandBlockStyle = {
  block_classname: "block block--expand",
  inner_classname: "block__inner",
  header_classname: "block__header",
  title_classname: "block__title title--m",
  intro_classname: "block__intro",
  content_classname: "block__body",
  text_classname: "",
  footer_classname: "block__footer",
  clickable_classname: "",
  list_classname: "accordion",
  expand_item_classname: "accordion__item",
  expand_button_classname: "accordion__button",
  expand_title_classname: "accordion__title title--s",
  expand_icon_classname: "accordion__icon",
  expand_text_classname: "accordion__body"
}

export const mediaBlockClasses: Fun<"image" | "video", MediaBlockStyle> = k => ({
  block_classname: "block block--media",
  inner_classname: "block__inner",
  content_classname: "block__body",
  figure_classname: "media",
  media_classname: "media__visual",
  video_classname: "embed-responsive embed-responsive-16by9",
  figcaption_classname: "media__caption media__caption--default text--l",
  caption_classname: null
})

export const linkGridBlockPlainClasses: Fun<number, LinkGridBlockStyle> = n => ({
  block_classname: "block block--grid",
  inner_classname: "block__inner",
  header_classname: "block__header",
  title_classname: "block__title title--m",
  intro_classname: "block__intro",
  content_classname: "block__body",
  list_classname: "grid",
  grid_item_classname: "grid__item",
  grid_link_classname: "card",
  grid_visual_classname: "card__visual",
  grid_image_classname: "card__image",
  grid_content_classname: "card__body",
  grid_title_classname: "card__title",
  grid_intro_classname: "card__intro",
  grid_fauxlink_classname: null,
  clickable_classname: "button button-link"
})

export const quoteBlockClasses: QuoteBlockStyle = {
  block_classname: "block block--quote",
  inner_classname: "block__inner",
  content_classname: "block__body",
  quote_classname: "quote",
  figure_classname: "quote__figure",
  image_classname: "quote__image",
  quote_content_classname: "quote__content title--l",
  quote_footer_classname: "quote__footer",
  quote_person_classname: "quote__person",
  clickable_classname: "quote__link text--styled"
}

export const IframeBlockClasses: IframeBlockStyle = {
  block_classname: "block block--iframe",
  inner_classname: "block__inner",
}

// ---- Used only on Campaigns post type

type ctaImageCampaignBlockVariables = {
  width: "full" | "content",
  direction: "left" | "right" | "center",
  titleSize: string,
  titleFont: string,
  textSize: string
  textFont: string
}

export const ctaImageCampaignBlockClasses: Fun<ctaImageCampaignBlockVariables, CtaImageCampaignBlockStyle> = cicbv => ({
  block_classname: `block block--cta cta--${cicbv.width} cta--${cicbv.direction}`,
  inner_classname: "block__inner",
  content_classname: "block__content",
  header_classname: "block__header",
  title_classname: `title--l${cicbv.titleSize ? ` c-${cicbv.titleSize}` : ''}${cicbv.titleFont ? ` c-font--${cicbv.titleFont}` : ''}`,
  body_classname: `block__body text--m${cicbv.textSize ? ` c-${cicbv.textSize}` : ''}${cicbv.textFont ? ` c-font--${cicbv.textFont}` : ''}`,
  footer_classname: "block__footer",
  clickable_primary_classname: "button button--primary",
  clickable_secondary_classname: "button button--secondary",
  visual_classname: "block__visual",
  image_classname: "block__image"
})

type mediaCampaignBlockVariables = {
  width: "full" | "content",
  textSize: string,
  textFont: string
}

export const mediaCampaignBlockClasses: Fun<mediaCampaignBlockVariables, MediaCampaignBlockStyle> = mcbv => ({
  block_classname: `block block--media media--${mcbv.width}`,
  inner_classname: "block__inner",
  content_classname: "block__body",
  figure_classname: "media",
  media_classname: "media__visual",
  video_classname: "embed-responsive embed-responsive-16by9",
  figcaption_classname: `media__caption media__caption--campaign text--l${mcbv.textSize ? ` c-${mcbv.textSize}` : ''}${mcbv.textFont ? ` c-font--${mcbv.textFont}` : ''}`,
})

type linkGridCampaignBlockVariables = {
  width: "full" | "content",
  colunms: string,
  alignment: "center" | "left",
  titleSize: string,
  titleFont: string,
  textSize: string
  textFont: string
}

export const linkGridCampaignBlockClasses: Fun<linkGridCampaignBlockVariables, LinkGridBlockStyle> = lgcbv => ({
  block_classname: `block block--grid grid--${lgcbv.width} grid--${lgcbv.colunms}`,
  inner_classname: "block__inner",
  header_classname: "block__header",
  title_classname: `block__title title--m${lgcbv.titleSize ? ` c-${lgcbv.titleSize}` : ' '}${lgcbv.titleFont ? ` c-font--${lgcbv.titleFont}` : ''}`,
  intro_classname: `block__intro${lgcbv.textSize ? ` c-${lgcbv.textSize}` : ''}${lgcbv.textFont ? ` c-font--${lgcbv.textFont}` : ''}`,
  content_classname: "block__body",
  list_classname: "grid",
  grid_item_classname: "grid__item",
  grid_link_classname: `card${lgcbv.alignment == 'center' ? ' card--centered' : ''}`,
  grid_visual_classname: "card__visual",
  grid_image_classname: "card__image",
  grid_content_classname: "card__body",
  grid_title_classname: `card__title${lgcbv.alignment == 'center' ? ' title--s' : ''}`,
  grid_intro_classname: "card__intro",
  grid_fauxlink_classname: null,
  footer_classname: "block__footer",
  clickable_classname: "button button--primary"
})

type textCampaignBlockVariables = {
  titleSize: string,
  titleFont: string,
  textSize: string
  textFont: string
}

export const textCampaignBlockClasses: Fun<textCampaignBlockVariables, TextCampaignBlockStyle> = tcbv => ({
  block_classname: "block block--text",
  inner_classname: "block__inner",
  header_classname: "block__header",
  title_classname: `block__title title--m${tcbv.titleSize ? ` c-${tcbv.titleSize}` : ''}${tcbv.titleFont ? ` c-font--${tcbv.titleFont}` : ''}`,
  content_classname: `block__body${tcbv.textSize ? ` c-${tcbv.textSize}` : ''}${tcbv.textFont ? ` c-font--${tcbv.textFont}` : ''} text--styled`,
  footer_classname: "block__footer-buttons",
  clickable_primary_classname: "button button--secondary",
  clickable_secondary_classname: "button button-link"
})

type ctaCampaignBlockVariables = {
  titleSize: string,
  titleFont: string,
  textSize: string
  textFont: string
}

export const ctaCampaignBlockClasses: Fun<ctaCampaignBlockVariables, CtaCampaignBlockStyle> = ccbv => ({
  block_classname: "block block--cta",
  inner_classname: "block__inner",
  header_classname: "block__header",
  title_classname: `title--l${ccbv.titleSize ? ` c-${ccbv.titleSize}` : ''}${ccbv.titleFont ? ` c-font--${ccbv.titleFont}` : ''}`,
  content_classname: `block__body text--m${ccbv.textSize ? ` c-${ccbv.textSize}` : ''}${ccbv.textFont ? ` c-font--${ccbv.textFont}` : ''}`,
  footer_classname: "block__footer",
  clickable_primary_classname: "button button--primary",
  clickable_secondary_classname: "button button-link"
})

type expandCampaignBlockVariables = {
  titleSize: string,
  titleFont: string,
  textSize: string
  textFont: string
}

export const expandCampaignBlockClasses: Fun<expandCampaignBlockVariables, ExpandCampaignBlockStyle> = ecbv => ({
  block_classname: "block block--expand",
  inner_classname: "block__inner",
  header_classname: "block__header",
  title_classname: `block__title title--m${ecbv.titleSize ? ` c-${ecbv.titleSize}` : ''}${ecbv.titleFont ? ` c-font--${ecbv.titleFont}` : ''}`,
  intro_classname: `block__intro${ecbv.textSize ? ` c-${ecbv.textSize}` : ''}${ecbv.textFont ? ` c-font--${ecbv.textFont}` : ''}`,
  content_classname: "block__body",
  text_classname: "",
  footer_classname: "block__footer",
  clickable_classname: "",
  list_classname: "accordion",
  expand_item_classname: "accordion__item",
  expand_button_classname: "accordion__button",
  expand_title_classname: "accordion__title title--s",
  expand_icon_classname: "accordion__icon",
  expand_text_classname: "accordion__body"
})

export const embedBlockClasses: EmbedBlockStyle = {
  block_classname: "block",
  inner_classname: "block__inner",
}