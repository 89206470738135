import { Action, any, button, div, hn, label, link, string, Widget } from "widgets-for-react";
import { notifications } from '../notifications';
import { isLoggedOutState, LoggedOutState, LoginState } from '../state';
import i18next = require('i18next');

export let login_form = <a>(s0: LoggedOutState, readonly: boolean, rootpath: string): Widget<Action<LoginState<a>>> =>
  div<Action<LoginState<a>>>({ className: "login" })(
    div<Action<LoginState<a>>>({ className: "login__inner" })([
      div<Action<LoginState<a>>>()(
        any<Action<LoggedOutState>>()([
          hn<Action<LoggedOutState>>(1, i18next.t('login:login'), { className: "login__title title--l" })(),
          div<Action<LoginState<a>>>({ className: "text--styled login__text" })([
            string({ readonly: true, readonly_element: 'nothing' })(i18next.t('login:old_accounts_are_gone:before_link')).never(),
            link(i18next.t('login:old_accounts_are_gone:link'), `${rootpath}/register`).never(),
            string({ readonly: true, readonly_element: 'nothing' })(i18next.t('login:old_accounts_are_gone:after_link')).never(),
          ]).never(),
          div<Action<LoggedOutState>>({ className: "field" })([
            label<Action<LoggedOutState>>(i18next.t('login:email'), { htmlFor: "username", label_position: "before", className: "label" })(
              string({ readonly, readonly_element: "input", id: "username", type: "text" })(s0.login_data.username)
                .map<Action<LoggedOutState>>(x => s => ({ ...s, status: "logged out", login_data: { ...s.login_data, username: x } }))
            )
          ]),
          div<Action<LoggedOutState>>({ className: "field" })([
            label<Action<LoggedOutState>>(i18next.t('login:password'), { htmlFor: "password", label_position: "before", className: "label" })(
              string({ readonly, readonly_element: "input", id: "password", type: s0.login_data.show_password ? "text" : "password" })(s0.login_data.password)
                .map<Action<LoggedOutState>>(x => s => ({ ...s, status: "logged out", login_data: { ...s.login_data, password: x } }))
            )
          ]),
        ]).map<Action<LoginState<a>>>(a => s => isLoggedOutState(s) ? a(s) : s)
      ),
      div<Action<LoginState<a>>>({ className: "text--styled login__forgot-password" })(
        link(i18next.t('login:forgot_password'), `${rootpath}/forgot-password`, { className: "", key: "forgot-password" }).never()
      ),
      div<Action<LoginState<a>>>({ className: "text--styled login__wrong-credentials inline-error" })(
        notifications(s0).never()
      ),
      any<Action<LoggedOutState>>()([
        button<Action<LoggedOutState>>(i18next.t('login:login'),
          { className: "button button--primary button--full-width", disabled: readonly })
          (() => s => {
            return ({ ...s, status: "logged out - logging in", login_data: s0.login_data })
          })
        ]).map<Action<LoginState<a>>>(a => s => isLoggedOutState(s) ? a(s) : s),
      div<Action<LoginState<a>>>({ className: "text--styled" })([
        div<Action<LoginState<a>>>({ className: "login__link" })([
          string({ readonly: true, readonly_element: "p" })(i18next.t('login:no_account_yet')).never(),
          link(i18next.t('login:create_account'), `${rootpath}/register`, { key: "sign-up" }).never()
        ]),
        div<Action<LoginState<a>>>({ className: "login__link" })([
          string({ readonly: true, readonly_element: "p" })(i18next.t('login:lost_activationmail')).never(),
          link(i18next.t('login:send_again'), `${rootpath}/resend-activation`, { key: "resend-activation" }).never()
        ])
      ]),
    ])
  ).map<Action<LoginState<a>>>(a => s => isLoggedOutState(s) && !readonly ? a(s) : s)
