import { LoginState, LoggedInState } from '../state';
import { Action, string, button, hn, div } from "widgets-for-react";
import i18next = require('i18next');
export let activate_account_success = <a>(s0: LoggedInState<a>, readonly: boolean) =>

div<Action<LoginState<a>>>({ className: "login", key: "login-widget-activate-account-success" })(
  div<Action<LoginState<a>>>({ className: "login__inner", key: "login-widget-activate-account-success-inner" })([
    hn<Action<LoginState<a>>>(1, i18next.t('activate_account_success:account_activated'), { className: "login__title title--l" })(),
    string({ readonly: true, readonly_element: "p", key: "activate-account-success-text" })(i18next.t('activate_account_success:account_activated_text')).never(),
    button<Action<LoginState<a>>>(i18next.t('activate_account_success:login'), { key: "activate-account-success-button", className: "button button--primary button--full-width", disabled: readonly })(() => s => ({ status: "logged out", login_data: { password: "", username: "", pin: { kind: "no pin" }, show_password: false } }))
  ])
)
