import { ResetPasswordRequestState, LoginState } from "../state";
import { Widget, Action, any, string, div, hn } from "widgets-for-react";
import i18next = require('i18next');

export let reset_password_request_success = <a>(s0: ResetPasswordRequestState, rootpath: string): Widget<Action<LoginState<a>>> =>
  any<Action<LoginState<a>>>({ key: "login-widget-reset-password-request-success" })([
    div<Action<LoginState<a>>>({ key: "reset_pass_success_text", className: "login" })(
      div<Action<LoginState<a>>>({ className: "login__inner" })([
        div<Action<LoginState<a>>>()([
          hn<Action<LoginState<a>>>(1, i18next.t('reset_password_request_success:forgot_password'), { className: "login__title title--l" })(),
          string({ readonly: true, readonly_element: "p" })(i18next.t('reset_password_request_success:forgot_password_text', { email: s0.login_data.email })).never(),
        ])
      ])
    )
  ])