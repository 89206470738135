import * as React from "react"
import { Widget, mk_widget } from "widgets-for-react"

export const styleguideTokens: Widget<never> = mk_widget({
  run: _ => (
    <>
      <section id="tokens" className="styleguide-chapter styleguide-chapter--token">
        <header className="styleguide--styled styleguide-chapter__header">
          <h2 className="styleguide__title styleguide-chapter__title">Design Tokens</h2>
          <p className="styleguide-chapter__intro">Design tokens are the visual design atoms of the design system — specifically, they are named entities that store visual design attributes. We use them in place of hard-coded values (such as hex values for color or pixel values for spacing) in order to maintain a scalable and consistent visual system for UI development.</p>
        </header>

        <div className="styleguide-chapter__content">
          <section id="tokens-colors" className="styleguide-section styleguide-section--token">
            <header className="styleguide--styled styleguide-section__header">
              <h3 className="styleguide-section__title">Colors</h3>
              <p className="styleguide-section__intro">Generic colors that are uses in this project.</p>
            </header>

            <div className="styleguide-section__content">
              <table className="styleguide-table">
                <thead>
                  <tr className="styleguide-table__heading styleguide--styled">
                    <th className="styleguide-table__label styleguide-table__label--token">Token</th>
                    <th className="styleguide-table__label styleguide-table__label--example">Example</th>
                    <th className="styleguide-table__label styleguide-table__label--attributes">Attributes</th>
                  </tr>
                </thead>

                <tbody>
                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">$clr--green-400</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <div className="styleguide-token__color token-color--1c3b42"></div>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">#1c3b42</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">$clr--green-600</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <div className="styleguide-token__color token-color--052F2E"></div>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">#052F2E</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">$clr--yellow-400</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <div className="styleguide-token__color token-color--CDAD72"></div>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">#CDAD72</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">$clr--yellow-400-dark (10%)</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <div className="styleguide-token__color token-color--c0974c"></div>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">#c0974c</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">$clr--grey-400</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <div className="styleguide-token__color token-color--F3F0E9"></div>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">#F3F0E9</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">$clr--grey-400-dark (10%)</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <div className="styleguide-token__color token-color--e1dac8"></div>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">#e1dac8</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">$clr--black</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <div className="styleguide-token__color token-color--000000"></div>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">#000000</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">$clr--white</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <div className="styleguide-token__color--border token-color--FFFFFF"></div>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">#FFFFFF</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row" aria-hidden="true"><td className="styleguide-table__seperator" colSpan={3}></td></tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">[OLD!] $color--grey-600</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <div className="styleguide-token__color token-color--D8D8D8"></div>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">#D8D8D8</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">[OLD!] $color--grey-800</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <div className="styleguide-token__color token-color--797773"></div>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">#797773</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">[OLD!] $color--grey-900</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <div className="styleguide-token__color token-color--232322"></div>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">#232322</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <section id="tokens-fonts" className="styleguide-section styleguide-section--token">
            <header className="styleguide--styled styleguide-section__header">
              <h3 className="styleguide-section__title">Fonts</h3>
              <p className="styleguide-section__intro"></p>
            </header>

            <div className="styleguide-section__content">
              <table className="styleguide-table">
                <thead>
                  <tr className="styleguide-table__heading styleguide--styled">
                    <th className="styleguide-table__label styleguide-table__label--token">Token</th>
                    <th className="styleguide-table__label styleguide-table__label--example">Example</th>
                    <th className="styleguide-table__label styleguide-table__label--attributes">Attributes</th>
                  </tr>
                </thead>

                <tbody>
                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">$font--amsi</code>
                      <p className="styleguide-token__description">Primary text font</p>
                    </td>
                    <td className="styleguide-table__cell">
                      <p className="token-font--amsi">Vulputate Commodo Sollicitudin Fringilla Nullam. Etiam porta sem malesuada magna mollis euismod.</p>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">'Amsi Pro', Arial, sans-serif</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <section id="tokens-styles" className="styleguide-section styleguide-section--token">
            <header className="styleguide--styled styleguide-section__header">
              <h3 className="styleguide-section__title">Font Styles</h3>
              <p className="styleguide-section__intro"></p>
            </header>

            <div className="styleguide-section__content">
              <table className="styleguide-table">
                <thead>
                  <tr className="styleguide-table__heading styleguide--styled">
                    <th className="styleguide-table__label styleguide-table__label--token">Token</th>
                    <th className="styleguide-table__label styleguide-table__label--example">Example</th>
                    <th className="styleguide-table__label styleguide-table__label--attributes">Attributes</th>
                  </tr>
                </thead>

                <tbody>
                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">$weight--regular</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <p className="token-weight--regular">Vulputate Commodo Sollicitudin Fringilla Nullam. Etiam porta sem malesuada magna mollis euismod.</p>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">Font-weight: normal</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">$weight--bold</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <p className="token-weight--bold">Vulputate Commodo Sollicitudin Fringilla Nullam. Etiam porta sem malesuada magna mollis euismod.</p>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">Font-weight: bold</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">$weight--800</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <p className="token-weight--800">Vulputate Commodo Sollicitudin Fringilla Nullam. Etiam porta sem malesuada magna mollis euismod.</p>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">Font-weight: 800</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <section id="tokens-sizes-heights" className="styleguide-section styleguide-section--token">
            <header className="styleguide--styled styleguide-section__header">
              <h3 className="styleguide-section__title">Font Sizes &amp; Line Heights</h3>
              <p className="styleguide-section__intro"></p>
            </header>

            <div className="styleguide-section__content">
              <table className="styleguide-table">
                <thead>
                  <tr className="styleguide-table__heading styleguide--styled">
                    <th className="styleguide-table__label styleguide-table__label--token">Token</th>
                    <th className="styleguide-table__label styleguide-table__label--example">Example</th>
                    <th className="styleguide-table__label styleguide-table__label--attributes">Attributes</th>
                  </tr>
                </thead>

                <tbody>
                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">%text--xs</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <p className="text--xs">Vulputate Commodo Sollicitudin Fringilla Nullam. Etiam porta sem malesuada magna mollis euismod.</p>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">

                      <span className="styleguide-token__attribute">Font-size: 10px</span>
                      <span className="styleguide-token__attribute">Line-height: 10px</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">%text--s</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <p className="text--s">Vulputate Commodo Sollicitudin Fringilla Nullam. Etiam porta sem malesuada magna mollis euismod.</p>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">

                      <span className="styleguide-token__attribute">Font-size: 14px</span>
                      <span className="styleguide-token__attribute">Line-height: 24px</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">%text--m</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <p className="text--m">Vulputate Commodo Sollicitudin Fringilla Nullam. Etiam porta sem malesuada magna mollis euismod.</p>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">

                      <span className="styleguide-token__attribute">Font-size: 16px</span>
                      <span className="styleguide-token__attribute">Line-height: 32px</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">%text--l</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <p className="text--l">Vulputate Commodo Sollicitudin Fringilla Nullam. Etiam porta sem malesuada magna mollis euismod.</p>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">

                      <span className="styleguide-token__attribute">Font-size: 18px</span>
                      <span className="styleguide-token__attribute">Line-height: 36px</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row" aria-hidden="true"><td className="styleguide-table__seperator" colSpan={3}></td></tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">%title--xs</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <p className="title--xs">Vulputate Commodo Sollicitudin Fringilla Nullam.</p>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">Font-size: 18px</span>
                      <span className="styleguide-token__attribute">Line-height: 32px / {32 / 18}</span>
                      <span className="styleguide-token__attribute">Font-weight: 700</span>
                      <span className="styleguide-token__attribute">Text-transform: uppercase</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">%title--s</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <p className="title--s">Vulputate Commodo Sollicitudin Fringilla Nullam.</p>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">Font-size: 20px</span>
                      <span className="styleguide-token__attribute">Line-height: 32px / {32 / 20}</span>
                      <span className="styleguide-token__attribute">Font-weight: 700</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">%title--m</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <p className="title--m">Vulputate Commodo Sollicitudin Fringilla Nullam.</p>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">Font-size: 24px</span>
                      <span className="styleguide-token__attribute">Line-height: 32px / {30 / 24}</span>
                      <span className="styleguide-token__attribute">Font-weight: bold</span>
                      <span className="styleguide-token__attribute">Text-transform: uppercase</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">%title--l</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <p className="title--l">Reserveer een tafel</p>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">Font-size: 42px</span>
                      <span className="styleguide-token__attribute">Line-height: 54px / {54 / 42}</span>
                      <span className="styleguide-token__attribute">Font-weight: 800</span>
                      <span className="styleguide-token__attribute">Text-transform: uppercase</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">%title--xl</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <p className="title--xl">Lekker hapje eten?</p>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">Font-size: 60px</span>
                      <span className="styleguide-token__attribute">Line-height: 60px / {60 / 60}</span>
                      <span className="styleguide-token__attribute">Font-weight: 800</span>
                      <span className="styleguide-token__attribute">Text-transform: uppercase</span>
                      
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">%title--xxl</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <p className="title--xl">Lekker hapje eten?</p>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">Font-size: 120px</span>
                      <span className="styleguide-token__attribute">Line-height: 120px / {120 / 120}</span>
                      <span className="styleguide-token__attribute">Font-weight: 800</span>
                      <span className="styleguide-token__attribute">Text-transform: uppercase</span>
                      
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <section id="tokens-icons" className="styleguide-section styleguide-section--token">
            <header className="styleguide--styled styleguide-section__header">
              <h3 className="styleguide-section__title">Icons</h3>
              <p className="styleguide-section__intro"></p>
            </header>

            <div className="styleguide-section__content">
              <table className="styleguide-table">
                <thead>
                  <tr className="styleguide-table__heading styleguide-table__heading--2-column styleguide--styled">
                    <th className="styleguide-table__label styleguide-table__label--token">Token</th>
                    <th className="styleguide-table__label styleguide-table__label--example">Example</th>
                  </tr>
                </thead>

                <tbody>
                  <tr className="styleguide-table__row bg--green">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">#image--logo</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <svg aria-hidden="true" width="80" height="80"><use xlinkHref="#image--logo" /></svg>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row bg--green">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">#image--logo-word</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <svg aria-hidden="true" width="130" height="20"><use xlinkHref="#image--logo-word" /></svg>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">#icon--caret-right</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <svg aria-hidden="true" width="12" height="20"><use xlinkHref="#icon--caret-right" /></svg>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">#icon--caret-down</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <svg aria-hidden="true" width="20" height="13"><use xlinkHref="#icon--caret-down" /></svg>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">#icon--caret-down-thin</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <svg aria-hidden="true" width="20" height="12"><use xlinkHref="#icon--caret-down-thin" /></svg>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">#icon--facebook</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <svg className="styleguide-token__icon" width="11" height="20"><use xlinkHref="#icon--facebook"/></svg>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">#icon--twitter</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <svg className="styleguide-token__icon" width="22" height="18"><use xlinkHref="#icon--twitter"/></svg>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">#icon--instagram</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <svg className="styleguide-token__icon" width="20" height="20"><use xlinkHref="#icon--instagram"/></svg>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">#icon--youtube</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell">
                      <svg className="styleguide-token__icon" width="22" height="22"><use xlinkHref="#icon--youtube"/></svg>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <section id="tokens-spacings-gutters" className="styleguide-section styleguide-section--token">
            <header className="styleguide--styled styleguide-section__header">
              <h3 className="styleguide-section__title">Gutters</h3>
              <p className="styleguide-section__intro"></p>
            </header>

            <div className="styleguide-section__content">
              <table className="styleguide-table">
                <thead>
                  <tr className="styleguide-table__heading styleguide-table__heading--2-column styleguide--styled">
                    <th className="styleguide-table__label styleguide-table__label--token">Token</th>
                    <th className="styleguide-table__label styleguide-table__label--attributes">Attributes</th>
                  </tr>
                </thead>

                <tbody>
                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">$gutter--s</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">15 pixels</span>
                    </td>
                  </tr>

                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">$gutter--m</code>
                      <p className="styleguide-token__description"></p>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">20 pixels</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <section id="tokens-transitions" className="styleguide-section styleguide-section--token">
            <header className="styleguide--styled styleguide-section__header">
              <h3 className="styleguide-section__title">Transitions</h3>
              <p className="styleguide-section__intro"></p>
            </header>

            <div className="styleguide-section__content">
              <table className="styleguide-table">
                <thead>
                  <tr className="styleguide-table__heading styleguide-table__heading--2-column styleguide--styled">
                    <th className="styleguide-table__label styleguide-table__label--token">Token</th>
                    <th className="styleguide-table__label styleguide-table__label--attributes">Attributes</th>
                  </tr>
                </thead>

                <tbody>
                  <tr className="styleguide-table__row">
                    <td className="styleguide-table__cell styleguide--styled">
                      <code className="styleguide-token__code">$transition--normal</code>
                      <p className="styleguide-token__description">o.a. used for all transitions at the moment.</p>
                    </td>
                    <td className="styleguide-table__cell styleguide--styled">
                      <span className="styleguide-token__attribute">0.25 seconds</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </section>
    </>
  )
})
