import { List } from "immutable"
import * as React from "react"
import { Fun, mk_widget, Option, Pair } from "widgets-for-react"
import { ContentType } from "../../public_site"
import { ParsedNode } from "../drupal/drupal_generated/types"
import { Breadcrumb } from "../drupal/drupal_utils/types"
import { CountdownCampaign, pageHeaderCampaign } from "../public_site/campaign_page"
import { bodyFooter } from "./body_footer"
import { bodyHeader } from "./body_header"
import { pageHeader } from "./page_headers"

export const page: Fun<Pair<List<Breadcrumb>, Option<ParsedNode>>, ContentType> = breadcrumbs_and_node => blocks =>
  mk_widget({
    run: o => (
      <>
        {breadcrumbs_and_node.snd.v.kind == "r" && (
          <>
            {breadcrumbs_and_node.snd.v.v.bundle == 'campaign' ?
              pageHeaderCampaign(breadcrumbs_and_node.snd.v.v).run(_=> null)
            :
              pageHeader(breadcrumbs_and_node.fst)(breadcrumbs_and_node.snd.v.v).run(() => null)
            }
            {bodyHeader(breadcrumbs_and_node.snd.v.v).run(() => null)}
          </>
        )}

        {
          breadcrumbs_and_node.snd.v.kind == "r" &&
          breadcrumbs_and_node.snd.v.v.bundle == 'campaign' &&
          breadcrumbs_and_node.snd.v.v.field_countdown != undefined &&
          breadcrumbs_and_node.snd.v.v.field_countdown_start != undefined
        && (
          <CountdownCampaign node={breadcrumbs_and_node.snd.v.v} />
        )}

        <div className={`content${breadcrumbs_and_node.snd.v.kind == "r" ? ` content--${breadcrumbs_and_node.snd.v.v.bundle}` : ''}`}>
          {blocks.run(o)}

          {breadcrumbs_and_node.snd.v.kind == "r" && [bodyFooter(breadcrumbs_and_node.snd.v.v).run(() => null)]}
        </div>
      </>
    )
  })
  