import * as React from "react"
import { Action, IOWidget, mk_pair, Widget, nothing, Option, Pair } from "widgets-for-react"
import { CtaBlock, CtaBlockProps, CtaBlockStyle } from "./ctaBlock"
import { DownloadBlock, DownloadBlockProps, DownloadBlockStyle } from "./downloadBlock"
import { ExpandBlock, ExpandBlockProps, ExpandBlockStyle } from "./expandBlock"
import { FeaturedContentBlockStyle, FeaturedContentComponent, FeaturedContentBlockProps } from "./featuredContentBlock"
import { LinkBlock, LinkBlockProps, LinkBlockStyle } from "./linkBlock"
import { LinkGridBlock, LinkGridBlockProps, LinkGridBlockStyle } from "./linkGridBlock"
import { MediaBlock, MediaBlockProps, MediaBlockStyle } from "./mediaBlock"
import { BlockData, CMSState } from "./state"
import { TextBlock, TextBlockProps, TextBlockStyle } from "./textBlock"
import { jsxToWidget } from "./utils"
import { QuoteBlock, QuoteBlockProps, QuoteBlockStyle } from "./quoteBlock";
import { IframeBlockProps, IframeBlockStyle, IframeBlock } from "./iframeBlock";
import { LeafletMapState } from "./LeafletMapBlock/LeafletMap.State"
import { LeafletMapBlock, LeafletMapBlockStyle } from "./LeafletMapBlock/LeafletMap.widget"
import { EmbedBlockProps, EmbedBlockStyle, EmbedBlock } from "./embedBlock"

export type BlocksRenderer<customBlockData, extra_images> = {
  ctaBlock:(props: CtaBlockProps,options?: { style?: CtaBlockStyle; key?: string }) => IOWidget<CMSState<customBlockData, extra_images>, Action<CMSState<customBlockData, extra_images>>>
  featuredContentComponent:(itemsOptions: Array<Pair<FeaturedContentBlockProps<customBlockData, extra_images>, { style?: FeaturedContentBlockStyle; key?: string }>>) => IOWidget<CMSState<customBlockData, extra_images>, Action<CMSState<customBlockData, extra_images>>>,
  expandBlock:(props: ExpandBlockProps,options?: { style?: ExpandBlockStyle; key?: string }) => IOWidget<CMSState<customBlockData, extra_images>, Action<CMSState<customBlockData, extra_images>>>,
  quoteBlock:(props: QuoteBlockProps<extra_images>,options?: { style?: QuoteBlockStyle; key?: string }) => IOWidget<CMSState<customBlockData, extra_images>, Action<CMSState<customBlockData, extra_images>>>,
  mediaBlock:(props: MediaBlockProps<extra_images>,options?: { style?: MediaBlockStyle; key?: string }) => IOWidget<CMSState<customBlockData, extra_images>, Action<CMSState<customBlockData, extra_images>>>,
  linkGridBlock:(props: LinkGridBlockProps<extra_images>,options?: { style?: LinkGridBlockStyle; key?: string }) => IOWidget<CMSState<customBlockData, extra_images>, Action<CMSState<customBlockData, extra_images>>>,
  linkBlock:(props: LinkBlockProps,options?: {style?: LinkBlockStyle, key?: string}) => IOWidget<CMSState<customBlockData, extra_images> , Action<CMSState<customBlockData, extra_images> >>
  downloadBlock:(props: DownloadBlockProps,options?: { style?: DownloadBlockStyle; key?: string }) => IOWidget<CMSState<customBlockData, extra_images>, Action<CMSState<customBlockData, extra_images>>> ,
  textBlock:(props: TextBlockProps<customBlockData, extra_images>,options?: { style?: TextBlockStyle; key?: string }) => IOWidget<CMSState<customBlockData, extra_images>, Action<CMSState<customBlockData, extra_images>>>,
  iframeBlock:(props: IframeBlockProps,options?: { style?: IframeBlockStyle; key?: string }) => IOWidget<CMSState<customBlockData, extra_images>, Action<CMSState<customBlockData, extra_images>>> ,
  leafletMapBlock:(props: LeafletMapState,options?: { style?: LeafletMapBlockStyle; key?: string }) => IOWidget<CMSState<customBlockData, extra_images>, Action<CMSState<customBlockData, extra_images>>> ,
  embedBlock:(props: EmbedBlockProps,options?: { style?: EmbedBlockStyle; key?: string }) => IOWidget<CMSState<customBlockData, extra_images>, Action<CMSState<customBlockData, extra_images>>>

}

export let getMockedBlockRenderer = <customBlockData, extra_images>():BlocksRenderer<customBlockData, extra_images> =>  {
  return {
    featuredContentComponent: FeaturedContentComponent,
    quoteBlock: QuoteBlock,
    expandBlock: ExpandBlock,
    mediaBlock: MediaBlock,
    linkGridBlock: LinkGridBlock,
    linkBlock: LinkBlock,
    downloadBlock: DownloadBlock,
    ctaBlock: CtaBlock,
    textBlock: TextBlock,
    iframeBlock: IframeBlock,
    leafletMapBlock: LeafletMapBlock,
    embedBlock: EmbedBlock,
  }
}

export function renderBlock<CustomBlockData extends {kind:CustomBlockData["kind"]}, custom_images>
  (block: BlockData<CustomBlockData, custom_images>, block_renderer:BlocksRenderer<CustomBlockData, custom_images>, key?: string): IOWidget<CMSState<CustomBlockData, custom_images>, Action<CMSState<CustomBlockData, custom_images>>> {
    return s0 =>
    block.kind === "featuredContentBlock"
      ? block_renderer.featuredContentComponent(block.value.map(e => mk_pair(e.fst, { style: e.snd, key })))(s0)
    :  block.kind === "textBlock"
      ? block_renderer.textBlock(block.value.fst, { style: block.value.snd, key })(s0)
    : block.kind === "ctaBlock"
      ? block_renderer.ctaBlock(block.value.fst, { style: block.value.snd, key })(s0)
    : block.kind === "downloadBlock"
      ? block_renderer.downloadBlock(block.value.fst, { key, style: block.value.snd })(s0)
    : block.kind === "linkBlock"
      ? block_renderer.linkBlock(block.value.fst, { style: block.value.snd, key })(s0)
    : block.kind === "linkGridBlock"
      ? block_renderer.linkGridBlock(block.value.fst, { style: block.value.snd, key })(s0)
    : block.kind === "mediaBlock"
      ? block_renderer.mediaBlock(block.value.fst, { style: block.value.snd, key })(s0)
    : block.kind === "expandBlock"
      ? block_renderer.expandBlock(block.value.fst, { style: block.value.snd, key })(s0)
    : block.kind === "quoteBlock"
      ? block_renderer.quoteBlock(block.value.fst, { style: block.value.snd, key })(s0)
    : block.kind === "iframeBlock"
      ? block_renderer.iframeBlock(block.value.fst, { style: block.value.snd, key })(s0)   
    : block.kind === "embedBlock"
      ? block_renderer.embedBlock(block.value.fst, { style: block.value.snd, key })(s0) 
    : jsxToWidget<Action<CMSState<CustomBlockData, custom_images>>>(
        <div key={key}>Block not found</div>
      ).never()
}
