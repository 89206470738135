import * as React from "react"
import { Link as LinkComponent } from "react-router-dom"
import { mkWidget } from "widgets-for-react"
import { Link } from "./types"
import { ButtonProps } from "./featuredContentBlock";
import { LinkProps } from "./linkBlock";

export function jsxToWidget<a>(jsx: JSX.Element) {
  return mkWidget<a>({ run: _ => jsx })
}

export function link(
  link: Link,
  style: { classname: string },
  inner: JSX.Element | string
): JSX.Element {
  return link.is_link_external || link.target === "_blank" ? (
    <a className={style.classname} target={link.is_link_external ? '_blank' : link.target} href={link.url}>
      {inner}
    </a>
  ) : (
      <LinkComponent className={style.classname} to={link.url}>
        {inner}
      </LinkComponent>
    )
}

//  This function matches the url of a DrupalLink against a regex that checks if the url that we want to
//  navigat to is on the same host we are curently at, or is only a path so we can use the <Link> component
//  otherwhise, if it isnt a url on our own hostname it returns true and is external.

export const isLinkExternal = (link: string): boolean => {
  let currentUrl = window.location.host
  let pattern = RegExp(`(https?:\/\/(!?${currentUrl}/.*)|^\/.*)`, "gm")
  return !pattern.test(link)
}

// Strip a Drupal link of its domain information so we only have the path left

export const stripUrlToPath = (link: { title: string, url: string }): string => {
  let a = document.createElement("a")
  a.href = link.url
  return a.pathname + a.hash
}

// Create a link from an acf link that checks if it is external, and rewrites the url if needed

export const buttonWrapper = (link: { title: string, url: string }): ButtonProps<never, never> => ({
  kind: "link",
  is_link_external: isLinkExternal(link.url),
  target: "_self",
  title: link.title,
  url: isLinkExternal(link.url) ? link.url : stripUrlToPath(link),
})

export const linkWrapper = (link: { title: string, url: string }): Link => ({
  kind: "link",
  is_link_external: isLinkExternal(link.url),
  target: isLinkExternal(link.url) ? "_blank" : "_self",
  title: link.title,
  url: isLinkExternal(link.url) ? link.url : stripUrlToPath(link),
})

export const parseLinkProps = (li: { title: string, url: string }, key: number): LinkProps => ({
  key: key.toString(),
  title: li.title,
  target: isLinkExternal(li.url) ? "_blank" : "_self",
  url: isLinkExternal(li.url) ? li.url : stripUrlToPath(li)
})