import * as React from "react"
import { Widget, mk_widget } from "widgets-for-react"

export const styleguideObjects: Widget<never> = mk_widget({
  run: _ => (
    <>
      <section id="objects" className="styleguide-chapter styleguide-chapter--objects">
        <header className="styleguide--styled styleguide-chapter__header">
          <h2 className="styleguide__title styleguide-chapter__title">Design Objects</h2>
          <p className="styleguide-chapter__intro">If design tokens are the atoms of the design system, design objects are the material — specifically, they what comes after. We use them to make the developer’s job easier and to give the designers some extra insight in their work.</p>
        </header>

        <div className="styleguide-chapter__content">
          <section id="objects-typography" className="styleguide-section styleguide-section--objects">
            <header className="styleguide--styled styleguide-section__header">
              <h3 className="styleguide-section__title">Typography</h3>
              <p className="styleguide-section__intro"></p>
            </header>

            <div className="styleguide-section__content">
              <section className="styleguide-part">
                <header className="styleguide--styled styleguide-part__header">
                  <h4 className="styleguide-part__title">Titles</h4>
                </header>

                <div className="styleguide-part__content">
                  <section className="styleguide-example">
                    <div className="styleguide-example__section cf">
                      <div className="text--styled">
                        {/* <h1>H1 - Integer posuere erat a ante venenatis</h1> */}
                        <h2>H2 - Donec ullamcorper nulla aliquet</h2>
                        <h3>H3 - Vivamus sagittis lacus vel augue</h3>
                        {/* <h4>H4 - Laoreet rutrum faucibus dolor auctor</h4> */}
                        {/* <h5>H5 - Donec id elit non mi porta</h5> */}
                        {/* <h6>H6 - Gravida at eget metus</h6> */}
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
          </section>
        </div>

        <div className="styleguide-chapter__content">
          <section id="objects-links" className="styleguide-section styleguide-section--objects">
            <header className="styleguide--styled styleguide-section__header">
              <h3 className="styleguide-section__title">Links</h3>
              <p className="styleguide-section__intro">The links are clickable items to perform an action. They should be used in situations where user might need to submit a form, begin a task or specify a next or new step in a process.</p>
            </header>

            <div className="styleguide-section__content">
              <section className="styleguide-part">
                <header className="styleguide--styled styleguide-part__header">
                  <h4 className="styleguide-part__title">Inline link</h4>
                </header>

                <div className="styleguide-part__content">
                  <section className="styleguide-example">
                    <div className="styleguide-example__section cf">
                      <div className="text--styled">
                        <p>Integer posuere erat a ante venenatis <a href="#">Donec ullamcorper nulla</a> aliquet. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec id elit non mi porta gravida at eget metus.</p>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
          </section>
        </div>


        <div className="styleguide-chapter__content">
          <section id="objects-buttons" className="styleguide-section styleguide-section--objects">
            <header className="styleguide--styled styleguide-section__header">
              <h3 className="styleguide-section__title">Buttons</h3>
            </header>

            <div className="styleguide-section__content">
              <section className="styleguide-part">
                <header className="styleguide--styled styleguide-part__header">
                  <h4 className="styleguide-part__title">Primary</h4>
                </header>

                <div className="styleguide-part__content">
                  <section className="styleguide-example">
                    <div className="styleguide-example__section cf">
                      <a href="#" className="button button--primary">Soliciteer nu!</a>
                    </div>
                  </section>
                </div>
              </section>
            </div>

            <div className="styleguide-section__content">
              <section className="styleguide-part">
                <header className="styleguide--styled styleguide-part__header">
                  <h4 className="styleguide-part__title">Secondary</h4>
                </header>

                <div className="styleguide-part__content">
                  <section className="styleguide-example">
                    <div className="styleguide-example__section cf">
                      <a href="#" className="button button--secondary">Bestel online</a>
                    </div>
                  </section>
                </div>
              </section>
            </div>

            <div className="styleguide-section__content">
              <section className="styleguide-part">
                <header className="styleguide--styled styleguide-part__header">
                  <h4 className="styleguide-part__title">Tertiary</h4>
                </header>

                <div className="styleguide-part__content">
                  <section className="styleguide-example">
                    <div className="styleguide-example__section cf">
                      <a href="#" className="button button--tertiary">Bestel online</a>
                    </div>
                  </section>
                </div>
              </section>
            </div>
          </section>
        </div>
      </section>
    </>
  )
})
